import { NavLink } from "react-router-dom";
import { InputAdornment, Divider, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";

import egicon1 from "../../../assets/images/icons/egicon1.svg";
import egicon2 from "../../../assets/images/icons/egicon2.svg";

import useLayout from "../../../utils/hooks/useLayout";
import { color, spacing } from "../../../styles/variables";
import {
	Wrapper,
	NavigationLink,
	LeftNav,
	Searchbar,
	SearchField,
	Dropdown,
	RightNav,
	QuickNaviation,
	MobileWrapper,
	MobileTopNavs,
	MobileLeftNav,
	MobileRightNav,
	MobileQuickNav,
} from "./index.styled";

const Header = () => {
	const isMobile = useLayout();
	return (
		<Wrapper isMobile={isMobile}>
			{isMobile ? (
				<MobileWrapper>
					<MobileTopNavs>
						<MobileLeftNav>
							<MenuIcon sx={{ color: color("pWhite"), width: 30 }} />
							<SearchIcon sx={{ color: color("pWhite"), width: 23 }} />
						</MobileLeftNav>
						<MobileRightNav>
							<NavLink>
								<NotificationsIcon sx={{ color: color("pWhite"), width: 30 }} />
							</NavLink>
							<NavLink>
								<FavoriteBorderIcon sx={{ color: color("pWhite"), width: 32 }} />
							</NavLink>
						</MobileRightNav>
					</MobileTopNavs>
					<MobileQuickNav>
						<QuickNaviation>
							<img src={egicon1} alt="" />
							Micro Blogging
						</QuickNaviation>
						<QuickNaviation>
							<img src={egicon2} alt="" />
							Short Videos
						</QuickNaviation>
					</MobileQuickNav>
				</MobileWrapper>
			) : (
				<>
					<LeftNav>
						<NavigationLink>
							Jobs
							<ExpandMoreIcon />
						</NavigationLink>
						<NavigationLink>
							Talent
							<ExpandMoreIcon />
						</NavigationLink>
						<NavigationLink>
							Reports
							<ExpandMoreIcon />
						</NavigationLink>
						<NavigationLink>Messages</NavigationLink>
					</LeftNav>
					<Searchbar>
						<SearchField
							startAdornment={
								<InputAdornment
									position="start"
									sx={{
										marginInlineEnd: spacing("xs"),
									}}
								>
									<SearchIcon color={color("dimGray")} />
								</InputAdornment>
							}
							endAdornment={
								<InputAdornment position="end">
									<Dropdown IconComponent={ExpandMoreIcon} variant="standard" defaultValue={"talent"}>
										<MenuItem value="talent">Talent</MenuItem>
										<MenuItem value="tech">Tech</MenuItem>
									</Dropdown>
								</InputAdornment>
							}
							placeholder="Search"
						/>
					</Searchbar>
					<RightNav>
						<QuickNaviation>
							<img src={egicon1} alt="" />
							Micro Blogging
						</QuickNaviation>
						<QuickNaviation>
							<img src={egicon2} alt="" />
							Short Videos
						</QuickNaviation>
					</RightNav>
				</>
			)}
		</Wrapper>
	);
};

export default Header;
