import React, { useEffect, useState, useRef } from "react";
import lodash from "lodash";
import "./MBSearch.scss";
import pic3 from "../../../assets/images/profile/follow/3.png";
import { Navigate, useNavigate } from "react-router-dom";
import { InputAdornment, Input, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import Loader from "../../loader/index";
import { connect } from "react-redux";

const MBSearch = ({ setActiveModule, setActiveMBProfile }) => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [search, setSearch] = useState("");
	const [searchResults, setSearchResults] = useState([]);

	const fullText =
		"Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus Fattu Dhinga - Sultanpur Lodhi";
	const maxLines = 3;

	const [showFullText, setShowFullText] = useState(false);
	const [isTextTruncated, setIsTextTruncated] = useState(false);

	const textRef = useRef();

	const toggleText = () => {
		setShowFullText(!showFullText);
	};

	const debouncedSearch = lodash.debounce((value) => setSearch(value), 300);

	const handleSearch = (event) => {
		debouncedSearch(event.target.value);
	};

	const { isLoading, data, refetch } = useQuery({
		queryKey: ["MBSearch", search],
		queryFn: async () => {
			const response = await query("GET", "MB", `searchMicrobloggingAccounts?searchName=${search}`);
			setSearchResults(response.data.result);
			return response.data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	useEffect(() => {
		refetch();
	}, [search, refetch]);

	const handleProfileView = (result) => {
		setActiveModule("it");
		setActiveMBProfile(result?.microbloggingId);
		navigate("/microblogging/profile/public", {
			state: {
				endpoint: "getMicrobloggingAccountDetails",
			},
		});
	};

	return (
		<>
			{token ? (
				<div className="mbsc">
					<div className="mbsc_pop">
						<div className="mbsc_pop_container">
							<div className="mbsc_pop_section">
								<div className="mbsc_pop_section_input">
									<Input
										onChange={handleSearch}
										startAdornment={
											<InputAdornment position="start">
												<IconButton style={{ color: "#989898", margin: "0px" }}>
													<SearchIcon />
												</IconButton>
											</InputAdornment>
										}
										placeholder="Search"
									/>
								</div>

								<div className="mbsc_pop_section_cards">
									{!isLoading &&
										searchResults?.map((result) => (
											<div
												className="mbsc_pop_section_cards_content"
												onClick={() => handleProfileView(result)}
											>
												<div className="mbsc_pop_section_cards_content_center">
													<div className="mbsc_pop_section_cards_content_center_image">
														<img src={result?.profilePicture || pic3} alt="" />
													</div>
													<div className="mbsc_pop_section_cards_content_center_content">
														<h2>{result?.microbloggingUsername}</h2>
														<h5
															className={showFullText ? "show-full" : "show-truncated"}
															ref={textRef}
															onClick={toggleText}
														>
															{showFullText
																? fullText
																: `${fullText.split("\n").slice(0, maxLines).join("\n")}`}
															{isTextTruncated && <span className="toggle">...</span>}
														</h5>
														<p>13 Mutual Friends</p>
													</div>
												</div>
												<div className="mbsc_pop_section_cards_content_right">
													<button type="button">Message</button>
												</div>
											</div>
										))}
									{isLoading && <Loader />}
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(MBSearch);
