import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../../../components/Education/Forms/Signup";
import ClientSignUpForm from "../../../../components/Education/Forms/Signup/Client";

const ClientSignUp = () => {
	const [profilePicture, setProfilePicture] = useState(null);
	const navigate = useNavigate();
	const handleSubmit = (values) => {
		console.log(values);
		debugger;
		navigate("/education/microblogging", {
			state: {
				allData: { ...values },
				picture: profilePicture,
				url: "createEducationClient",
				redirect: "/education/client/profile/private",
			},
		});
	};
	return (
		<SignUp userType={"Client"} onSubmit={handleSubmit}>
			<ClientSignUpForm setProfilePicture={setProfilePicture} onSubmit={handleSubmit} />
		</SignUp>
	);
};

export default ClientSignUp;
