import styled from "@emotion/styled";

const ReviewWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	column-gap: 12px;
	margin-block: 8px;

	@media screen and (max-width: 768px) {
		margin-block: 6px;
	}
`;

const Review = styled.div``;

const BtnWrapper = styled.div`
	grid-column: span 1;
	display: flex;
	flex-direction: column;

	row-gap: 8px;
`;

const InputWrapper = styled.div`
	display: flex;
	column-gap: 14px;
	align-items: center;
	position: relative;

	@media screen and (max-width: 768px) {
		& > button {
			position: absolute;
			right: 0;
		}
	}
`;

const RatingWrapper = styled.div`
	@media screen and (max-width: 768px) {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}
`;

export { ReviewWrapper, Review, BtnWrapper, InputWrapper, RatingWrapper };
