import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import "./PublicProfile.scss";
import tick from "../../assets/icons/charm_tick.svg";
import friends from "../../assets/icons/account.png";
import square_banner from "../../assets/images/user-profile-backsquare.png";
import banner from "../../assets/images/profile/banner.png";
import profile from "../../assets/icons/user-profile.png";
import filter from "../../assets/images/profile/filter.png";
import follow1 from "../../assets/images/profile/follow/1.png";
import follow2 from "../../assets/images/profile/follow/2.png";
import follow3 from "../../assets/images/profile/follow/3.png";
import follow4 from "../../assets/images/profile/follow/4.png";
import photo1 from "../../assets/images/profile/photos/1.png";
import photo2 from "../../assets/images/profile/photos/2.png";
import photo3 from "../../assets/images/profile/photos/3.png";
import photo4 from "../../assets/images/profile/photos/4.png";
import photo5 from "../../assets/images/profile/photos/5.png";
import photo6 from "../../assets/images/profile/photos/6.png";
import photo7 from "../../assets/images/profile/photos/7.png";
import photo8 from "../../assets/images/profile/photos/8.png";
import photo9 from "../../assets/images/profile/photos/9.png";
import photo10 from "../../assets/images/profile/photos/10.png";
import photo11 from "../../assets/images/profile/photos/11.png";
import photo12 from "../../assets/images/profile/photos/12.png";
import PostComponent from "../../components/PostComponent/PostComponent";
import addfriend from "../../assets/images/icons/add-friend.png";
import message from "../../assets/images/icons/message.png";
import funds from "../../assets/images/icons/funds.png";
import review from "../../assets/images/icons/review.png";
import { Rating, Typography } from "@mui/material";
import Input from "../../components/Inputs/Input";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import ReviewComponent from "../../components/ReviewComponent/ReviewComponent";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import {
	AddFriendRequest,
	CreateOrJoinChat,
	GetAllFriendList,
	GetAllFriendListSentStatus,
	GetAllUserPost,
	GetAllUserReview,
	GetUserDetails,
	ReviewGPT,
	ReviewUser,
} from "../../utils/apiCalls";
import SidenavMobileComponent from "../../components/SidenavMobileComponent";
import { UserContext } from "../../store/UserDetailsStore";
import Modal from "../../components/Modal";
import PrimaryBtn from "../../components/Buttons/Primary/index";
import { ReviewWrapper, Review, BtnWrapper, InputWrapper, RatingWrapper } from "./index.styled";
import Rocket from "../../assets/icons/chatbot/rocket";
import useLayout from "../../utils/hooks/useLayout";

const PublicProfile = ({ setMenuopen, menuopen }) => {
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const { isLoginUserDetails } = useContext(UserContext);
	const handleClose = () => {
		setIsResponse(undefined);
		setOpen(false);
	};
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const searchId = sessionStorage.getItem("searchId");
	const [isPopupVisible, setPopupVisibility] = useState(false);
	const [profileData, setProfileData] = useState([]);
	const [statusData, setStatusData] = useState([]);
	const [userList, setUserList] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [activePageReview, setActivePageReview] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const [postList, setPostList] = useState([]);
	const [postListAll, setPostListAll] = useState([]);
	const [reviewList, setReviewList] = useState([]);
	const [reviewListAll, setReviewListAll] = useState([]);
	const [totalRecordsCount, setTotalRecordsCount] = useState("");
	const [totalPagesCount, setTotalPagesCount] = useState("");
	const [loading, setLoading] = useState(false);
	const bottomOfPageRef = useRef(null);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertReviewMessage, setAlertReviewMessage] = useState("");
	const [starRating, setStarRating] = useState(5);
	const [reviewGPT, setReviewGPT] = useState("");
	const [reviewText, setReviewText] = useState({
		text: "",
	});
	const [isResponse, setIsResponse] = useState(undefined);
	const isMobile = useLayout();

	const handleClearReview = () => {
		setReviewText({
			text: "",
		});
		setReviewGPT("");
	};

	const togglePopup = () => {
		handleClearReview();
		setPopupVisibility((isVisible) => !isVisible);
		// document.body.style.overflow = "auto";
	};

	const handleReviewChange = (name, value) => {
		setReviewText({
			...reviewText,
			[name]: value,
		});
	};

	useEffect(() => {
		const payload = {
			searchId: searchId,
		};
		if (searchId) {
			GetUserDetails((res) => {
				const { message, status, data } = res;
				if (status === 200) {
					setProfileData(data);
				} else {
					setProfileData([]);
				}
			}, payload);
		}
	}, []);

	const handleAddFriend = () => {
		if (searchId) {
			const payload = {
				recipientId: searchId,
			};
			if (payload) {
				AddFriendRequest((res) => {
					const { message, status, data } = res;
					if (status === 200) {
						handleSentStatus();
					}
				}, payload);
			}
		}
	};

	const handleSentStatus = () => {
		const payload = {
			searchId: searchId,
		};
		if (searchId) {
			GetAllFriendListSentStatus((res) => {
				const { message, status, data } = res;
				if (status === 200) {
					setStatusData(data);
				} else {
					setStatusData(data);
				}
			}, payload);
		}
	};

	const getAllUserData = () => {
		const payload = {
			searchId: searchId,
		};
		if (payload) {
			GetAllFriendList((res) => {
				const { message, data, status } = res;
				if (status === 200) {
					setUserList(data);
				} else {
					setUserList([]);
				}
			}, payload);
		}
	};

	useEffect(() => {
		handleSentStatus();
		getAllUserData();
	}, []);

	useEffect(() => {
		if (postList?.length !== 0) {
			pushArrayList(postList);
		}
	}, [postList]);

	useEffect(() => {
		if (reviewList?.length !== 0) {
			pushArrayListReview(reviewList);
		}
	}, [reviewList]);

	const pushArrayListReview = (reviewList) => {
		for (let i = 0; i < reviewList.length; i++) {
			const newGeneration = reviewList[i];
			const isReviewId = newGeneration.ReviewId;
			const isGenerationAlreadyPresent = reviewListAll.some((existingPost) => {
				return existingPost.ReviewId === isReviewId;
			});

			if (!isGenerationAlreadyPresent) {
				reviewListAll.push(newGeneration);
			}
		}
		setReviewListAll([...reviewListAll]);
	};

	const pushArrayList = (postList) => {
		for (let i = 0; i < postList.length; i++) {
			const newGeneration = postList[i];
			const isPostId = newGeneration.postId;
			const isGenerationAlreadyPresent = postListAll.some((existingPost) => {
				return existingPost.postId === isPostId;
			});

			if (!isGenerationAlreadyPresent) {
				postListAll.push(newGeneration);
			}
		}
		setPostListAll([...postListAll]);
	};

	const getAllPostData = (pageIndex) => {
		const payload = {
			userId: searchId,
			pageSize,
			pageIndex,
		};
		GetAllUserPost((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setPostList(data.post);
				setTotalRecordsCount(data?.totalRecords);
				setTotalPagesCount(Math.ceil(data?.totalRecords / pageSize));
			} else if (status === 404) {
				setAlertMessage("No posts available.");
			} else {
				setTotalRecordsCount(0);
				setTotalPagesCount(0);
			}
			setLoading(false);
		}, payload);
	};

	const getAllReviewPost = (pageIndex) => {
		const payload = {
			userId: searchId,
			pageSize,
			pageIndex,
		};
		GetAllUserReview((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setReviewList((prevList) => [...prevList, ...data?.reviews]);
				setTotalRecordsCount(data?.totalRecords);
				setTotalPagesCount(Math.ceil(data?.totalRecords / pageSize));
			} else if (status === 404) {
				setAlertReviewMessage("No reviews available.");
			} else {
				setTotalRecordsCount(0);
				setTotalPagesCount(0);
			}
			setLoading(false);
		}, payload);
	};

	// const handleScroll = () => {
	// 	if (bottomOfPageRef.current && bottomOfPageRef.current.getBoundingClientRect().bottom <= window.innerHeight + 1) {
	// 		if (activePage < totalPagesCount && !loading) {
	// 			setLoading(true);
	// 			getAllPostData(activePage);
	// 			setActivePage((prevPage) => prevPage + 1);
	// 		}
	// 	}
	// };

	// const handleScroll1 = () => {
	// 	if (bottomOfPageRef.current && bottomOfPageRef.current.getBoundingClientRect().bottom <= window.innerHeight + 1) {
	// 		if (activePageReview < totalPagesCount && !loading) {
	// 			setLoading(true);
	// 			getAllReviewPost(activePageReview);
	// 			setActivePageReview((prevPage) => prevPage + 1);
	// 		}
	// 	}
	// };

	useEffect(() => {
		setActivePage(1);
	}, [pageSize]);

	useEffect(() => {
		setActivePageReview(1);
	}, [pageSize]);

	useEffect(() => {
		if (activePage >= 1) {
			getAllPostData(activePage - 1);
		}
	}, [pageSize, activePage]);

	useEffect(() => {
		if (activePage >= 1) {
			getAllReviewPost(activePageReview - 1);
		}
	}, [pageSize, activePageReview]);

	// useEffect(() => {
	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll);
	// 	};
	// }, [handleScroll]);

	// useEffect(() => {
	// 	window.addEventListener("scroll", handleScroll1);
	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll1);
	// 	};
	// }, [handleScroll1]);

	const handleReviewGPT = () => {
		if (searchId) {
			const payload = {
				userId: searchId,
				review: reviewText?.review,
			};
			setIsResponse(true);
			ReviewGPT((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setReviewGPT(data);
					setIsResponse(false);
					setReviewText({
						text: "",
					});
				} else if (status === 400) {
					setOpen(true);
					setAlertReviewMessage(message);
				}
			}, payload);
		}
	};

	const handleCancelReview = (e) => {
		const textarea = document.querySelector(".hemant");
		textarea.style.height = "29px";
		setIsResponse(false);
		handleClearReview();
	};

	const handlePostReview = () => {
		if (searchId) {
			const payload = {
				userId: searchId,
				stars: starRating,
				review: reviewGPT,
			};
			ReviewUser((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					window.location.href = "/publicprofile";
					setPopupVisibility(!isPopupVisible);
					getAllReviewPost(activePage - 1);
					handleClearReview();
				}
			}, payload);
		}
	};

	const sidebarRef = useRef(null);

	// useEffect(() => {
	// 	const sidebar = sidebarRef.current;

	// 	const onScroll = () => {
	// 		const sidebarTop = sidebar.getBoundingClientRect().top;
	// 		if (sidebarTop <= 0) {
	// 			sidebar.classList.add("sticky");
	// 		} else {
	// 			sidebar.classList.remove("sticky");
	// 		}
	// 	};

	// 	window.addEventListener("scroll", onScroll);

	// 	return () => {
	// 		window.removeEventListener("scroll", onScroll);
	// 	};
	// }, []);

	const handleNavigateMessage = () => {
		if (searchId) {
			const payload = {
				recipientId: searchId,
			};
			sessionStorage?.setItem("searchId", searchId);
			CreateOrJoinChat((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					navigate("/messaging", { state: { activeChat: searchId } });
				}
			}, payload);
		}
	};

	return (
		<>
			{token ? (
				<div className="profile" ref={bottomOfPageRef}>
					{menuopen ? (
						<div className="open-class">
							<SidenavMobileComponent />
						</div>
					) : (
						<div className="profile_container">
							<div className="profile_banner">
								{profileData?.coverUrl ? (
									<img src={profileData?.coverUrl} alt="banner" />
								) : (
									<img src={banner} alt="banner" />
								)}
							</div>
							<div className="profile_user">
								<div className="profile_user_details">
									<div className="profile_user_details_left">
										<div className="profile_user_details_image">
											{profileData?.profilePictureUrl ? (
												<img src={profileData?.profilePictureUrl} alt="profile" className="profile" />
											) : (
												<img src={profile} alt="profile" className="profile" />
											)}
										</div>
										<div className="profile_user_details_content">
											<div className="profile_user_details_content_name">
												{profileData?.name ? <h3>{profileData?.name}</h3> : <h3>Anonymous User</h3>}
											</div>
											<div className="profile_user_details_content_follow">
												<span>928 followers</span>
												<p>*</p>
												<span>3k following</span>
											</div>
											<div className="profile_user_details_content_images">
												<img src={follow1} alt="follow" />
												<img src={follow2} alt="follow" />
												<img src={follow3} alt="follow" />
												<img src={follow4} alt="follow" />
											</div>
										</div>
									</div>
									<div className="profile_user_details_right">
										{statusData?.friendRequestStatus === "canceled" ? (
											<div
												className="profile_user_details_right_buttonsec"
												style={{ display: "flex" }}
												onClick={handleAddFriend}
											>
												<img src={addfriend} alt="" />
												<p>Add Friend</p>
											</div>
										) : statusData?.friendRequestStatus === "pending" ? (
											<div
												className="profile_user_details_right_buttonsec"
												style={{ display: "flex" }}
												onClick={handleAddFriend}
											>
												<img src={tick} alt="" />
												<p>Request Sent</p>
											</div>
										) : statusData?.friendRequestStatus === "accepted" ? (
											<div
												className="profile_user_details_right_buttonsec"
												style={{ display: "flex" }}
												onClick={handleAddFriend}
											>
												<img src={friends} alt="" />
												<p>Friends</p>
											</div>
										) : (
											<></>
										)}
										<div
											className="profile_user_details_right_buttonsec"
											style={{ display: "flex" }}
											onClick={handleNavigateMessage}
										>
											<img src={message} alt="" />
											<p> Message</p>
										</div>

										<div className="profile_user_details_right_buttonsec" style={{ display: "flex" }}>
											<img src={funds} alt="" />
											<p>Send Funds</p>
										</div>
										<div
											onClick={togglePopup}
											className="profile_user_details_right_buttonsec"
											style={{ display: "flex", cursor: "pointer" }}
										>
											<img src={review} alt="" />
											<p>Add Review</p>
										</div>
									</div>
								</div>

								<div className="profile_user_data">
									<div className="profile_user_data_sections">
										<div className="profile_user_data_sections_left" ref={sidebarRef}>
											<div className="profile_user_data_sections_left_container">
												<div className="profile_user_data_sections_left_intro">
													<div className="profile_user_data_sections_left_intro_heading">
														<h4>Intro</h4>
													</div>
													<div className="profile_user_data_sections_left_intro_content">
														<p>
															<span>Profile</span> Digital Creator
														</p>
														<p>Project Manager at lorem ipsum</p>
														<p>Project Manager at lorem ipsum</p>
													</div>
												</div>
												<div className="profile_user_data_sections_left_links">
													<div className="profile_user_data_sections_left_links_heading">
														<h4>Profile Links</h4>
													</div>

													<div className="profile_user_data_sections_left_links_content">
														<p>
															<span>Engineering</span> George Kajaia123
														</p>
														<p>
															<span>Profile</span> George Kajaia456
														</p>
														<p>
															<span>Profile</span> George Kajaia897
														</p>
													</div>
												</div>
												<div className="profile_user_data_sections_left_photos">
													<div className="profile_user_data_sections_left_photos_heading">
														<h4>Photos</h4>
														<span>View all</span>
													</div>
													<div className="profile_user_data_sections_left_photos_container">
														<img src={photo1} alt="photo1" />
														<img src={photo4} alt="photo2" />
														<img src={photo7} alt="photo3" />
														<img src={photo10} alt="photo4" />
														<img src={photo2} alt="photo5" />
														<img src={photo5} alt="photo6" />
														<img src={photo8} alt="photo7" />
														<img src={photo11} alt="photo8" />
														<img src={photo3} alt="photo9" />
														<img src={photo6} alt="photo10" />
														<img src={photo9} alt="photo11" />
														<img src={photo12} alt="photo12" />
													</div>
												</div>
												<div className="profile_user_data_sections_left_friend">
													<div className="profile_user_data_sections_left_friend_heading">
														<h4>Friends</h4>
														<span>
															<NavLink to={"/publicfriendrequest"}>View All</NavLink>
														</span>
													</div>
													<div className="profile_user_data_sections_left_friend_container">
														{userList.slice(0, 12)?.map((data, index) => {
															return (
																<img
																	key={index}
																	src={data?.profilePicture || square_banner}
																	alt="friend1"
																/>
															);
														})}
													</div>
												</div>
											</div>
										</div>
										<div className="profile_user_data_sections_right">
											<div className="profile_user_data_sections_right_tabsection">
												<Tabs>
													<TabList>
														<div className="tablist-custom ">
															<div className="tabnames">
																<Tab>Posts</Tab>
																<Tab>Reviews</Tab>
															</div>
															<div className="tabimage">
																<Tab>
																	<img src={filter} alt="filter" />
																</Tab>
															</div>
														</div>
													</TabList>
													<TabPanel>
														<div className="profile_user_data_sections_right_container">
															{postListAll && postListAll.length > 0 ? (
																postListAll.map((data, index) => (
																	<div key={index}>
																		<PostComponent
																			userId={data?.userId}
																			postid={data?.postId}
																			image={data?.postPicture}
																			heading={"Main"}
																			name={data?.fullName}
																			profile={data?.profilePicture}
																			content={data?.content}
																			likes={data?.likes}
																			Comments={data?.comments}
																			currentUserLikeStatus={data?.currentUserLikeStatus}
																		/>
																	</div>
																))
															) : (
																<p className="ptag">{alertMessage}</p>
															)}
														</div>
													</TabPanel>
													<TabPanel>
														<div className="profile_user_data_sections_right_container">
															{reviewListAll && reviewListAll.length > 0 ? (
																reviewListAll.map((data, index) => (
																	<div key={index}>
																		<ReviewComponent
																			isCurrentUser={data?.isCurrentUser}
																			profile={data?.ReviewerProfilePicture}
																			heading={"Main"}
																			name={data?.ReviewerFullName}
																			commentimage={data?.UserProfilePicture}
																			commenttitle={data?.Reply}
																			review={data?.Review}
																			stars={data?.stars}
																		/>
																	</div>
																))
															) : (
																<p className="ptag">{alertReviewMessage}</p>
															)}
														</div>
													</TabPanel>
												</Tabs>
											</div>
											<Modal open={isPopupVisible} onClose={togglePopup}>
												<RatingWrapper>
													<Rating
														name="simple-controlled"
														value={starRating}
														onChange={(event, newValue) => {
															setStarRating(newValue);
														}}
													/>
													{isMobile && (
														<Fragment>
															<PrimaryBtn
																label={"Post Review"}
																onClick={handlePostReview}
																overrides={{
																	borderRadius: "40px",
																	textTransform: "capitalize",
																	width: "max-content",
																	fontSize: "16px",
																	fontWeight: 300,
																}}
															/>
														</Fragment>
													)}
												</RatingWrapper>
												<ReviewWrapper>
													<Review>
														{reviewGPT ? (
															reviewGPT
														) : (
															<Fragment>
																Chatbot refines reviews for politeness. Rate {profileData?.name}{" "}
																with stars and write your review in the prompt below.
															</Fragment>
														)}
													</Review>
													<BtnWrapper>
														{!isMobile && (
															<Fragment>
																<PrimaryBtn
																	label={"Cancel"}
																	onClick={handleCancelReview}
																	overrides={{
																		borderRadius: "40px",
																		textTransform: "capitalize",

																		fontSize: "16px",
																		fontWeight: 300,
																	}}
																/>
																<PrimaryBtn
																	label={"Post Review"}
																	onClick={handlePostReview}
																	overrides={{
																		borderRadius: "40px",
																		textTransform: "capitalize",
																		width: "max-content",
																		fontSize: "16px",
																		fontWeight: 300,
																	}}
																/>
															</Fragment>
														)}
													</BtnWrapper>
												</ReviewWrapper>
												<InputWrapper>
													<Input
														name={"review"}
														placeholder={`${isLoginUserDetails?.name} Review`}
														onChange={handleReviewChange}
														noMargin
														overrides={{
															".MuiInputBase-root": {
																borderRadius: "40px !important",
															},
															".MuiInputBase-input": {
																paddingBlock: "8px !important",
															},
														}}
													/>
													<PrimaryBtn
														noHoverEffect
														label={<Rocket />}
														onClick={handleReviewGPT}
														overrides={{
															borderRadius: "40px",
															paddingInline: "24px",
														}}
														loading={isResponse}
													/>
												</InputWrapper>
											</Modal>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						{/* <Box sx={style}> */}
						<Typography id="modal-modal-description">{alertReviewMessage}</Typography>
						{/* <div
								style={{
									display: "flex",
									justifyContent: "space-evenly",
									marginTop: "1vw",
								}}
								className="modal_button"
							>
								<button className="modalbutton" onClick={handleClose}>
									OK
								</button>
							</div> */}
						<PrimaryBtn
							label={"Ok"}
							onClick={handleClose}
							fulWidth={false}
							overrides={{
								borderRadius: "40px",
								paddingInline: "24px",
								fontSize: "16px",
								fontWeight: 300,
							}}
						/>

						{/* </Box> */}
					</Modal>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default PublicProfile;
