import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../../../components/Investment/Forms/Signup";
import SignUpForm from "../../../../components/Investment/Forms/Signup/Individual";
import Skills from "../../../../components/Investment/Forms/Signup/Individual/Skills.jsx";

const FreelancerSignUp = () => {
	const navigate = useNavigate();
	const [signUpStage, setSignUpStage] = useState(1);
	const [formValues, setFormValues] = useState({});
	const handleSubmit = (values) => {
		setFormValues({ ...formValues, ...values });
		setSignUpStage(2);

		if (signUpStage === 2) {
			navigate("/investment/microblogging");
		}
	};
	return (
		<SignUp
			userType="Individual Investor"
			onSubmit={handleSubmit}
			onBack={signUpStage === 2 ? () => setSignUpStage(1) : undefined}
		>
			{signUpStage === 1 ? <SignUpForm /> : <Skills />}
		</SignUp>
	);
};

export default FreelancerSignUp;
