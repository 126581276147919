import React, { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileComponent from "../../ProfileComponent/ProfileComponent";
import { connect } from "react-redux";

const Profiles = ({ updateUserData, id, open, anchorEl, handleClose, updateProfilesData }) => {
	const navigate = useNavigate();
	const [selectedProfileId, setSelectedProfileId] = useState();
	const location = useLocation();
	const ModuleName = location?.pathname.split("/")[1];
	const queryClient = useQueryClient();

	const { data, isLoading } = useQuery({
		queryKey: ["AgricultureProfiles"],
		queryFn: async () => {
			const response = await query("GET", "AG_PROFILE", "getUserAllAgricultureAccount");
			const { status, data } = response;
			updateProfilesData(data);
			return data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	const { data: lastAgricultureProfile } = useQuery({
		queryKey: ["LastAgricultureProfile"],
		queryFn: async () => {
			const response = await query("GET", "AG_PROFILE", "getUserLastAgricultureProfile");
			const { status, data } = response;
			updateUserData(data);
			return data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	const updateLatestAgricultureAccount = useMutation({
		mutationFn: async (payload) => {
			try {
				const response = await query("PUT", "AG_PROFILE", "updateLastAgricultureProfile", payload);
				return response.data;
			} catch (error) {
				console.error("Error:", error.message);
				throw error;
			}
		},
		onSettled: () => {
			queryClient.invalidateQueries("AgricultureProfiles");
		},
	});

	useEffect(() => {
		if (data && lastAgricultureProfile) {
			const filteredData = data.filter((user) => user?.agricultureId === lastAgricultureProfile?.agricultureId);
			setSelectedProfileId(filteredData[0]?.agricultureId);
		}
	}, [data, lastAgricultureProfile]);

	const handleUpdateProfile = (updateId) => {
		if (updateId) {
			updateLatestAgricultureAccount.mutateAsync({ AgricultureId: updateId });
		}
	};
	const toggleAddAccount = () => {
		navigate("/agriculture");
		handleClose();
	};

	const handleNavigateProfile = (user) => {
		if (user) {
			updateUserData(user);
			const profile = user.accountType.toLowerCase();
			if (ModuleName && profile) {
				navigate(`/${ModuleName}/${profile}/profile/private`, { state: { userData: user } });
				handleClose();
			}
		}
	};

	return (
		<ProfileComponent
			isLoading={isLoading}
			open={open}
			id={id}
			handleClose={handleClose}
			anchorEl={anchorEl}
			usersData={data}
			toggleAddAccount={toggleAddAccount}
			handleNavigateProfile={handleNavigateProfile}
			handleUpdateProfile={handleUpdateProfile}
			selectedProfileId={selectedProfileId}
			keyName={"agricultureId"}
			typeKeyName={"accountType"}
		/>
	);
};

const mapPropsToState = (dispatch) => ({
	updateUserData: (data) =>
		dispatch({
			type: "UPDATE_AG_USER_DATA",
			payload: data,
		}),
	updateProfilesData: (data) =>
		dispatch({
			type: "UPDATE_AG_ALL_PROFILES",
			payload: data,
		}),
});
export default connect(undefined, mapPropsToState)(Profiles);
