import React from "react";
import Heading from "../../components/Heading/Heading";
import icon from "../../assets/icons/engineering.png";
import engineering from "../../assets/images/Engineering.jpg";
import "./Engineering.scss";
import { Box, Modal, Typography } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	boxShadow: 24,
	p: 4,
};

const Engineering = () => {
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);
	const navigate = useNavigate();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const handleNav = () => {
		navigate("/engineering-signup");
	};
	return (
		<>
			{token ? (
				<div className="engineering">
					<Heading icon={icon} title={"Engineering"} showSearchInput={false} />
					<div className="engineering_container">
						<div
							className="engineering_container_content"
							style={{
								backgroundImage: `url(${engineering})`,
								backgroundSize: "cover",
								backgroundRepeat: "no-repeat",
								backgroundPosition: "right top",
							}}
						>
							<div className="engineering_container_content_data">
								<div className="engineering_container_content_data_sections">
									<div className="engineering_container_content_data_sections_top">
										<h3>Unlock Engineering Excellence for your Business</h3>
									</div>
									<div className="engineering_container_content_data_sections_middle">
										<h3>
											Connect with Top Engineering Talent! Discover a seamless way to hire freelance
											engineers tailored to your project needs. Let's bring your vision to life, efficiently
											and expertly.
										</h3>
									</div>
									<div className="engineering_container_content_data_sections_bottom">
										<div
											className="engineering_container_content_data_sections_bottom_data"
											onClick={handleOpen}
										>
											<p>Post a job and</p>
											<p className="hire">hire a pro</p>
											<p className="talent">Talent Marketplace&nbsp;&nbsp;&rarr;</p>
										</div>
										<div
											className="engineering_container_content_data_sections_bottom_data"
											onClick={handleOpen}
										>
											<p>Get advice from an</p>
											<p className="hire">industry expert</p>
											<p className="talent">consultations&nbsp;&nbsp;&rarr;</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<Modal
						open={open}
						onClose={handleClose}
						aria-labelledby="modal-modal-title"
						aria-describedby="modal-modal-description"
					>
						<Box sx={style}>
							<Typography id="modal-modal-description" sx={{ fontSize: "1.4vw" }}>
								For a seamless experience and to access all features,please create a username specially dedicated
								to engineering. Dive deeper, connect better, and harness the complete power of our platform.
							</Typography>
							<div
								style={{
									display: "flex",
									justifyContent: "space-evenly",
									marginTop: "2vw",
								}}
								className="modal_button"
							>
								<button className="modalbutton" onClick={handleClose}>
									Cancel
								</button>
								<button className="modalbutton" onClick={handleNav}>
									Continue
								</button>
							</div>
						</Box>
					</Modal>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default Engineering;
