import styled from "@emotion/styled";

const Title = styled.h2`
	color: #000;

	/* font-weight-bold-xl */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const Info = styled.div`
	color: #000;

	/* font-weight-medium-lg */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	& span {
		color: #696a68;
	}
`;

const Description = styled.p`
	color: #3d3d3d;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	margin-block: 24px;
`;

export { Title, Info, Description };
