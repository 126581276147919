import React, { useContext, useEffect, useState } from "react";
import "./HomeHeader.scss";
import message from "../../assets/icons/messagem.png";
import home from "../../assets/icons/homem.png";
import chat from "../../assets/icons/chatbot.png";
import search from "../../assets/images/icons/search.png";
import notifications from "../../assets/images/icons/notifications.png";
import settings from "../../assets/images/icons/settings.png";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import searchbar from "../../assets/images/icons/searchbar-img.png";
import Buzzgalactic from "../../assets/icons/buzzgalactic/logo.svg";
import Messaging from "../../assets/icons/Messages";
import Messages from "../../assets/icons/Messages";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import { GetUserNotifications } from "../../utils/apiCalls";
import { color } from "../../styles/variables";
import SidenavMoboleComponent from "../SidenavMobileComponent";
import io from "socket.io-client";
import { UserContext } from "../../store/UserDetailsStore";
import defaultProfile from "../../assets/icons/user-profile.png";
import loader from "../../assets/icons/loading.png";
import Notifications from "../Notifications";
import Home from "../../assets/icons/Home";
import Banking from "../../assets/icons/banking/Banking";
import Chat from "../../assets/icons/Chat";
import HomeActive from "../../assets/icons/HomeActive";
import MessagesActive from "../../assets/icons/MessagesActive";
import BankingActive from "../../assets/icons/BankingActive";
import ChatActive from "../../assets/icons/ChatActive";
import { connect } from "react-redux";
import { LogoContainer } from "../Main/Header/index.styled";

const iconsMapping = {
	home: <Home />,
	chatbot: <Chat />,
	messaging: <Messaging />,
};

const ChatEventEnum = Object.freeze({
	CONNECTED_EVENT: "connected",
	DISCONNECT_EVENT: "disconnect",
	JOIN_CHAT_EVENT: "joinChat",
	LEAVE_CHAT_EVENT: "leaveChat",
	UPDATE_GROUP_NAME_EVENT: "updateGroupName",
	MESSAGE_RECEIVED_EVENT: "messageReceived",
	NEW_CHAT_EVENT: "newChat",
	SOCKET_ERROR_EVENT: "socketError",
	STOP_TYPING_EVENT: "stopTyping",
	TYPING_EVENT: "typing",
	ADD_NOTIFICATION_EVENT: "addNotification",
	REMOVE_NOTIFICATION_EVENT: "removeNotification",
});

const HomeHeader = ({ page = "main", logout }) => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [notificationsOpen, setNotificationsOpen] = useState(false);
	const [settingsOpen, setSettingsOpen] = useState(false);
	const [isSearch, setIsSearch] = useState(false);
	const [isLoader, setIsLoader] = useState([]);
	const [notification, setNotification] = useState([]);
	const [activePath, setActivePath] = useState(page);
	const [socket, setSocket] = useState(null);
	const { isLoginUserDetails } = useContext(UserContext);
	const [hasNewNotifications, setHasNewNotifications] = useState(false);
	const [hasNewMessage, setHasNewMessage] = useState(false);
	const [isActive, setIsActive] = useState(false);

	const { pathname } = useLocation();
	useEffect(() => {
		const newSocket = io("https://api-messaging.buzzgalactic.com", {
			withCredentials: true,
			extraHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});

		newSocket.on("connect", () => {});

		newSocket.on(ChatEventEnum.MESSAGE_RECEIVED_EVENT, (message) => {
			setHasNewMessage(true);
		});

		newSocket.on(ChatEventEnum.ADD_NOTIFICATION_EVENT, (notification) => {
			// setNotification((prevNotifications) => [...prevNotifications, notification]);
			setHasNewNotifications(true);
			getAllNotificationData();
		});

		newSocket.on(ChatEventEnum.REMOVE_NOTIFICATION_EVENT, (notificationToRemove) => {
			setNotification((prevNotifications) =>
				prevNotifications.filter((n) => n.notificationId !== notificationToRemove.notificationId),
			);
		});

		setSocket(newSocket);

		return () => {
			newSocket.off(ChatEventEnum.ADD_NOTIFICATION_EVENT);
			newSocket.off(ChatEventEnum.REMOVE_NOTIFICATION_EVENT);
			newSocket.off(ChatEventEnum.MESSAGE_RECEIVED_EVENT);
			newSocket.disconnect();
		};
	}, []);

	useEffect(() => {
		if (socket && isLoginUserDetails?.userId) {
			socket.emit(ChatEventEnum.JOIN_CHAT_EVENT, isLoginUserDetails?.userId);
		}
	}, [socket, isLoginUserDetails]);

	const handleSettings = () => {
		setSettingsOpen(!settingsOpen);
	};

	const handleHome = () => {
		navigate("/home");
	};

	const handleLogout = () => {
		localStorage.clear();
		sessionStorage.clear();
		logout();
		navigate("/login");
	};

	const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(
		({ theme }) => ({
			[`& .${tooltipClasses.tooltip}`]: {
				backgroundColor: theme.palette.common.white,
				color: "rgba(0, 0, 0, 0.87)",
				boxShadow: theme.shadows[1],
				fontSize: 11,
			},
		}),
	);

	const handleSearch = () => {
		navigate("/search");
	};

	const checkPath = () => {
		pathname === "/search" ? setIsSearch(true) : setIsSearch(false);
		switch (pathname) {
			case "/chatbot":
				setActivePath("chatbot");
				break;
			case "/home":
				setActivePath("main");
				break;
			case "/publicprofile":
				setActivePath("main");
				break;
			case "/privateprofile":
				setActivePath("main");
				break;
			case "/messaging":
				setActivePath("messaging");
				break;
			case "/banking":
				setActivePath("banking");
				break;
			case "/search":
				setActivePath("search");
				break;
			default:
				setActivePath(null);
		}
	};

	useEffect(() => {
		checkPath();
	}, []);
	useEffect(() => {
		checkPath();
	}, [pathname]);

	const getAllNotificationData = () => {
		setIsLoader(true);
		GetUserNotifications((res) => {
			const { status, data, message } = res;
			if (status === 200) {
				setNotification(res.data.notifications);
				setIsLoader(false);
			} else {
				setNotification([]);
				setIsLoader(false);
			}
		}, []);
	};

	const handleNotificationsToggle = () => {
		getAllNotificationData();
		setNotificationsOpen(!notificationsOpen);
		setHasNewNotifications(false);
		setIsActive(!isActive);
	};

	const handleNotificationClick = (notification) => {
		if (!notification.isFriend) {
			navigate("/privatefriendrequest");
		} else if (notification.isReview) {
			navigate("/privateprofile");
		}
	};

	return (
		<>
			<div className="home-header">
				<div className="home-header_container">
					<div className="home-header_sections">
						<div className="home-header_left">
							<div className="home-header_left_menu">
								<LogoContainer onClick={handleHome}>
									<img src={Buzzgalactic} alt="logo" />
								</LogoContainer>
								<NavLink>
									{typeof iconsMapping[activePath] === "object" ? (
										iconsMapping[activePath]
									) : (
										<img src={iconsMapping[activePath] || home} alt="icon" />
									)}
									<span>{activePath}</span>
								</NavLink>
							</div>
							<div className="home-header_left_mobilemenu">
								<img className="searchimg" src={search} alt="search-icon" onClick={handleSearch} />
								<SidenavMoboleComponent />
							</div>
							<div className="home-header_left_input">
								<TextField
									className="input"
									variant="standard"
									disabled={isSearch}
									onClick={handleSearch}
									sx={{ width: "95%", background: "#fff", borderRadius: "1vw" }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="end">
												<SearchIcon sx={{ width: "0.8vw" }} />
											</InputAdornment>
										),
									}}
								/>
							</div>
						</div>
						<div className="home-header_right">
							<div className="home-header_right_links">
								<ul>
									<li>
										<NavLink to="/home">
											<img src={home} alt="icon" />
											<span>Home</span>
										</NavLink>
									</li>
									<li className="message">
										<NavLink
											to="/messaging"
											onClick={() => {
												setHasNewMessage(false);
											}}
										>
											<img src={message} alt="icon" />
											<span>Messaging</span>
											{hasNewMessage && <span className="message-dot"></span>}
										</NavLink>
									</li>

									<li>
										<NavLink to="/banking">
											<Banking size={18} />
											<span>Banking</span>
										</NavLink>
									</li>
									<li>
										<NavLink to="/chatbot">
											<img src={chat} alt="icon" />
											<span>Chatbot</span>
										</NavLink>
									</li>
									<li className="notifications">
										<NavLink onClick={handleNotificationsToggle}>
											<img src={notifications} alt="icon" />
											{hasNewNotifications && <span className="notification-dot"></span>}
											<span>Notifications</span>
										</NavLink>
										{notificationsOpen && (
											<div className="notifications_dropdown">
												<div className="notifications_dropdown_container">
													<h5>Notifications</h5>
													<div className="notifications_dropdown_content">
														{notification.length === 0 ? (
															<div className="notifications_dropdown_loader">
																{isLoader ? (
																	<img className="loader" src={loader} alt="Loading" />
																) : (
																	<p>Notifications are not available</p>
																)}
															</div>
														) : (
															notification?.map((notification, index) => (
																<div
																	className="notifications_dropdown_section"
																	key={index}
																	onClick={() => handleNotificationClick(notification)}
																>
																	<h6>{"Main"}</h6>
																	<div className="notifications_dropdown_section_content">
																		<div className="notifications_dropdown_section_content_left">
																			<img
																				src={
																					notification?.senderDetails?.profilePicture ??
																					defaultProfile
																				}
																				alt="icon"
																			/>
																		</div>
																		<div className="notifications_dropdown_section_content_right">
																			<p className="">{notification?.content}</p>
																			<p className="date">
																				{notification?.createdAt?.split("T")[0]}
																			</p>
																		</div>
																	</div>
																</div>
															))
														)}
													</div>
												</div>
											</div>
										)}
									</li>
								</ul>
							</div>
							<div className="home-header_right_links2">
								<ul>
									<li>
										<LightTooltip
											PopperProps={{
												disablePortal: true,
											}}
											onClose={() => {}}
											open={"open" || true}
											disableFocusListener
											disableHoverListener
											disableTouchListener
											title={
												<div
													onClick={handleLogout}
													style={{
														width: "5vw",
														display: "flex",
														justifyContent: "flex-start",
														alignItems: "center",
														backgroundColor: "#fff",
														padding: "6px",
														border: "none",
														color: "#256F61",
														borderRadius: "0.5vw",
														cursor: "pointer",
													}}
												>
													<ExitToAppRoundedIcon />
													<span
														style={{
															fontSize: "0.8vw",
															fontWeight: "500",
															marginLeft: "0.5vw",
															cursor: "pointer",
														}}
													>
														Logout
													</span>
												</div>
											}
										>
											<Button>
												<NavLink to={"#"}>
													<img src={settings} alt="icon" />
													<span>Settings</span>
												</NavLink>
											</Button>
										</LightTooltip>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="header_mobile">
				<div
					className="header_mobile_top"
					style={
						(isSearch && {
							justifyContent: "flex-end",
						}) ||
						{}
					}
				>
					{!isSearch && (
						<div className="header_mobile_top_input">
							<img src={searchbar} onClick={handleSearch} alt="searchbar-img" />
						</div>
					)}
					<div className="header_mobile_top_settings">
						<NavLink onClick={handleSettings}>
							<img src={settings} alt="icon" />
						</NavLink>
					</div>
					{settingsOpen && (
						<div className="settings-logout">
							<div onClick={handleLogout} className="settings-logout_contain">
								<ExitToAppRoundedIcon
									sx={{
										color: color("pGreen"),
									}}
								/>
								<span
									style={{
										fontSize: "12px",
										fontWeight: "400",
										marginLeft: "0.5vw",
										cursor: "pointer",
										color: color("pGreen"),
									}}
								>
									Logout
								</span>
							</div>
						</div>
					)}
				</div>

				<div className="header_mobile_bottom">
					<ul>
						<li>
							<NavLink
								to="/home"
								activeClassName="active"
								className={!isActive && activePath === "main" && "show-active"}
							>
								{!isActive && activePath === "main" ? <HomeActive /> : <Home />}
							</NavLink>
						</li>
						<li className="message">
							<NavLink
								to="/messaging"
								onClick={() => {
									setHasNewMessage(false);
								}}
								activeClassName={"active"}
								className={!isActive && "show-active"}
							>
								{!isActive && activePath === "messaging" ? <MessagesActive /> : <Messages />}
								{hasNewMessage && <span className="message-dot"></span>}
							</NavLink>
						</li>
						<li>
							<NavLink to="/banking" activeClassName="active" className={!isActive && "show-active"}>
								{!isActive && activePath === "banking" ? <BankingActive /> : <Banking />}
							</NavLink>
						</li>
						<li>
							<NavLink to="/chatbot" activeClassName="active" className={!isActive && "show-active"}>
								{!isActive && activePath === "chatbot" ? <ChatActive /> : <Chat />}
							</NavLink>
						</li>
						<li className="notifications">
							<div activeClassName="active" className={isActive && "active"} onClick={handleNotificationsToggle}>
								<Notifications isActive={isActive} notified={notification} hasNew={hasNewNotifications} />
							</div>
						</li>
					</ul>
				</div>
			</div>
		</>
	);
};

const mapStateToProps = (state) => state;

const mapPropsToState = (dispatch) => ({
	logout: () =>
		dispatch({
			type: "LOGOUT",
		}),
});

export default connect(mapStateToProps, mapPropsToState)(HomeHeader);
