import React from "react";
import "./TermsAndConditions.scss";
import logo from "../../assets/icons/terms_conditions.png";
import HeaderPrivacy from "../PrivacyPolicy/HeaderPrivacy";

const TermsAndConditions = () => {
	return (
		<>
			<HeaderPrivacy icon={logo} title={"Terms and Conditions"} />
			<div className="tac">
				<div className="tac_container">
					<div className="tac_container_data">
						<h4>Terms & Conditions</h4>
						<p>
							Welcome to Buzzgalactic, where we develop technologies and services that empower people to connect,
							build communities, and grow businesses. These Terms govern your usage of Buzzgalactic's products,
							features, apps, services, and technologies that we offer." Please note that these Products are
							provided to you by Buzzgalactic.
						</p>
						<p>
							By using our Products, you agree to our practice of displaying ads that we believe may be relevant to
							you and your interests. To determine which personalized ads to show you, we use your personal data.
						</p>
						<p>
							Our Privacy Policy provides in-depth information about how we collect and utilize your personal data,
							including the criteria for selecting the ads you encounter, as well as details on all the other
							services we offer. You can also visit the settings pages of the relevant Buzzgalactic Product at any
							time to review your privacy choices and preferences regarding how we use your data. Your privacy
							matters to us, and we are committed to transparency and providing you with control over your data.
						</p>
						<p>
							Our mission at Buzzgalactic is to empower individuals and communities, bringing the world closer
							together. To achieve this mission, we offer a range of products and services to enhance your
							experience:
						</p>
						<p>
							<span>Personalized Experience: </span> We tailor your experience to make it more personal and
							engaging.
						</p>
						<p>
							<span>Connecting with Others: </span> Our platform connects you with people and organizations that
							hold significance to you.
						</p>
						<p>
							<span>Self-Expression: </span> We provide tools that empower you to express yourself and engage in
							meaningful communication on topics that matter to you.
						</p>
						<p>
							<span>Content Discovery: </span> We help you discover content, products, and services that align with
							your interests.
						</p>
						<p>
							<span>Safety and Security: </span> We actively promote the safety, security, and integrity of our
							services, working to combat harmful conduct and ensure the safety of our user community.
						</p>
						<p>
							<span>Advanced Technologies: </span> We use and develop cutting-edge technologies to deliver safe and
							functional services to everyone.
						</p>
						<p>
							<span>Service Enhancement: </span> We continuously research ways to improve our services, making them
							more valuable and user-friendly.
						</p>
						<p>
							<span>Consistency Across Products: </span> We strive to provide consistent and seamless experiences
							across the range of Meta Company Products.
						</p>
						<p>
							<span>Access to Our Services: </span> We are committed to ensuring access to our services so that
							individuals and communities can benefit from the opportunities we offer.
						</p>
						<p>
							Our focus is on building a safe, engaging, and connected environment for our users, and these services
							are designed to help advance our mission.
						</p>
						<h4>Other Terms and Policies that may apply to you</h4>
						<p>
							<span>Community Standards: </span> Our Community Standards outline the rules and expectations for
							behavior on our platform. They provide guidelines for respectful and responsible interaction within
							the community.
						</p>
						<p>
							<span>Commercial Terms: </span> If you engage in any commercial activities on our platform, our
							Commercial Terms govern those activities. These terms pertain to businesses, advertisers, and other
							entities using our services for commercial purposes.
						</p>
						<p>
							<span>Community Payment Terms: </span> If you make or receive payments within our platform, our
							Community Payment Terms set out the rules and conditions for such transactions.
						</p>
						<p>
							<span>Commerce Policies: </span> For those involved in e-commerce and selling products or services
							through our platform, our Commerce Policies specify the rules and guidelines to ensure a fair and safe
							trading environment.
						</p>
						<p>
							<span>Recommendations Guidelines: </span> Our Recommendations Guidelines provide insight into how
							content and recommendations are generated and presented to users. Understanding these guidelines can
							help you optimize your experience on our platform.
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default TermsAndConditions;
