import Coder from "../../../assets/images/singnup/coder.png";
import Input from "../../Inputs/Input";
import { Checkbox, FormControlLabel } from "@mui/material";
import PrimaryBtn from "../../Buttons/Primary/index";
import SecondaryBtn from "../../Buttons/Secondary/index";
import Google from "../../../assets/icons/Google";
import { useNavigate } from "react-router-dom";
import { UserGoogleLogin } from "../../../utils/apiCalls";
import { useFormik } from "formik";
import * as yup from "yup";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import { useState } from "react";
import {
	Wrapper,
	LeftWrapper,
	InfoWrapper,
	InfoTitle,
	Desc,
	Form,
	FormTitle,
	TermsAndConditions,
	Divider,
	DividerText,
	GoogleLogin,
	Login,
} from "./index.styled";

const validationSchema = yup.object({
	firstName: yup
		.string()
		.matches(/^[A-Za-z]+$/, "First Name should only contain letters")
		.min(5, "First Name should be at least 5 characters long")
		.max(10, "First Name should be at most 10 characters long")
		.required("First Name is required"),
	lastName: yup
		.string()
		.matches(/^[A-Za-z]+$/, "First Name should only contain letters")
		.min(5, "Last Name should be at least 5 characters long")
		.max(10, "Last Name should be at most 10 characters long")
		.required("Last Name is required"),
	email: yup.string().email("Enter a valid email").required("Email is required"),
	password: yup.string().min(8, "Password should be at least 8 characters long").required("Password is required"),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("Confirm Password is required"),
	termsAndConditions: yup.boolean().oneOf([true], "You must agree to the terms and conditions"),
});

const SignUpForm = ({ onSubmit, showLoader }) => {
	const navigate = useNavigate();
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const handleGoogle = () => {
		UserGoogleLogin((res) => {});
		const googleAuthUrl =
			"https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&redirect_uri=https%3A%2F%2Fapi.buzzgalactic.com%2Fapi%2Fv1%2Flogin%2Fgoogle%2Fredirect&scope=email%20profile&client_id=363715705998-g1j4omj303i94ao4j5751e0nkvgn0atv.apps.googleusercontent.com&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow";
		window.open(googleAuthUrl, "_self");
	};
	const handleLogin = () => {
		navigate("/login");
	};

	const handleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};
	const handleShowConfirmPassword = () => {
		setShowConfirmPassword((prev) => !prev);
	};

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			email: "",
			password: "",
			confirmPassword: "",
			termsAndConditions: false,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			let payload = {
				firstName: values?.firstName,
				lastName: values?.lastName,
				email: values?.email,
				password: values?.password,
			};
			onSubmit(payload);
		},
	});

	return (
		<Wrapper>
			<LeftWrapper>
				<InfoWrapper>
					<InfoTitle>Free Membership, Unlimited Possibilities</InfoTitle>
					<Desc>Signing up on Buzzgalactic is always free.</Desc>
				</InfoWrapper>
				<img src={Coder} alt="coder" />
			</LeftWrapper>
			<Form onChange={formik.handleChange} onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
				<FormTitle>Sign up through your Email</FormTitle>
				<Input
					name={"firstName"}
					placeholder={"Enter your first name"}
					required
					error={formik.touched.firstName && Boolean(formik.errors.firstName)}
					helperText={formik.touched.firstName && formik.errors.firstName}
				/>
				<Input
					name={"lastName"}
					placeholder={"Enter your last name"}
					required
					error={formik.touched.lastName && Boolean(formik.errors.lastName)}
					helperText={formik.touched.lastName && formik.errors.lastName}
				/>
				<Input
					name={"email"}
					type="email"
					placeholder={"Enter your email address"}
					required
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
				/>
				<Input
					name={"password"}
					placeholder={"Password"}
					type={showPassword ? "text" : "password"}
					required
					error={formik.touched.password && Boolean(formik.errors.password)}
					helperText={formik.touched.password && formik.errors.password}
					inputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton aria-label="toggle password visibility" edge="end" onClick={handleShowPassword}>
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<Input
					name={"confirmPassword"}
					placeholder={"Re-password"}
					type={showConfirmPassword ? "text" : "password"}
					required
					error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
					helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
					inputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									edge="end"
									onClick={handleShowConfirmPassword}
								>
									{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						),
					}}
				/>
				<TermsAndConditions>
					<FormControlLabel
						sx={{
							width: "max-content",
							color: "#8C8A8A",
							".MuiTypography-root, .MuiFormControlLabel-asterisk": {
								fontSize: "13px",
							},
							"@media screen and (max-width: 768px)": {
								width: "-webkit-fill-available",
							},
						}}
						control={<Checkbox name="termsAndConditions" required />}
						label={
							<span style={{ fontSize: "13px" }}>
								I agree to the{" "}
								<a
									href="/privacy-policy"
									target="_blank"
									style={{ fontSize: "13px", textDecorationLine: "underline", fontWeight: "600" }}
								>
									Privacy Policy
								</a>{" "}
								&amp;{" "}
								<a
									href="/terms-and-conditions"
									target="_blank"
									style={{ fontSize: "13px", textDecorationLine: "underline", fontWeight: "600" }}
								>
									Terms and Conditions
								</a>
							</span>
						}
					/>
					<PrimaryBtn
						label={"Sign up"}
						type="submit"
						loading={showLoader}
						overrides={{
							borderRadius: "8px",
							paddingInline: "36px",
							"@media screen and (max-width: 768px)": {
								width: "100%",
								fontSize: "16px",
								paddingBlock: "8px",
							},
						}}
					/>
				</TermsAndConditions>
				<Divider>
					<DividerText>Signup with</DividerText>
				</Divider>
				<GoogleLogin>
					<SecondaryBtn
						label={
							<>
								<Google />
								Google
							</>
						}
						onClick={handleGoogle}
						overrides={{
							width: "85%",
							borderRadius: "20px",
							border: "1px solid #000",
							backgroundColor: "#fff",
							textTransform: "none",
							color: "#000",
							fontFamily: "Inter",
							fontSize: "16px",
							fontStyle: "normal",
							fontWeight: 500,
							lineHeight: "137%" /* 24.66px */,
							marginBlock: "18px",
							paddingBlock: "10px",
							"&:hover": {
								backgroundColor: "#fff",
							},

							"& > svg": {
								marginInlineEnd: "12px",
							},
						}}
					/>
				</GoogleLogin>
				<Divider />
				<Login>
					Already have an account? <span onClick={handleLogin}>login here</span>
				</Login>
			</Form>
		</Wrapper>
	);
};

export default SignUpForm;
