import LeftSubNav from "../../../components/LeftSubNav";
import Page from "../../../layouts/Page";
import { LeftNav, ProfileSection } from "./index.styled";
import ProfileDetails from "../../../components/TV/Profile";
import useLayout from "../../../utils/hooks/useLayout";

const Profile = () => {
	const isMobile = useLayout();
	return (
		<Page
			overrides={
				isMobile && {
					"@media screen and (max-width: 768px)": {
						paddingBlockEnd: "40px",
					},
				}
			}
		>
			<LeftNav>
				<LeftSubNav post={false} videos={true} support={false} />
			</LeftNav>
			<ProfileSection>
				<ProfileDetails />
			</ProfileSection>
		</Page>
	);
};

export default Profile;
