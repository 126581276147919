import React from "react";
import location from "../../../../../assets/images/icons/location.png";
import { Navigate, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/IT/itprofile1.png";
import editable from "../../../../../assets/images/editable.png";
import editablenoborder from "../../../../../assets/images/editablenoborder.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent";
import Button from "../../../../../components/button/index.js";
import message from "../../../../../assets/icons/mi_message.png";
import outline from "../../../../../assets/icons/material-symbols_rate-review-outline.png";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
} from "../../../styles/index.styled.js";
import { ProfilesWrapper } from "../../../../Creativity/styles/index.styled.js";

const ProfilePrivate = () => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");

	const navigate = useNavigate();
	const handlePublicProfileNav = (path) => {
		return;
		navigate(`/${path}`);
	};

	return (
		<>
			{token ? (
				<Wrapper>
					<ProfileWrapper>
						<ProfileSections>
							<SectionTitle>Company</SectionTitle>
							<Profile>
								<ProfileDetailsWrapper>
									<ProfileImageWrapper>
										<img src={ProfilePicture} alt="profile" />
										<img src={editable} className="imgedit" alt="profile" />
									</ProfileImageWrapper>
									<UserDetailsWrapper>
										<UserNameWrapper>
											<UserName>Paychex</UserName>
											<img
												src={editablenoborder}
												className="imgedit"
												alt="profile"
												style={{
													display: "block",
												}}
											/>
										</UserNameWrapper>
										<UserAddress>
											<img src={location} alt="location-icon" />
											United States, India - 10:46 am local time <br />
											Human Resources Services Payroll Software Rochester, NY
											<br />
											10,001+ employees
											<br />
											www.paychex.com
										</UserAddress>
									</UserDetailsWrapper>
								</ProfileDetailsWrapper>
								<UserProfileActions>
									<Button buttonClick={() => handlePublicProfileNav("message")}>
										<img src={message} alt="message" />
										Message
									</Button>
									<Button buttonClick={() => handlePublicProfileNav("review")}>
										<img src={outline} alt="review" />
										Review
									</Button>
								</UserProfileActions>
							</Profile>

							<MoreDetailWrapper>
								<ProfilesAndAboutWrapper>
									<ProfilesWrapper>
										<Profiles fillAvailable>
											<ProfileHeading>Other Profiles</ProfileHeading>
											<ProfileList>
												<ProfileName>
													<ProfileUserName>Engineering</ProfileUserName> George Kajaia123
												</ProfileName>
												<ProfileName>
													<ProfileUserName>Education</ProfileUserName> George Kajaia456
												</ProfileName>
												<ProfileName>
													<ProfileUserName>Ecommerce</ProfileUserName> George Kajaia897
												</ProfileName>
											</ProfileList>
										</Profiles>
									</ProfilesWrapper>
									<About card>
										<AboutTitle>
											About <img src={editablenoborder} alt="" />
										</AboutTitle>
										<AboutDesc noClamp>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
											exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
											dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
											ExceLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
											exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
											dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
											ExceLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
											exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
											dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
											Exce
										</AboutDesc>
									</About>
								</ProfilesAndAboutWrapper>
								<FeedbackWrapper>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment1}
											commentimagestyle={{
												marginLeft: "2rem",
											}}
											commenttitle="Thanks"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment}
											commenttitle="Reply"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
								</FeedbackWrapper>
							</MoreDetailWrapper>
						</ProfileSections>
					</ProfileWrapper>
				</Wrapper>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default ProfilePrivate;
