import styled from "@emotion/styled";
import { BottomNavigationAction } from "@mui/material";
import { color } from "../../../styles/variables";

const NavAction = styled(BottomNavigationAction)`
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 0;
	min-width: auto;
	&.Mui-selected {
		color: #fff;
		background-color: ${color("pGreen")};
	}
`;

export { NavAction };
