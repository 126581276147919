const Index = ({ color = "#696969", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
			<path
				d="M19.825 10.4503V18.3253M19.825 18.3253V22.0003H6.175V18.3253M19.825 18.3253H23.5V10.4503C23.5 8.87527 22.45 7.03777 20.875 5.72527C19.3 4.41277 17.2 4.15027 17.2 4.15027L13 8.35027M6.175 18.3253H2.5V10.4503C2.5 8.87527 3.55 7.03777 5.125 5.72527C6.7 4.41277 8.8 4.15027 8.8 4.15027L13 8.35027M6.175 18.3253V10.4503M13 8.35027V13.0753"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.12507 5.725C6.70007 4.4125 8.80007 4.15 8.80007 4.15L13.0001 8.35L17.2001 4.15C17.2001 4.15 19.3001 4.4125 20.8751 5.725L21.9251 3.3625L20.8751 1H5.12507L4.07507 3.3625L5.12507 5.725Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Index;
