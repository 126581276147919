const OnlineConnections = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
		<circle cx="46" cy="46" r="45" stroke="#256E61" strokeWidth="2" />
		<path
			d="M70.2362 43.6985C72.1822 43.6985 73.7654 42.1153 73.7654 40.1682V25.2326C73.7654 24.5823 73.239 24.0553 72.5881 24.0553H50.5318C49.8815 24.0553 49.3545 24.5817 49.3545 25.2326V42.5217C49.3545 43.1721 49.8809 43.6991 50.5318 43.6991H57.7697V45.1781H54.7793C54.1289 45.1781 53.6019 45.7056 53.6019 46.3565C53.6019 47.0058 54.1284 47.5339 54.7793 47.5339H58.9471H64.1745H68.3435C68.9938 47.5339 69.5208 47.0063 69.5208 46.3565C69.5208 45.7062 68.9944 45.1781 68.3435 45.1781H65.3519V43.6991L70.2362 43.6985ZM51.7086 37.4451H69.7148C70.3651 37.4451 70.8921 36.9176 70.8921 36.2667C70.8921 35.6174 70.3657 35.0893 69.7148 35.0893H51.7086V26.4094H71.4113V40.1682C71.4113 40.8163 70.8848 41.3433 70.2362 41.3433H64.1745H58.9471H51.7092L51.7086 37.4451ZM62.9972 45.1775H60.1239V43.6985H62.9972V45.1775Z"
			fill="#256E61"
		/>
		<path
			d="M61.5621 40.6061C61.8733 40.6061 62.1755 40.4833 62.3913 40.2568C62.6083 40.0409 62.7406 39.7388 62.7406 39.4287C62.7406 39.1176 62.6083 38.8154 62.3913 38.599C61.9591 38.1566 61.1669 38.1566 60.7234 38.599C60.5075 38.8159 60.3848 39.1181 60.3848 39.4287C60.3848 39.7388 60.5075 40.0409 60.7234 40.2568C60.9499 40.4839 61.251 40.6061 61.5621 40.6061Z"
			fill="#256E61"
		/>
		<path
			d="M24.5369 65.2743H30.4399V66.5279C30.4399 67.1783 30.9663 67.7064 31.6172 67.7064H36.5357C37.1861 67.7064 37.7131 67.1788 37.7131 66.5279V65.2743H57.8631V66.5279C57.8631 67.1783 58.389 67.7064 59.0405 67.7064H63.959C64.6082 67.7064 65.1364 67.1788 65.1364 66.5279V65.2743H72.404C73.0543 65.2743 73.5824 64.7468 73.5824 64.097C73.5824 63.4466 73.0549 62.9185 72.404 62.9185H65.1364V61.6649C65.1364 61.0146 64.6088 60.4865 63.959 60.4865H59.0405C58.389 60.4865 57.8631 61.014 57.8631 61.6649V62.9185H37.7131V61.6649C37.7131 61.0146 37.1866 60.4865 36.5357 60.4865H31.6172C30.9669 60.4865 30.4399 61.014 30.4399 61.6649V62.9185H24.5369C23.8865 62.9185 23.3584 63.4461 23.3584 64.097C23.3584 64.7468 23.886 65.2743 24.5369 65.2743ZM60.2173 62.8423H62.7805V65.3511H60.2173V62.8423ZM32.794 62.8423H35.3584V65.3511H32.794V62.8423Z"
			fill="#256E61"
		/>
		<path
			d="M34.0783 59.077C34.7288 59.077 35.2562 58.5496 35.2562 57.8991C35.2562 57.2486 34.7288 56.7212 34.0783 56.7212C33.4278 56.7212 32.9004 57.2486 32.9004 57.8991C32.9004 58.5496 33.4278 59.077 34.0783 59.077Z"
			fill="#256E61"
		/>
		<path
			d="M34.0783 55.3089C34.7288 55.3089 35.2562 54.7815 35.2562 54.131C35.2562 53.4804 34.7288 52.9531 34.0783 52.9531C33.4278 52.9531 32.9004 53.4804 32.9004 54.131C32.9004 54.7815 33.4278 55.3089 34.0783 55.3089Z"
			fill="#256E61"
		/>
		<path
			d="M34.0783 51.5408C34.7288 51.5408 35.2562 51.0135 35.2562 50.3629C35.2562 49.7124 34.7288 49.185 34.0783 49.185C33.4278 49.185 32.9004 49.7124 32.9004 50.3629C32.9004 51.0135 33.4278 51.5408 34.0783 51.5408Z"
			fill="#256E61"
		/>
		<path
			d="M61.4967 56.2508C62.1469 56.2508 62.674 55.7236 62.674 55.0734C62.674 54.4232 62.1469 53.8961 61.4967 53.8961C60.8465 53.8961 60.3193 54.4232 60.3193 55.0734C60.3193 55.7236 60.8465 56.2508 61.4967 56.2508Z"
			fill="#256E61"
		/>
		<path
			d="M61.4967 60.0183C62.1469 60.0183 62.674 59.4912 62.674 58.841C62.674 58.1908 62.1469 57.6636 61.4967 57.6636C60.8465 57.6636 60.3193 58.1908 60.3193 58.841C60.3193 59.4912 60.8465 60.0183 61.4967 60.0183Z"
			fill="#256E61"
		/>
		<path
			d="M61.4963 52.4827C62.1468 52.4827 62.6742 51.9553 62.6742 51.3047C62.6742 50.6542 62.1468 50.1268 61.4963 50.1268C60.8457 50.1268 60.3184 50.6542 60.3184 51.3047C60.3184 51.9553 60.8457 52.4827 61.4963 52.4827Z"
			fill="#256E61"
		/>
		<path
			d="M37.1298 36.7213C36.5792 36.3732 35.8532 36.5363 35.5039 37.0858L34.4168 38.8008H33.8769L32.7899 37.0846C32.4406 36.5358 31.7134 36.3726 31.164 36.7213C30.6151 37.0706 30.452 37.7978 30.8007 38.3472L31.9747 40.1957L30.7693 45.251C30.6179 45.8839 31.0093 46.518 31.6411 46.6688C31.733 46.6907 31.825 46.7008 31.9158 46.7008C32.4479 46.7008 32.9311 46.3386 33.0601 45.7976L34.1505 41.2227L35.2606 45.7931C35.4137 46.425 36.05 46.8124 36.6818 46.6593C37.3148 46.5062 37.7022 45.8688 37.5492 45.2369L36.3225 40.1923L37.4931 38.3472C37.8412 37.7978 37.6781 37.0695 37.1298 36.7213Z"
			fill="#256E61"
		/>
		<path
			d="M24.1773 44.7408C24.8266 44.7408 25.3547 44.2132 25.3558 43.5635C25.3558 43.5405 25.3637 41.239 25.8727 39.538C26.1323 38.6685 27.9729 37.7412 29.1357 37.3885C29.7574 37.2002 30.1089 36.5431 29.9206 35.9213C29.7333 35.2996 29.0796 34.9458 28.4567 35.1342C28.0374 35.2603 24.3405 36.4338 23.6145 38.8636C23.0078 40.8982 23 43.4547 23 43.5623C23 44.2132 23.527 44.7402 24.1773 44.7408Z"
			fill="#256E61"
		/>
		<path
			d="M42.4637 39.5385C42.9117 41.0321 42.9806 43.5673 42.9818 43.592C42.998 44.2328 43.5217 44.7407 44.158 44.7407C44.1681 44.7407 44.1776 44.7407 44.1888 44.7407C44.8381 44.7234 45.3516 44.1835 45.3354 43.5331C45.3331 43.4182 45.2574 40.6587 44.7198 38.8641C43.9926 36.4343 40.2969 35.2603 39.8775 35.1347C39.2557 34.9474 38.6009 35.3001 38.4125 35.9218C38.2253 36.5436 38.5779 37.1995 39.1986 37.389C40.3613 37.7417 42.2042 38.669 42.4637 39.5385Z"
			fill="#256E61"
		/>
		<path
			d="M39.8648 40.7563C39.2156 40.7563 38.6875 41.2827 38.6875 41.9336C38.6875 42.5845 39.2151 43.111 39.8648 43.111H40.3257C40.9749 43.111 41.503 42.5845 41.503 41.9336C41.503 41.2827 40.9755 40.7563 40.3257 40.7563H39.8648Z"
			fill="#256E61"
		/>
		<path
			d="M39.638 27.6883L39.6391 27.5896C39.6414 27.4864 39.6414 27.3839 39.6358 27.2734C39.5898 26.359 39.3117 25.4822 38.8066 24.6675C38.33 23.8978 37.692 23.2659 36.9088 22.7866C36.0998 22.296 35.2257 22.032 34.3158 22.0045L34.1134 22C33.2854 22 32.4842 22.194 31.7374 22.5758C30.7171 23.0916 29.9131 23.8625 29.3468 24.8666C28.8955 25.666 28.6578 26.5092 28.6393 27.371C28.6281 27.9226 28.6533 28.4782 28.7116 29.017C28.8126 29.9611 28.9976 30.7942 29.2768 31.5662C29.6731 32.6567 30.4255 33.6905 31.447 34.5511C31.932 34.9615 32.5341 35.4711 33.5242 35.632C33.7322 35.6662 33.9295 35.6814 34.1168 35.6814C34.974 35.6814 35.6204 35.3422 36.139 35.0103C37.3915 34.2086 38.3738 33.0363 38.9052 31.7098C39.3952 30.4876 39.6346 29.1734 39.638 27.6883ZM37.2839 27.6866C37.2805 28.8639 37.0955 29.8933 36.7187 30.8335C36.3695 31.7086 35.7124 32.4879 34.8703 33.0278C34.3349 33.3693 34.1914 33.3553 33.9032 33.307C33.6021 33.2588 33.4238 33.1394 32.9669 32.752C32.2661 32.1628 31.7425 31.4552 31.4896 30.7634C31.276 30.173 31.1325 29.5193 31.0529 28.7642C31.0047 28.3229 30.985 27.8705 30.9946 27.4209C31.0047 26.9443 31.137 26.4868 31.3977 26.0226C31.7352 25.425 32.1938 24.9843 32.806 24.6759C33.2265 24.4601 33.6559 24.3553 34.1134 24.3553L34.2362 24.3586C34.7486 24.3738 35.2229 24.5184 35.6838 24.7987C36.1536 25.0858 36.5192 25.448 36.8051 25.9088C37.1017 26.3876 37.2598 26.8737 37.2844 27.3861C37.2867 27.4444 37.2844 27.4988 37.2844 27.5537L37.2839 27.6866Z"
			fill="#256E61"
		/>
	</svg>
);

export default OnlineConnections;
