const Index = ({ color = "#5E6B86", size = 18 }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M0 15.0404V16.625C0 17.935 3.19141 19 7.125 19C11.0586 19 14.25 17.935 14.25 16.625V15.0404C12.7174 16.1203 9.91562 16.625 7.125 16.625C4.33437 16.625 1.53262 16.1203 0 15.0404ZM11.875 4.75C15.8086 4.75 19 3.68496 19 2.375C19 1.06504 15.8086 0 11.875 0C7.94141 0 4.75 1.06504 4.75 2.375C4.75 3.68496 7.94141 4.75 11.875 4.75ZM0 11.1477V13.0625C0 14.3725 3.19141 15.4375 7.125 15.4375C11.0586 15.4375 14.25 14.3725 14.25 13.0625V11.1477C12.7174 12.4094 9.91191 13.0625 7.125 13.0625C4.33809 13.0625 1.53262 12.4094 0 11.1477ZM15.4375 11.5559C17.5639 11.1439 19 10.3795 19 9.5V7.91543C18.1391 8.52402 16.8736 8.93965 15.4375 9.1957V11.5559ZM7.125 5.9375C3.19141 5.9375 0 7.26602 0 8.90625C0 10.5465 3.19141 11.875 7.125 11.875C11.0586 11.875 14.25 10.5465 14.25 8.90625C14.25 7.26602 11.0586 5.9375 7.125 5.9375ZM15.2631 8.02676C17.4896 7.62598 19 6.83926 19 5.9375V4.35293C17.6826 5.28438 15.4189 5.78535 13.0365 5.9041C14.1312 6.43477 14.9365 7.14727 15.2631 8.02676Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
