import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import EastIcon from "@mui/icons-material/East";
import avator1 from "../../../assets/images/profile/follow/3.png";
import avator2 from "../../../assets/images/profile/follow/2.png";
import avator3 from "../../../assets/images/profile/follow/1.png";
import avator4 from "../../../assets/images/profile/follow/4.png";
import companies1 from "../../../assets/images/IT/companies1.png";
import companies2 from "../../../assets/images/IT/companies2.png";
import companies3 from "../../../assets/images/IT/companies3.png";
import companies4 from "../../../assets/images/IT/companies4.png";
import Slick from "../../../components/Slick";
import {
	Wrapper,
	ScreenTitleWrapper,
	ScreenTitle,
	PostJob,
	Button,
	SectionWrapper,
	SectionTitle,
	CardWrapper,
	IconWrapper,
	OriginCountry,
	Role,
	StackWrapper,
	Rate,
	Stack,
	TechStacks,
	UserDetails,
	UserDetailsWrapper,
	UserImage,
	UserName,
} from "../index.styled";
import { spacing } from "../../../styles/variables";
import useLayout from "../../../utils/hooks/useLayout";

const talents = [
	{
		title: "Freelancers",
		members: [
			{
				id: 1,
				image: avator1,
				name: "Peter Smith",
				country: "United States",
				role: "Full Stack Developer",
				ratePerHour: 8,
				stack: ["laravel Framework", "Codegnitor", "javascript", "jQuery"],
			},
			{
				id: 2,
				image: avator2,
				name: "Jason",
				country: "United States",
				role: "Website Developer",
				ratePerHour: 15,
				stack: ["laravel Framework", "Codegnitor", "javascript", "jQuery"],
			},
			{
				id: 3,
				image: avator3,
				name: "Parth",
				country: "Georgia",
				role: "Python Developer",
				ratePerHour: 18,
				stack: ["laravel Framework", "Codegnitor", "javascript", "Python"],
			},
			{
				id: 4,
				image: avator4,
				name: "Thomas",
				country: "Georgia",
				role: "Java Developer",
				ratePerHour: 25,
				stack: ["laravel Framework", "Codegnitor", "Java"],
			},
			{
				id: 5,
				image: avator1,
				name: "Peter Smith",
				country: "United States",
				role: "Full Stack Developer",
				ratePerHour: 8,
				stack: ["laravel Framework", "Codegnitor", "javascript", "jQuery"],
			},
		],
	},
	{
		title: "Regular Employees",
		members: [
			{
				id: 1,
				image: avator1,
				name: "Peter Smith",
				country: "United States",
				role: "Full Stack Developer",
				ratePerHour: 8,
				stack: ["laravel Framework", "Codegnitor", "javascript", "jQuery"],
			},
			{
				id: 2,
				image: avator2,
				name: "Jason",
				country: "United States",
				role: "Website Developer",
				ratePerHour: 15,
				stack: ["laravel Framework", "Codegnitor", "javascript", "jQuery"],
			},
			{
				id: 3,
				image: avator3,
				name: "Parth",
				country: "Georgia",
				role: "Python Developer",
				ratePerHour: 18,
				stack: ["laravel Framework", "Codegnitor", "javascript", "Python"],
			},
			{
				id: 4,
				image: avator4,
				name: "Thomas",
				country: "Georgia",
				role: "Java Developer",
				ratePerHour: 25,
				stack: ["laravel Framework", "Codegnitor", "Java"],
			},
		],
	},
	{
		title: "Companies",
		members: [
			{
				id: 1,
				image: companies1,
				name: "Kattle Kick",
				country: "United States",
				role: "Web Design & Development",
				stack: ["laravel Framework", "Codegnitor", "javascript", "jQuery"],
			},
			{
				id: 2,
				image: companies2,
				name: "Peter Cock",
				country: "United States",
				role: "Python Development",
				stack: ["laravel Framework", "Codegnitor", "javascript", "jQuery"],
			},
			{
				id: 3,
				image: companies3,
				name: "TLC",
				country: "Georgia",
				role: "I Phone & Android Development",
				stack: ["laravel Framework", "Codegnitor", "javascript", "Python"],
			},
			{
				id: 4,
				image: companies4,
				name: "Marriot Battles",
				country: "Georgia",
				role: "Java Development",
				stack: ["laravel Framework", "Codegnitor", "Java"],
			},
			{
				id: 5,
				image: companies1,
				name: "Kattle Kick",
				country: "United States",
				role: "Web Design & Development",
				stack: ["laravel Framework", "Codegnitor", "javascript", "jQuery"],
			},
		],
	},
	{
		title: "Clients",
		members: [
			{
				id: 1,
				image: avator1,
				name: "Peter Smith",
				country: "United States",
			},
			{
				id: 2,
				image: avator2,
				name: "Jason",
				country: "United States",
			},
			{
				id: 3,
				image: avator3,
				name: "Parth",
				country: "Georgia",
			},
			{
				id: 4,
				image: avator4,
				name: "Thomas",
				country: "Georgia",
			},
		],
	},
];

const HomePage = (props) => {
	const isMobile = useLayout();
	const [isPopupVisible, setPopupVisibility] = useState(false);

	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const handleNav = (title) => {
		switch (title) {
			case "Freelancers":
				navigate("/healthcare/freelancer/profile/public");
				break;
			case "Regular Employees":
				navigate("/healthcare/employee/profile/public");
				break;
			case "Companies":
				navigate("/healthcare/company/profile/public");
				break;
			case "Clients":
				navigate("/healthcare/client/profile/public");
				break;
			default:
				break;
		}
		// navigate("/engineering-freelance-profile");
	};
	const togglePopup = () => {
		setPopupVisibility(!isPopupVisible);
	};

	return (
		<>
			{token ? (
				<Wrapper>
					<ScreenTitleWrapper>
						<ScreenTitle>
							Discover top rated talent{" "}
							<span>
								Browse Talent <EastIcon />
							</span>
						</ScreenTitle>
						<PostJob onClick={togglePopup}>Post a Job +</PostJob>
					</ScreenTitleWrapper>
					{talents?.map((talent) => (
						<SectionWrapper>
							<SectionTitle>{talent?.title}</SectionTitle>
							<Slick colSpace={isMobile ? spacing("sm") : spacing("xl")}>
								{talent?.members?.map((member) => (
									<CardWrapper>
										<UserDetailsWrapper>
											<UserImage src={member?.image} alt="profile" />
											<UserDetails>
												<UserName>{member?.name}</UserName>
												<OriginCountry>{member?.country}</OriginCountry>
											</UserDetails>
											<IconWrapper>
												<FavoriteBorderIcon />
											</IconWrapper>
										</UserDetailsWrapper>
										<StackWrapper>
											<Role>{member?.role}</Role>
											{member?.ratePerHour && (
												<Rate>
													<MonetizationOnOutlinedIcon /> ${member?.ratePerHour}/hr
												</Rate>
											)}
											{member?.stack && (
												<TechStacks>
													{member?.stack?.map((stack) => (
														<Stack>{stack}</Stack>
													))}
												</TechStacks>
											)}
										</StackWrapper>
										<Button onClick={() => handleNav(talent.title)}>View Profile</Button>
									</CardWrapper>
								))}
							</Slick>
						</SectionWrapper>
					))}
				</Wrapper>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapStateToProps = (state) => state;

export default connect(mapStateToProps)(HomePage);
