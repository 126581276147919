import styled from "@emotion/styled";
import { color, spacing, toRem } from "../../../../styles/variables";

const PreviewWrapper = styled.div`
	display: flex;
	align-items: center;
	margin-block-end: ${spacing("md4")};
	max-height: 70dvh;
	overflow: hidden;
`;

const Preview = styled.img`
	width: 100%;
	object-fit: contain;
`;

const ButtonsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-evenly;
`;

const RecordButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
	border-radius: 50%;
	background-color: ${color("dimGrayLight")};
	padding: ${spacing("lg1")};
`;

const RecordButton = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	background-color: ${color("error")};
`;

const SecondaryIconWrapper = styled.div`
	padding: ${spacing("sm2")};
	background-color: ${color("darkGray")};
	border-radius: 50%;
	display: flex;
	color: ${color("pWhite")};

	& > svg {
		font-size: ${toRem(32)};
	}
`;

export { PreviewWrapper, Preview, RecordButtonWrapper, ButtonsWrapper, RecordButton, SecondaryIconWrapper };
