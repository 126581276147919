const Index = ({ color = "#696969", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
			<path
				d="M12.5 9L10.5 9M12.5 6L9.5 6M12.5 12L9.5 12M12.5 18L9.5 18M12.5 15L10.5 15M21.5 3L17.5 3M19.5 3L19.5 21M21.5 21L17.5 21M12.5 19.875C12.5 20.496 11.988 21 11.357 21L5.643 21C5.012 21 4.5 20.496 4.5 19.875L4.5 4C4.5 3.73478 4.60536 3.48043 4.79289 3.29289C4.98043 3.10536 5.23478 3 5.5 3L11.357 3C11.988 3 12.5 3.504 12.5 4.125L12.5 19.875Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Index;
