const Index = ({ color = "#A0A0A0", size = 26 }) => {
	return (
		<svg height={size} viewBox="0 0 17 26" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect width="2.95455" height="10.6364" fill={color} />
			<rect x="6.5" width="3.54545" height="10.6364" fill={color} />
			<rect x="13.5908" width="2.95455" height="10.6364" fill={color} />
			<rect y="15.3635" width="2.95455" height="10.6364" fill={color} />
			<rect x="6.5" y="15.3635" width="3.54545" height="10.6364" fill={color} />
			<rect x="13.5908" y="15.3635" width="2.95455" height="10.6364" fill={color} />
		</svg>
	);
};

export default Index;
