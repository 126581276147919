import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 32px;

	background: #fff;

	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
	padding: 24px;
	margin-block-end: 24px;

	@media screen and (max-width: 768px) {
		margin-inline: 12px;
	}
`;

const SearchWrapper = styled.div`
	cursor: pointer;
	display: flex;
	column-gap: 36px;
	align-items: flex-start;
	@media screen and (max-width: 768px) {
		column-gap: 16px;
	}
`;

const ProfileImage = styled.img`
	width: 68px;
	height: 68px;
	border-radius: 50%;

	@media screen and (max-width: 768px) {
		width: 74px;
		height: 74px;
	}
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	@media screen and (max-width: 768px) {
		row-gap: 6px;
	}
`;

const Name = styled.h3`
	color: #6d6d6d;

	/* font-weight-bold-lg */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	@media screen and (max-width: 768px) {
		color: #000;
		font-family: Poppins;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
	}
`;

const SearchContent = styled.p`
	color: #6b6666;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	display: -webkit-box;
	-webkit-line-clamp: 3;
	-webkit-box-orient: vertical;
	overflow: hidden;

	@media screen and (max-width: 768px) {
		-webkit-line-clamp: 2;
		color: #5e5e5e;
		font-family: Poppins;
		font-size: 15px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;

export { Wrapper, SearchWrapper, ProfileImage, InfoWrapper, Name, SearchContent };
