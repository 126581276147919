const Index = ({ color = "#696969", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
			<path
				d="M3.5 8.71003C3.5 7.23603 3.5 6.50003 3.893 6.07003C4.03209 5.91706 4.20129 5.79449 4.39 5.71003C4.922 5.47403 5.621 5.70703 7.019 6.17303C8.086 6.52903 8.619 6.70703 9.159 6.68803C9.3571 6.68152 9.55406 6.65539 9.747 6.61003C10.272 6.48503 10.74 6.17303 11.676 5.55003L13.058 4.62803C14.258 3.82803 14.857 3.42803 15.545 3.33703C16.233 3.24403 16.917 3.47203 18.284 3.92803L19.449 4.31603C20.439 4.64603 20.934 4.81103 21.217 5.20403C21.5 5.59703 21.5 6.11903 21.5 7.16203V15.291C21.5 16.764 21.5 17.501 21.107 17.931C20.9677 18.0833 20.7985 18.2051 20.61 18.289C20.078 18.526 19.379 18.293 17.981 17.827C16.914 17.471 16.381 17.293 15.841 17.312C15.6429 17.3185 15.4459 17.3447 15.253 17.39C14.728 17.515 14.26 17.827 13.324 18.45L11.942 19.372C10.742 20.172 10.143 20.572 9.455 20.663C8.767 20.756 8.083 20.528 6.716 20.072L5.551 19.684C4.561 19.354 4.066 19.189 3.783 18.796C3.5 18.403 3.5 17.88 3.5 16.838V8.71003Z"
				stroke={color}
				strokeWidth="1.5"
			/>
			<path opacity="0.5" d="M9.5 6.639V20.5M15.5 3V17" stroke={color} stroke-width="1.5" />
		</svg>
	);
};

export default Index;
