const Index = ({ color = "#696969", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M6.84302 3.68603C7.31987 3.20862 7.85511 2.79334 8.43602 2.45003C9.66776 1.7253 11.0709 1.34314 12.5 1.34314C13.9291 1.34314 15.3323 1.7253 16.564 2.45003C17.1449 2.79334 17.6802 3.20863 18.157 3.68603L12.5 9.34303L6.84302 3.68603ZM12.5 6.51403L9.91302 3.92703C10.7211 3.5422 11.6049 3.3425 12.5 3.3425C13.3951 3.3425 14.2789 3.5422 15.087 3.92703L12.5 6.51403Z"
				fill={color}
			/>
			<path
				d="M2.50001 12.6581C2.49851 11.1713 2.82922 9.70293 3.46799 8.36034C4.10676 7.01775 5.03744 5.83484 6.192 4.89807L7.61501 6.32207C6.29254 7.34178 5.32234 8.74977 4.8404 10.3487C4.35846 11.9475 4.38895 13.6572 4.92759 15.2379C5.46624 16.8185 6.48603 18.191 7.84401 19.1629C9.20198 20.1348 10.8301 20.6574 12.5 20.6574C14.1699 20.6574 15.798 20.1348 17.156 19.1629C18.514 18.191 19.5338 16.8185 20.0724 15.2379C20.6111 13.6572 20.6416 11.9475 20.1596 10.3487C19.6777 8.74977 18.7075 7.34178 17.385 6.32207L18.808 4.89807C19.9626 5.83484 20.8933 7.01775 21.532 8.36034C22.1708 9.70293 22.5015 11.1713 22.5 12.6581C22.5 18.1801 18.023 22.6581 12.5 22.6581C6.97701 22.6581 2.50001 18.1801 2.50001 12.6581Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
