import React from "react";
import dp from "../../../assets/images/MB/dp.png";
import cover from "../../../assets/images/MB/cover.png";
import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

const CoverImageWrapper = styled.div`
	position: relative;
`;

const CoverImage = styled.img`
	width: 100%;
`;

const ProfilePicture = styled.img`
	position: absolute;
	top: -10%;
	transform: translateY(-50%);
	left: 4%;
	width: 100px;
	height: 100px;
	border-radius: 50%;
`;

const Button = styled.button`
	width: 100px;
	padding-block: 6px;
	border: none;
	border-radius: 5px;
	background-color: #61bcac;
	color: white;
	font-weight: 600;
	cursor: pointer;
`;

const UserDetailsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	position: relative;
	width: -webkit-fill-available;

	padding-block-start: 48px;
`;

const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	align-items: baseline;
`;

const Username = styled.h2`
	color: #000;

	/* font-weight-medium-xl */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const FollowDetails = styled.div`
	display: flex;
`;

const Followers = styled.p`
	margin-inline-end: 10px;
	color: #848484;

	/* body-text-xsm */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Following = styled.p`
	margin-inline-start: 10px;
	color: #848484;

	/* body-text-xsm */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const MBProfileDetails = ({ userName, profilePicture, button = "Follow" }) => {
	return (
		<Wrapper>
			<CoverImageWrapper>
				<CoverImage src={cover} />
			</CoverImageWrapper>
			<UserDetailsWrapper>
				<ProfilePicture src={profilePicture || dp} />
				<UserDetails>
					<Username>{userName}</Username>
					<FollowDetails>
						<Followers>74 Followers</Followers>
						<Following>441 Following</Following>
					</FollowDetails>
				</UserDetails>
				<Button>{button}</Button>
			</UserDetailsWrapper>
		</Wrapper>
	);
};

export default MBProfileDetails;
