import { TextField } from "@mui/material";
import styled from "@emotion/styled";
import { borderRadius, color, spacing, typography } from "../../../styles/variables";
import PropTypes from "prop-types";

const InputField = ({
	name,
	label,
	type = "text",
	required = false,
	helperText = undefined,
	gap = spacing("sm"),
	placeholder = undefined,
	overrides = {},
	disabled = false,
	inputProps = {},
	noMargin = false,
	error = undefined,
	value,
	styles = {},
}) => {
	return (
		<Wrapper gap={gap} noMargin={noMargin} style={styles}>
			<label>
				{label && (
					<CustomLabel>
						{label}
						{required && "*"}
					</CustomLabel>
				)}
				<CustomTextField
					disabled={disabled}
					type={type}
					fullWidth
					name={name}
					error={error}
					value={value}
					placeholder={!label && required && placeholder ? placeholder + "*" : placeholder}
					InputProps={inputProps}
					sx={{ ...overrides }}
					helperText={helperText}
				/>
			</label>
		</Wrapper>
	);
};

export const CustomLabel = styled.span`
	display: inline-block;
	color: ${color("pLabel")};
	${typography("body", "md", false)}
`;

const Wrapper = styled.div`
	width: 100%;
	margin-bottom: ${spacing("sm")};
	label {
		display: flex;
		flex-direction: column;
		row-gap: ${(props) => props.gap};
	}
	${(props) =>
		props.noMargin && {
			margin: 0 + "!important",
		}}
	${(props) => props.style && props.style}
`;

const CustomTextField = styled(TextField)`
	.MuiInputBase-input {
		padding-block: ${spacing("sm1")};
		font-size: 14px;
		&::placeholder {
			font-size: 14px;
		}
	}
	.MuiInputBase-root {
		border-radius: ${borderRadius(7)};
		.MuiOutlinedInput-notchedOutline {
			border-color: ${color("borderDimGrayLight")};
		}
		&:not(.Mui-error).Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: ${color("borderDimGrayLight")};
		}
		&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline {
			border-color: ${color("error")};
		}
	}
	.MuiFormHelperText-root {
		margin-inline: 0;
	}
`;

InputField.propTypes = {
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	type: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	helperText: PropTypes.string,
	gap: PropTypes.string,
	overrides: PropTypes.object,
	disabled: PropTypes.bool,
	inputProps: PropTypes.object,
	noMargin: PropTypes.bool,
	error: PropTypes.bool,
	value: PropTypes.any,
	styles: PropTypes.object,
};

export default InputField;
