import LeftSubNav from "../../../components/LeftSubNav";
import Page from "../../../layouts/Page";
import { LeftNav, CenterContent, RightContent } from "./index.styled";
import useLayout from "../../../utils/hooks/useLayout";
import Trending from "../../../components/SocialJournalist/Trending";
import Feed from "../../../components/SocialJournalist/Feed";

const Profile = () => {
	const isMobile = useLayout();
	return (
		<Page
			overrides={
				isMobile && {
					"@media screen and (max-width: 768px)": {
						paddingBlockEnd: "72px",
						flexDirection: "column",
						paddingInline: "12px",
					},
				}
			}
		>
			<LeftNav>
				<LeftSubNav support={false} videos={true} />
			</LeftNav>
			<CenterContent>
				<Feed />
			</CenterContent>
			<RightContent>
				<Trending />
			</RightContent>
		</Page>
	);
};

export default Profile;
