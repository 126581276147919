import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 32px;

	background: #fff;

	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
	padding: 24px;
	margin-block-end: 12px;

	@media screen and (max-width: 768px) {
		margin-inline: 12px;
		row-gap: 16px;
	}
`;

const NotificationWrapper = styled.div`
	display: flex;
	column-gap: 18px;
	align-items: flex-start;
	cursor: pointer;
	@media screen and (max-width: 768px) {
		padding-block-end: 16px;
		border-bottom: 1px solid #d9d9d9;

		&:last-child {
			border-bottom: none;
			padding-block-end: 0;
		}
	}
`;

const ProfileImage = styled.img`
	width: 68px;
	height: 68px;
	border-radius: 50%;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 8px;
`;

const Name = styled.h3`
	color: #6d6d6d;

	/* font-weight-bold-lg */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const NotificationContent = styled.p`
	color: #6b6666;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export { Wrapper, NotificationWrapper, ProfileImage, InfoWrapper, Name, NotificationContent };
