import { useState } from "react";
import { styled } from "@mui/material/styles";
import { color, spacing } from "../../styles/variables";
// import { TabPanel } from "@mui/lab";
import { Tab, Tabs } from "@mui/material";
import TabContext from "@mui/lab/TabContext";

const StyledTabs = styled((props) => (
	<Tabs {...props} TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }} />
))({
	"& .MuiTabs-indicator": {
		display: "flex",
		justifyContent: "center",
		backgroundColor: "transparent",
		color: "yellow",
	},
	"& .MuiTabs-indicatorSpan": {
		width: "100%",
		backgroundColor: color("pGreen"),
	},
	"& .MuiTabs-flexContainer": {
		padding: 0,
		height: "auto",
		columnGap: spacing("xl2"),
	},
	"& .MuiTab-root": {
		padding: "0 !important",
	},
	"& button.Mui-selected": {
		padding: "0 !important",
		marginRight: "0 !important",
		height: "auto",
		backgroundColor: "transparent !important",
		borderRadius: "0 !important",
	},
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
	textTransform: "none",
	color: "#000",
	fontFamily: "Poppins",
	fontSize: "15px",
	fontStyle: "normal",
	fontWeight: 300,
	lineHeight: "normal",
	marginRight: 0,
}));

const CustomizedTabs = ({ tabs, onChange, children, defaultActive = null }) => {
	const [value, setValue] = useState(defaultActive);

	const handleChange = (event, newValue) => {
		setValue(newValue);
		onChange(newValue);
	};

	return (
		<TabContext value={value}>
			<StyledTabs
				value={value}
				onChange={handleChange}
				variant="scrollable"
				// scrollButtons
				allowScrollButtonsMobile
			>
				{tabs?.map((tab) => (
					<StyledTab value={tab?.id} label={tab?.label} />
				))}
			</StyledTabs>
			{children}
		</TabContext>
	);
};

export default CustomizedTabs;
