import { useEffect, useRef, useState } from "react";
import { NavAction } from "./index.styled";
import { BottomNavigation } from "@mui/material";
import Home from "../../../assets/icons/shorts/mobile/Home";
import Search from "../../../assets/icons/shorts/mobile/Search";
import NotificationBell from "../../../assets/icons/NotificationBell";
import SidenavMobileComponent from "../../../components/SidenavMobileComponent";
import { Mail } from "@mui/icons-material";
import User from "../../../assets/icons/User";
import { color } from "../../../styles/variables";
import { useLocation, useNavigate } from "react-router-dom";

const redirectionMapping = {
	home: "/tv/home",
	search: "/tv/search",
	notifications: "/tv/notifications",
	messages: "/messaging",
	user: "/tv/profile",
};

const BottomNav = () => {
	const [value, setValue] = useState("home");
	const navigate = useNavigate();
	const { pathname } = useLocation();
	const bottomRef = useRef(null);
	const handleChange = (event, newValue) => {
		if (newValue) setValue(newValue);
		if (redirectionMapping[newValue]) navigate(redirectionMapping[newValue]);
	};

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path.includes("home")) setValue("home");
		if (path.includes("search")) setValue("search");
		if (path.includes("notifications")) setValue("notifications");
		if (path.includes("messaging")) setValue("messages");
		if (path.includes("profile")) setValue("user");
	}, [pathname]);

	return (
		<BottomNavigation
			ref={bottomRef}
			showLabels
			value={value}
			onChange={handleChange}
			sx={{
				width: "-webkit-fill-available",
				position: "fixed",
				zIndex: 1,

				bottom: 0,
				left: 0,
				borderTop: "1px solid #E5E5E5",
				boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.25)",
			}}
		>
			<NavAction value="home" icon={<Home size={18} color={value === "home" ? "#fff" : color("pGreen")} />} />
			<NavAction value="search" icon={<Search size={18} color={value === "search" ? "#fff" : "#DC6262"} />} />
			<NavAction
				value="notifications"
				icon={<NotificationBell size={18} color={value === "notifications" ? "#fff" : "#9BAC2F"} />}
			/>
			<NavAction
				value="messages"
				icon={
					<Mail
						sx={{
							color: value === "messages" ? "#fff" : "#7284E2",
						}}
					/>
				}
			/>
			<NavAction value="user" icon={<User color={value === "user" ? "#fff" : "#3ABF81"} />} />
			<NavAction value={null} icon={<SidenavMobileComponent module="tv" color={color("pGreen")} />} />
		</BottomNavigation>
	);
};

export default BottomNav;
