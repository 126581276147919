const initialUserState = {
	// initial user state
	it: { data: null, isLoading: false, error: null },
	signUpPopup: false,
};

const itReducer = (state = initialUserState, action) => {
	// reducer logic based on different action types
	switch (action.type) {
		case "USER_DATA_GET":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: true,
				},
			};
			break;
		case "USER_DATA_SUCCESS":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					data: action?.payload,
				},
			};
			break;
		case "UPDATE_IT_USER_DATA":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					data: action?.payload,
				},
			};
			break;
		case "USER_DATA_ERROR":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					error: action?.payload,
				},
			};
			break;
		case "SET_SIGNUP_TOGGLE_IT":
			state = {
				...state,
				signUpPopup: action?.payload || false,
			};
			break;
	}
	return state;
};

export default itReducer;
