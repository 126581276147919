import styled from "@emotion/styled";

const CardTitle = styled.h2`
	color: #000;

	/* font-weight-bold-lg */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;

	& span {
		color: #676464;

		/* body-text-sm */
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;

const CardDesc = styled.p`
	color: #676464;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	margin-block: 10px;
`;

export { CardTitle, CardDesc };
