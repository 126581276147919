import React, { useEffect, useState } from "react";
import locationMark from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/user-profile-backsquare.png";
import editable from "../../../../../assets/images/editable.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";
import message from "../../../../../assets/icons/mi_message.png";
import outline from "../../../../../assets/icons/material-symbols_rate-review-outline.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserWorkLocationAndSkills,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	EducationHistory,
	EducationTitle,
	Education,
	SkillsWrapper,
	SkillsTitle,
	Skills,
	ProfileHeading,
	ManageOtherProfile,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfileModule,
	Butt,
} from "../../../styles/index.styled.js";
import { ProfilesWrapper } from "../../../../Education/styles/index.styled.js";
import { GetEducationRegularEmployeeProfile } from "../../../../../utils/apiCalls.js";
import Loader from "../../../../../components/loader/index.js";
import { connect } from "react-redux";

const EmployeePublic = ({setActiveMBProfile,setActiveModule}) => {
	const [educationEmployeeData, setEducationEmployeeData] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const location = useLocation();
	const userData = location.state?.user;
	const navigate = useNavigate();
	const handlePublicProfileNav = (path) => {
		return;
		navigate(`/${path}`);
	};

	const getEducationEmployeeProfile = () => {
		if (userData) {
			setIsLoader(true);
			const payload = {
				educationTypeId: userData?.employeeId || userData?.accountTypeId,
				educationId: userData?.educationId || userData?.moduleId,
			};
			GetEducationRegularEmployeeProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setIsLoader(false);
					setEducationEmployeeData(data);
				} else {
					setIsLoader(false);
					setEducationEmployeeData();
				}
			}, payload);
		}
	};

	useEffect(() => {
		getEducationEmployeeProfile();
	}, []);

	const handleShortvideos = (educationEmployeeData) => {
		navigate("/shorts/profile/public", { state: { user: educationEmployeeData } });
	};

	const handleMBRedirection = (data) => {
		setActiveModule("education");
		setActiveMBProfile(data?.educationId);
		navigate("/microblogging/profile/public");
	};

	return (
		<>
			{token ? (
				isLoader ? (
					<Loader/>
				):(
				<Wrapper>
					<ProfileWrapper>
						<ProfileSections>
							<SectionTitle>Regular Employee</SectionTitle>
							<Profile>
								<ProfileDetailsWrapper>
									<ProfileImageWrapper>
										<img src={educationEmployeeData?.profilePicture || ProfilePicture} alt="profile" />
										<img src={editable} className="imgedit" alt="profile" />
									</ProfileImageWrapper>
									<UserDetailsWrapper>
										<UserNameWrapper>
											<UserName>{educationEmployeeData?.fullName || "Anonymous User"}</UserName>
										</UserNameWrapper>
										<UserAddress>
											<img src={locationMark} alt="location-icon" />
											{educationEmployeeData?.location || "No Location Available"} <br />
											{educationEmployeeData?.jobTitle || "No Data Available"}&nbsp;in&nbsp;
											{educationEmployeeData?.companyName}
											<br />
											{educationEmployeeData?.industrySector}
										</UserAddress>
									</UserDetailsWrapper>
								</ProfileDetailsWrapper>
								<UserProfileActions>
									<Button buttonClick={() => handlePublicProfileNav("message")}>
										<img src={message} alt="message" />
										Message
									</Button>
									<Button buttonClick={() => handlePublicProfileNav("review")}>
										<img src={outline} alt="review" />
										Review
									</Button>
								</UserProfileActions>
							</Profile>
							<MoreDetailWrapper>
								<ProfilesAndAboutWrapper>
									<ProfilesWrapper>
										<Profiles>
											<ProfileHeading>Other Profiles</ProfileHeading>
											<ProfileModule>
													<Butt onClick={() => handleMBRedirection(educationEmployeeData)}>
														Microblogging
													</Butt>
													<Butt onClick={() => handleShortvideos(educationEmployeeData)}>ShortVideos</Butt>
												</ProfileModule>
											<ProfileList>
												{educationEmployeeData?.userName ? (
													<ProfileName>
														<ProfileUserName>Username</ProfileUserName>
														{educationEmployeeData?.userName}
													</ProfileName>
												) : (
													"No Data available"
												)}
												{educationEmployeeData?.shortVideoUsername && (
													<ProfileName>
														<ProfileUserName>shortVideoUsername</ProfileUserName>
														{educationEmployeeData?.shortVideoUsername}
													</ProfileName>
												)}
											</ProfileList>
										</Profiles>
									</ProfilesWrapper>
									<About>
										<AboutTitle>About</AboutTitle>
										<AboutDesc>{educationEmployeeData?.about || "No Data available"}</AboutDesc>
										<More>more...</More>
										<EducationHistory>
											<EducationTitle>Education History</EducationTitle>
											<Education>
												<span>{educationEmployeeData?.educationDegree}</span>
												<span>{educationEmployeeData?.educationInstitution}</span>
												<span>{educationEmployeeData?.gradYear}</span>
											</Education>
										</EducationHistory>
										<SkillsWrapper>
											<SkillsTitle>Skills</SkillsTitle>
											<Skills>{educationEmployeeData?.skill || "No Data available"}</Skills>
										</SkillsWrapper>
									</About>
								</ProfilesAndAboutWrapper>
								<FeedbackWrapper>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment1}
											commentimagestyle={{
												marginLeft: "2rem",
											}}
											commenttitle="Thanks"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment}
											commenttitle="Reply"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
								</FeedbackWrapper>
							</MoreDetailWrapper>
						</ProfileSections>
					</ProfileWrapper>
				</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(EmployeePublic);
