const initialUserState = {
	// initial user state
	education: { data: null, isLoading: false, error: null },
	signUpPopup: false,
};

const EducationReducer = (state = initialUserState, action) => {
	switch (action.type) {
		case "UPDATE_SELECTED_PROFILE_ID":
			return {
				...state,
				selectedProfileId: action.payload,
			};
		default:
			return state;
		break;
		case "USER_DATA_GET":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: true,
				},
			};
			break;
		case "USER_DATA_SUCCESS":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					data: action?.payload,
				},
			};
			break;
		case "UPDATE_EDUCATION_USER_DATA":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					data: action?.payload,
				},
			};
			break;
		case "USER_DATA_ERROR":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					error: action?.payload,
				},
			};
			break;
		case "SET_SIGNUP_TOGGLE_EDUCATION":
			state = {
				...state,
				signUpPopup: action?.payload || false,
			};
			break;
	}
	return state;
};

export default EducationReducer;
