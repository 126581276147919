const Share = ({ color = "#848484", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
			<path
				d="M13.0001 7.05V4C13.0001 3.73478 13.1054 3.48043 13.2929 3.29289C13.4805 3.10536 13.7348 3 14.0001 3C14.2624 3.0011 14.5138 3.10526 14.7001 3.29L21.7001 10.29C21.7938 10.383 21.8682 10.4936 21.9189 10.6154C21.9697 10.7373 21.9958 10.868 21.9958 11C21.9958 11.132 21.9697 11.2627 21.9189 11.3846C21.8682 11.5064 21.7938 11.617 21.7001 11.71L14.7001 18.71C14.5597 18.8476 14.3818 18.9408 14.1888 18.9779C13.9958 19.015 13.7961 18.9944 13.6147 18.9186C13.4333 18.8429 13.2782 18.7153 13.1689 18.5519C13.0596 18.3886 13.0009 18.1966 13.0001 18V14.9H12.1501C10.5701 14.8764 9.00401 15.197 7.56038 15.8393C6.11676 16.4817 4.83022 17.4306 3.79005 18.62C3.6647 18.7869 3.49027 18.9104 3.29122 18.9731C3.09216 19.0359 2.87846 19.0348 2.68005 18.97C2.47815 18.9019 2.30328 18.771 2.18096 18.5965C2.05864 18.422 1.99526 18.213 2.00005 18C2.00005 8.88 10.0801 7.32 13.0001 7.05ZM12.1501 12.88C12.8189 12.8779 13.4871 12.9213 14.1501 13.01C14.3868 13.0459 14.6028 13.1656 14.7588 13.3473C14.9148 13.5289 15.0004 13.7606 15.0001 14V15.59L19.5801 11L15.0001 6.41V8C15.0001 8.26522 14.8947 8.51957 14.7072 8.70711C14.5196 8.89464 14.2653 9 14.0001 9C13.0901 9 5.89005 9.2 4.33005 15.43C6.59436 13.7606 9.33688 12.8663 12.1501 12.88Z"
				fill={color}
			/>
		</svg>
	);
};

export default Share;
