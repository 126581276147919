import { Box, Card } from "@mui/material";
import { borderRadius } from "../../../../styles/variables";
import {
	Breadcrumbs,
	ModuleTitle,
	ModuleUserAvatar,
	ModuleUserName,
	PostAuthor,
	PostAvatar,
	PostDescription,
	PostModule,
	PostedAt,
	Thumbnail,
	ThumbnailWrapper,
} from "./index.styled";
import MUICrumbs from "@mui/material/Breadcrumbs";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import Offers from "../../../SocialJournalist/Feed/Offers";
import SecondaryBtn from "../../../Buttons/Secondary/index";
import Comment from "../../../../assets/icons/Comment";
import Reshare from "../../../../assets/icons/Reshare";
import Like from "../../../../assets/icons/Like";
import Statistics from "@mui/icons-material/BarChart";
import Bookmark from "@mui/icons-material/BookmarkBorder";
import ShareOrForward from "../../../../assets/icons/Share";
import Share from "../../../../assets/icons/Share2";
import Shorts from "./Shorts";
import Promotion from "./Promotion";

const renderBreadcumbs = (crumbs) => {
	if (crumbs?.length <= 1) {
		return <Breadcrumbs>{crumbs[0]}</Breadcrumbs>;
	} else {
		return (
			<MUICrumbs
				separator={
					<NavigateNextIcon
						sx={{
							color: "#D9D9D9",
						}}
						fontSize="small"
					/>
				}
				sx={{
					"& .MuiBreadcrumbs-li": {
						display: "flex",
					},
					"& .MuiBreadcrumbs-separator": {
						m: 0,
					},
				}}
			>
				{crumbs.map((crumb, index) => (
					<Breadcrumbs key={index}>{crumb}</Breadcrumbs>
				))}
			</MUICrumbs>
		);
	}
};

const renderPostAuthor = (type, post) => {
	if (type !== "offer")
		return (
			<Box
				display={"flex"}
				alignItems={"center"}
				sx={{
					columnGap: "12px",
					"@media screen and (max-width: 768px)": {
						columnGap: "10px",
					},
				}}
			>
				{post.avatar && <PostAvatar src={post.avatar} alt={post.name} />}
				<Box display={"flex"} flexDirection={"column"} rowGap={"6px"}>
					<Box display={"flex"} alignItems={"center"} columnGap={"12px"}>
						<PostAuthor>{post.name}</PostAuthor>
						{post.posted && <PostedAt>{post.posted}</PostedAt>}
					</Box>
					{post.module && <PostModule>{post.module}</PostModule>}
				</Box>
				{post.reward && (
					<SecondaryBtn
						label={`Ad $${post.reward} Reward`}
						overrides={{
							border: "1px solid #D9D9D9",
							marginLeft: "auto",
							backgroundColor: "#fff",
							"&:hover": {
								backgroundColor: "#fff",
							},
						}}
					/>
				)}
			</Box>
		);
};

const renderPostDescription = (type, post) => {
	if (type !== "offer") return <PostDescription>{post.description}</PostDescription>;
};

const renderPost = (type, post) => {
	switch (type) {
		case "offer":
			return <Offers noPaper offer={post} />;
		case "shorts":
			return <Shorts short={post} />;
		case "promotion":
			return <Promotion post={post} />;
		default:
			return (
				<ThumbnailWrapper>
					<Thumbnail src={post.thumbnail} />
				</ThumbnailWrapper>
			);
	}
};

const renderActions = (type) => {
	if (type === "reward" || type === "real estate") {
		return (
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				sx={{
					"& > *": {
						cursor: "pointer",
					},
				}}
			>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Comment /> 300
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Reshare /> 2.6k
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Like /> 8k
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Statistics
						sx={{
							color: "#929292",
						}}
					/>
					800
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Bookmark
						sx={{
							color: "#929292",
						}}
					/>
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Share />
				</Box>
			</Box>
		);
	} else if (type === "promotion") {
		return (
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				sx={{
					borderTop: "1px solid #e2e2e2",
					pt: "12px",
					"& > *": {
						cursor: "pointer",
					},
				}}
			>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					Like
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Comment /> Comment
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<ShareOrForward /> Share
				</Box>
			</Box>
		);
	}
};

const Post = ({ post }) => {
	const { author, post: postInfo } = post;
	return (
		<Box
			sx={{
				"&:last-child > .MuiPaper-root": {
					mb: "12px",
				},
			}}
		>
			<Card
				elevation={6}
				sx={{ p: "12px", borderRadius: borderRadius(8), display: "flex", flexDirection: "column", rowGap: "16px" }}
			>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					sx={{
						borderBottom: "1px solid #e2e2e2",
						pb: "14px",
					}}
				>
					<span>{renderBreadcumbs(post.module)}</span>
					<Box
						display={"flex"}
						alignItems={"center"}
						sx={{
							columnGap: "12px",
							"@media screen and (max-width: 768px)": {
								columnGap: "10px",
							},
						}}
						justifyContent={"space-between"}
					>
						{author.avatar && <ModuleUserAvatar src={author.avatar} alt={author.name} />}
						<Box display={"flex"} flexDirection={"column"} rowGap={"6px"}>
							<ModuleUserName>{author.name}</ModuleUserName>
							{author.module && <ModuleTitle>{author.module}</ModuleTitle>}
						</Box>
					</Box>
				</Box>
				{renderPostAuthor(post.type, postInfo)}
				{renderPostDescription(post.type, postInfo)}
				{renderPost(post.type, postInfo)}
				{renderActions(post.type)}
			</Card>
		</Box>
	);
};

export default Post;
