import PrimaryButton from "../../Buttons/Primary";
import PlusIcon from "@mui/icons-material/AddSharp";
import Comment from "../../../assets/icons/shorts/mobile/Comment";
import Like from "../../../assets/icons/shorts/mobile/Like";
import Play from "../../../assets/icons/shorts/mobile/Play";
import {
	MobileWrapper,
	Wrapper,
	UserAvatarWrapper,
	UserAvatar,
	SubWrapper,
	InfoWrapper,
	UserName,
	RewardsWrapper,
	FeedImageWrapper,
	FeedImage,
	AnalyticsWrapper,
	Analytic,
	AnalyticIcon,
	AnalyticInfo,
	RewardsButton,
	UserActionSection,
	PlusIconWrapper,
	LikeWrapper,
	CommentsWrapper,
	PlayerWrapper,
	Preview,
	PreviewImage,
	PlayButtonWrapper,
} from "./index.styled";
import { useLocation } from "react-router-dom";
import { spacing } from "../../../styles/variables";

const Feed = ({ feed, isMobile }) => {
	const { pathname } = useLocation();
	const path = pathname?.split("/");
	return feed?.map((feedItem) =>
		isMobile ? (
			<MobileWrapper>
				<UserActionSection>
					<UserAvatarWrapper>
						<UserAvatar src={feedItem?.profile} alt="profile" />
						<PlusIconWrapper>
							<PlusIcon />
						</PlusIconWrapper>
					</UserAvatarWrapper>
					<LikeWrapper>
						<Like />
						323
					</LikeWrapper>
					<CommentsWrapper>
						<Comment />
						424
					</CommentsWrapper>
				</UserActionSection>
				<PlayerWrapper>
					<Preview>
						<PreviewImage src={feedItem?.preview} alt={"preview"} />
					</Preview>
					<PlayButtonWrapper>
						<Play />
					</PlayButtonWrapper>
					{path?.includes("shorts") && path?.includes("rewards") && <RewardsButton>Ad $1 Reward</RewardsButton>}
				</PlayerWrapper>
			</MobileWrapper>
		) : (
			<Wrapper
				key={feedItem?.id}
				style={
					(path?.includes("rewards") && {
						paddingBlockStart: spacing("xl2"),
						position: "relative",
					}) ||
					{}
				}
			>
				<UserAvatar src={feedItem?.profile} alt="profile" />
				<SubWrapper>
					<InfoWrapper
						style={
							(path?.includes("shorts") &&
								path?.includes("rewards") && {
									minHeight: "auto",
								}) ||
							{}
						}
					>
						<UserName>{feedItem?.name}</UserName>
						{path?.includes("shorts") && path?.includes("rewards") && (
							<RewardsWrapper>
								<RewardsButton>Ad $1 Reward</RewardsButton>
							</RewardsWrapper>
						)}
						<PrimaryButton
							label={(path?.includes("shorts") && path?.includes("marketlink") && "Follow") || "Post"}
							overrides={
								(path?.includes?.("rewards") && {
									position: "absolute",
									top: 0,
									right: 0,
									marginBlockEnd: spacing("xl2"),
								}) ||
								{}
							}
						/>
					</InfoWrapper>
					<FeedImageWrapper>
						<FeedImage src={feedItem?.preview} alt="feed" />
					</FeedImageWrapper>
					<AnalyticsWrapper>
						{feedItem?.analytics?.map((analytic) => (
							<Analytic key={analytic?.stat}>
								<AnalyticIcon>
									<img src={analytic?.icon} alt="analytic icon" />
								</AnalyticIcon>
								<AnalyticInfo>{analytic?.stat}</AnalyticInfo>
							</Analytic>
						))}
					</AnalyticsWrapper>
				</SubWrapper>
			</Wrapper>
		),
	);
};

export default Feed;
