import React, { createContext, useEffect, useState } from "react";

export const MenuContext = createContext();

export const MenuProvider = ({ children }) => {
	const [menuopen, setMenuopen] = useState(false);

	const handleMenu = () => {
		setMenuopen(!menuopen);
	};

	const updateMenuDetails = (newData) => {
		setMenuopen((prevData) => ({ ...prevData, ...newData }));
	};

	const clearMenuDetails = () => {
		setMenuopen(null);
	};

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth >= 768) {
				setMenuopen(false);
			}
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<MenuContext.Provider value={{ menuopen, setMenuopen, updateMenuDetails, clearMenuDetails, handleMenu }}>
			{children}
		</MenuContext.Provider>
	);
};
