import React, { useEffect, useState } from "react";
import locationMark from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/user-profile-backsquare.png";
import editable from "../../../../../assets/images/editable.png";
import editablenoborder from "../../../../../assets/images/editablenoborder.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";
import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";
import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserWorkLocationAndSkills,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	EducationHistory,
	EducationTitle,
	Education,
	SkillsWrapper,
	SkillsTitle,
	Skills,
	ProfileHeading,
	ManageOtherProfile,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfileModule,
	Butt,
} from "../../../styles/index.styled.js";
import { GetITRegularEmployeeProfile } from "../../../../../utils/apiCalls.js";
import Loader from "../../../../../components/loader/index.js";
import { connect } from "react-redux";

const EmployeePrivate = ({ setActiveModule, setActiveMBProfile }) => {
	const [regularEmployeeProfileData, SetRegularEmployeeProfileData] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const location = useLocation();
	const userData = location.state?.userData;
	const navigate = useNavigate();
	const handlePublicView = () => {
		navigate("/it/employee/profile/public");
	};

	const getITRegularEmployeeProfile = () => {
		if (userData) {
			setIsLoader(true);
			const payload = {
				itProfileTypeId: userData?.itProfileTypeId || userData?.accountTypeId,
				itProfileId: userData?.itProfileId || userData?.moduleId,
			};
			GetITRegularEmployeeProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setIsLoader(false);
					SetRegularEmployeeProfileData(data);
				} else {
					setIsLoader(false);
					SetRegularEmployeeProfileData();
				}
			}, payload);
		}
	};

	useEffect(() => {
		getITRegularEmployeeProfile();
	}, []);

	const handleMBRedirection = (data) => {
		setActiveModule("it");
		setActiveMBProfile(data?.itProfileId);
		navigate("/microblogging/profile/private");
	};

	const handleShortvideos = (regularEmployeeProfileData) => {
		navigate("/shorts/profile/private", { state: { user: regularEmployeeProfileData } });
	};

	return (
		<>
			{token ? (
				isLoader ? (
					<Loader />
				) : (
					<Wrapper>
						<ProfileWrapper>
							<ProfileSections>
								<SectionTitle>Regular Employee</SectionTitle>
								<Profile>
									<ProfileDetailsWrapper>
										<ProfileImageWrapper>
											<img
												src={regularEmployeeProfileData?.profilePicture || ProfilePicture}
												alt="profile"
											/>
											<img src={editable} className="imgedit" alt="profile" />
										</ProfileImageWrapper>
										<UserDetailsWrapper>
											<UserNameWrapper>
												<UserName>{regularEmployeeProfileData?.fullName || "Anonymous User"}</UserName>
												<img
													src={editablenoborder}
													className="imgedit"
													alt="profile"
													style={{
														display: "block",
													}}
												/>
											</UserNameWrapper>
											<UserAddress>
												<img src={locationMark} alt="location-icon" />
												{regularEmployeeProfileData?.location || "No Location Available"}
											</UserAddress>
											<UserWorkLocationAndSkills>
												<span>{regularEmployeeProfileData?.jobTitle || "No Data Available"}</span>
												<span>
													{regularEmployeeProfileData?.companyName}&nbsp;
													{regularEmployeeProfileData?.industrySector}
												</span>
											</UserWorkLocationAndSkills>
											{/* To be visible on mobile only */}
											<UserProfileActions
												hideOnDesktop
												style={{
													marginBlockEnd: "14px",
												}}
											>
												<Button
													buttonClick={handlePublicView}
													styles={{
														width: "fit-content",
														backgroundColor: "#FCFDFF",
														color: "#6B6B6B",
														borderColor: "#C2C2C2",
													}}
												>
													See Public View
												</Button>
											</UserProfileActions>
										</UserDetailsWrapper>
									</ProfileDetailsWrapper>
									{/* To be visible on desktop only */}
									<UserProfileActions
										hideOnMobile
										style={{
											marginBlockEnd: "14px",
										}}
									>
										<Button
											buttonClick={handlePublicView}
											styles={{
												width: "fit-content",
												backgroundColor: "#FCFDFF",
												marginLeft: "auto",
												color: "#6B6B6B",
												borderColor: "#C2C2C2",
											}}
										>
											See Public View
										</Button>
									</UserProfileActions>
								</Profile>

								<MoreDetailWrapper>
									<ProfilesAndAboutWrapper>
										<Profiles>
											<ProfileHeading>
												Other Profiles
												<ManageOtherProfile>Manage</ManageOtherProfile>
											</ProfileHeading>
											<ProfileModule>
												<Butt onClick={() => handleMBRedirection(regularEmployeeProfileData)}>
													Microblogging
												</Butt>
												<Butt onClick={() => handleShortvideos(regularEmployeeProfileData)}>
													ShortVideos
												</Butt>
											</ProfileModule>
											<ProfileList>
												{regularEmployeeProfileData?.userName ? (
													<ProfileName>
														<ProfileUserName>Username</ProfileUserName>
														{regularEmployeeProfileData?.userName}
													</ProfileName>
												) : (
													"No Data available"
												)}
												{regularEmployeeProfileData?.shortVideoUsername && (
													<ProfileName>
														<ProfileUserName>shortVideoUsername</ProfileUserName>
														{regularEmployeeProfileData?.shortVideoUsername}
													</ProfileName>
												)}
											</ProfileList>
										</Profiles>
										<About>
											<AboutTitle>
												About <img src={editablenoborder} alt="" />
											</AboutTitle>
											<AboutDesc>{regularEmployeeProfileData?.about || "No Data available"}</AboutDesc>
											<More>more...</More>
											<EducationHistory>
												<EducationTitle>
													Education History <img src={editablenoborder} alt="" />
												</EducationTitle>
												<Education>
													<span>{regularEmployeeProfileData?.educationDegree}</span>
													<span>{regularEmployeeProfileData?.educationInstitution}</span>
													<span>{regularEmployeeProfileData?.gradYear}</span>
												</Education>
											</EducationHistory>
											<SkillsWrapper>
												<SkillsTitle>
													Skills <img src={editablenoborder} alt="" />
												</SkillsTitle>
												<Skills>{regularEmployeeProfileData?.skill || "No Data available"}</Skills>
											</SkillsWrapper>
										</About>
									</ProfilesAndAboutWrapper>
									<FeedbackWrapper>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment1}
												commentimagestyle={{
													marginLeft: "2rem",
												}}
												commenttitle="Thanks"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment}
												commenttitle="Reply"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
									</FeedbackWrapper>
								</MoreDetailWrapper>
							</ProfileSections>
						</ProfileWrapper>
					</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(EmployeePrivate);
