import { Fragment } from "react";
import InputField, { CustomLabel } from "../../../../Inputs/Input";
import Textarea from "../../../../Inputs/Textarea";
import { FormContainer, FormTitleInfo, FormRowWrapper } from "../index.styled";
import { spacing } from "../../../../../styles/variables";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";

const EmployeeSignUpForm = () => {
	return (
		<Fragment>
			<FormContainer>
				<FormTitleInfo>Personal Information</FormTitleInfo>
				<FormRowWrapper>
					<InputField name={"firstName"} label={"First Name"} required />
					<InputField name={"lastName"} label={"Last Name"} required />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"location"} label={"Location"} required />
					<InputFieldWithUpload name={"profilePicture"} label={"Profile Picture"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"currentJob"} label={"Current Job Title"} placeholder={"eg. Web Designer, Developer"} />
					<InputField name={"currentCompnany"} label={"Company Name (If currently employed)"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"industry"} label={"Industry/Sector"} />
					<InputField name={"skills"} label={"Skills & Expertise"} />
				</FormRowWrapper>
				<FormRowWrapper
					childCount={1}
					style={{
						gridRowGap: spacing("md2"),
					}}
				>
					<CustomLabel>Education History</CustomLabel>
					<FormRowWrapper childCount={3} colGap={spacing("md1")}>
						<InputField name={"industry"} placeholder={"Degree"} required />
						<InputField name={"skills"} placeholder={"Institution"} required />
						<InputField name={"skills"} placeholder={"Graduation Year"} required />
					</FormRowWrapper>
				</FormRowWrapper>
				<FormRowWrapper childCount={1}>
					<Textarea name={"about"} label={"About"} rows={3} mobileRows={6} />
				</FormRowWrapper>
			</FormContainer>
		</Fragment>
	);
};

export default EmployeeSignUpForm;
