import {
	Channel,
	Details,
	DetailsWrapper,
	Image,
	Info,
	Name,
	SectionTitle,
	Title,
	UserName,
	VideoCard,
	Videos,
	VideosSection,
	Wrapper,
	VideoThumbail,
	VideoInfoWrapper,
} from "./index.styled";
import ProfilePicture from "../../../assets/images/IT/itprofileclient.png";
import Thumbnail from "../../../assets/images/tv/NewsChannelThumbnail.png";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";

export const videos = [
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
	{
		thumbnail: Thumbnail,
		title: "Ep-123 Loyal News on Parliament Secutiry",
		channel: "ANI News",
		views: "1.6M views",
		dated: "1 month ago",
	},
];

const Profile = () => {
	return (
		<Wrapper>
			<DetailsWrapper>
				<Image src={ProfilePicture} alt="profile" />
				<Details>
					<Name>George Kajaia</Name>
					<UserName>@George123</UserName>
				</Details>
			</DetailsWrapper>
			<VideosSection>
				<SectionTitle>Videos</SectionTitle>
				<Videos>
					{videos.map((video, index) => (
						<VideoCard key={index}>
							<VideoThumbail src={video.thumbnail} alt="thumbnail" />
							<VideoInfoWrapper>
								<Title>{video.title}</Title>
								<Channel>
									{video.channel} <CheckCircleRoundedIcon />
								</Channel>
								<Info>{`${video.views}. ${video.dated}`}</Info>
							</VideoInfoWrapper>
						</VideoCard>
					))}
				</Videos>
			</VideosSection>
		</Wrapper>
	);
};

export default Profile;
