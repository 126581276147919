import { Card, Box } from "@mui/material";
import ProfilePicture from "../../../assets/images/dating/Profile.png";
import { IconWrapper, Info, Name, SubTitle } from "./index.styled";
import Like from "../../../assets/icons/Like";
import PrimaryBtn from "../../Buttons/Primary/index";
import Message from "@mui/icons-material/Chat";
import Review from "@mui/icons-material/RateReview";
import Divider from "@mui/material/Divider";
import MaritalStatus from "../../../assets/icons/MaritalStatus";
import EducationalStauts from "../../../assets/icons/EducationalStatus";
import Religion from "../../../assets/icons/Religion";
import BodyType from "../../../assets/icons/BodyType";
import HeightMeasure from "../../../assets/icons/HeightMeasure";
import Etnicity from "../../../assets/icons/Ethnicity";
import ReviewComponent from "../../ReviewComponent/ReviewComponent";
import comment1 from "../../../assets/images/profile/comment1.png";
import useLayout from "../../../utils/hooks/useLayout";

const Profile = () => {
	const isMobile = useLayout();
	return (
		<Card elevation={6} sx={{ p: "10px" }}>
			<Box
				display={"grid"}
				gridTemplateColumns={"repeat(3, 1fr)"}
				columnGap={"72px"}
				alignItems={"flex-start"}
				sx={{
					"@media (max-width: 768px)": {
						display: "flex",
						flexDirection: "column",
						rowGap: "24px",

						"& img": {
							width: "-webkit-fill-available",
						},
					},
				}}
			>
				<img src={ProfilePicture} alt="profile" />
				<Box display={"flex"} flexDirection={"column"} justifyContent={"center"} rowGap={"14px"}>
					<Name>Jason Borg</Name>
					<Info>36 United States</Info>
					<Info>Online Now</Info>
					<IconWrapper>
						<Like size={24} />
					</IconWrapper>
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"14px"} width={"100%"}>
					<PrimaryBtn
						noHoverEffect
						label={
							<Box display={"inline-flex"} alignItems={"center"} columnGap={"6px"}>
								<Review
									sx={{
										color: "white",
									}}
								/>
								Review
							</Box>
						}
						fullWidth
					/>
					<PrimaryBtn
						noHoverEffect
						label={
							<Box display={"inline-flex"} alignItems={"center"} columnGap={"6px"}>
								<Message
									sx={{
										color: "white",
									}}
								/>
								Message
							</Box>
						}
						fullWidth
					/>
				</Box>
			</Box>
			<Divider sx={{ my: (isMobile && "16px") || "36px", borderColor: "#797979" }} />
			<SubTitle>About</SubTitle>
			<Box display={"flex"} alignItems={"center"} columnGap={"44px"} rowGap={"24px"} flexWrap={"wrap"}>
				<Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
					<MaritalStatus />
					<Info>Never Married</Info>
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
					<EducationalStauts />
					<Info>High School</Info>
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
					<Religion />
					<Info>Hindu</Info>
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
					<BodyType />
					<Info>Slim/Slender</Info>
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
					<HeightMeasure />
					<Info>161 cm</Info>
				</Box>
			</Box>
			<Divider sx={{ my: "16px", borderColor: "#797979" }} />
			<SubTitle>Ethnicity</SubTitle>
			<Box display={"flex"} alignItems={"center"} columnGap={"4px"}>
				<Etnicity />
				<Info>White/Causasian</Info>
			</Box>
			<Divider sx={{ my: "16px", borderColor: "#797979" }} />
			<SubTitle>Other Profiles</SubTitle>
			<Box display={"flex"} flexDirection={"column"} rowGap={"12px"}>
				<Info>
					<span>Engineering</span> George Kajai123
				</Info>
				<Info>
					<span>Education</span> George Kajai456
				</Info>
				<Info>
					<span>Ecommerce</span> George Kajai789
				</Info>
			</Box>
			<Divider sx={{ my: "16px", borderColor: "#797979" }} />
			<SubTitle>Reviews</SubTitle>
			<Box
				display={"grid"}
				gridTemplateColumns={"repeat(2, 1fr)"}
				columnGap={"12px"}
				sx={{
					"@media (max-width: 768px)": {
						display: "flex",
						flexDirection: "column",
						rowGap: "12px",
					},
				}}
			>
				<ReviewComponent
					heading={"Education"}
					name={"George"}
					commentimage={comment1}
					commentimagestyle={{
						marginLeft: "2rem",
					}}
					stars={5}
					commenttitle="Thanks"
					review={
						"David Smith is an exceptional friend, admired for his kindness, reliability, and great sense of humor. His positive attitude and supportive nature make every moment spent with him truly enjoyable. A truly valued companion!"
					}
				/>
				<ReviewComponent
					heading={"Education"}
					name={"George"}
					commentimage={comment1}
					commentimagestyle={{
						marginLeft: "2rem",
					}}
					stars={5}
					commenttitle="Thanks"
					review={
						"David Smith is an exceptional friend, admired for his kindness, reliability, and great sense of humor. His positive attitude and supportive nature make every moment spent with him truly enjoyable. A truly valued companion!"
					}
				/>
			</Box>
		</Card>
	);
};

export default Profile;
