import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-gap: 24px;

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		padding-inline: 12px;
	}
`;

const LiveTitle = styled.h4`
	grid-column: span 10;
	color: #000;

	/* font-weight-bold-xl */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const TVWrapper = styled.div`
	grid-column: span 6;
	margin-block-end: 24px;

	max-height: 75dvh;
	/* overflow-y: auto; */

	position: sticky;
	top: 28px;

	@media screen and (max-width: 768px) {
		max-height: none;
		position: static;
		margin-block-end: 0;
	}
`;

const LiveThumbnail = styled.img`
	width: 100%;
	border-radius: 8px;
	padding-bottom: 16px;
	background-color: #fff;
	position: sticky;
	top: 0;
`;

const LiveActionsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	margin-top: 16px;
	padding-top: 16px;
	border-top: 1px solid #d9d9d9;
`;

const Action = styled.div`
	cursor: pointer;

	display: flex;
	align-items: center;
	column-gap: 8px;

	color: #908a8a;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const ChannelsWrapper = styled.div`
	grid-column: span 4;
	display: grid;
	row-gap: 24px;
	margin-block-end: 24px;
`;

const ChannelCard = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 20px;
`;

const ChannelThumbnail = styled.img`
	width: 100%;
	border-radius: 8px;
`;

const ChannelInfo = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 12px;
`;

const ThumbnailTitle = styled.h5`
	color: #676464;

	/* font-weight-medium-md */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const ChannelTitle = styled.h5`
	color: #797979;

	/* font-weight-medium-md */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const ChannelStats = styled.div`
	display: flex;
	column-gap: 8px;

	color: #000;

	/* body-text-xsm */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export {
	Wrapper,
	LiveTitle,
	TVWrapper,
	LiveThumbnail,
	LiveActionsWrapper,
	Action,
	ChannelsWrapper,
	ChannelCard,
	ChannelThumbnail,
	ChannelInfo,
	ThumbnailTitle,
	ChannelTitle,
	ChannelStats,
};
