import styled from "@emotion/styled";

const Wrapper = styled.div`
	width: -webkit-fill-available;
	padding-inline: 12.5dvw;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-inline: auto;
	padding-block: 8px;
	background-color: #fff;

	position: sticky;
	top: 0;
	z-index: 1;

	& > svg {
		cursor: pointer;
	}

	@media screen and (max-width: 768px) {
		padding-inline: 12px;
	}
`;

const Login = styled.button`
	display: flex;
	align-items: center;
	column-gap: 10px;
	background-color: transparent;
	border: none;
	outline: none;
	cursor: pointer;
`;

export { Wrapper, Login };
