const Index = ({ color = "#696969", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
			<path
				d="M1.5 9.28571L12.5 3L23.5 9.28571L12.5 15.5714L1.5 9.28571Z"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M5.42859 11.6429V17.9286L12.5 21.8572M12.5 21.8572L19.5714 17.9286V11.6429M12.5 21.8572V15.5715M23.5 17.9286V9.28577"
				stroke={color}
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Index;
