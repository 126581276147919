import React from "react";

const Freelancer = ({ color = "#61BCAC", size = 44 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 44 44" fill="none">
			<path
				d="M16.521 12.8779C16.1997 12.7031 16 12.3662 16 12C16 11.6338 16.1997 11.2969 16.521 11.1221L27.521 5.12207C27.8198 4.95996 28.1802 4.95996 28.479 5.12207L39.479 11.1221C39.8003 11.2969 40 11.6338 40 12V17.9009C40.0179 17.917 40.0429 17.9223 40.0601 17.9395C40.3403 18.2197 40.5 18.6094 40.5 19C40.5 19.3896 40.3403 19.7803 40.0601 20.0596C39.7803 20.3398 39.4004 20.5 39 20.5C38.6001 20.5 38.2202 20.3398 37.9399 20.0596C37.6602 19.7803 37.5 19.3896 37.5 19C37.5 18.6094 37.6602 18.2197 37.9399 17.9395C37.957 17.9221 37.9821 17.9167 38 17.9005V13.6846L36 14.7756V24C36 24.5527 35.5522 25 35 25H21C20.4478 25 20 24.5527 20 24V14.7756L16.521 12.8779ZM28 7.13867L19.0884 12L28 16.8613L36.9116 12L28 7.13867ZM22 23H34V15.8665L28.479 18.8779C28.3296 18.959 28.165 19 28 19C27.835 19 27.6704 18.959 27.521 18.8779L22 15.8665V23ZM1.02246 35.6016L9.68994 26.7041C10.0703 26.3154 10.6904 26.2998 11.0884 26.6709L11.82 27.3531L14.4734 24.5072C12.9157 22.0395 12 19.1277 12 16C12 7.17773 19.1777 0 28 0C36.8223 0 44 7.17773 44 16C44 24.8223 36.8223 32 28 32C24.4761 32 21.2253 30.841 18.5788 28.9032L16.2078 31.4448L16.9395 32.127C17.1362 32.3105 17.2505 32.5654 17.2568 32.834C17.2637 33.1035 17.1611 33.3633 16.9736 33.5566L8.32129 42.4385C7.75537 43.0439 6.95557 43.3916 6.12646 43.3916C5.36426 43.3916 4.6377 43.1055 4.08105 42.5859L1.15479 39.8584C-0.0532227 38.7305 -0.119629 36.8281 1.02246 35.6016ZM42 16C42 8.28027 35.7197 2 28 2C20.2803 2 14 8.28027 14 16C14 23.7197 20.2803 30 28 30C35.7197 30 42 23.7197 42 16ZM17.0378 27.623C16.5513 27.1639 16.0831 26.6861 15.657 26.1699L13.2823 28.7167L14.7452 30.0809L17.0378 27.623ZM2.51953 38.3955L5.4458 41.123C5.83545 41.4893 6.48047 41.4795 6.87354 41.0576L14.8276 32.8926L14.1126 32.2258L11.187 29.498C11.1865 29.4976 11.1863 29.4968 11.1858 29.4963L10.4399 28.8008L2.4707 36.9814C2.09473 37.3848 2.1167 38.0195 2.51953 38.3955Z"
				fill={color}
			/>
		</svg>
	);
};

export default Freelancer;
