const Index = ({ color = "#fff", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
			<path
				d="M13.1021 21.883C15.8551 21.4573 18.3264 19.9557 19.9726 17.7084C21.6189 15.4611 22.3053 12.652 21.881 9.89876C21.671 8.53578 21.1945 7.22753 20.4788 6.04877C19.763 4.87001 18.822 3.84383 17.7095 3.02889C16.597 2.21395 15.3349 1.62621 13.9951 1.29927C12.6554 0.972318 11.2644 0.912569 9.90152 1.12343C7.14848 1.54917 4.67719 3.05076 3.03097 5.29807C1.38474 7.54538 0.698329 10.3545 1.12264 13.1077L1.63441 16.2698L2.34704 20.4826L2.40338 20.7433C2.7845 22.1902 4.19336 23.1478 5.70249 22.9406L10.7497 22.2312L13.1021 21.883ZM10.1297 2.60142C11.2984 2.42073 12.4913 2.47207 13.6401 2.75251C14.789 3.03295 15.8714 3.53699 16.8254 4.23584C17.7794 4.9347 18.5864 5.81466 19.2003 6.82547C19.8141 7.83628 20.2228 8.95813 20.403 10.1269C20.7566 12.4228 20.21 14.7666 18.8773 16.6692C17.5445 18.5718 15.5285 19.8863 13.25 20.3384L12.8739 20.405L10.1196 20.812L5.49859 21.4599L5.31393 21.4734C4.98923 21.4775 4.672 21.3759 4.4102 21.1837C4.14839 20.9916 3.95624 20.7195 3.86276 20.4085L3.82114 20.2293L2.79019 14.0726L2.60064 12.8795L2.54988 12.5021C2.29375 10.1933 2.93908 7.87474 4.35119 6.03026C5.76331 4.18577 7.83403 2.95662 10.1297 2.60142Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
