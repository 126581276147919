import { Fragment, useState } from "react";
import Heading from "../../../../components/Heading/Heading";
import icon from "../../../../assets/icons/engineering.png";
import { useNavigate } from "react-router-dom";
import SignUpForm from "../../../../components/IT/Forms/Signup/Freelancer";
import Skills from "../../../../components/IT/Forms/Signup/Freelancer/Skills";
import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	justify-content: center;
	margin-inline: 40px;

	@media screen and (max-width: 768px) {
		display: flex;
		margin-inline: 0;
	}

	@media screen and (min-width: 1440px) {
		max-width: 1440px;
		margin-inline: auto;
	}
`;

const ITFreelancerSignUp = () => {
	const navigate = useNavigate();
	const [signUpStage, setSignUpStage] = useState(1);
	const [formValues, setFormValues] = useState({});
	const [skills, setSkills] = useState([]);
	const [profilePicture, setProfilePicture] = useState(null);

	const handleSubmit = async (values) => {
		if (signUpStage === 1) {
			setFormValues((prev) => {
				return { ...values, ...prev };
			});
			setSignUpStage(2);
			return;
		}

		const allData = { ...skills, ...formValues };

		try {
			if (signUpStage === 2) {
				navigate("/it/microblogging", {
					state: {
						allData: allData,
						picture: profilePicture,
						url: "createFreelancerAcc",
						redirect: "/it/freelancer/profile/private",
					},
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleBack = () => {
		if (signUpStage === 2) setSignUpStage(1);
		else navigate(-1);
	};
	return (
		<Fragment>
			<Heading icon={icon} title={"Information Technology"} showSearchInput={false} moduleHomeRedirect={"/it/home"} />
			<Wrapper>
				{signUpStage === 1 ? (
					<SignUpForm setProfilePicture={setProfilePicture} handleSubmit={handleSubmit} handleBack={handleBack} />
				) : (
					<Skills setSkills={setSkills} handleSubmit={handleSubmit} handleBack={handleBack} />
				)}
			</Wrapper>
		</Fragment>
	);
};

export default ITFreelancerSignUp;
