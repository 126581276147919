import React, { useState } from "react";

import { Navigate, useNavigate } from "react-router-dom";

import SignUpForm from "../../../components/MB/Forms/Signup";

const MBITSignup = () => {
	const [popup, setPopup] = useState(false);

	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const handleNav = () => {
		navigate("/home");
	};
	const togglePopup = () => {
		setPopup(!popup);
	};

	return token ? <SignUpForm /> : <Navigate to={"/login"} />;
};

export default MBITSignup;
