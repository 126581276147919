import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import HomePage from "../pages/Agriculture/Home";
import ClientSignup from "../pages/Agriculture/Client/Signup";
import CompanySignup from "../pages/Agriculture/Company/Signup";
import FreelancerSignup from "../pages/Agriculture/Freelancer/Signup";
import FreelancerProfilePrivate from "../pages/Agriculture/Freelancer/Profile/Private";
import FreelancerProfilePublic from "../pages/Agriculture/Freelancer/Profile/Public";
import EmployeeSignup from "../pages/Agriculture/Employee/Signup";
import SearchResult from "../pages/Agriculture/SearchResult";
import CompanyProfilePrivate from "../pages/Agriculture/Company/Profile/Private";
import CompanyProfilePublic from "../pages/Agriculture/Company/Profile/Public";
import ClientProfilePrivate from "../pages/Agriculture/Client/Profile/Private";
import ClientProfilePublic from "../pages/Agriculture/Client/Profile/Public";
import EmployeeProfilePrivate from "../pages/Agriculture/Employee/Profile/Private";
import EmployeeProfilePublic from "../pages/Agriculture/Employee/Profile/Public";
import Heading from "../components/Heading/Heading";
import Icon from "../assets/icons/agriculture/agriculture";

import SubHeader from "../components/Agriculture/Header";
import SignupMain from "../pages/Agriculture/SingupMain";
import Microblogging from "../components/Agriculture/Microblogging";
import Profiles from "../components/Agriculture/Profiles";
import { connect } from "react-redux";

const AgricultureRoutes = ({ userData, setActiveModule, setSignUpPopupToggle, signUpPopup }) => {
	const location = useLocation();
	const { pathname } = location;
	const [profileData, setProfileData] = useState({});
	const [isAgriculture, setIsAgriculture] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path?.includes("agriculture") && !path.includes("microblogging") && !path.includes("signup") && path?.length > 1) {
			setActiveModule("ag");
			setIsAgriculture(true);
		} else setIsAgriculture(false);
	}, [pathname]);

	useEffect(() => {
		if (!userData?.isLoading) setProfileData(userData?.data || null);
	}, [userData]);

	const handleProfiles = (event) => {
		setAnchorEl(event?.currentTarget);
	};

	const handleProfilesClose = (event) => {
		setAnchorEl(null);
	};

	const isOpen = Boolean(anchorEl);
	const id = isOpen ? "agriculture-simple-popover" : undefined;

	return (
		<>
			{isAgriculture && (
				<>
					<Heading
						icon={Icon}
						title={"Agriculture"}
						moduleHomeRedirect={"/agriculture/home"}
						handleClick={handleProfiles}
						user={profileData?.fullName}
						id={id}
					/>
					<SubHeader user={profileData?.fullName} />
				</>
			)}
			<Profiles open={isOpen} id={id} handleClose={handleProfilesClose} anchorEl={anchorEl} />
			<Routes>
				<Route path="agriculture">
					<Route index element={<SignupMain />} />
					<Route path="home" element={<HomePage />} />
					<Route path="microblogging" element={<Microblogging />} />
					<Route path="client">
						<Route path="signup" element={<ClientSignup />} />
						<Route path="profile">
							<Route path="public" element={<ClientProfilePublic />} />
							<Route path="private" element={<ClientProfilePrivate />} />
						</Route>
					</Route>
					<Route path="company">
						<Route path="signup" element={<CompanySignup />} />
						<Route path="profile">
							<Route path="private" element={<CompanyProfilePrivate />} />
							<Route path="public" element={<CompanyProfilePublic />} />
						</Route>
					</Route>
					<Route path="freelancer">
						<Route path="signup" element={<FreelancerSignup />} />
						<Route path="profile">
							<Route path="private" element={<FreelancerProfilePrivate />} />
							<Route path="public" element={<FreelancerProfilePublic />} />
						</Route>
					</Route>
					<Route path="employee">
						<Route path="signup" element={<EmployeeSignup />} />
						<Route path="profile">
							<Route path="private" element={<EmployeeProfilePrivate />} />
							<Route path="public" element={<EmployeeProfilePublic />} />
						</Route>
					</Route>
					<Route path="search" element={<SearchResult />} />
				</Route>
			</Routes>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state.ag?.profile,
		signUpPopup: state.ag?.signUpPopup,
	};
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MODULE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_AG",
			payload: data,
		}),
});

export default connect(mapStateToProps, mapPropsToState)(AgricultureRoutes);
