import InputField, { CustomLabel } from "../../../../Inputs/Input";
import Textarea from "../../../../Inputs/Textarea";
import { FormTitleInfo, FormRowWrapper, Form, FormWrapper, FormTitle, BtnWrapper, btnStyleOverrides } from "../index.styled";
import { spacing } from "../../../../../styles/variables";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";
import Autocomplete from "../../../../Inputs/Autocomplete";
import PrimaryBtn from "../../../../Buttons/Primary";
import SecondaryBtn from "../../../../Buttons/Secondary";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
	firstName: Yup.string().required("First Name is required"),
	lastName: Yup.string().required("Last Name is required"),
});

const EmployeeSignUpForm = ({ setProfilePicture, onSubmit }) => {
	const navigate = useNavigate();
	const handleBack = () => {
		navigate(-1);
	};

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onSubmit(values);
		},
	});
	return (
		<FormWrapper onSubmit={formik.handleSubmit} onBlur={formik.handleBlur} onChange={formik.handleChange}>
			<FormTitle>Provide Your Employee Information</FormTitle>
			<Form>
				<FormTitleInfo>Personal Information</FormTitleInfo>
				<FormRowWrapper>
					<InputField
						name={"firstName"}
						label={"First Name"}
						required
						error={formik.touched.firstName && Boolean(formik.errors.firstName)}
						helperText={formik.touched.firstName && formik.errors.firstName}
					/>
					<InputField
						name={"lastName"}
						label={"Last Name"}
						required
						error={formik.touched.lastName && Boolean(formik.errors.lastName)}
						helperText={formik.touched.lastName && formik.errors.lastName}
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<Autocomplete name={"location"} label={"Location"} required onChange={formik.setFieldValue} />
					<InputFieldWithUpload name={"file_upload"} onChange={setProfilePicture} label={"Profile Picture"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"jobTitle"} label={"Current Job Title"} placeholder={"eg. Web Designer, Developer"} />
					<InputField name={"companyName"} label={"Company Name (If currently employed)"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"industrySector"} label={"Industry/Sector"} />
					<InputField name={"skill"} label={"Skills & Expertise"} />
				</FormRowWrapper>
				<FormRowWrapper
					childCount={1}
					style={{
						gridRowGap: spacing("md2"),
					}}
				>
					<CustomLabel>Education History</CustomLabel>
					<FormRowWrapper childCount={3} colGap={spacing("md1")}>
						<InputField name={"educationDegree"} placeholder={"Degree"} required />
						<InputField name={"educationInstitution"} placeholder={"Institution"} required />
						<InputField name={"gradYear"} placeholder={"Graduation Year"} required />
					</FormRowWrapper>
				</FormRowWrapper>
				<FormRowWrapper childCount={1}>
					<Textarea name={"about"} label={"About"} rows={3} mobileRows={6} />
				</FormRowWrapper>
			</Form>
			<BtnWrapper>
				<SecondaryBtn label={"Back"} onClick={handleBack} overrides={btnStyleOverrides} />
				<PrimaryBtn label={"Next"} overrides={btnStyleOverrides} />
			</BtnWrapper>
		</FormWrapper>
	);
};

export default EmployeeSignUpForm;
