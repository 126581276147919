import { useNavigate } from "react-router-dom";
import { InputAdornment, Popover } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import egicon1 from "../../../assets/images/icons/egicon1.svg";
import egicon2 from "../../../assets/images/icons/egicon2.svg";
import useLayout from "../../../utils/hooks/useLayout";
import { color, spacing } from "../../../styles/variables";
import {
	LeftNav,
	MobileTopNavs,
	MobileWrapper,
	NavigationLink,
	QuickNaviation,
	RightNav,
	Searchbar,
	SearchField,
	SubWrapper,
	Wrapper,
} from "./index.styled";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Chat from "../../../assets/icons/chat/Chat";
import { connect } from "react-redux";
import React, { Fragment, useEffect, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import Loader from "../../loader";
import styled from "@emotion/styled";
import User from "../../../assets/icons/User.svg";
import AddIcon from "@mui/icons-material/AddOutlined";
import UserWhite from "../../../assets/icons/UserWhite.svg";
import SidenavMobileComponent from "../../SidenavMobileComponent";

const Header = ({ setActiveMBModule, signUpPopup, setSignUpPopupToggle, userData, user, setActiveMBModuleProfile }) => {
	const isMobile = useLayout();
	const [usersData, setUsersData] = useState([]);
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [selectedProfileId, setSelectedProfileId] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const [isMessage, setIsMessage] = useState("");

	const { data: agricultureLastProfile, refetch } = useQuery({
		queryKey: ["AgricultureLastProfile"],
		queryFn: async () => {
			const response = await query("GET", "AG_PROFILE", "getUserLastAgricultureProfile");
			return response.data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	const handleProfileClick = (event) => {
		if (!userData?.data?.itProfileId) {
			setSignUpPopupToggle(!signUpPopup);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleProfileClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleSearch = () => {
		if (userData?.data?.itProfileId) {
			navigate("/agriculture/search");
		} else {
			setSignUpPopupToggle(!signUpPopup);
		}
	};

	const handleActiveModule = (name) => {
		setActiveMBModule(name);
		if (userData?.data?.agricultureId) {
			if (name === "shorts") {
				handleShortVideo();
			} else {
				handleMicroBlogging();
			}
		} else {
			setSignUpPopupToggle(!signUpPopup);
		}
	};

	useEffect(() => {
		const filteredData = usersData.filter((user) => user.agricultureId === agricultureLastProfile?.agricultureId);
		setSelectedProfileId(filteredData[0]?.agricultureId);
	}, [usersData, agricultureLastProfile]);

	const updateLatestAgricultureAccount = useMutation({
		mutationFn: async (id) => {
			try {
				const response = await query("PUT", "AG_PROFILE", "updateLastAgricultureProfile", id);
				return response.data;
			} catch (error) {
				console.error("Error:", error.message);
				throw error;
			} finally {
				// Code that runs regardless of success or failure
			}
		},
	});

	const handleUpdateProfile = (updateId) => {
		if (updateId) {
			updateLatestAgricultureAccount.mutateAsync(updateId);
		}
	};

	const toggleAddAccount = () => {
		navigate("/agriculture");
	};

	const handleNavigateProfile = (user) => {
		if (user) {
			const profile = user?.accountType.toLowerCase();
			navigate(`/agriculture/${profile}/profile/private`, { state: { userData: user } });
			handleProfileClose();
		}
	};

	const { refetch: getPrivateSVProfile } = useQuery({
		queryKey: ["AgriculturePrivateSVProfile", agricultureLastProfile],
		queryFn: async () => {
			const apiEndpoint = `getPrivateAgricultureShortVideoProfile?agricultureId=${agricultureLastProfile?.agricultureId}`;
			const response = await query("GET", "AG_PROFILE", apiEndpoint);
			return response;
		},

		refetchOnWindowFocus: false,
		enabled: false,
		refetchIntervalInBackground: false,
	});

	const handleShortVideo = () => {
		if (agricultureLastProfile) {
			getPrivateSVProfile().then((res) => {
				const { data } = res;
				const { status, data: profileData } = data;
				if (status === 200) {
					navigate("/shorts/profile/private", {
						// state: {
						// 	module: "ag",
						// 	profile: profileData,
						// },
					});
				}
			});
		}
	};

	const { refetch: getPrivateMBProfile } = useQuery({
		queryKey: ["AgriculturePrivateMBProfile", agricultureLastProfile],
		queryFn: async () => {
			const apiEndpoint = `getPrivateAgricultureMicrobloggingProfile?agricultureId=${agricultureLastProfile?.agricultureId}`;
			const response = await query("GET", "AG_PROFILE", apiEndpoint);
			return response;
		},

		refetchOnWindowFocus: false,
		enabled: false,
		refetchIntervalInBackground: false,
	});

	const handleMicroBlogging = () => {
		if (agricultureLastProfile) {
			getPrivateMBProfile().then((res) => {
				const { data } = res;
				const { status, data: profileData } = data;
				if (status === 200) {
					setActiveMBModuleProfile(profileData);
					navigate("/microblogging/profile/private", {
						state: {
							module: "ag",
							profile: profileData,
						},
					});
				}
			});
		}
	};

	return (
		<Wrapper>
			<SubWrapper isMobile={isMobile}>
				{isMobile ? (
					<MobileWrapper>
						<MobileTopNavs>
							<SearchIcon sx={{ color: color("pWhite"), width: 23 }} onClick={handleSearch} />
							<NotificationsIcon sx={{ color: color("pWhite"), width: 30 }} />
							<span onClick={() => navigate("/messaging")}>
								<Chat />
							</span>
							<span onClick={handleProfileClick}>
								<img src={UserWhite} alt="user-white" />
							</span>
							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={handleProfileClose}
								placement="bottom-end"
								sx={{
									right: 0,
									width: "50%",
									top: "42px",
									".MuiPaper-root": {
										borderRadius: 0,
										right: "-100%",
										left: "initial !important",
										width: "100%",
										padding: "12px",
									},
								}}
							>
								{user !== "Guest" && (
									<>
										{isLoader ? (
											<Loader />
										) : usersData?.length === 0 ? (
											<p>{isMessage}</p>
										) : (
											usersData?.map((user, index) => (
												<Fragment key={index}>
													<ProfileName>
														<img src={User} alt={"user-icon"} /> {user?.accountType}
													</ProfileName>
													<InputWrapper>
														<div onClick={() => handleUpdateProfile(user?.agricultureId)}>
															<input
																type="radio"
																name="userOption"
																value={user?.agricultureId}
																checked={user?.agricultureId === selectedProfileId}
																onChange={() => setSelectedProfileId(user?.agricultureId)}
															/>
														</div>
														<div
															onClick={() => {
																handleNavigateProfile(user);
																handleUpdateProfile(user?.agricultureId);
																setSelectedProfileId(user?.agricultureId);
															}}
														>
															<p>{user.firstName}</p>
														</div>
													</InputWrapper>
												</Fragment>
											))
										)}
										{usersData?.length > 0 && (
											<ButtonWrapper>
												<AddAccount onClick={toggleAddAccount}>
													Add Account <AddIcon />
												</AddAccount>
											</ButtonWrapper>
										)}
									</>
								)}
							</Popover>
							<SidenavMobileComponent module={"ag"} color={"#fff"} />
						</MobileTopNavs>
					</MobileWrapper>
				) : (
					<>
						<LeftNav>
							<NavigationLink>
								Jobs
								<ExpandMoreIcon />
							</NavigationLink>
							<NavigationLink>
								Talent
								<ExpandMoreIcon />
							</NavigationLink>
							<NavigationLink>
								Reports
								<ExpandMoreIcon />
							</NavigationLink>
							<NavigationLink to={"/messaging"}>Messages</NavigationLink>
						</LeftNav>
						<Searchbar onClick={handleSearch}>
							<SearchField
								onClick={handleSearch}
								startAdornment={
									<InputAdornment
										position="start"
										sx={{
											marginInlineEnd: spacing("xs"),
										}}
									>
										<SearchIcon color={color("dimGray")} />
									</InputAdornment>
								}
								placeholder="Search"
							/>
						</Searchbar>
						<RightNav>
							<QuickNaviation onClick={() => handleActiveModule("ag")}>
								<img src={egicon1} alt="" />
								Micro Blogging
							</QuickNaviation>
							<QuickNaviation onClick={() => handleActiveModule("shorts")}>
								<img src={egicon2} alt="" />
								Short Videos
							</QuickNaviation>
						</RightNav>
					</>
				)}
			</SubWrapper>
		</Wrapper>
	);
};

const ProfileName = styled.h5`
	color: #000;
	margin-block: 8px;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	display: inline-flex;
	align-items: center;
	column-gap: 12px;

	& > img {
		height: 16px;
		aspect-ratio: 1;
	}
`;

const InputWrapper = styled.div`
	display: flex;
	column-gap: 12px;
	margin: 8px 16px;

	& > div {
		display: inline-flex;
		align-items: center;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-block-start: 16px;
`;

const AddAccount = styled.button`
	width: 100%;
	text-align: center;
	border-radius: 8px;
	background: #3abf81;
	border: 1px solid transparent;
	display: inline-flex;
	justify-content: center;
	padding: 7px 10px;
	align-items: center;
	gap: 2px;
	color: #fff;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const mapStateToProps = (state) => {
	return {
		userData: state.ag?.profile,
		signUpPopup: state.ag?.signUpPopup,
	};
};
const mapPropsToState = (dispatch) => ({
	setActiveMBModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: data,
		}),
	setActiveMBModuleProfile: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE_PROFILE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_AG",
			payload: data,
		}),
});

export default connect(mapStateToProps, mapPropsToState)(Header);
