import React from "react";

const Freelancer = ({ color = "#61BCAC", size = 44 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 47 47" fill="none">
			<path
				d="M16.6519 23.3909H31.6588C32.0815 23.3909 32.3633 23.1091 32.3633 22.6864C32.3633 -7.46818 32.3633 2.95909 32.3633 0.704545C32.3633 0.281818 32.0815 0 31.6588 0H17.6383C15.736 0 14.186 1.55 14.186 3.45227C14.186 4.22727 14.186 -0.91591 14.186 20.9955C14.186 22.3341 15.3133 23.3909 16.6519 23.3909ZM30.9542 21.9818H16.6519C16.0883 21.9818 15.6656 21.5591 15.6656 20.9955C15.6656 20.925 15.6656 20.3614 15.6656 20.4318C15.6656 19.8682 16.0883 19.4455 16.6519 19.4455H30.9542V21.9818ZM30.9542 18.0364H19.611V1.40909H30.9542V18.0364ZM17.6383 1.40909H18.1315V18.0364H16.5815C16.2292 18.0364 15.8769 18.1068 15.5951 18.2477V3.45227C15.5951 2.325 16.511 1.40909 17.6383 1.40909Z"
				fill={color}
			/>
			<path
				d="M40.4658 9.86377C39.9022 9.86377 39.409 10.0751 39.0568 10.4274C38.2113 11.3433 36.8727 13.457 37.7886 16.9797C38.634 20.1501 37.2249 24.3069 32.6454 27.1956C30.8136 28.3229 28.559 29.732 27.7136 32.7615C26.7977 35.932 26.3045 40.9342 26.3045 41.1456C26.234 41.4979 26.5863 41.8501 26.9386 41.9206C27.2908 41.991 27.6431 41.6388 27.7136 41.2865C27.7136 41.216 28.2068 36.2138 29.0522 33.1842C29.8272 30.6479 31.5886 29.5206 33.4204 28.3933C35.8158 26.9138 37.6477 25.2229 38.7045 22.4047C39.5499 21.5592 40.7477 21.841 40.8886 22.5456C41.1704 24.3774 37.4363 28.816 35.1113 30.5774C34.8295 30.7888 34.759 31.2819 34.9704 31.5638C35.1818 31.8456 35.6749 31.916 35.9568 31.7047C37.9999 30.1547 42.7204 25.1524 42.2977 22.3342C42.0863 20.8547 40.6068 20.2206 39.2681 20.5729C39.5499 19.1638 39.4795 17.8251 39.1977 16.6979C38.4931 13.9501 39.3386 12.3297 40.1136 11.4138C40.2545 11.2729 40.3249 11.2729 40.3954 11.2729C40.5363 11.2729 40.6068 11.3433 40.6772 11.4138C42.0863 13.3865 45.3977 18.8115 45.1158 27.0547C44.9749 31.0706 41.5227 32.1979 37.0136 36.8479C36.8727 36.9888 33.4204 40.1592 33.7727 45.7956C33.8431 46.7115 35.2522 46.641 35.1818 45.7251C34.8295 40.7933 37.9295 37.9047 37.9999 37.8342C42.5795 33.1138 46.3136 31.8456 46.5249 27.1251C46.8772 18.4592 43.3545 12.682 41.8045 10.6388C41.5227 10.1456 41.0295 9.93422 40.4658 9.86377Z"
				fill={color}
			/>
			<path
				d="M13.1996 26.702C9.3246 24.1657 8.05642 19.9384 8.83142 17.0498C10.2405 11.6248 6.43596 8.31341 4.74505 10.6384C3.19505 12.6816 -0.327675 18.4589 0.0245981 27.1248C0.235962 31.9157 4.25187 33.3952 8.62005 37.9043C8.62005 37.9043 11.7201 40.793 11.3678 45.7248C11.3678 46.1475 11.6496 46.4293 12.0019 46.4998C12.4246 46.4998 12.7064 46.218 12.7769 45.8657C13.1291 40.2293 9.67687 37.0589 9.53596 36.918C4.95642 32.1975 1.64505 31.0702 1.43369 27.1248C1.15187 18.8816 4.46323 13.3861 5.87232 11.4134C6.50642 10.568 8.33823 13.1748 7.42233 16.627C7.14051 17.7543 7.07005 19.1634 7.35187 20.502C5.4496 20.0793 3.40642 21.4884 4.74505 24.377C5.73142 26.5611 7.84505 29.5907 10.7337 31.7043C11.0155 31.9157 11.5087 31.8452 11.7201 31.5634C11.9314 31.2816 11.861 30.7884 11.5791 30.577C8.90187 28.6043 6.92914 25.7861 6.01323 23.743C5.66096 22.8975 5.52005 22.2634 6.2246 21.9816C6.78823 21.7702 7.49278 21.9111 7.91551 22.4043C10.4519 28.8861 16.0882 28.1111 17.5678 33.1839C18.4837 36.2134 18.9064 41.2157 18.9064 41.2861C18.9769 41.7089 19.2587 41.9907 19.6814 41.9202C20.0337 41.8498 20.386 41.568 20.3155 41.1452C19.2587 29.802 17.5678 29.5907 13.1996 26.702Z"
				fill={color}
			/>
		</svg>
	);
};

export default Freelancer;
