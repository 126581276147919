import styled from "@emotion/styled";
import { Input } from "@mui/material";
import { color, spacing, borderRadius, toRem } from "../../styles/variables";

const MobileWrapper = styled.div`
	height: 100%;
	position: relative;
`;

const SearchWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-inline: ${spacing("sm1")};
	padding-block: ${spacing("xl2")} ${spacing("xl1")};

	& > svg {
		color: ${color("dimGray")};
	}
	@media screen and (max-width: 768px) {
		padding: 20px 0;
	}
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	margin-block: ${spacing("xl")};
	margin-inline: calc(${spacing("xxxl1")} * 2);
	grid-column-gap: ${spacing("lg")};
	@media screen and (max-width: 768px) {
		display: flex;
		width: 100%;
		justify-content: center;
		margin: ${spacing("xl")} 0 ${spacing("xxxl")};
	}
`;
const LeftSection = styled.section`
	grid-column: span 3;
	button {
		margin-inline-end: ${spacing("xxl")};
	}

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const CenterContent = styled.section`
	grid-column: span 7;
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("lg1")};

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-inline: ${spacing("md")};
	}
`;

const RightSection = styled.div`
	grid-column: span 2 /13;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const Searchbar = styled.div`
	width: 60%;
	padding-bottom:20px;
	.MuiInputBase-input {
		padding: 0;
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		padding-bottom:0px;
	}
`;
const SearchField = styled(Input)`
	background-color: white;
	width: 100%;
	padding-inline: ${spacing("md1")};
	padding-block: ${spacing("sm2")};
	border-radius: ${borderRadius(50)};
	box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.25);
	.MuiInputAdornment {
		&-positionStart {
			svg {
				color: ${color("dimGray")};
			}
		}
		&-positionEnd {
			width: 25%;

			.MuiInputBase-root {
				width: 100%;
			}
		}
	}
	@media screen and (max-width: 768px) {
		border: 1px solid ${color("grayishFaded")};
	}
`;

const ResultWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(5, 1fr);
	grid-gap: ${spacing("lg1")};
	margin-block: ${spacing("xl")};
	margin-inline: ${spacing("sm")};
	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		grid-gap: ${spacing("xs1")};
		margin-block: 0;
		padding-block-end: ${spacing("lg")};
		padding-top: 20px;
		margin-inline: ${spacing("sm2")};
	}
`;

const Result = styled.img`
	cursor: pointer;
	width: 100%;
	height: fit-content;
	object-fit: contain;
`;

const ProfileWrapper = styled.div`
	width: 60%;
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("sm2")};

	@media screen and (max-width: 768px) {
		row-gap: ${spacing("xl")};
		width: -webkit-fill-available;
		display: flex;
		flex-direction: column;
		padding: ${spacing("xl")};
	}
`;

const UserImage = styled.img`
	border-radius: 50%;
	margin-inline-start: ${spacing("md2")};
	width: 128px;
	aspect-ratio: 1;
	@media screen and (max-width: 768px) {
		margin: 0;
	}
`;

const EditIcon = styled.div`
	position: absolute;
	bottom: 0;
	right: 0;

	display: flex;
	transform: translate(20%, 20%);

	background-color: ${color("pGreen")};
	border-radius: 50%;
	padding: ${spacing("md")};
`;

const UserDetailsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		row-gap: ${spacing("sm1")};

		& > button {
			padding-inline: ${spacing("xl")};
			padding-block: ${spacing("sm2")};
			font-size: 20px;
			font-weight: 600;
		}
	}
`;

const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("xs1")};
	@media screen and (max-width: 768px) {
		row-gap: ${spacing("sm1")};
	}
`;

const Btnwrapper = styled.div`
		display:flex;
		justify-content: space-between;
		width:90%;
`;

const UserName = styled.span`
	color: ${color("pBlack")};
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: 768px) {
		font-weight: 400;
		display:flex;
		align-items:center;
		& > img{
			width:20px;
			padding: 0 8px 0 0;
		}
		
	}
`;

const StatsWrapper = styled.div`
	display: flex;
	column-gap: ${spacing("md4")};
	@media screen and (max-width: 768px) {
		justify-content: center;
	}
`;

const Stat = styled.span`
	color: ${color("lightGray")};
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	@media screen and (max-width: 768px) {
		font-size: 18px;
		font-weight: 600;
		text-align: center;
		display: flex;
		flex-direction: column;
		row-gap: ${spacing("xs1")};
		color: ${color("pBlack")};
		& > span {
			color: ${color("lightGray")};
		}
	}
`;

const OtherProfilesWrapper = styled.div`
	border-radius: ${borderRadius(6)};
	border: 1px solid ${color("sBlackLight")};
	background: ${color("pWhite")};
	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
	padding: ${spacing("md1")};
	margin-block-start: ${spacing("xl2")};
`;

const ProfilesHeader = styled.h4`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Butt = styled.button`
	width: 90%;
	outline: none;
	cursor: pointer;
	color: white;
	font-size: 1vw;
	padding: 0.8vw 0;
	border-radius: 0.7vw;
	border: 1px solid transparent;
	background: #256f61;
	margin-top:10px;

	@media screen and (max-width: 768px) {
		font-size: 12px;
		padding: 12px 0;
		border-radius: 8px;
	}
`;

const Profiles = styled.div`
	margin-block: ${spacing("md1")};
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("sm1")};
`;

const Profile = styled.span`
	color: ${color("sBlackFadedLight")};
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.28px;
	span {
		color: ${color("lightGrayFaded")};
	}
`;

const ManageLinks = styled.div`
	cursor: pointer;
	width: max-content;
	margin-inline-start: auto;
	color: ${color("lightGreyFaded")};
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-decoration-line: underline;
`;

const ViewIcons = styled.div`
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: ${spacing("md")};
	column-gap: ${spacing("lg")};

	& > svg {
		font-size: ${toRem(36)};
		color: ${color("grayish")};
	}
`;
const TabPanelWrapper = styled.div`
	// margin-block: ${spacing("xl1")};
`;

export {
	MobileWrapper,
	Wrapper,
	LeftSection,
	CenterContent,
	RightSection,
	Searchbar,
	SearchField,
	ResultWrapper,
	Result,
	ProfileWrapper,
	UserImage,
	UserDetailsWrapper,
	UserDetails,
	EditIcon,
	UserName,
	StatsWrapper,
	Stat,
	OtherProfilesWrapper,
	ProfilesHeader,
	Profiles,
	Profile,
	ManageLinks,
	SearchWrapper,
	ViewIcons,
	Butt,
	TabPanelWrapper,
	Btnwrapper,
};
