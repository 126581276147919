import { Fragment } from "react";
import InputField from "../../../../Inputs/Input";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";
import Textarea from "../../../../Inputs/Textarea";
import { FormContainer, FormTitleInfo, FormRowWrapper } from "../index.styled";

const CompanySignUpForm = () => {
	return (
		<Fragment>
			<FormContainer>
				<FormTitleInfo>Company Basic Information</FormTitleInfo>
				<FormRowWrapper>
					<InputField name={"companyName"} label={"Company Name"} required />
					<InputField
						name={"typeOfBusiness"}
						label={"Type of Business"}
						placeholder={"LLC, Corporation, Partnership"}
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"industry"} label={"Industry Sector"} />
					<InputField name={"companySize"} label={"Company Size"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"location"} label={"Location"} placeholder={"eg. Austria"} />
					<InputFieldWithUpload name={"companyLogo"} label={"Company Logo"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"websiteUrl"} label={"Website URL"} />
				</FormRowWrapper>
				<FormRowWrapper childCount={1}>
					<Textarea name={"about"} label={"About"} rows={4} mobileRows={8} />
				</FormRowWrapper>
			</FormContainer>
		</Fragment>
	);
};

export default CompanySignUpForm;
