import React, { useContext, useState } from "react";
import "./ReviewComponent.scss";
import { Box, Modal, Rating, Typography } from "@mui/material";
import defaultprofile from "../../assets/icons/user-profile.png";
import comment from "../../assets/images/profile/comment.png";
import chatbotnewchat from "../../assets/images/icons/chatbotnewchat.png";
import cancel from "../../assets/images/icons/cancel.png";
import { UserContext } from "../../store/UserDetailsStore";
import { ReplyGPT, ReplyUser } from "../../utils/apiCalls";
import loader from "../../assets/icons/loading.png";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	maxwidth: 400,
	bgcolor: "background.paper",
	boxShadow: 24,
	p: 2,
};

const ReviewComponent = ({ ReviewerId, isCurrentUser, review, profile, heading, name, commentimage, commenttitle, stars }) => {
	const [open, setOpen] = React.useState(false);
	const handleClose = () => setOpen(false);
	const { isLoginUserDetails } = useContext(UserContext);
	const [isPopupVisible, setPopupVisibility] = useState(false);
	const [alertReviewMessage, setAlertReviewMessage] = useState("");
	const [reviewGPT, setReviewGPT] = useState("");
	const [reviewText, setReviewText] = useState({
		text: "",
	});
	const [isResponse, setIsResponse] = useState("");

	const handleClearReview = () => {
		setReviewText({
			text: "",
		});
		setReviewGPT("");
	};

	const togglePopup = () => {
		handleClearReview();
		setPopupVisibility(!isPopupVisible);
		document.body.style.overflow = "auto";
	};

	const handleReviewChange = (e) => {
		const { name, value } = e.target;
		setReviewText({
			...reviewText,
			[name]: value,
		});
		const textarea = e.target;
		textarea.style.height = "auto";
		textarea.style.height = `${textarea.scrollHeight - 11}px`;
	};

	const handleReviewGPT = () => {
		debugger;
		console.log(ReviewerId);
		if (ReviewerId) {
			const payload = {
				reviewId: ReviewerId,
				reply: reviewText?.text,
			};
			setIsResponse(true);
			ReplyGPT((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setReviewGPT(data);
					setIsResponse(false);
					setReviewText({
						text: "",
					});
				} else if (status === 400) {
					setOpen(true);
					setAlertReviewMessage(message);
				}
			}, payload);
		}
	};

	const handleCancelReview = () => {
		const textarea = document.querySelector(".hemant");
		textarea.style.height = "29px";
		setIsResponse(false);
		handleClearReview();
	};

	const handlePostReview = () => {
		if (ReviewerId) {
			const payload = {
				reviewId: ReviewerId,
				reply: reviewGPT,
			};
			ReplyUser((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					window.location.href = "/privateprofile";
					setPopupVisibility(!isPopupVisible);
					handleClearReview();
				}
			}, payload);
		}
	};

	return (
		<div className="review">
			<div className="review_container">
				<div className="review_heading">
					<div className="review_heading_left">
						<img src={profile || defaultprofile} alt="profile" />
						<div className="review_heading_left_user">
							<div className="review_heading_left_user_name">
								<h6>{name}</h6>
							</div>
							<div className="review_heading_left_user_date">
								<p>{heading}</p>
								<span>9 oct 2023</span>
							</div>
						</div>
						<div className="review_heading_left_stars">
							<Rating name="read-only" value={stars} readOnly />
						</div>
					</div>
					<div className="review_heading_right">
						<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 17 17" fill="none">
							<path
								d="M0.153321 14.1209L14.0745 0.199707L16.4369 2.56209L2.5157 16.4833L0.153321 14.1209ZM0.15332 2.56209L2.5157 0.199707L16.4369 14.1209L14.0745 16.4833L0.15332 2.56209Z"
								fill="#BFBCBC"
							/>
						</svg>
					</div>
				</div>
				<div className="review_content">{review}</div>
				<div className="review_bottom">
					{commenttitle === "" ? (
						isCurrentUser && (
							<div className="review_bottom_comment">
								<img src={comment} alt="comment" />
								<p onClick={togglePopup}>Reply</p>
							</div>
						)
					) : (
						<div className="review_bottom_comment">
							<img src={commentimage} alt="comment" />
							<p>{commenttitle}</p>
						</div>
					)}
				</div>
			</div>
			{isPopupVisible && (
				<div>
					<div className="popup">
						<div className="popupclose">
							<img src={cancel} className="close-button" onClick={togglePopup} alt="Close Popup" />
						</div>
						<div className="popup_top">
							<div className="popup_top_rating">
								<span className="popup_top_content_postbutton mobilepostbutton" onClick={handleCancelReview}>
									Cancel
								</span>
								<span className="popup_top_content_postbutton mobilepostbutton" onClick={handlePostReview}>
									Post Review
								</span>
							</div>
							<div className="popup_top_content">
								{reviewGPT ? <p>{reviewGPT}</p> : <p className="popup_top_content_text">{review}</p>}
								<div className="popup_top_content_postbuttons">
									<span className="popup_top_content_postbutton" onClick={handlePostReview}>
										Post Reply
									</span>
									<span className="popup_top_content_postbutton" onClick={handleCancelReview}>
										Cancel
									</span>
								</div>
							</div>
						</div>
						<div className="popup_bottom">
							<div className="popup_bottom_type">
								<textarea
									name="text"
									rows={1}
									value={reviewText.text}
									onChange={(e) => {
										handleReviewChange(e);
									}}
									className="hemant"
									maxLength={250}
									placeholder={`${isLoginUserDetails?.name} Reply`}
									style={{ overflow: "hidden", resize: "none" }}
								/>
								<div className="count">{reviewText.text.length} / 250</div>
							</div>

							<div className="popup_bottom_image" onClick={handleReviewGPT}>
								{isResponse === true ? (
									<img className="loader" src={loader} alt="Loading" />
								) : (
									<img src={chatbotnewchat} alt="" />
								)}
							</div>
						</div>
					</div>
					<div className="blurred-background" onClick={togglePopup}></div>
				</div>
			)}
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Typography id="modal-modal-description" sx={{ fontSize: "1vw" }}>
						{alertReviewMessage}
					</Typography>
					<div
						style={{
							display: "flex",
							justifyContent: "space-evenly",
							marginTop: "1vw",
						}}
						className="modal_button"
					>
						<button className="modalbutton" onClick={handleClose}>
							OK
						</button>
					</div>
				</Box>
			</Modal>
		</div>
	);
};

export default ReviewComponent;
