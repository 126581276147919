import React from "react";
import { Rightdiv2, Rightdiv2Content, Rightdiv2Head } from "../../../pages/MB/styles/index.styled";

const MBRightTrend = () => {
	return (
		<>
			<Rightdiv2>
				<Rightdiv2Head>
					<h2>What's Happening</h2>
				</Rightdiv2Head>
				<Rightdiv2Content>
					<span>Politics-Trending</span>
					<p>Big Breaking</p>
					<span>25.5K</span>
				</Rightdiv2Content>
				<Rightdiv2Content>
					<span>Trending</span>
					<p>Harvard</p>
					<span>208K</span>
				</Rightdiv2Content>
			</Rightdiv2>
		</>
	);
};

export default MBRightTrend;
