import {
	Action,
	ChannelCard,
	ChannelInfo,
	ChannelStats,
	ChannelTitle,
	ChannelsWrapper,
	LiveActionsWrapper,
	LiveThumbnail,
	LiveTitle,
	TVWrapper,
	ThumbnailTitle,
	Wrapper,
} from "./index.styled";
import LiveTV from "../../../assets/images/tv/LiveTV.png";
import SentimentSatisfiedAltSharpIcon from "@mui/icons-material/SentimentSatisfiedAltSharp";
import Comment from "../../../assets/icons/Comment";
import Share from "../../../assets/icons/Share";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { videos } from "../Profile";

function createData(id, name, date, amount) {
	return { id, name, date, amount: `$${amount}` };
}

const rows = [
	createData(48592, "Mike Mady", "04/12/2024", 354),
	createData(48592, "Mike Mady 1", "04/12/2024", 354),
	createData(48592, "Mike Mady 2", "04/12/2024", 354),
];

const Live = () => {
	return (
		<Wrapper>
			<LiveTitle>Live</LiveTitle>
			<TVWrapper>
				<LiveThumbnail src={LiveTV} alt="Live Thumbnail" />
				<LiveActionsWrapper>
					<Action>
						<SentimentSatisfiedAltSharpIcon
							sx={{
								color: "#848484",
							}}
						/>{" "}
						Like
					</Action>
					<Action>
						<Comment /> Comment
					</Action>
					<Action>
						<Share /> Share
					</Action>
				</LiveActionsWrapper>
				<TableContainer
					component={Paper}
					elevation={0}
					sx={{
						maxHeight: "35%",
						overflowY: "auto",
					}}
				>
					<Table
						sx={{
							borderCollapse: "separate",
							borderSpacing: "0 12px",
							".MuiTableCell-root": {
								border: "none",
								paddingBlock: "12px",
								"&:first-child": {
									borderTopLeftRadius: "inherit",
									borderBottomLeftRadius: "inherit",
								},
								"&:last-child": {
									borderTopRightRadius: "inherit",
									borderBottomRightRadius: "inherit",
								},
							},
							".MuiTableRow-root": {
								borderRadius: "8px !important",
							},
						}}
					>
						<TableHead
							sx={{
								background: "transparent",
							}}
						>
							<TableRow>
								<TableCell>Id</TableCell>
								<TableCell align="center">Name</TableCell>
								<TableCell align="center">Date</TableCell>
								<TableCell align="right">Amount</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{rows.map((row) => (
								<TableRow
									key={row.name}
									sx={{
										borderRadius: "8px !important",
										background: "#E8F8F5",
										boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.025)",
									}}
								>
									<TableCell>{row.id}</TableCell>
									<TableCell align="center">{row.name}</TableCell>
									<TableCell align="center">{row.date}</TableCell>
									<TableCell align="right">{row.amount}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			</TVWrapper>
			<ChannelsWrapper>
				{videos.map((video, index) => (
					<ChannelCard key={index}>
						<LiveThumbnail src={video.thumbnail} alt="Channel Thumbnail" />
						<ChannelInfo>
							<ThumbnailTitle>{video.title}</ThumbnailTitle>
							<ChannelTitle>{video.channel}</ChannelTitle>
							<ChannelStats>{`${video.views}. ${video.dated}`}</ChannelStats>
						</ChannelInfo>
					</ChannelCard>
				))}
			</ChannelsWrapper>
		</Wrapper>
	);
};

export default Live;
