import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import HomePage from "../pages/Education/Home";
import ClientSignup from "../pages/Education/Client/Signup";
import CompanySignup from "../pages/Education/Company/Signup";
import FreelancerSignup from "../pages/Education/Freelancer/Signup";
import FreelancerProfilePrivate from "../pages/Education/Freelancer/Profile/Private";
import FreelancerProfilePublic from "../pages/Education/Freelancer/Profile/Public";
import EmployeeSignup from "../pages/Education/Employee/Signup";
import SearchResult from "../pages/Education/SearchResult";
import CompanyProfilePrivate from "../pages/Education/Company/Profile/Private";
import CompanyProfilePublic from "../pages/Education/Company/Profile/Public";
import ClientProfilePrivate from "../pages/Education/Client/Profile/Private";
import ClientProfilePublic from "../pages/Education/Client/Profile/Public";
import EmployeeProfilePrivate from "../pages/Education/Employee/Profile/Private";
import EmployeeProfilePublic from "../pages/Education/Employee/Profile/Public";
import Heading from "../components/Heading/Heading";
import Education from "../assets/icons/education/education";
import SubHeader from "../components/Education/Header/index";
import SignupMain from "../pages/Education/SingupMain";
import Microblogging from "../components/Education/Microblogging";
import RegistrationModal from "../components/IT/RegistrationModal";
import { connect } from "react-redux";
import EducationProfile from "../components/Education/EducationProfile";

const EducationRoutes = ({ userData, setActiveModule, setSignUpPopupToggle, signUpPopup }) => {
	const location = useLocation();
	const { pathname } = location;
	const [isEducation, setIsEducation] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path?.includes("education") && !path.includes("microblogging") && !path.includes("signup") && path?.length > 1) {
			setActiveModule("education");
			localStorage.setItem("activeModule", "Education");
			setIsEducation(true);
		} else setIsEducation(false);
	}, [pathname]);

	const handleClick = (event) => {
		if (!userData?.data?.fullName) {
			setSignUpPopupToggle(true);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleClose = () => {
		setAnchorEl(false);
	};

	const isOpen = Boolean(anchorEl);
	const id = isOpen ? "simple-popover" : undefined;
	return (
		<>
			<RegistrationModal open={signUpPopup} handleClose={() => setSignUpPopupToggle(false)} />
			{isEducation && !userData?.isLoading && (
				<>
					<Heading
						icon={Education}
						title={"Education"}
						moduleHomeRedirect={"/education/home"}
						user={userData?.data?.fullName}
						handleClick={handleClick}
						anchorEl={anchorEl}
						id={id}
					/>

					<SubHeader user={userData?.data.fullName} />
				</>
			)}
			<EducationProfile open={isOpen} id={id} handleClose={handleClose} anchorEl={anchorEl} />
			<Routes>
				<Route path="education">
					<Route index element={<SignupMain handleClick={handleClick} id={id} />} />
					<Route path="home" element={<HomePage />} />
					<Route path="microblogging" element={<Microblogging />} />
					<Route path="client">
						<Route path="signup" element={<ClientSignup />} />
						<Route path="profile">
							<Route path="public" element={<ClientProfilePublic />} />
							<Route path="private" element={<ClientProfilePrivate />} />
						</Route>
					</Route>
					<Route path="company">
						<Route path="signup" element={<CompanySignup />} />
						<Route path="profile">
							<Route path="private" element={<CompanyProfilePrivate />} />
							<Route path="public" element={<CompanyProfilePublic />} />
						</Route>
					</Route>
					<Route path="freelancer">
						<Route path="signup" element={<FreelancerSignup />} />
						<Route path="profile">
							<Route path="private" element={<FreelancerProfilePrivate />} />
							<Route path="public" element={<FreelancerProfilePublic />} />
						</Route>
					</Route>
					<Route path="employee">
						<Route path="signup" element={<EmployeeSignup />} />
						<Route path="profile">
							<Route path="private" element={<EmployeeProfilePrivate />} />
							<Route path="public" element={<EmployeeProfilePublic />} />
						</Route>
					</Route>
					<Route path="search" element={<SearchResult />} />
				</Route>
			</Routes>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state?.education?.profile,
		signUpPopup: state.education?.signUpPopup,
	};
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MODULE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_EDUCATION",
			payload: data,
		}),
});
export default connect(mapStateToProps, mapPropsToState)(EducationRoutes);
