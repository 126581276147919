import styled from "@emotion/styled";
import { borderRadius, color, spacing, typography } from "../../styles/variables";

const Wrapper = styled.div`
	margin-inline: ${spacing("xxxl1")};
	margin-block: ${spacing("xxl1")};

	display: grid;
	grid-template-columns: repeat(12, 1fr);

	@media screen and (max-width: 768px) {
		margin-inline: ${spacing("md")};
		margin-block: ${spacing("xl")};
	}

	@media screen and (min-width: 1440px) {
		max-width: 1440px;
		margin-inline: auto;
	}
`;

const ScreenTitleWrapper = styled.div`
	grid-row: 1;
	grid-column: span 12;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-block-end: ${spacing("lg")};

	@media screen and (max-width: 768px) {
		position: relative;
		margin-block-end: ${spacing("xl2")};
	}
`;

const ScreenTitle = styled.h2`
	color: ${color("pGreen")};
	display: inline-flex;
	align-items: center;

	.screen-title {
		display: block ${typography("heading", "lg")};
	}

	.mobile-title {
		display: none;
	}

	& > svg {
		width: 36px;
		margin-inline-start: ${spacing("sm")};
		height: 100%;
	}

	@media screen and (max-width: 768px) {
		.screen-title {
			display: none;
		}

		.mobile-title {
			${typography("heading", "lg")};
			display: block;
		}

		& > svg {
			width: 24px;
		}
	}
`;

const PostJob = styled.button`
	display: inline-flex;
	align-items: center;
	column-gap: 4px;
	cursor: pointer;
	border-radius: ${borderRadius(8)};
	background: ${color("freshGreen")};
	border: 2px solid ${color("freshGreen")};

	color: ${color("pWhite")};
	${typography("body", "md", false)}
	padding: ${spacing("sm")} ${spacing("md")};

	&:hover {
		background-color: ${color("pWhite")};
		color: ${color("freshGreen")};
	}

	@media screen and (max-width: 768px) {
		//position: absolute;
		bottom: 0;
		right: 0;
	}
`;

const SubWrapper = styled.div`
	grid-row: 2;
	grid-column: span 12;
`;
const SectionWrapper = styled.div`
	&:not(:last-child) {
		margin-block-end: ${spacing("lg")};
	}
`;
const SectionTitle = styled.h3`
	color: ${color("pGreen")};
	${typography("heading", "lg")}
	margin-block-end: ${spacing("sm")};

	@media screen and (max-width: 768px) {
		margin-block-end: ${spacing("xs")};
	}
`;

const CardWrapper = styled.div`
	width: 100%;
	border-radius: ${borderRadius(6)};
	background: ${color("pWhite")};
	box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.25);

	margin-block: ${spacing("sm2")};
	padding-inline: ${spacing("lg")};
	padding-block: ${spacing("xl")};

	display: flex !important;
	flex-direction: column;
	row-gap: ${spacing("md1")};

	@media screen and (max-width: 768px) {
		padding-inline: ${spacing("lg")};
	}
`;

const UserDetailsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: ${spacing("sm2")};
	align-items: center;

	@media screen and (max-width: 768px) {
		column-gap: ${spacing("sm1")};
	}
`;

const UserImage = styled.img`
	border-radius: 50%;
	height: 54px;
	aspect-ratio: 1;
`;

const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
`;

const UserName = styled.h4`
	color: ${color("pBlack")};
	${typography("body", "lg", false)}
`;

const OriginCountry = styled.span`
	color: ${color("dimGray")};
	${typography("body", "xsm", false)}
`;

const IconWrapper = styled.div`
	margin-inline-start: auto;
	cursor: pointer;

	& > svg {
		width: 24px;
		height: 24px;
	}
`;

const StackWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("md1")};
	padding: 1vw 0;
`;

const Role = styled.h3`
	color: ${color("pBlack")};
	${typography("body", "lg", true)}
	font-weight: 500;
`;

const Rate = styled.span`
	display: inline-flex;
	align-items: center;
	column-gap: ${spacing("sm")};

	color: ${color("dimGray")};
	${typography("body", "md", false)}

	& > svg {
		height: 24px;
		width: auto;
	}
`;

const TechStacks = styled.div`
	display: flex;
	column-gap: ${spacing("sm2")};
	row-gap: ${spacing("md1")};
	flex-wrap: wrap;
`;

const Stack = styled.div`
	border-radius: ${borderRadius(6)};
	background: ${color("dimGrey")};
	padding: ${spacing("sm2")} ${spacing("md")};

	color: "#626262";
	${typography("body", "vsm", false)};

	@media screen and (max-width: 768px) {
		padding: ${spacing("sm1")};
	}
`;

const Button = styled.button`
	cursor: pointer;
	width: 100%;
	border-radius: ${borderRadius(40)};
	border: 2px solid ${color("pGreen")};
	padding-block: ${spacing("sm")};
	text-align: center;

	color: ${color("pBlack")};
	${typography("body", "md", false)}

	transition: 300ms all;

	margin-block-start: auto;

	&:hover {
		color: ${color("pWhite")};
		background-color: ${color("pGreen")};
	}
`;

export {
	Wrapper,
	ScreenTitleWrapper,
	ScreenTitle,
	PostJob,
	SubWrapper,
	SectionWrapper,
	SectionTitle,
	CardWrapper,
	UserDetailsWrapper,
	UserImage,
	UserDetails,
	UserName,
	OriginCountry,
	IconWrapper,
	StackWrapper,
	Role,
	Rate,
	TechStacks,
	Stack,
	Button,
};
