import React, { useState } from "react";
import "./SVHeading.scss";
import logo from "../../../assets/icons/buzzgalactic/logo.svg";
import { Avatar, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import avatar from "../../../assets/images/Educationprofile.png";
import message from "../../../assets/icons/message-short.png";
import comment from "../../../assets/icons/comment-short.png";
import { NavLink, useNavigate } from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import support from "../../../assets/images/MB/support.png";
import MenuIcon from "@mui/icons-material/Menu";
import profileicon from "../../../assets/icons/user_white.png";
import techicon from "../../../assets/images/icons/Group 11.png";
import settingsicon from "../../../assets/images/icons/Group.png";

const SVHeading = ({ icon, title, showSearchInput, showUpload, user}) => {
	const navigate = useNavigate();
	const handleHome = () => {
		navigate("/home");
	};

	const [isDropdownOpen, setDropdownOpen] = useState(false);
	const [dropdownPosition, setDropdownPosition] = useState("right");

	const toggleDropdown = () => {
		setDropdownOpen(!isDropdownOpen);
		setDropdownPosition(dropdownPosition === "right" ? "left" : "right");
	};

	const handleModuleRedirect = () => {
		navigate("/shorts/feed");
	};

	return (
		<>
			<div className="svheading">
				<div className="svheading_container">
					<div className="svheading_sections">
						<div className="svheading_left">
							<img src={logo} alt="logo" className="img" onClick={handleHome} />
							{icon && (
								<div className="svheading_left_content"  onClick={handleModuleRedirect}>
									<img src={icon} alt="logo" className="image" />
									<h3>{title}</h3>
								</div>
							)}
						</div>
						{showSearchInput && (
							<div className="svheading_right">
								<TextField
									className="input"
									variant="standard"
									sx={{ width: "95%", background: "#fff", borderRadius: "1vw" }}
									InputProps={{
										startAdornment: (
											<InputAdornment position="end">
												<SearchIcon sx={{ width: "1vw" }} />
											</InputAdornment>
										),
									}}
								/>
							</div>
						)}

						{user && (
							<div className="svheading_mobile">
								<ul>
									<li>
										<NavLink to="/">
											<PersonOutlineIcon />
										</NavLink>
									</li>
									<li>
										<p>{user}</p>
									</li>
								</ul>
							</div>
						)}

						{showUpload && (
							<div className="svheading_middle">
								<div className="upload">+ Upload</div>
								<div className="svheading_middle_content">
									<img src={message} alt="msg" />
									<img src={comment} alt="comment" />
									<Avatar alt="Remy Sharp" src={avatar} />
								</div>
							</div>
						)}
						{user && (
							<div className="svheading_user">
								<ul>
									<li>
										<NavLink>
											<NotificationsNoneIcon />
										</NavLink>
									</li>
									<li>
										<NavLink to={"/shorts/profiles"}>
										<PersonOutlineIcon />
										</NavLink>
									</li>
									<li>
										<NavLink to={"/shorts/profiles"}>
											<p>{user}</p>
										</NavLink>
									</li>
								</ul>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="svheading_logo">
				<div className="svheading_logo_container">
					<div className="svheading_logo_left">
						{/* <img src={icon} alt="logo" className="image" /> */}
						{/* <h3>{title}</h3> */}
						<MenuIcon sx={{ color: "White", width: 30 }}/>
					</div>
					<div className="svheading_logo_right">
						<img src={profileicon} alt="" />
						<img src={techicon} alt="" />
						<img src={settingsicon} alt="" />
					</div>
				</div>
			</div>
			
		</>
	);
};

export default SVHeading;
