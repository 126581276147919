import { useTheme } from "@mui/joy";
import { useMediaQuery } from "@mui/material";

const useLayout = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery("(max-width:768px)");
	return isMobile;
};

export default useLayout;
