import { useState } from "react";
import styled from "@emotion/styled";
import Input from "../../Inputs/Input";
import PrimaryBtn from "../../Buttons/Primary";
import { UserResetPassword } from "../../../utils/apiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";

const validationSchema = yup.object({
	email: yup.string().email("Enter your Email").required("Email is required"),
	password: yup
		.string("Enter your password")
		.min(4, "Password should be of minimum 4 characters length")
		.required("Password is required."),
	confirmPassword: yup
		.string()
		.oneOf([yup.ref("password"), null], "Passwords must match")
		.required("Confirm Password is required"),
});

const ResetPasswordForm = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [showConfirmPassword, setShowConfirmPassword] = useState(false);
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const tokenFromUrl = searchParams.get("token");

	const navigate = useNavigate();
	const [isShowLoader, setIsShowLoader] = useState(false);
	const formik = useFormik({
		initialValues: {
			email: "",
			password: "",
			confirmPassword: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			setIsLoading(true);
			let payload = {
				email: values?.email,
				password: values?.password,
				token: tokenFromUrl,
			};
			if (payload) {
				setIsShowLoader(true);
				UserResetPassword((res) => {
					const { status } = res;
					if (status === 200) {
						setIsShowLoader(false);
						navigate("/login");
					} else {
						setIsShowLoader(false);
					}
				}, payload);
			}
		},
	});

	// const handleShowPassword = () => {
	// 	setShowPassword((prev) => !prev);
	// };
	// const handleShowConfirmPassword = () => {
	// 	setShowConfirmPassword((prev) => !prev);
	// };

	return (
		<Wrapper onChange={formik.handleChange} onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
			<Title>Reset your Password</Title>
			<FormWrapper>
				<FormTitle>Enter a new password below</FormTitle>
				<Input
					name={"email"}
					placeholder={"Email"}
					required
					error={formik.touched.email && Boolean(formik.errors.email)}
					helperText={formik.touched.email && formik.errors.email}
				/>
				<Input
					name={"password"}
					placeholder={"Password"}
					type={showPassword ? "text" : "password"}
					error={formik.touched.password && Boolean(formik.errors.password)}
					helperText={formik.touched.password && formik.errors.password}
					required
					// inputProps={{
					// 	endAdornment: (
					// 		<InputAdornment position="end">
					// 			<IconButton aria-label="toggle password visibility" edge="end" onClick={handleShowPassword}>
					// 				{showPassword ? <Visibility /> : <VisibilityOff />}
					// 			</IconButton>
					// 		</InputAdornment>
					// 	),
					// }}
				/>
				<Input
					name={"confirmPassword"}
					placeholder={"Confirm Password"}
					type={showConfirmPassword ? "text" : "password"}
					error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
					helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
					required
					// inputProps={{
					// 	endAdornment: (
					// 		<InputAdornment position="end">
					// 			<IconButton
					// 				aria-label="toggle password visibility"
					// 				edge="end"
					// 				onClick={handleShowConfirmPassword}
					// 			>
					// 				{showConfirmPassword ? <Visibility /> : <VisibilityOff />}
					// 			</IconButton>
					// 		</InputAdornment>
					// 	),
					// }}
				/>
				<PrimaryBtn
					label={"Reset Password"}
					overrides={{
						borderRadius: "40px",
						paddingBlock: "12px",
						fontSize: "16px",
					}}
					loading={isShowLoader}
				/>
			</FormWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.form`
	grid-column: 4 / span 6;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	background-color: red;
	min-width: 40dvw;

	background: #fff;
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
	border-radius: 7px;

	padding: 20px;
	height: max-content;

	@media screen and (max-width: 768px) {
		padding-block: 20px;
		max-width: initial;
		width: -webkit-fill-available;
	}
`;

const Title = styled.h1`
	color: #000;
	font-family: Poppins;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 128%; /* 46.08px */

	padding-block-end: 18px;
	text-align: center;
	width: 100%;

	border-bottom: 1px solid #dedede;

	@media screen and (max-width: 768px) {
		font-size: 26px;
	}
`;

const FormWrapper = styled.div`
	width: 90%;
	display: flex;
	flex-direction: column;

	margin-inline: auto;

	@media screen and (max-width: 768px) {
		width: -webkit-fill-available;
		margin-inline: 0;
	}
`;

const FormTitle = styled.h2`
	color: #515151;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 157%; /* 34.54px */

	margin-inline: auto;
	margin-block: 18px;

	@media screen and (max-width: 768px) {
		margin-block: 20px;
	}
`;

export default ResetPasswordForm;
