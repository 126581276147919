import education from "../../../../assets/images/MB/Education.png";
import agriculture from "../../../../assets/images/MB/agriculture.png";
import transport from "../../../../assets/images/MB/transport.png";
import art from "../../../../assets/images/MB/art.png";
import information from "../../../../assets/images/MB/information.png";
import financial from "../../../../assets/images/MB/financial.png";
import transport2 from "../../../../assets/images/MB/transport2.png";
import hospitality from "../../../../assets/images/MB/hospitality.png";
import environmental from "../../../../assets/images/MB/environmental.png";
import construction from "../../../../assets/images/MB/construction.png";
import realestate from "../../../../assets/images/MB/realestate.png";
import professional from "../../../../assets/images/MB/professional.png";
import general from "../../../../assets/images/MB/general.png";

import PrimaryButton from "../../../Buttons/Primary";
import SecondaryButton from "../../../Buttons/Secondary";

import {
	FormTitleWrapper,
	FormWrapper,
	Form,
	Info,
	SubWrapper,
	SectionWrapper,
	Section,
	SectionTitle,
	FieldWrapper,
	FieldName,
	FieldImage,
	ButtonWrapper,
	btnStyles,
} from "../index.styled";

const industries = [
	{ name: "Education", image: education, color: "#834EF2" },
	{ name: "Agriculture", image: agriculture, color: "#3ABF81" },
	{ name: "Transport", image: transport, color: "#0DE957" },
	{ name: "Art Design", image: art, color: "#FFA800" },
	{ name: "Information Tech", image: information, color: "#27D1D1" },
	{ name: "Financial", image: financial, color: "#F800FD" },
	{ name: "Healthcare", image: transport2, color: "#C32121" },
	{ name: "Hospitality", image: hospitality, color: "#FFA800" },
	{ name: "Environmental", image: environmental, color: "#5685FF" },
	{ name: "Construction", image: construction, color: "#2261AC" },
	{ name: "Real Estate", image: realestate, color: "#FF7A00" },
	{ name: "Professional Services", image: professional, color: "#05B1B1" },
];

const ecommerces = [
	{
		name: "General Merchandise",
		image: general,
		color: "General merchandise",
	},
];

const sections = [
	{ title: "Industries", data: industries },
	{ title: "Ecommerce", data: ecommerces },
];

const SignUpForm = () => {
	return (
		<Form>
			<FormWrapper>
				<FormTitleWrapper>Please provide your details to register for our Micro Blogging module</FormTitleWrapper>
				<SubWrapper>
					<Info>
						Welcome to our Micro Blogging module! To get started, please select an industry module that aligns with
						your interests or professional field. This choice helps us tailor your experience, connecting you with
						relevant content and like-minded individuals in your chosen industry.
					</Info>
					{sections?.map((section) => (
						<SectionWrapper key={section?.title}>
							<SectionTitle>{section?.title}</SectionTitle>
							<Section>
								{section?.data?.map((industry) => (
									<FieldWrapper key={industry?.name}>
										<FieldImage src={industry?.image} />
										<FieldName style={{ color: industry?.color }}>{industry?.name}</FieldName>
									</FieldWrapper>
								))}
							</Section>
						</SectionWrapper>
					))}
				</SubWrapper>
			</FormWrapper>
			<ButtonWrapper>
				<SecondaryButton label={"Back"} />
				<PrimaryButton type="submit" label={"Next"} />
			</ButtonWrapper>
		</Form>
	);
};

export default SignUpForm;
