const initialUserState = {
	// initial user state
	active: { name: undefined },
};

const moduleReducer = (state = initialUserState, action) => {
	// reducer logic based on different action types
	switch (action.type) {
		case "SET_ACTIVE_MODULE":
			state = {
				...state,
				active: {
					...state.active,
					name: action?.payload,
				},
			};
	}
	return state;
};

export default moduleReducer;
