import styled from "@emotion/styled";
import { color, spacing, toRem } from "../../../../styles/variables";
import Search from "../../../../assets/icons/shorts/mobile/Search";
import Home from "../../../../assets/images/shorts/Mobmain_icon.png";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SidenavMobileComponent from "../../../../components/SidenavMobileComponent";

const tabs = [
	// {
	// 	id: 0,
	// 	icon: homemainicon,
	// 	link: "/home",
	// 	label: "",
	// },
	{
		id: 1,
		label: "For you",
		link: "/shorts/feed",
	},
	{
		id: 2,
		label: "Following",
		link: "/shorts/feed/following",
	},
	{
		id: 3,
		label: "Rewardfeed",
		link: "/shorts/feed/rewards",
	},
	{
		id: 4,
		label: "Once-Monthly",
		link: "/shorts/feed/monthly",
	},
	{
		id: 5,
		label: "Marketlink",
		link: "/shorts/feed/marketlink",
	},
];
const TopNav = () => {
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(0);
	const { pathname } = useLocation();
	const [showNav, setShowNav] = useState(false);

	const handleNavigation = ({ id, link }) => {
		setActiveTab(id);
		navigate(link);
	};

	const checkPath = () => {
		tabs?.some((tab) => {
			if (tab?.link === pathname) {
				setActiveTab(tab?.id);
				return true;
			}
		});
		let path = pathname?.split("/");
		path.shift();
		if (path?.includes("shorts") && path?.includes("feed")) {
			setShowNav(true);
		} else setShowNav(false);
	};

	useEffect(() => {
		checkPath();
	}, [pathname]);

	useEffect(() => {
		checkPath();
	}, []);

	if (!showNav) return <></>;

	return (
		<Wrapper>
			<TabWrapper>
				<LeftIcons>
					<SidenavMobileComponent module="shorts" color={"#fff"} anchor="left" />
					<img src={Home} alt="home" onClick={() => navigate("/home")} />
				</LeftIcons>
				{tabs?.map((tab) => (
					<Tab key={tab?.id} onClick={() => handleNavigation(tab)} active={activeTab === tab?.id}>
						{tab.icon && <Icon src={tab.icon} alt="tab-icon" />}
						{tab?.label}
					</Tab>
				))}
				<SearchIcon onClick={() => navigate("/shorts/search")}>
					<Search />
				</SearchIcon>
			</TabWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: absolute;
	width: -webkit-fill-available;
	overflow: hidden;
	z-index: 1;
	margin-inline: ${spacing("md2")};
	margin-block-start: ${spacing("md1")};
	padding-inline-end: ${spacing("xl2")};
	padding-inline-start: ${spacing("xxxl1")};
`;

const TabWrapper = styled.div`
	display: flex;
	column-gap: ${spacing("xl")};
	overflow-x: auto;
	&::-webkit-scrollbar {
		display: none;
	}
	position: relative;
`;

const Tab = styled.div`
	padding-block: ${spacing("sm")};
	white-space: nowrap;

	color: ${color("pWhite")};
	font-family: Inter;
	font-size: ${toRem(18)};
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	border-bottom: 5px solid transparent;

	${(props) =>
		props.active && {
			fontWeight: 600,
			borderBottomColor: color("pWhite"),
		}}
`;

const LeftIcons = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${spacing("md2")};
	position: fixed;
	left: ${spacing("md2")};
	top: ${spacing("lg")};
`;

const SearchIcon = styled.div`
	display: flex;
	position: fixed;
	right: ${spacing("md2")};
	top: ${spacing("lg")};
`;

const Icon = styled.img`
	width: 20px;
	height: 20px;
	margin-right: 8px;
`;

export default TopNav;
