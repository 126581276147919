const Chat = ({ color = "white", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 22 19" fill="none">
			<path
				d="M0.509766 0V15.6622H4.24152V13.9332H2.26708V1.72895H20.2307V13.9332H10.3152L4.73395 17.5569L5.70184 19L10.8426 15.6622H21.988V0H0.509766Z"
				fill={color}
			/>
			<path d="M8.77034 7.43457H7.11816V9.08674H8.77034V7.43457Z" fill={color} />
			<path d="M12.075 7.43457H10.4229V9.08674H12.075V7.43457Z" fill={color} />
			<path d="M15.3797 7.43457H13.7275V9.08674H15.3797V7.43457Z" fill={color} />
		</svg>
	);
};

export default Chat;
