import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import HomeHeader from "../../components/Main/Header";
import nextarrow from "../../assets/icons/arrownext.png";
import points from "../../assets/images/points.png";
import editable from "../../assets/images/editable.png";
import { AccordionDetails, AccordionSummary, Grid, InputAdornment, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import defaultProfile from "../../assets/icons/user-profile.png";
import send from "../../assets/images/icons/Sendmessage.png";
import profile from "../../assets/images/profile.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import io from "socket.io-client";
import {
	ActiveChatUser,
	ActiveChatUserDetailsWrapper,
	ActiveChatWrapper,
	BreadCrumb,
	BreadCrumbArrow,
	ChatUserImage,
	ChatWindowWrapper,
	ChatWrapper,
	LastActive,
	MediaFilesAndLinkAccordion,
	Message,
	MessagesSettingsAndEdit,
	MessagesTitle,
	MessagesWrapper,
	MessagingField,
	NavWrapper,
	PlaceholderImage,
	PlaceholderImageTitle,
	TextMessagePreview,
	User,
	UserActiveDetails,
	UserDetails,
	UserDetailsWrapper,
	UserImage,
	UserIndustry,
	UserList,
	UserListWrapper,
	UserName,
	UserWrapper,
	Wrapper,
} from "./index.styles";
import { GetAllChats, GetAllOneToOneChat, SendMessage } from "../../utils/apiCalls";
import { UserContext } from "../../store/UserDetailsStore";

const ChatEventEnum = Object.freeze({
	CONNECTED_EVENT: "connected",
	DISCONNECT_EVENT: "disconnect",
	JOIN_CHAT_EVENT: "joinChat",
	LEAVE_CHAT_EVENT: "leaveChat",
	UPDATE_GROUP_NAME_EVENT: "updateGroupName",
	MESSAGE_RECEIVED_EVENT: "messageReceived",
	NEW_CHAT_EVENT: "newChat",
	SOCKET_ERROR_EVENT: "socketError",
	STOP_TYPING_EVENT: "stopTyping",
	TYPING_EVENT: "typing",
	ADD_NOTIFICATION_EVENT: "addNotification",
	REMOVE_NOTIFICATION_EVENT: "removeNotification",
});

const Messaging = () => {
	const navigate = useNavigate("");
	const { isLoginUserDetails } = useContext(UserContext);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const hasFetchedChats = useRef(false);
	const [messagesData, setMessagesData] = useState([]);
	const [activeChat, setActiveChat] = useState(null);
	const [loading, setLoading] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [totalPagesCount, setTotalPagesCount] = useState("");
	const [pageSize, setPageSize] = useState(10);
	const [messageChat, setMessageChat] = useState([]);
	const [typedMessage, setTypedMessage] = useState("");
	const [isSending, setIsSending] = useState(false);
	const [socket, setSocket] = useState(null);
	const searchId = sessionStorage.getItem("searchId");
	const location = useLocation();
	const activeChatId = location.state?.activeChat;
	const chatWindowRef = useRef(null);
	const bottomOfPageRef = useRef(null);
	const hasFetched = useRef(false);
	const [isChatVisible, setIsChatVisible] = useState(false);

	useEffect(() => {
		if (searchId) {
			const filteredChats = [];
			messagesData?.forEach((dataTab) => {
				const hasParticipant = dataTab?.participants?.some((participant) => participant?._id === searchId);

				if (hasParticipant) {
					filteredChats.push(dataTab);
				}
			});
			setActiveChat(filteredChats[0]);
		} else {
			if (!activeChat && messagesData.length > 0) {
				setActiveChat(messagesData[0]);
			}
		}
	}, [messagesData, searchId]);

	useEffect(() => {
		if (activeChat) {
			sessionStorage.removeItem("searchId");
		}
	}, [activeChat]);

	const getAllChats = () => {
		GetAllChats((res) => {
			setMessagesData(res.data);
		});
	};

	useEffect(() => {
		if (!hasFetchedChats.current) {
			getAllChats();
			hasFetchedChats.current = true;
		}
	}, []);

	useEffect(() => {
		if (socket) {
			socket.disconnect();
		}
		const newSocket = io("https://api-messaging.buzzgalactic.com", {
			withCredentials: true,
			extraHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});

		newSocket.on("connect", () => {
			console.log("Connected to socket server");
		});

		newSocket.on(ChatEventEnum.MESSAGE_RECEIVED_EVENT, (message) => {
			console.log("Message received:", message);
			setMessageChat((prevMessages) => [...prevMessages, message]);
		});

		setSocket(newSocket);

		return () => {
			newSocket.off(ChatEventEnum.MESSAGE_RECEIVED_EVENT);
			newSocket.disconnect();
		};
	}, [activeChat]);

	useEffect(() => {
		if (socket && activeChat?._id) {
			socket.emit(ChatEventEnum.JOIN_CHAT_EVENT, activeChat._id);
		}
	}, [socket, activeChat]);

	useEffect(() => {
		if (socket && isLoginUserDetails?.id) {
			socket.emit(ChatEventEnum.JOIN_CHAT_EVENT, isLoginUserDetails?.id);
			socket.on(ChatEventEnum?.NEW_CHAT_EVENT);
		}
	}, [socket, isLoginUserDetails]);

	useEffect(() => {
		if (chatWindowRef.current) {
			chatWindowRef.current.scrollTop = chatWindowRef.current.scrollHeight;
		}
	}, [messageChat]);

	const handleSendMessage = () => {
		if (isSending) {
			return;
		}

		setIsSending(true);

		if (activeChat?._id) {
			const payload = {
				chatId: activeChat?._id,
				message: typedMessage,
			};
			SendMessage((res) => {
				setTypedMessage("");
				// getOneToOneChat(activeChat?._id);
				getAllChats();
			}, payload);

			setTypedMessage("");
			setIsSending(false);
		}
	};

	const handleFriendProfile = (id) => {
		sessionStorage?.setItem("searchId", id);
		navigate("/publicprofile");
	};

	// for page index
	const handleScroll = useCallback(() => {
		if (chatWindowRef.current && chatWindowRef.current.scrollTop === 0) {
			if (activePage < totalPagesCount && !loading) {
				console.log("Conditions met, fetching previous page...");
				setLoading(true);
				setActivePage((prevPage) => {
					const newPage = prevPage + 1;
					getOneToOneChat(newPage, activeChat?._id);
					return newPage;
				});
			} else {
				console.log("Conditions not met, not fetching.");
			}
		}
	}, [loading, totalPagesCount]);

	const getOneToOneChat = (pageIndex, id) => {
		if (id) {
			const payload = {
				chatId: id,
				pageSize: pageSize,
				pageIndex: pageIndex,
			};
			GetAllOneToOneChat((res) => {
				const { data, message, status } = res;
				if (status === 200) {
					setMessageChat((prevMessages) => [...prevMessages, ...data?.messages]);
					setTotalPagesCount(Math.ceil(data?.totalRecords / pageSize));
				} else {
					setMessageChat([]);
					setTotalPagesCount(0);
				}
			}, payload);
		}
	};

	useEffect(() => {
		console.log("Calling useEffect", activePage, activeChat?._id, hasFetched.current);
		if (activePage >= 0 && activeChat?._id && !hasFetched.current) {
			for (let i = 0; i <= 10; i++) {
				getOneToOneChat(activePage + i, activeChat?._id);
			}
			hasFetched.current = true;
		}

		// return () => {
		// 	setHasFetched(false);
		// };
	}, [pageSize, activePage, activeChat?._id]);

	useEffect(() => {
		const chatWindow = chatWindowRef.current;
		if (chatWindow) {
			chatWindow.addEventListener("scroll", handleScroll);
		}
		return () => {
			if (chatWindow) {
				chatWindow.removeEventListener("scroll", handleScroll);
			}
		};
	}, [handleScroll]);

	const scrollToTopOfActiveChat = () => {
		if (bottomOfPageRef.current) {
			bottomOfPageRef.current.scrollTop = 0;
		}
	};

	useEffect(() => {
		if (activeChat) {
			scrollToTopOfActiveChat();
			getOneToOneChat(activePage - 1, activeChat?._id);
		}
	}, [activeChat]);

	const handleUserClick = (messageData) => {
		setMessageChat([]);
		setActiveChat(messageData);
		setIsChatVisible(!isChatVisible);
	};

	const handleChatActive = (data) => {
		if (data?._id !== activeChat?._id) {
			setActiveChat(data);
			// setMessageChat([]);
		}
	};

	return (
		<>
			{token ? (
				<>
					<HomeHeader />
					<Wrapper>
						<NavWrapper>
							<BreadCrumb> Main </BreadCrumb>
							<BreadCrumbArrow src={nextarrow} alt="" />
							<BreadCrumb> Messaging </BreadCrumb>
						</NavWrapper>
						<Grid
							container
							marginTop={"12px"}
							style={{
								backgroundColor: "#FCFDFF",
								paddingInline: "5.4vw",
							}}
						>
							<Grid item xs={10} md={2.5}>
								<UserListWrapper className={isChatVisible ? "hidden-on-mobile" : ""}>
									<MessagesWrapper>
										<MessagesTitle>
											Messages
											<MessagesSettingsAndEdit>
												<img src={points} />
												<img src={editable} />
											</MessagesSettingsAndEdit>
										</MessagesTitle>
										{/* <SearchMessages placeholder="Search Messages" /> */}
									</MessagesWrapper>
									<UserList>
										{messagesData?.map((messageData, index) => (
											<User onClick={() => handleChatActive(messageData)} key={index}>
												<UserIndustry>{"Main"}</UserIndustry>
												{messageData?.participants?.map((participantData, index) => (
													<UserDetailsWrapper onClick={() => handleUserClick(messageData)} key={index}>
														<UserImage src={participantData?.profilePicture ?? defaultProfile} />
														<UserDetails>
															<UserName onClick={() => handleUserClick(messageData)}>
																{participantData?.fullName}
															</UserName>
															<TextMessagePreview>
																{messageData?.lastMessage?.content}
															</TextMessagePreview>
														</UserDetails>
													</UserDetailsWrapper>
												))}
											</User>
										))}
									</UserList>
								</UserListWrapper>
							</Grid>
							<Grid item xs={12} md={7}>
								<ActiveChatWrapper className={!isChatVisible ? "hidden-on-mobile" : ""} ref={bottomOfPageRef}>
									{activeChat?.participants?.map((activeData, index) => (
										<ActiveChatUserDetailsWrapper key={index}>
											<UserIndustry>{"Main"}</UserIndustry>
											<ActiveChatUser>
												<UserImage
													src={activeData?.profilePicture ?? defaultProfile}
													onClick={() => {
														handleFriendProfile(activeData?._id);
													}}
												/>
												<UserActiveDetails>
													<UserName>{activeData?.fullName}</UserName>
													<LastActive>{activeChat?.lastActive}</LastActive>
												</UserActiveDetails>
											</ActiveChatUser>
										</ActiveChatUserDetailsWrapper>
									))}

									<ChatWindowWrapper ref={chatWindowRef}>
										<ChatWrapper>
											{messageChat?.map((data, index) => {
												const isSender = isLoginUserDetails?.userId !== data?.sender?._id;
												return (
													<>
														{isSender && (
															<Message key={index}>
																<ChatUserImage
																	src={data?.sender?.profilePicture ?? defaultProfile}
																/>
																<p>{data?.content}</p>
															</Message>
														)}
														{!isSender && (
															<Message
																key={index}
																{...(!false
																	? {
																			userMessage: true,
																			firstUserMessage: true,
																		}
																	: {})}
															>
																<p>{data?.content}</p>
															</Message>
														)}
													</>
												);
											})}
										</ChatWrapper>
									</ChatWindowWrapper>
									<MessagingField
										placeholder="Type your message"
										name="typedMessage"
										value={typedMessage}
										onChange={(e) => {
											setTypedMessage(e.target.value);
										}}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												handleSendMessage();
											}
										}}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<img
														src={send}
														alt="Send Message"
														style={{
															width: "24px",
															cursor: "pointer",
														}}
														onClick={handleSendMessage}
													/>
												</InputAdornment>
											),
											disableUnderline: true,
										}}
									/>
								</ActiveChatWrapper>
							</Grid>
							<Grid item xs={2.5} md={2.5}>
								{activeChat?.participants?.map((activeData, index) => (
									<UserWrapper key={index}>
										<UserImage
											src={activeData?.profilePicture ?? defaultProfile}
											onClick={() => {
												handleFriendProfile(activeData?._id);
											}}
										/>
										<UserIndustry>{"Main"}</UserIndustry>
										<UserName>{activeData?.fullName}</UserName>

										<PlaceholderImage src={profile} alt="" />

										<PlaceholderImageTitle>Profile</PlaceholderImageTitle>
										<MediaFilesAndLinkAccordion>
											<AccordionSummary
												expandIcon={
													<ExpandMoreIcon
														sx={{
															color: "#000",
														}}
													/>
												}
												sx={{
													backgroundColor: "transparent",
												}}
											>
												<Typography>Media files and links</Typography>
											</AccordionSummary>
											<AccordionDetails>
												<Typography>List of files</Typography>
											</AccordionDetails>
										</MediaFilesAndLinkAccordion>
									</UserWrapper>
								))}
							</Grid>
						</Grid>
					</Wrapper>
				</>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default Messaging;
