const Index = ({ color = "white", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 34 34" fill="none">
			<path
				d="M9.9165 9.91675H8.49984C7.74839 9.91675 7.02772 10.2153 6.49637 10.7466C5.96501 11.278 5.6665 11.9986 5.6665 12.7501V25.5001C5.6665 26.2515 5.96501 26.9722 6.49637 27.5035C7.02772 28.0349 7.74839 28.3334 8.49984 28.3334H21.2498C22.0013 28.3334 22.722 28.0349 23.2533 27.5035C23.7847 26.9722 24.0832 26.2515 24.0832 25.5001V24.0834"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M22.6667 7.08336L26.9167 11.3334M28.8787 9.32878C29.4367 8.77083 29.7502 8.01409 29.7502 7.22503C29.7502 6.43597 29.4367 5.67923 28.8787 5.12128C28.3208 4.56333 27.5641 4.24988 26.775 4.24988C25.9859 4.24988 25.2292 4.56333 24.6713 5.12128L12.75 17V21.25H17L28.8787 9.32878Z"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Index;
