export const BaseURL = {
	BASE_URL: "https://api.buzzgalactic.com/api/v1",
	MESSAGE_URL: "https://api-messaging.buzzgalactic.com/api/v1",
	IT_URL: "https://zhz0p1x903.execute-api.eu-north-1.amazonaws.com/dev/api/v1/subscription/",
	MB_URL: "https://lel4mv7hj4.execute-api.eu-north-1.amazonaws.com/dev/api/v1/profile/",
	PROFILE_URL: "https://zhz0p1x903.execute-api.eu-north-1.amazonaws.com/dev/api/v1/profile/",
	SHORT_VIDEO_URL: "https://xz25hdx4oh.execute-api.eu-north-1.amazonaws.com/dev/api/v1/profile/",
	EDUCATE_PROFILE_URL:"https://yvilycxm86.execute-api.eu-north-1.amazonaws.com/dev/api/v1/profile/",

	EDUCATE_CREATE_URL:"https://yvilycxm86.execute-api.eu-north-1.amazonaws.com/dev/api/v1/subscription/",

	AGRICULTURE_URL: "https://jwyllilux4.execute-api.eu-north-1.amazonaws.com/dev/api/v1/subscription/",
	AGRICULTURE_PROFILE_URL: "https://jwyllilux4.execute-api.eu-north-1.amazonaws.com/dev/api/v1/profile/",

};

export const API_CONSTANTS = {
	LOGIN: `${BaseURL.BASE_URL}/login/loginUser`,
	SIGNUP: `${BaseURL.BASE_URL}/login/signUpUser`,
	TOKEN: `${BaseURL.BASE_URL}/login/getToken`,
	CLEARTOKEN: `${BaseURL.BASE_URL}/login/clearToken`,
	VERIFYUSER: `${BaseURL.BASE_URL}/login/verifyUser`,
	FORGOTPASSWORD: `${BaseURL.BASE_URL}/login/sendResetLinkOTP`,
	RESETPASSWORD: `${BaseURL.BASE_URL}/login/resetPassword`,
	GOOGLELOGIN: `${BaseURL.BASE_URL}/login/google`,
	FACEBOOKLOGIN: `${BaseURL.BASE_URL}/login/auth/facebook`,
	CHAT_GPT_RESPONSE: `${BaseURL.BASE_URL}/gpt/getChatResponse`,
	GET_USER_PROFILE: `${BaseURL.BASE_URL}/profile/getUser`,
	EDIT_USER_PROFILE: `${BaseURL.BASE_URL}/profile/editProfile`,
	GETALLUSERLIST: `${BaseURL.BASE_URL}/profile/getUserList`,
	GETALLFRIENDLIST: `${BaseURL.BASE_URL}/friend/getFriendList`,
	SENDFRIENDREQUEST: `${BaseURL.MESSAGE_URL}/friend/sendFriendRequest`,
	GETFRIENDREQUEST: `${BaseURL.BASE_URL}/friend/getSentFriendRequests`,
	GETFRIENDREQUESTSTATUS: `${BaseURL.BASE_URL}/friend/getFriendRequestStatus`,
	CANCELFRIENDREQUEST: `${BaseURL.MESSAGE_URL}/friend/cancelSentFriendRequest`,
	GETREQUESTFRIENDLIST: `${BaseURL.BASE_URL}/friend/getReceivedFriendRequests`,
	CONFIRMFRIENDREQUEST: `${BaseURL.MESSAGE_URL}/friend/acceptFriendRequest`,
	CANCELRECEIVEDFRIENDREQUEST: `${BaseURL.MESSAGE_URL}/friend/cancelReceivedFriendRequest`,
	REMOVEFRIEND: `${BaseURL.MESSAGE_URL}/friend/removeFriend`,
	GETALLPOST: `${BaseURL.BASE_URL}/posts/getAllUserPosts`,
	GETALLUSERPOST: `${BaseURL.BASE_URL}/posts/getUserPosts`,
	LIKESPOST: `${BaseURL.BASE_URL}/posts/likePost`,
	GETPOSTLIKES: `${BaseURL.BASE_URL}/posts/getPostLikes`,
	POSTCOMMENT: `${BaseURL.BASE_URL}/posts/addCommentToPost`,
	GETALLCOMMENTS: `${BaseURL.BASE_URL}/posts/getPostComments`,
	GETALLREVIEW: `${BaseURL.BASE_URL}/profile/getAllReviews`,
	REVIEWGPT: `${BaseURL.BASE_URL}/profile/reviewGPT`,
	REVIEWUSER: `${BaseURL.MESSAGE_URL}/profile/reviewUser`,
	REPLYGPT: `${BaseURL.BASE_URL}/profile/replyToReviewGPT`,
	REPLYUSER: `${BaseURL.MESSAGE_URL}/profile/replyToReview`,
	GETALLCHATS: `${BaseURL.MESSAGE_URL}/chat/getAllChats`,
	GETONETONECHATS: `${BaseURL.MESSAGE_URL}/chat/getChat`,
	SENDMESSAGE: `${BaseURL.MESSAGE_URL}/chat/sendMessage`,
	CREATEORJOINCHAT: `${BaseURL.MESSAGE_URL}/chat/createOrJoinChat`,
	GETUSERNOTIFICATIONS: `${BaseURL.MESSAGE_URL}/profile/getUserNotifications`,
	/*IT*/
	ITPROFILEDROPDOWN: `${BaseURL.PROFILE_URL}getAllITProfiles`,
	ITPROFILEDUPDATE: `${BaseURL.PROFILE_URL}updateLastITProfile`,
	ITCLIENTPROFILE: `${BaseURL.PROFILE_URL}getUserClientITProfile`,
	ITFREELANCERPROFILE: `${BaseURL.PROFILE_URL}getUserFreelancerITProfile`,
	ITCOMPANYPROFILE: `${BaseURL.PROFILE_URL}getUserCompanyITProfile`,
	ITREGULAREMPLOYEEPROFILE: `${BaseURL.PROFILE_URL}getUserEmployeeITProfile`,
	ITSEARCHPROFILE: `${BaseURL.PROFILE_URL}searchITProfile`,
	FREELANCERPROFILE: `${BaseURL.PROFILE_URL}getTopFreelancerITProfile`,
	CLIENTPROFILE: `${BaseURL.PROFILE_URL}getTopClientITProfile`,
	EMPLOYEEPROFILE: `${BaseURL.PROFILE_URL}getTopEmployeeITProfile`,
	COMPANYPROFILE: `${BaseURL.PROFILE_URL}getTopCompanyITProfile`,
	SHORTVIDEOPROFILE: `${BaseURL.SHORT_VIDEO_URL}getLatestShortVideoAccount`,
	ITSHORTVIDEOPROFILE: `${BaseURL.PROFILE_URL}getPrivateITShortVideoAccount`,
	SHORTVIDEOPRIVATEPROFILE: `${BaseURL.SHORT_VIDEO_URL}getShortVideoAccountDetails`,
	ITMICROBLOGGINGPROFILE: `${BaseURL.PROFILE_URL}getPrivateITMicrobloggingProfile`,
	SHORTVIDEOALLACCOUNTS: `${BaseURL.SHORT_VIDEO_URL}getAllShortVideoAccounts`,
	SHORTVIDEOACCOUNTUPDATE: `${BaseURL.SHORT_VIDEO_URL}updateLastShortVideoAccount`,
	SUBMODULEALLPROFILES: `${BaseURL.BASE_URL}/profile/getAllSubModuleProfiles`,
	COUNTRYLIST: `${BaseURL.IT_URL}getCountryList`,
	SHORTVIDEOPUBLICPROFILE: `${BaseURL.PROFILE_URL}getPublicShortVidProfile`,
	SEARCHSHORTVIDEOACCOUNTS: `${BaseURL.SHORT_VIDEO_URL}searchShortVideoAccounts`,
	SHORTVIDEOUSERS: `${BaseURL.SHORT_VIDEO_URL}getShortVidUserDetail`,
	ITSHORTVIDEOACCOUNT: `${BaseURL.PROFILE_URL}getPrivateITShortVideoAccount`,
	/* Education*/
	EDUCATIONPROFILEDROPDOWN: `${BaseURL.EDUCATE_PROFILE_URL}getUserAllEducationAccount`,
	EDUCATIONPROFILEUPDATE: `${BaseURL.EDUCATE_PROFILE_URL}updateLastEducationProfile`,
	EDUCATIONSEARCHPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}searchEducationProfile`,
	EDUCATIONCLIENTPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getEducationClientProfile`,
	EDUCATIONFREELANCERPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getEducationFreelancerProfile`,
	EDUCATIONCOMPANYPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getEducationCompanyProfile`,
	EDUCATIONEMPLOYEEPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getEducationEmployeeProfile`,
	CLIENTEDUCATIONPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getTopClientEducationProfile`,
	COMPANYEDUCATIONPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getTopCompanyEducationProfile`,
	EMPLOYEEEDUCATIONPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getTopEmployeeEducationProfile`,
	FREELANCEREDUCATIONPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getTopFreelancerEducationProfile`,
	EDUCATIONSHORTVIDEOPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getPrivateEducationShortVideoProfile`,
	EDUCATIONMICROBLOGGINGPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getPrivateEducationMicrobloggingProfile`,
	EDUCATIONSHORTVIDEOACCOUNT: `${BaseURL.EDUCATE_PROFILE_URL}getPrivateEducationShortVideoProfile`,
	EDUCATIONSHORTVIDEOPUBLICPROFILE: `${BaseURL.EDUCATE_PROFILE_URL}getPublicShortVidProfile`,
	/* Agriculture */
	AGRICULTUREPROFILEDROPDOWN: `${BaseURL.AGRICULTURE_PROFILE_URL}getUserAllAgricultureAccount`,
};
export const API_METHODS = {
	GET: "GET",
	POST: "POST",
	DELETE: "DELETE",
	UPDATE: "UPDATE",
	PUT: "PUT",
};
