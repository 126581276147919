import LeftSubNav from "../../../components/LeftSubNav";
import Live from "../../../components/TV/Live";
import Page from "../../../layouts/Page";
import { LeftNav, TVContent } from "./index.styled";
import useLayout from "../../../utils/hooks/useLayout";

const Home = () => {
	const isMobile = useLayout();
	return (
		<Page
			overrides={
				isMobile && {
					"@media screen and (max-width: 768px)": {
						paddingBlockEnd: "40px",
					},
				}
			}
		>
			<LeftNav>
				<LeftSubNav post={false} support={false} videos={true} />
			</LeftNav>
			<TVContent>
				<Live />
			</TVContent>
		</Page>
	);
};

export default Home;
