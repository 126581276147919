import React from "react";
import Buzzgalactic from "../../assets/icons/buzzgalactic/logo.svg";
import { NavLink, useNavigate } from "react-router-dom";
import { Login, Wrapper } from "./index.styled";
import { LogoContainer } from "../Main/Header/index.styled";

const Header = ({ hideButton = false }) => {
	const navigate = useNavigate();
	const handleLogin = () => {
		navigate("/login");
	};
	return (
		<Wrapper>
			<NavLink to="/">
				<LogoContainer noBorder>
					<img src={Buzzgalactic} alt="logo" />
				</LogoContainer>
			</NavLink>
			{!hideButton && (
				<Login onClick={handleLogin}>
					<svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
						<path
							fillRule="evenodd"
							clipRule="evenodd"
							d="M9 0C4.0326 0 0 4.0326 0 9C0 13.9674 4.0326 18 9 18C13.9674 18 18 13.9674 18 9C18 4.0326 13.9674 0 9 0ZM9 1.2C13.305 1.2 16.8 4.695 16.8 9C16.8 13.305 13.305 16.8 9 16.8C4.695 16.8 1.2 13.305 1.2 9C1.2 4.695 4.695 1.2 9 1.2ZM13.0236 15C13.3548 15 13.6236 14.7312 13.6236 14.4C13.6236 11.8554 11.5572 9.789 9.0126 9.789C6.468 9.789 4.4022 11.8554 4.4022 14.4C4.4022 14.7312 4.6704 15 5.0022 15H13.0236ZM12.3708 13.8H5.6544C5.9388 12.2034 7.335 10.989 9.0126 10.989C10.6902 10.989 12.087 12.2034 12.3708 13.8ZM9.0126 3C7.2612 3 5.8386 4.422 5.8386 6.174C5.8386 7.926 7.2612 9.348 9.0126 9.348C10.7646 9.348 12.1866 7.926 12.1866 6.174C12.1866 4.422 10.7646 3 9.0126 3ZM9.0126 4.2C10.1022 4.2 10.9866 5.0844 10.9866 6.174C10.9866 7.2636 10.1022 8.148 9.0126 8.148C7.923 8.148 7.0386 7.2636 7.0386 6.174C7.0386 5.0844 7.923 4.2 9.0126 4.2Z"
							fill="#363636"
						/>
					</svg>
					LOGIN
				</Login>
			)}
		</Wrapper>
	);
};

export default Header;
