import styled from "@emotion/styled";
import { Paper } from "@mui/material";

const CustomPaper = styled(Paper)`
	display: grid;
	grid-template-columns: repeat(8, 1fr);
	padding: 6px 12px;
	grid-column-gap: 16px;
	align-items: center;
	justify-content: center;
	text-align: center;
`;

const UserImage = styled.img`
	grid-column: span 1;
	width: 100%;
	max-width: 50px;
	border-radius: 50%;
`;

const SettingsIconWrapper = styled.div`
	grid-column: span 1;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ActiveModule = styled.div`
	color: #256e61;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-transform: capitalize;

	margin-inline-start: 8px;
	padding-inline-start: 8px;
	padding-block: 6px;
	border-left: 1px solid #cbc3c3;

	display: flex;
	align-items: center;
	column-gap: 8px;
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;

	& > img {
		width: 132px;
	}
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-inline: 12px;

	margin-block: 16px;
`;

const LabelWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const Category = styled.div`
	color: #171513;
	font-family: "Inter";
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
	text-transform: capitalize;
`;

const CollapsibleIcons = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 4px;
	background: #f1eeee;
`;

const SubListWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const SubCategory = styled.div`
	cursor: pointer;
	color: #696a68;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 41px; /* 292.857% */

	display: flex;
	column-gap: 16px;
	padding: 4px 12px;
	align-items: center;

	& > svg {
		height: 18px;
	}
`;

const CategoryIconWrapper = styled.div`
	width: 10%;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export {
	CustomPaper,
	UserImage,
	SettingsIconWrapper,
	ActiveModule,
	LogoWrapper,
	Wrapper,
	LabelWrapper,
	CollapsibleIcons,
	Category,
	SubListWrapper,
	SubCategory,
	CategoryIconWrapper,
};
