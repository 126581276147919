import { Box, Card, Input, LinearProgress } from "@mui/material";
import { borderRadius, color } from "../../../styles/variables";
import { GPTResponse } from "./index.styled";
import { useMutation, useQuery } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import PrimaryBtn from "../../Buttons/Primary/index";
import SendMessage from "../../../assets/icons/chatbot/SendMessage";
import { useState } from "react";

const Chat = () => {
	const [GPTQuestion, setGPTQuestion] = useState("");
	const [chatGPTResponse, setChatGPTResponse] = useState(null);
	const [isChatGPTResponseLoading, setIsChatGPTResponseLoading] = useState(false);
	const { data, isLoading } = useQuery({
		queryKey: "GPTUserData",
		queryFn: async () => {
			const token = localStorage.getItem("token") || sessionStorage.getItem("token");
			const response = await query("GET", undefined, `profile/getUser?token=${token}`);
			const { data } = response;
			return data;
		},
	});

	const chatGPT = useMutation({
		mutationFn: async () => {
			try {
				setIsChatGPTResponseLoading(true);
				const payload = {
					message: GPTQuestion,
				};
				const response = await query("POST", undefined, "gpt/getChatResponse", payload);
				let { data } = response;
				let { result } = data;
				const responseResult = result.trim().replace(/\n/g, "<br>") || "";
				setChatGPTResponse(responseResult);
				setGPTQuestion("");
				return response.data;
			} catch (error) {
				console.error("Error:", error.message);
				throw error;
			} finally {
				setIsChatGPTResponseLoading(false);
			}
		},
	});

	const handleGPTSubmit = (event, values) => {
		event.preventDefault();
		chatGPT.mutate();
	};

	return (
		<Box>
			<Card
				elevation={6}
				sx={{ p: "12px", borderRadius: borderRadius(8), display: "flex", flexDirection: "column", rowGap: "16px" }}
			>
				<Card elevation={0} sx={{ p: "12px", borderRadius: borderRadius(8), border: "1px solid #d9d9d9" }}>
					{(isLoading || isChatGPTResponseLoading) && (
						<GPTResponse>
							<LinearProgress />
						</GPTResponse>
					)}
					{!(isLoading || isChatGPTResponseLoading) && !chatGPTResponse && (
						<GPTResponse>
							Hi <span>{data?.name}</span>, I'm your chatbot. Let's explore powerful features and uncover unique
							financial opportunities together!
						</GPTResponse>
					)}
					{!(isLoading || isChatGPTResponseLoading) && chatGPTResponse && (
						<GPTResponse
							dangerouslySetInnerHTML={{
								__html: chatGPTResponse,
							}}
						></GPTResponse>
					)}
				</Card>
				<form onSubmit={handleGPTSubmit}>
					<Box display={"flex"} columnGap={"16px"}>
						<Card
							elevation={0}
							sx={{ p: "8px", borderRadius: borderRadius(8), border: "1px solid #d9d9d9", width: "100%" }}
						>
							<Input
								placeholder={"Type your message"}
								sx={{
									"& input": {
										p: 0,
									},
									width: "100%",
								}}
								name="gpt"
								onChange={(e) => setGPTQuestion(e.target.value)}
								value={GPTQuestion}
								autoComplete="off"
							/>
						</Card>
						<PrimaryBtn
							label={<SendMessage />}
							overrides={{
								"&:hover": {
									backgroundColor: color("pGreen"),
									boxShadow: "none",
								},
							}}
						/>
					</Box>
				</form>
			</Card>
		</Box>
	);
};

export default Chat;
