import React, { useState } from "react";
import "./MBPost.scss";
import smileb from "../../../assets/images/profile/smileb.png";
import defaultprofile from "../../../assets/images/Educationprofile2.png";
import comment from "../../../assets/images/profile/comment.png";
import share1 from "../../../assets/images/MB/share.png";
import love from "../../../assets/images/MB/love.png";
import graph from "../../../assets/images/MB/graph.png";
import bookmark from "../../../assets/images/MB/bookmark.png";
import upload from "../../../assets/images/MB/upload.png";
import styled from "@emotion/styled";
import { borderRadius, color, spacing } from "../../../styles/variables";

const Post = ({ image, postedBy, industry, profile, content, Comments, likes, postid, currentUserLikeStatus, reward }) => {
	const [likedPost, setLikedPost] = useState(likes);
	const [activeLikes, setActiveLikes] = useState(currentUserLikeStatus);
	//   const handleLikes = (id) => {
	//     const payload = {
	//       postId: id,
	//     };
	//     LikePost((res) => {
	//       const { message, data, status } = res;
	//       if (status === 200) {
	//         setActiveLikes(!activeLikes);
	//         GetPostLikes((res) => {
	//           const { message, data, status } = res;
	//           setLikedPost(data);
	//         }, payload);
	//       }
	//     }, payload);
	//   };

	return (
		<Wrapper>
			<UserDetails>
				<UserImage src={profile || defaultprofile} alt="user" />
				<UserInfo>
					<UserName>
						{postedBy}
						<PostedBefore>14 Hrs</PostedBefore>
					</UserName>
					<UserIndustry>{industry}</UserIndustry>
				</UserInfo>
			</UserDetails>
			<PostInfo>{content}</PostInfo>
			<PostImage src={image} alt="post" />
			<AnalyticsWrapper>
				<Analytic>
					<AnalyticImage src={activeLikes ? smileb : comment} alt="smile" />
					<AnalyticStat>300</AnalyticStat>
				</Analytic>
				<Analytic>
					<AnalyticImage src={share1} alt="smile" />
					<AnalyticStat>2.6k</AnalyticStat>
				</Analytic>
				<Analytic>
					<AnalyticImage src={love} alt="smile" />
					<AnalyticStat>8k</AnalyticStat>
				</Analytic>
				<Analytic>
					<AnalyticImage
						src={graph}
						alt="smile"
						style={{
							width: "16px",
						}}
					/>
					<AnalyticStat>800</AnalyticStat>
				</Analytic>
				<Analytic>
					<AnalyticImage src={bookmark} alt="smile" />
				</Analytic>
				<Analytic>
					<AnalyticImage src={upload} alt="smile" />
				</Analytic>
			</AnalyticsWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	border-radius: ${borderRadius(6)};
	border: 1px solid ${color("borderGray")};
	background: ${color("pWhite")};
	box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
	padding: ${spacing("sm2")};

	display: flex;
	flex-direction: column;
`;

const UserDetails = styled.div`
	display: flex;
	column-gap: ${spacing("sm2")};
	align-items: center;
`;

const UserImage = styled.img`
	width: 68px;
	aspect-ratio: 1;
	border-radius: 50%;

	cursor: pointer;
`;

const UserInfo = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("xxs")};
`;

const UserName = styled.h4`
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	color: ${color("grey")};
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const PostedBefore = styled.span`
	color: ${color("lightGray")};
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	margin-inline-start: ${spacing("sm1")};
`;

const UserIndustry = styled.span`
	color: ${color("pGreen")};
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const PostInfo = styled.p`
	margin: ${spacing("sm")};
	color: ${color("lightGrey")};
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 300;
	line-height: 25px; /* 138.889% */
`;

const PostImage = styled.img`
	margin-block-start: ${spacing("sm2")};

	width: 100%;
	border-radius: ${borderRadius(8)};
`;

const AnalyticsWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	padding-block: ${spacing("md2")};
`;

const Analytic = styled.div`
	cursor: pointer;

	display: inline-flex;
	align-items: center;
	column-gap: ${spacing("sm")};
`;

const AnalyticImage = styled.img`
	width: 24px;
	aspect-ratio: auto;
`;

const AnalyticStat = styled.span`
	color: ${color("strokedGray")};
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: 25px; /* 178.571% */
`;

export default Post;
