import React from "react";

const Environment = ({ color = "#5685FF", size = 20 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 21 21" fill="none">
			<path
				d="M7.3252 1.49707C7.02579 1.62012 6.76329 1.87031 6.63614 2.14922C6.55001 2.34609 6.5418 2.41992 6.5295 3.28945L6.51719 4.22461H4.49923C2.3254 4.22461 2.24337 4.23281 1.68555 4.4543C1.05802 4.70039 0.590437 5.13105 0.30743 5.71758C-0.0206953 6.39434 -0.00428906 6.07031 0.00801562 12.0586L0.0203203 17.3701L0.147469 17.7311C0.323836 18.2232 0.504305 18.5021 0.848836 18.8262C1.18106 19.1379 1.56251 19.343 2.0711 19.4906L2.39923 19.585H10.4998H18.6004L18.9285 19.4906C19.4371 19.343 19.8186 19.1379 20.1508 18.8262C20.4953 18.5021 20.6758 18.2232 20.8522 17.7311L20.9793 17.3701V11.9355V6.50098L20.885 6.19336C20.5856 5.2459 19.9621 4.63887 18.99 4.34356C18.6906 4.25332 18.625 4.24922 16.5824 4.23281L14.4783 4.22051V3.34277C14.4783 2.54297 14.4701 2.44043 14.3922 2.22715C14.2773 1.93184 14.0066 1.63652 13.7277 1.51348L13.5145 1.41504L10.5408 1.40684L7.56719 1.39453L7.3252 1.49707ZM13.2971 2.30098C13.5555 2.3707 13.576 2.45684 13.576 3.4043V4.22461H10.4998H7.42364V3.42891C7.42364 2.98184 7.44415 2.58398 7.46465 2.51426C7.50977 2.39531 7.60821 2.31738 7.77227 2.28457C7.95684 2.24356 13.1494 2.25586 13.2971 2.30098ZM18.6004 5.18848C19.1992 5.31563 19.6299 5.63145 19.8719 6.12363C19.9498 6.2877 20.0359 6.51738 20.0565 6.64043C20.0811 6.77988 20.0975 8.72813 20.0975 11.9314C20.0975 16.2586 20.0893 17.0379 20.0359 17.2594C19.8596 17.9977 19.3551 18.4734 18.5553 18.6416C18.1287 18.7318 2.87091 18.7318 2.44434 18.6416C1.64044 18.4734 1.13595 17.9977 0.96368 17.2471C0.910359 17.0133 0.902156 16.2996 0.910359 11.7797L0.922664 6.58301L1.0211 6.33692C1.2713 5.71348 1.71837 5.33613 2.38282 5.19258C2.78887 5.10234 18.182 5.10234 18.6004 5.18848Z"
				fill={color}
			/>
			<path
				d="M8.85937 7.55508C8.58867 7.73965 8.57227 7.82578 8.57227 8.88809V9.84375H7.63711C6.75117 9.84375 6.69375 9.84785 6.52148 9.93398C6.38613 10.0037 6.3123 10.0775 6.24668 10.2129C6.15234 10.3893 6.15234 10.4262 6.15234 11.7797C6.15234 13.2891 6.16055 13.3424 6.38203 13.5475C6.5502 13.7033 6.80039 13.7402 7.73145 13.7402H8.57227V14.6877C8.57227 15.5777 8.57637 15.6475 8.6584 15.791C8.70762 15.8771 8.81426 15.9838 8.90039 16.033C9.04805 16.115 9.10547 16.1191 10.5082 16.1191C11.952 16.1191 11.9643 16.1191 12.0996 16.0248C12.4031 15.8197 12.4072 15.8115 12.4195 14.7205L12.4318 13.7402H13.2686C14.1996 13.7402 14.4498 13.7033 14.618 13.5475C14.8395 13.3424 14.8477 13.2891 14.8477 11.7838C14.8477 10.4713 14.8436 10.3934 14.7615 10.2293C14.7123 10.1227 14.6139 10.0119 14.5277 9.95859C14.3842 9.86836 14.335 9.86426 13.4039 9.85195L12.4277 9.83965V8.88809C12.4277 7.82578 12.4113 7.73965 12.1406 7.55508C12.0053 7.46484 11.993 7.46484 10.5 7.46484C9.00703 7.46484 8.99473 7.46484 8.85937 7.55508ZM11.5336 9.4377L11.5459 10.5082L11.6812 10.6271L11.8125 10.7461H12.8789H13.9453V11.7674V12.7928L12.8912 12.8051C11.7305 12.8174 11.7018 12.8256 11.5869 13.0676C11.5377 13.1742 11.5254 13.3957 11.5254 14.2078V15.2168H10.5H9.47461V14.2078C9.47461 13.3957 9.4623 13.1742 9.41309 13.0676C9.29824 12.8256 9.26953 12.8174 8.11289 12.8051L7.05469 12.7928V11.7674V10.7461H8.12109H9.1875L9.31875 10.6271L9.4541 10.5082L9.46641 9.4377L9.47871 8.36719H10.5H11.5213L11.5336 9.4377Z"
				fill={color}
			/>
		</svg>
	);
};

export default Environment;
