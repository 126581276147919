import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import { useLocation, useNavigate } from "react-router-dom";
import { GetAllEducationProfile, UpdateEducationProfile } from "../../../utils/apiCalls";
import { connect } from "react-redux";
import EduProfileComponent from "../../EduProfileComponent/EduProfileComponent";

const EducationProfile = ({ UpdateEducationUserData, id, open, anchorEl, handleClose }) => {
	const navigate = useNavigate();
	const [usersData, setUsersData] = useState([]);
	const [updateStatus, setUpdateStatus] = useState("");
	const [updateStatusId, setUpdateStatusId] = useState("");
	const [activeUser, setActiveUser] = useState("");
	const [activeUserId, setActiveUserId] = useState();
	const [selectedProfileId, setSelectedProfileId] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const [isMessage, setIsMessage] = useState("");
	const location = useLocation();
	const ModuleName = location?.pathname.split("/")[1];

	const getEducationProfilesDetails = () => {
		setIsLoader(true);
		GetAllEducationProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setUsersData(res.data);
				setIsLoader(false);
			} else {
				setUsersData([]);
				setIsLoader(false);
				setIsMessage(message);
			}
		});
	};
	useEffect(() => {
		getEducationProfilesDetails();
	}, []);

	const { data } = useQuery({
		queryKey: ["EducationProfile"],
		queryFn: async () => {
			const response = await query("GET", "EDUCATION", "getUserLastEducationProfile");
			setActiveUser(response?.data?.fullName);
			setActiveUserId(response?.data?.educationId);
			return response.data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	useEffect(() => {
		const filteredData = usersData.filter((user) => user.educationId === data?.educationId);
		setSelectedProfileId(filteredData[0]?.educationId);
	}, [usersData, data]);

	const handleUpdateEducationProfile = (updateId) => {	
		setActiveUser("");
		if (updateId) {
			setUpdateStatusId(updateId);
			const payload = {
				EducationId: updateId,
			};
			UpdateEducationProfile((res) => {
				setSelectedProfileId(updateId);
				const { status, message, data } = res;
				if (status === 200) {
					localStorage.setItem("educationId", updateId);
					setUpdateStatus(updateId);
				} else {
					setUpdateStatus("error");
				}
			}, payload);
		}
	};

	const toggleAddAccount = () => {
		navigate("/education");
		handleClose();
	};

	const handleNavigateProfile = (user) => {		
		if (user) {
			UpdateEducationUserData(user);
			const profile = user.educationType.toLowerCase();
			if (ModuleName && profile) {
				navigate(`/${ModuleName}/${profile}/profile/private`, { state: { userData: user } });
				handleClose();
			}
		}
	};

	return (
		<EduProfileComponent
			open={open}
			id={id}
			handleClose={handleClose}
			anchorEl={anchorEl}
			usersData={usersData}
			toggleAddAccount={toggleAddAccount}
			handleNavigateProfile={handleNavigateProfile}
			handleUpdateProfile={handleUpdateEducationProfile}
			selectedProfileId={selectedProfileId}
		/>
	);
};

const mapPropsToState = (dispatch) => ({
	UpdateEducationUserData: (data) =>
		dispatch({
			type: "UPDATE_EDUCATION_USER_DATA",
			payload: data,
		}),
});
export default connect(undefined, mapPropsToState)(EducationProfile);
