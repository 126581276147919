import React from "react";
import "./MBNotifications.scss";
import Home from "../../../assets/images/MB/home.png";
import Explore from "../../../assets/images/MB/explore.png";
import Notifications from "../../../assets/images/MB/notifications.png";
import Messages from "../../../assets/images/MB/messages.png";
import { NavLink, Navigate } from "react-router-dom";
import Search from "../../../assets/images/MB/search.png";
import {
	EduWrapperLeft,
	EduWrapperCenter,
	EduWrapperRight,
	Wrapper,
	MobileBottom,
	MobileBottomIcons,
} from "../styles/index.styled";
import LeftSubNav from "../../../components/LeftSubNav";
import dp from "../../../assets/images/MB/dp.png";
import MBMobileBottom from "../../../components/MB/MBMobileBottom/MBMobileBottom";
import Page from "../../../layouts/Page";

const MBNotifications = () => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const notificationsData = [
		{
			name: "Michelle",
			content:
				"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
		},
		{
			name: "Peter Smith",
			content:
				"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
		},
		{
			name: "David Beckham",
			content:
				"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
		},
		{
			name: "Michelle",
			content:
				"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
		},
		{
			name: "Michelle",
			content:
				"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
		},
		{
			name: "Peter Smith",
			industry: "Engineering",
			content:
				"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
		},
	];

	return (
		<>
			{token ? (
				<>
					<Page>
						<EduWrapperLeft>
							<LeftSubNav />
						</EduWrapperLeft>
						<EduWrapperCenter>
							<div className="notif">
								<div className="notif_container">
									{/* <div className="notif_top">
										<div className="notif_top_dp">
											<img src={dp} alt="" />
										</div>
										<div className="notif_top_desc">
											<h5>George Kajaria</h5>
											<div className="notif_top_desc_follow">
												<p>448 Following</p>
												<p>74 Followers</p>
											</div>
										</div>
									</div> */}
									<div className="notif_bottom">
										<div className="notif_bottom_container">
											{notificationsData.map((notification, index) => (
												<div className="notif_bottom_card" key={index}>
													<img src={dp} alt="" />
													<div className="notif_bottom_card_right">
														<h6>{notification.name}</h6>
														<span className="industry">{notification.industry}</span>
														<p>{notification.content}</p>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</EduWrapperCenter>
						<EduWrapperRight />
					</Page>
					<MBMobileBottom />
				</>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default MBNotifications;
