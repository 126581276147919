import React from "react";

const TV = ({ color = "#834EF2", size = 18 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 19 18" fill="none">
			<g clipPath="url(#clip0_4767_703)">
				<path
					d="M6.16406 0.0908871C6.09023 0.168232 6.07265 0.217449 6.07265 0.361591V0.540888L7.4332 1.90495L8.79375 3.26901L4.96171 3.27604L1.12968 3.28659L1.00312 3.37097C0.936324 3.41667 0.841402 3.51159 0.795699 3.57839L0.711324 3.70495L0.700777 9.86784C0.693746 15.9358 0.693746 16.0342 0.760542 16.1678C0.848433 16.3366 1.01015 16.4737 1.1789 16.5229C1.24921 16.5405 1.48476 16.5581 1.6957 16.5581H2.08242L2.42695 17.226C2.61679 17.5917 2.80664 17.9186 2.84882 17.9467C2.95429 18.0206 4.78945 18.0241 4.88789 17.9503C4.92304 17.9221 5.10937 17.6022 5.29921 17.2366L5.64726 16.5757H9.69375H13.7402L14.0883 17.2366C14.2781 17.6022 14.4644 17.9221 14.4996 17.9503C14.598 18.0241 16.4332 18.0206 16.5387 17.9467C16.5809 17.9186 16.7707 17.5917 16.9605 17.226L17.3051 16.5581H17.6918C17.9062 16.5581 18.1383 16.5405 18.2086 16.5229C18.3773 16.4737 18.5391 16.3366 18.627 16.1678C18.6937 16.0342 18.6937 15.9358 18.6867 9.86784L18.6762 3.70495L18.5918 3.57839C18.5461 3.51159 18.4512 3.41667 18.3844 3.37097L18.2578 3.28659L14.4258 3.27604L10.5937 3.26901L11.9543 1.90495L13.3148 0.540888V0.361591C13.3148 0.11198 13.2023 -0.000518799 12.9527 -0.000518799H12.7734L11.2336 1.53581L9.69375 3.07214L8.1539 1.53581L6.61406 -0.000518799H6.43476C6.29062 -0.000518799 6.2414 0.0170593 6.16406 0.0908871ZM17.9906 9.89596V15.8198H9.69375H1.39687V9.89596V3.97214H9.69375H17.9906V9.89596ZM4.62421 16.9272L4.43789 17.2964H3.86836H3.29531L3.11953 16.9448C3.02461 16.7514 2.94375 16.5862 2.94375 16.5757C2.94375 16.5651 3.36211 16.5581 3.87539 16.5581H4.80703L4.62421 16.9272ZM16.4437 16.5757C16.4437 16.5862 16.3629 16.7514 16.268 16.9448L16.0922 17.2964H15.5191H14.9496L14.7633 16.9272L14.5805 16.5581H15.5121C16.0254 16.5581 16.4437 16.5651 16.4437 16.5757Z"
					fill={color}
				/>
				<path
					d="M3.69922 4.74551C3.12266 4.82285 2.59883 5.19551 2.31055 5.73691C2.09961 6.13418 2.09961 6.08496 2.09961 9.8291C2.09961 12.1072 2.11367 13.3096 2.13828 13.4502C2.25781 14.1146 2.82383 14.7123 3.49531 14.8881C3.79766 14.9689 13.4094 14.9689 13.7117 14.8881C14.3727 14.7158 14.9176 14.1568 15.0547 13.51C15.132 13.1514 15.132 6.48574 15.0547 6.15879C14.907 5.53301 14.4324 5.01973 13.8102 4.80527L13.5781 4.72793L8.74414 4.72441C6.08633 4.7209 3.81523 4.73145 3.69922 4.74551ZM6.89844 8.15566L4.19141 10.8627V11.0244C4.19141 11.1615 4.20898 11.2002 4.30742 11.2881C4.45859 11.4217 4.60977 11.4252 4.79258 11.2881C4.86641 11.2318 6.21992 9.8959 7.79492 8.31738L10.6602 5.44863H11.9258C12.6219 5.44863 13.2828 5.4627 13.3953 5.48379C13.7891 5.54707 14.1125 5.78965 14.2742 6.14473C14.3516 6.30996 14.3516 6.30996 14.3621 9.72012C14.3691 12.2303 14.3621 13.1795 14.334 13.3201C14.2531 13.6928 13.9859 13.9986 13.6309 14.1357C13.4621 14.199 13.2793 14.2025 8.60352 14.2025C3.79414 14.2025 3.75195 14.2025 3.56211 14.1287C3.31602 14.0373 3.07344 13.8053 2.95039 13.5416L2.85547 13.3412L2.84492 9.96621C2.83438 6.42949 2.83789 6.29941 2.98555 6.04629C3.16484 5.74746 3.46016 5.54004 3.78359 5.48379C3.87148 5.46973 5.21797 5.45566 6.77539 5.45215L9.60547 5.44863L6.89844 8.15566Z"
					fill={color}
				/>
				<path
					d="M8.40313 9.74824L6.70508 11.4428V11.6045C6.70508 11.8646 6.90898 12.0299 7.15508 11.9736C7.28867 11.9385 10.5934 8.63379 10.6285 8.49668C10.6602 8.3666 10.6215 8.23652 10.5195 8.13457C10.4527 8.06777 10.3965 8.05019 10.2664 8.05019H10.0977L8.40313 9.74824Z"
					fill={color}
				/>
				<path
					d="M5.59785 12.5643C5.49941 12.6662 5.45723 12.7963 5.48887 12.9229C5.57676 13.2744 6.11113 13.2744 6.19902 12.9229C6.23066 12.7963 6.18848 12.6662 6.09004 12.5643C6.02676 12.501 5.96699 12.4799 5.84394 12.4799C5.7209 12.4799 5.66113 12.501 5.59785 12.5643Z"
					fill={color}
				/>
				<path
					d="M15.7191 5.16748C15.6066 5.21318 15.4941 5.3749 15.4941 5.49092C15.4941 5.62451 15.5891 5.78271 15.6945 5.83193C15.7473 5.85654 16.0602 5.87061 16.5172 5.87061C17.2977 5.87061 17.4137 5.84951 17.498 5.69834C17.5438 5.60693 17.5438 5.39248 17.4945 5.30107C17.4172 5.15693 17.266 5.13232 16.5066 5.13584C16.1129 5.13584 15.7578 5.1499 15.7191 5.16748Z"
					fill={color}
				/>
				<path
					d="M15.6278 6.65455C15.4591 6.81275 15.4555 7.05533 15.6208 7.21001C15.7087 7.29087 15.7263 7.29439 16.4188 7.30494C16.8055 7.31197 17.1747 7.30845 17.238 7.2979C17.5263 7.24165 17.6388 6.88658 17.4313 6.67564L17.3294 6.57369H16.5208C15.7263 6.57369 15.7157 6.57369 15.6278 6.65455Z"
					fill={color}
				/>
				<path
					d="M15.7616 8.01514C15.5436 8.08193 15.4417 8.29639 15.5295 8.51084C15.6139 8.71123 15.6456 8.71826 16.5174 8.71826H17.3155L17.4245 8.62686C17.5194 8.54951 17.5335 8.51436 17.5335 8.3667C17.5335 8.17334 17.428 8.03975 17.2487 8.00107C17.087 7.96943 15.8811 7.97998 15.7616 8.01514Z"
					fill={color}
				/>
				<path
					d="M16.3487 9.66748C15.874 9.76592 15.5365 10.1562 15.5049 10.6483C15.4733 11.0667 15.6983 11.4675 16.0709 11.6644C16.2256 11.7452 16.2924 11.7593 16.5666 11.7593C16.8268 11.7593 16.9076 11.7452 17.0307 11.6784C17.2627 11.5554 17.4174 11.4007 17.5229 11.1862C17.653 10.9226 17.66 10.5464 17.5405 10.2792C17.3401 9.83272 16.8268 9.56904 16.3487 9.66748ZM16.7846 10.4866C16.883 10.5745 16.9182 10.7292 16.8619 10.8347C16.7319 11.0948 16.4119 11.1089 16.2854 10.8628C16.2115 10.7187 16.2186 10.6378 16.3205 10.5147C16.4541 10.3601 16.6334 10.3495 16.7846 10.4866Z"
					fill={color}
				/>
				<path
					d="M16.3487 12.8315C15.874 12.93 15.5365 13.3202 15.5049 13.8124C15.4733 14.2308 15.6983 14.6315 16.0709 14.8284C16.2256 14.9093 16.2924 14.9233 16.5666 14.9233C16.8268 14.9233 16.9076 14.9093 17.0307 14.8425C17.2627 14.7194 17.4174 14.5647 17.5229 14.3503C17.653 14.0866 17.66 13.7104 17.5405 13.4433C17.3401 12.9968 16.8268 12.7331 16.3487 12.8315ZM16.7846 13.6507C16.883 13.7386 16.9182 13.8933 16.8619 13.9987C16.7319 14.2589 16.4119 14.2729 16.2854 14.0269C16.2115 13.8827 16.2186 13.8019 16.3205 13.6788C16.4541 13.5241 16.6334 13.5136 16.7846 13.6507Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_4767_703">
					<rect width={size} height={size} fill="white" transform="translate(0.693359 -0.000488281)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default TV;
