import { Box, Card } from "@mui/material";
import useLayout from "../../../../utils/hooks/useLayout";
import Slick from "../../../Slick";
import { borderRadius, spacing } from "../../../../styles/variables";
import styled from "@emotion/styled";
import smileb from "../../../../assets/images/profile/smileb.png";

const Promotion = ({ post }) => {
	const isMobile = useLayout();
	const { products, likes, comments } = post;
	const slickOptions = {
		slidesToShow: 2,
	};
	return (
		<Box>
			<Slick colSpace={isMobile ? spacing("sm") : spacing("md3")} customSetting={slickOptions}>
				{products?.map((product) => (
					<Card elevation={6} sx={{ p: "4px", pb: 3, borderRadius: borderRadius(8), cursor: "pointer" }}>
						<Box display={"flex"} flexDirection={"column"} rowGap={"12px"}>
							<img src={product?.thumbnail} alt={"product-image"} style={{ width: "100%" }} />
							<Price>${product?.price}</Price>
							<Location>{product?.location?.join(", ")}</Location>
							<Description>{product?.description}</Description>
						</Box>
					</Card>
				))}
			</Slick>
			<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"} mt={"12px"}>
				<Box display={"flex"} columnGap={"10px"} alignItems={"center"}>
					<img
						src={smileb}
						alt="likes"
						style={{
							width: isMobile ? "18px" : "24px",
						}}
					/>
					{likes}
				</Box>
				<Box display={"flex"} columnGap={"10px"} alignItems={"center"}>
					{comments} comments
				</Box>
			</Box>
		</Box>
	);
};

const Price = styled.span`
	color: #61bcac;

	/* font-weight-bold-md */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const Location = styled.span`
	text-transform: capitalize;
	color: #454545;

	/* body-text-xsm */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Description = styled.p`
	color: #454545;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export default Promotion;
