const OnlinePlatforms = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
		<circle cx="46" cy="46" r="45" stroke="#4C4B80" strokeWidth="2" />
		<path
			d="M38.3426 35.6666H61.8154C62.2622 35.6666 62.6316 35.307 62.6316 34.8533V31.8309C62.6316 31.3857 62.2622 31.0175 61.8154 31.0175H38.3426C37.8958 31.0175 37.5264 31.3771 37.5264 31.8309V34.8533C37.5264 35.2985 37.8958 35.6666 38.3426 35.6666ZM39.1588 32.6443H60.9992V34.0399H39.1588V32.6443Z"
			fill="#4C4B80"
		/>
		<path
			d="M27.1689 49.614H43.2341C43.6724 49.614 44.0348 49.203 44.0348 48.6842C44.0348 48.1753 43.6724 47.7544 43.2341 47.7544H27.1689C26.7306 47.7544 26.3682 48.1655 26.3682 48.6842C26.3682 49.203 26.7306 49.614 27.1689 49.614Z"
			fill="#4C4B80"
		/>
		<path
			d="M36.4815 44.0351H43.2209C43.6667 44.0351 44.0354 43.8296 44.0354 43.5702C44.0354 43.3157 43.6667 43.1053 43.2209 43.1053H36.4815C36.0357 43.1053 35.667 43.3108 35.667 43.5702C35.667 43.8296 36.0357 44.0351 36.4815 44.0351Z"
			fill="#4C4B80"
		/>
		<path
			d="M71 65.0979V44.5C71 42.8549 69.6591 41.515 68.0126 41.515H67.164V29.8126C67.164 28.159 65.8146 26.8107 64.1596 26.8107H34.6002V20.7306C34.6002 19.2296 33.3781 18 31.8674 18H20.7327C19.2306 18 18 19.2211 18 20.7306V41.4981C18 42.999 19.2221 44.2286 20.7327 44.2286H21.5475V59.8318C21.5475 61.4854 22.8969 62.8338 24.5603 62.8338H34.9142V66.972H31.0357C30.5944 66.972 30.2295 67.3282 30.2295 67.7776C30.2295 68.2186 30.5944 68.5832 31.0357 68.5832H47.6444C47.9074 69.957 49.1211 71 50.5723 71H68.0126C69.6591 71 71 69.6602 71 68.015V65.0979ZM69.3875 44.5V64.2923H49.1974V44.5C49.1974 43.7453 49.817 43.1262 50.5723 43.1262H66.3662H68.0126C68.7765 43.1262 69.3875 43.7453 69.3875 44.5ZM20.7327 19.6112H31.8674C32.4869 19.6112 32.9877 20.1115 32.9877 20.7306V38.1146H19.621V20.7306C19.621 20.1115 20.1217 19.6112 20.7327 19.6112ZM19.621 41.4981V39.7258H32.9962V41.4981C32.9962 42.1171 32.4954 42.6174 31.8759 42.6174H20.7327C20.1217 42.609 19.621 42.1086 19.621 41.4981ZM31.8759 44.2202C33.3781 44.2202 34.6086 42.999 34.6086 41.4896V38.9117V28.4134H64.1681C64.9404 28.4134 65.56 29.041 65.56 29.8042V41.515H50.5723C48.9259 41.515 47.5849 42.8549 47.5849 44.5V53.0224H23.16V44.2202H31.8759ZM23.16 59.8234V54.6251H47.5849V61.2141H24.5603C23.788 61.2226 23.16 60.595 23.16 59.8234ZM36.5351 66.972V62.8338H47.5849V66.972H36.5351ZM68.0126 69.3888H50.5723C49.817 69.3888 49.1974 68.7698 49.1974 68.015V65.9035H69.3875V68.015C69.3875 68.7698 68.7765 69.3888 68.0126 69.3888Z"
			fill="#4C4B80"
		/>
		<path
			d="M60.8336 67.2807H58.8506C58.3754 67.2807 57.9824 67.6918 57.9824 68.2105C57.9824 68.7195 58.3754 69.1403 58.8506 69.1403H60.8336C61.3088 69.1403 61.7017 68.7293 61.7017 68.2105C61.7017 67.6918 61.3088 67.2807 60.8336 67.2807Z"
			fill="#4C4B80"
		/>
		<path
			d="M57.8796 46.8246H61.8048C62.2574 46.8246 62.6317 46.4135 62.6317 45.8947C62.6317 45.3858 62.2574 44.9649 61.8048 44.9649H57.8796C57.427 44.9649 57.0527 45.376 57.0527 45.8947C57.0527 46.4135 57.4183 46.8246 57.8796 46.8246Z"
			fill="#4C4B80"
		/>
		<path
			d="M59.377 48.6842C55.5295 48.6842 52.4033 51.8103 52.4033 55.6579C52.4033 59.5054 55.5295 62.6316 59.377 62.6316C63.2246 62.6316 66.3507 59.5054 66.3507 55.6579C66.3507 51.8103 63.2246 48.6842 59.377 48.6842ZM59.377 60.9998C56.4312 60.9998 54.0351 58.6037 54.0351 55.6579C54.0351 52.7121 56.4312 50.316 59.377 50.316C62.3228 50.316 64.7189 52.7121 64.7189 55.6579C64.7275 58.6037 62.3228 60.9998 59.377 60.9998Z"
			fill="#4C4B80"
		/>
		<path
			d="M62.0137 54.5048L59.1155 52.6159C58.7064 52.3499 58.1842 52.3322 57.749 52.5716C57.3225 52.811 57.0527 53.2722 57.0527 53.7688V57.5466C57.0527 58.0432 57.3225 58.5043 57.749 58.7438C57.9492 58.8591 58.1668 58.9123 58.3931 58.9123C58.6455 58.9123 58.8979 58.8413 59.1155 58.6994L62.0137 56.8105C62.3967 56.5622 62.6317 56.1277 62.6317 55.6666C62.6317 55.1877 62.4054 54.7531 62.0137 54.5048ZM58.7151 56.9524V54.3541L60.7082 55.6577L58.7151 56.9524Z"
			fill="#4C4B80"
		/>
		<path
			d="M25.1717 22.6491H27.5652C27.928 22.6491 28.2281 22.4436 28.2281 22.1842C28.2281 21.9248 27.928 21.7193 27.5652 21.7193H25.1717C24.8088 21.7193 24.5088 21.9248 24.5088 22.1842C24.5088 22.4436 24.8019 22.6491 25.1717 22.6491Z"
			fill="#4C4B80"
		/>
	</svg>
);

export default OnlinePlatforms;
