import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../../../components/Education/Forms/Signup";
import SignUpForm from "../../../../components/Education/Forms/Signup/Freelancer";
import Skills from "../../../../components/Education/Forms/Signup/Freelancer/Skills.jsx";

const FreelancerSignUp = () => {
	const navigate = useNavigate();
	const [signUpStage, setSignUpStage] = useState(1);
	const [formValues, setFormValues] = useState({});
	const [skills, setSkills] = useState([]);
	const [profilePicture, setProfilePicture] = useState(null);

	const handleSubmit = async (values) => {
		if (signUpStage === 1) {
			setFormValues((prev) => {
				return { ...values, ...prev };
			});
			setSignUpStage(2);
			return;
		}

		const allData = { ...skills, ...formValues };

		try {
			if (signUpStage === 2) {
				navigate("/education/microblogging", {
					state: {
						allData: allData,
						picture: profilePicture,
						url: "createEducationFreelancer",
						redirect: "/education/freelancer/profile/private",
					},
				});
			}
		} catch (err) {
			console.log(err);
		}
	};
	return (
		<SignUp userType="Freelancer" onSubmit={handleSubmit} onBack={signUpStage === 2 ? () => setSignUpStage(1) : undefined}>
			{/* {signUpStage === 1 ? <SignUpForm setProfilePicture={setProfilePicture} /> : <Skills />} */}
			{signUpStage === 1 ? (
				<SignUpForm setProfilePicture={setProfilePicture} handleSubmit={handleSubmit} />
			) : (
				<Skills setSkills={setSkills} handleSubmit={handleSubmit} />
			)}
		</SignUp>
	);
};

export default FreelancerSignUp;
