import * as React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import { borderRadius, color, spacing } from "../../styles/variables";

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
	color: theme.palette.text.secondary,
	[`& .${treeItemClasses.root}`]: {},
	[`& .${treeItemClasses.content}`]: {
		color: color("sBlackFaded"),
		fontFamily: "Red Hat Display",
		fontSize: "18px",
		fontStyle: "normal",
		fontWeight: 500,
		lineHeight: "normal",
		width: "-webkit-fill-available",
		borderRadius: borderRadius(6),
		"&.Mui-expanded": {
			fontWeight: 700,
		},
		"&:hover": {
			backgroundColor: theme.palette.action.hover,
		},
		"&:not(.MuiTreeItem-content).Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused": {
			fontWeight: 700,
			backgroundColor: `var(--tree-view-bg-color, ${color("pGreenFaded")})`,
			color: "var(--tree-view-color)",
			width: "-webkit-fill-available",
		},
		[`& .${treeItemClasses.label}`]: {
			fontWeight: "inherit",
			color: "inherit",
			padding: `${spacing("sm")} ${spacing("sm2")}`,
		},
	},
	[`& .${treeItemClasses.group}`]: {
		marginLeft: 0,
		[`& .${treeItemClasses.content}`]: {
			paddingLeft: theme.spacing(2),
		},
	},
}));

const StyledTreeItem = React.forwardRef(function StyledTreeItem(props, ref) {
	const { bgColor, color, labelIcon: LabelIcon, labelInfo, labelText, colorForDarkMode, bgColorForDarkMode, ...other } = props;

	const styleProps = {
		"--tree-view-color": color,
		"--tree-view-bg-color": bgColor,
	};

	return (
		<StyledTreeItemRoot
			label={
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						width: "-webkit-fill-available",
					}}
				>
					<Box component={LabelIcon} color="inherit" sx={{ mr: 1 }} />
					<Typography variant="body2" sx={{ fontWeight: "inherit", flexGrow: 1 }}>
						{labelText}
					</Typography>
					<Typography variant="caption" color="inherit">
						{labelInfo}
					</Typography>
				</Box>
			}
			style={styleProps}
			{...other}
			ref={ref}
		/>
	);
});

const TreeSelect = ({ treeNodes, ariaLabel, defaultExpanded = undefined, onChange }) => {
	return (
		<TreeView
			aria-label={ariaLabel}
			defaultExpanded={[String(defaultExpanded)]}
			defaultCollapseIcon={<ExpandMoreIcon />}
			defaultExpandIcon={<ChevronRightIcon />}
			sx={{ flexGrow: 1 }}
			onNodeSelect={onChange}
		>
			{treeNodes?.map((treeNode, index) => (
				<StyledTreeItem key={treeNode?.id} nodeId={String(treeNode?.id)} labelText={treeNode?.name}>
					{treeNode?.subNodes?.map((subNode, index) => (
						<StyledTreeItem
							key={subNode?.id}
							nodeId={String(subNode?.id)}
							labelText={subNode?.name}
							labelIcon={() => (
								<img
									style={{
										width: "100%",
										maxWidth: "18px",
										marginRight: spacing("md"),
									}}
									src={subNode?.icon}
								/>
							)}
						/>
					))}
				</StyledTreeItem>
			))}
		</TreeView>
	);
};

export default TreeSelect;
