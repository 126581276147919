import styled from "@emotion/styled";
import { Accordion, InputBase, TextField } from "@mui/material";

const Wrapper = styled.div`
	margin-block: 5px 0;

	@media (min-width: 320px) and (max-width: 760px) {
		margin-block: 0px 0;
	}
`;

const NavWrapper = styled.div`
	display: flex;
	column-gap: 12px;
	padding-inline: 5.4vw;

	@media (min-width: 320px) and (max-width: 760px) {
		display: none;
	}
`;

const BreadCrumb = styled.nav`
	color: #256e61;
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const BreadCrumbArrow = styled.img`
	width: 8px;
	aspect-ratio: 1;
`;

const UserListWrapper = styled.div`
	display: flex;
	flex-direction: column;
	padding-inline-end: 0px;
	// padding-block: 78px;

	&.hidden-on-mobile {
		@media (min-width: 320px) and (max-width: 720px) {
			display: none;
		}
	}
`;

const MessagesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-block-end: 28px;
`;
const MessagesTitle = styled.h3`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width:90%;
	color: #000;
	font-family: Poppins;
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	@media (min-width: 320px) and (max-width: 760px) {
		display: none;
	}
`;

const MessagesSettingsAndEdit = styled.div`
	display: flex;
	column-gap: 4px;

	img {
		cursor: pointer;
		border-radius: 50%;
		width: 32px;
		aspect-ratio: 1;
		filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.25));
	}
`;
const SearchMessages = styled.input`
	margin-top: 28px;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	padding: 12px 48px;
	border-radius: 60px;
	background: #fff;
	box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.09);
	&::placeholder {
		color: #d9d9d9;
		opacity: 1;
	}
`;

const UserList = styled.div`
	display: flex;
	flex-direction: column;
	align-items: baseline;
	row-gap: 24px;
	max-height: 75vh;
	overflow-y: auto;
	overflow-x: hidden;

	scrollbar-width: thin;
	scrollbar-color: #61bcac #ededed;

	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent; 
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(58, 116, 58);
		border-radius: 4px;
	}

	@media (min-width: 320px) and (max-width: 760px) {
		max-height: auto;
	}
`;

const User = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 12px;

	width: 100%;
	cursor: pointer;
`;

const UserIndustry = styled.h5`
	color: #61bcac;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const UserDetailsWrapper = styled.div`
	display: flex;
	column-gap: 16px;
	align-items: center;
`;

const UserImage = styled.img`
	width: 55px;
	aspect-ratio: 1;
	border-radius: 50%;
	cursor: pointer;
`;

const UserDetails = styled.div`
	display: flex;
	flex-direction: column;

	width: 100%;
`;

const UserName = styled.h4`
	color: #000;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const TextMessagePreview = styled.p`
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	width: 75%;
	margin-block-start: -4px;
	color: #b8b8b8;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const ActiveChatWrapper = styled.div`
	border-inline: 1px solid #cdcdcd;
	

	// padding-inline-start: 36px;
	// padding-block-end: 24px;

	&.hidden-on-mobile {
		@media (min-width: 320px) and (max-width: 720px) {
			display: none;
			border-inline: none;
		}

		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		height: 80vh;
	}

	@media (min-width: 320px) and (max-width: 720px) {
		border: none;
		padding: 0;
	}
`;

const ActiveChatUserDetailsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 16px;
	padding-inline-start: 20px;
	padding-block-end: 16px;
	border-bottom: 1px solid #cdcdcd;

	img {
		width: 62px;
	}
	@media (min-width: 320px) and (max-width: 720px) {
		border-bottom: none;
		padding: 0;
	}
`;

const ActiveChatUser = styled.div`
	display: flex;
	align-items: center;
	column-gap: 22px;
	@media (min-width: 320px) and (max-width: 720px) {
		padding-bottom: 24px;
	}
`;

const UserActiveDetails = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2px;
`;

const LastActive = styled.span`
	color: #898989;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const ChatWindowWrapper = styled.div`
	flex: 1;
	padding-inline-start: 24px;
	padding-block: 50px 0;
	height: 53vh;
	margin-inline-end: 16px;
	overflow-y: auto;

	scrollbar-width: thin;
	scrollbar-color: #61bcac #ededed;

	&::-webkit-scrollbar {
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent; 
	}

	&::-webkit-scrollbar-thumb {
		background-color: rgb(58, 116, 58);
		border-radius: 4px;
	}

	@media (min-width: 320px) and (max-width: 760px) {
		padding: 0;
	}
`;

const ChatWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 6px;
	margin-block-end: 24px;
`;

const Message = styled.div`
	display: flex;
	align-items: center;
	column-gap: 8px;

	${(props) =>
		props.firstUserMessage && {
			marginBlockStart: "10px",
		}}

	img {
		margin-block-end: auto;
	}

	p {
		width: fit-content;
		max-width: 70%;
		word-wrap: normal;
		border-radius: 50px;
		background: #ededed;
		padding: 4px 20px;
		color: #8d8d8d;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		${(props) =>
			props.userMessage && {
				backgroundColor: "#61BCAC",
				color: "#000",
				marginInlineStart: "auto",
			}}
	}
`;

const ChatUserImage = styled.img`
	cursor: pointer;
	width: 34px;
	aspect-ratio: 1;
	border-radius: 50%;
`;

const MessagingField = styled(TextField)`
	width: -webkit-fill-available;
	margin-inline-end: 36px;
	border-radius: 50px;
	background: #ededed;
	margin: 1vw 1vw 0vw 1vw;
	border: none;

	input {
		font-family: Poppins;
		border: none;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		::placeholder {
			color: #9b9b9b;
			opacity: 1;
		}
	}
	.MuiOutlinedInput-notchedOutline {
		border: none;
	}
`;

const UserWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-block: 92px;
	padding-inline: 28px;

	img {
		width: 62px;
		margin-block-end: 4px;
		cursor: pointer;
	}
	@media (min-width: 320px) and (max-width: 760px) {
		display: none;
	}
`;

const PlaceholderImage = styled.img`
	width: 28px !important;
	aspect-ratio: 1;
	margin-block: 4px;
`;

const PlaceholderImageTitle = styled.h4`
	color: #717171;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	margin-block-end: 34px;
`;

const MediaFilesAndLinkAccordion = styled(Accordion)`
	box-shadow: none;
	width: 100%;
	.MuiAccordionSummary-root,
	.MuiAccordionSummary-root.Mui-expanded {
		background-color: #fcfdff;
		border: none;
		min-height: fit-content;
		padding: 0;
	}

	.MuiTypography-root {
		color: #000;
		font-family: Poppins;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	.MuiAccordionSummary-content {
		margin: 0;
		&.Mui-expanded {
			margin: 0;
		}
	}
	.MuiAccordionDetails-root {
		padding-inline: 0 !important;
		background-color: #fcfdff;
	}
`;

export {
	Wrapper,
	NavWrapper,
	BreadCrumb,
	BreadCrumbArrow,
	MessagesWrapper,
	MessagesTitle,
	MessagesSettingsAndEdit,
	SearchMessages,
	UserListWrapper,
	UserList,
	User,
	UserIndustry,
	UserDetailsWrapper,
	UserImage,
	UserDetails,
	UserName,
	TextMessagePreview,
	ActiveChatWrapper,
	ActiveChatUserDetailsWrapper,
	ActiveChatUser,
	UserActiveDetails,
	LastActive,
	ChatWindowWrapper,
	ChatWrapper,
	Message,
	ChatUserImage,
	MessagingField,
	UserWrapper,
	PlaceholderImage,
	PlaceholderImageTitle,
	MediaFilesAndLinkAccordion,
};
