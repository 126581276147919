const OnlineAdSpending = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
		<circle cx="46" cy="46" r="45" stroke="#FE7715" strokeWidth="2" />
		<path
			d="M63.0625 54.5H28.9375C28.722 54.5 28.5153 54.4144 28.363 54.262C28.2106 54.1097 28.125 53.903 28.125 53.6875V22.8125C28.125 22.597 28.2106 22.3903 28.363 22.238C28.5153 22.0856 28.722 22 28.9375 22H63.0625C63.278 22 63.4847 22.0856 63.637 22.238C63.7894 22.3903 63.875 22.597 63.875 22.8125V53.6875C63.875 53.903 63.7894 54.1097 63.637 54.262C63.4847 54.4144 63.278 54.5 63.0625 54.5ZM29.75 52.875H62.25V23.625H29.75V52.875Z"
			fill="#FE7715"
		/>
		<path
			d="M54.9375 52.0625C54.4554 52.0625 53.9841 51.9195 53.5833 51.6517C53.1825 51.3839 52.87 51.0032 52.6855 50.5578C52.5011 50.1124 52.4528 49.6223 52.5468 49.1495C52.6409 48.6766 52.873 48.2423 53.2139 47.9014C53.5548 47.5605 53.9891 47.3284 54.462 47.2343C54.9348 47.1403 55.4249 47.1886 55.8703 47.373C56.3157 47.5575 56.6964 47.87 56.9642 48.2708C57.232 48.6716 57.375 49.1429 57.375 49.625C57.375 50.2715 57.1182 50.8915 56.6611 51.3486C56.204 51.8057 55.584 52.0625 54.9375 52.0625ZM54.9375 48.8125C54.7768 48.8125 54.6197 48.8602 54.4861 48.9494C54.3525 49.0387 54.2483 49.1656 54.1869 49.3141C54.1254 49.4625 54.1093 49.6259 54.1406 49.7835C54.172 49.9411 54.2493 50.0859 54.363 50.1995C54.4766 50.3132 54.6214 50.3905 54.779 50.4219C54.9366 50.4532 55.1 50.4371 55.2484 50.3757C55.3969 50.3142 55.5238 50.21 55.6131 50.0764C55.7023 49.9428 55.75 49.7857 55.75 49.625C55.75 49.4095 55.6644 49.2028 55.512 49.0505C55.3597 48.8981 55.153 48.8125 54.9375 48.8125Z"
			fill="#FE7715"
		/>
		<path d="M63.0625 44.75H28.9375V46.375H63.0625V44.75Z" fill="#FE7715" />
		<path d="M60.625 48.8125H56.5625V50.4375H60.625V48.8125Z" fill="#FE7715" />
		<path d="M53.3125 48.8125H31.375V50.4375H53.3125V48.8125Z" fill="#FE7715" />
		<path
			d="M41.9375 38.25H40.3125V33.375C40.3125 32.944 40.1413 32.5307 39.8365 32.226C39.5318 31.9212 39.1185 31.75 38.6875 31.75C38.2565 31.75 37.8432 31.9212 37.5385 32.226C37.2337 32.5307 37.0625 32.944 37.0625 33.375V38.25H35.4375V33.375C35.4375 32.513 35.7799 31.6864 36.3894 31.0769C36.9989 30.4674 37.8255 30.125 38.6875 30.125C39.5495 30.125 40.3761 30.4674 40.9856 31.0769C41.5951 31.6864 41.9375 32.513 41.9375 33.375V38.25Z"
			fill="#FE7715"
		/>
		<path
			d="M46.8125 38.25H43.5625C43.347 38.25 43.1403 38.1644 42.988 38.012C42.8356 37.8597 42.75 37.653 42.75 37.4375V30.9375C42.75 30.722 42.8356 30.5153 42.988 30.363C43.1403 30.2106 43.347 30.125 43.5625 30.125H46.8125C47.459 30.125 48.079 30.3818 48.5361 30.8389C48.9932 31.296 49.25 31.916 49.25 32.5625V35.8125C49.25 36.459 48.9932 37.079 48.5361 37.5361C48.079 37.9932 47.459 38.25 46.8125 38.25ZM44.375 36.625H46.8125C47.028 36.625 47.2347 36.5394 47.387 36.387C47.5394 36.2347 47.625 36.028 47.625 35.8125V32.5625C47.625 32.347 47.5394 32.1403 47.387 31.988C47.2347 31.8356 47.028 31.75 46.8125 31.75H44.375V36.625Z"
			fill="#FE7715"
		/>
		<path
			d="M54.125 38.25H52.5C51.8535 38.25 51.2335 37.9932 50.7764 37.5361C50.3193 37.079 50.0625 36.459 50.0625 35.8125H51.6875C51.6875 36.028 51.7731 36.2347 51.9255 36.387C52.0778 36.5394 52.2845 36.625 52.5 36.625H54.125C54.3405 36.625 54.5472 36.5394 54.6995 36.387C54.8519 36.2347 54.9375 36.028 54.9375 35.8125C54.9375 35.597 54.8519 35.3903 54.6995 35.238C54.5472 35.0856 54.3405 35 54.125 35H52.5C51.8535 35 51.2335 34.7432 50.7764 34.2861C50.3193 33.829 50.0625 33.209 50.0625 32.5625C50.0625 31.916 50.3193 31.296 50.7764 30.8389C51.2335 30.3818 51.8535 30.125 52.5 30.125H54.125C54.7715 30.125 55.3915 30.3818 55.8486 30.8389C56.3057 31.296 56.5625 31.916 56.5625 32.5625H54.9375C54.9375 32.347 54.8519 32.1403 54.6995 31.988C54.5472 31.8356 54.3405 31.75 54.125 31.75H52.5C52.2845 31.75 52.0778 31.8356 51.9255 31.988C51.7731 32.1403 51.6875 32.347 51.6875 32.5625C51.6875 32.778 51.7731 32.9847 51.9255 33.137C52.0778 33.2894 52.2845 33.375 52.5 33.375H54.125C54.7715 33.375 55.3915 33.6318 55.8486 34.0889C56.3057 34.546 56.5625 35.166 56.5625 35.8125C56.5625 36.459 56.3057 37.079 55.8486 37.5361C55.3915 37.9932 54.7715 38.25 54.125 38.25Z"
			fill="#FE7715"
		/>
		<path d="M41.125 33.375H36.25V35H41.125V33.375Z" fill="#FE7715" />
		<path
			d="M69.5625 61H22.4375C21.791 61 21.171 60.7432 20.7139 60.2861C20.2568 59.829 20 59.209 20 58.5625V24.4375C20 23.791 20.2568 23.171 20.7139 22.7139C21.171 22.2568 21.791 22 22.4375 22H27.3125V23.625H22.4375C22.222 23.625 22.0153 23.7106 21.863 23.863C21.7106 24.0153 21.625 24.222 21.625 24.4375V58.5625C21.625 58.778 21.7106 58.9847 21.863 59.137C22.0153 59.2894 22.222 59.375 22.4375 59.375H69.5625C69.778 59.375 69.9847 59.2894 70.137 59.137C70.2894 58.9847 70.375 58.778 70.375 58.5625V24.4375C70.375 24.222 70.2894 24.0153 70.137 23.863C69.9847 23.7106 69.778 23.625 69.5625 23.625H64.6875V22H69.5625C70.209 22 70.829 22.2568 71.2861 22.7139C71.7432 23.171 72 23.791 72 24.4375V58.5625C72 59.209 71.7432 59.829 71.2861 60.2861C70.829 60.7432 70.209 61 69.5625 61Z"
			fill="#FE7715"
		/>
		<path
			d="M67.9375 54.5H24.0625C23.847 54.5 23.6403 54.4144 23.488 54.262C23.3356 54.1097 23.25 53.903 23.25 53.6875V26.0625C23.25 25.847 23.3356 25.6403 23.488 25.488C23.6403 25.3356 23.847 25.25 24.0625 25.25H27.3125V26.875H24.875V52.875H67.125V26.875H64.6875V25.25H67.9375C68.153 25.25 68.3597 25.3356 68.512 25.488C68.6644 25.6403 68.75 25.847 68.75 26.0625V53.6875C68.75 53.903 68.6644 54.1097 68.512 54.262C68.3597 54.4144 68.153 54.5 67.9375 54.5Z"
			fill="#FE7715"
		/>
		<path
			d="M54.9375 65.875H37.0625C36.847 65.875 36.6403 65.7894 36.488 65.637C36.3356 65.4847 36.25 65.278 36.25 65.0625V60.1875C36.25 59.972 36.3356 59.7653 36.488 59.613C36.6403 59.4606 36.847 59.375 37.0625 59.375H54.9375C55.153 59.375 55.3597 59.4606 55.512 59.613C55.6644 59.7653 55.75 59.972 55.75 60.1875V65.0625C55.75 65.278 55.6644 65.4847 55.512 65.637C55.3597 65.7894 55.153 65.875 54.9375 65.875ZM37.875 64.25H54.125V61H37.875V64.25Z"
			fill="#FE7715"
		/>
		<path
			d="M57.375 69.125H34.625C34.4095 69.125 34.2028 69.0394 34.0505 68.887C33.8981 68.7347 33.8125 68.528 33.8125 68.3125V66.6875C33.8125 66.041 34.0693 65.421 34.5264 64.9639C34.9835 64.5068 35.6035 64.25 36.25 64.25H55.75C56.3965 64.25 57.0165 64.5068 57.4736 64.9639C57.9307 65.421 58.1875 66.041 58.1875 66.6875V68.3125C58.1875 68.528 58.1019 68.7347 57.9495 68.887C57.7972 69.0394 57.5905 69.125 57.375 69.125ZM35.4375 67.5H56.5625V66.6875C56.5625 66.472 56.4769 66.2653 56.3245 66.113C56.1722 65.9606 55.9655 65.875 55.75 65.875H36.25C36.0345 65.875 35.8278 65.9606 35.6755 66.113C35.5231 66.2653 35.4375 66.472 35.4375 66.6875V67.5Z"
			fill="#FE7715"
		/>
		<path
			d="M50.0625 63.4375H41.9375C41.722 63.4375 41.5153 63.3519 41.363 63.1995C41.2106 63.0472 41.125 62.8405 41.125 62.625V60.1875H42.75V61.8125H49.25V60.1875H50.875V62.625C50.875 62.8405 50.7894 63.0472 50.637 63.1995C50.4847 63.3519 50.278 63.4375 50.0625 63.4375Z"
			fill="#FE7715"
		/>
	</svg>
);

export default OnlineAdSpending;
