import React from "react";
import "./EngineeringFreelanceProfile.scss";
import Heading from "../../components/Heading/Heading";
import icon from "../../assets/icons/engineering.png";
import profile from "../../assets/images/profile/friends/3.png";
import location from "../../assets/images/icons/location.png";
import job from "../../assets/images/icons/job.png";
import rated from "../../assets/images/icons/rated.png";
import time from "../../assets/images/icons/time.png";
import hours from "../../assets/images/icons/hours.png";
import language from "../../assets/images/icons/language.png";
import star from "../../assets/images/icons/star.png";
import share from "../../assets/images/icons/share.png";
import { NavLink, Navigate } from "react-router-dom";
import { InputAdornment, TextField } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import egicon1 from "../../assets/images/icons/egicon1.svg";
import egicon2 from "../../assets/images/icons/egicon2.svg";
import MenuIcon from "@mui/icons-material/Menu";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";

const EngineeringFreelanceProfile = () => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	return (
		<>
			{token ? (
				<div className="engineering-f">
					<Heading icon={icon} title={"Engineering"} showSearchInput={false} />
					<div className="engineering-f_container">
						<div className="engineerhome_header">
							<div className="engineerhome_header_main">
								<div className="engineerhome_header_main_left">
									<ul>
										<li>
											<NavLink>
												<span>Jobs</span>
												<ExpandMoreIcon />
											</NavLink>
										</li>
										<li>
											<NavLink to="/">
												<span>Talent</span>
												<ExpandMoreIcon />
											</NavLink>
										</li>
										<li>
											<NavLink to="/">
												<span>Reports</span>
												<ExpandMoreIcon />
											</NavLink>
										</li>
										<li>
											<NavLink to="/">
												<span>Messages</span>
											</NavLink>
										</li>
									</ul>
									<TextField
										className="input"
										variant="standard"
										sx={{
											width: "95%",
											background: "#fff",
											borderRadius: "1vw",
										}}
										InputProps={{
											startAdornment: (
												<InputAdornment>
													<SearchIcon sx={{ width: "1.4vw", marginLeft: "0.3vw" }} />
													<span style={{ borderLeft: "1px solid black" }}>Talent</span>
													<ExpandMoreIcon />
												</InputAdornment>
											),
										}}
									/>
								</div>
								<div className="engineerhome_header_main_center">
									<ul>
										<li>
											<NavLink>
												<img src={egicon1} alt="" />
												<span>Micro Blogging</span>
											</NavLink>
										</li>
										<li>
											<NavLink to="/">
												<img src={egicon2} alt="" />
												<span> Short Videos</span>
											</NavLink>
										</li>
									</ul>
								</div>
								<div className="engineerhome_header_main_right">
									<div className="engineerhome_header_main_right_rightmob">
										<MenuIcon sx={{ color: "white", fontSize: 40 }} />
										<SearchIcon sx={{ color: "white", fontSize: 25 }} />
									</div>

									<ul>
										<li>
											<NavLink>
												<NotificationsIcon />
											</NavLink>
										</li>
										<li>
											<NavLink to="/">
												<FavoriteBorderIcon />
											</NavLink>
										</li>
										<li>
											<NavLink to="/">
												<PersonOutlineIcon />
											</NavLink>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div className="engineering-f_sections">
							<div className="engineering-f_profile">
								<div className="engineering-f_profile_container">
									<div className="engineering-f_profile_left">
										<div className="engineering-f_profile_left_image">
											<img src={profile} alt="profile" />
										</div>
										<div className="engineering-f_profile_left_user">
											<h4>Thomas Mark</h4>
											<div className="engineering-f_profile_left_user_location">
												<img src={location} alt="location-icon" />
												<p>United States, India - 10:46 am local time</p>
											</div>
											<div className="engineering-f_profile_left_user_job">
												<div className="engineering-f_profile_left_user_job_left">
													<img src={job} alt="job-icon" />
													<p>100% Job Success</p>
												</div>
												<div className="engineering-f_profile_left_user_job_right">
													<img src={rated} alt="rated-icon" />
													<p>Top rated</p>
												</div>
											</div>
										</div>
									</div>
									<div className="engineering-f_profile_left_user_job_mobile">
										<div className="engineering-f_profile_left_user_job_left">
											<img src={job} alt="job-icon" />
											<p>100% Job Success</p>
										</div>
										<div className="engineering-f_profile_left_user_job_right">
											<img src={rated} alt="rated-icon" />
											<p>Top rated</p>
										</div>
									</div>
									<div className="engineering-f_profile_right">
										<button>See public view</button>
										<button>Profile Settings</button>
									</div>
								</div>
							</div>
							<div className="engineering-f_details">
								<div className="engineering-f_details_container">
									<div className="engineering-f_details_left">
										<div className="engineering-f_details_left_container">
											<div className="engineering-f_details_left_earnings">
												<div className="engineering-f_details_left_earnings_sec">
													<h5>$80K</h5>
													<p>Total Earnings</p>
												</div>
												<div className="engineering-f_details_left_earnings_sec">
													<h5>150</h5>
													<p>Total Jobs</p>
												</div>
												<div className="engineering-f_details_left_earnings_sec">
													<h5>8966</h5>
													<p>Total hours</p>
												</div>
											</div>
											<div className="engineering-f_details_left_hours">
												<div className="engineering-f_details_left_hours_heading">
													<h5>Hours per week</h5>
													<img src={hours} alt="hours-icon" />
												</div>
												<div className="engineering-f_details_left_hours_content">
													<h6>More than 30 hrs/week</h6>
													<h6>No contract-to-hire preference set</h6>
												</div>
											</div>
											<div className="engineering-f_details_left_language">
												<div className="engineering-f_details_left_language_heading">
													<h5>Languages</h5>
													<img src={language} alt="hours-icon" />
												</div>
												<div className="engineering-f_details_left_hours_content">
													<h6>English: Native or Bilingual</h6>
												</div>
											</div>
										</div>
									</div>
									<div className="engineering-f_details_right">
										<div className="engineering-f_details_right_container">
											<div className="engineering-f_details_right_heading">
												<div className="engineering-f_details_right_heading_left">
													<h3>Web Developer , Graphics Designer, Digital Marketing expert</h3>
												</div>
												<div className="engineering-f_details_right_heading_right">
													<h3>$9/hr</h3>
													<img src={time} alt="time-icon" />
												</div>
											</div>
											<div className="engineering-f_details_right_about">
												<p>
													I have 10 years of professional experience. I always work honestly and hard to
													please my clients. I always strict deadlines and do work with fast turnaround
													times. I am flexible with hourly rates.
												</p>
											</div>
											<div className="engineering-f_details_right_history">
												<div className="engineering-f_details_right_history_heading">
													<h4>Work History</h4>
												</div>
												<div className="engineering-f_details_right_history_data">
													<div className="engineering-f_details_right_history_data_tabs">
														<div className="engineering-f_details_right_history_data_tab">
															<h5>Completed jobs (102)</h5>
														</div>
														<div className="engineering-f_details_right_history_data_tab">
															<h5 className="active">In Progress (20)</h5>
														</div>
													</div>
													<div className="engineering-f_details_right_history_data_sec">
														<div className="engineering-f_details_right_history_data_sec_heading">
															<h6>Wordpress Development</h6>
														</div>
														<div className="engineering-f_details_right_history_data_sec_content">
															<p>
																He was quick, efficient & had a great eye for detail. She went
																over & beyond. Highly recommend him."
															</p>
														</div>
														<div className="engineering-f_details_right_history_data_sec_details">
															<div className="engineering-f_details_right_history_data_sec_details_stars">
																<img src={star} alt="star-icon" />
																<img src={star} alt="star-icon" />
																<img src={star} alt="star-icon" />
																<img src={star} alt="star-icon" />
																<img src={star} alt="star-icon" />
															</div>
															<p>Oct 18, 2018 - May 14, 2022</p>
															<img src={share} alt="share-icon" />
														</div>
													</div>
													<div className="engineering-f_details_right_history_data_sec">
														<div className="engineering-f_details_right_history_data_sec_heading">
															<h6>Software Engineering</h6>
														</div>
														<div className="engineering-f_details_right_history_data_sec_content">
															<p>
																Excellent skills and good communication skills. Was always able to
																give us an update on task and looking forward to working with her
																for our project.
															</p>
														</div>
														<div className="engineering-f_details_right_history_data_sec_details">
															<div className="engineering-f_details_right_history_data_sec_details_stars">
																<img src={star} alt="star-icon" />
																<img src={star} alt="star-icon" />
																<img src={star} alt="star-icon" />
																<img src={star} alt="star-icon" />
																<img src={star} alt="star-icon" />
															</div>
															<p>Oct 18, 2018 - May 14, 2022</p>
															<img src={share} alt="share-icon" />
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default EngineeringFreelanceProfile;
