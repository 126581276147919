import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import Agriculture from "../../../../assets/icons/agriculture/agriculture.jsx";
import SignUpForm from "../../../../components/Agriculture/Forms/Signup/Freelancer";
import Skills from "../../../../components/Agriculture/Forms/Signup/Freelancer/Skills/index.jsx";
import styled from "@emotion/styled";
import Heading from "../../../../components/Heading/Heading.jsx";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	justify-content: center;
	margin-inline: 40px;

	@media screen and (max-width: 768px) {
		display: flex;
		margin-inline: 0;
	}

	@media screen and (min-width: 1440px) {
		max-width: 1440px;
		margin-inline: auto;
	}
`;

const FreelancerSignUp = () => {
	const navigate = useNavigate();
	const [signUpStage, setSignUpStage] = useState(1);
	const [formValues, setFormValues] = useState({});
	const [profilePicture, setProfilePicture] = useState(null);

	const handleSubmit = async (values) => {
		if (signUpStage === 1) {
			setFormValues((prev) => {
				return { ...prev, ...values };
			});
			setSignUpStage(2);
			return;
		}
		const allData = { ...formValues, ...values };
		try {
			if (signUpStage === 2) {
				navigate("/agriculture/microblogging", {
					state: {
						allData: allData,
						picture: profilePicture,
						url: "createAgricultureFreelancer",
						redirect: "/agriculture/freelancer/profile/private",
					},
				});
			}
		} catch (err) {
			console.log(err);
		}
	};

	const handleBack = () => {
		if (signUpStage === 2) setSignUpStage(1);
		else navigate(-1);
	};
	return (
		<Fragment>
			<Heading icon={Agriculture} title={"Agriculture"} showSearchInput={false} moduleHomeRedirect={"/agriculture/home"} />
			<Wrapper>
				{signUpStage === 1 ? (
					<SignUpForm setProfilePicture={setProfilePicture} handleSubmit={handleSubmit} handleBack={handleBack} />
				) : (
					<Skills handleSubmit={handleSubmit} handleBack={handleBack} />
				)}
			</Wrapper>
		</Fragment>
	);
};

export default FreelancerSignUp;
