import styled from "@emotion/styled";

const CardTitle = styled.h2`
	color: #696a68;
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	& span {
		color: #848484;
		font-family: Inter;
		font-size: 13px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;

const CardDesc = styled.p`
	color: #3d3d3d;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	margin-block: 10px;
`;

export { CardTitle, CardDesc };
