import React, { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import { GetAllITProfile, UpdateITProfile } from "../../../utils/apiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import ProfileComponent from "../../ProfileComponent/ProfileComponent";
import { connect } from "react-redux";
import Loader from "../../../components/loader/index";

const ITprofile = ({ UpdateItUserData, id, open, anchorEl, handleClose, activeModule }) => {
	const navigate = useNavigate();
	const [usersData, setUsersData] = useState([]);
	const [selectedProfileId, setSelectedProfileId] = useState();
	const location = useLocation();
	const ModuleName = location?.pathname.split("/")[1];

	const getItProfilesDetails = () => {
		GetAllITProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setUsersData(data);
			} else {
				setUsersData([]);
			}
		});
	};
	useEffect(() => {
		getItProfilesDetails();
	}, []);

	const { data } = useQuery({
		queryKey: ["ITProfile"],
		queryFn: async () => {
			const response = await query("GET", "IT", "getITProfile");
			const { data } = response;
			UpdateItUserData(data);
			return response.data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
		enabled: activeModule === "it",
	});

	useEffect(() => {
		const filteredData = usersData.filter((user) => user.itProfileId === data?.itProfileId);
		setSelectedProfileId(filteredData[0]?.itProfileId);
	}, [usersData, data]);

	const handleUpdateProfile = (updateId) => {
		if (updateId) {
			const payload = {
				itProfileId: updateId,
			};
			UpdateITProfile((res) => {
				setSelectedProfileId(updateId);
				const { status, message, data } = res;
				if (status === 200) {
					localStorage.setItem("itProfileId", updateId);
				}
			}, payload);
		}
	};
	const toggleAddAccount = () => {
		navigate("/it");
		handleClose();
	};

	const handleNavigateProfile = (user) => {
		if (user) {
			UpdateItUserData(user);
			const profile = user.itProfileType.toLowerCase();
			if (ModuleName && profile) {
				navigate(`/${ModuleName}/${profile}/profile/private`, { state: { userData: user } });
				handleClose();
			}
		}
	};

	return (
		<ProfileComponent
			open={open}
			id={id}
			handleClose={handleClose}
			anchorEl={anchorEl}
			usersData={usersData}
			toggleAddAccount={toggleAddAccount}
			handleNavigateProfile={handleNavigateProfile}
			handleUpdateProfile={handleUpdateProfile}
			selectedProfileId={selectedProfileId}
			keyName={"itProfileId"}
			typeKeyName={"itProfileType"}
		/>
	);
};

const mapStateToProps = (state) => ({
	activeModule: state?.module?.active?.name,
});

const mapPropsToState = (dispatch) => ({
	UpdateItUserData: (data) =>
		dispatch({
			type: "UPDATE_IT_USER_DATA",
			payload: data,
		}),
});
export default connect(mapStateToProps, mapPropsToState)(ITprofile);
