import FeedContent from "../../../components/Magazine/Feed/Content";
import FeedNav from "../../../components/Magazine/Feed/Nav";
import Page from "../../../layouts/Page";
import { Content, FeedWrapper, HotTopics, LeftNav, Trending } from "./index.styled";
import LatestTopics from "../../../components/Magazine/Feed/LatestTopics";
import TrendingTopics from "../../../components/Magazine/Feed/TrendingTopics";
import useLayout from "../../../utils/hooks/useLayout";

const Home = () => {
	const isMobile = useLayout();
	return (
		<Page
			overrides={
				isMobile && {
					paddingInline: "12px",
				}
			}
		>
			{!isMobile && (
				<LeftNav>
					<FeedNav />
				</LeftNav>
			)}
			<FeedWrapper>
				<Content>
					<FeedContent />
				</Content>
				<Trending>
					<TrendingTopics />
				</Trending>
				<LatestTopics />
			</FeedWrapper>
		</Page>
	);
};

export default Home;
