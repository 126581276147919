import React, { useState } from "react";
import Slick from "../../../components/Slick";
import { Wrapper, Button, SubWrapper, SectionWrapper, SectionTitle, CardWrapper, Info } from "./index.styled";
import { spacing } from "../../../styles/variables";
import useLayout from "../../../utils/hooks/useLayout";
import { Box } from "@mui/material";
import Dating1 from "../../../assets/images/dating/dating1.png";
import Dating2 from "../../../assets/images/dating/dating2.png";
import Dating3 from "../../../assets/images/dating/dating3.png";
import Dating4 from "../../../assets/images/dating/dating4.png";
import Dating5 from "../../../assets/images/dating/dating5.png";
import Dating6 from "../../../assets/images/dating/dating6.png";
import Dating7 from "../../../assets/images/dating/dating7.png";
import Dating8 from "../../../assets/images/dating/dating8.png";
import Camera from "@mui/icons-material/PhotoCameraOutlined";
import Comment from "../../../assets/icons/Comment";
import Like from "../../../assets/icons/Like";

const matches = [
	{
		title: "Female Matches",
		members: [
			{
				id: 1,
				profilePicture: Dating1,
				name: "Peter Smith",
				country: "United States",
				age: 25,
			},
			{
				id: 2,
				profilePicture: Dating2,
				name: "Jason",
				country: "United States",
				age: 24,
			},
			{
				id: 3,
				profilePicture: Dating3,
				name: "Parth",
				country: "Georgia",
				age: 28,
			},
			{
				id: 4,
				profilePicture: Dating4,
				name: "Thomas",
				country: "Georgia",
				age: 31,
			},
			{
				id: 5,
				profilePicture: Dating1,
				name: "Peter Smith",
				country: "United States",
				age: 26,
			},
		],
	},
	{
		title: "Male Matches",
		members: [
			{
				id: 1,
				profilePicture: Dating5,
				name: "Peter Smith",
				country: "United States",
				age: 24,
			},
			{
				id: 2,
				profilePicture: Dating6,
				name: "Jason",
				country: "United States",
				age: 23,
			},
			{
				id: 3,
				profilePicture: Dating7,
				name: "Parth",
				country: "Georgia",
				age: 27,
			},
			{
				id: 4,
				profilePicture: Dating8,
				name: "Thomas",
				country: "Georgia",
				age: 30,
			},
		],
	},
	{
		title: "LGBTQ Matches",
		members: [
			{
				id: 1,
				profilePicture: Dating1,
				name: "Kattle Kick",
				country: "United States",
				age: 24,
			},
			{
				id: 2,
				profilePicture: Dating6,
				name: "Peter Cock",
				country: "United States",
				age: 22,
			},
			{
				id: 3,
				profilePicture: Dating8,
				name: "TLC",
				country: "Georgia",
				age: 27,
			},
			{
				id: 4,
				profilePicture: Dating2,
				name: "Marriot Battles",
				country: "Georgia",
				age: 29,
			},
			{
				id: 5,
				profilePicture: Dating3,
				name: "Kattle Kick",
				country: "United States",
				age: 30,
			},
		],
	},
];

const Matches = () => {
	const isMobile = useLayout();

	return (
		<Wrapper>
			{matches?.map((match) => (
				<SubWrapper>
					<SectionWrapper>
						<SectionTitle>{match?.title}</SectionTitle>
						<Slick colSpace={isMobile ? spacing("sm") : spacing("xl")}>
							{match?.members?.map((member) => (
								<CardWrapper>
									<Box display={"flex"} flexDirection={"column"} rowGap={"24px"}>
										<img src={member?.profilePicture} alt="Profile Picture" />
										<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
											<Box display={"flex"} flexDirection={"column"}>
												<Info>{member?.name}</Info>
												<Info>{`${member?.age}, ${member?.country}`}</Info>
											</Box>
											<Box display={"flex"} alignItems={"center"} columnGap={"6px"} sx={{ mt: "auto" }}>
												3 <Camera />
											</Box>
											<Box
												display={"flex"}
												alignItems={"center"}
												columnGap={"6px"}
												sx={{
													mb: "auto",
													"& > *": {
														cursor: "pointer",
													},
												}}
											>
												<Comment color="#2D7D6F" size={24} />
												<Like color="#D751D1" size={24} />
											</Box>
										</Box>
										<Button>View Profile</Button>
									</Box>
								</CardWrapper>
							))}
						</Slick>
					</SectionWrapper>
				</SubWrapper>
			))}
		</Wrapper>
	);
};

export default Matches;
