import styled from "@emotion/styled";
import { FormControlLabel } from "@mui/material";
import { color } from "../../../styles/variables";

const Wrapper = styled.form`
	grid-column: 4 / span 6;
	margin-inline: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-width: 400px;
	background: #fff;
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
	border-radius: 7px;

	padding: 12px;

	@media screen and (max-width: 768px) {
		min-width: initial;
		padding-block: 24px;
		max-width: initial;
		width: -webkit-fill-available;
	}
`;

const Title = styled.h1`
	color: #000;
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 128%; /* 46.08px */

	padding-block-end: 12px;
	text-align: center;
	width: 100%;

	border-bottom: 1px solid #dedede;

	@media screen and (max-width: 768px) {
		font-size: 26px;
	}
`;

const FormWrapper = styled.div`
	width: 95%;
	display: flex;
	flex-direction: column;

	margin-inline: auto;

	@media screen and (max-width: 768px) {
		width: -webkit-fill-available;
		margin-inline: 0;
	}
`;

const FormTitle = styled.h2`
	color: #515151;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 157%; /* 34.54px */

	margin-inline: auto;
	margin-block: 12px;

	@media screen and (max-width: 768px) {
		margin-block: 20px;
	}
`;

const Divider = styled.div`
	width: -webkit-fill-available;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		top: 50%;
		width: 100%;
		background-color: #dedede;
	}
`;

const DividerText = styled.span`
	color: #000;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 157%; /* 28.26px */

	background-color: #fff;

	padding-inline: 32px;

	position: relative;
	z-index: 2;
`;

const RememberMeWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	margin-block-end: 12px;

	@media screen and (max-width: 768px) {
		margin-block-end: 12px;
	}
`;

const RememberMe = styled(FormControlLabel)`
	color: #8c8a8a;
	.MuiTypography-root {
		font-size: 14px;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 157%; /* 23.55px */
	}
`;

const ForgotPassword = styled.span`
	cursor: pointer;
	color: #8c8a8a;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 157%; /* 23.55px */

	&:hover {
		color: ${color("pGreen")};
	}
`;

const GoogleLogin = styled.div`
	width: 95%;
`;

const Signup = styled.div`
	margin-block-start: 12px;
	&,
	& > span {
		color: #696363;
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 500;
		line-height: 137%; /* 24.66px */

		@media screen and (max-width: 768px) {
			font-size: 16px;
		}
	}

	& > span {
		cursor: pointer;
		color: #61bcac;
		text-decoration-line: underline;
	}
`;

export {
	Wrapper,
	Title,
	FormWrapper,
	FormTitle,
	Divider,
	DividerText,
	RememberMeWrapper,
	RememberMe,
	ForgotPassword,
	GoogleLogin,
	Signup,
};
