import React, { useEffect, useState } from "react";
import locationMark from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/user-profile-backsquare.png";
import editable from "../../../../../assets/images/editable.png";
import editablenoborder from "../../../../../assets/images/editablenoborder.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";
import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfilesWrapper,
	ProfileModule,
	Butt,
} from "../../../styles/index.styled.js";
import { connect } from "react-redux";
import { GetITClientProfile, GetITShortVideoPrivateAccount } from "../../../../../utils/apiCalls.js";
import Loader from "../../../../../components/loader/index.js";

const ClientPrivate = ({ setActiveModule, setActiveMBProfile }) => {
	const [clientProfileData, setClientProfileData] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const location = useLocation();
	const userData = location.state?.userData;
	const [shortVideoIT, setShortVideoIT] = useState([]);
	const navigate = useNavigate();
	const handlePublicView = () => {
		navigate("/it/client/profile/public");
	};

	const getITClientProfile = () => {
		setIsLoader(true);
		if (userData) {
			const payload = {
				itProfileTypeId: userData?.itProfileTypeId || userData?.accountTypeId,
				itProfileId: userData?.itProfileId || userData?.moduleId,
			};
			GetITClientProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setIsLoader(false);
					setClientProfileData(data);
				} else {
					setIsLoader(false);
					setClientProfileData();
				}
			}, payload);
		}
	};

	useEffect(() => {
		getITClientProfile();
	}, [userData]);

	const handleMBRedirection = (data) => {
		setActiveModule("it");
		setActiveMBProfile(data?.itProfileId);
		navigate("/microblogging/profile/private");
	};

	const getITSVPrivateAccount = () => {
		const itProfileId = userData?.itProfileId;
		if (itProfileId) {
			const payload = {
				itProfileId: itProfileId,
			};
			GetITShortVideoPrivateAccount((res) => {
				const { message, data, status } = res;
				if (status === 200) {
					setShortVideoIT(data);
				} else {
					setShortVideoIT([]);
				}
			}, payload);
		}
	};

	const handleShortvideos = (clientProfileData) => {
		getITSVPrivateAccount();
		if (shortVideoIT) {
			navigate("/shorts/profile/private", { state: { user: clientProfileData } });
		}
	};

	return (
		<>
			{token ? (
				isLoader ? (
					<Loader />
				) : (
					<Wrapper>
						<ProfileWrapper>
							<ProfileSections>
								<SectionTitle>Client</SectionTitle>
								<Profile>
									<ProfileDetailsWrapper>
										<ProfileImageWrapper>
											<img src={clientProfileData?.profilePicture || ProfilePicture} alt="" />
											<img src={editable} className="imgedit" alt="profile" />
										</ProfileImageWrapper>
										<UserDetailsWrapper>
											<UserNameWrapper>
												{clientProfileData?.fullName || "Anonymous User"}
												<img
													src={editablenoborder}
													className="imgedit"
													alt="profile"
													style={{
														display: "block",
													}}
												/>
											</UserNameWrapper>
											<UserAddress>
												<img src={locationMark} alt="location-icon" />
												{clientProfileData?.location || "No Location available"}
											</UserAddress>
											{/* To be visible on mobile only */}
											<UserProfileActions
												hideOnDesktop
												style={{
													marginBlockEnd: "14px",
												}}
											>
												<Button
													buttonClick={handlePublicView}
													styles={{
														width: "fit-content",
														backgroundColor: "#FCFDFF",
														color: "#6B6B6B",
														borderColor: "#C2C2C2",
													}}
												>
													See Public View
												</Button>
											</UserProfileActions>
										</UserDetailsWrapper>
									</ProfileDetailsWrapper>
									{/* To be visible on desktop only */}
									<UserProfileActions
										hideOnMobile
										style={{
											marginBlockEnd: "14px",
										}}
									>
										<Button
											buttonClick={handlePublicView}
											styles={{
												width: "fit-content",
												backgroundColor: "#FCFDFF",
												marginLeft: "auto",
												color: "#6B6B6B",
												borderColor: "#C2C2C2",
											}}
										>
											See Public View
										</Button>
									</UserProfileActions>
								</Profile>

								<MoreDetailWrapper>
									<ProfilesAndAboutWrapper>
										<ProfilesWrapper>
											<Profiles fillAvailable>
												<ProfileHeading>Other Profiles</ProfileHeading>
												<ProfileModule>
													<Butt onClick={() => handleMBRedirection(clientProfileData)}>
														Microblogging
													</Butt>
													<Butt onClick={() => handleShortvideos(clientProfileData)}>ShortVideos</Butt>
												</ProfileModule>
												<ProfileList>
													{clientProfileData?.userName ? (
														<ProfileName>
															<ProfileUserName>userName</ProfileUserName>{" "}
															{clientProfileData?.userName}
														</ProfileName>
													) : (
														"No Data Available"
													)}
													{clientProfileData?.shortVideoUsername && (
														<ProfileName>
															<ProfileUserName>shortVideoUsername</ProfileUserName>{" "}
															{clientProfileData?.shortVideoUsername}
														</ProfileName>
													)}
												</ProfileList>
											</Profiles>
										</ProfilesWrapper>
										<About>
											<AboutTitle>
												About <img src={editablenoborder} alt="" />
											</AboutTitle>
											<AboutDesc>{clientProfileData?.about || "No Data available "}</AboutDesc>
											<More>more...</More>
										</About>
									</ProfilesAndAboutWrapper>
									<FeedbackWrapper>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment1}
												commentimagestyle={{
													marginLeft: "2rem",
												}}
												commenttitle="Thanks"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment}
												commenttitle="Reply"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
									</FeedbackWrapper>
								</MoreDetailWrapper>
							</ProfileSections>
						</ProfileWrapper>
					</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state.it?.profile?.data,
	};
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(mapStateToProps, mapPropsToState)(ClientPrivate);
