import { CardTitle, CardDesc } from "./index.styled";
import { Box, Card } from "@mui/material";
import Comment from "../../../../assets/icons/Comment";
import Statistics from "@mui/icons-material/BarChart";
import Bookmark from "@mui/icons-material/BookmarkBorder";
import Share from "../../../../assets/icons/Share2";
import { BarChart } from "@mui/x-charts/BarChart";
import { color } from "../../../../styles/variables";

const Index = ({ actions = false }) => {
	return (
		<Card elevation={6} sx={{ p: "10px" }}>
			<Box display={"flex"} flexDirection={"column"} rowGap={"12px"}>
				<CardTitle>Project Name: Riverdale Bridge Construction</CardTitle>
				<CardTitle>
					Location: <span>Over the Grand River, connecting the East and West sides of Riverdale</span>
				</CardTitle>
				<CardTitle>Overview:</CardTitle>
			</Box>
			<CardDesc>
				The Department of Transportation and Infrastructure is considering the construction of a new bridge, designated as
				the Riverdale Bridge, spanning the Grand River. This project is a significant undertaking aimed at improving
				transportation efficiency, enhancing connectivity between regions, and supporting local economic growth.
			</CardDesc>
			<BarChart
				sx={{
					"& .MuiChartsAxis-tick": {
						display: "none",
					},
				}}
				axisHighlight={{
					x: "none",
				}}
				tooltip={{
					trigger: "none",
				}}
				xAxis={[
					{
						scaleType: "band",
						data: ["Favour", "Against", "Abstention"],
						categoryGapRatio: 0.6,
						barGapRatio: -1,
						axisLine: {
							show: false,
						},
					},
				]}
				series={[
					{ data: [30, 0, 0], stack: "A", color: "#61BCAC" },
					{ data: [0, 70, 0], stack: "B", color: "#256F61" },
					{ data: [0, 0, 40], stack: "C", color: "#3ABF81" },
				]}
				leftAxis={null}
				height={400}
				margin={{
					left: 12,
					right: 12,
				}}
			/>
			{actions && (
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					mt={"24px"}
					sx={{
						"& > *": {
							cursor: "pointer",
						},
					}}
				>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Comment /> 300
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Statistics
							sx={{
								color: "#929292",
							}}
						/>
						800
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Bookmark
							sx={{
								color: "#929292",
							}}
						/>
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Share />
					</Box>
				</Box>
			)}
		</Card>
	);
};

export default Index;
