import { useEffect, useState } from "react";
import { Wrapper, FormWrapper } from "./index.styled";
import Header from "../../components/Header";
import LoginForm from "../../components/Login/Form";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserLogin } from "../../utils/apiCalls";

const Login = () => {
	const navigate = useNavigate();
	const [isShowLoader, setIsShowLoader] = useState(false);
	const [loginError, setLoginError] = useState(null);

	const tokenUrl = localStorage.getItem("token");
	useEffect(() => {
		if (tokenUrl) {
			navigate("/home");
		}
	}, [tokenUrl]);

	const handleSubmit = (values) => {
		const payload = {
			email: values?.username,
			password: values?.password,
			remember: values?.rememberMe,
		};
		if (payload) {
			setIsShowLoader(true);
			UserLogin((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					if (payload.remember) {
						localStorage.setItem("token", data?.token);
						localStorage.setItem("userEmail", data?.userEmail);
					} else {
						sessionStorage.setItem("token", data?.token);
						sessionStorage.setItem("userEmail", data?.userEmail);
					}
					navigate("/home");
				} else {
					setLoginError(message);
					setIsShowLoader(false);
					navigate("/login");
				}
			}, payload);
		}
	};

	return (
		<>
			<Wrapper>
				<Header hideButton />
				<FormWrapper>
					<LoginForm onSubmit={handleSubmit} disabled={isShowLoader} loginError={loginError} />
				</FormWrapper>
			</Wrapper>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userProfileData: state.user.profile,
	};
};

export default connect(mapStateToProps)(Login);
