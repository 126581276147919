import { Box } from "@mui/material";
import SidenavComponent from "../../SidenavComponent/SidenavComponent";
import styled from "@emotion/styled";

const LeftNav = () => {
	return (
		<CustomBox
			gridColumn={"span 3"}
			position={"sticky"}
			top={0}
			maxHeight={"inherit"}
			sx={{
				overflowY: "auto",
			}}
		>
			<SidenavComponent />
		</CustomBox>
	);
};

const CustomBox = styled(Box)`
	::-webkit-scrollbar {
		border-radius: 2px;
		width: 10px;
	}

	&::-webkit-scrollbar {
		border-radius: 2px;
		width: 4px;
		height: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: transparent;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #d9d9d9;
		border-radius: 4px;
	}
`;

export default LeftNav;
