import React from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../../../components/Investment/Forms/Signup";
import EmployeeSignUpForm from "../../../../components/Investment/Forms/Signup/Employee";

const EmployeeSignUp = () => {
	const navigate = useNavigate();
	const handleFormSubmit = (values) => {
		navigate("/investment/microblogging");
	};
	return (
		<>
			<SignUp userType="Regular Employee" onSubmit={handleFormSubmit}>
				<EmployeeSignUpForm />
			</SignUp>
		</>
	);
};

export default EmployeeSignUp;
