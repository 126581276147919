import { Route, Routes, useLocation } from "react-router-dom";
import Signup from "../pages/MB/Signup";
import Feed from "../pages/MB/Feed";
import PublicProfile from "../pages/MB/Profile/Public";
import PrivateProfile from "../pages/MB/Profile/Private";
import Education from "../pages/MB/Feed/Education";
import Following from "../pages/MB/Following";
import RewardFeed from "../pages/MB/Feed/Reward";
import Monthly from "../pages/MB/Monthly";
import Home from "../pages/MB";
import headerimg from "../assets/images//MB/mbheaderimg.png";
import SubHeader from "../components/MB/MBSubHeader/MBSubHeader";
import MarketLink from "../pages/MB/MarketLink";
import Notifications from "../pages/MB/Notifications";
import Explore from "../pages/MB/Explore";
import Profiles from '../pages/MB/Profiles'
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Heading from "../components/Heading/Heading";
import { useQuery } from "@tanstack/react-query";
import { query } from "../utils/queryController";

const MicroBlogging = ({ userData }) => {
	const { pathname, state } = useLocation();
	const url = pathname.split("/");
	const isMicro = url[1] === "microblogging";

	const [subModule, setSubModule] = useState(null);

	useEffect(() => {
		switch (state?.module) {
			case "it":
				setSubModule("it");
				break;
			default:
				break;
		}
	}, [state]);

	useEffect(() => {
		switch (subModule) {
			case "IT":
				// fetchProfile("it");
				break;
			default:
				break;
		}
	}, [subModule]);

	const { data, refetch } = useQuery({
		queryKey: ["MBLatestAccount"],
		queryFn: async () => {
			const response = await query("GET", "MB", "getLatestMicrobloggingAccount");
			return response.data?.data;
		},
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	return (
		<div>
			{isMicro && (
				<>
					<Heading
						icon={headerimg}
						title={"Microblogging"}
						moduleHomeRedirect={"/microblogging"}
						user={userData?.userName || userData?.microbloggingUsername || "Guest"}
						redirect={"/microblogging/profiles"}
					/>
					<SubHeader rightmenu leftsearch lefttabs microbloggingId={ data?.microbloggingId } />
				</>
			)}
			<Routes>
				<Route path="microblogging">
					<Route index element={<Home />} />
					<Route path="signup" element={<Signup />} />
					<Route path="profiles" element={<Profiles />} />
					<Route path="feed">
						<Route index element={<Feed />} />
						<Route path="edu" element={<Education />} />
						<Route path="it" element={<Education />} />
						<Route path="reward" element={<RewardFeed />} />
						<Route path="following" element={<Following />} />
						<Route path="monthly" element={<Monthly />} />
						<Route path="marketlink" element={<MarketLink />} />
					</Route>

					<Route path="profile">
						<Route path="public" element={<PublicProfile />} />
						<Route path="private" element={<PrivateProfile />} />
					</Route>

					<Route path="notifications" element={<Notifications />} />
					<Route path="search" element={<Explore />} />
				</Route>
			</Routes>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state.mb?.activeProfile,
	};
};
export default connect(mapStateToProps)(MicroBlogging);
