import {
	Container,
	LeftSection,
	LightTooltip as Tooltip,
	LogoContainer,
	MiddleSection,
	ModuleName,
	MoreContainer,
	NavContainer,
	NavItem,
	Notification,
	NotificationDesc,
	NotificationImg,
	NotificationInfo,
	NotificationModule,
	NotificationsSubWrapper,
	NotificationsTitle,
	NotificationsWrapper,
	NotificationTime,
	NotificationTitle,
	RightSection,
	Search,
	TooltipContent,
	Wrapper,
	Activewrap,
} from "./index.styled";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import searchbar from "../../../assets/images/icons/searchbar-img.png";
import React, { Fragment, useContext, useEffect, useRef, useState } from "react";
import io from "socket.io-client";
import Messaging from "../../../assets/icons/Messages";
import Messages from "../../../assets/icons/Messages";
import { GetUserNotifications } from "../../../utils/apiCalls";
import { UserContext } from "../../../store/UserDetailsStore";
import Home from "../../../assets/icons/Home";
import Chat from "../../../assets/icons/Chat";
import Banking from "../../../assets/icons/banking/Banking";
import home from "../../../assets/icons/homem.png";
import mainicon from "../../../assets/images/icons/main.png";
import Buzzgalactic from "../../../assets/icons/buzzgalactic/logo.svg";
import { Badge, ClickAwayListener, InputAdornment, Popover } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import User from "../../../assets/icons/User.svg";
import Userinactive from "../../../assets/icons/user_grey.png";
import Useractive from "../../../assets/icons/user_white.png";
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import Settings from "../../../assets/icons/Settings";
import { connect } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import { color } from "../../../styles/variables";
import defaultProfile from "../../../assets/icons/user-profile.png";
import HomeActive from "../../../assets/icons/HomeActive";
import MessagesActive from "../../../assets/icons/MessagesActive";
import BankingActive from "../../../assets/icons/BankingActive";
import ChatActive from "../../../assets/icons/ChatActive";
import SidenavMobileComponent from "../../SidenavMobileComponent";
import useLayout from "../../../utils/hooks/useLayout";
import Notifications from "../../Notifications";
import NotificationsBell from "../../../assets/icons/NotificationBell";
const iconsMapping = {
	main: <Home />,
	home: <Home />,
	chatbot: <Chat />,
	messaging: <Messaging />,
};

const ChatEventEnum = Object.freeze({
	CONNECTED_EVENT: "connected",
	DISCONNECT_EVENT: "disconnect",
	JOIN_CHAT_EVENT: "joinChat",
	LEAVE_CHAT_EVENT: "leaveChat",
	UPDATE_GROUP_NAME_EVENT: "updateGroupName",
	MESSAGE_RECEIVED_EVENT: "messageReceived",
	NEW_CHAT_EVENT: "newChat",
	SOCKET_ERROR_EVENT: "socketError",
	STOP_TYPING_EVENT: "stopTyping",
	TYPING_EVENT: "typing",
	ADD_NOTIFICATION_EVENT: "addNotification",
	REMOVE_NOTIFICATION_EVENT: "removeNotification",
});

const Header = ({ page = "main", logout, setHomeHeaderHeight }) => {
	const [showLogoutSuccess, setShowLogoutSuccess] = useState(false);
	const navigate = useNavigate();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const isMobile = useLayout();
	const [notificationsOpen, setNotificationsOpen] = useState(false);
	const [settingsOpen, setSettingsOpen] = useState(false);
	const [isSearch, setIsSearch] = useState(false);
	const [isLoader, setIsLoader] = useState(false);
	const [notification, setNotification] = useState([]);
	const [activePath, setActivePath] = useState(page);
	const [socket, setSocket] = useState(null);
	const { isLoginUserDetails } = useContext(UserContext);
	const [hasNewNotifications, setHasNewNotifications] = useState(false);
	const [hasNewMessage, setHasNewMessage] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [showNotifications, setShowNotifications] = useState(false);
	const headerRef = useRef(null);

	useEffect(() => {
		if (headerRef?.current) setHomeHeaderHeight(headerRef.current?.clientHeight);
	}, [headerRef]);

	const handleClick = (event, target) => {
		switch (target) {
			case "notifications":
				handleNotificationsToggle();
				break;
			case "settings":
				handleSettings();
				break;
			default:
				break;
		}
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setShowNotifications(false);
		setSettingsOpen(false);
		setIsActive(false);
		setAnchorEl(null);
	};

	const { pathname } = useLocation();
	useEffect(() => {
		const newSocket = io("https://api-messaging.buzzgalactic.com", {
			withCredentials: true,
			extraHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});

		newSocket.on("connect", () => {});

		newSocket.on(ChatEventEnum.MESSAGE_RECEIVED_EVENT, (message) => {
			setHasNewMessage(true);
		});

		newSocket.on(ChatEventEnum.ADD_NOTIFICATION_EVENT, (notification) => {
			// setNotification((prevNotifications) => [...prevNotifications, notification]);
			setHasNewNotifications(true);
			getAllNotificationData();
		});

		newSocket.on(ChatEventEnum.REMOVE_NOTIFICATION_EVENT, (notificationToRemove) => {
			setNotification((prevNotifications) =>
				prevNotifications.filter((n) => n.notificationId !== notificationToRemove.notificationId),
			);
		});

		setSocket(newSocket);

		return () => {
			newSocket.off(ChatEventEnum.ADD_NOTIFICATION_EVENT);
			newSocket.off(ChatEventEnum.REMOVE_NOTIFICATION_EVENT);
			newSocket.off(ChatEventEnum.MESSAGE_RECEIVED_EVENT);
			newSocket.disconnect();
		};
	}, []);

	useEffect(() => {
		if (socket && isLoginUserDetails?.userId) {
			socket.emit(ChatEventEnum.JOIN_CHAT_EVENT, isLoginUserDetails?.userId);
		}
	}, [socket, isLoginUserDetails]);

	const handleSettings = () => {
		setSettingsOpen(!settingsOpen);
	};

	const handleHome = () => {
		navigate("/home");
	};

	const handleLogout = () => {
		localStorage.clear();
		sessionStorage.clear();
		logout();
		setShowLogoutSuccess(true);
		setTimeout(() => {
			setShowLogoutSuccess(false);
			navigate("/login");
		}, 2000);
	};

	const checkPath = () => {
		pathname === "/search" ? setIsSearch(true) : setIsSearch(false);
		switch (pathname) {
			case "/chatbot":
				setActivePath("chatbot");
				break;
			case "/home":
				setActivePath("main");
				break;
			case "/publicprofile":
				setActivePath("main");
				break;
			case "/privateprofile":
				setActivePath("main");
				break;
			case "/messaging":
				setActivePath("messaging");
				break;
			case "/banking":
				setActivePath("banking");
				break;
			case "/search":
				setActivePath("search");
				break;
			case "/profiles":
				setActivePath("profiles");
				break;
			default:
				setActivePath(null);
		}
	};

	useEffect(() => {
		checkPath();
	}, []);
	useEffect(() => {
		checkPath();
	}, [pathname]);

	const getAllNotificationData = () => {
		setIsLoader(true);
		GetUserNotifications((res) => {
			const { status, data, message } = res;
			if (status === 200) {
				setNotification(res.data.notifications);
				setIsLoader(false);
			} else {
				setNotification([]);
				setIsLoader(false);
			}
		}, []);
	};

	const handleNotificationsToggle = () => {
		getAllNotificationData();
		setShowNotifications((prev) => !prev);
		setNotificationsOpen(!notificationsOpen);
		setHasNewNotifications(false);
		setIsActive(!isActive);
	};

	const handleNotificationClick = (notification) => {
		if (!notification.isFriend) {
			navigate("/privatefriendrequest");
		} else if (notification.isReview) {
			navigate("/privateprofile");
		}
	};

	return (
		<>
			<Wrapper ref={headerRef}>
				<Container>
					<LeftSection>
						<LogoContainer onClick={handleHome}>
							<img src={Buzzgalactic} alt="logo" />
						</LogoContainer>
						<ModuleName>
							<img src={mainicon} alt="icon" />
							{page || "main"}
						</ModuleName>
					</LeftSection>
					<RightSection>
						{!isMobile && (
							<Fragment>
								<NavContainer>
									<NavItem
										to={"/home"}
										active={!isActive && activePath === "main"}
										activeClassName="active"
										className={!isActive && "show-active"}
									>
										{!isActive && activePath === "main" ? <HomeActive /> : <Home />}
										<p>Home</p>
									</NavItem>
									<NavItem
										to="/messaging"
										onClick={() => {
											setHasNewMessage(false);
										}}
										active={!isActive && activePath === "messaging"}
										activeClassName="active"
										className={!isActive && "show-active"}
									>
										{!isActive && activePath === "messaging" ? <MessagesActive /> : <Messages />}
										<p>Messaging</p>
									</NavItem>
									<NavItem
										to="/banking"
										active={!isActive && activePath === "banking"}
										activeClassName="active"
										className={!isActive && "show-active"}
									>
										{!isActive && activePath === "banking" ? <BankingActive /> : <Banking />}
										<p>Banking</p>
									</NavItem>
									<NavItem
										to="/chatbot"
										active={!isActive && activePath === "chatbot"}
										activeClassName="active"
										className={!isActive && "show-active"}
									>
										{!isActive && activePath === "chatbot" ? <ChatActive /> : <Chat />}
										<p>Chatbot</p>
									</NavItem>
									<NavItem
										to="/profiles"
										active={!isActive && activePath === "profiles"}
										activeClassName="active"
										className={!isActive && "show-active"}
									>
										<img src={!isActive && activePath === "profiles" ? Useractive : Userinactive} />
										<p>Profiles</p>
									</NavItem>
								</NavContainer>
								<MoreContainer>
									<NavItem to={"/search"}>
										<SearchIcon fontSize={"small"} />
									</NavItem>
									<NavItem onClick={(event) => handleClick(event, "notifications")}>
										<Badge color={"error"} variant={"dot"} invisible={!hasNewMessage}>
											<NotificationsBell />
										</Badge>
									</NavItem>
									<Popover
										open={showNotifications}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "right",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
										sx={{
											top: "16px",
										}}
									>
										<NotificationsWrapper>
											<NotificationsTitle>Notifications</NotificationsTitle>
											<NotificationsSubWrapper>
												{notification.length === 0 ? (
													<Fragment>
														{isLoader ? (
															<CircularProgress
																sx={{
																	color: color("pGreen"),
																	marginInline: "auto",
																}}
															/>
														) : (
															<NotificationModule>No new notifications</NotificationModule>
														)}
													</Fragment>
												) : (
													notification?.map((notification, index) => (
														<Notification
															key={index}
															onClick={() => handleNotificationClick(notification)}
														>
															<NotificationModule>{"Main"}</NotificationModule>
															<NotificationInfo>
																<NotificationImg
																	src={
																		notification?.senderDetails?.profilePicture ??
																		defaultProfile
																	}
																	alt="icon"
																/>
																<NotificationDesc>
																	<NotificationTitle>{notification?.content}</NotificationTitle>
																	<NotificationTime>
																		{notification?.createdAt?.split("T")[0]}
																	</NotificationTime>
																</NotificationDesc>
															</NotificationInfo>
														</Notification>
													))
												)}
											</NotificationsSubWrapper>
										</NotificationsWrapper>
									</Popover>
									{/* <Tooltip
										arrow
										placement={"bottom-end"}
										title={
											<TooltipContent onClick={handleLogout}>
												<ExitToAppRoundedIcon /> Logout
											</TooltipContent>
										}
									>
										<NavItem>
											<Settings />
										</NavItem>
									</Tooltip>
									<Popover
										open={settingsOpen}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "right",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
										sx={{
											top: "14px",
										}}
									>
										<TooltipContent onClick={handleLogout}>
											<ExitToAppRoundedIcon /> Logout
										</TooltipContent>
									</Popover>  */}
									<NavItem onClick={(event) => handleClick(event, "settings")}>
										<Settings />
									</NavItem>
									<Popover
										open={settingsOpen}
										anchorEl={anchorEl}
										onClose={handleClose}
										anchorOrigin={{
											vertical: "bottom",
											horizontal: "right",
										}}
										transformOrigin={{
											vertical: "top",
											horizontal: "right",
										}}
										sx={{
											top: "14px",
										}}
									>
										<TooltipContent onClick={handleLogout}>
											<ExitToAppRoundedIcon /> Logout
										</TooltipContent>
									</Popover>
								</MoreContainer>
							</Fragment>
						)}
						{isMobile && (
							<NavContainer>
								<NavItem to={"/search"}>
									<SearchIcon fontSize={"small"} />
								</NavItem>
								<NavItem onClick={handleNotificationsToggle}>
									<Notifications isActive={isActive} notified={notification} hasNew={hasNewNotifications} />
								</NavItem>
								<NavItem onClick={(event) => handleClick(event, "settings")}>
									<Settings />
								</NavItem>
								<Popover
									open={settingsOpen}
									anchorEl={anchorEl}
									onClose={handleClose}
									anchorOrigin={{
										vertical: "bottom",
										horizontal: "right",
									}}
									transformOrigin={{
										vertical: "top",
										horizontal: "right",
									}}
									sx={{
										top: "10px",
									}}
								>
									<TooltipContent onClick={handleLogout}>
										<ExitToAppRoundedIcon /> Logout
									</TooltipContent>
								</Popover>
							</NavContainer>
						)}
					</RightSection>
				</Container>
			</Wrapper>
			{showLogoutSuccess && (
				<>
					<div
						style={{
							position: "fixed",
							top: 0,
							left: 0,
							width: "100%",
							height: "100%",
							backgroundColor: "rgba(255, 255, 255, 0.9)",
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							zIndex: 1000,
						}}
					>
						<div
							style={{
								padding: "20px 40px",
								backgroundColor: "#61BCAC",
								color: "white",
								borderRadius: "5px",
								fontSize: "20px",
							}}
						>
							Logout Successful
						</div>
					</div>
				</>
			)}
			{isMobile && (
				<>
					<div className="header_mobile_bottom">
						<ul>
							<li>
								<NavLink
									to="/home"
									activeClassName="active"
									className={!isActive && activePath === "main" && "show-active active"}
								>
									{!isActive && activePath === "main" ? <HomeActive /> : <Home />}
								</NavLink>
							</li>
							<li className="message">
								<NavLink
									to="/messaging"
									onClick={() => {
										setHasNewMessage(false);
									}}
									activeClassName={"active"}
									className={!isActive && "show-active"}
								>
									{!isActive && activePath === "messaging" ? <MessagesActive /> : <Messages />}
									{hasNewMessage && <span className="message-dot"></span>}
								</NavLink>
							</li>
							<li>
								<NavLink to="/banking" activeClassName="active" className={!isActive && "show-active"}>
									{!isActive && activePath === "banking" ? <BankingActive /> : <Banking />}
								</NavLink>
							</li>
							<li>
								<NavLink to="/chatbot" activeClassName="active" className={!isActive && "show-active"}>
									{!isActive && activePath === "chatbot" ? <ChatActive /> : <Chat />}
								</NavLink>
							</li>
							<li>
								<NavLink to="/profiles" activeClassName="active" className={!isActive && "show-active"}>
									{!isActive && activePath === "profiles" ? (
										<img src={Useractive} />
									) : (
										<img src={Userinactive} />
									)}
								</NavLink>
							</li>
							<li>
								<SidenavMobileComponent />
							</li>
						</ul>
					</div>
				</>
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => {
	return {
		logout: () =>
			dispatch({
				type: "LOGOUT",
			}),
		setHomeHeaderHeight: (height) =>
			dispatch({
				type: "SET_HOME_HEADER_HEIGHT",
				payload: height,
			}),
	};
};

export default connect(undefined, mapPropsToState)(Header);
