import React from "react";

const Rocket = ({ color = "#FFFFFF", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
			<path
				d="M3.88536 13.8105C3.72962 13.6544 3.47685 13.6552 3.32032 13.8097L0.117351 16.9867C-0.0387838 17.1417 -0.0391831 17.3929 0.116553 17.5486C0.194421 17.6265 0.297047 17.6655 0.399273 17.6655C0.5011 17.6655 0.603327 17.6265 0.681195 17.5494L3.88456 14.3728C4.0407 14.2178 4.0411 13.9663 3.88536 13.8105Z"
				fill={color}
			/>
			<path
				d="M8.22101 14.8699C8.06528 14.7137 7.81251 14.7145 7.65637 14.8691L1.18175 21.3006C1.02562 21.4559 1.02522 21.7071 1.18136 21.8625C1.25922 21.9403 1.36145 21.9793 1.46408 21.9793C1.5663 21.9793 1.66813 21.9403 1.7464 21.8633L8.22101 15.4313C8.37675 15.2764 8.37715 15.0248 8.22101 14.8699Z"
				fill={color}
			/>
			<path
				d="M8.7195 19.183L5.51614 22.3592C5.3596 22.5142 5.3592 22.7654 5.51494 22.9211C5.59281 22.999 5.69543 23.038 5.79766 23.038C5.89949 23.038 6.00171 22.999 6.07958 22.9219L9.28295 19.7457C9.43948 19.5907 9.43988 19.3396 9.28415 19.1838C9.12841 19.0281 8.87564 19.0281 8.7195 19.183Z"
				fill={color}
			/>
			<path
				d="M22.5735 0.586368C21.9993 0.0149433 21.1415 -0.156325 20.3856 0.150051L3.70472 6.92966C2.96318 7.22173 2.48479 7.90601 2.45644 8.71546C2.42808 9.52332 2.84498 10.2215 3.57214 10.5835L8.70384 13.12C9.27407 13.3938 9.69176 13.8066 9.98007 14.3804L12.5262 19.4923C12.8644 20.1793 13.5548 20.6061 14.3287 20.6061L14.4042 20.6045C15.2008 20.5771 15.8873 20.0991 16.1975 19.3552L23.0128 2.7608C23.3202 1.99506 23.1521 1.16177 22.5735 0.586368ZM22.2724 2.46317L15.4588 19.0532C15.2679 19.5101 14.8634 19.7935 14.3758 19.8106L14.3287 19.8114C13.8551 19.8114 13.4494 19.5614 13.2425 19.141L10.6953 14.026C10.3243 13.2881 9.7864 12.7576 9.05564 12.4063L3.92914 9.87222C3.48349 9.65009 3.23791 9.2388 3.25508 8.74328C3.27225 8.24696 3.55058 7.84481 4.00301 7.66679L20.6871 0.885989C20.8432 0.822806 21.0038 0.790619 21.1639 0.790619C21.4814 0.790619 21.7733 0.9146 22.0085 1.14826C22.3631 1.50073 22.4609 1.99307 22.2724 2.46317Z"
				fill={color}
			/>
		</svg>
	);
};

export default Rocket;
