import { Fragment } from "react";
import Heading from "../../../components/Heading/Heading";
import Page from "../../../layouts/Page";
import SignUpForm from "../../../components/Magazine/Forms/Signup";
import Dating from "../../../assets/icons/dating/dating";

const Signup = () => {
	return (
		<Fragment>
			<Heading icon={Dating} title={"Dating"} moduleHomeRedirect={"/dating/home"} />
			<Page>
				<SignUpForm />
			</Page>
		</Fragment>
	);
};

export default Signup;
