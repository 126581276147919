import TextArea from "@mui/joy/Textarea";
import styled from "@emotion/styled";
import { useState } from "react";
import { borderRadius, color, spacing, typography } from "../../../styles/variables";
import PropTypes from "prop-types";
import { FormHelperText } from "@mui/material";
import useLayout from "../../../utils/hooks/useLayout";

const Textarea = ({
	name,
	label,
	type = "text",
	required = false,
	errorMsg = undefined,
	gap = spacing("md"),
	rows = 4,
	mobileRows = rows,
	placeholder = undefined,
}) => {
	if (!errorMsg && required) {
		errorMsg = label + " is required.";
	}

	const isMobile = useLayout();

	const [error, setError] = useState(false);
	const [value, setValue] = useState("");

	const handleChange = (event) => {
		const inputValue = event.target.value;
		setValue(inputValue);
		required && setError(inputValue === "");
	};

	return (
		<Wrapper gap={gap}>
			<label>
				<CustomLabel>
					{label}
					{required && "*"}
				</CustomLabel>
				<CustomTextarea
					variant="outlined"
					type={type}
					fullWidth
					name={name}
					error={error}
					value={value}
					required={required}
					helperText={error && errorMsg}
					onChange={handleChange}
					minRows={isMobile ? mobileRows : rows}
					maxRows={isMobile ? mobileRows : rows}
					placeholder={placeholder}
					style={{
						borderRadius: borderRadius(8),
						paddingBlock: spacing("sm2"),
						"&.Mui-focused::before": {
							boxShadow: "none !important",
						},
					}}
					sx={{
						background: "transparent",
						"&::before": {
							boxShadow: "none",
						},
						"&.Mui-error": {
							borderColor: color("error"),
						},
						"&.Mui-focused:not(.Mui-error):focus-within, &:not(.Mui-error):hover": {
							borderColor: color("pBlack"),
						},
					}}
				/>
				{error && errorMsg && (
					<FormHelperText
						sx={{
							margin: 0,
							position: "relative",
							bottom: spacing("sm1"),
							color: color("error"),
						}}
					>
						{errorMsg}
					</FormHelperText>
				)}
			</label>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	margin-block-end: ${spacing("md2")};
	label {
		display: flex;
		flex-direction: column;
		row-gap: ${(props) => props.gap};

		color: ${color("pLabel")};
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;

const CustomLabel = styled.span`
	display: inline-block;

	${typography("body", "md", false)}
`;

const CustomTextarea = styled(TextArea)`
	border-color: ${color("borderDimGrayLight")};
	box-shadow: none;
`;

Textarea.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	type: PropTypes.string,
	required: PropTypes.bool,
	errorMsg: PropTypes.string,
	gap: PropTypes.string,
	placeholder: PropTypes.string,
};

export default Textarea;
