import styled from "@emotion/styled";
import { borderRadius, color, spacing, typography } from "../../../styles/variables";
import { NavLink } from "react-router-dom";
import { Input, Select } from "@mui/material";

const Wrapper = styled.div`
	background-color: ${color("pGreen")};

	display: grid;
	grid-column-gap: ${spacing("xxxl")};
	grid-template-columns: repeat(1, 1fr);

	${(props) =>
		props.isMobile &&
		`
			grid-template-columns: repeat(1, 1fr);
			padding-inline: ${spacing("md")}
  		`};

	@media screen and (min-width: 1440px) {
		& > div {
			max-width: 1440px;
		}
	}
`;
const SubWrapper = styled.div`
	margin-inline: ${spacing("xxxl1")};
	padding-block: ${spacing("md")};
	width: -webkit-fill-available;

	display: grid;
	grid-column-gap: ${spacing("xxxl")};
	grid-template-columns: repeat(8, 1fr);

	${(props) =>
		props.isMobile &&
		`
			grid-template-columns: repeat(1, 1fr);
			padding-inline: ${spacing("md")}
  		`};

	@media screen and (min-width: 1440px) {
		margin-inline: auto;
	}
	@media screen and (max-width: 768px) {
		margin-inline: 0;
		padding-block: 10px;
	}
`;

const LeftNav = styled.div`
	grid-column: span 3;
	display: flex;
	align-items: center;
	column-gap: ${spacing("md1")};
`;

const NavigationLink = styled(NavLink)`
	color: #fff;
	${typography("body", "sm", false)}
	display: inline-flex;
	align-items: center;

	svg {
		width: 20px;
	}
`;
const Searchbar = styled.div`
	grid-column: span 2;
	align-self: center;
	.MuiInputBase-input {
		padding: 0;
	}
`;
const SearchField = styled(Input)`
	background-color: white;
	width: 100%;
	padding-inline: ${spacing("md3")};
	padding-block: ${spacing("sm")};
	border-radius: ${borderRadius(8)};

	.MuiInputBase-inputAdornedStart {
		//border-right: 1px solid ${color("dimGray")};
	}
	.MuiInputAdornment {
		&-positionStart {
			svg {
				color: ${color("dimGray")};
			}
		}
		&-positionEnd {
			width: 25%;

			.MuiInputBase-root {
				width: 100%;
			}
		}
	}
`;

const Dropdown = styled(Select)`
	color: ${color("dimGray")};
`;
const RightNav = styled.div`
	grid-column: span 3;
	display: flex;
	align-items: center;
	column-gap: ${spacing("md2")};
	justify-content: flex-end;
`;

const QuickNaviation = styled.span`
	display: inline-flex;
	width: fit-content;
	align-items: center;
	cursor: pointer;
	column-gap: ${spacing("xs1")};
	background-color: ${color("sGreen")};
	padding-inline: ${spacing("md3")};
	padding-block: ${spacing("sm")};
	border-radius: ${borderRadius(8)};
	${typography("body", "sm", false)}

	color: ${color("pWhite")};

	img {
		width: 21px;
		aspect-ratio: 1;
	}
`;

const MobileWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("lg")};
`;

const MobileTopNavs = styled.div`
	display: flex;
	justify-content: space-between;
`;
const MobileLeftNav = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${spacing("lg1")};
`;
const MobileRightNav = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${spacing("md")};
`;
const MobileQuickNav = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${spacing("xs")};

	> * {
		flex: 1;
		justify-content: center;
		font-weight: 500;
		padding-inline: ${spacing("xs")};
	}
`;

export {
	Wrapper,
	SubWrapper,
	LeftNav,
	NavigationLink,
	Searchbar,
	SearchField,
	Dropdown,
	RightNav,
	QuickNaviation,
	MobileWrapper,
	MobileTopNavs,
	MobileLeftNav,
	MobileRightNav,
	MobileQuickNav,
};
