const Index = ({ size = 18 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 15 17" fill="none">
			<path
				d="M11.8173 3.92415C10.6901 2.14985 8.68532 0.962402 6.39651 0.962402C2.87638 0.962402 0.0126953 3.76245 0.0126953 7.20435C0.0126953 8.5469 0.45257 9.84985 1.25695 10.9103L0.0605078 13.5657C-0.0249922 13.7554 0.00594532 13.976 0.140383 14.1366C0.248383 14.2653 0.409258 14.3379 0.575195 14.3379C0.615695 14.3379 0.656195 14.3335 0.696695 14.3252L3.94963 13.6207C5.41551 15.0628 7.53726 15.7415 9.62976 15.368L14.1669 16.3503C14.2074 16.3591 14.2484 16.363 14.2884 16.363C14.4549 16.363 14.6152 16.2909 14.7232 16.1617C14.8576 16.0011 14.888 15.7811 14.8031 15.5908L13.6066 12.9354C14.4116 11.875 14.8509 10.572 14.8509 9.22945C14.8509 7.0696 13.6888 5.0533 11.8168 3.9236L11.8173 3.92415ZM5.10107 12.244L1.5382 13.0157L2.42357 11.0516C2.50963 10.8602 2.47757 10.638 2.34088 10.4774C1.56576 9.56935 1.13882 8.40665 1.13882 7.20435C1.1377 4.3691 3.49682 2.0624 6.39595 2.0624C9.29507 2.0624 11.6548 4.3691 11.6548 7.20435C11.6548 10.0396 9.29563 12.3458 6.39595 12.3458C6.04382 12.3458 5.69788 12.3117 5.33901 12.2418C5.26307 12.227 5.17701 12.2281 5.10107 12.244ZM12.4417 13.0773L13.3265 15.0413L9.76363 14.2697C9.68713 14.2532 9.60051 14.2521 9.52457 14.2675C8.02382 14.5612 6.52363 14.2042 5.35138 13.3578C5.70182 13.4139 6.04663 13.4458 6.39651 13.4458C9.91663 13.4458 12.7803 10.6457 12.7803 7.20435C12.7803 6.84905 12.7426 6.5031 12.6841 6.16375C13.3473 7.03825 13.727 8.11075 13.727 9.22945C13.727 10.4323 13.3001 11.5945 12.5249 12.5025C12.3877 12.6631 12.3556 12.8853 12.4423 13.0767L12.4417 13.0773Z"
				fill="#5E6B86"
			/>
			<path
				d="M3.5625 7.74404C3.87316 7.74404 4.125 7.4978 4.125 7.19404C4.125 6.89029 3.87316 6.64404 3.5625 6.64404C3.25184 6.64404 3 6.89029 3 7.19404C3 7.4978 3.25184 7.74404 3.5625 7.74404Z"
				fill="#256E61"
			/>
			<path
				d="M6.36133 7.74404C6.67199 7.74404 6.92383 7.4978 6.92383 7.19404C6.92383 6.89029 6.67199 6.64404 6.36133 6.64404C6.05067 6.64404 5.79883 6.89029 5.79883 7.19404C5.79883 7.4978 6.05067 7.74404 6.36133 7.74404Z"
				fill="#256E61"
			/>
			<path
				d="M9.11768 7.74404C9.42834 7.74404 9.68018 7.4978 9.68018 7.19404C9.68018 6.89029 9.42834 6.64404 9.11768 6.64404C8.80702 6.64404 8.55518 6.89029 8.55518 7.19404C8.55518 7.4978 8.80702 7.74404 9.11768 7.74404Z"
				fill="#256E61"
			/>
		</svg>
	);
};

export default Index;
