import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Heading from "../../../components/Heading/Heading";
import Icon from "../../../assets/icons/it/it";
import InitialSignupForm from "../../../components/IT/Forms/Signup/InitialSignupForm";
import { query } from "../../../utils/queryController";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../components/loader";

const SignupMain = ({ handleClick, id }) => {
	const location = useLocation();
	const navigate = useNavigate();
	const { pathname } = location;
	const [isUser, setIsUser] = useState(false);
	const [activeProfile, setActiveProfile] = useState("Thomas Mark");

	const { isLoading } = useQuery({
		queryKey: ["ITProfile"],
		queryFn: async () => {
			const response = await query("GET", "IT", "getITProfile");
			setActiveProfile(response?.data?.firstName);
			return response.data;
		},
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();

		if (path.includes("private") || path.includes("it") || path.includes("public")) setIsUser(true);
		else setIsUser(false);

		if (path.includes("company")) setActiveProfile("Paychex");
		else setActiveProfile("Guest");
	}, [pathname]);

	return (
		<>
			<Heading
				icon={Icon}
				title={"Information Technology"}
				user={isUser ? activeProfile : "Guest"}
				moduleHomeRedirect={"/it/home"}
				handleClick={handleClick}
				id={id}
			/>
			{isLoading ? <Loader /> : <InitialSignupForm />}
		</>
	);
};

export default SignupMain;
