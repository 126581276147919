import { Box } from "@mui/material";
import Post from "./Post/index";

const Feed = () => {
	return (
		<Box display={"flex"} flexDirection={"column"} rowGap={"36px"}>
			<Post image={false} actions />
			<Post image={false} actions />
			<Post image={false} actions />
		</Box>
	);
};

export default Feed;
