import SearchIcon from "@mui/icons-material/Search";
import useLayout from "../../../utils/hooks/useLayout";
import { color } from "../../../styles/variables";
import { MobileTopNavs, MobileWrapper, SubWrapper, Wrapper } from "./index.styled";

import UserWhite from "../../../assets/icons/UserWhite.svg";
import SidenavMobileComponent from "../../SidenavMobileComponent";

const Header = () => {
	const isMobile = useLayout();

	const handleSearch = () => {
		// navigate("/mg/search");
	};

	return (
		<Wrapper>
			<SubWrapper isMobile={isMobile}>
				<MobileWrapper>
					<MobileTopNavs>
						<SearchIcon sx={{ color: color("pWhite"), width: 23 }} onClick={handleSearch} />
						<span>
							<img src={UserWhite} alt="user-white" />
						</span>
						<SidenavMobileComponent module={"mg"} color={"#fff"} />
					</MobileTopNavs>
				</MobileWrapper>
			</SubWrapper>
		</Wrapper>
	);
};

export default Header;
