import { Box } from "@mui/material";
import { Info, Title, Description } from "./index.styled";
import Comment from "../../../../assets/icons/Comment";
import Reshare from "../../../../assets/icons/Reshare";
import Like from "../../../../assets/icons/Like";
import Statistics from "@mui/icons-material/BarChart";
import Bookmark from "@mui/icons-material/BookmarkBorder";
import Share from "../../../../assets/icons/Share2";
import Post from "../Post";
import { Fragment } from "react";

const Offers = ({ noPaper = false }) => {
	if (noPaper) {
		return (
			<Fragment>
				<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
					<Box display={"flex"} flexDirection={"column"} rowGap={"10px"}>
						<Title>Offer 15</Title>
						<Info>
							Location:
							<span> Georgia</span>
						</Info>
						<Info>
							Purpose:
							<span> News Creation</span>
						</Info>
						<Info>Description:</Info>
					</Box>
					<Info>
						Amount:
						<span> $200</span>
					</Info>
				</Box>
				<Description>
					A car incident has just occurred, and your perspective is invaluable. If you're nearby, grab your phone and
					capture what's happening. Your footage and observations can provide crucial insights into the event.
				</Description>
				<Post />
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					mt={"24px"}
					sx={{
						"& > *": {
							cursor: "pointer",
						},
					}}
				>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Comment /> 300
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Reshare /> 2.6k
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Like /> 8k
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Statistics
							sx={{
								color: "#929292",
							}}
						/>
						800
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Bookmark
							sx={{
								color: "#929292",
							}}
						/>
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Share />
					</Box>
				</Box>
			</Fragment>
		);
	}
	return (
		<Box
			borderRadius={"8px"}
			p={"10px"}
			sx={{
				backgroundColor: "#fff",
				boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.25)",
			}}
		>
			<Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
				<Box display={"flex"} flexDirection={"column"} rowGap={"10px"}>
					<Title>Offer 15</Title>
					<Info>
						Location:
						<span> Georgia</span>
					</Info>
					<Info>
						Purpose:
						<span> News Creation</span>
					</Info>
					<Info>Description:</Info>
				</Box>
				<Info>
					Amount:
					<span> $200</span>
				</Info>
			</Box>
			<Description>
				A car incident has just occurred, and your perspective is invaluable. If you're nearby, grab your phone and
				capture what's happening. Your footage and observations can provide crucial insights into the event.
			</Description>
			<Post />
			<Box
				display={"flex"}
				justifyContent={"space-between"}
				alignItems={"center"}
				mt={"24px"}
				sx={{
					"& > *": {
						cursor: "pointer",
					},
				}}
			>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Comment /> 300
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Reshare /> 2.6k
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Like /> 8k
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Statistics
						sx={{
							color: "#929292",
						}}
					/>
					800
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Bookmark
						sx={{
							color: "#929292",
						}}
					/>
				</Box>
				<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
					<Share />
				</Box>
			</Box>
		</Box>
	);
};

export default Offers;
