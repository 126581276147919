// Spacing
const toRem = (value, baseFontSize = 16) => {
	return `${value / baseFontSize}rem`;
};

const primaryFontFamily = "Poppins";
const secondaryFontFamily = "Inter";

const spacers = {
	xxs: toRem(2),
	xs: toRem(4),
	xs1: toRem(6),
	sm: toRem(8),
	sm1: toRem(10),
	sm2: toRem(12),
	md: toRem(14),
	md1: toRem(16),
	md2: toRem(18),
	md3: toRem(20),
	md4: toRem(22),
	lg: toRem(24),
	lg1: toRem(28),
	xl: toRem(32),
	xl1: toRem(36),
	xl2: toRem(44),
	xxl: toRem(48),
	xxl1: toRem(54),
	xxxl: toRem(64),
	xxxl1: toRem(74),
};

const border_Radius = {
	4: toRem(4),
	6: toRem(6),
	8: toRem(8),
	12: toRem(12),
	18: toRem(18),
	24: toRem(24),
	32: toRem(32),
	40: toRem(40),
};

export const typography = (format, fontStyle, isPrimary = true) => {
	const BodyVSM = `
		font-family: ${isPrimary ? primaryFontFamily : secondaryFontFamily};
		font-size: 10px;
		font-weight: normal;
		line-height: normal;
	`;
	const BodyXSM = `
		font-family: ${isPrimary ? primaryFontFamily : secondaryFontFamily};
		font-size: 12px;
		font-weight: normal;
		line-height: normal;
	`;
	const BodySM = `
		font-family: ${isPrimary ? primaryFontFamily : secondaryFontFamily};
		font-size: 14px;
		font-weight: normal;
		line-height: normal;
	`;
	const BodyMD = `
		font-family: ${isPrimary ? primaryFontFamily : secondaryFontFamily};
		font-size: 16px;
		font-weight: normal;
		line-height: normal;
	`;
	const BodyLG = `
		font-family: ${isPrimary ? primaryFontFamily : secondaryFontFamily};
		font-size: 18px;
		font-weight: normal;
		line-height: normal;
	`;
	const HeadingLG = `
		font-family: ${isPrimary ? primaryFontFamily : secondaryFontFamily};
		font-size: 24px;
		font-weight: 500;
		line-height: normal;
	`;

	switch (format.toLowerCase()) {
		case "body":
			return eval(`Body${fontStyle.toUpperCase()}`);
		default:
			return eval(`Heading${fontStyle.toUpperCase()}`);
	}
};

// Colors

const colors = {
	pWhite: "#ffffff",
	pBlack: "#000000",
	pGreen: "#61BCAC",
	freshGreen: "#3abf81",
	pGreenFaded: "#E4F6F3",

	sWhite: "#F5F5F5",
	sBlack: "#2B2A2A",
	sBlackLight: "#ebebeb",
	sBlackFaded: "#30302F",
	sBlackFadedLight: "#828282",
	sGreen: "#256F61",

	pinkFaded: "#dcbcbc",

	darkGray: "#524F4F",
	gray: "#767575",
	dimGray: "#6b6b6b",
	grayish: "#a7a7a7",
	grayishFaded: "#CECECE",
	lightGray: "#848484",
	lightGrayFaded: "#1c1b1b",
	grey: "#797979",
	dimGrey: "#f1f1f1",
	lightGrey: "#3d3d3d",
	lightGreyFaded: "#6d6d6d",
	strokedGray: "#585858",
	strokedGrayLight: "#6b6666",
	dimGrey: "#D9D9D9",
	dimGray: "#989898",
	dimGrayLight: "#D9D9D9",
	dimGrayExtraLight: "#767070",

	borderGray: "#d0cfcf",
	borderDimGray: "#D6CECE",
	borderDimGrayLight: "#CDCDCD",

	pLabel: "#4D4D4D",
	error: "#d32f2f",
};

const spacing = (key) => spacers[key] || "0";
const color = (color) => colors[color] || color;
const borderRadius = (radius) => border_Radius[radius] || toRem(radius);

export { spacing, color, borderRadius, toRem };
