import React from "react";
import "./Heading.scss";
import { Avatar, InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import avatar from "../../assets/images/Educationprofile.png";
import message from "../../assets/icons/message-short.png";
import comment from "../../assets/icons/comment-short.png";
import { NavLink, useNavigate } from "react-router-dom";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import styled from "@emotion/styled";
import Buzzgalactic from "../../assets/icons/buzzgalactic/logo.svg";
import useLayout from "../../utils/hooks/useLayout";
import { spacing } from "../../styles/variables";

const LogoContainer = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-block: 12px;
	border-right: 1px solid #cbc3c3;

	& > img {
		width: 132px;
		padding-right: 12px;
	}

	@media screen and (max-width: 768px) {
		border: none;

		& > img {
			padding-right: 0;
			width: 132px;
		}
	}
`;

const Heading = ({
	icon,
	title,
	showSearchInput,
	showUpload,
	user = "Guest",
	redirect = null,
	moduleHomeRedirect,
	handleClick,
	id,
}) => {
	const navigate = useNavigate();
	const isMobile = useLayout();

	const handleHome = () => {
		navigate("/home");
	};
	const handleModuleRedirection = () => {
		if (moduleHomeRedirect) navigate(moduleHomeRedirect);
	};
	let Icon = null;
	if (typeof icon === "function") {
		Icon = icon;
	}

	return (
		<>
			<Wrapper>
				<SubWrapper>
					<div className="heading">
						<div className="heading_container">
							<div className="heading_sections">
								<div className="heading_left">
									<LogoContainer onClick={handleHome}>
										<img src={Buzzgalactic} alt="logo" />
									</LogoContainer>
									{icon && (
										<div className="heading_left_content" onClick={handleModuleRedirection}>
											{typeof icon === "function" ? (
												<Icon />
											) : (
												<img src={icon} alt="logo" className="image" />
											)}
											<h3
												style={{
													textTransform: "capitalize",
												}}
											>
												{title}
											</h3>
										</div>
									)}
									{showSearchInput && (
										<div className="heading_right">
											<TextField
												className="input"
												variant="standard"
												sx={{ width: "95%", background: "#fff", borderRadius: "1vw" }}
												InputProps={{
													startAdornment: (
														<InputAdornment position="end">
															<SearchIcon sx={{ width: "1vw" }} />
														</InputAdornment>
													),
												}}
											/>
										</div>
									)}
									{isMobile && (
										<div className="heading_logo" onClick={handleModuleRedirection}>
											<div className="heading_logo_content">
												{typeof icon === "function" ? (
													<Icon />
												) : (
													<img src={icon} alt="logo" className="image" />
												)}
												<h3>{title}</h3>
											</div>
										</div>
									)}
									{user && !isMobile && (
										<div className="heading_mobile">
											<ul>
												<li>
													<NavLink to={redirect || "/"}>
														<PersonOutlineIcon />
														<p>{user}</p>
													</NavLink>
												</li>
											</ul>
										</div>
									)}

									{showUpload && (
										<div className="heading_middle">
											<div className="upload">+ Upload</div>
											<div className="heading_middle_content">
												<img src={message} alt="msg" />
												<img src={comment} alt="comment" />
												<Avatar alt="Remy Sharp" src={avatar} />
											</div>
										</div>
									)}

									<div className="heading_user">
										<ul>
											<li>
												<NavLink>
													<NotificationsNoneIcon />
												</NavLink>
											</li>

											<li className="flex">
												<NavLink
													style={{
														position: "relative",
													}}
													to={redirect || null}
													onClick={handleClick}
												>
													<PersonOutlineIcon aria-describedby={id} variant="contained" />
													<p aria-describedby={id} variant="contained">
														{user || "Guest"}
													</p>
												</NavLink>
											</li>
										</ul>
									</div>
								</div>
							</div>
							{!isMobile && (
								<div className="heading_logo">
									<div className="heading_logo_content">
										{typeof icon === "function" ? <Icon /> : <img src={icon} alt="logo" className="image" />}
										<h3>{title}</h3>
									</div>
								</div>
							)}
						</div>
					</div>
				</SubWrapper>
			</Wrapper>
		</>
	);
};

const Wrapper = styled.div`
	background-color: #f5f5f5;

	display: grid;
	grid-column-gap: ${spacing("xxxl")};
	grid-template-columns: repeat(1, 1fr);

	@media screen and (min-width: 1440px) {
		& > div {
			max-width: 1440px;
			margin-inline: auto;
		}
	}
`;
const SubWrapper = styled.div`
	margin-inline: ${spacing("xxxl1")};
	width: -webkit-fill-available;

	@media screen and (min-width: 1440px) {
		margin-inline: auto;
	}

	@media screen and (max-width: 768px) {
		margin-inline: 0;
	}
`;

export default Heading;
