import React from "react";
import "./EngineeringHome.scss";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { InputAdornment, TextField } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import egicon1 from "../../assets/images/icons/egicon1.svg";
import egicon2 from "../../assets/images/icons/egicon2.svg";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EastIcon from "@mui/icons-material/East";
import avator1 from "../../assets/images/profile/follow/3.png";
import avator2 from "../../assets/images/profile/follow/2.png";
import avator3 from "../../assets/images/profile/follow/1.png";
import avator4 from "../../assets/images/profile/follow/4.png";
import egicon4 from "../../assets/images/icons/egicon4.svg";
import Heading from "../../components/Heading/Heading";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";

const EngineeringHome = () => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const handleNav = () => {
		navigate("/engineering-freelance-profile");
	};
	return (
		<>
			{token ? (
				<div className="engineerhome">
					<Heading icon={egicon4} title={"Engeering"} />
					<div className="engineerhome_header">
						<div className="engineerhome_header_main">
							<div className="engineerhome_header_main_left">
								<ul>
									<li>
										<NavLink>
											<span>Jobs</span>
											<ExpandMoreIcon />
										</NavLink>
									</li>
									<li>
										<NavLink to="/">
											<span>Talent</span>
											<ExpandMoreIcon />
										</NavLink>
									</li>
									<li>
										<NavLink to="/">
											<span>Reports</span>
											<ExpandMoreIcon />
										</NavLink>
									</li>
									<li>
										<NavLink to="/">
											<span>Messages</span>
										</NavLink>
									</li>
								</ul>
								<TextField
									className="input"
									variant="standard"
									sx={{ width: "95%", background: "#fff", borderRadius: "1vw" }}
									InputProps={{
										startAdornment: (
											<InputAdornment
												sx={{
													display: "flex",
													justifyContent: "space-between",
												}}
											>
												<SearchIcon sx={{ width: "1.4vw", marginLeft: "0.8vw" }} />
												<span style={{ borderLeft: "1px solid black" }}>Talent</span>
												<ExpandMoreIcon />
											</InputAdornment>
										),
									}}
								/>
							</div>
							<div className="engineerhome_header_main_center">
								<ul>
									<li>
										<NavLink>
											<img src={egicon1} alt="" />
											<span>Micro Blogging</span>
										</NavLink>
									</li>
									<li>
										<NavLink to="/">
											<img src={egicon2} alt="" />
											<span> Short Videos</span>
										</NavLink>
									</li>
								</ul>
							</div>
							<div className="engineerhome_header_main_right">
								<div className="engineerhome_header_main_right_rightmob">
									<MenuIcon sx={{ color: "white", fontSize: 40 }} />
									<SearchIcon sx={{ color: "white", fontSize: 25 }} />
								</div>
								<ul>
									<li>
										<NavLink>
											<NotificationsIcon />
										</NavLink>
									</li>
									<li>
										<NavLink to="/">
											<FavoriteBorderIcon />
										</NavLink>
									</li>
									<li>
										<NavLink to="/">
											<PersonOutlineIcon />
										</NavLink>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="engineerhome_mini">
						<div className="engineerhome_heading">
							<div className="engineerhome_heading_main">
								<div className="engineerhome_heading_main_left">
									<p>Discover top rated talent</p>
									<div className="engineerhome_heading_main_left_one">
										<span>Browse Talent</span>
										<EastIcon />
									</div>
								</div>
								<div className="engineerhome_heading_main_right">
									<button>Post a Job +</button>
								</div>
							</div>
						</div>
					</div>
					<div className="engineerhome_mini1">
						<div className="engineerhome_cards">
							<div className="engineerhome_cards_main">
								<div className="engineerhome_cards_main_first">
									<div className="firstone">
										<img src={avator1} alt="" onClick={handleNav} />
										<div>
											<h2>Peter Smith</h2>
											<p>United States</p>
										</div>
									</div>
									<FavoriteBorderIcon />
								</div>
								<div className="engineerhome_cards_main_second">
									<h2>Full Stack Developer </h2>
									<div className="doller">
										<span>$</span>
										<p>$8/hr</p>
									</div>
								</div>
								<div className="engineerhome_cards_main_third">
									<button>laravel Framework</button>
									<button>Codegnitor</button>
									<button>javascript</button>
									<button>Javascript</button>
								</div>
								<div className="engineerhome_cards_main_four">
									<button onClick={handleNav}>
										<span>View Profile</span>
									</button>
								</div>
							</div>
							<div className="engineerhome_cards_main">
								<div className="engineerhome_cards_main_first">
									<div className="firstone">
										<img src={avator2} alt="" onClick={handleNav} />
										<div>
											<h2>Jason</h2>
											<p>United States</p>
										</div>
									</div>
									<FavoriteBorderIcon />
								</div>
								<div className="engineerhome_cards_main_second">
									<h2>Website Developer </h2>
									<div className="doller">
										<span>$</span>
										<p>$15/hr</p>
									</div>
								</div>
								<div className="engineerhome_cards_main_third">
									<button>laravel Framework</button>
									<button>Codegnitor</button>
									<button>javascript</button>
									<button>Javascript</button>
								</div>
								<div className="engineerhome_cards_main_four">
									<button onClick={handleNav}>
										<span>View Profile</span>
									</button>
								</div>
							</div>
							<div className="engineerhome_cards_main">
								<div className="engineerhome_cards_main_first">
									<div className="firstone">
										<img src={avator3} alt="" onClick={handleNav} />
										<div>
											<h2>Parth</h2>
											<p>United States</p>
										</div>
									</div>
									<FavoriteBorderIcon />
								</div>
								<div className="engineerhome_cards_main_second">
									<h2>Phython Developer </h2>
									<div className="doller">
										<span>$</span>
										<p>$18/hr</p>
									</div>
								</div>
								<div className="engineerhome_cards_main_third">
									<button>laravel Framework</button>
									<button>Codegnitor</button>
									<button>javascript</button>
									<button>Javascript</button>
								</div>
								<div className="engineerhome_cards_main_four">
									<button onClick={handleNav}>
										<span>View Profile</span>
									</button>
								</div>
							</div>
							<div className="engineerhome_cards_main">
								<div className="engineerhome_cards_main_first">
									<div className="firstone">
										<img src={avator4} alt="" onClick={handleNav} />
										<div>
											<h2>Thomas</h2>
											<p>United States</p>
										</div>
									</div>
									<FavoriteBorderIcon />
								</div>
								<div className="engineerhome_cards_main_second">
									<h2>Java Developer </h2>
									<div className="doller">
										<span>$</span>
										<p>$25/hr</p>
									</div>
								</div>
								<div className="engineerhome_cards_main_third">
									<button>laravel Framework</button>
									<button>Codegnitor</button>
									<button>javascript</button>
									<button>Javascript</button>
								</div>
								<div className="engineerhome_cards_main_four">
									<button onClick={handleNav}>
										<span>View Profile</span>
									</button>
								</div>
							</div>
						</div>
						<div className="engineerhome_cards">
							<div className="engineerhome_cards_main">
								<div className="engineerhome_cards_main_first">
									<div className="firstone">
										<img src={avator1} alt="" onClick={handleNav} />
										<div>
											<h2>Peter Smith</h2>
											<p>United States</p>
										</div>
									</div>
									<FavoriteBorderIcon />
								</div>
								<div className="engineerhome_cards_main_second">
									<h2>Full Stack Developer </h2>
									<div className="doller">
										<span>$</span>
										<p>$8/hr</p>
									</div>
								</div>
								<div className="engineerhome_cards_main_third">
									<button>laravel Framework</button>
									<button>Codegnitor</button>
									<button>javascript</button>
									<button>Javascript</button>
								</div>
								<div className="engineerhome_cards_main_four">
									<button onClick={handleNav}>
										<span>View Profile</span>
									</button>
								</div>
							</div>
							<div className="engineerhome_cards_main">
								<div className="engineerhome_cards_main_first">
									<div className="firstone">
										<img src={avator2} alt="" onClick={handleNav} />
										<div>
											<h2>Jason</h2>
											<p>United States</p>
										</div>
									</div>
									<FavoriteBorderIcon />
								</div>
								<div className="engineerhome_cards_main_second">
									<h2>Website Developer </h2>
									<div className="doller">
										<span>$</span>
										<p>$15/hr</p>
									</div>
								</div>
								<div className="engineerhome_cards_main_third">
									<button>laravel Framework</button>
									<button>Codegnitor</button>
									<button>javascript</button>
									<button>Javascript</button>
								</div>
								<div className="engineerhome_cards_main_four">
									<button onClick={handleNav}>
										<span>View Profile</span>
									</button>
								</div>
							</div>
							<div className="engineerhome_cards_main">
								<div className="engineerhome_cards_main_first">
									<div className="firstone">
										<img src={avator3} alt="" onClick={handleNav} />
										<div>
											<h2>Parth</h2>
											<p>United States</p>
										</div>
									</div>
									<FavoriteBorderIcon />
								</div>
								<div className="engineerhome_cards_main_second">
									<h2>Phython Developer </h2>
									<div className="doller">
										<span>$</span>
										<p>$18/hr</p>
									</div>
								</div>
								<div className="engineerhome_cards_main_third">
									<button>laravel Framework</button>
									<button>Codegnitor</button>
									<button>javascript</button>
									<button>Javascript</button>
								</div>
								<div className="engineerhome_cards_main_four">
									<button onClick={handleNav}>
										<span>View Profile</span>
									</button>
								</div>
							</div>
							<div className="engineerhome_cards_main">
								<div className="engineerhome_cards_main_first">
									<div className="firstone">
										<img src={avator4} alt="" onClick={handleNav} />
										<div>
											<h2>Thomas</h2>
											<p>United States</p>
										</div>
									</div>
									<FavoriteBorderIcon />
								</div>
								<div className="engineerhome_cards_main_second">
									<h2>Java Developer </h2>
									<div className="doller">
										<span>$</span>
										<p>$25/hr</p>
									</div>
								</div>
								<div className="engineerhome_cards_main_third">
									<button>laravel Framework</button>
									<button>Codegnitor</button>
									<button>javascript</button>
									<button>Javascript</button>
								</div>
								<div className="engineerhome_cards_main_four">
									<button onClick={handleNav}>
										<span>View Profile</span>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default EngineeringHome;
