import styled from "@emotion/styled";
import Header from "../../components/Header";
import SignUpForm from "../../components/Signup/Form";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserSignUp } from "../../utils/apiCalls";

const SignUp = () => {
	const navigate = useNavigate();
	const [isShowLoader, setIsShowLoader] = useState(false);
	const onSignUp = (values) => {
		setIsShowLoader(true);
		const payload = {
			firstName: values?.firstName,
			lastName: values?.lastName,
			email: values?.email,
			password: values?.password,
		};

		if (payload) {
			UserSignUp((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					navigate("/verify");
				} else {
					navigate("/signup");
				}
			}, payload);
		}
		setIsShowLoader(false);
	};
	return (
		<Wrapper>
			<Header hideButton />
			<FormWrapper>
				<SignUpForm onSubmit={onSignUp} showLoader={isShowLoader} />
			</FormWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	min-height: 100dvh;
	background: #f8fdfb;
	display: flex;
	flex-direction: column;
`;

const FormWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	margin: 40px;
	flex: 1;
	position: relative;
	align-items: center;
	margin-inline: auto;

	@media screen and (max-width: 768px) {
		display: flex;
		margin: 0;
	}

	@media screen and (min-width: 1440px) {
		max-width: 1440px;
	}
`;

export default SignUp;
