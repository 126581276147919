import { Search } from "@mui/icons-material";
import { Box, Input } from "@mui/material";
import profile1 from "../../../assets/images/profile/profile.png";
import profile2 from "../../../assets/images/profile/follow/1.png";
import profile3 from "../../../assets/images/profile/follow/2.png";
import profile4 from "../../../assets/images/profile/follow/3.png";
import profile5 from "../../../assets/images/profile/follow/4.png";
import { ContactName, Events, Profile } from "./index.styled";

const contactList = [
	{
		id: 1,
		name: "George",
		profilePicture: profile1,
	},
	{
		id: 2,
		name: "Lisa",
		profilePicture: profile2,
	},
	{
		id: 3,
		name: "Michael",
		profilePicture: profile3,
	},
	{
		id: 4,
		name: "Peter",
		profilePicture: profile4,
	},
	{
		id: 5,
		name: "Elle",
		profilePicture: profile5,
	},
];

const Contacts = () => {
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			rowGap={"24px"}
			mt={"24px"}
			gridColumn={"10 / span 3"}
			position={"sticky"}
			top={"24px"}
		>
			<Input
				placeholder="Contacts"
				sx={{
					"& .MuiInputBase-input": {
						pt: 0,
						"&::placeholder": {
							color: "#797979",
						},
					},
					borderBottom: `1px solid #D9D9D9`,
					color: "#797979",
				}}
				endAdornment={
					<Search
						sx={{
							color: "#797979",
						}}
					/>
				}
			/>
			<Box display={"flex"} flexDirection={"column"} rowGap={"16px"}>
				{contactList.map((contact) => (
					<Box key={contact.id} display={"flex"} alignItems={"center"} columnGap={"16px"}>
						<Profile src={contact.profilePicture} alt="profile" />
						<ContactName>{contact.name}</ContactName>
					</Box>
				))}
			</Box>
			<Events>Events</Events>
		</Box>
	);
};

export default Contacts;
