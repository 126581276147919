const initialUserState = {
	// initial user state
	profile: { data: null, isLoading: false, error: null },
};

const userReducer = (state = initialUserState, action) => {
	// reducer logic based on different action types
	switch (action.type) {
		case "USER_DATA_GET":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: true,
				},
			};
			break;
		case "USER_DATA_SUCCESS":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					data: action?.payload,
				},
			};
			break;
		case "USER_DATA_ERROR":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					error: action?.payload,
				},
			};
			break;
	}
	return state;
};

export default userReducer;
