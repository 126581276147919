import { borderRadius, color } from "../../../styles/variables";
import { LoadingButton as Button } from "@mui/lab";

const PrimaryBtn = ({
	type = "submit",
	label,
	onClick = undefined,
	overrides,
	loading = false,
	fullWidth = false,
	noHoverEffect = false,
	capitalize = false,
	disable = false,
}) => {
	return (
		<Button
			type={type}
			disabled={disable}
			onClick={onClick}
			variant="contained"
			loading={loading}
			sx={{
				backgroundColor: color("pGreen"),
				color: color("pWhite"),
				boxShadow: "none",
				border: "1px solid transparent",
				textTransform: capitalize ? "capitalize" : "none",
				borderRadius: borderRadius(8),
				"&:hover": {
					backgroundColor: color("pGreen"),
					color: color("pWhite"),
					boxShadow: "none",
				},
				...(!noHoverEffect && {
					"&:hover": {
						backgroundColor: color("pWhite"),
						borderColor: color("pGreen"),
						color: color("pGreen"),
						boxShadow: "none",
					},
				}),
				...overrides,
			}}
			fullWidth={fullWidth}
		>
			{label}
		</Button>
	);
};

export default PrimaryBtn;
