import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../../../components/Education/Forms/Signup";
import EmployeeSignUpForm from "../../../../components/Education/Forms/Signup/Employee";

const EmployeeSignUp = () => {
	const [profilePicture, setProfilePicture] = useState(null);
	const navigate = useNavigate();
	const handleFormSubmit = (values) => {
		navigate("/education/microblogging", {
			state: {
				allData: { ...values },
				picture: profilePicture,
				url: "createEducationEmployee",
				redirect: "/education/employee/profile/private",
			},
		});
	};
	return (
		<>
			<SignUp userType="Regular Employee" onSubmit={handleFormSubmit}>
				<EmployeeSignUpForm  setProfilePicture={setProfilePicture} onSubmit={handleFormSubmit}/>
			</SignUp>
		</>
	);
};

export default EmployeeSignUp;
