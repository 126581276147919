import LeftSubNav from "../../../../components/LeftSubNav";
import RightSubNav from "../../../../components/RightSubNav";
import FeedList from "../../../../components/Shorts/Feed";
import { MobileWrapper, Wrapper, LeftSection, CenterContent, RightSection } from "../../index.styled";
import Profile1 from "../../../../assets/images/Educationprofile2.png";
import Profile2 from "../../../../assets/images/Educationprofile.png";
import Preview1 from "../../../../assets/images/shorts/Preview1.png";
import Preview2 from "../../../../assets/images/shorts/Preview2.png";
import Smiley from "../../../../assets/icons/shorts/Smile.svg";
import Chat from "../../../../assets/icons/shorts/Chat.svg";

const feedData = [
	{
		id: 1,
		name: "George Kajaia",
		profile: Profile1,
		preview: Preview1,
		analytics: [
			{
				name: "reaction",
				stat: 2456,
				icon: Smiley,
			},
			{
				name: "comments",
				stat: 456,
				icon: Chat,
			},
		],
	},
	{
		id: 2,
		name: "Michelle",
		profile: Profile2,
		preview: Preview2,
		analytics: [
			{
				name: "reaction",
				stat: 12326,
				icon: Smiley,
			},
			{
				name: "comments",
				stat: 8745,
				icon: Chat,
			},
		],
	},
];

const RewardsFeed = () => {
	return (
		<Wrapper>
			<LeftSection>
				<LeftSubNav />
			</LeftSection>
			<CenterContent>
				<FeedList feed={feedData} />
			</CenterContent>
			<RightSection>
				<RightSubNav />
			</RightSection>
		</Wrapper>
	);
};

export default RewardsFeed;
