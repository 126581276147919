import { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../../../components/Environment/Forms/Signup";
import SignUpForm from "../../../../components/Environment/Forms/Signup/Freelancer";
import Skills from "../../../../components/Environment/Forms/Signup/Freelancer/Skills.jsx";

const FreelancerSignUp = () => {
	const navigate = useNavigate();
	const [signUpStage, setSignUpStage] = useState(1);
	const [formValues, setFormValues] = useState({});
	const handleSubmit = (values) => {
		setFormValues({ ...formValues, ...values });
		setSignUpStage(2);

		if (signUpStage === 2) {
			navigate("/environment/microblogging");
		}
	};
	return (
		<SignUp userType="Freelancer" onSubmit={handleSubmit} onBack={signUpStage === 2 ? () => setSignUpStage(1) : undefined}>
			{signUpStage === 1 ? <SignUpForm /> : <Skills />}
		</SignUp>
	);
};

export default FreelancerSignUp;
