import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Heading from "../../../components/Heading/Heading";
import Icon from "../../../assets/icons/agriculture/agriculture";
import InitialSignupForm from "../../../components/Agriculture/Forms/Signup/InitialSignupForm";

const SignupMain = () => {
	const location = useLocation();
	const { pathname } = location;
	const [isUser, setIsUser] = useState(false);
	const [activeProfile, setActiveProfile] = useState("Thomas Mark");

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();

		if (path.includes("private") || path.includes("public")) setIsUser(true);
		else setIsUser(false);

		if (path.includes("company")) setActiveProfile("Paychex");
		else setActiveProfile("Thomas Mark");
	}, [pathname]);
	return (
		<>
			<Heading icon={Icon} title={"Agriculture"} user={isUser ? activeProfile : "Guest"} />
			<InitialSignupForm />
		</>
	);
};

export default SignupMain;
