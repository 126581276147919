import React from "react";
import Home from "../../../../assets/images/MB/home.png";
import Explore from "../../../../assets/images/MB/explore.png";
import Notifications from "../../../../assets/images/MB/notifications.png";
import Messages from "../../../../assets/images/MB/messages.png";
import { NavLink, Navigate } from "react-router-dom";
import mbpic1 from "../../../../assets/icons/public/blog1.png";

import MBPost from "../../../../components/MB/Post";
import Search from "../../../../assets/images/MB/search.png";
import {
	EduWrapperLeft,
	EduWrapperCenter,
	EduWrapperRight,
	Wrapper,
	MobileBottom,
	MobileBottomIcons,
} from "../../styles/index.styled";
import RightSubNav from "../../../../components/RightSubNav";
import LeftSubNav from "../../../../components/LeftSubNav";
import MBPostStatus from "../../../../components/MB/PostStatus";
import MBMobileBottom from "../../../../components/MB/MBMobileBottom/MBMobileBottom";
import Page from "../../../../layouts/Page";

const NFEducation = () => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");

	return (
		<>
			{token ? (
				<>
					<Page>
						<EduWrapperLeft>
							<LeftSubNav />
						</EduWrapperLeft>
						<EduWrapperCenter>
							<MBPostStatus />
							<MBPost
								heading={"Michelle"}
								name="Education"
								content="Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting gas the Jews"
								image={mbpic1}
							/>
							<MBPost
								heading={"Michelle"}
								name="Education"
								content="Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting gas the Jews.Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hama"
							/>
						</EduWrapperCenter>
						<EduWrapperRight>
							<RightSubNav />
						</EduWrapperRight>
					</Page>
					<MBMobileBottom />
				</>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default NFEducation;
