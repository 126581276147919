import React from "react";

const UsedProducts = ({ color = "#0099FF", size = 20 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 22 20" fill="none">
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M15.4803 11.4374H13.0641C12.9026 11.4374 12.7715 11.5634 12.7715 11.7187C12.7715 11.874 12.9026 12 13.0641 12H15.4803C15.6418 12 15.7728 11.874 15.7728 11.7187C15.7728 11.5634 15.6418 11.4374 15.4803 11.4374ZM17.4146 5.48005V9.58652C17.4146 9.7418 17.5456 9.86781 17.7071 9.86781C17.8686 9.86781 17.9996 9.7418 17.9996 9.58652V5.48005C17.9996 5.32478 17.8686 5.19876 17.7071 5.19876C17.5456 5.19876 17.4146 5.32478 17.4146 5.48005ZM9.22473 7.87852V5.36472C9.22473 5.20945 9.09367 5.08343 8.93219 5.08343C8.77071 5.08343 8.63965 5.20945 8.63965 5.36472V7.87852C8.63965 8.0338 8.77071 8.15981 8.93219 8.15981C9.09367 8.15981 9.22473 8.0338 9.22473 7.87852ZM11.1699 3.56258H15.4803C15.6418 3.56258 15.7728 3.43656 15.7728 3.28129C15.7728 3.12602 15.6418 3 15.4803 3H11.1699C11.0085 3 10.8774 3.12602 10.8774 3.28129C10.8774 3.43656 11.0085 3.56258 11.1699 3.56258Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M21.1199 0.900278C21.1199 0.661578 21.0214 0.432593 20.8457 0.26368C20.67 0.0947661 20.4319 0 20.1836 0C19.3088 0 17.7313 0 16.8564 0C16.608 0 16.3698 0.0947661 16.1941 0.26368C16.0187 0.432593 15.9199 0.661578 15.9199 0.900278C15.9199 1.74148 15.9199 3.25833 15.9199 4.09952C15.9199 4.33842 16.0187 4.56741 16.1941 4.73632C16.3698 4.90504 16.608 5 16.8564 5C17.7313 5 19.3088 5 20.1836 5C20.4319 5 20.67 4.90504 20.8457 4.73632C21.0214 4.56741 21.1199 4.33842 21.1199 4.09952V0.900278ZM20.5014 0.900278V4.09952C20.5014 4.18061 20.468 4.25833 20.4084 4.31562C20.3488 4.37292 20.268 4.40523 20.1836 4.40523H16.8564C16.7721 4.40523 16.6913 4.37292 16.6317 4.31562C16.5721 4.25833 16.5385 4.18061 16.5385 4.09952V0.900278C16.5385 0.819191 16.5721 0.741475 16.6317 0.684179C16.6913 0.626883 16.7721 0.594766 16.8564 0.594766H20.1836C20.268 0.594766 20.3488 0.626883 20.4084 0.684179C20.468 0.741475 20.5014 0.819191 20.5014 0.900278Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M21.1199 10.9003C21.1199 10.6616 21.0214 10.4326 20.8457 10.2637C20.67 10.095 20.4319 10 20.1836 10C19.3088 10 17.7313 10 16.8564 10C16.608 10 16.3698 10.095 16.1941 10.2637C16.0187 10.4326 15.9199 10.6616 15.9199 10.9003C15.9199 11.7415 15.9199 13.2585 15.9199 14.0997C15.9199 14.3384 16.0187 14.5674 16.1941 14.7363C16.3698 14.905 16.608 15 16.8564 15C17.7313 15 19.3088 15 20.1836 15C20.4319 15 20.67 14.905 20.8457 14.7363C21.0214 14.5674 21.1199 14.3384 21.1199 14.0997V10.9003ZM20.5014 10.9003V14.0997C20.5014 14.1808 20.468 14.2585 20.4084 14.3158C20.3488 14.3731 20.268 14.4052 20.1836 14.4052H16.8564C16.7721 14.4052 16.6913 14.3731 16.6317 14.3158C16.5721 14.2585 16.5385 14.1808 16.5385 14.0997V10.9003C16.5385 10.8194 16.5721 10.7417 16.6317 10.6844C16.6913 10.6271 16.7721 10.5948 16.8564 10.5948H20.1836C20.268 10.5948 20.3488 10.6271 20.4084 10.6844C20.468 10.7417 20.5014 10.8194 20.5014 10.9003Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M10.72 0.900278C10.72 0.661578 10.6213 0.432593 10.4458 0.26368C10.2701 0.0947661 10.032 0 9.78373 0C8.90889 0 7.33115 0 6.45631 0C6.20806 0 5.96992 0.0947661 5.79425 0.26368C5.61878 0.432593 5.52002 0.661578 5.52002 0.900278C5.52002 1.74148 5.52002 3.25833 5.52002 4.09952C5.52002 4.33842 5.61878 4.56741 5.79425 4.73632C5.96992 4.90504 6.20806 5 6.45631 5C7.33115 5 8.90889 5 9.78373 5C10.032 5 10.2701 4.90504 10.4458 4.73632C10.6213 4.56741 10.72 4.33842 10.72 4.09952V0.900278ZM10.1015 0.900278V4.09952C10.1015 4.18061 10.0679 4.25833 10.0083 4.31562C9.94868 4.37292 9.86785 4.40523 9.78373 4.40523H6.45631C6.37198 4.40523 6.29115 4.37292 6.23157 4.31562C6.17198 4.25833 6.13858 4.18061 6.13858 4.09952V0.900278C6.13858 0.819191 6.17198 0.741475 6.23157 0.684179C6.29115 0.626883 6.37198 0.594766 6.45631 0.594766H9.78373C9.86785 0.594766 9.94868 0.626883 10.0083 0.684179C10.0679 0.741475 10.1015 0.819191 10.1015 0.900278Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M12.4912 13.3031C12.693 13.1091 12.8043 12.8449 12.7997 12.5708C12.7879 11.8666 12.7572 10.0403 12.7408 9.05288C12.7317 8.50633 12.2732 8.06547 11.7048 8.05677C10.6779 8.04094 8.77857 8.01151 8.04619 8.00013C7.76117 7.99567 7.48636 8.10271 7.28459 8.29671C6.29271 9.25045 2.0849 13.2964 0.628267 14.697C0.430678 14.8868 0.319824 15.1446 0.319824 15.4131C0.319824 15.6815 0.430678 15.9391 0.628267 16.1291C1.53411 17.0001 3.43973 18.8324 4.34558 19.7034C4.54317 19.8934 4.81102 20 5.09025 20C5.36947 20 5.63756 19.8934 5.83491 19.7034L12.4912 13.3031ZM11.9994 12.8299L5.34303 19.2305C5.27601 19.2949 5.1851 19.331 5.09025 19.331C4.99539 19.331 4.90448 19.2949 4.83746 19.2305L1.12015 15.6561C1.05313 15.5917 1.01556 15.5043 1.01556 15.4131C1.01556 15.3219 1.05313 15.2344 1.12015 15.17L7.77671 8.76968C7.84512 8.7039 7.93835 8.66755 8.03506 8.66911L11.6937 8.72553C11.8864 8.72865 12.042 8.87828 12.0453 9.06358C12.0617 10.051 12.0924 11.8773 12.1039 12.5815C12.1056 12.6745 12.0678 12.7641 11.9994 12.8299Z"
				fill={color}
			/>
			<path
				fill-rule="evenodd"
				clip-rule="evenodd"
				d="M9.15961 10.7256C9.60407 10.7256 9.96501 11.0726 9.96501 11.5C9.96501 11.9274 9.60407 12.2744 9.15961 12.2744C8.71515 12.2744 8.3542 11.9274 8.3542 11.5C8.3542 11.3912 8.3776 11.2876 8.4196 11.1938C8.50236 11.01 8.41407 10.7962 8.22291 10.7169C8.03174 10.6375 7.80939 10.7224 7.72688 10.9062C7.64514 11.0884 7.59961 11.2891 7.59961 11.5C7.59961 12.3279 8.29862 13 9.15961 13C10.0206 13 10.7196 12.3279 10.7196 11.5C10.7196 10.6721 10.0206 10 9.15961 10C8.95134 10 8.78231 10.1625 8.78231 10.3628C8.78231 10.563 8.95134 10.7256 9.15961 10.7256Z"
				fill={color}
			/>
		</svg>
	);
};

export default UsedProducts;
