import PoliceImage from "../../../../assets/images/PoliceImage.png";
import { CardTitle, CardDesc } from "./index.styled";
import { Box, Card } from "@mui/material";
import Comment from "../../../../assets/icons/Comment";
import Reshare from "../../../../assets/icons/Reshare";
import Like from "../../../../assets/icons/Like";
import Statistics from "@mui/icons-material/BarChart";
import Bookmark from "@mui/icons-material/BookmarkBorder";
import Share from "../../../../assets/icons/Share2";

const Index = ({ image = true, actions = false }) => {
	return (
		<Card elevation={4} sx={{ p: "10px", border: ".5px solid #D9D9D9" }}>
			<CardTitle>
				<Box display={"flex"} columnGap={"20px"} alignItems={"center"}>
					Id 70584356<span>14 hrs</span>
				</Box>
			</CardTitle>
			<CardDesc>
				Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera
				House where crowds of Hamas supporters were chanting "gas the Jews".
			</CardDesc>
			{image && (
				<Card
					sx={{
						display: "flex",
						justifyContent: "center",
						alignItems: "center",
						img: {
							borderRadius: "4px",
							width: "100%",
						},
					}}
				>
					<img src={PoliceImage} alt="police-image" />
				</Card>
			)}
			{actions && (
				<Box
					display={"flex"}
					justifyContent={"space-between"}
					alignItems={"center"}
					mt={"24px"}
					sx={{
						"& > *": {
							cursor: "pointer",
						},
					}}
				>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Comment /> 300
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Reshare /> 2.6k
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Like /> 8k
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Statistics
							sx={{
								color: "#929292",
							}}
						/>
						800
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Bookmark
							sx={{
								color: "#929292",
							}}
						/>
					</Box>
					<Box display={"flex"} alignItems={"center"} columnGap={"10px"}>
						<Share />
					</Box>
				</Box>
			)}
		</Card>
	);
};

export default Index;
