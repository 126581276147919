import { useNavigate } from "react-router-dom";
import { InputAdornment, Popover } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import useLayout from "../../../utils/hooks/useLayout";
import { color, spacing } from "../../../styles/variables";
import {
	LeftNav,
	MobileTopNavs,
	MobileWrapper,
	NavigationLink,
	QuickNaviation,
	RightNav,
	Searchbar,
	SearchField,
	SubWrapper,
	Wrapper,
} from "./index.styled";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Chat from "../../../assets/icons/chat/Chat";
import { connect } from "react-redux";
import { Fragment, useEffect, useState } from "react";
import { GetITMicroBloggingProfile, GetITShortVideosProfile, UpdateITProfile } from "../../../utils/apiCalls";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import Loader from "../../loader";
import styled from "@emotion/styled";
import User from "../../../assets/icons/User.svg";
import AddIcon from "@mui/icons-material/AddOutlined";
import UserWhite from "../../../assets/icons/UserWhite.svg";
import SidenavMobileComponent from "../../SidenavMobileComponent";
import MicroBlogging from "../../../assets/icons/microBlogging/microBlogging";
import ShortVideos from "../../../assets/icons/shorts/shortVideos";

const Header = ({ setActiveMBModule, signUpPopup, setSignUpPopupToggle, userData, user, setActiveMBModuleProfile }) => {
	const isMobile = useLayout();
	const [usersData, setUsersData] = useState([]);
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = useState(null);
	const [selectedProfileId, setSelectedProfileId] = useState();
	const [updateStatus, setUpdateStatus] = useState("");
	const [updateStatusId, setUpdateStatusId] = useState("");

	const handleProfileClick = (event) => {
		if (!userData?.data?.itProfileId) {
			setSignUpPopupToggle(!signUpPopup);
		} else {
			setAnchorEl(event.currentTarget);
		}
	};

	const handleProfileClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleSearch = () => {
		if (userData?.data?.itProfileId) {
			navigate("/it/search");
		} else {
			setSignUpPopupToggle(!signUpPopup);
		}
	};

	const handleActiveModule = (name) => {
		setActiveMBModule(name);
		if (userData?.data?.itProfileId) {
			if (name === "shorts") {
				handleShortVideo();
			} else {
				handleMicroBlogging();
			}
		} else {
			setSignUpPopupToggle(!signUpPopup);
		}
	};

	const { data, refetch, isLoading } = useQuery({
		queryKey: ["AllITProfiles"],
		queryFn: async () => {
			const response = await query("GET", "PROFILE", "getAllITProfiles");
			const { data } = response;
			const dataToSet = [...data];
			setUsersData(dataToSet);
		},
		staleTime: 0,
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	useEffect(() => {
		const filteredData = usersData.filter((user) => user.itProfileId === data?.itProfileId);
		setSelectedProfileId(filteredData[0]?.itProfileId);
	}, [usersData, data]);

	useEffect(() => {
		if (updateStatus === updateStatusId) {
			refetch();
		}
	}, [updateStatus, refetch]);

	const handleUpdateProfile = (updateId) => {
		if (updateId) {
			setUpdateStatusId(updateId);
			const payload = {
				itProfileId: updateId,
			};
			UpdateITProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setUpdateStatus(updateId);
				} else {
					setUpdateStatus("error");
				}
			}, payload);
		}
	};

	const toggleAddAccount = () => {
		navigate("/it");
	};

	const handleNavigateProfile = (user) => {
		if (user) {
			const profile = user.itProfileType.toLowerCase();
			navigate(`/it/${profile}/profile/private`, { state: { userData: user } });
			handleProfileClose();
		}
	};

	const handleShortVideo = () => {
		if (data) {
			const payload = {
				itProfileId: data?.itProfileId,
			};
			GetITShortVideosProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					navigate("/shorts/profile/private");
				}
			}, payload);
		}
	};
	const handleMicroBlogging = () => {
		if (data) {
			const payload = {
				itProfileId: data?.itProfileId,
			};
			GetITMicroBloggingProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setActiveMBModuleProfile(data);
					navigate("/microblogging/profile/private", {
						state: {
							module: "it",
							profile: data,
						},
					});
				}
			}, payload);
		}
	};

	return (
		<Wrapper>
			<SubWrapper isMobile={isMobile}>
				{isMobile ? (
					<MobileWrapper>
						<MobileTopNavs>
							<SearchIcon sx={{ color: color("pWhite"), width: 23 }} onClick={handleSearch} />
							<NotificationsIcon sx={{ color: color("pWhite"), width: 30 }} />
							<span onClick={() => navigate("/messaging")}>
								<Chat />
							</span>
							<span onClick={handleProfileClick}>
								<img src={UserWhite} alt="user-white" />
							</span>
							<Popover
								id={id}
								open={open}
								anchorEl={anchorEl}
								onClose={handleProfileClose}
								placement="bottom-end"
								sx={{
									right: 0,
									width: "50%",
									top: "42px",
									".MuiPaper-root": {
										borderRadius: 0,
										right: "-100%",
										left: "initial !important",
										width: "100%",
										padding: "12px",
									},
								}}
							>
								{user !== "Guest" && (
									<>
										{isLoading ? (
											<Loader />
										) : (
											usersData?.map((user, index) => (
												<Fragment key={index}>
													<ProfileName>
														<img src={User} alt={"user-icon"} /> {user?.itProfileType}
													</ProfileName>
													<InputWrapper>
														<div onClick={() => handleUpdateProfile(user?.itProfileId)}>
															<input
																type="radio"
																name="userOption"
																value={user?.itProfileId}
																checked={user?.itProfileId === selectedProfileId}
																onChange={() => setSelectedProfileId(user?.itProfileId)}
															/>
														</div>
														<div
															onClick={() => {
																handleNavigateProfile(user);
																handleUpdateProfile(user?.itProfileId);
																setSelectedProfileId(user?.itProfileId);
															}}
														>
															<p>{user.firstName}</p>
														</div>
													</InputWrapper>
												</Fragment>
											))
										)}
										{usersData?.length > 0 && (
											<ButtonWrapper>
												<AddAccount onClick={toggleAddAccount}>
													Add Account <AddIcon />
												</AddAccount>
											</ButtonWrapper>
										)}
									</>
								)}
							</Popover>
							<SidenavMobileComponent module={"it"} color={"#fff"} />
						</MobileTopNavs>
					</MobileWrapper>
				) : (
					<>
						<LeftNav>
							<NavigationLink>
								Jobs
								<ExpandMoreIcon />
							</NavigationLink>
							<NavigationLink>
								Talent
								<ExpandMoreIcon />
							</NavigationLink>
							<NavigationLink>
								Reports
								<ExpandMoreIcon />
							</NavigationLink>
							<NavigationLink to={"/messaging"}>Messages</NavigationLink>
						</LeftNav>
						<Searchbar onClick={handleSearch}>
							<SearchField
								onClick={handleSearch}
								startAdornment={
									<InputAdornment
										position="start"
										sx={{
											marginInlineEnd: spacing("xs"),
										}}
									>
										<SearchIcon color={color("dimGray")} />
									</InputAdornment>
								}
								placeholder="Search"
							/>
						</Searchbar>
						<RightNav>
							<QuickNaviation onClick={() => handleActiveModule("it")}>
								<MicroBlogging color="#fff" />
								Micro Blogging
							</QuickNaviation>
							<QuickNaviation onClick={() => handleActiveModule("shorts")}>
								<ShortVideos color="#fff" />
								Short Videos
							</QuickNaviation>
						</RightNav>
					</>
				)}
			</SubWrapper>
		</Wrapper>
	);
};

const ProfileName = styled.h5`
	color: #000;
	margin-block: 8px;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	display: inline-flex;
	align-items: center;
	column-gap: 12px;

	& > img {
		height: 16px;
		aspect-ratio: 1;
	}
`;

const InputWrapper = styled.div`
	display: flex;
	column-gap: 12px;
	margin: 8px 16px;

	& > div {
		display: inline-flex;
		align-items: center;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-block-start: 16px;
`;

const AddAccount = styled.button`
	width: 100%;
	text-align: center;
	border-radius: 8px;
	background: #3abf81;
	border: 1px solid transparent;
	display: inline-flex;
	justify-content: center;
	padding: 7px 10px;
	align-items: center;
	gap: 2px;
	color: #fff;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const mapStateToProps = (state) => {
	return {
		userData: state.it?.profile,
		signUpPopup: state.it?.signUpPopup,
	};
};
const mapPropsToState = (dispatch) => ({
	setActiveMBModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: data,
		}),
	setActiveMBModuleProfile: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE_PROFILE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_IT",
			payload: data,
		}),
});

export default connect(mapStateToProps, mapPropsToState)(Header);
