import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { borderRadius, color, spacing } from "../../../styles/variables";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	margin-block: ${spacing("xl")};
	margin-inline: calc(${spacing("xxxl1")} * 2);
	grid-column-gap: ${spacing("lg")};
	@media screen and (max-width: 768px) {
		display: flex;
		width: 100%;
		justify-content: center;
		background-color: #fff;
		margin: ${spacing("xl")} 0 ${spacing("xxxl")};
	}
`;

const EduWrapper = styled.div`
	display: flex;
	width: 80%;
	justify-content: space-around;
	background-color: #fff;
	margin-top: 1vw;

	@media screen and (max-width: 768px) {
		display: flex;
		width: 95%;
		flex-direction: column;
	}
`;

const EduWrapperLeft = styled.div`
	grid-column: span 3;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const EduWrapperCenter = styled.div`
	grid-column: 4 / span 5;
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("lg1")};
	margin-block-end: 12px;

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding-inline: ${spacing("md")};
		margin-block-end: 56px;
	}
`;

const EduWrapperRight = styled.div`
	grid-column: 10 / span 3;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const RightHead = styled.div`
	display: flex;
	align-items: center;
	img {
		width: 0.4vw;
		height: 0.3vw;
		margin: 0 0.5vw 0 0;
	}
	h3 {
		margin: 1vw 0 1vw;
		font-size: 1vw;
		font-weight: 500;
		color: #30302f;
	}

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const LeftList = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("xl")};

	button {
		border-radius: ${borderRadius(60)};
		text-transform: none;
		font-family: Inter;
		font-size: 20px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;

		padding-block: ${spacing("md")};
		margin-block-start: ${spacing("xxxl1")};
	}

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const CustomNavLinkLeft = styled(NavLink)`
	display: flex;
	align-items: center;
	column-gap: ${spacing("md")};
	img {
		width: 20px;
		aspect-ratio: 1;
	}
	span {
		color: ${color("gray")};
		font-family: Inter;
		font-size: 18px;
		font-style: normal;
		font-weight: 500;
	}
	${(props) =>
		props.active &&
		`span {
		color: ${color("pGreen")};
		font-weight: 700;
	}`};
`;

const LeftBottom = styled.div`
	margin-block-start: 24px;
	width: max-content;
	box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.25);
	border-radius: 0.5vw;
	padding: 16px 24px;
	@media screen and (max-width: 768px) {
		margin: 0;
		width: -webkit-fill-available;
	}
`;

const LeftBottomHead = styled.div`
	margin-block: 12px;
	h3 {
		font-size: 1vw;
		font-weight: 600;
		color: #000000;
	}

	@media screen and (max-width: 768px) {
		padding: 3vw;
		h3 {
			font-size: 5vw;
		}
	}
`;

const LeftBottomContent = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	p {
		font-size: 0.8vw;
		font-weight: 600;
		color: #1c1b1b;
	}
	span {
		font-size: 0.8vw;
		font-weight: 400;
		color: #828282;
	}

	@media screen and (max-width: 768px) {
		margin: 2vw 0 2vw 3vw;
		p {
			font-size: 4vw;
			margin-bottom: 1vw;
		}
		span {
			font-size: 3.5vw;
			margin-bottom: 1vw;
		}
	}
`;

const LeftBottomSpan = styled.div`
	margin-block-start: 8px;
	text-align: end;
	span {
		cursor: pointer;
		font-weight: 400;
		color: #6d6d6d;
		font-size: 0.8vw;
		text-decoration-line: underline;
	}

	@media screen and (max-width: 768px) {
		padding: 2vw;

		span {
			font-size: 3.5vw;
		}
	}
`;

const RightList = styled.ul`
	li {
		display: flex;
		margin-left: 1.5vw;
	}

	.filter-option {
		display: flex;
		align-items: center;
		padding: 1vw 0;
		cursor: pointer;
		img {
			width: 1vw;
			height: auto;
		}
		span {
			font-size: 1vw;
			padding: 0 1vw;
			color: #767575;
		}
	}

	.filter-option.active {
		background-color: #e4f6f3;
		width: 80%;
		border-radius: 0.5vw;
		padding: 0.5vw;
		margin-left: -1.5vw;
	}
`;
const CustomNavLink = styled(NavLink)`
	display: flex;
	align-items: center;
	padding: 1vw 0;
	img {
		width: 1vw;
		height: auto;
	}
	span {
		font-size: 1vw;
		padding: 0 1vw;
		color: #767575;
	}
	&.active {
		background-color: #e4f6f3;
		width: 80%;
		border-radius: 0.5vw;
		padding: 0.5vw;
		margin-left: -1.5vw;
	}
`;

const SpanHead = styled.span`
	font-size: 1vw;
	font-weight: 500;
	color: #696a68;

	@media screen and (max-width: 768px) {
		font-size: 1vw;
		font-weight: 500;
		color: #696a68;
	}
`;

const CenterTop = styled.div`
	display: flex;
	width: 100%;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
	border-radius: 0.2vw;
	margin-bottom: 1vw;

	@media screen and (max-width: 768px) {
		display: flex;
		width: 95%;
		box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
		border-radius: 1vw;
		margin-bottom: 1vw;
		padding: 0 2vw 2vw;
	}
`;

const CenterTopImage = styled.div`
	display: flex;
	width: 10%;
	img {
		width: 3vw;
		height: 3vw;
		margin: 1vw;
	}

	@media screen and (max-width: 768px) {
		display: flex;
		width: 20%;

		img {
			width: 56px;
			height: 56px;
			margin: 4vw;
		}
	}
`;
const CenterTopContent = styled.div`
	display: flex;
	width: 80%;
	flex-direction: column;
	h3 {
		margin: 1vw 0 1vw 2vw;
		color: #61bcac;
		font-size: 1vw;
		font-weight: 500;
	}

	@media screen and (max-width: 768px) {
		h3 {
			margin: 4vw 0 5vw 2vw;
			color: #61bcac;
			font-size: 5vw;
			font-weight: 500;
			margin-left: 4vw;
		}
	}
`;
const CenterIcons = styled.div`
	display: flex;
	margin-left: 1vw;
	img {
		width: 1vw;
		height: 1vw;
		display: flex;
		margin-left: 1vw;
	}

	@media screen and (max-width: 768px) {
		img {
			width: 5vw;
			height: 5vw;
			margin-left: 3vw;
		}
	}
`;

const CenterTopPost = styled.div`
	display: flex;
	width: 15%;
	align-items: end;

	@media screen and (max-width: 768px) {
		display: flex;
		width: 15%;
		align-items: end;
	}
`;

const PostButton = styled.div`
	width: 2.5vw;
	color: #fff;
	background-color: #61bcac;
	border-radius: 2.5vw;
	margin: 0 2vw 0.8vw 1vw;
	font-size: 1vw;
	font-weight: 600;
	padding: 0.2vw 0.8vw;
	text-align: center;

	@media screen and (max-width: 768px) {
		padding: 1vw 2vw;
		width: 10vw;
		border-radius: 5vw;
		margin: 0 2vw 2vw 1vw;
		font-size: 4vw;
		text-align: center;
	}
`;

const LeftPostButton = styled.div`
	width: 11vw;
	color: #fff;
	background-color: #61bcac;
	border-radius: 2.5vw;
	margin: 5vw 2vw 0.8vw 0;
	font-size: 1vw;
	font-weight: 600;
	padding: 0.8vw;
	text-align: center;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const MobileBottom = styled.div`
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		width: 100%;
		background: #fff;
		box-shadow: 0px 1px 11px 0px rgba(0, 0, 0, 0.25);
		display: flex;
		justify-content: center;
		position: fixed;
		bottom: 0%;
	}
`;

const MobileBottomIcons = styled.ul`
	display: none;

	@media screen and (max-width: 768px) {
		display: block;
		display: flex;
		justify-content: space-between;
		width: 90%;
		li {
			display: flex;
			padding: 1vw 0;
			img {
				width: 6vw;
				height: 6vw;
				margin: 1.5vw 0 1vw 0;
			}
		}
	}
`;

const Rightdiv2 = styled.div`
	width: 100%;
	background-color: #f5f5f5;
	height: 25vw;
	@media screen and (max-width: 768px) {
		height: auto;
		margin-bottom: 10vw;
	}
`;

const Rightdiv2Head = styled.div`
	h2 {
		padding: 1vw 0 1vw 1.5vw;
		font-size: 1.2vw;
		font-weight: 700;
		color: #000000;
	}

	@media screen and (max-width: 768px) {
		h2 {
			padding: 2vw 0 2vw 2vw;
			font-size: 5vw;
		}
	}
`;

const Rightdiv2Content = styled.div`
	margin: 2vw 0 2vw 1.5vw;
	p {
		font-size: 1vw;
		font-weight: 700;
		color: #242323;
	}
	span {
		font-size: 0.8vw;
		font-weight: 500;
		color: #8d8d8d;
	}

	@media screen and (max-width: 768px) {
		margin: 3vw 0 3vw 4vw;
		p {
			font-size: 4vw;
		}
		span {
			font-size: 3vw;
		}
	}
`;

const SearchWrapper = styled.div`
	display: flex;

	@media screen and (max-width: 768px) {
	}
`;
const SearchLeft = styled.div`
	// width: 20%;

	@media screen and (max-width: 768px) {
	}
`;
const SearchRight = styled.div`
	@media screen and (max-width: 768px) {
	}
`;

export {
	EduWrapper,
	EduWrapperLeft,
	EduWrapperCenter,
	EduWrapperRight,
	LeftList,
	CenterTop,
	CenterTopImage,
	CenterTopContent,
	CenterTopPost,
	PostButton,
	CenterIcons,
	SpanHead,
	Wrapper,
	RightHead,
	RightList,
	LeftPostButton,
	MobileBottom,
	MobileBottomIcons,
	LeftBottom,
	LeftBottomHead,
	LeftBottomContent,
	LeftBottomSpan,
	Rightdiv2,
	Rightdiv2Head,
	Rightdiv2Content,
	SearchWrapper,
	SearchLeft,
	SearchRight,
	CustomNavLink,
	CustomNavLinkLeft,
};
