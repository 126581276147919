import { React, useEffect } from "react";
import "./App.scss";
import "./styles/global.scss";
import Routing from "./routes";
import { Helmet } from "react-helmet";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
const queryClient = new QueryClient();

function App() {
	// const navigate = useNavigate();
	// const location = useLocation();
	// const token = localStorage.getItem("token") || sessionStorage.getItem("token");

	// useEffect(() => {
	//   if (!token) {
	//     navigate("/login");
	//   }
	// }, [location, token, navigate]);
	return (
		<div className="app">
			<Helmet>
				<title>Buzzgalatic: Revolutionize Your Business with Web 4.0</title>
				<meta property="og:title" content="Buzzgalatic: Revolutionize Your Business with Web 4.0" />
				<meta property="og:site_name" content="Buzzgalactic" />
				<meta property="og:url" content="https://buzzgalactic.com/" />
				<meta
					property="og:description"
					content="Explore Buzzgalactic, the innovative Web 4.0 ecosystem that redefines online interaction by blending banking, social media, e-commerce, and content creation into one seamless experience. Empower yourself with our unique features like ad revenue sharing, social-driven e-commerce, direct news from eyewitnesses, and multi-profiling for diverse interests. Make your voice heard in government decisions, discover financial equality, and connect in novel ways. With Buzzgalactic, unlock endless opportunities for business, creativity, and communication, and join a community committed to shaping a fairer, more connected world."
				/>
				<meta property="og:type" content="website" />
				<meta
					property="og:image"
					content="https://buzzgalactic.com/static/media/logo.67bd0800a88336898a00a5361052378c.svg"
				/>
			</Helmet>
			<QueryClientProvider client={queryClient}>
				<Routing />
			</QueryClientProvider>
		</div>
	);
}

export default App;
