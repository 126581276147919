import React, { useState, useEffect } from "react";
import locationMark from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/user-profile-backsquare.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";
import message from "../../../../../assets/icons/mi_message.png";
import outline from "../../../../../assets/icons/material-symbols_rate-review-outline.png";
import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";
import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfilesWrapper,
	ProfileModule,
	Butt,
} from "../../../styles/index.styled.js";
import { GetITClientProfile, GetITShortVideoPrivateAccount } from "../../../../../utils/apiCalls.js";
import Loader from "../../../../../components/loader/index.js";
import { connect } from "react-redux";

const ClientPublic = ({ setActiveModule, setActiveMBProfile }) => {
	const [clientProfileData, setClientProfileData] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const location = useLocation();
	const userData = location.state?.user;
	const navigate = useNavigate();
	const handlePublicProfileNav = (path) => {
		return;
		navigate(`/${path}`);
	};

	const [shortVideoIT, setShortVideoIT] = useState([]);

	const getITClientProfile = () => {
		setIsLoader(true);
		if (userData) {
			const payload = {
				itProfileTypeId: userData?.clientId || userData?.accountTypeId,
				itProfileId: userData?.itProfileId || userData?.moduleId,
			};
			GetITClientProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setIsLoader(false);
					setClientProfileData(data);
				} else {
					setIsLoader(false);
					setClientProfileData();
				}
			}, payload);
		}
	};

	useEffect(() => {
		getITClientProfile();
	}, []);

	const getITSVPrivateAccount = () => {
		const itProfileId = localStorage?.getItem("itProfileId");
		if (itProfileId) {
			const payload = {
				itProfileId: itProfileId,
			};
			GetITShortVideoPrivateAccount((res) => {
				const { message, data, status } = res;
				if (status === 200) {
					setShortVideoIT(data);
				} else {
					setShortVideoIT([]);
				}
			}, payload);
		}
	};

	const handleShortvideos = (clientProfileData) => {
		getITSVPrivateAccount();
		if (shortVideoIT) {
			navigate("/shorts/profile/public", { state: { user: clientProfileData } });
		}
	};

	const handleMBRedirection = (data) => {
		setActiveModule("it");
		setActiveMBProfile(data?.itProfileId);
		navigate("/microblogging/profile/public");
	};

	return (
		<>
			{token ? (
				isLoader ? (
					<Loader />
				) : (
					<Wrapper>
						<ProfileWrapper>
							<ProfileSections>
								<SectionTitle>Client</SectionTitle>
								<Profile>
									<ProfileDetailsWrapper>
										<ProfileImageWrapper>
											<img src={clientProfileData?.profilePicture || ProfilePicture} alt="profile" />
										</ProfileImageWrapper>
										<UserDetailsWrapper>
											<UserNameWrapper>
												<UserName>{clientProfileData?.fullName || "Anonymous User"}</UserName>
											</UserNameWrapper>
											<UserAddress>
												<img src={locationMark} alt="location-icon" />
												{clientProfileData?.location || "No Location available"}
											</UserAddress>
										</UserDetailsWrapper>
									</ProfileDetailsWrapper>
									<UserProfileActions>
										<Button buttonClick={() => handlePublicProfileNav("message")}>
											<img src={message} alt="message" />
											Message
										</Button>
										<Button buttonClick={() => handlePublicProfileNav("review")}>
											<img src={outline} alt="review" />
											Review
										</Button>
									</UserProfileActions>
								</Profile>

								<MoreDetailWrapper>
									<ProfilesAndAboutWrapper>
										<ProfilesWrapper>
											<Profiles fillAvailable>
												<ProfileHeading>Other Profiles</ProfileHeading>
												<ProfileModule>
													<Butt onClick={() => handleMBRedirection(clientProfileData)}>
														Microblogging
													</Butt>
													<Butt onClick={() => handleShortvideos(clientProfileData)}>ShortVideos</Butt>
												</ProfileModule>
												<ProfileList>
													{clientProfileData?.userName ? (
														<ProfileName>
															<ProfileUserName>userName</ProfileUserName>{" "}
															{clientProfileData?.userName}
														</ProfileName>
													) : (
														"No Data Available"
													)}
													{clientProfileData?.shortVideoUsername && (
														<ProfileName>
															<ProfileUserName>shortVideoUsername</ProfileUserName>{" "}
															{clientProfileData?.shortVideoUsername}
														</ProfileName>
													)}
												</ProfileList>
											</Profiles>
										</ProfilesWrapper>
										<About>
											<AboutTitle>About</AboutTitle>
											<AboutDesc>{clientProfileData?.about || "No Data available"}</AboutDesc>
											<More>more...</More>
										</About>
									</ProfilesAndAboutWrapper>
									<FeedbackWrapper>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment1}
												commentimagestyle={{
													marginLeft: "2rem",
												}}
												commenttitle="Thanks"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment}
												commenttitle="Reply"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
									</FeedbackWrapper>
								</MoreDetailWrapper>
							</ProfileSections>
						</ProfileWrapper>
					</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(ClientPublic);
