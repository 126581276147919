import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../../../components/Education/Forms/Signup";
import CompanySignUpForm from "../../../../components/Education/Forms/Signup/Company";

const CompanySignUp = () => {
	const navigate = useNavigate();
	const [profilePicture, setProfilePicture] = useState(null);
	const handleSubmit = (values) => {
		navigate("/education/microblogging", {
			state: {
				allData: { ...values },
				picture: profilePicture,
				url: "createEducationCompany",
				redirect: "/education/company/profile/private",
			},
		});
	};
	return (
		<SignUp userType={"Company"} onSubmit={handleSubmit}>
			<CompanySignUpForm setProfilePicture={setProfilePicture} />
		</SignUp>
	);
};

export default CompanySignUp;
