import Chat from "../Chat";
import { Box } from "@mui/material";
import Post from "./Post";
import profile1 from "../../../assets/images/profile/profile.png";
import profile2 from "../../../assets/images/profile/follow/1.png";
import profile3 from "../../../assets/images/profile/follow/2.png";
import profile4 from "../../../assets/images/profile/follow/3.png";
import profile5 from "../../../assets/images/profile/follow/4.png";
import PoliceImg from "../../../assets/images/PoliceImage.png";
import Shoes from "../../../assets/images/Shoes.png";
import useLayout from "../../../utils/hooks/useLayout";
import PostDialog from "../PostDialog";
import Product1 from "../../../assets/images/Main/Product1.png";
import Product2 from "../../../assets/images/Main/Product2.png";
import Product3 from "../../../assets/images/Main/Product3.png";
import Product4 from "../../../assets/images/Main/Product4.png";
import Product5 from "../../../assets/images/Main/Product5.png";
import Product6 from "../../../assets/images/Main/Product6.png";

const posts = [
	{
		id: 1,
		type: "reward",
		module: ["microblogging", "reward feed"],
		author: {
			name: "george Kajaia",
			module: "information technology",
			avatar: profile1,
		},
		post: {
			name: "michelle",
			avatar: profile2,
			posted: "14 hrs",
			reward: 1,
			description:
				"Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting 'gas the Jews'.",
			thumbnail: PoliceImg,
			comments: 300,
			reshares: 2.6,
			likes: 8,
			stats: 800,
		},
	},
	{
		id: 2,
		type: "promotion",
		module: ["used products"],
		author: {
			name: "george Kajaia",
			avatar: profile1,
		},
		post: {
			name: "michelle",
			avatar: profile3,
			posted: "14 hrs",
			description: "Check out new items just added by a seller you follow",
			products: [
				{
					id: 1,
					location: ["georgia", "united states"],
					price: 30,
					description: "White Adidas Doorstep High Top Mens Sneakers Shoes",
					thumbnail: Product1,
				},
				{
					id: 2,
					location: ["georgia", "united states"],
					price: 40,
					description: "White Adidas Doorstep High Top Mens Sneakers Shoes",
					thumbnail: Product2,
				},
				{
					id: 3,
					location: ["georgia", "united states"],
					price: 40,
					description: "White Adidas Doorstep High Top Mens Sneakers Shoes",
					thumbnail: Product2,
				},
			],
			comments: 80,
			likes: 114,
		},
	},
	{
		id: 3,
		type: "real estate",
		module: ["real estate"],
		author: {
			name: "george Kajaia",
			module: "education",
			avatar: profile1,
		},
		post: {
			name: "peter bark",
			avatar: profile4,
			posted: "14 hrs",
			module: "transport",
			description:
				"Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting 'gas the Jews'.",
			thumbnail: Shoes,
			comments: 300,
			reshares: 2.6,
			likes: 8,
			stats: 800,
		},
	},
	{
		id: 4,
		type: "shorts",
		module: ["short videos", "for you"],
		author: {
			name: "george Kajaia",
			module: "investment",
			avatar: profile1,
		},
		post: {
			name: "bob johnson",
			avatar: profile5,
			posted: "14 hrs",
			module: "agriculture",
			thumbnail: "",
			comments: 456,
			likes: "2,456",
		},
	},
	{
		id: 5,
		type: "promotion",
		module: ["real estate"],
		author: {
			name: "george Kajaia",
			avatar: profile1,
		},
		post: {
			name: "peter bark",
			avatar: profile2,
			posted: "14 hrs",
			description: "Check out new items just added by a seller you follow",
			products: [
				{
					id: 1,
					location: ["georgia", "united states"],
					price: "20,000",
					description: "White Adidas Doorstep High Top Mens Sneakers Shoes",
					thumbnail: Product3,
				},
				{
					id: 2,
					location: ["georgia", "united states"],
					price: "40,000",
					description: "White Adidas Doorstep High Top Mens Sneakers Shoes",
					thumbnail: Product4,
				},
			],
			comments: 80,
			likes: 114,
		},
	},
	{
		id: 6,
		type: "promotion",
		module: ["real estate"],
		author: {
			name: "george Kajaia",
			avatar: profile1,
		},
		post: {
			name: "peter bark",
			avatar: profile2,
			posted: "14 hrs",
			description: "Check out new items just added by a seller you follow",
			products: [
				{
					id: 1,
					location: ["georgia", "united states"],
					price: "10,000",
					description: "Sporty, reliable, and successful: For over 40 years",
					thumbnail: Product5,
				},
				{
					id: 2,
					location: ["georgia", "united states"],
					price: "20,000",
					description: "Sporty, reliable, and successful: For over 40 years",
					thumbnail: Product6,
				},
			],
			comments: 80,
			likes: 114,
		},
	},
	{
		id: 7,
		type: "offer",
		module: ["social journalist"],
		author: {
			name: "ID 45677894",
		},
		name: "offer 15",
		location: "georgia",
		purpose: "news creation",
		description:
			"A car incident has just occurred, and your perspective is invaluable. If you're nearby, grab your phone and capture what's happening. Your footage and observations can provide crucial insights into the event.",
		amount: "200",
		post: {
			name: "Id 70584356",
			posted: "14 hrs",
			description:
				"Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting 'gas the Jews'.",
			thumbnail: "",
			comments: 300,
			reshares: 2.6,
			likes: 8,
			stats: 800,
		},
	},
];

const Feed = () => {
	const isMobile = useLayout();
	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			rowGap={"32px"}
			gridColumn={"span 5"}
			my={!isMobile && "24px"}
			px={isMobile && "12px"}
			width={"-webkit-fill-available"}
		>
			<Chat />
			<PostDialog />
			{posts.map((post) => (
				<Post key={post.id} post={post} />
			))}
		</Box>
	);
};

export default Feed;
