import Preview from "../../../../assets/images/shorts/Preview1.png";
import Smiley from "../../../../assets/icons/shorts/Smile.svg";
import Chat from "../../../../assets/icons/shorts/Chat.svg";
import { Box, Card } from "@mui/material";
import { borderRadius, color, spacing } from "../../../../styles/variables";
import styled from "@emotion/styled";
import Play from "@mui/icons-material/PlayArrow";

const Shorts = ({ short }) => {
	return (
		<Card elevation={10} sx={{ p: "10px", border: ".5px solid #D9D9D9", borderRadius: borderRadius(8) }}>
			<Box display={"flex"} columnGap={"10px"} alignItems={"center"}>
				<ThumbnailWrapper>
					<Thumbnail src={Preview} />
					<Play
						sx={{
							position: "absolute",
							top: "50%",
							left: "50%",
							transform: "translate(-50%, -50%)",
							color: "#fff",
							fontSize: "110px",
							cursor: "pointer",
						}}
					/>
				</ThumbnailWrapper>
				<Box
					display={"flex"}
					flexDirection={"column"}
					justifyContent={"space-between"}
					alignItems={"center"}
					width={"20%"}
					rowGap={"24px"}
				>
					<Box
						display={"flex"}
						flexDirection={"column"}
						rowGap={"12px"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<IconWrapper>
							<img src={Smiley} alt="likes" />
						</IconWrapper>
						2,456
					</Box>
					<Box
						display={"flex"}
						flexDirection={"column"}
						rowGap={"12px"}
						justifyContent={"center"}
						alignItems={"center"}
					>
						<IconWrapper>
							<img src={Chat} alt="comments" />
						</IconWrapper>
						456
					</Box>
				</Box>
			</Box>
		</Card>
	);
};

const ThumbnailWrapper = styled.div`
	width: 80%;
	position: relative;
`;

const Thumbnail = styled.img`
	width: 100%;
	border-radius: ${borderRadius(8)};
`;

const IconWrapper = styled.div`
	cursor: pointer;
	border-radius: 50%;
	padding: ${spacing("md1")};
	background-color: ${color("sWhite")};

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: 36px;
		width: 36px;
	}
`;

export default Shorts;
