import React from "react";

const SendMessage = ({ color = "#fff", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
			<path
				d="M3.88536 13.1883C3.72962 13.0392 3.47685 13.0399 3.32032 13.1876L0.117351 16.2214C-0.0387838 16.3694 -0.0391831 16.6092 0.116553 16.758C0.194421 16.8324 0.297047 16.8696 0.399273 16.8696C0.5011 16.8696 0.603327 16.8324 0.681195 16.7588L3.88456 13.7253C4.0407 13.5773 4.0411 13.3371 3.88536 13.1883Z"
				fill={color}
			/>
			<path
				d="M8.22077 14.2C8.06503 14.0509 7.81226 14.0517 7.65613 14.1993L1.18151 20.341C1.02538 20.4894 1.02498 20.7292 1.18111 20.8776C1.25898 20.952 1.36121 20.9891 1.46383 20.9891C1.56606 20.9891 1.66789 20.952 1.74615 20.8783L8.22077 14.7362C8.37651 14.5882 8.37691 14.348 8.22077 14.2Z"
				fill={color}
			/>
			<path
				d="M8.71975 18.3189L5.51638 21.352C5.35985 21.5 5.35945 21.7398 5.51518 21.8886C5.59305 21.963 5.69568 22.0002 5.79791 22.0002C5.89973 22.0002 6.00196 21.963 6.07983 21.8893L9.28319 18.8562C9.43973 18.7082 9.44013 18.4684 9.28439 18.3197C9.12865 18.1709 8.87588 18.1709 8.71975 18.3189Z"
				fill={color}
			/>
			<path
				d="M22.5738 0.559949C21.9995 0.0142701 21.1418 -0.149282 20.3859 0.14329L3.70496 6.61744C2.96342 6.89635 2.48503 7.5498 2.45668 8.32278C2.42833 9.09424 2.84522 9.76097 3.57239 10.1067L8.70408 12.5288C9.27432 12.7903 9.69201 13.1846 9.98032 13.7325L12.5264 18.614C12.8646 19.2701 13.5551 19.6777 14.3289 19.6777L14.4044 19.6762C15.2011 19.65 15.8875 19.1935 16.1978 18.4831L23.013 2.63641C23.3205 1.90517 23.1524 1.10942 22.5738 0.559949ZM22.2727 2.35219L15.459 18.1947C15.2681 18.6311 14.8636 18.9017 14.3761 18.918L14.3289 18.9187C13.8553 18.9187 13.4496 18.68 13.2428 18.2786L10.6955 13.394C10.3245 12.6893 9.78665 12.1827 9.05589 11.8473L3.92938 9.42741C3.48374 9.21529 3.23816 8.82254 3.25533 8.34934C3.2725 7.87538 3.55082 7.49136 4.00326 7.32135L20.6874 0.846069C20.8435 0.785734 21.004 0.754996 21.1641 0.754996C21.4816 0.754996 21.7735 0.873391 22.0087 1.09652C22.3633 1.43311 22.4611 1.90327 22.2727 2.35219Z"
				fill={color}
			/>
		</svg>
	);
};

export default SendMessage;
