import React, { useEffect, useState } from "react";
import locationMark from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/user-profile-backsquare.png";
import editable from "../../../../../assets/images/editable.png";
import editablenoborder from "../../../../../assets/images/editablenoborder.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent";
import Button from "../../../../../components/button/index.js";
import message from "../../../../../assets/icons/mi_message.png";
import outline from "../../../../../assets/icons/material-symbols_rate-review-outline.png";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfileModule,
	Butt,
} from "../../../styles/index.styled.js";
import { ProfilesWrapper } from "../../../../Education/styles/index.styled.js";
import { GetEducationCompanyProfile } from "../../../../../utils/apiCalls.js";
import Loader from "../../../../../components/loader/index.js";
import { connect } from "react-redux";

const ProfilePrivate = ({setActiveMBProfile,setActiveModule}) => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [educationCompanyData, setEducationCompanyData] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const navigate = useNavigate();
	const location = useLocation();
	const userData = location.state?.user;

	const getEducationCompanyProfile = () => {
		setIsLoader(true);
		if (userData) {
			const payload = {
				educationTypeId: userData?.companyId || userData?.accountTypeId, 
				educationId: userData?.educationId  || userData?.moduleId,
			};
			GetEducationCompanyProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setIsLoader(false);
					setEducationCompanyData(data);
				} else {
					setIsLoader(false);
					setEducationCompanyData();
				}
			}, payload);
		}
	};

	useEffect(() => {
		getEducationCompanyProfile();
	}, []);

	const handlePublicProfileNav = (path) => {
		return;
		navigate(`/${path}`);
	};
	const handleShortvideos = (educationCompanyData) => {
		navigate("/shorts/profile/public", { state: { user: educationCompanyData } });
	};

	const handleMBRedirection = (data) => {
		setActiveModule("education");
		setActiveMBProfile(data?.educationId);
		navigate("/microblogging/profile/public");
	};
	return (
		<>
			{token ? (
				isLoader ? (
					<Loader/>
				):(
				<Wrapper>
					<ProfileWrapper>
						<ProfileSections>
							<SectionTitle>Company</SectionTitle>
							<Profile>
								<ProfileDetailsWrapper>
									<ProfileImageWrapper>
									<img src={educationCompanyData?.companyLogo || ProfilePicture} alt="profile" />
										<img src={editable} className="imgedit" alt="profile" />
									</ProfileImageWrapper>
									<UserDetailsWrapper>
										<UserNameWrapper>
											<UserName>{educationCompanyData?.companyName || "companyName "}</UserName>
											<img
												src={editablenoborder}
												className="imgedit"
												alt="profile"
												style={{
													display: "block",
												}}
											/>
										</UserNameWrapper>
										<UserAddress>
											<img src={locationMark} alt="location-icon" />
											{educationCompanyData?.location || "No Location available"} <br />
												{educationCompanyData?.industrySector}
												<br />
												{educationCompanyData?.companySize}+ employees <br />
												{educationCompanyData?.websiteURL}
										</UserAddress>
									</UserDetailsWrapper>
								</ProfileDetailsWrapper>
								<UserProfileActions>
									<Button buttonClick={() => handlePublicProfileNav("message")}>
										<img src={message} alt="message" />
										Message
									</Button>
									<Button buttonClick={() => handlePublicProfileNav("review")}>
										<img src={outline} alt="review" />
										Review
									</Button>
								</UserProfileActions>
							</Profile>

							<MoreDetailWrapper>
								<ProfilesAndAboutWrapper>
									<ProfilesWrapper>
										<Profiles fillAvailable>
											<ProfileHeading>Other Profiles</ProfileHeading>
											<ProfileModule>
													<Butt onClick={() => handleMBRedirection(educationCompanyData)}>
														Microblogging
													</Butt>
													<Butt onClick={() => handleShortvideos(educationCompanyData)}>ShortVideos</Butt>
												</ProfileModule>
											<ProfileList>
											{educationCompanyData?.userName ? (
														<ProfileName>
															<ProfileUserName>Username</ProfileUserName>{" "}
															{educationCompanyData?.userName}
														</ProfileName>
													) : (
														"No Data Available"
													)}
													{educationCompanyData?.shortVideoUsername && (
														<ProfileName>
															<ProfileUserName>shortVideoUsername</ProfileUserName>{" "}
															{educationCompanyData?.shortVideoUsername}
														</ProfileName>
													)}
											</ProfileList>
										</Profiles>
									</ProfilesWrapper>
									<About card>
										<AboutTitle>
											About <img src={editablenoborder} alt="" />
										</AboutTitle>
										<AboutDesc noClamp>
										{educationCompanyData?.about || "No Data Available"}
										</AboutDesc>
									</About>
								</ProfilesAndAboutWrapper>
								<FeedbackWrapper>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment1}
											commentimagestyle={{
												marginLeft: "2rem",
											}}
											commenttitle="Thanks"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment}
											commenttitle="Reply"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
								</FeedbackWrapper>
							</MoreDetailWrapper>
						</ProfileSections>
					</ProfileWrapper>
				</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};


const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(ProfilePrivate);
