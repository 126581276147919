import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { btnStyleOverrides, BtnWrapper, FormTitle, FormWrapper } from "../index.styled";
import { borderRadius, color, spacing } from "../../../../../styles/variables";
import PrimaryBtn from "../../../../Buttons/Primary";
import SecondaryBtn from "../../../../Buttons/Secondary";
import { useNavigate } from "react-router-dom";
import Client from "../../../../../assets/icons/education/Client";
import Freelancer from "../../../../../assets/icons/education/Freelancer";
import Employee from "../../../../../assets/icons/education/Employee";
import Company from "../../../../../assets/icons/education/Company";
import { GetAllITProfile } from "../../../../../utils/apiCalls";
import Modal from "../../../../Modal";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	width: inherit;

	@media screen and (max-width: 768px) {
		display: flex;
		margin-inline: 12px;
	}

	@media screen and (min-width: 1440px) {
		margin-inline: auto;
		max-width: 1440px;
	}
`;

const CardWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: ${spacing("sm1")};
	padding-block: ${spacing("lg")};
	padding-inline: ${spacing("md3")};
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.25);
	border-radius: 0 0 6px 6px;
	& > label {
		display: flex;
		height: 100%;

		color: ${color("pBlack")};
		font-family: Poppins;
		font-size: 19px;
		font-style: normal;
		font-weight: 400;
		line-height: 30px; /* 157.895% */
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: ${spacing("sm2")};
	}
`;

const Card = styled.div`
	cursor: pointer;
	grid-column: span 1;
	position: relative;
	border-radius: 6px;
	border: 1px solid #aba6a6;
	background: ${color("pWhite")};
	padding: ${spacing("md")};

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: ${spacing("md")};
	width: -webkit-fill-available;
	font-size: 14px;
`;

const RadioButton = styled.input`
	margin: 0;
	position: absolute;
	top: 14px;
	right: 14px;
	transform: translateY(50%);
`;

const cards = [
	{
		id: 1,
		icon: Client,
		name: "Client",
		desc: "I'm a client , hiring for a project or making a purchase ",
		link: "/it/client/signup",
	},
	{
		id: 2,
		icon: Freelancer,
		name: "Freelancer",
		desc: "I’m a freelancer, looking for work",
		link: "/it/freelancer/signup",
	},
	{
		id: 3,
		icon: Employee,
		name: "Employee",
		desc: "I'm a regular employee seeking to connect with like-minded individuals.",
		link: "/it/employee/signup",
	},
	{
		id: 4,
		icon: Company,
		name: "Company",
		desc: "We are company seeking clients interested in our range of products and services.",
		link: "/it/company/signup",
	},
];

const CardComponent = ({ selectedCard, setSelectedCard }) => {
	const handleRadioChange = (value) => {
		setSelectedCard(value);
	};

	return (
		<CardWrapper>
			{cards?.map((card) => (
				<label key={card?.id}>
					<Card>
						<RadioButton
							type="radio"
							name="card"
							value={card?.id}
							checked={selectedCard?.id === card?.id}
							onChange={() => handleRadioChange(card)}
						/>
						{card?.icon && <card.icon />}
						{card?.desc}
					</Card>
				</label>
			))}
		</CardWrapper>
	);
};

const InitialSignupForm = () => {
	const [selectedCard, setSelectedCard] = useState(null);
	const [usersData, setUsersData] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const apiCallExecuted = useRef(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (selectedCard) {
			const newFilteredUsers = usersData.filter((user) => user?.itProfileType === selectedCard?.name);
			setFilteredUsers(newFilteredUsers);
			setErrorMessage(selectedCard?.name);
		}
	}, [selectedCard]);

	const getItProfilesDetails = () => {
		setButtonDisable(true);
		GetAllITProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setUsersData(data);
				setTimeout(() => {
					setButtonDisable(false);
				}, 1000);
			} else {
				setButtonDisable(false);
			}
		});
	};

	useEffect(() => {
		if (!apiCallExecuted.current) {
			apiCallExecuted.current = true;
			getItProfilesDetails();
		}
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (filteredUsers.length > 0) {
			setShowModal(true);
		} else {
			navigate(selectedCard?.link);
			setButtonDisable(false);
		}
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const handleOk = () => {
		setShowModal(false);
	};

	return (
		<Wrapper>
			<FormWrapper initialForm onSubmit={handleSubmit}>
				<FormTitle
					style={{
						borderRadius: borderRadius(6),
					}}
				>
					<strong>Information Technology: </strong>Join as a Client , freelancer , Regular Employee or a company
				</FormTitle>
				<CardComponent selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
				<BtnWrapper>
					<SecondaryBtn label={"Back"} onClick={() => navigate(-1)} overrides={btnStyleOverrides} />
					<PrimaryBtn label={"Continue"} overrides={btnStyleOverrides} disable={buttonDisable} />
				</BtnWrapper>
			</FormWrapper>
			<Modal open={showModal} onClose={handleCloseModal} noIcon>
				<p style={{ fontSize: "1.5vw", padding: "1vw" }}>The {errorMessage} profile already exists.</p>
				<button
					style={{ color: "white", background: "#61BCAC", border: "none", padding: "0.5vw", borderRadius: "1vw", cursor:"pointer" }}
					onClick={handleOk}
				>
					OK
				</button>
			</Modal>
		</Wrapper>
	);
};

export default InitialSignupForm;
