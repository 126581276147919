import React, { useState } from "react";
import All from "../../assets/images/MB/all.png";
import Edu from "../../assets/images/MB/Education.png";
import Agriculture from "../../assets/images/MB/agriculture.png";
import Transport from "../../assets/images/MB/transport.png";
import Art from "../../assets/images/MB/art.png";
import Tech from "../../assets/images/MB/information.png";
import Finance from "../../assets/images/MB/financial.png";
import Healthcare from "../../assets/images/MB/transport2.png";
import Hospitality from "../../assets/images/MB/hospitality.png";
import Environment from "../../assets/images/MB/environmental.png";
import Construction from "../../assets/images/MB/construction.png";
import Realestate from "../../assets/images/MB/realestate.png";
import Professional from "../../assets/images/MB/professional.png";
import General from "../../assets/images/MB/general.png";
import TreeSelect from "../treeSelect";

const treeNodes = [
	{
		id: 1,
		name: "Industries",
		subNodes: [
			{
				id: 2,
				name: "All",
				icon: All,
			},
			{
				id: 3,
				name: "Education",
				icon: Edu,
			},
			{
				id: 4,
				name: "Agriculture",
				icon: Agriculture,
			},
			{
				id: 5,
				name: "Transport",
				icon: Transport,
			},
			{
				id: 6,
				name: "Art/Design",
				icon: Art,
			},
			{
				id: 7,
				name: "Information Tech",
				icon: Tech,
			},
			{
				id: 8,
				name: "Finance",
				icon: Finance,
			},
			{
				id: 9,
				name: "Healthcare",
				icon: Healthcare,
			},
			{
				id: 10,
				name: "Hospitality",
				icon: Hospitality,
			},
			{
				id: 11,
				name: "Environment",
				icon: Environment,
			},
			{
				id: 12,
				name: "Construction",
				icon: Construction,
			},
			{
				id: 13,
				name: "Realestate",
				icon: Realestate,
			},
			{
				id: 14,
				name: "Professional",
				icon: Professional,
			},
		],
	},
	{
		id: 15,
		name: "Ecommerce",
		subNodes: [
			{
				id: 16,
				name: "General Merchandise",
				icon: General,
			},
		],
	},
];

const RightSubNav = () => {
	const [isFilter, setIsFilter] = useState(1);

	const handleClick = (event, id) => {
		setIsFilter(id);
	};

	return <TreeSelect treeNodes={treeNodes} defaultExpanded={1} onChange={handleClick} />;
};

export default RightSubNav;
