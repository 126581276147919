import styled from "@emotion/styled";

const Title = styled.h4`
	color: #000;

	/* font-weight-bold-lg */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const Info = styled.p`
	color: #676464;

	/* Body-text-md */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export { Title, Info };
