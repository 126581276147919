const Index = ({ color = "#696969", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
			<path
				d="M12.7273 7.5C13.4578 4.53938 16.2169 3.02766 22.224 3.00001C22.3201 2.99963 22.4152 3.01878 22.504 3.05634C22.5928 3.0939 22.6735 3.14913 22.7414 3.21885C22.8093 3.28856 22.8631 3.37139 22.8997 3.46255C22.9363 3.55372 22.9549 3.65141 22.9545 3.75001V17.25C22.9545 17.4489 22.8776 17.6397 22.7406 17.7803C22.6036 17.921 22.4178 18 22.224 18C16.3799 18 14.1221 19.2098 12.7273 21M12.7273 7.5C11.9968 4.53938 9.23768 3.02766 3.23052 3.00001C3.13449 2.99963 3.03933 3.01878 2.95054 3.05634C2.86174 3.0939 2.78107 3.14913 2.71316 3.21885C2.64525 3.28856 2.59146 3.37139 2.55487 3.46255C2.51829 3.55372 2.49964 3.65141 2.50001 3.75001V17.1595C2.50001 17.6227 2.77943 18 3.23052 18C9.07468 18 11.3407 19.2188 12.7273 21M12.7273 7.5V21"
				stroke={color}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Index;
