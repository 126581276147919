import React from "react";
import location from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/IT/itprofileclient.png";
import editable from "../../../../../assets/images/editable.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";
import message from "../../../../../assets/icons/mi_message.png";
import outline from "../../../../../assets/icons/material-symbols_rate-review-outline.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserWorkLocationAndSkills,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	EducationHistory,
	EducationTitle,
	Education,
	SkillsWrapper,
	SkillsTitle,
	Skills,
	ProfileHeading,
	ManageOtherProfile,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfileModule,
	Butt,
} from "../../../styles/index.styled.js";
import { ProfilesWrapper } from "../../../../Agriculture/styles/index.styled.js";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../../../utils/queryController.js";
import Loader from "../../../../../components/loader/index.js";
import { connect } from "react-redux";

const EmployeePublic = ({ setActiveMBProfile, setActiveModule }) => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const { state } = useLocation();
	const userData = state?.user;
	const handlePublicProfileNav = (path) => {
		return;
		navigate(`/${path}`);
	};

	const { data, isLoading } = useQuery({
		queryKey: "AgricultureEmployeeProfile",
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getAgricultureEmployeeProfile?employeeId=${userData?.employeeId}&agricultureId=${userData?.agricultureId}`,
			);
			const { data } = response;
			return data;
		},
	});

	const { isLoading: isShortVideoAccountLoading, refetch: fetchShortVideos } = useQuery({
		queryKey: "AgriculturePrivateShortVideosProfile",
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getPrivateAgricultureShortVideoProfile?agricultureId=${data?.agricultureId}`,
			);
			const { data: profileData, status } = response;
			if (status === 200) {
				navigate("/shorts/profile/public", {
					state: {
						user: profileData,
					},
				});
			}
			return data;
		},
		enabled: false,
	});

	const handleMBRedirection = () => {
		setActiveModule("ag");
		setActiveMBProfile(data?.agricultureId);
		navigate("/microblogging/profile/public");
	};

	if (isLoading || isShortVideoAccountLoading) {
		return <Loader />;
	}

	return (
		<>
			{token ? (
				<Wrapper>
					<ProfileWrapper>
						<ProfileSections>
							<SectionTitle>Regular Employee</SectionTitle>
							<Profile>
								<ProfileDetailsWrapper>
									<ProfileImageWrapper>
										<img src={data?.ProfilePicture || ProfilePicture} alt="profile" />
									</ProfileImageWrapper>
									<UserDetailsWrapper>
										<UserNameWrapper>
											<UserName>{data?.fullName || "Anonymous User"}</UserName>
										</UserNameWrapper>
										<UserAddress>
											<img src={location} alt="location-icon" />
											{data?.location || "Location not available"}
										</UserAddress>
									</UserDetailsWrapper>
								</ProfileDetailsWrapper>
								<UserProfileActions>
									<Button buttonClick={() => handlePublicProfileNav("message")}>
										<img src={message} alt="message" />
										Message
									</Button>
									<Button buttonClick={() => handlePublicProfileNav("review")}>
										<img src={outline} alt="review" />
										Review
									</Button>
								</UserProfileActions>
							</Profile>
							<MoreDetailWrapper>
								<ProfilesAndAboutWrapper>
									<ProfilesWrapper>
										<Profiles>
											<ProfileHeading>Other Profiles</ProfileHeading>
											<ProfileModule>
												<Butt onClick={handleMBRedirection}>Microblogging</Butt>
												<Butt onClick={fetchShortVideos}>ShortVideos</Butt>
											</ProfileModule>
											<ProfileList>
												{data?.userName && (
													<ProfileName>
														<ProfileUserName>userName </ProfileUserName>
														{data?.userName}
													</ProfileName>
												)}
												{data?.shortVideoUsername && (
													<ProfileName>
														<ProfileUserName>shortVideoUsername </ProfileUserName>
														{data?.shortVideoUsername}
													</ProfileName>
												)}
											</ProfileList>
										</Profiles>
									</ProfilesWrapper>
									<About>
										<AboutTitle>About</AboutTitle>
										<AboutDesc>{data?.about || "No Data available"}</AboutDesc>
										<More>more...</More>
										<EducationHistory>
											<EducationTitle>Education History</EducationTitle>
											<Education>
												<span>{data?.educationDegree}</span>
												<span>{data?.educationInstitution}</span>
												<span>{data?.gradYear}</span>
											</Education>
										</EducationHistory>
										<SkillsWrapper>
											<SkillsTitle>Skills</SkillsTitle>
											<Skills>{data?.skill || "No Skills available"}</Skills>
										</SkillsWrapper>
									</About>
								</ProfilesAndAboutWrapper>
								<FeedbackWrapper>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment1}
											commentimagestyle={{
												marginLeft: "2rem",
											}}
											commenttitle="Thanks"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment}
											commenttitle="Reply"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
								</FeedbackWrapper>
							</MoreDetailWrapper>
						</ProfileSections>
					</ProfileWrapper>
				</Wrapper>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(EmployeePublic);
