import React from "react";
import PropTypes from "prop-types";
import "./index.scss";
import loader from "../../assets/icons/loading.png";
import { CircularProgress } from "@mui/material";
import { color } from "../../styles/variables";

const Loader = ({ size = "medium" }) => {
	const sizes = {
		small: 20,
		medium: 40,
		large: 60,
	};

	return (
		<div
			style={{
				display: "flex",
				justifyContent: "center",
				alignItems: "center",
			}}
		>
			<CircularProgress
					size={sizes[size]}
				style={{
					color: color("pGreen"),
				}}
			/>
		</div>
	);
};

Loader.propTypes = {
	isSmallLoader: PropTypes.bool,
	tableLoader: PropTypes.bool,
};

Loader.defaultProps = {
	isSmallLoader: false,
	tableLoader: false,
};

export default Loader;
