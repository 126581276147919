import InputField from "../../../../Inputs/Input";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";
import Textarea from "../../../../Inputs/Textarea";
import { FormWrapper, FormTitleInfo, FormRowWrapper, Form, BtnWrapper, btnStyleOverrides, FormTitle } from "../index.styled";
import PrimaryBtn from "../../../../Buttons/Primary";
import SecondaryBtn from "../../../../Buttons/Secondary";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Autocomplete from "../../../../Inputs/Autocomplete";

const validationSchema = Yup.object({
	companyName: Yup.string().required("Company Name is required"),
});

const CompanySignUpForm = ({ setPicture, onSubmit }) => {
	const navigate = useNavigate();
	const handleBack = () => {
		navigate(-1);
	};

	const formik = useFormik({
		initialValues: {
			companyName: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onSubmit(values);
		},
	});
	return (
		<FormWrapper onSubmit={formik.handleSubmit} onBlur={formik.handleBlur} onChange={formik.handleChange}>
			<FormTitle>Provide Your Company Information</FormTitle>
			<Form>
				<FormTitleInfo>Company Basic Information</FormTitleInfo>
				<FormRowWrapper>
					<InputField
						name={"companyName"}
						label={"Company Name"}
						required
						error={formik.touched.companyName && Boolean(formik.errors.companyName)}
						helperText={formik.touched.companyName && formik.errors.companyName}
					/>
					<InputField
						name={"typeOfBusiness"}
						label={"Type of Business"}
						placeholder={"LLC, Corporation, Partnership"}
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"industrySector"} label={"Industry Sector"} />
					<InputField name={"companySize"} label={"Company Size"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<Autocomplete
						name={"location"}
						label={"Location"}
						placeholder={"eg. Austria"}
						onChange={formik.setFieldValue}
					/>
					<InputFieldWithUpload onChange={setPicture} name={"file_upload"} label={"Company Logo"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"websiteURL"} label={"Website URL"} />
				</FormRowWrapper>
				<FormRowWrapper childCount={1}>
					<Textarea name={"about"} label={"About"} rows={4} mobileRows={8} />
				</FormRowWrapper>
			</Form>
			<BtnWrapper>
				<SecondaryBtn label={"Back"} onClick={handleBack} overrides={btnStyleOverrides} />
				<PrimaryBtn label={"Next"} overrides={btnStyleOverrides} />
			</BtnWrapper>
		</FormWrapper>
	);
};

export default CompanySignUpForm;
