import React from "react";
import Heading from "../../components/Heading/Heading";
import icon from "../../assets/icons/e-commerce.png";
import { Checkbox, FormControlLabel, Slider } from "@mui/material";
import "./ECommerce.scss";
import lgtv from "../../assets/images/lgtv.png";
import marq from "../../assets/images/marqtv.png";
import star from "../../assets/icons/Star 21.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Navigate } from "react-router-dom";

function valuetext(value) {
	return `${value}°C`;
}

const ECommerce = () => {
	const [value, setValue] = React.useState([0, 300]);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<>
			{token ? (
				<div className="ecommerce">
					<Heading icon={icon} title={"Ecommerce"} showSearchInput={true} />
					<div className="ecommerce_container">
						<div className="ecommerce_container_slider">
							<div className="ecommerce_container_slider_header">
								<p>Filters</p>
							</div>
							<div className="ecommerce_container_slider_heading">
								<p>Price</p>
							</div>
							<div className="ecommerce_container_slider_slider">
								<Slider
									getAriaLabel={() => "Temperature range"}
									value={value}
									onChange={handleChange}
									valueLabelDisplay="auto"
									min={0}
									step={10}
									max={300}
									getAriaValueText={valuetext}
								/>
							</div>
							<div className="ecommerce_container_slider_input">
								<input value={"Min"} />
								<p>to</p>
								<input value={"60000+"} />
							</div>
							<div className="ecommerce_container_slider_heading">
								<p>Brand</p>
							</div>
							<div className="ecommerce_container_slider_brand">
								<input placeholder="Search Brand" />
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="Samsung" />
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="Sony" />
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="LG" />
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="Mi" />
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="Motorolla" />
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="Hisense" />
							</div>
							<div className="ecommerce_container_slider_head">
								<p>Customer Ratings</p>
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="4* & above" />
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="3* & above" />
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="2* & above" />
							</div>
							<div className="ecommerce_container_slider_brand_checkbox">
								<FormControlLabel disabled control={<Checkbox />} label="1* & above" />
							</div>
						</div>
						<div className="ecommerce_container_header">
							<div className="ecommerce_container_filters">
								<h3>Filters</h3>
							</div>
							<div className="ecommerce_container_sorting">
								<h3>Sort by</h3>
								<ExpandMoreIcon />
							</div>
						</div>
						<div className="ecommerce_container_rightdata">
							<div className="ecommerce_container_rightdata_sorting">
								<p className="sort">Sort By</p>
								<p className="price">Price - Low to High</p>
								<p>Price - High to Low</p>
								<p>Discounts</p>
							</div>
							<div className="ecommerce_container_rightdata_data">
								<div className="ecommerce_container_rightdata_data_image">
									<img src={lgtv} alt="lgtv" />
								</div>
								<div className="ecommerce_container_rightdata_data_details">
									<div className="ecommerce_container_rightdata_data_details_leftside">
										<div className="ecommerce_container_rightdata_data_details_leftside_heading">
											<p>
												Mi A series 108cm(43 inch) Full HD LED Smart Google TV 2023 Edition with FHD |
												Dolby Audio | DTS : H...
											</p>
										</div>
										<div className="ecommerce_container_rightdata_data_details_leftside_rating">
											<div className="ecommerce_container_rightdata_data_details_leftside_rating_star">
												<p>4.4</p>
												<img src={star} alt="star" />
											</div>
											<div className="ecommerce_container_rightdata_data_details_leftside_rating_data">
												<p>4,73,066 Ratings</p>
											</div>
										</div>
										<div className="ecommerce_container_rightdata_data_details_leftside_rating_details">
											<ul>
												<li>Operating System: Google TV</li>
												<li>Full HD 1920 x 1080 Pixels</li>
												<li>Launch Year: 2023</li>
												<li>1 Year Warranty on Product and 2 Years Warranty on Panel</li>
											</ul>
										</div>
									</div>
									<div className="ecommerce_container_rightdata_data_details_rightside">
										<div className="ecommerce_container_rightdata_data_details_rightside_left">
											{" "}
											<div className="ecommerce_container_rightdata_data_details_rightside_pricing">
												<p>$21,999</p>
											</div>
											<div className="ecommerce_container_rightdata_data_details_rightside_offer">
												<p className="strike">35,999</p>
												<p>38% off</p>
											</div>
										</div>
										<div className="ecommerce_container_rightdata_data_details_rightside_right">
											<div className="ecommerce_container_rightdata_data_details_rightside_launch">
												<p>Lowest price since launch</p>
											</div>
											<div className="ecommerce_container_rightdata_data_details_rightside_bottom">
												<p>upto 1400 on exchange</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="ecommerce_container_rightdata_data">
								<div className="ecommerce_container_rightdata_data_image">
									<img src={marq} alt="lgtv" />
								</div>
								<div className="ecommerce_container_rightdata_data_details">
									<div className="ecommerce_container_rightdata_data_details_leftside">
										<div className="ecommerce_container_rightdata_data_details_leftside_heading">
											<p>MarQ 60cm(24 inch) HD Ready LED TV</p>
										</div>
										<div className="ecommerce_container_rightdata_data_details_leftside_rating">
											<div className="ecommerce_container_rightdata_data_details_leftside_rating_star">
												<p>4.2</p>
												<img src={star} alt="star" />
											</div>
											<div className="ecommerce_container_rightdata_data_details_leftside_rating_data">
												<p>3,831 Ratings</p>
											</div>
										</div>
										<div className="ecommerce_container_rightdata_data_details_leftside_rating_details">
											<ul>
												<li>HD Ready 1366 x 768 Pixels</li>
												<li>Launch Year: 2022</li>
												<li>1 Year Warranty</li>
											</ul>
										</div>
									</div>
									<div className="ecommerce_container_rightdata_data_details_rightside">
										<div className="ecommerce_container_rightdata_data_details_rightside_left">
											<div className="ecommerce_container_rightdata_data_details_rightside_pricing">
												<p>$5,999</p>
											</div>
											<div className="ecommerce_container_rightdata_data_details_rightside_offer">
												<p className="strike">35,999</p>
												<p>38% off</p>
											</div>
										</div>
										<div className="ecommerce_container_rightdata_data_details_rightside_right">
											<div className="ecommerce_container_rightdata_data_details_rightside_launch">
												<p>Lowest price since launch</p>
											</div>
											<div className="ecommerce_container_rightdata_data_details_rightside_bottom">
												<p>upto 1400 on exchange</p>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default ECommerce;
