import React from "react";
import { Route, Routes } from "react-router-dom";

import TermsAndConditions from "../pages/TermsAndConditions/TermsAndConditions";
import PrivacyPolicy from "../pages/PrivacyPolicy/PrivacyPolicy";
import Chatbot from "../pages/Chatbot/Chatbot";
import Resetpassword from "../pages/ResetPassword";
import VerifyEmail from "../pages/VerifyEmail";
import ConfirmEmail from "../pages/ConfirmEmail";
import PrivateProfile from "../pages/PrivateProfile/PrivateProfile";
import PublicProfile from "../pages/PublicProfile/PublicProfile";
import PublicFriendrequest from "../pages/PublicFriendrequest/PublicFriendrequest";
import PrivateFriendrequest from "../pages/PrivateFriendrequest/PrivateFriendrequest";
import Messaging from "../pages/Messaging";
import SearchComponent from "../pages/SearchComponent/SearchComponent";
import Home from "../pages/Home/Home";
import EngineeringSignUp from "../pages/EngineeringSignUp/EngineeringSignUp";
import Banking from "../pages/Banking/Banking";
import Engineering from "../pages/Engineering/Engineering";
import Engineeringsignup2 from "../pages/EngineeringSignUp2/EngineeringSignUp2";
import ECommerce from "../pages/E-Commerce/ECommerce";
import MicroBlogging from "../components/IT/Microblogging";
import EngineeringFreelanceProfile from "../pages/EngineeringFreelanceProfile/EngineeringFreelanceProfile";
import EngineeringHome from "../pages/EngineeringHome/EngineeringHome";
import Profiles from "../components/Profiles/index";

const SubRoutes = () => {
	return (
		<Routes>
			<Route path="/home" element={<Home />} />
			<Route path="/publicprofile" element={<PublicProfile />} />
			<Route path="/privateprofile" element={<PrivateProfile />} />
			<Route path="/publicfriendrequest" element={<PublicFriendrequest />} />
			<Route path="/privatefriendrequest" element={<PrivateFriendrequest />} />
			<Route path="/e-commerce" element={<ECommerce />} />
			<Route path="/engineering-signup" element={<EngineeringSignUp />} />
			<Route path="/engineering" element={<Engineering />} />
			<Route path="/engineering-signup2" element={<Engineeringsignup2 />} />
			<Route path="/engineering-home" element={<EngineeringHome />} />
			<Route path="/banking" element={<Banking />} />
			<Route path="/micro-blogging" element={<MicroBlogging />} />
			<Route path="/terms-and-conditions" element={<TermsAndConditions />} />
			<Route path="/privacy-policy" element={<PrivacyPolicy />} />
			<Route path="/engineering-freelance-profile" element={<EngineeringFreelanceProfile />} />
			<Route path="/chatbot" element={<Chatbot />} />
			<Route path="/reset-password" element={<Resetpassword />} />
			<Route path="/verify" element={<VerifyEmail />} />
			<Route path="/confirm" element={<ConfirmEmail />} />
			<Route path="/messaging" element={<Messaging />} />
			<Route path="/search" element={<SearchComponent />} />
			<Route path="/profiles" element={<Profiles />} />
		</Routes>
	);
};

export default SubRoutes;
