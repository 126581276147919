import { BaseURL } from "./api-constant";

export const query = async (method, module, url, body, noContentType = false, authorize = true) => {
	const headers = {};
	if (authorize) {
		const token = localStorage.getItem("token") || sessionStorage.getItem("token");
		headers["Authorization"] = `Bearer ${token}`;
		if (!noContentType && (method === "POST" || method === "PUT" || method === "DELETE") && body) {
			headers["Content-Type"] = "application/json";
		}
	}

	const fetchOptions = {
		method,
		headers,
		body: !noContentType ? JSON.stringify(body) : body,
	};

	try {
		let endpoint = "";
		switch (module) {
			case "IT":
				endpoint = `${BaseURL.IT_URL}${url}`;
				break;

			case "MB":
				endpoint = `${BaseURL.MB_URL}${url}`;
				break;

			case "PROFILE":
				endpoint = `${BaseURL.PROFILE_URL}${url}`;
				break;

			case "EDUCATION":
				endpoint = `${BaseURL.EDUCATE_PROFILE_URL}${url}`;
				break;

			case "EDUCATIONCREATE":
				endpoint = `${BaseURL.EDUCATE_CREATE_URL}${url}`;
				break;

			case "AG":
				endpoint = `${BaseURL.AGRICULTURE_URL}${url}`;
				break;

			case "AG_PROFILE":
				endpoint = `${BaseURL.AGRICULTURE_PROFILE_URL}${url}`;
				break;

			default:
				endpoint = `${BaseURL.BASE_URL}/${url}`;
				break;
		}

		const response = await fetch(endpoint, fetchOptions);

		if (!response.ok) {
			throw new Error(`HTTP error! Status: ${response.status}`);
		}

		return response.json();
	} catch (error) {
		console.error("Request failed:", error.message);
		throw error; // Re-throw the error to be handled by the caller
	}
};
