import styled from "@emotion/styled";
import { spacing } from "../styles/variables";

const Page = ({ children, overrides = undefined }) => {
	const Wrapper = styled.div`
		margin-inline: ${spacing("xxxl1")};
		padding-block: ${spacing("lg")};

		display: grid;
		grid-template-columns: repeat(12, 1fr);
		grid-column-gap: ${spacing("lg")};

		@media screen and (max-width: 768px) {
			display: flex;
			margin-inline: 0;
			padding-block: ${spacing("md")};
			grid-column-gap: 0;
		}

		@media screen and (min-width: 1440px) {
			max-width: 1440px;
			margin-inline: auto;
		}

		&::-webkit-scrollbar {
			display: none;
		}

		${{ ...overrides }}
	`;
	return <Wrapper>{children}</Wrapper>;
};

export default Page;
