import styled from "@emotion/styled";
import { color, typography } from "../../../styles/variables";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	justify-content: center;
	flex-direction: column;
	align-items: center;
	margin-block: 40px;

	@media screen and (max-width: 768px) {
		margin-block: 0;
	}
	@media screen and (min-width: 1440px) {
		max-width: 1440px;
		margin-inline: auto;
	}
`;
const BloggingWrapper = styled.form`
	grid-column: 3 / span 8;
	max-width: 740px;
	margin-inline: auto;

	@media screen and (max-width: 768px) {
		border: none;
		background-color: unset;
		margin: 4px 8px;
		grid-column: 1/-1;
		border-radius: 6px;
		overflow: hidden;

		& > * {
			grid-column: span 12;
		}
	}
`;

const BloggingTitle = styled.div`
	grid-row: 1;
	background-color: ${color("pGreen")};
	padding: 14px 20px;
	border-radius: 6px 6px 0 0;
	color: ${color("pWhite")};

	@media screen and (max-width: 768px) {
		padding: 16px 8px;
		color: #fff;
		text-align: center;
		font-family: Poppins;
		font-size: 26px;
		font-style: normal;
		font-weight: 400;
		line-height: 29px; /* 111.538% */
	}
`;

const DetailsWrapper = styled.div`
	grid-row: 2;
	border-radius: 0 0 6px 6px;
	border: 1px solid #d6cece;
	border-top: none;
	padding: 20px;
	@media screen and (max-width: 768px) {
		padding: 30px 12px;
		padding-block-end: 52px;
	}
`;

const BloggingInfo = styled.p`
	margin-bottom: 44px;
	@media screen and (max-width: 768px) {
		margin-block-end: 36px;
	}
`;

const SubHeaders = styled.h3`
	color: #000;

	${typography("body", "lg", false)}

	margin-bottom: 24px;
	@media screen and (max-width: 768px) {
		margin-block-end: 22px;
	}
`;

const SubDesc = styled.h6`
	color: #000;
	${typography("body", "md", false)}
`;

const TextAreaWrapper = styled.div`
	position: relative;
	margin-block: 24px 36px;
	@media screen and (max-width: 768px) {
		margin-block-start: 14px;
		margin-block-end: 32px;
	}
`;

const TextArea = styled.textarea`
	resize: none;
	width: -webkit-fill-available;
	padding-inline: 20px;
	padding-block: 20px 36px;
	border-radius: 8px;
	border: 1px solid #cdcdcd;
	position: relative;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	&::placeholder {
		color: #979797;
	}
	@media screen and (max-width: 768px) {
		padding-inline: 16px;
		padding-block: 26px;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
`;

const CharacterCount = styled.span`
	position: absolute;
	bottom: 12px;
	right: 12px;
	color: #928a8a;
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Username = styled.input`
	width: 40%;
	border-radius: 8px;
	border: 1px solid #cdcdcd;

	margin-block-start: 8px;
	padding: 10px 8px;
	@media screen and (max-width: 768px) {
		width: -webkit-fill-available;
	}
`;

const BloggingProfileBtnWrapper = styled.div`
	grid-row: 3;
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin-block: 48px;

	& > button {
		/* button text white */
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		padding: 8px 40px;
	}

	@media screen and (max-width: 768px) {
		justify-content: center;
		flex-direction: column-reverse;
		align-items: center;
		margin-top: 20px;
		width: 100%;
		row-gap: 12px;
		margin-block-end: 44px;
	}
`;

const BloggingProfileBtn = styled.button`
	cursor: pointer;
	min-width: 10vw;
	width: fit-content;
	border-radius: 70px;
	border: none;
	padding: 6px 36px;
	color: #2b2a2a;
	@media screen and (max-width: 768px) {
		width: inherit;
	}
`;

const BackBtnStyles = {
	backgroundColor: "#d9d9d9",
	color: "#000000",
	// borderRadius: borderRadius(40),

	"@media screen and (max-width: 758px)": {
		width: "100%",
	},
};

const NextBtnStyles = {
	backgroundColor: "#61bcac",
	color: "#ffffff",
	// borderRadius: borderRadius(40),

	"@media screen and (max-width: 758px)": {
		width: "100%",
	},
};

export {
	Wrapper,
	BloggingWrapper,
	BloggingTitle,
	DetailsWrapper,
	BloggingInfo,
	SubHeaders,
	SubDesc,
	TextAreaWrapper,
	TextArea,
	CharacterCount,
	Username,
	BloggingProfileBtnWrapper,
	BloggingProfileBtn,
	BackBtnStyles,
	NextBtnStyles,
};
