import React, { useState, useEffect } from "react";
import "../MBPublicProfile.scss";
import Home from "../../../../assets/images/MB/home.png";
import Explore from "../../../../assets/images/MB/explore.png";
import Notifications from "../../../../assets/images/MB/notifications.png";
import Messages from "../../../../assets/images/MB/messages.png";
import { NavLink, Navigate, useLocation } from "react-router-dom";
import mbpic1 from "../../../../assets/icons/public/blog1.png";
import Search from "../../../../assets/images/MB/search.png";

import Post from "../../../../components/MB/Post";
import {
	EduWrapperLeft,
	EduWrapperCenter,
	EduWrapperRight,
	Wrapper,
	MobileBottom,
	MobileBottomIcons,
} from "../../styles/index.styled";
import LeftSubNav from "../../../../components/LeftSubNav";
import MBProfileDetails from "../../../../components/MB/MBProfileDetails";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MBLeftOther from "../../../../components/MB/MBLeftOther/MBLeftOther";
import MBRightTrend from "../../../../components/MB/MBRightTrend/MBRightTrend";
import { spacing } from "../../../../styles/variables";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../../utils/queryController";
import Loader from "../../../../components/loader";
import MBMobileBottom from "../../../../components/MB/MBMobileBottom/MBMobileBottom";
import Page from "../../../../layouts/Page";

const MBPublicProfile = ({ activeId }) => {
	const { state } = useLocation();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 760);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	const { isLoading, data, refetch } = useQuery({
		queryKey: ["MBProfile", activeId],
		queryFn: async () => {
			let endpoint = state?.endpoint
				? `${state?.endpoint}?microbloggingId=${activeId}`
				: `getPublicMicrobloggingProfile?itProfileId=${activeId}`;
			const response = await query("GET", state?.endpoint ? "MB" : "PROFILE", endpoint);
			return response.data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	return (
		<>
			{token ? (
				<>
					{isLoading ? (
						<Loader />
					) : (
						<>
							<Page>
								<EduWrapperLeft>
									<LeftSubNav lists />
									<MBLeftOther visible={!isMobile} />
								</EduWrapperLeft>
								<EduWrapperCenter>
									<MBProfileDetails
										profilePicture={data?.profilePicture}
										userName={data?.fullName || data?.userName || data?.microbloggingUsername}
									/>
									<div className="tabsection">
										<Tabs>
											<TabList
												style={{
													marginBlockEnd: spacing("lg1"),
												}}
											>
												<Tab>Posts</Tab>
											</TabList>

											<TabPanel
												style={{
													display: "flex",
													flexDirection: "column",
													rowGap: spacing("lg1"),
												}}
											>
												<MBLeftOther visible={isMobile} />
												<Post
													heading={"Michelle"}
													name="Education"
													content="Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting gas the Jews"
													image={mbpic1}
												/>
												<Post
													heading={"Michelle"}
													name="Education"
													content="Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting gas the Jews.Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hama"
												/>
											</TabPanel>
										</Tabs>
									</div>
								</EduWrapperCenter>
								<EduWrapperRight>
									<MBRightTrend />
								</EduWrapperRight>
							</Page>
							<MBMobileBottom />
						</>
					)}
				</>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		activeId: state?.mb?.activeProfileId,
	};
};

export default connect(mapStateToProps)(MBPublicProfile);
