import Heading from "../../../Heading/Heading";
import icon from "../../../../assets/icons/transportation/transportation";
import PropType from "prop-types";
import { Wrapper, FormWrapper, FormTitle, Form, BtnWrapper } from "./index.styled";
import { Fragment, useEffect, useState } from "react";
import PrimaryBtn from "../../../Buttons/Primary";
import SecondaryBtn from "../../../Buttons/Secondary";
import { spacing, borderRadius } from "../../../../styles/variables";
import { useNavigate } from "react-router-dom";

const btnStyleOverrides = {
	borderRadius: borderRadius(70),
	textTransform: "none",
	paddingInline: spacing("xxxl"),
	paddingBlock: spacing("sm"),
};

const SignUp = ({ userType, children, onSubmit, onBack = undefined, nextBtnLabel = "Next", backBtnLabel = "Back" }) => {
	const navigate = useNavigate();
	const [formValues, setFormValues] = useState({});
	const handleFormValues = (event) => {
		setFormValues((prevValues) => {
			return {
				...prevValues,
				[event.target.name]: event.target.value,
			};
		});
	};
	const handleFormSubmit = (event) => {
		event.preventDefault();
		onSubmit(formValues);
	};

	const handleBack = () => {
		if (onBack) onBack();
		else navigate(-1);
	};

	return (
		<Fragment>
			<Heading icon={icon} title={"Transportation"} showSearchInput={false} />
			<Wrapper>
				<FormWrapper onSubmit={handleFormSubmit} onChange={handleFormValues}>
					<FormTitle>Provide Your {userType} Information</FormTitle>
					<Form>{children}</Form>
					<BtnWrapper>
						<SecondaryBtn label={backBtnLabel} onClick={handleBack} overrides={btnStyleOverrides} />
						<PrimaryBtn label={nextBtnLabel} overrides={btnStyleOverrides} />
					</BtnWrapper>
				</FormWrapper>
			</Wrapper>
		</Fragment>
	);
};

SignUp.propTypes = {
	userType: PropType.string.isRequired,
	children: PropType.node.isRequired,
	onSubmit: PropType.func,
	onBack: PropType.func,
	nextBtnLabel: PropType.string,
	backBtnLabel: PropType.string,
};

export default SignUp;
