import { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { FormTitle, FormWrapper, BtnWrapper, btnStyleOverrides } from "../index.styled";
import { borderRadius, color, spacing } from "../../../../../styles/variables";
import PrimaryBtn from "../../../../Buttons/Primary";
import SecondaryBtn from "../../../../Buttons/Secondary";
import { useNavigate } from "react-router-dom";
import Client from "../../../../../assets/icons/education/Client";
import Freelancer from "../../../../../assets/icons/education/Freelancer";
import Employee from "../../../../../assets/icons/education/Employee";
import Company from "../../../../../assets/icons/education/Company";
import { connect } from "react-redux";
import Modal from "../../../../Modal";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);

	@media screen and (max-width: 768px) {
		display: flex;
		margin-inline: 12px;
	}

	@media screen and (min-width: 1440px) {
		margin-inline: auto;
		max-width: 1440px;
	}
`;

const CardWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: ${spacing("sm1")};
	padding-block: ${spacing("lg")};
	padding-inline: ${spacing("md3")};
	box-shadow: 0 4px 24px 0 rgba(0, 0, 0, 0.25);
	border-radius: 0 0 6px 6px;
	& > label {
		display: flex;
		height: 100%;

		color: ${color("pBlack")};
		font-family: Poppins;
		font-size: 19px;
		font-style: normal;
		font-weight: 400;
		line-height: 30px; /* 157.895% */
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: ${spacing("sm2")};
	}
`;

const Card = styled.div`
	cursor: pointer;
	grid-column: span 1;
	position: relative;
	border-radius: 6px;
	border: 1px solid #aba6a6;
	background: ${color("pWhite")};
	padding: ${spacing("md")};

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: ${spacing("md")};
	width: -webkit-fill-available;
	font-size: 14px;
`;

const RadioButton = styled.input`
	margin: 0;
	position: absolute;
	top: 20px;
	right: 20px;
	transform: translateY(50%);
`;

const ErrorMessage = styled.p`
	color: #000;

	/* Body-text-md */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	margin-block: 24px;
	text-align: center;
`;

const cards = [
	{
		id: 1,
		icon: Client,
		name: "Client",
		desc: "I'm a client in the agriculture sector, hiring for fieldwork assistance and expertise in farming practices, crop management, and sustainable agriculture.",
		link: "/agriculture/client/signup",
	},
	{
		id: 2,
		icon: Freelancer,
		name: "Freelancer",
		desc: "I'm a freelancer specializing in agronomy, actively seeking opportunities in fieldwork, farming, and related agricultural endeavors.",
		link: "/agriculture/freelancer/signup",
	},
	{
		id: 3,
		icon: Employee,
		name: "Employee",
		desc: "I'm a regular employee in the agriculture sector, seeking to connect with like-minded individuals in the field of agriculture.",
		link: "/agriculture/employee/signup",
	},
	{
		id: 4,
		icon: Company,
		name: "Company",
		desc: "We are an agriculture-focused company seeking clients interested in our diverse range of agricultural products and services.",
		link: "/agriculture/company/signup",
	},
];

const InitialSignupForm = ({ profiles }) => {
	const [selectedCard, setSelectedCard] = useState(null);
	const [availableProfiles, setAvailableProfiles] = useState(null);
	const [showModal, setShowModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState(null);
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		if (availableProfiles?.includes(selectedCard?.name)) {
			setErrorMessage(`You are already registered as a ${selectedCard?.name}. Please try again with a different profile.`);
			setShowModal(true);
		} else {
			setErrorMessage(null);
			navigate(selectedCard?.link);
		}
	};

	useEffect(() => {
		if (!availableProfiles?.length && profiles?.length) {
			let tempProfiles = [];
			for (let profile of profiles) {
				tempProfiles.push(profile?.accountType);
			}
			setAvailableProfiles(tempProfiles);
		}
	}, [profiles]);

	const CardComponent = ({ selectedCard, setSelectedCard }) => {
		const handleRadioChange = (value) => {
			setSelectedCard(value);
		};

		return (
			<CardWrapper>
				{cards?.map((card) => (
					<label key={card?.id}>
						<Card>
							<RadioButton
								type="radio"
								name="card"
								value={card?.id}
								checked={selectedCard?.id === card?.id}
								onChange={() => handleRadioChange(card)}
							/>
							{card?.icon && <card.icon />}
							{card?.desc}
						</Card>
					</label>
				))}
			</CardWrapper>
		);
	};

	const handleModalClose = () => {
		setShowModal(false);
	};

	return (
		<Wrapper>
			<Modal open={showModal} title={"Please try with a different profile"} onClose={handleModalClose}>
				<ErrorMessage>{errorMessage}</ErrorMessage>
				<PrimaryBtn
					overrides={{
						width: "max-content",
						marginInline: "auto",
					}}
					label={"Close"}
					onClick={handleModalClose}
				/>
			</Modal>
			<FormWrapper initialForm onSubmit={handleSubmit}>
				<FormTitle
					style={{
						borderRadius: borderRadius(6),
					}}
				>
					<strong>Agriculture: </strong>Join as a Client , freelancer , Regular Employee or a company
				</FormTitle>
				<CardComponent selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
				<BtnWrapper>
					<SecondaryBtn label={"Back"} onClick={() => navigate(-1)} overrides={btnStyleOverrides} />
					<PrimaryBtn label={"Continue"} overrides={btnStyleOverrides} />
				</BtnWrapper>
			</FormWrapper>
		</Wrapper>
	);
};

const mapStateToProps = (state) => {
	return {
		profiles: state.ag.profiles,
	};
};

export default connect(mapStateToProps)(InitialSignupForm);
