import styled from "@emotion/styled";
import Background from "../../assets/images/login/background.svg";
import MobileBackground from "../../assets/images/login/mobile/background.svg";

const Wrapper = styled.div`
	min-height: 100dvh;
	background-color: #f8fdfb;
	background-image: url(${Background});
	background-size: cover;
	background-repeat: no-repeat;
	display: flex;
	flex-direction: column;
	background-position: center;
	@media screen and (max-width: 768px) {
		background-image: url(${MobileBackground});
		background-size: contain;
	}
`;

const FormWrapper = styled.div`
	margin: 48px;
	flex: 1;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	align-items: center;
	@media screen and (max-width: 768px) {
		display: flex;
		margin-inline: 20px;
	}
`;

export { Wrapper, FormWrapper };
