import { Box, Divider } from "@mui/material";
import { Author, ModuleName, Title } from "./index.styled";

const topics = [
	{
		module: "Information Technology",
		title: "I spent the morning with the Apple Vision Pro",
		author: "John Doe",
	},
	{
		module: "Education",
		title: "The future of AI in the workplace",
		author: "John Doe",
	},
	{
		module: "Information Technology",
		title: "I spent the morning with the Apple Vision Pro",
		author: "John Doe",
	},
	{
		module: "Information Technology",
		title: "This is something that had to happen for all this time...",
		author: "John Doe",
	},
];

const TrendingTopics = () => {
	return (
		<Box display={"flex"} flexDirection={"column"} rowGap={"24px"}>
			{topics?.map((topic, index) => (
				<Box
					key={index}
					display={"flex"}
					flexDirection={"column"}
					rowGap={"8px"}
					sx={{
						"&:last-of-type .MuiDivider-root": {
							display: "none",
						},
					}}
				>
					<ModuleName>{topic?.module}</ModuleName>
					<Title>{topic?.title}</Title>
					<Author>{topic?.author}</Author>
					<Divider sx={{ mt: "16px" }} />
				</Box>
			))}
		</Box>
	);
};

export default TrendingTopics;
