import { useState, Fragment } from "react";
import InputField from "../../../../../Inputs/Input";
import { FormContainer, FormTitleInfo, FormRowWrapper, CheckboxWrapper } from "../../index.styled";
import { FormControlLabel, FormGroup, Checkbox } from "@mui/material";

const skills = [
	{
		id: 1,
		name: "Maths",
		placeholder: "e.g. Algebra, Calculus, Geometry, Statistics, etc.",
	},
	{
		id: 2,
		name: "Computer",
		placeholder: "e.g. Programming languages, Software applications, etc.",
	},
	{
		id: 3,
		name: "Science",
		placeholder: "e.g. Biology, Chemistry, Physics, Engineering fields, etc.",
	},
	{
		id: 4,
		name: "Business",
		placeholder: "e.g. Economics, Finance, Marketing, etc.",
	},
	{
		id: 5,
		name: "Language",
		placeholder: "e.g. Spanish, French, Chinese, ESL/ESOL, etc.",
	},
	{
		id: 6,
		name: "History",
		placeholder: "e.g. Various historical periods and regions.",
	},
	{
		id: 7,
		name: "Test Preparation",
		placeholder: "e.g. SAT, ACT, GRE, MCAT, etc.",
	},
	{
		id: 8,
		name: "Music",
		placeholder: "e.g. Different instruments, Music theory, etc.",
	},
	{
		id: 9,
		name: "Elementary Education",
		placeholder: "e.g. Basic subjects for K-6th grade.",
	},
	{
		id: 10,
		name: "Arts",
		placeholder: "e.g. Drawing, Photography, Theatre, etc.",
	},
	{
		id: 11,
		name: "Special Needs",
		placeholder: "e.g. Tailored for specific educational needs.",
	},
];

const renderSkills = (activeSkills, setActiveSkills) => {
	const rows = [];
	const handleCheckbox = (event, id) => {
		if (event.target.checked) {
			setActiveSkills([...activeSkills, id]);
		} else {
			setActiveSkills(activeSkills.filter((skill) => skill !== id));
		}
	};

	for (let i = 0; i < skills.length; i += 2) {
		rows.push(
			<FormRowWrapper key={skills[i]?.id}>
				<CheckboxWrapper>
					<FormControlLabel
						control={<Checkbox onChange={(event) => handleCheckbox(event, skills[i]?.id)} />}
						label={skills[i]?.name}
					/>
					<InputField
						name={skills[i]}
						placeholder={skills[i]?.placeholder}
						disabled={!activeSkills?.includes(skills[i]?.id)}
					/>
				</CheckboxWrapper>
				{i + 1 < skills.length && (
					<CheckboxWrapper>
						<FormControlLabel
							control={<Checkbox onChange={(event) => handleCheckbox(event, skills[i + 1]?.id)} />}
							label={skills[i + 1]?.name}
						/>
						<InputField
							name={skills[i + 1]}
							placeholder={skills[i + 1]?.placeholder}
							disabled={!activeSkills?.includes(skills[i + 1]?.id)}
						/>
					</CheckboxWrapper>
				)}
			</FormRowWrapper>,
		);
	}

	return rows;
};

const FreelancerSkills = () => {
	const [activeSkills, setActiveSkills] = useState([]);
	return (
		<Fragment>
			<FormContainer>
				<FormTitleInfo>Skills & Expertise</FormTitleInfo>
				{renderSkills(activeSkills, setActiveSkills)}
			</FormContainer>
		</Fragment>
	);
};

export default FreelancerSkills;
