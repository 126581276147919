import { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import { FormTitle, FormWrapper, BtnWrapper, btnStyleOverrides } from "../index.styled";
import { borderRadius, color, spacing } from "../../../../../styles/variables";
import PrimaryBtn from "../../../../Buttons/Primary";
import SecondaryBtn from "../../../../Buttons/Secondary";
import { useNavigate } from "react-router-dom";
import Client from "../../../../../assets/icons/education/Client";
import Freelancer from "../../../../../assets/icons/education/Freelancer";
import Employee from "../../../../../assets/icons/education/Employee";
import Company from "../../../../../assets/icons/education/Company";
import Modal from "../../../../Modal";
import { GetAllEducationProfile } from "../../../../../utils/apiCalls";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
`;

const CardWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: ${spacing("md3")};
	margin-block: ${spacing("xxl")};

	& > label {
		display: flex;
		height: 100%;

		color: ${color("pBlack")};
		font-family: Poppins;
		font-size: 19px;
		font-style: normal;
		font-weight: 400;
		line-height: 30px; /* 157.895% */
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: ${spacing("xl2")};
	}
`;

const Card = styled.div`
	cursor: pointer;
	grid-column: span 1;
	border: 1px solid #ccc;
	position: relative;
	border-radius: 6px;
	border: 1px solid #aba6a6;
	background: ${color("pWhite")};
	padding: ${spacing("md3")} ${spacing("lg")};

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: ${spacing("md1")};
`;

const RadioButton = styled.input`
	margin: 0;
	position: absolute;
	top: 20px;
	right: 20px;
	transform: translateY(50%);
`;

const cards = [
	{
		id: 1,
		icon: Client,
		name: "Client",
		desc: "I'm a client, hiring for educational assistance, tutoring, or seeking collaborative learning and internship opportunities.",
		link: "/education/client/signup",
	},
	{
		id: 2,
		icon: Freelancer,
		name: "Freelancer",
		desc: "I'm a freelancer specializing in education, offering services in teaching, tutoring, and internship mentorship.",
		link: "/education/freelancer/signup",
	},
	{
		id: 3,
		icon: Employee,
		name: "Employee",
		desc: "I'm a regular employee in the education sector, seeking to connect with like-minded educators and learners",
		link: "/education/employee/signup",
	},
	{
		id: 4,
		icon: Company,
		name: "Company",
		desc: "We are an education-focused company seeking clients interested in our diverse range of educational products and services.",
		link: "/education/company/signup",
	},
];

const CardComponent = ({ selectedCard, setSelectedCard }) => {
	const handleRadioChange = (value) => {
		setSelectedCard(value);
	};

	return (
		<CardWrapper>
			{cards?.map((card) => (
				<label key={card?.id}>
					<Card>
						<RadioButton
							type="radio"
							name="card"
							value={card?.id}
							checked={selectedCard?.id === card?.id}
							onChange={() => handleRadioChange(card)}
						/>
						{card?.icon && <card.icon />}
						{card?.desc}
					</Card>
				</label>
			))}
		</CardWrapper>
	);
};

const InitialSignupForm = () => {
	const [selectedCard, setSelectedCard] = useState(null);
	const [usersData, setUsersData] = useState([]);
	const [filteredUsers, setFilteredUsers] = useState([]);
	const [buttonDisable, setButtonDisable] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");
	const apiCallExecuted = useRef(false);
	const navigate = useNavigate();

	useEffect(() => {
		if (selectedCard) {
			const newFilteredUsers = usersData.filter((user) => user?.educationType === selectedCard?.name);
			setFilteredUsers(newFilteredUsers);
			setErrorMessage(selectedCard?.name);
		}
	}, [selectedCard]);


	const  getEducationProfilesDetails = () => {
		setButtonDisable(true);
		GetAllEducationProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setUsersData(data);
				setTimeout(() => {
					setButtonDisable(false);
				}, 1000);
			} else {
				setButtonDisable(false);
			}
		});
	};
	useEffect(() => {
		if (!apiCallExecuted.current) {
			apiCallExecuted.current = true;
			getEducationProfilesDetails();
		}
	}, []);

	const handleSubmit = (event) => {
		event.preventDefault();
		if (filteredUsers.length > 0) {
			setShowModal(true);
		} else {
			navigate(selectedCard?.link);
			setButtonDisable(false);
		}
	};
	const handleCloseModal = () => {
		setShowModal(false);
	};
	const handleOk = () => {
		setShowModal(false);
	};
	return (
		<Wrapper>
			<FormWrapper initialForm onSubmit={handleSubmit}>
				<FormTitle
					style={{
						borderRadius: borderRadius(6),
					}}
				>
					<strong>Education: </strong>Join as a Client , freelancer , Regular Employee or a company
				</FormTitle>
				<CardComponent selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
				<BtnWrapper>
					<SecondaryBtn label={"Back"} onClick={() => navigate(-1)} overrides={btnStyleOverrides} />
					<PrimaryBtn label={"Continue"} overrides={btnStyleOverrides} disable={buttonDisable} />
				</BtnWrapper>
			</FormWrapper>
			<Modal open={showModal} onClose={handleCloseModal} noIcon>
				<p style={{ fontSize: "1.5vw", padding: "1vw" }}>The {errorMessage} profile already exists.</p>
				<button
					style={{ color: "white", background: "#61BCAC", border: "none", padding: "0.5vw", borderRadius: "1vw", cursor:"pointer" }}
					onClick={handleOk}
				>
					OK
				</button>
			</Modal>
		</Wrapper>
	);
};

export default InitialSignupForm;
