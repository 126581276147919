import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { color } from "../../../styles/variables";
import { Accordion, AccordionDetails } from "@mui/joy";

const Container = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	margin-inline: 40px;
	margin-block: 24px;

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		margin: 0;
	}
`;

const Back = styled(NavLink)`
	height: fit-content;
	grid-column: span 2;
	display: flex;
	column-gap: 8px;
	align-items: center;
	color: ${color("pGreen")};

	/* body-text-lg */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	@media screen and (max-width: 768px) {
		color: #fff;
	}
`;

const Wrapper = styled.div`
	grid-column: 4 / span 6;
	background: #fff;
	border-radius: 8px;

	/* dropshadow-formbg */
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);

	overflow: hidden;

	@media screen and (max-width: 768px) {
		margin-inline: 10px;
	}
`;

const TitleWrapper = styled.div`
	background-color: ${color("pGreen")};
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 10px;

	color: #fff;

	/* body-text-lg */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	@media screen and (max-width: 768px) {
		justify-content: space-between;

		margin-block-end: 16px;
	}
`;

const ProfilesWrapper = styled.div``;

const ProfileModuleTitleWrapper = styled.div`
	display: inline-flex;
	align-items: center;
	column-gap: 12px;
`;

const ModuleTitle = styled.span`
	/* font-weight-medium-lg */
	color: #676464;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const Section = styled(Accordion)`
	margin: 12px;
	box-sizing: border-box;

	& button {
		flex-direction: row-reverse;
		justify-content: flex-end;
		column-gap: 8px;
	}
`;

const ModuleSubGroup = styled.div`
	margin-inline-start: 28px;
`;

const ModuleSubGroupTitle = styled.span`
	color: ${color("pGreen")};

	/* font-weight-medium */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const Details = styled(AccordionDetails)`
	margin-inline-start: 34px;
	margin-block-start: 8px;
	& .MuiAccordionDetails-content {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		column-gap: 24px;
		row-gap: 18px;

		@media screen and (max-width: 768px) {
			grid-template-columns: repeat(1, 1fr);
		}
	}
`;

const SubProfilesWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const ProfileDetails = styled.label`
	display: flex;
	align-items: center;
	column-gap: 12px;
	margin-block: 8px;
`;

const ProfileRadio = styled.input``;

const ProfileName = styled.span`
	color: #000;

	/* body-text-xsm */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export {
	Container,
	Back,
	Wrapper,
	TitleWrapper,
	ProfilesWrapper,
	Section,
	Details,
	ProfileModuleTitleWrapper,
	ModuleTitle,
	ModuleSubGroup,
	SubProfilesWrapper,
	ProfileDetails,
	ProfileRadio,
	ProfileName,
	ModuleSubGroupTitle,
};
