import styled from "@emotion/styled";

const LeftNav = styled.div`
	grid-column: 1 / span 2;
`;

const CenterContent = styled.div`
	grid-column: 4 / span 5;
	display: flex;
	flex-direction: column;
	row-gap: 24px;
`;

const RightContent = styled.div`
	grid-column: 10 / span 3;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

export { LeftNav, CenterContent, RightContent };
