import React from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../../../components/Creativity/Forms/Signup";
import ClientSignUpForm from "../../../../components/Creativity/Forms/Signup/Client";

const ClientSignUp = () => {
	const navigate = useNavigate();
	const handleSubmit = (values) => {
		navigate("/creativity/microblogging");
	};
	return (
		<SignUp userType={"Client"} onSubmit={handleSubmit}>
			<ClientSignUpForm />
		</SignUp>
	);
};

export default ClientSignUp;
