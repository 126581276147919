import { useNavigate } from "react-router-dom";
import Heading from "../../Heading/Heading";
import Icon from "../../../assets/icons/agriculture/agriculture";

import {
	BackBtnStyles,
	DetailsWrapper,
	BloggingInfo,
	BloggingProfileBtn,
	BloggingProfileBtnWrapper,
	BloggingTitle,
	BloggingWrapper,
	NextBtnStyles,
	SubHeaders,
	SubDesc,
	TextAreaWrapper,
	TextArea,
	CharacterCount,
	Username,
} from "./index.styled";
import { useState } from "react";
import { connect } from "react-redux";

const MicroBlogging = ({ activeModule }) => {
	const navigate = useNavigate();
	const bioLength = 160;
	const [bioCharLength, setBioCharLenght] = useState(0);

	return (
		<>
			<Heading icon={Icon} title={activeModule?.name} showSearchInput={false} />
			<BloggingWrapper>
				<BloggingTitle>Provide Your Microblogging Information</BloggingTitle>
				<DetailsWrapper>
					<BloggingInfo>
						We gather additional info for microblogging and short video modules to enhance your experience: deep
						industry conversations on microblogging, and engaging visual content in short videos for broader appeal
						and impact.
					</BloggingInfo>
					<SubHeaders>Personal Information for Microblogging</SubHeaders>
					<SubDesc>Bio</SubDesc>
					<TextAreaWrapper>
						<TextArea
							maxLength={bioLength}
							placeholder={"A detailed professional overview highlighting skills and experience"}
							onInput={(event) => setBioCharLenght(event.target.value.length)}
							rows={4}
						/>
						<CharacterCount>{bioLength - bioCharLength} characters</CharacterCount>
					</TextAreaWrapper>
					<SubHeaders>Personal Information for Short Videos</SubHeaders>
					<SubDesc>Username</SubDesc>
					<Username />
				</DetailsWrapper>
				<BloggingProfileBtnWrapper>
					<BloggingProfileBtn style={BackBtnStyles} onClick={() => navigate(-1)}>
						Back
					</BloggingProfileBtn>
					<BloggingProfileBtn style={NextBtnStyles}>Create Profile</BloggingProfileBtn>
				</BloggingProfileBtnWrapper>
			</BloggingWrapper>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		activeModule: state.module.active,
	};
};

export default connect(mapStateToProps)(MicroBlogging);
