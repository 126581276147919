import React, { useEffect, useState } from "react";
import "./ProfileComponent.scss";
import { Popover, Typography } from "@mui/material";
import Loader from "../../components/loader/index";

const ProfileComponent = ({
	id,
	open,
	anchorEl,
	handleClose,
	handleNavigateProfile,
	toggleAddAccount,
	usersData,
	handleUpdateProfile,
	selectedProfileId,
	keyName,
	typeKeyName,
	isLoading = false,
}) => {
	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			placement="bottom-end"
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
			sx={{
				"& .MuiPaper-root": {
					top: "34px !important",
				},
				"& .MuiPopover-paper": {
					borderRadius: "8px",
					width: "180px",
					transform: "translateY(18px) !important",
				},
			}}
		>
			<Typography sx={{ p: 2 }}>
				{isLoading ? (
					<Loader />
				) : (
					<div className="profiledropdown">
						{usersData?.map((user, index) => (
							<div className="flex_dropdown_section" key={index}>
								<h6>{user?.[typeKeyName]}</h6>
								<div className="flex_dropdown_section_content">
									<div
										className="flex_dropdown_section_content_left"
										onClick={() => {
											handleNavigateProfile(user);
											handleUpdateProfile(user[keyName]);
										}}
									>
										<input
											type="radio"
											name="userOption"
											value={user?.[keyName]}
											checked={user?.[keyName] === selectedProfileId}
										/>
										<p>{user.firstName || user?.fullName}</p>
									</div>
								</div>
							</div>
						))}

						{usersData?.length < 4 && (
							<button className="add-account-btn" onClick={toggleAddAccount}>
								Add Account
							</button>
						)}
					</div>
				)}
			</Typography>
		</Popover>
	);
};

export default ProfileComponent;
