import styled from "@emotion/styled";
import { borderRadius, color, spacing } from "../../../styles/variables";
import { NavLink } from "react-router-dom";
import { Input, Select } from "@mui/material";

const Wrapper = styled.div`
	padding-inline: ${spacing("xxxl1")};
	padding-block: ${spacing("md")};
	background-color: ${color("pGreen")};

	display: grid;
	grid-column-gap: ${spacing("xxxl")};
	grid-template-columns: repeat(3, 1fr);

	${(props) =>
		props.isMobile &&
		`
    grid-template-columns: repeat(1, 1fr);
    padding-inline: ${spacing("md")}
  `};
`;

const LeftNav = styled.div`
	grid-area: span 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
`;

const NavigationLink = styled(NavLink)`
	color: #fff;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	display: inline-flex;

	svg {
		width: 20px;
	}
`;
const Searchbar = styled.div`
	grid-area: span 1;
	align-self: center;
	.MuiInputBase-input {
		padding: 0;
	}
`;
const SearchField = styled(Input)`
	background-color: white;
	width: 100%;
	padding-inline: ${spacing("md1")};
	padding-block: ${spacing("md")};
	border-radius: ${borderRadius(50)};

	.MuiInputBase-inputAdornedStart {
		border-right: 1px solid ${color("dimGray")};
	}
	.MuiInputAdornment {
		&-positionStart {
			svg {
				color: ${color("dimGray")};
			}
		}
		&-positionEnd {
			width: 25%;

			.MuiInputBase-root {
				width: 100%;
			}
		}
	}
`;

const Dropdown = styled(Select)`
	color: ${color("dimGray")};
`;
const RightNav = styled.div`
	grid-area: span 1;
	display: flex;
	align-items: center;
	column-gap: ${spacing("md2")};
	justify-content: flex-end;
`;

const QuickNaviation = styled(NavLink)`
	display: inline-flex;
	column-gap: ${spacing("xs1")};
	background-color: ${color("sGreen")};
	padding-inline: ${spacing("lg")};
	padding-block: ${spacing("md")};
	border-radius: ${borderRadius(40)};

	color: ${color("pWhite")};

	img {
		width: 21px;
		aspect-ratio: 1;
	}
`;

const MobileWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("lg")};
`;

const MobileTopNavs = styled.div`
	display: flex;
	justify-content: space-between;
`;
const MobileLeftNav = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${spacing("lg1")};
`;
const MobileRightNav = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${spacing("md")};
`;
const MobileQuickNav = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${spacing("xs")};

	> * {
		flex: 1;
		justify-content: center;
		font-weight: 500;
		padding-inline: ${spacing("xs")};
	}
`;

export {
	Wrapper,
	LeftNav,
	NavigationLink,
	Searchbar,
	SearchField,
	Dropdown,
	RightNav,
	QuickNaviation,
	MobileWrapper,
	MobileTopNavs,
	MobileLeftNav,
	MobileRightNav,
	MobileQuickNav,
};
