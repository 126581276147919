import React from "react";
import "./index.scss";
import pic from "../../../assets/icons/user-profile.png";
import heartplus from "../../../assets/images/heart-plus.png";

const SearchResult = () => {
	return (
		<div className="sr">
			<div className="sr_main"></div>
			<div className="sr_pop">
				<div className="sr_pop_container">
					<div className="sr_pop_section">
						<div className="sr_pop_section_heading">
							<h3>Regular Employee</h3>
						</div>
						<div className="sr_pop_section_cards">
							<div className="sr_pop_section_cards_content">
								<div className="sr_pop_section_cards_content_center">
									<div className="sr_pop_section_cards_content_center_image">
										<img src={pic} alt="" />
									</div>
									<div className="sr_pop_section_cards_content_center_content">
										<h2>Thomas Deck</h2>
										<h5>
											Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus
											Fattu Dhinga - Sultanpur Lodhi
										</h5>
										<p>13 Mutual Friends</p>
									</div>
								</div>
								<div className="sr_pop_section_cards_content_right">
									<img src={heartplus} alt="heartplus" />
								</div>
							</div>
							<div className="sr_pop_section_cards_content">
								<div className="sr_pop_section_cards_content_center">
									<div className="sr_pop_section_cards_content_center_image">
										<img src={pic} alt="" />
									</div>
									<div className="sr_pop_section_cards_content_center_content">
										<h2>Thomas Deck</h2>
										<h5>
											Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus
											Fattu Dhinga - Sultanpur Lodhi
										</h5>
									</div>
								</div>
								<div className="sr_pop_section_cards_content_right">
									<img src={heartplus} alt="heartplus" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="sr_pop_container">
					<div className="sr_pop_section">
						<div className="sr_pop_section_heading">
							<h3>Clients</h3>
						</div>
						<div className="sr_pop_section_cards">
							<div className="sr_pop_section_cards_content">
								<div className="sr_pop_section_cards_content_center">
									<div className="sr_pop_section_cards_content_center_image">
										<img src={pic} alt="" />
									</div>
									<div className="sr_pop_section_cards_content_center_content">
										<h2>Julia</h2>
										<h5>
											Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus
											Fattu Dhinga - Sultanpur Lodhi
										</h5>
									</div>
								</div>
								<div className="sr_pop_section_cards_content_right">
									<img src={heartplus} alt="heartplus" />
								</div>
							</div>
							<div className="sr_pop_section_cards_content">
								<div className="sr_pop_section_cards_content_center">
									<div className="sr_pop_section_cards_content_center_image">
										<img src={pic} alt="" />
									</div>
									<div className="sr_pop_section_cards_content_center_content">
										<h2>Elle Panther</h2>
										<h5>
											Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus
											Fattu Dhinga - Sultanpur Lodhi
										</h5>
									</div>
								</div>
								<div className="sr_pop_section_cards_content_right">
									<img src={heartplus} alt="heartplus" />
								</div>
							</div>
							<div className="sr_pop_section_cards_content">
								<div className="sr_pop_section_cards_content_center">
									<div className="sr_pop_section_cards_content_center_image">
										<img src={pic} alt="" />
									</div>
									<div className="sr_pop_section_cards_content_center_content">
										<h2>Thomas Mark</h2>
										<h5>
											Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus
											Fattu Dhinga - Sultanpur Lodhi
										</h5>
										<p>13 Mutual Friends</p>
									</div>
								</div>
								<div className="sr_pop_section_cards_content_right">
									<img src={heartplus} alt="heartplus" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="sr_pop_container">
					<div className="sr_pop_section">
						<div className="sr_pop_section_heading">
							<h3>Company</h3>
						</div>
						<div className="sr_pop_section_cards">
							<div className="sr_pop_section_cards_content">
								<div className="sr_pop_section_cards_content_center">
									<div className="sr_pop_section_cards_content_center_image">
										<img src={pic} alt="" />
									</div>
									<div className="sr_pop_section_cards_content_center_content">
										<h2>Paychax</h2>
										<h5>
											Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus
											Fattu Dhinga - Sultanpur Lodhi
										</h5>
										<p>13 Mutual Friends</p>
									</div>
								</div>
								<div className="sr_pop_section_cards_content_right">
									<img src={heartplus} alt="heartplus" />
								</div>
							</div>
							<div className="sr_pop_section_cards_content">
								<div className="sr_pop_section_cards_content_center">
									<div className="sr_pop_section_cards_content_center_image">
										<img src={pic} alt="" />
									</div>
									<div className="sr_pop_section_cards_content_center_content">
										<h2>KYX</h2>
										<h5>
											Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus
											Fattu Dhinga - Sultanpur Lodhi
										</h5>
									</div>
								</div>
								<div className="sr_pop_section_cards_content_right">
									<img src={heartplus} alt="heartplus" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="sr_pop_container">
					<div className="sr_pop_section">
						<div className="sr_pop_section_heading">
							<h3>Regular Employees</h3>
						</div>
						<div className="sr_pop_section_cards">
							<div className="sr_pop_section_cards_content">
								<div className="sr_pop_section_cards_content_center">
									<div className="sr_pop_section_cards_content_center_image">
										<img src={pic} alt="" />
									</div>
									<div className="sr_pop_section_cards_content_center_content">
										<h2>Julia</h2>
										<h5>
											Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus
											Fattu Dhinga - Sultanpur Lodhi
										</h5>
										<p>13 Mutual Friends</p>
									</div>
								</div>
								<div className="sr_pop_section_cards_content_right">
									<img src={heartplus} alt="heartplus" />
								</div>
							</div>
							<div className="sr_pop_section_cards_content">
								<div className="sr_pop_section_cards_content_center">
									<div className="sr_pop_section_cards_content_center_image">
										<img src={pic} alt="" />
									</div>
									<div className="sr_pop_section_cards_content_center_content">
										<h2>Elle Panther</h2>
										<h5>
											Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus
											Fattu Dhinga - Sultanpur Lodhi
										</h5>
									</div>
								</div>
								<div className="sr_pop_section_cards_content_right">
									<img src={heartplus} alt="heartplus" />
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SearchResult;
