import LeftSubNav from "../../../../components/LeftSubNav";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { color, spacing } from "../../../../styles/variables";
import { Searchbar, SearchField, MobileWrapper, SearchWrapper } from "../../../../pages/Shorts/index.styled";
import PreviewImg from "../../../../assets/images/shorts/UploadPreview.png";
import { Preview, PreviewWrapper, ButtonsWrapper, SecondaryIconWrapper, RecordButton, RecordButtonWrapper } from "./index.styled";
import useLayout from "../../../../utils/hooks/useLayout";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate } from "react-router-dom";
import UploadIcon from "@mui/icons-material/FileUploadSharp";
import BackIcon from "@mui/icons-material/ArrowBackIosRounded";

const Upload = () => {
	const isMobile = useLayout();
	const navigate = useNavigate();
	return (
		isMobile && (
			<MobileWrapper>
				<SearchWrapper>
					<ArrowBackIcon onClick={() => navigate(-1)} />
					<Searchbar>
						<SearchField
							startAdornment={
								<InputAdornment
									position="start"
									sx={{
										marginInlineEnd: spacing("xs"),
									}}
								>
									<SearchIcon color={color("dimGray")} />
								</InputAdornment>
							}
							placeholder="Search"
						/>
					</Searchbar>
				</SearchWrapper>
				<PreviewWrapper>
					<Preview src={PreviewImg} alt="preview" />
				</PreviewWrapper>
				<ButtonsWrapper>
					<SecondaryIconWrapper onClick={() => navigate(-1)}>
						<BackIcon />
					</SecondaryIconWrapper>
					<RecordButtonWrapper>
						<RecordButton />
					</RecordButtonWrapper>
					<SecondaryIconWrapper>
						<UploadIcon />
					</SecondaryIconWrapper>
				</ButtonsWrapper>
			</MobileWrapper>
		)
	);
};

export default Upload;
