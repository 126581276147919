import { useState, Fragment } from "react";
import { FormContainer, FormRowWrapper, CheckboxWrapper, CheckboxGroupTitle, Checkboxes } from "../../index.styled";
import { FormControlLabel, Checkbox } from "@mui/material";

const allSkills = [
	{
		id: 1,
		name: "Actors",
		skills: [
			{
				id: 1,
				name: "Theater",
			},
			{
				id: 2,
				name: "Houses",
			},
			{
				id: 3,
				name: "Unique stays for guests",
			},
		],
	},
	{
		id: 2,
		name: "Costume Designers",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "TV",
			},
			{
				id: 3,
				name: "Theaters",
			},
		],
	},
	{
		id: 3,
		name: "Dancers",
		skills: [
			{
				id: 1,
				name: "Ballet",
			},
			{
				id: 2,
				name: "Houses",
			},
			{
				id: 3,
				name: "Unique stays for guests",
			},
		],
	},
	{
		id: 4,
		name: "Makeup Artists",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "TV",
			},
			{
				id: 3,
				name: "Theaters",
			},
		],
	},
	{
		id: 5,
		name: "Singers",
		skills: [
			{
				id: 1,
				name: "Pop",
			},
			{
				id: 2,
				name: "Classical",
			},
			{
				id: 3,
				name: "Jazz",
			},
		],
	},
	{
		id: 6,
		name: "Sound Designers",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "TV",
			},
			{
				id: 3,
				name: "Theaters",
			},
		],
	},
	{
		id: 7,
		name: "Musicians",
		skills: [
			{
				id: 1,
				name: "Orchestras",
			},
			{
				id: 2,
				name: "Solo Performances",
			},
			{
				id: 3,
				name: "Studio sessions",
			},
		],
	},
	{
		id: 8,
		name: "Lighting Designers",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "TV",
			},
			{
				id: 3,
				name: "Theaters",
			},
		],
	},
	{
		id: 9,
		name: "Comedians",
		skills: [
			{
				id: 1,
				name: "Stand-up",
			},
			{
				id: 2,
				name: "Sketch",
			},
			{
				id: 3,
				name: "Improvisational comedy",
			},
		],
	},
	{
		id: 10,
		name: "Event Hosts/Emcees",
		skills: [
			{
				id: 1,
				name: "Events",
			},
			{
				id: 2,
				name: "Ceremonies",
			},
			{
				id: 3,
				name: "Shows",
			},
		],
	},
	{
		id: 11,
		name: "Magicians",
		skills: [
			{
				id: 1,
				name: "Magic Shows",
			},
			{
				id: 2,
				name: "Illusions",
			},
			{
				id: 3,
				name: " Close-up magic",
			},
		],
	},
	{
		id: 12,
		name: "Special Effects Technicians",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "TV",
			},
			{
				id: 3,
				name: "Theaters",
			},
		],
	},
	{
		id: 13,
		name: "Circus Artists",
		skills: [
			{
				id: 1,
				name: "Acrobats",
			},
			{
				id: 2,
				name: "Aerialists",
			},
			{
				id: 3,
				name: "Clowns",
			},
			{
				id: 4,
				name: "Contortionists",
			},
		],
	},
	{
		id: 14,
		name: "Choreographers",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "Theater",
			},
			{
				id: 3,
				name: "Music Videos",
			},
		],
	},
	{
		id: 15,
		name: "Voice-over Artists",
		skills: [
			{
				id: 1,
				name: "Animations",
			},
			{
				id: 2,
				name: "Commercials",
			},
			{
				id: 3,
				name: "Documentaries",
			},
			{
				id: 4,
				name: "Games",
			},
		],
	},
	{
		id: 16,
		name: "Voice-over Artists",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "TV",
			},
		],
	},
	{
		id: 17,
		name: "Directors",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "Theaters",
			},
			{
				id: 3,
				name: "TV Productions",
			},
		],
	},
	{
		id: 18,
		name: "Casting Directors",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "TV",
			},
		],
	},
	{
		id: 19,
		name: "Screenwriters",
		skills: [
			{
				id: 1,
				name: "Movies",
			},
			{
				id: 2,
				name: "TV Shows",
			},
			{
				id: 3,
				name: "Web Series",
			},
			{
				id: 4,
				name: "Commercials",
			},
		],
	},
	{
		id: 20,
		name: "Exotic Dancers",
		skills: [
			{
				id: 1,
				name: "Cultural",
			},
			{
				id: 2,
				name: "Artistic",
			},
			{
				id: 3,
				name: "Sensual expressions",
			},
		],
	},
	{
		id: 21,
		name: "Production Designers",
		skills: [
			{
				id: 1,
				name: "Film",
			},
			{
				id: 2,
				name: "TV",
			},
			{
				id: 3,
				name: "Theaters",
			},
		],
	},
];

const renderSkills = (activeSkills, setActiveSkills) => {
	const rows = [];
	const handleCheckbox = (event, id) => {
		if (event.target.checked) {
			setActiveSkills([...activeSkills, id]);
		} else {
			setActiveSkills(activeSkills.filter((skill) => skill !== id));
		}
	};

	for (let i = 0; i < allSkills.length; i += 2) {
		rows.push(
			<FormRowWrapper key={allSkills[i]?.id}>
				<CheckboxWrapper>
					<CheckboxGroupTitle>{allSkills[i]?.name}</CheckboxGroupTitle>
					<Checkboxes>
						{allSkills[i]?.skills?.map((skill) => (
							<FormControlLabel
								control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
								label={skill?.name}
							/>
						))}
					</Checkboxes>
				</CheckboxWrapper>
				{i + 1 < allSkills.length && (
					<CheckboxWrapper>
						<CheckboxGroupTitle>{allSkills[i + 1]?.name}</CheckboxGroupTitle>
						<Checkboxes>
							{allSkills[i + 1]?.skills?.map((skill) => (
								<FormControlLabel
									control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
									label={skill?.name}
								/>
							))}
						</Checkboxes>
					</CheckboxWrapper>
				)}
			</FormRowWrapper>,
		);
	}

	return rows;
};

const FreelancerSkills = () => {
	const [activeSkills, setActiveSkills] = useState([]);
	return (
		<Fragment>
			<FormContainer>{renderSkills(activeSkills, setActiveSkills)}</FormContainer>
		</Fragment>
	);
};

export default FreelancerSkills;
