import { useState, Fragment } from "react";
import { FormContainer, FormRowWrapper, CheckboxWrapper } from "../../index.styled";
import { FormControlLabel, Checkbox } from "@mui/material";
const skills = [
	{
		id: 1,
		name: "Environmental Scientists and Specialists",
	},
	{
		id: 2,
		name: "Climate Change Analysts ",
	},
	{
		id: 3,
		name: "Environmental Health and Safety",
	},
	{
		id: 4,
		name: "Environmental Restoration Planners",
	},
	{
		id: 5,
		name: "Industrial Ecologists ",
	},
	{
		id: 6,
		name: "Environmental Chemists ",
	},
];

const renderSkills = (activeSkills, setActiveSkills) => {
	const rows = [];
	const handleCheckbox = (event, id) => {
		if (event.target.checked) {
			setActiveSkills([...activeSkills, id]);
		} else {
			setActiveSkills(activeSkills.filter((skill) => skill !== id));
		}
	};

	for (let i = 0; i < skills.length; i++) {
		rows.push(
			<FormRowWrapper key={skills[i]?.id} childCount={1}>
				<CheckboxWrapper>
					<FormControlLabel
						control={<Checkbox onChange={(event) => handleCheckbox(event, skills[i]?.id)} />}
						label={skills[i]?.name}
					/>
				</CheckboxWrapper>
			</FormRowWrapper>,
		);
	}

	return rows;
};

const FreelancerSkills = () => {
	const [activeSkills, setActiveSkills] = useState([]);
	return (
		<Fragment>
			<FormContainer>{renderSkills(activeSkills, setActiveSkills)}</FormContainer>
		</Fragment>
	);
};

export default FreelancerSkills;
