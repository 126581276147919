import { borderRadius, color } from "../../../styles/variables";
import { Button } from "@mui/material";

const SecondaryBtn = ({ type = "button", label, onClick = undefined, overrides }) => {
	return (
		<Button
			type={type}
			onClick={onClick}
			variant="contained"
			disableRipple
			sx={{
				backgroundColor: color("dimGrayLight"),
				color: color("sBlack"),
				boxShadow: "none",
				textTransform: "capitalize",
				borderRadius: borderRadius(8),
				"&:hover": {
					backgroundColor: color("dimGrayLight"),
					color: color("sBlack"),
					boxShadow: "none",
				},
				...overrides,
			}}
		>
			{label}
		</Button>
	);
};

export default SecondaryBtn;
