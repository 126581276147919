import styled from "@emotion/styled";
import { borderRadius, color, spacing } from "../../../styles/variables";

const Form = styled.div`
	margin-inline: calc(${spacing("xxxl1")} + ${spacing("xxxl1")});
	overflow: hidden;

	margin-block-start: calc(${spacing("xxxl1")} + ${spacing("xl1")});

	@media screen and (max-width: 768px) {
		margin: ${spacing("lg1")} ${spacing("sm1")};
	}
`;

const FormTitleWrapper = styled.h2`
	background: #61bcac;
	color: ${color("pWhite")};
	font-family: Poppins;
	font-size: 28px;
	font-style: normal;
	font-weight: 400;
	line-height: 45px; /* 160.714% */

	padding-inline: ${spacing("xxxl")};
	padding-block: ${spacing("lg1")} ${spacing("md1")};

	@media screen and (max-width: 768px) {
		padding: ${spacing("lg")};
		font-size: 23px;
		line-height: 34px; /* 147.826% */
	}
`;

const FormWrapper = styled.form`
	border-radius: ${borderRadius(8)};
	overflow: hidden;

	border: 1px solid ${color("borderDimGray")};
	@media screen and (max-width: 768px) {
		border: none;
	}
`;

const SubWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	margin-block: ${spacing("xxl")};
	@media screen and (max-width: 768px) {
		margin-block: ${spacing("xl1")};
		grid-template-columns: repeat(2, 1fr);
	}
`;

const Info = styled.p`
	grid-column-start: 2;
	grid-column-end: span 10;
	color: ${color("dimGrayExtraLight")};
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 31px; /* 155% */

	margin-block-end: ${spacing("xl2")};
	@media screen and (max-width: 768px) {
		font-size: 18px;
		line-height: 26px; /* 144.444% */
		margin-block-end: ${spacing("lg")};
		margin-inline: ${spacing("md4")};

		grid-column-start: 1;
		grid-column-end: span 2;
	}
`;

const SectionWrapper = styled.div`
	grid-column-start: 2;
	grid-column-end: span 10;
	@media screen and (max-width: 768px) {
		grid-column-start: 1;
		grid-column-end: span 2;
	}
`;

const SectionTitle = styled.h3`
	grid-column-start: 1;
	grid-column-end: span 4;
	border-radius: 40px;
	background: ${color("dimGrey")};

	color: ${color("pBlack")};
	font-family: Inter;
	font-size: 23px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	padding: ${spacing("sm")} ${spacing("md")};
	@media screen and (max-width: 768px) {
		padding-inline: ${spacing("md4")};
	}
`;

const Section = styled.section`
	display: grid;
	grid-template-columns: repeat(4, 1fr);

	grid-gap: ${spacing("xxxl")};

	margin-block: ${spacing("lg1")};
	margin-inline: ${spacing("md1")};
	@media screen and (max-width: 768px) {
		width: -webkit-fill-available;
		grid-template-columns: repeat(2, 48%);
		grid-row-gap: ${spacing("lg1")};
		grid-column-gap: unset;
		margin-inline: ${spacing("md4")};
		justify-content: space-between;
	}
`;

const FieldWrapper = styled.div`
	cursor: pointer;
	border-radius: ${borderRadius(10)};
	background: ${color("pWhite")};

	/* shadowstyle */
	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);

	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: ${spacing("md1")};
	text-align: center;
	padding: ${spacing("lg")};

	aspect-ratio: 1;
	@media screen and (max-width: 768px) {
		aspect-ratio: unset;
		height: -webkit-fill-available;
		width: -webkit-fill-available;
		min-height: 132px;
		grid-area: span 1;
	}
`;

const FieldName = styled.h5`
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const FieldImage = styled.img`
	max-width: 56px;
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-block: ${spacing("xxxl1")};

	button {
		border-radius: ${borderRadius(70)};
		text-transform: none;
		font-family: Poppins;
		font-size: 28px;
		font-style: normal;
		font-weight: 400;
		line-height: 45px /* 160.714% */;
		min-width: 180px;
	}
	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
		row-gap: ${spacing("md")};
		margin-inline: ${spacing("md4")};
		margin-block: 0 ${spacing("xl2")};

		button {
			width: 100%;
		}
	}
`;

const btnStyles = {};

export {
	Form,
	FormTitleWrapper,
	Info,
	FormWrapper,
	SubWrapper,
	SectionWrapper,
	Section,
	SectionTitle,
	FieldWrapper,
	FieldName,
	FieldImage,
	ButtonWrapper,
	btnStyles,
};
