import styled from "@emotion/styled";
import { spacing } from "../../../styles/variables";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		width: 100%;
	}
`;

const ProfileWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 0 8vw 0;

	@media screen and (max-width: 768px) {
		padding: 4vw 0 1vw;
		justify-content: space-between;
	}
`;

const ProfileSections = styled.div`
	width: 80%;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 768px) {
		width: 100%;
		align-items: flex-start;
	}
`;

const SectionTitle = styled.div`
	font-size: 1.4vw;
	color: #256f61;
	margin: 2vw 0 0 2vw;
	font-weight: 500;

	@media screen and (max-width: 768px) {
		display: none;
		font-size: 23px;
	}
`;

const Profile = styled.div`
	display: flex;
	justify-content: center;
	justify-content: space-between;
	padding: 4vw 0 32px;
	border-bottom: 1px solid #e2e2e2;
	margin-inline: 2vw;

	@media screen and (max-width: 768px) {
		width: -webkit-fill-available;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: flex-start;
		margin-inline: 14px;
		padding-bottom: 0;
	}
`;

const ProfileDetailsWrapper = styled.div`
	display: flex;
	column-gap: 28px;
`;

const ProfileImageWrapper = styled.div`
	height: fit-content;
	position: relative;

	img {
		border-radius: 50%;
		width: 176px;
		height: 176px;
	}

	.imgedit {
		cursor: pointer;
		height: 33px;
		width: 33px;
		position: absolute;
		bottom: 1vw;
		right: 0;
	}

	@media screen and (max-width: 768px) {
		img {
			width: 121px;
			height: 121px;
			flex-shrink: 0;
			width: 84px;
			height: 84px;
		}

		.imgedit {
			position: absolute;
			bottom: -4px;
			right: -4px;
		}
	}
`;

const UserDetailsWrapper = styled.div`
	width: -webkit-fill-available;
	display: flex;
	flex-direction: column;
	padding-block: 12px 0;
`;

const UserNameWrapper = styled.div`
	display: flex;
	align-items: center;

	img {
		cursor: pointer;
		position: relative;
		bottom: 12px;
		height: fit-content;
		margin-left: 28px;
	}
`;

const UserName = styled.h4`
	color: #000;
	font-family: Poppins;
	font-size: 23px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const UserAddress = styled.div`
	display: flex;
	align-items: flex-start;
	column-gap: 8px;

	color: #000;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	padding: 4px 0;

	img {
		width: 22px;
		height: 22px;
		flex-shrink: 0;
	}

	@media screen and (max-width: 768px) {
		color: #000;
		font-size: 10px;
		padding: 0;
		column-gap: 4px;
	}
`;

const UserWorkLocationAndSkills = styled.div`
	color: #000;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	margin-block-start: 8px;

	display: flex;
	flex-direction: column;
	row-gap: 4px;
`;

const UserProfileActions = styled.div`
	width: 28%;
	display: ${(props) => (props.hideOnDesktop ? "none" : "flex")};
	align-items: center;
	justify-content: space-between;
	column-gap: 18px;
	button {
		width: 100%;
		display: flex;
		justify-content: center;
		column-gap: 10px;
		align-items: center;
		padding: 12px 18px;
		color: #fff;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;

		&:active {
			box-shadow: none !important;
		}
	}

	@media screen and (max-width: 768px) {
		width: 100%;
		margin-block: 12px 26px;
		column-gap: 24px;

		button {
			width: 50%;
			margin: 0;
		}
		display: ${(props) => (props.hideOnMobile ? "none" : "flex")};
	}
`;

const MoreDetailWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin: 54px 2vw;
	@media screen and (max-width: 768px) {
		width: 100%;
		box-sizing: border-box;
		margin: 0;
		padding: 14px 3vw;
	}
`;

const ProfilesAndAboutWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	grid-column-gap: 24px;
	margin-bottom: 54px;
	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-column-gap: 0;
		grid-row-gap: 36px;
		margin-bottom: 0;
	}
`;

const ProfilesWrapper = styled.div`
	grid-column: span 3;
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("xxl")};
`;

const Profiles = styled.div`
	height: fit-content;
	border-radius: 6px;
	border: 1px solid #ebebeb;
	background: #fff;
	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
	padding: 22px 16px;
	${(props) =>
		props.fillAvailable && {
			height: "-webkit-fill-available",
		}}
`;

const ProfileList = styled.div`
	display: flex;
	flex-direction: column;
	margin-block-start: 20px;
	@media screen and (max-width: 768px) {
		margin-block-start: 18px;
	}
`;

const ProfileHeading = styled.h4`
	display: flex;
	align-items: center;
	justify-content: space-between;
	cursor: pointer;

	color: #000;
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const ManageOtherProfile = styled.span`
	color: #868d8c;
	font-family: Poppins;
	font-size: 13px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration-line: underline;
`;

const ProfileName = styled.p`
	color: #828282;
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.3px;
`;

const ProfileUserName = styled.span`
	color: #1c1b1b;
	font-family: Poppins;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	letter-spacing: 0.3px;
`;

const About = styled.div`
	grid-column: span 7;

	${(props) =>
		props.card && {
			padding: "22px 16px",
			border: "1px solid #ebebeb",
			borderRadius: "6px",
			background: "#fff",
			boxShadow: "0px 4px 13px 0px rgba(0, 0, 0, 0.25)",
		}}
`;

const AboutTitle = styled.h3`
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: #000;
	font-family: Poppins;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	img {
		cursor: pointer;
		height: fit-content;
	}
`;

const AboutDesc = styled.p`
	color: #000;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
	display: -webkit-box;
	-webkit-line-clamp: ${(props) => !props.noClamp && (props.lines || 3)};
	-webkit-box-orient: vertical;
	overflow: hidden;
	margin-block: 16px 14px;
	@media screen and (max-width: 768px) {
		margin-block: 8px;
		-webkit-line-clamp: 6;
	}
`;

const More = styled.span`
	cursor: pointer;
	color: #61bcac;
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	text-decoration-line: underline;
`;

const EducationHistory = styled.div`
	margin-block-start: 18px;
`;

const EducationTitle = styled.h3`
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: #000;
	font-family: Poppins;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	img {
		cursor: pointer;
		height: fit-content;
	}
	@media screen and (max-width: 768px) {
		margin-block-end: 34px;
	}
`;

const Education = styled.div`
	display: grid;
	grid-template-columns: repeat(3, 1fr);

	color: #000;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;

	span {
		grid-area: span 1;

		&:last-of-type {
			margin-left: auto;
		}
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: 1fr;
		grid-row-gap: 20px;
		span {
			margin: 0 !important;
		}
	}
`;

const SkillsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 4px;

	margin-block-start: 34px;
	@media screen and (max-width: 768px) {
		margin-block-end: 40px;
	}
`;

const SkillsTitle = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;

	color: #000;
	font-family: Poppins;
	font-size: 28px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;

	img {
		cursor: pointer;
		height: fit-content;
	}
`;

const Skills = styled.p`
	color: #000;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 300;
	line-height: normal;
`;

const FeedbackWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-column-gap: 84px;
	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		row-gap: 28px;
		margin-block: 12px;

		.review {
			margin: 0;
		}
	}
`;

const Feedback = styled.div`
	grid-area: span 1;
`;

const reviewComponentStyles = {
	backgroundColor: "#FFF",
};

const TabPanelWrapper = styled.div`
	margin-block: ${spacing("xl1")};
`;

const JobSuccessMobile = styled.div`
	width: -webkit-fill-available;
	display: none;
	.itfreelancerprivate_profile_left_user_job {
		& > div {
			flex: 50%;
			text-align: start;
			justify-content: flex-start;
		}
		img {
			width: 26px;
			aspect-ratio: 1;
			margin-inline-end: ${spacing("sm")};
		}
		p {
			font-family: Poppins;
			font-size: 14px;
			font-style: normal;
			font-weight: 300;
			line-height: normal;
		}
	}
	@media screen and (max-width: 768px) {
		display: block;
		.itfreelancerprivate_profile_left_user_job {
			display: flex;
			justify-content: space-between;
			column-gap: ${spacing("lg")};
		}
	}
`;

const Butt = styled.button`
	width: 48%;
	outline: none;
	cursor: pointer;
	color: white;
	font-size: 0.9vw;
	padding: 0.8vw 0;
	border-radius: 0.7vw;
	border: 1px solid transparent;
	background: #256f61;

	@media screen and (max-width: 768px) {
		font-size: 12px;
		padding: 12px 0;
		border-radius: 8px;
	}
`;

const ProfileModule = styled.div`
	width: 90%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-top: 10px;
`;

export {
	Wrapper,
	ProfileWrapper,
	ProfileSections,
	SectionTitle,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserNameWrapper,
	UserName,
	UserAddress,
	UserWorkLocationAndSkills,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	ProfilesWrapper,
	Profiles,
	ProfileList,
	ProfileHeading,
	ManageOtherProfile,
	ProfileName,
	ProfileUserName,
	About,
	AboutTitle,
	AboutDesc,
	More,
	EducationHistory,
	EducationTitle,
	Education,
	SkillsWrapper,
	SkillsTitle,
	Skills,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	TabPanelWrapper,
	JobSuccessMobile,
	Butt,
	ProfileModule,
};
