import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import EastIcon from "@mui/icons-material/East";
import Slick from "../../../components/Slick";
import AddIcon from "@mui/icons-material/AddOutlined";
import {
	Button,
	CardWrapper,
	IconWrapper,
	OriginCountry,
	PostJob,
	Rate,
	Role,
	ScreenTitle,
	ScreenTitleWrapper,
	SectionTitle,
	SectionWrapper,
	Stack,
	StackWrapper,
	SubWrapper,
	TechStacks,
	UserDetails,
	UserDetailsWrapper,
	UserImage,
	UserName,
	Wrapper,
} from "../index.styled";
import { spacing } from "../../../styles/variables";
import useLayout from "../../../utils/hooks/useLayout";
import { query } from "../../../utils/queryController";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../components/loader/index.js";

import {
	GetTopClientITProfile,
	GetTopCompanyITProfile,
	GetTopEmployeeITProfile,
	GetTopFreelancerITProfile,
} from "../../../utils/apiCalls";
import { CircularProgress, LinearProgress } from "@mui/material";

const dummySkills = [
	{
		stack: ["laravel Framework"],
	},
	{
		stack: ["Codegnitor"],
	},
	{
		stack: ["javascript"],
	},
	{
		stack: ["javascript"],
	},
];

const members = [
	{
		id: 1,
		role: "Full Stack Developer",
	},
	{
		id: 2,
		role: "Website Developer",
	},
	{
		id: 3,
		role: "Python Developer",
	},
	{
		id: 4,
		role: "Java Developer",
	},
	{
		id: 5,
		role: "Full Stack Developer",
	},
];

const HomePage = ({ userData, setSignUpPopupToggle, signUpPopup }) => {
	const isMobile = useLayout();
	const [isLoader, setIsLoader] = useState(false);
	const [freeLancerData, setFreeLancerData] = useState([]);
	const [employeeData, setEmployeeData] = useState([]);
	const [companyData, setCompanyData] = useState([]);
	const [clientData, setClientData] = useState([]);
	const [freelancerPageIndex, setFreelancerPageIndex] = useState(0);
	const [clientPageIndex, setClientPageIndex] = useState(0);
	const [companyPageIndex, setCompanyPageIndex] = useState(0);
	const [employeePageIndex, setEmployeePageIndex] = useState(0);
	const [pageSize, setPageSize] = useState(4);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const togglePopup = (talent) => {
		console.log(userData);
		if (!userData?.accountType) {
			setSignUpPopupToggle(!signUpPopup);
		} else if (talent?.accountType) {
			const profile = talent?.accountType?.toLowerCase();
			navigate(`/agriculture/${profile}/profile/public`, { state: { user: talent } });
		}
	};

	const { data, isLoading } = useQuery({
		queryKey: ["latestAgricultureProfile"],
		queryFn: async () => {
			const response = await query("GET", "AG_PROFILE", "getUserLastAgricultureProfile");
			const { status, message, data } = response;
			return data;
		},
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	const { isLoading: isFreelancerProfileLoading } = useQuery({
		queryKey: ["freelancerProfile"],
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getTopAgricultureFreelancerProfile?pageIndex=${freelancerPageIndex}&pageSize=${pageSize}`,
			);
			const { status, message, data } = response;
			console.log(data);
			setFreeLancerData(data?.result);
			return data;
		},
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});
	const { isLoading: isEmployeeProfileLoading } = useQuery({
		queryKey: ["employeeProfile"],
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getTopAgricultureEmployeeProfile?pageIndex=${employeePageIndex}&pageSize=${pageSize}`,
			);
			const { status, message, data } = response;
			setEmployeeData(data?.result);
			return data;
		},
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});
	const { isLoading: isCompanyProfileLoading } = useQuery({
		queryKey: ["companyProfile"],
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getTopAgricultureCompanyProfile?pageIndex=${companyPageIndex}&pageSize=${pageSize}`,
			);
			const { status, message, data } = response;
			setCompanyData(data?.result);
			return data;
		},
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});
	const { isLoading: isClientProfileLoading } = useQuery({
		queryKey: ["clientProfile"],
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getTopAgricultureClientProfile?pageIndex=${clientPageIndex}&pageSize=${pageSize}`,
			);
			const { status, message, data } = response;
			setClientData(data?.result);
			return data;
		},
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	if (isClientProfileLoading || isCompanyProfileLoading || isEmployeeProfileLoading || isFreelancerProfileLoading)
		return <LinearProgress variant="query" />;

	return (
		<>
			{token ? (
				<Wrapper>
					<ScreenTitleWrapper>
						<ScreenTitle>
							<span className="screen-title">Discover top rated talent</span>
							<span className="mobile-title">Browse Talent</span>
							<EastIcon />
						</ScreenTitle>
						<PostJob onClick={togglePopup}>
							Post a Job <AddIcon />
						</PostJob>
					</ScreenTitleWrapper>
					<SubWrapper>
						<SectionWrapper>
							<SectionTitle>{freeLancerData[0]?.accountType}</SectionTitle>
							{freeLancerData[0]?.accountType && (
								<Slick colSpace={isMobile ? spacing("sm") : spacing("md3")}>
									{freeLancerData?.map((talent, index) => (
										<CardWrapper key={talent.id}>
											<UserDetailsWrapper>
												<UserImage src={talent?.profilePicture} alt="profile" />
												<UserDetails>
													<UserName>{talent?.fullName}</UserName>
													<OriginCountry>{talent?.location}</OriginCountry>
												</UserDetails>
												<IconWrapper>
													<FavoriteBorderIcon />
												</IconWrapper>
											</UserDetailsWrapper>
											<StackWrapper>
												<Role>{members[index % members.length].role}</Role>
												<Rate>
													<MonetizationOnOutlinedIcon /> $8/hr
												</Rate>
												<TechStacks>
													{dummySkills?.map((stack) => (
														<Stack>{stack?.stack}</Stack>
													))}
												</TechStacks>
											</StackWrapper>
											<Button onClick={() => togglePopup(talent)}>View Profile</Button>
										</CardWrapper>
									))}
								</Slick>
							)}
						</SectionWrapper>
						<SectionWrapper>
							<SectionTitle>{employeeData[0]?.accountType}</SectionTitle>
							{employeeData[0]?.accountType && (
								<Slick colSpace={isMobile ? spacing("sm") : spacing("md3")}>
									{employeeData?.map((talent, index) => (
										<CardWrapper key={index}>
											<UserDetailsWrapper>
												<UserImage src={talent?.profilePicture} alt="profile" />
												<UserDetails>
													<UserName>{talent?.fullName}</UserName>
													<OriginCountry>{talent?.location}</OriginCountry>
												</UserDetails>
												<IconWrapper>
													<FavoriteBorderIcon />
												</IconWrapper>
											</UserDetailsWrapper>
											<StackWrapper>
												<Role>{members[index % members.length].role}</Role>
												<TechStacks>
													{dummySkills?.map((stack) => (
														<Stack>{stack?.stack}</Stack>
													))}
												</TechStacks>
											</StackWrapper>
											<Button onClick={() => togglePopup(talent)}>View Profile</Button>
										</CardWrapper>
									))}
								</Slick>
							)}
						</SectionWrapper>
						<SectionWrapper>
							<SectionTitle>{companyData[0]?.accountType}</SectionTitle>
							{companyData[0]?.accountType && (
								<Slick colSpace={isMobile ? spacing("sm") : spacing("md3")}>
									{companyData?.map((talent, index) => (
										<CardWrapper key={index}>
											<UserDetailsWrapper>
												<UserImage src={talent?.profilePicture} alt="profile" />
												<UserDetails>
													<UserName>{talent?.companyName}</UserName>
													<OriginCountry>{talent?.location}</OriginCountry>
												</UserDetails>
												<IconWrapper>
													<FavoriteBorderIcon />
												</IconWrapper>
											</UserDetailsWrapper>
											<StackWrapper>
												<Role>{members[index % members.length].role}</Role>
												<TechStacks>
													{dummySkills?.map((stack) => (
														<Stack>{stack?.stack}</Stack>
													))}
												</TechStacks>
											</StackWrapper>
											<Button onClick={() => togglePopup(talent)}>View Profile</Button>
										</CardWrapper>
									))}
								</Slick>
							)}
						</SectionWrapper>
						<SectionWrapper>
							<SectionTitle>{clientData[0]?.accountType}</SectionTitle>
							{clientData[0]?.accountType && (
								<Slick colSpace={isMobile ? spacing("sm") : spacing("md3")}>
									{clientData?.map((talent) => (
										<CardWrapper>
											<UserDetailsWrapper>
												<UserImage src={talent?.profilePicture} alt="profile" />
												<UserDetails>
													<UserName>{talent?.fullName}</UserName>
													<OriginCountry>{talent?.location}</OriginCountry>
												</UserDetails>
												<IconWrapper>
													<FavoriteBorderIcon />
												</IconWrapper>
											</UserDetailsWrapper>
											<StackWrapper>
												<Role>{talent?.skill}</Role>
												{talent?.ratePerHour && (
													<Rate>
														<MonetizationOnOutlinedIcon /> ${talent?.ratePerHour}/hr
													</Rate>
												)}
												{talent?.skill && (
													<TechStacks>
														{talent?.skill?.map((stack) => (
															<Stack>{stack}</Stack>
														))}
													</TechStacks>
												)}
											</StackWrapper>
											<Button onClick={() => togglePopup(talent)}>View Profile</Button>
										</CardWrapper>
									))}
								</Slick>
							)}
						</SectionWrapper>
					</SubWrapper>
				</Wrapper>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state.ag?.profile?.data,
		signUpPopup: state.ag?.popup,
	};
};

const mapPropsToState = (dispatch) => ({
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_AG",
			payload: data,
		}),
});

export default connect(mapStateToProps, mapPropsToState)(HomePage);
