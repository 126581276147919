import React, { useRef, useState } from "react";
import "./Chatbot.scss";
import chatbotmsg from "../../assets/images/icons/chatbotmsg.png";
import chatbotsend from "../../assets/images/icons/chatbotsend.png";
import chatbotnewchat from "../../assets/images/icons/chatbotnewchat.png";
import cancel from "../../assets/images/icons/cancel.png";
import sidenavmsg from "../../assets/images/icons/sidenavmsg.png";
import { Navigate, useNavigate } from "react-router-dom";
import { UserChatGpt } from "../../utils/apiCalls";
import loader from "../../assets/icons/loading.png";
import { useEffect } from "react";
import { UserContext } from "../../store/UserDetailsStore";
import { useContext } from "react";
import { Avatar } from "@mui/material";

const Chatbot = () => {
	const navigate = useNavigate();
	const [sidenavopen, setSidenavOpen] = useState(false);
	const [isMessage, setIsMessage] = useState("");
	const [isResponse, setIsResponse] = useState("");
	const [isData, setIsData] = useState([]);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const inputRef = useRef();
	const { isLoginUserDetails } = useContext(UserContext);

	const handlesidenav = () => {
		setSidenavOpen(!sidenavopen);
	};

	const handleHome = () => {
		navigate("/home");
	};
	const chatbotSuggestionsRef = useRef(null);

	const handleChatGpt = async () => {
		const payload = {
			message: isMessage,
		};
		if (isMessage !== 0) {
			setIsResponse(true);
			if (payload && payload.message !== "") {
				try {
					const res = await userChatGptAsync(payload);
					if (res.status === 200) {
						setIsMessage("");
						setIsData((prevMessages) => [...prevMessages, res.data]);
						setIsResponse(false);

						setTimeout(() => {
							inputRef.current.focus();
							chatbotSuggestionsRef.current.scrollIntoView({
								behavior: "smooth",
								block: "end",
							});
						}, 0);
					} else {
						setIsMessage("");
					}
				} catch (error) {
					console.error("Error:", error);
					setIsMessage("");
				}
			}
		}
	};

	const userChatGptAsync = (payload) => {
		return new Promise((resolve, reject) => {
			UserChatGpt((res) => {
				if (res.status === 200) {
					resolve(res);
				} else {
					reject(new Error("API request failed"));
				}
			}, payload);
		});
	};

	useEffect(() => {
		setTimeout(() => {
			inputRef.current.focus();
		}, 0);
	}, []);

	return (
		<>
			{token ? (
				<>
					<div className="chatbot">
						<div className="chatbot_sidenav">
							<div className="chatbot_sidenav_main">
								<div className="chatbot_sidenav_main_one">
									<div className="newchat">
										<p>+ Newchat</p>
									</div>
									<div className="newsend">
										<img src={chatbotnewchat} alt="" />
									</div>
								</div>
								<div className="chatbot_sidenav_main_two">
									<div className="chatday">
										<p>Yesterday</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Navigate our platform with ease</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Monetize your creativity</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Publish and profit</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Ad revenue and idea funding</p>
									</div>
								</div>
								<div className="chatbot_sidenav_main_three">
									<div className="chatday">
										<p>Today</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Navigate our platform with ease</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Monetize your creativity</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Publish and profit</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Ad revenue and idea funding</p>
									</div>
								</div>
							</div>
							<div className="chatbot_sidenav_bottom">
								<div className="chatbot_sidenav_bottom_four">
									<div className="fourbottom">
										<Avatar>{isLoginUserDetails?.name?.charAt(0)}</Avatar>
										<p>{isLoginUserDetails?.name}</p>
									</div>
								</div>
							</div>
						</div>
						<div className="chatbot_main">
							<div className="chatbot_main_container">
								<div className="chatbot_main_suggestions">
									{isData?.length !== 0 ? (
										isData?.map((res, index) => {
											const formattedMessage = res?.result
												? res?.result?.trim()?.replace(/\n/g, "<br>")
												: "";
											const isLastItem = index === isData.length - 1;
											return (
												<div
													key={index}
													className={`qanda ${isLastItem ? "last-item" : ""}`}
													ref={isLastItem ? chatbotSuggestionsRef : null}
												>
													<p className="question">{res?.question}</p>
													<p
														className="answer"
														dangerouslySetInnerHTML={{
															__html: formattedMessage,
														}}
													></p>
												</div>
											);
										})
									) : (
										<div>
											<div className="tempdata">
												<div className="chatbot_main_suggestions_box">
													<h5>Navigate our platform with ease:</h5>
													<p>
														How can I optimize my journey on your site to ensure maximum financial
														gain?
													</p>
												</div>
												<div className="chatbot_main_suggestions_box">
													<h5>Monetize your creativity:</h5>
													<p>
														What steps should I take to earn by crafting commissioned content for
														BuzzGalactic?
													</p>
												</div>
											</div>
											<div className="tempdata">
												<div className="chatbot_main_suggestions_box">
													<h5>Publish and profit:</h5>
													<p>
														Can you guide me through creating a trending news story to generate
														revenue?
													</p>
												</div>
												<div className="chatbot_main_suggestions_box">
													<h5>Ad revenue and idea funding</h5>
													<p>
														What are the requirements to start receiving advertising income or get my
														project funded through your platform?
													</p>
												</div>
											</div>
										</div>
									)}
								</div>
								<div className="chatbot_main_message">
									<div className="chatbotmsgimg">
										<img src={chatbotmsg} alt="" />
									</div>
									<input
										type="text"
										placeholder="Send a message"
										value={isMessage}
										disabled={isResponse === true}
										onChange={(e) => setIsMessage(e.target.value)}
										onKeyDown={(e) => {
											if (e.key === "Enter") {
												handleChatGpt();
											}
										}}
										ref={inputRef}
									/>
									<div className="chatbotsendimg">
										{isResponse === true ? (
											<img className="loader" src={loader} alt="Loading" />
										) : (
											<img src={chatbotsend} alt="" onClick={handleChatGpt} />
										)}
									</div>
								</div>
							</div>
						</div>
					</div>
					{sidenavopen && (
						<div className="chatbot_sidenavmobile">
							<div className="chatbot_sidenavmobile_main">
								<div className="chatbot_sidenavmobile_main_one">
									<div className="newchat">
										<p>+ Newchat</p>
									</div>
									<div className="newsend" onClick={handlesidenav}>
										<img src={cancel} alt="" />
									</div>
								</div>
								<div className="chatbot_sidenavmobile_main_two">
									<div className="chatday">
										<p>Yesterday</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Navigate our platform with ease</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Monetize your creativity</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Publish and profit</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Ad revenue and idea funding</p>
									</div>
								</div>
								<div className="chatbot_sidenavmobile_main_three">
									<div className="chatday">
										<p>Today</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Navigate our platform with ease</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Monetize your creativity</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Publish and profit</p>
									</div>
									<div className="chathistory">
										<img src={sidenavmsg} alt="" />
										<p>Ad revenue and idea funding</p>
									</div>
								</div>
							</div>
						</div>
					)}
				</>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default Chatbot;
