import Page from "../../../layouts/Page";
import useLayout from "../../../utils/hooks/useLayout";
import Matches from "../../../components/Dating/Matches";

const Home = () => {
	const isMobile = useLayout();
	return (
		<Page
			overrides={
				isMobile && {
					paddingInline: "12px",
				}
			}
		>
			<Matches />
		</Page>
	);
};

export default Home;
