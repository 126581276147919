import React, { useEffect, useState } from "react";
import "./MBSubHeader.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { IconButton, Input, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import "react-tabs/style/react-tabs.css";
import { connect } from "react-redux";
import { MobileTopNavs, MobileWrapper, SubWrapper, Wrapper } from "./index.styled";
import useLayout from "../../../utils/hooks/useLayout";
import User from "../../../assets/icons/UserWhite.svg";
import Settings from "../../../assets/icons/Settings.svg";
import InformationTechnology from "../../../assets/icons/it/it";
import ShortVideos from "../../../assets/icons/shorts/shortVideos";
import Agriculture from "../../../assets/icons/agriculture/agriculture";
import SidenavMobileComponent from "../../SidenavMobileComponent";

const moduleMapping = {
	it: "Information Technology",
	sv: "Short Videos",
	ag: "Agriculture",
};

const moduleRedirectionMapping = {
	it: "it",
	sv: "shorts",
	ag: "agriculture",
};

const iconsMapping = {
	it: <InformationTechnology color="#fff" />,
	sv: <ShortVideos color="#fff" />,
	ag: <Agriculture color="#fff" />,
};

const SubHeader = ({ leftsearch, lefttabs, rightmobilemenu, activeModule, activeUser }) => {
	const isMobile = useLayout();
	const location = useLocation();
	const navigate = useNavigate();
	const [activeRoute, setActiveRoute] = useState(null);
	const [activeTab, setActiveTab] = useState("ForYou");
	// const [module, setModule] = useState(null);
	// const [moduleTitle, setModuleTitle] = useState(null);
	const [anchorEl, setAnchorEl] = useState(null);
	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};

	const checkPath = () => {
		const { pathname } = location;
		const path = pathname?.split("/");
		path.shift();

		if (path?.includes("shorts")) {
			setActiveRoute("shorts");
		} else if (path?.includes("microblogging")) {
			setActiveRoute("microblogging");
		}
	};

	useEffect(() => {
		checkPath();
	}, []);
	useEffect(() => {
		checkPath();
	}, [location.pathname]);

	// useEffect(() => {
	// 	let moduleName = location?.state?.module || activeModule;
	// 	if (moduleName) {
	// 		setModule(moduleName);
	// 		setModuleTitle(moduleMapping[moduleName]);
	// 	}
	// }, []);
	// useEffect(() => {
	// 	let moduleName = location?.state?.module || activeModule;
	// 	if (moduleName) {
	// 		setModuleTitle(moduleMapping[moduleName]);
	// 	}
	// }, [activeModule]);

	const handleModuleNavigation = () => {
		navigate(-1, {
			state: { user: activeUser },
		});
	};

	if (location?.pathname?.includes("profiles")) {
		return <></>;
	}

	const handleSearch = () => {
		navigate(`/${activeRoute}/search`);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div className="mbsubheader_header">
			<div className="mbsubheader_header_main">
				<div className="mbsubheader_header_main_left">
					{leftsearch ? (
						<div className="mbsubheader_header_main_left_search">
							<Input
								onClick={handleSearch}
								style={{ background: "white", padding: "0.2vw 1vw ", borderRadius: "8px" }}
								startAdornment={
									<InputAdornment position="start">
										<IconButton style={{ color: "#989898", margin: "0px" }}>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								}
								placeholder="Search"
							/>
						</div>
					) : (
						<div className="mbsubheader_header_main_left_emptysearch"></div>
					)}
					{lefttabs ? (
						<div className="mbsubheader_header_main_left_tabsection">
							<ul>
								<li className={activeTab === "ForYou" ? "active" : ""}>
									<NavLink onClick={() => handleTabClick("ForYou")} to="/microblogging/feed">
										For you
									</NavLink>
								</li>
								<li className={activeTab === "Following" ? "active" : ""}>
									<NavLink onClick={() => handleTabClick("Following")} to="/microblogging/feed/following">
										Following
									</NavLink>
								</li>
								<li
									className={activeTab === "Rewardfeed" ? "active" : ""}
									onClick={() => handleTabClick("Rewardfeed")}
								>
									<NavLink to="/microblogging/feed/reward">Rewardfeed</NavLink>
								</li>
								<li
									className={activeTab === "Once-monthly" ? "active" : ""}
									onClick={() => handleTabClick("Once-monthly")}
								>
									<NavLink to="/microblogging/feed/monthly">Oncemonthly</NavLink>
								</li>
								<li
									className={activeTab === "Marketlink" ? "active" : ""}
									onClick={() => handleTabClick("Marketlink")}
								>
									<NavLink to="/microblogging/feed/marketlink">Marketlink</NavLink>
								</li>
							</ul>
						</div>
					) : (
						<div className="mbsubheader_header_main_left_emptytabsection"></div>
					)}
				</div>
				{/* {!isMobile && moduleTitle && (
					<div className="mbsubheader_header_main_right">
						<p onClick={handleModuleNavigation}>{moduleTitle}</p>
					</div>
				)} */}
				{isMobile && (
					<Wrapper>
						<SubWrapper isMobile={isMobile}>
							<MobileWrapper>
								<MobileTopNavs>
									<SearchIcon
										onClick={() => navigate("/microblogging/search")}
										sx={{
											color: "#fff",
										}}
									/>
									<span>
										<img onClick={() => navigate("/microblogging/profiles")} src={User} />
										{/* <span onClick={handleModuleNavigation}>{iconsMapping[module]}</span> */}
										<img src={Settings} />
										<SidenavMobileComponent module="mb" color={"#fff"} />
									</span>
								</MobileTopNavs>
							</MobileWrapper>
						</SubWrapper>
					</Wrapper>
				)}
			</div>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		activeModule: state.mb?.active || undefined,
		state: state,
		activeUser: state.mb?.activeProfile,
	};
};

export default connect(mapStateToProps)(SubHeader);
