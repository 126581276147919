import styled from "@emotion/styled";
import { borderRadius, color, spacing } from "../../../../styles/variables";

const Wrapper = styled.div`
	width: 100%;

	display: flex;
	justify-content: center;
	margin-block: ${spacing("xl1")};
`;

const FormWrapper = styled.form`
	display: inline-block;
	min-width: 55dvw;
	margin-inline: auto;
	margin-block: ${spacing("xxxl")};
	overflow: hidden;
	max-width: 90dvw;

	border-radius: ${borderRadius(6)};

	@media screen and (max-width: 768px) {
		margin-block: ${spacing("xs")};
		margin-inline: ${spacing("sm1")};
		width: 100dvw;

		${(props) =>
			props.initialForm && {
				backgroundColor: color("pWhite"),
				gridColumn: "2/12",
				width: "-webkit-fill-available",
				marginBlock: spacing("xl"),
				paddingInline: 0,
				marginInline: 0,
				paddingBlock: 0,
			}}
	}

	${(props) =>
		props.initialForm && {
			backgroundColor: color("pWhite"),
			gridColumn: "2/12",
			width: "-webkit-fill-available",
			marginBlock: spacing("xl2"),
			paddingBlock: spacing("xxxl1"),
			paddingInline: spacing("xxl"),
		}}
`;

const FormTitle = styled.div`
	background-color: ${color("pGreen")};
	padding-inline: ${spacing("xxxl")};
	padding-block: ${spacing("lg1")} ${spacing("md1")};

	&,
	& > strong {
		color: ${color("pWhite")};
		font-family: Poppins;
		font-size: 28px;
		font-style: normal;
		font-weight: 400;
		line-height: 45px; /* 160.714% */
	}

	& > strong {
		font-weight: 600;
	}

	@media screen and (max-width: 768px) {
		padding: ${spacing("md1")};
		margin-block-end: ${spacing("lg1")};
		border-radius: ${borderRadius(6)};
		text-align: center;
		font-family: Poppins;
		font-size: 26px;
		font-style: normal;
		font-weight: 400;
		line-height: 29px;
	}
`;

const Form = styled.div`
	padding-inline: ${spacing("xxxl")};
	padding-block: ${spacing("xxl1")};
	margin-block-end: ${spacing("xxl1")};

	border: 1px solid ${color("borderDimGray")};
	border-block-start-width: 0;
	border-bottom-left-radius: ${borderRadius(8)};
	border-bottom-right-radius: ${borderRadius(8)};
	@media screen and (max-width: 768px) {
		padding-inline: ${spacing("md1")};
		padding-block: ${spacing("xl")};
		border-block-start-width: 1px;
		border-radius: ${borderRadius(6)};
	}
`;

const BtnWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		font-family: Poppins;
		font-size: 28px;
		font-style: normal;
		font-weight: 400;
		line-height: 45px;
	}
	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
		row-gap: ${spacing("sm2")};

		button {
			width: 100%;
		}
	}
`;

const FormContainer = styled.div`
	width: 100%;
`;

const FormTitleInfo = styled.h3`
	color: ${color("pBlack")};
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	margin-block-end: ${spacing("lg")};

	@media screen and (max-width: 768px) {
		margin-block-end: ${spacing("md3")};
	}
`;

const FormRowWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(${(props) => props.childCount || 2}, 1fr);
	grid-column-gap: ${(props) => (props.colGap ? props.colGap : spacing("xl1"))};
	&:last-of-type > div:only-child {
		margin-block-end: 0;
	}
	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(${(props) => props.childCount || 1}, 1fr);
	}
`;

const btnStyleOverrides = {
	borderRadius: borderRadius(70),
	textTransform: "none",
	paddingInline: spacing("xxxl"),
	paddingBlock: spacing("sm"),
};

const CheckboxWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	align-items: flex-start;

	.MuiFormControlLabel-label {
		color: ${color("pBlack")};
		font-family: Inter;
		font-size: 16px !important;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-align: left !important;
	}
`;

const CheckboxGroupTitle = styled.h3`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 19px;
	font-style: normal;
	font-weight: 400;
	line-height: 45px; /* 236.842% */
`;

const Checkboxes = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;

	@media screen and (max-width: 768px) {
		flex-direction: column;
	}
`;

export {
	Wrapper,
	FormWrapper,
	FormTitle,
	Form,
	BtnWrapper,
	FormContainer,
	FormTitleInfo,
	FormRowWrapper,
	btnStyleOverrides,
	CheckboxWrapper,
	CheckboxGroupTitle,
	Checkboxes,
};
