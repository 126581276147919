import { Route, Routes, useLocation } from "react-router-dom";
import styled from "@emotion/styled";
import Feed from "../pages/Shorts/Feed";
import Search from "../pages/Shorts/Search";
import Heading from "../components/MB/MBHeading/MBHeading";
import PrivateProfile from "../pages/Shorts/Profile/Private";
import PublicProfile from "../pages/Shorts/Profile/Public";
import Notifications from "../pages/Shorts/Notifications";
import MobileNav from "../components/Shorts/Mobile/Nav";
import MobileTopNav from "../components/Shorts/Mobile/TopNav";
import MobilePrivateProfile from "../components/Shorts/Mobile/Profile";
import MobilePublicProfile from "../components/Shorts/Mobile/Public";
import { Fragment, useEffect, useState } from "react";
import Rewards from "../pages/Shorts/Feed/Rewards";
import MarketLink from "../pages/Shorts/Feed/MarketLink";
import useLayout from "../utils/hooks/useLayout";
import Upload from "../components/Shorts/Mobile/Upload";
import headerimg from "../assets/images/shorts/short_videos_icon.png";
import { GetShortVideosProfile } from "../utils/apiCalls";
import SVHeading from "../components/Shorts/SVHeading/SVHeading";
import SVSubHeader from "../components/Shorts/SVSubHeader/SVSubHeader";
import Profiles from "../components/Shorts/Profiles";

const MobileWrapper = styled.div`
	height: calc(100dvh - ${(props) => props.navHeight}px);
	margin-block-end: ${(props) => props.navHeight || "0"}px;
	${(props) =>
		props.navHeight > 0 && {
			overflowY: "scroll",
		}}
	::-webkit-scrollbar {
		width: 4px;
	}
`;

const ShortsRoutes = () => {
	const location = useLocation();
	const isMobile = useLayout();
	const [mobileNavBarHeight, setMobileNavBarHeight] = useState(0);
	const { pathname } = location;
	const [isShorts, setIsShorts] = useState(false);
	const [isUser, setIsUser] = useState(false);
	const [activeProfile, setActiveProfile] = useState("Thomas Mark");
	const [path, setPath] = useState([]);
	const [shortVideosProfile, setShortVideosProfile] = useState([]);

	useEffect(() => {
		let path = pathname.split("/");
		path.shift();
		setPath(path);
		if (path.includes("shorts") && !path.includes("join") && !path.includes("signup")) {
			setIsShorts(true);
		} else setIsShorts(false);

		if (path.includes("private") || path.includes("public")) setIsUser(true);
		else setIsUser(false);

		if (path.includes("company")) setActiveProfile("Paychex");
		else setActiveProfile("Thomas Mark");
	}, [pathname]);
	const handleNavHeight = (height) => {
		if (path?.includes("search")) {
			setMobileNavBarHeight(0);
		} else setMobileNavBarHeight(height);
	};

	const getShortVideos = () => {
		GetShortVideosProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setShortVideosProfile(data);
				const svdata = JSON?.stringify(data);
				localStorage?.setItem("shortVideoData", svdata);
			} else {
				setShortVideosProfile([]);
			}
		});
	};

	useEffect(() => {
		if (location.pathname.includes("/shorts/")) {
			getShortVideos();
		}
	}, [location]);

	return (
		<>
			{isMobile && isShorts ? (
				<>
					{<MobileTopNav/>}
					<MobileWrapper navHeight={mobileNavBarHeight}>
						{pathname.includes("/shorts/search") && <SVHeading icon={headerimg} title={"Short Videos"} />}
						<Routes>
							<Route path="shorts">
								{/* <Route index  element={<Home />} /> */}
								<Route path="profiles" element={<Profiles />} />
								<Route path="feed">
									<Route index element={<Feed />} />
									<Route path="following" element={<Feed />} />
									<Route path="rewards" element={<Rewards />} />
									<Route path="monthly" element={<Feed />} />
									<Route path="marketlink" element={<Feed />} />
								</Route>

								<Route path="search" element={<Search />} />

								<Route path="upload" element={<Upload />} />
								<Route path="profile">
									<Route path="private" element={<MobilePrivateProfile />} />
									<Route path="public" element={<MobilePublicProfile />} />
								</Route>
							</Route>
						</Routes>
					</MobileWrapper>
					{<MobileNav shortVideosProfile={shortVideosProfile} setNavHeight={handleNavHeight} />}
				</>
			) : (
				isShorts && (
					<Fragment>
						<SVHeading icon={headerimg} title={"Short Videos"} user={shortVideosProfile?.shortVideoUsername} />
						{!pathname.includes("/shorts/profiles") && (
							<SVSubHeader
								modulTitle={shortVideosProfile?.shortVideoAccountType}
								shortVideosProfile={shortVideosProfile}
								rightmenu
								leftsearch
								lefttabs
							/>
						)}
						<Routes>
							<Route path="shorts">
								{/* <Route index  element={<Home />} /> */}
								<Route path="profiles" element={<Profiles />} />
								<Route path="feed">
									<Route index element={<Feed />} />
									<Route path="following" element={<Feed />} />
									<Route path="rewards" element={<Rewards />} />
									<Route path="monthly" element={<Feed />} />
									<Route path="marketlink" element={<MarketLink />} />
								</Route>
								<Route path="profile">
									<Route path="public" element={<PublicProfile />} />
									<Route path="private" element={<PrivateProfile />} />
								</Route>
								<Route path="search" element={<Search />} />
								<Route path="notifications" element={<Notifications />} />
							</Route>
						</Routes>
					</Fragment>
				)
			)}
		</>
	);
};

export default ShortsRoutes;
