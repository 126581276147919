import React, { useEffect, useState } from "react";
import "./SearchComponent.scss";
import pic from "../../assets/icons/user-profile.png";
import arrow from "../../assets/images/arrow.png";
import Pagination from "react-js-pagination";
import { GetAllUserList } from "../../utils/apiCalls";
import { Navigate, useNavigate } from "react-router-dom";
import Loader from "../../components/loader";

const SearchComponent = () => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [userList, setUserList] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [activeNewPage, setActiveNewPage] = useState(1);
	const [pageSize, setpageSize] = useState(15);
	const [newPageSize, setNewPageSize] = useState(15);
	const [totalRecordsCount, setTotalRecordsCount] = useState();
	const [query, setQuery] = useState("");
	const [status, setStatus] = useState();

	const handlePageChange = (event) => {
		setActiveNewPage(event);
	};

	useEffect(() => {
		getAllUserData();
	}, [activeNewPage, newPageSize, query]);

	const getAllUserData = () => {
		const payload = {
			searchName: query,
			pageSize: newPageSize ? newPageSize : pageSize,
			pageIndex: activeNewPage - 1 ? activeNewPage - 1 : activePage - 1,
		};
		GetAllUserList((res) => {
			const { status, messsage, data } = res;
			setStatus(status);
			if (status === 200) {
				setUserList(data?.users);
				setTotalRecordsCount(data?.totalRecords);
			} else if (status === 403) {
				navigate("/login");
			}
		}, payload);
	};

	const handleProfileNavigate = (id) => {
		sessionStorage.setItem("searchId", id);
		navigate(`/publicprofile`);
	};

	const handleChange = (event) => {
		setQuery(event.target.value);
	};

	return (
		<>
			{token ? (
				<div className="sc">
					{userList.length ? (
						<>
							<div className="sc_main">
								<h6>Main</h6>
								<img src={arrow} alt="arrow" />
								<h6>Search</h6>
							</div>
							<div className="sc_pop">
								<div className="sc_pop_container">
									<div className="sc_pop_section">
										<div className="sc_pop_section_input">
											<input placeholder="Search here..." value={query} onChange={handleChange} />
										</div>
										<div className="sc_pop_section_heading">
											<h3>People</h3>
										</div>
										<div className="sc_pop_section_cards">
											{status === 200 && userList?.length ? (
												userList?.map((data, index) => {
													return (
														<div className="sc_pop_section_cards_content" key={index}>
															<div className="sc_pop_section_cards_content_center">
																<div className="sc_pop_section_cards_content_center_image">
																	{data?.profilePicture ? (
																		<img src={data?.profilePicture} alt="" />
																	) : (
																		<img src={pic} alt="" />
																	)}
																</div>
																<div className="sc_pop_section_cards_content_center_content">
																	<h2>{data?.fullName}</h2>
																	{/* <h5>{data?.email}</h5> */}
																</div>
															</div>
															<div className="sc_pop_section_cards_content_right">
																<button
																	type="button"
																	onClick={() => handleProfileNavigate(data?.userId)}
																>
																	View Profile
																</button>
															</div>
														</div>
													);
												})
											) : status === 404 ? (
												<div className="no-records-found">
													<p>No records found</p>
												</div>
											) : (
												<div className="no-records-found">
													<p>No records found</p>
												</div>
											)}
										</div>
									</div>
								</div>
							</div>
							<div className="sc_pagination">
								<div className="sc_pagination_container">
									{status === 200 ? (
										<Pagination
											activePage={activeNewPage ? activeNewPage : activePage}
											itemsCountPerPage={15}
											totalItemsCount={totalRecordsCount}
											pageRangeDisplayed={15}
											onChange={(event) => handlePageChange(event)}
										/>
									) : (
										<></>
									)}
								</div>
							</div>
						</>
					) : (
						<>
							<Loader />
						</>
					)}
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default SearchComponent;
