import React from "react";
import { MobileBottom, MobileBottomIcons } from "../../../pages/MB/styles/index.styled";
import { NavLink } from "react-router-dom";
import Home from "../../../assets/images/MB/home.png";
import Explore from "../../../assets/images/MB/explore.png";
import Notifications from "../../../assets/images/MB/notifications.png";
import Messages from "../../../assets/images/MB/messages.png";
import Search from "../../../assets/images/MB/search.png";

const MBMobileBottom = () => {
	return (
		<>
			<MobileBottom>
				<MobileBottomIcons>
					<li>
						<NavLink to={"/microblogging"}>
							<img src={Home} alt="icon" />
						</NavLink>
					</li>
					<li>
						<NavLink to={"/microblogging/explore"}>
							<img src={Search} alt="icon" />
						</NavLink>
					</li>
					<li>
						<NavLink to={"/microblogging/notifications"}>
							<img src={Notifications} alt="icon" />
						</NavLink>
					</li>
					<li>
						<NavLink to={"/messaging"}>
							<img src={Messages} alt="icon" />
						</NavLink>
					</li>
				</MobileBottomIcons>
			</MobileBottom>
		</>
	);
};

export default MBMobileBottom;
