import React, { useContext, useEffect, useState } from "react";
import "./PrivateFriendrequest.scss";
import banner from "../../assets/images/profile/banner.png";
import profile from "../../assets/icons/user-profile.png";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { UserContext } from "../../store/UserDetailsStore";
import { Navigate, useNavigate } from "react-router-dom";
import {
	CancelFriendSentRequest,
	CancelReceivedFriendRequest,
	ConfirmRequestFriend,
	GetAllFriendList,
	GetAllFriendListSent,
	GetAllRequestFriendsList,
	RemoveFriend,
} from "../../utils/apiCalls";

const PrivateFriendrequest = () => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const { isLoginUserDetails } = useContext(UserContext);
	const [requestData, setRequestData] = useState([]);
	const [userList, setUserList] = useState([]);
	const [allFriendRequestData, setAllFriendRequestData] = useState([]);

	const getAllFriendRequetSent = () => {
		GetAllFriendListSent((res) => {
			const { message, data, status } = res;
			if (status === 200) {
				setRequestData(data);
			} else {
				setRequestData([]);
			}
		});
	};

	const getAllRequestFriendsList = () => {
		GetAllRequestFriendsList((res) => {
			const { message, data, status } = res;
			if (status === 200) {
				setAllFriendRequestData(data);
			} else {
				setAllFriendRequestData([]);
			}
		});
	};

	const handleCancelRequest = (id) => {
		if (id) {
			const payload = {
				recipientId: id,
			};
			if (payload) {
				CancelFriendSentRequest((res) => {
					if (res.status === 200) {
						getAllFriendRequetSent();
					}
				}, payload);
			}
		}
	};

	const handleAcceptFriendRequest = (id) => {
		if (id) {
			const payload = {
				requesterId: id,
			};
			if (payload) {
				ConfirmRequestFriend((res) => {
					if (res.status === 200) {
						getAllRequestFriendsList();
					}
				}, payload);
			}
		}
	};

	const handleCanCelFriendRequest = (id) => {
		if (id) {
			const payload = {
				requesterId: id,
			};
			if (payload) {
				CancelReceivedFriendRequest((res) => {
					if (res.status === 200) {
						getAllRequestFriendsList();
					}
				}, payload);
			}
		}
	};

	const getAllUserData = () => {
		const payload = {
			searchId: "",
		};
		GetAllFriendList((res) => {
			const { status, messsage, data } = res;
			if (status === 200) {
				setUserList(data);
			} else {
				setUserList([]);
			}
		}, payload);
	};

	const handleRemoveFriend = (id) => {
		if (id) {
			const payload = {
				friendId: id,
			};
			if (payload) {
				RemoveFriend((res) => {
					if (res.status === 200) {
						getAllUserData();
					}
				}, payload);
			}
		}
	};

	const handleFriends = () => {
		getAllUserData();
	};

	const handleAllRequestFriendsList = () => {
		getAllRequestFriendsList();
	};

	const handleAllFriendRequetSent = () => {
		getAllFriendRequetSent();
	};

	useEffect(() => {
		getAllFriendRequetSent();
		getAllRequestFriendsList();
		getAllUserData();
	}, []);

	const handleFriendProfile = (id) => {
		sessionStorage?.setItem("searchId", id);
		navigate("/publicprofile");
	};

	return (
		<>
			{token ? (
				<div className="privatefriend">
					<HomeHeader />
					<div className="privatefriend_container">
						<div className="privatefriend_banner">
							{isLoginUserDetails?.coverUrl ? (
								<img src={isLoginUserDetails?.coverUrl} alt="banner" />
							) : (
								<img src={banner} alt="banner" />
							)}
						</div>
						<div className="privatefriend_user">
							<div className="privatefriend_user_details">
								<div className="privatefriend_user_details_image">
									{isLoginUserDetails?.profilePictureUrl === null ||
									isLoginUserDetails?.profilePictureUrl === undefined ? (
										<img src={profile} alt="profile" />
									) : (
										<img src={isLoginUserDetails?.profilePictureUrl} alt="profile" />
									)}
								</div>
								<div className="privatefriend_user_details_content">
									<div className="privatefriend_user_details_content_name">
										{isLoginUserDetails?.name === null || isLoginUserDetails?.name === undefined ? (
											<h3>George Kajaia</h3>
										) : (
											<h3>{isLoginUserDetails?.name}</h3>
										)}
									</div>
									<div className="privatefriend_user_details_content_follow">
										<span>928 followers</span>
										<p>*</p>
										<span>3k following</span>
									</div>
								</div>
							</div>

							<div className="privatefriend_user_data">
								<div className="privatefriend_user_data_sections">
									<div className="privatefriend_user_data_sections_right">
										<div className="privatefriend_user_data_sections_right_tabsection">
											<Tabs>
												<TabList>
													<div className="tablist-custom ">
														<div className="tabnames">
															<Tab onClick={handleFriends}>Friends</Tab>
															<Tab onClick={handleAllRequestFriendsList}>Friend Request</Tab>
															<Tab onClick={handleAllFriendRequetSent}>Sent</Tab>
														</div>
													</div>
												</TabList>
												<TabPanel>
													<div className="privatefriend_user_data_sections_right_tabsection_tabpanel1_friendscards">
														{userList?.map((data, index) => {
															return (
																<div
																	className="privatefriend_user_data_sections_right_tabsection_tabpanel1_friendscard "
																	key={index}
																>
																	<div
																		className="friendscard-image"
																		onClick={() => {
																			handleFriendProfile(data?.userId);
																		}}
																	>
																		{data?.profilePicture ? (
																			<img src={data?.profilePicture} alt="" />
																		) : (
																			<img src={profile} alt="" />
																		)}
																	</div>
																	<div
																		className="friendscard-content"
																		onClick={() => {
																			handleFriendProfile(data?.userId);
																		}}
																	>
																		{data?.fullName ? (
																			<p>{data?.fullName}</p>
																		) : (
																			<p>Anonymous User</p>
																		)}
																	</div>
																	<div className="friendscard-buttons">
																		<button
																			onClick={() => {
																				handleRemoveFriend(data?.userId);
																			}}
																		>
																			Delete
																		</button>
																	</div>
																</div>
															);
														})}
													</div>
												</TabPanel>
												<TabPanel>
													<div className="privatefriend_user_data_sections_right_tabsection_tabpanel2_request">
														{allFriendRequestData?.map((data, index) => {
															return (
																<div
																	className="privatefriend_user_data_sections_right_tabsection_tabpanel2_one"
																	key={index}
																>
																	<div className="request-image">
																		{data?.requester?.profilePicture ? (
																			<img
																				src={data?.requester?.profilePicture}
																				alt=""
																				onClick={() => {
																					handleFriendProfile(data?.requester?.userId);
																				}}
																			/>
																		) : (
																			<img
																				src={profile}
																				alt=""
																				onClick={() => {
																					handleFriendProfile(data?.requester?.userId);
																				}}
																			/>
																		)}
																	</div>
																	<div className="request-content">
																		{data?.requester.fullName ? (
																			<p
																				onClick={() => {
																					handleFriendProfile(data?.requester?.userId);
																				}}
																			>
																				{data?.requester.fullName}
																			</p>
																		) : (
																			<p
																				onClick={() => {
																					handleFriendProfile(data?.requester?.userId);
																				}}
																			>
																				Anonymous User
																			</p>
																		)}
																		<div className="request-buttons">
																			<button
																				className="btn1"
																				onClick={() => {
																					handleAcceptFriendRequest(
																						data?.requester?.userId,
																					);
																				}}
																			>
																				Confirm
																			</button>
																			<button
																				className="btn2"
																				onClick={() => {
																					handleCanCelFriendRequest(
																						data?.requester?.userId,
																					);
																				}}
																			>
																				Delete
																			</button>
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												</TabPanel>
												<TabPanel>
													<div className="privatefriend_user_data_sections_right_tabsection_tabpanel3_sent">
														{requestData?.map((data, ind) => {
															return (
																<div
																	className="privatefriend_user_data_sections_right_tabsection_tabpanel3_first"
																	key={ind}
																>
																	<div
																		className="sent-image"
																		onClick={() => {
																			handleFriendProfile(data?.recipient?.userId);
																		}}
																	>
																		{data?.recipient?.profilePicture ? (
																			<img src={data?.recipient?.profilePicture} alt="" />
																		) : (
																			<img src={profile} alt="" />
																		)}
																	</div>
																	<div
																		className="sent-content"
																		onClick={() => {
																			handleFriendProfile(data?.recipient?.userId);
																		}}
																	>
																		{data?.recipient?.fullName ? (
																			<p>{data?.recipient?.fullName}</p>
																		) : (
																			<p>Anonymous User</p>
																		)}
																	</div>
																	<div className="sent-buttons">
																		<button
																			onClick={() => {
																				handleCancelRequest(data?.recipient?.userId);
																			}}
																		>
																			Cancel
																		</button>
																	</div>
																</div>
															);
														})}
													</div>
												</TabPanel>
											</Tabs>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default PrivateFriendrequest;
