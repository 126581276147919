const Index = ({ color = "#fff", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" fill="none">
			<line x1="1" y1="4.25" x2="11.3125" y2="4.25" stroke={color} />
			<circle cx="14.5938" cy="4.28125" r="2.78125" stroke={color} />
			<line
				y1="-0.5"
				x2="10.3125"
				y2="-0.5"
				transform="matrix(-1 -8.74228e-08 -8.74228e-08 1 17.875 13.1875)"
				stroke={color}
			/>
			<circle cx="3.28125" cy="3.28125" r="2.78125" transform="matrix(-1 0 0 1 7.5625 9.4375)" stroke={color} />
		</svg>
	);
};

export default Index;
