import React from "react";
import "./PrivacyPolicy.scss";
import logo from "../../assets/icons/privacy_policy.png";
import HeaderPrivacy from "./HeaderPrivacy";

const PrivacyPolicy = () => {
	return (
		<>
			<HeaderPrivacy icon={logo} title={"Privacy Policy"} />
			<div className="pp">
				<div className="pp_container">
					<div className="pp_container_data">
						<h4>Privacy Policy</h4>
						<p>
							At Buzzgalactic, we want to ensure that you have a clear understanding of how we handle your
							information, and we encourage you to review our Privacy Policy for a comprehensive explanation. This
							will empower you to make informed decisions about how you interact with our services.
						</p>
						<p>
							In our Privacy Policy, we detail the collection, usage, sharing, retention, and transfer of
							information. We also outline your rights as a user. To enhance clarity, each section of the Policy
							includes practical examples and simplified language to make our practices more accessible. We have
							also included links to additional resources that allow you to delve deeper into privacy topics that
							interest you.
						</p>
						<p>
							We are committed to giving you control over your privacy, and as such, we provide guidance on how to
							manage your information within the settings of the Products you use. This way, you can tailor your
							experience to suit your preferences.
						</p>
						<h4>How do we use your information?</h4>
						<p>
							We utilize the information we collect to deliver a personalized experience to you, including
							customized advertisements, in addition to the other specific purposes explained below.
						</p>
						<p>
							For certain purposes, we may employ the information across our range of products and your various
							devices. The information used for these purposes undergoes automatic processing by our systems.
							However, in some instances, we also employ manual review processes to access and assess your
							information.
						</p>
						<p>
							To minimize the reliance on information tied to individual users, we may, in certain cases,
							de-identify, aggregate, or anonymize the data. This process ensures that the information can no longer
							be associated with your specific identity. We then use this anonymized information in the same manner
							as your identifiable data, as described in this section.
						</p>
						<h4>Ways we use your information </h4>
						<h3>To Provide, Personalize, and Improve Our Products</h3>
						<p>
							We leverage the information at our disposal to offer and enhance our products. This encompasses
							tailoring features, content, and recommendations, such as your news feed, search results, stories, and
							advertisements. For these purposes, we employ data that you opt to provide, but not to target ads
							specifically to you.
						</p>
						<h3>To Promote Safety, Security, and Integrity</h3>
						<p>
							The information we gather is instrumental in safeguarding individuals from harm and maintaining the
							safety and integrity of our products. This includes implementing measures to protect against malicious
							activities and ensuring a secure user experience.
						</p>
						<h3>To Provide Measurement, Analytics, and Business Services</h3>
						<p>
							Many individuals and businesses utilize our products to operate and promote their ventures. We assist
							them in gauging the effectiveness of their ads, content, products, and services. This entails
							providing measurement and analytics services to help businesses optimize their performance.
						</p>
						<h3>To Communicate with You</h3>
						<p>
							We communicate with you based on the information you've provided, such as the contact details you've
							furnished in your profile. This allows us to keep you informed about important updates, notifications,
							and relevant information.
						</p>
						<h3>To Research and Innovate for Social Good</h3>
						<p>
							We harness the information we possess, along with data from researchers and publicly available
							sources, professional organizations, and charitable groups, to conduct and support research. This
							research contributes to social betterment and the development of innovative solutions.
						</p>
						<h4>How is your information shared on Buzzgalactic Products or with integrated partners?</h4>
						<p>
							You have the option to connect with individuals who use our products. When you choose to do so,
							integrated partners may receive information about you and your activities. It's important to note that
							these integrated partners can always access information that you have made public on our products.
							Your privacy settings on our platform can determine which aspects of your information are visible to
							these integrated partners.
						</p>
						<h4>How do we share information with third parties?</h4>
						<p>
							We do not sell any of your information to anyone, and we have no intention of doing so in the future.
							Furthermore, we have stringent rules in place for third-party developers and other partners,
							stipulating how they can and cannot utilize and disclose the information we provide to them. Your data
							privacy is of utmost importance to us, and we are committed to safeguarding it.
						</p>
						<p>we may share information with other third parties in the following circumstances:</p>
						<p>
							<span>In Response to Legal Requests: </span> We may disclose information to other parties when
							required by legal requests, court orders, or to comply with applicable laws and regulations. This is
							done to fulfill our legal obligations.
						</p>
						<p>
							<span>To Prevent Harm: </span> Information sharing may also occur to prevent harm, protect
							individuals, or address security concerns. Ensuring the safety and well-being of our users and the
							community is a top priority.
						</p>
						<p>
							<span>Business Transfers: </span> In the event of a sale or transfer of all or part of our business to
							another entity, we may transfer your information to the new owner as part of that transaction, but
							only to the extent permitted by applicable laws. This is done to ensure the continued operation and
							support of our services under new ownership.
						</p>
						<h4>How can you manage or delete your information and exercise your rights?</h4>
						<p>
							We provide you with a range of tools that empower you to view, manage, download, and delete your
							information. These tools are designed to give you control over your data. Additionally, you can
							further manage your information by accessing the settings within the specific products you use.
						</p>
						<p>
							It's important to note that you may also have additional privacy rights granted by applicable laws,
							which can vary depending on your location and the specific legal regulations that apply to you. These
							rights may include the ability to request access to your data, request the deletion of your data, or
							exercise other data protection rights, as mandated by the law. We are committed to upholding these
							rights and ensuring that your data privacy is protected in accordance with the law.
						</p>
						<h4>How long do we keep your information for?</h4>
						<p>
							Our data retention practices are guided by the principle of retaining information only as long as
							necessary to fulfill specific purposes. We determine the duration of retention on a case-by-case
							basis, considering several factors. Here's what we take into account when deciding how long to keep
							your information:
						</p>
						<p>
							<span>Operational Necessity: </span> We retain information that is essential for the operation and
							provision of our products. For instance, we need to keep certain data to maintain your account,
							provide services, and ensure a seamless user experience.
						</p>
						<p>
							<span>Feature-Specific Considerations: </span> The duration for which we retain information can vary
							depending on the feature it is associated with and how that feature operates. For example, messages
							sent using a feature like Messenger's vanish mode may be retained for a shorter period than regular
							messages.
						</p>
						<p>
							<span>Legal Obligations: </span> We retain information for as long as necessary to meet specific legal
							obligations. This includes compliance with data retention requirements stipulated by applicable laws
							and regulations.
						</p>
						<p>
							<span>Legitimate Interests: </span> We may need to retain information for other legitimate purposes,
							such as preventing harm, investigating potential violations of our terms and policies, promoting
							safety, security, and integrity, or protecting our rights, property, or products.
						</p>
						<p>
							Our data retention practices are designed to strike a balance between ensuring the functionality of
							our services, meeting legal obligations, and safeguarding our community's safety and well-being. We
							are committed to managing data responsibly and in accordance with these considerations.
						</p>
						<h4>How will you know the policy has changed?</h4>
						<p>
							We value transparency, and in the event of significant changes to this Policy, we will provide you
							with prior notice. You will have the opportunity to review the updated Policy before deciding whether
							to continue using our products. Your understanding and consent matter to us, and we are committed to
							keeping you informed of any material changes to our policies.
						</p>
						<h3>how we process your information</h3>
						<p>
							To offer and enhance Meta Products, we engage in various activities that involve the collection,
							storage, and, where applicable, sharing of information. These activities encompass profiling,
							reviewing, curating, and, in some cases, manual (human) review. Our objectives include:
						</p>
						<p>
							<span>Creating and Maintaining Your Account and Profile: </span> This involves the setup and
							maintenance of your Meta Products account, including your profile information.
						</p>
						<p>
							<span>Connecting Your Meta Products Account: </span> We enable the connection of your Meta Products
							account, including public profile information, with integrated partners, allowing you to sign in or
							share your information.
						</p>
						<p>
							<span>Facilitating Content Sharing: </span> We provide the infrastructure for sharing content and
							status updates with others.
						</p>
						<p>
							<span>Offering and Curating Features: </span> We design and manage features to enhance your experience
							on our platform.
						</p>
						<p>
							<span>Providing Messaging Services: </span> This includes services for text, voice, and video calls,
							as well as facilitating connections with others.
						</p>
						<p>
							<span>Delivering Advertising Products: </span> We utilize information to offer advertising products,
							including personalized ad experiences.
						</p>
						<p>
							<span>Enabling Content Creation: </span> Information is used to understand and support the creation of
							content, such as text, images, and videos.
						</p>
						<p>
							<span>Conducting Analytics: </span> We utilize data to perform analytical assessments for various
							purposes. Additionally, we utilize information for product development, research, and testing:
						</p>
						<p>
							<span>Product Testing and Troubleshooting: </span> We assess whether products are functioning as
							intended, troubleshoot issues when they arise, and evaluate new products and features to ensure they
							meet our quality standards.
						</p>
						<p>
							<span>Collecting Feedback: </span> We gather user feedback on our product ideas and features to make
							informed decisions.
						</p>
						<p>
							<span>Conducting Surveys and Research: </span> We conduct surveys and research to gain insights into
							user preferences, assess user satisfaction with our products and brands, and identify areas for
							improvement.
						</p>
						<p>
							We employ the information you've provided, such as contact information available on your profile, to
							facilitate communication. This communication can take the form of emails or in-product notices,
							serving various purposes, including:
						</p>
						<p>
							<span>Product-Related Communication: </span> We may contact you via email or in-product notifications
							concerning Meta Products, product-related matters, and updates.
						</p>
						<p>
							<span>Research Communication: </span> Communication may also pertain to research activities, which may
							include seeking your input or feedback on our products.
						</p>
						<p>
							<span>Terms and Policies Updates: </span> We use this channel to inform you about changes to our terms
							and policies, ensuring you are kept informed.
						</p>
						<p>
							<span>Response to Contact: </span> When you reach out to us, we use your provided contact information,
							such as your email address, to respond to your inquiries or concerns. This allows us to provide
							efficient and effective customer support.
						</p>
						<h4>How to contact Buzzgalactic</h4>
						<p>
							If you have any questions about this policy or have any questions, complaints or requests regarding
							your information, you can contact us on <a>buzzgalactic@gmail.com</a>
						</p>
					</div>
				</div>
			</div>
		</>
	);
};

export default PrivacyPolicy;
