import InputField from "../../../../Inputs/Input";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";
import Textarea from "../../../../Inputs/Textarea";
import { FormTitleInfo, FormRowWrapper, Form, FormWrapper, FormTitle, BtnWrapper, btnStyleOverrides } from "../index.styled";
import SecondaryBtn from "../../../../Buttons/Secondary";
import PrimaryBtn from "../../../../Buttons/Primary";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Autocomplete from "../../../../Inputs/Autocomplete";

const validationSchema = Yup.object({
	companyName: Yup.string().required("Company Name is required"),
	websiteURL: Yup.string().url("Invalid URL"),
	typeOfBusiness: Yup.string().required("Type of Business is required"),
	profilePicture: Yup.string().required("Profile Picture is required"),
});

const CompanySignUpForm = ({ setProfilePicture, onSubmit }) => {
	const navigate = useNavigate();
	const handleBack = () => {
		navigate(-1);
	};

	const formik = useFormik({
		initialValues: {
			companyName: "",
			websiteURL: "",
			location: "",
			typeOfBusiness: "",
			industrySector: "",
			companySize: "",
			profilePicture: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onSubmit(values);
		},
	});
	return (
		<FormWrapper onSubmit={formik.handleSubmit} onBlur={formik.handleBlur} onChange={formik.handleChange}>
			<FormTitle>Provide Your Company Information</FormTitle>
			<Form>
				<FormTitleInfo>Company Basic Information</FormTitleInfo>
				<FormRowWrapper>
					<InputField
						name={"companyName"}
						label={"Company Name"}
						required
						error={formik.touched.companyName && Boolean(formik.errors.companyName)}
						helperText={formik.touched.companyName && formik.errors.companyName}
					/>
					<InputField
						name={"typeOfBusiness"}
						label={"Type of Business"}
						required
						error={ formik.touched.typeOfBusiness && Boolean(formik.errors.typeOfBusiness) }
						helperText={ formik.touched.typeOfBusiness && formik.errors.typeOfBusiness }
						placeholder={"LLC, Corporation, Partnership"}
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField
						name={ "industrySector" }
						label={ "Industry Sector" }
						error={ formik.touched.industrySector && Boolean(formik.errors.industrySector) }
						helperText={ formik.touched.industrySector && formik.errors.industrySector }
						onChange={ formik.setFieldValue }
					/>
					<InputField
						name={ "companySize" }
						label={ "Company Size" }
						error={ formik.touched.companySize && Boolean(formik.errors.companySize) }
						helperText={ formik.touched.companySize && formik.errors.companySize }
						onChange={ formik.setFieldValue }
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<Autocomplete
						name={"location"}
						label={"Location"}
						placeholder={"eg. Austria"}
						error={ formik.touched.location && Boolean(formik.errors.location) }
						helperText={ formik.touched.location && formik.errors.location }
						onChange={formik.setFieldValue}
					/>
					<InputFieldWithUpload
						name={ "profilePicture" }
						onChange={ setProfilePicture }
						label={ "Company Logo" }
						required
						error={ formik.touched.profilePicture && Boolean(formik.errors.profilePicture) }
						helperText={ formik.touched.profilePicture && formik.errors.profilePicture } />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField
						name={ "websiteURL" }
						label={ "Website URL" }
						error={ formik.touched.websiteURL && Boolean(formik.errors.websiteURL) }
						helperText={ formik.touched.websiteURL && formik.errors.websiteURL } />
				</FormRowWrapper>
				<FormRowWrapper childCount={1}>
					<Textarea
						name={ "about" }
						label={ "About" }
						rows={ 4 }
						mobileRows={ 10 }
					/>
				</FormRowWrapper>
			</Form>
			<BtnWrapper>
				<SecondaryBtn label={"Back"} onClick={handleBack} overrides={btnStyleOverrides} />
				<PrimaryBtn label={"Next"} overrides={btnStyleOverrides} />
			</BtnWrapper>
		</FormWrapper>
	);
};

export default CompanySignUpForm;
