import React, { useEffect, useState } from "react";
import locationMark from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/user-profile-backsquare.png";
import editable from "../../../../../assets/images/editable.png";
import editablenoborder from "../../../../../assets/images/editablenoborder.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
} from "../../../styles/index.styled.js";
import { ProfilesWrapper } from "../../../../Education/styles/index.styled.js";
import { GetEducationClientProfile } from "../../../../../utils/apiCalls.js";
import Loader from "../../../../../components/loader/index.js";

const ClientPrivate = () => {
	const [educationClientData, setEducationClientData] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const location = useLocation();
	const userData = location?.state?.userData;
	const handlePublicView = () => {
		navigate("/education/client/profile/public");
	};
	const getEducationClientProfile = () => {
		setIsLoader(true);
		if (userData) {
			const payload = {
				educationTypeId: userData?.educationTypeId || userData?.accountTypeId,
				educationId: userData?.educationId || userData?.moduleId,
			};
			GetEducationClientProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setIsLoader(false);
					setEducationClientData(data);
				} else {
					setIsLoader(false);
					setEducationClientData();
				}
			}, payload);
		}
	};

	useEffect(() => {
		getEducationClientProfile();
	}, []);
	return (
		<>
			{token ? (
				isLoader ? (
					<Loader />
				) : (
					<Wrapper>
						<ProfileWrapper>
							<ProfileSections>
								<SectionTitle>Client</SectionTitle>
								<Profile>
									<ProfileDetailsWrapper>
										<ProfileImageWrapper>
										<img src={educationClientData?.profilePicture || ProfilePicture} alt="profile" />
											<img src={editable} className="imgedit" alt="profile" />
										</ProfileImageWrapper>
										<UserDetailsWrapper>
											<UserNameWrapper>
												<UserName>{educationClientData?.fullName || "Anonymous User"}</UserName>
												<img
													src={editablenoborder}
													className="imgedit"
													alt="profile"
													style={{
														display: "block",
													}}
												/>
											</UserNameWrapper>
											<UserAddress>
												<img src={locationMark} alt="location-icon" />
												{educationClientData?.location || "No Location available"}
											</UserAddress>
											{/* To be visible on mobile only */}
											<UserProfileActions
												hideOnDesktop
												style={{
													marginBlockEnd: "14px",
												}}
											>
												<Button
													buttonClick={handlePublicView}
													styles={{
														width: "fit-content",
														backgroundColor: "#FCFDFF",
														color: "#6B6B6B",
														borderColor: "#C2C2C2",
													}}
												>
													See Public View
												</Button>
											</UserProfileActions>
										</UserDetailsWrapper>
									</ProfileDetailsWrapper>
									{/* To be visible on desktop only */}
									<UserProfileActions
										hideOnMobile
										style={{
											marginBlockEnd: "14px",
										}}
									>
										<Button
											buttonClick={handlePublicView}
											styles={{
												width: "fit-content",
												backgroundColor: "#FCFDFF",
												marginLeft: "auto",
												color: "#6B6B6B",
												borderColor: "#C2C2C2",
											}}
										>
											See Public View
										</Button>
									</UserProfileActions>
								</Profile>

								<MoreDetailWrapper>
									<ProfilesAndAboutWrapper>
										<ProfilesWrapper>
											<Profiles fillAvailable>
												<ProfileHeading>Other Profiles</ProfileHeading>
												<ProfileList>
												{educationClientData?.userName ? (
														<ProfileName>
															<ProfileUserName>userName</ProfileUserName>{" "}
															{educationClientData?.userName}
														</ProfileName>
													) : (
														"No Data Available"
													)}
													{educationClientData?.shortVideoUsername && (
														<ProfileName>
															<ProfileUserName>shortVideoUsername</ProfileUserName>{" "}
															{educationClientData?.shortVideoUsername}
														</ProfileName>
													)}
												</ProfileList>
											</Profiles>
										</ProfilesWrapper>
										<About>
											<AboutTitle>
												About <img src={editablenoborder} alt="" />
											</AboutTitle>
											<AboutDesc>
											{educationClientData?.about || "No Data available"}
											</AboutDesc>
											<More>more...</More>
										</About>
									</ProfilesAndAboutWrapper>
									<FeedbackWrapper>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment1}
												commentimagestyle={{
													marginLeft: "2rem",
												}}
												commenttitle="Thanks"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment}
												commenttitle="Reply"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
									</FeedbackWrapper>
								</MoreDetailWrapper>
							</ProfileSections>
						</ProfileWrapper>
					</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default ClientPrivate;
