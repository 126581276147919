import styled from "@emotion/styled";

const LeftNav = styled.div`
	grid-column: span 2;
`;

const TVContent = styled.div`
	grid-column: span 10;
`;

export { LeftNav, TVContent };
