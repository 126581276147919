import { Route, Routes, useLocation } from "react-router-dom";
import ClientSignup from "../pages/IT/Client/Signup";
import HomePage from "../pages/IT/Home";
import CompanySignUp from "../pages/IT/Company/Signup";
import FreelancerSignup from "../pages/IT/Freelancer/Signup";
import FreelancerProfilePrivate from "../pages/IT/Freelancer/Profile/Private";
import FreelancerProfilePublic from "../pages/IT/Freelancer/Profile/Public";
import EmployeeSignup from "../pages/IT/Employee/Signup";
import ITSearchResult from "../pages/IT/SearchResult";
import CompanyProfilePrivate from "../pages/IT/Company/Profile/Private";
import CompanyProfilePublic from "../pages/IT/Company/Profile/Public";
import ClientProfilePrivate from "../pages/IT/Client/Profile/Private";
import ClientProfilePublic from "../pages/IT/Client/Profile/Public";
import EmployeeProfilePrivate from "../pages/IT/Employee/Profile/Private";
import EmployeeProfilePublic from "../pages/IT/Employee/Profile/Public";
import Heading from "../components/Heading/Heading";
import InformationTechnology from "../assets/icons/it/it";
import Microblogging from "../components/IT/Microblogging";
import SubHeader from "../components/IT/Header";
import React, { useEffect, useState } from "react";
import SignupMain from "../pages/IT/SingupMain";
import { connect } from "react-redux";
import RegistrationModal from "../components/IT/RegistrationModal";
import ITprofile from "../components/IT/ITprofile";

const ITRoutes = ({ userData, setActiveModule, setSignUpPopupToggle, signUpPopup }) => {
	const location = useLocation();
	const [profileData, setProfileData] = useState({});
	const { pathname } = location;
	const [isIT, setIsIT] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path?.includes("it") && !path.includes("microblogging") && !path.includes("signup") && path?.length > 1) {
			setActiveModule("it");
			setIsIT(true);
		} else setIsIT(false);
	}, [pathname]);

	useEffect(() => {
		if (!userData?.isLoading) setProfileData(userData?.data || null);
	}, [userData]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const isOpen = Boolean(anchorEl);
	const id = isOpen ? "simple-popover" : undefined;

	return (
		<>
			<RegistrationModal open={signUpPopup} handleClose={() => setSignUpPopupToggle(!signUpPopup)} />
			{isIT && !userData?.isLoading && (
				<>
					<Heading
						icon={InformationTechnology}
						title={"Information Technology"}
						moduleHomeRedirect={"/it/home"}
						user={profileData?.firstName}
						handleClick={handleClick}
						id={id}
					/>
					<SubHeader user={profileData?.firstName} />
				</>
			)}
			<ITprofile open={isOpen} id={id} handleClose={handleClose} anchorEl={anchorEl} />
			<Routes>
				<Route path="/it">
					<Route index element={<SignupMain handleClick={handleClick} id={id} />} />
					<Route path="home" element={<HomePage />} />
					<Route path="client">
						<Route path="signup" element={<ClientSignup />} />
						<Route path="profile">
							<Route path="private" element={<ClientProfilePrivate />} />
							<Route path="public" element={<ClientProfilePublic />} />
						</Route>
					</Route>
					<Route path="company">
						<Route path="signup" element={<CompanySignUp />} />
						<Route path="profile">
							<Route path="private" element={<CompanyProfilePrivate />} />
							<Route path="public" element={<CompanyProfilePublic />} />
						</Route>
					</Route>
					<Route path="freelancer">
						<Route path="signup" element={<FreelancerSignup />} />
						<Route path="profile">
							<Route path="private" element={<FreelancerProfilePrivate />} />
							<Route path="public" element={<FreelancerProfilePublic />} />
						</Route>
					</Route>
					<Route path="employee">
						<Route path="signup" element={<EmployeeSignup />} />
						<Route path="profile">
							<Route path="private" element={<EmployeeProfilePrivate />} />
							<Route path="public" element={<EmployeeProfilePublic />} />
						</Route>
					</Route>
					<Route path="search" element={<ITSearchResult />} />
					<Route path="microblogging" element={<Microblogging />} />
				</Route>
			</Routes>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state.it?.profile,
		signUpPopup: state.it?.signUpPopup,
	};
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MODULE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_IT",
			payload: data,
		}),
});
export default connect(mapStateToProps, mapPropsToState)(ITRoutes);
