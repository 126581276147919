import React, { useEffect, useState } from "react";
import "./SVSubHeader.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";

import { IconButton, Input, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import hamburger from "../../../assets/images/MB/hamburger.png";
import "react-tabs/style/react-tabs.css";
import { GetShortVideoUserDetails } from "../../../utils/apiCalls";

const SVSubHeader = ({ leftsearch, modulTitle, lefttabs, rightmobilemenu, shortVideosProfile }) => {
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState("ForYou");
	const handleTabClick = (tab) => {
		setActiveTab(tab);
	};
 
	const handleModuleNavigation = () => {
		const payload = {
			shortVideoId: shortVideosProfile.shortVideoId,
		};
		GetShortVideoUserDetails((res) => {
			if (res.data?.module === "Information Technology") {
				navigate(`it/${res.data?.accountType.toLowerCase()}/profile/private`, { state: { userData: res.data } });
			} else if (res.data?.module === "Education") {
				navigate(`education/${res.data?.accountType.toLowerCase()}/profile/private`, { state: { userData: res.data } });
			} else {

				navigate(`${res.data?.module.toLowerCase()}/${res.data?.accountType.toLowerCase()}/profile/private`, { state: { user: res.data } });

			}
		}, payload);
		
	};

	const handleSearchVideos = () => {
		navigate("/shorts/search");
	};
	return (
		<div className="svsubheader_header">
			<div className="svsubheader_header_main">
				<div className="svsubheader_header_main_left">
					{leftsearch ? (
						<div className="svsubheader_header_main_left_search">
							<Input
								onClick={handleSearchVideos}
								style={{ background: "white", padding: "0.2vw 1vw ", borderRadius: "70px" }}
								startAdornment={
									<InputAdornment position="start">
										<IconButton style={{ color: "#989898", margin: "0px" }}>
											<SearchIcon />
										</IconButton>
									</InputAdornment>
								}
								placeholder="Search"
							/>
						</div>
					) : (
						<div className="svsubheader_header_main_left_emptysearch"></div>
					)}
					{lefttabs ? (
						<div className="svsubheader_header_main_left_tabsection">
							<ul>
								<li className={activeTab === "ForYou" ? "active" : ""}>
									<NavLink onClick={() => handleTabClick("ForYou")} to="/shorts/feed">
										For you
									</NavLink>
								</li>
								<li className={activeTab === "Following" ? "active" : ""}>
									<NavLink onClick={() => handleTabClick("Following")} to="/shorts/feed/following">
										Following
									</NavLink>
								</li>
								<li
									className={activeTab === "Rewardfeed" ? "active" : ""}
									onClick={() => handleTabClick("Rewardfeed")}
								>
									<NavLink to="/shorts/feed/rewards">Rewardfeed</NavLink>
								</li>
								<li
									className={activeTab === "Once-monthly" ? "active" : ""}
									onClick={() => handleTabClick("Once-monthly")}
								>
									<NavLink to="/shorts/feed/monthly">Once-monthly</NavLink>
								</li>
								<li
									className={activeTab === "Marketlink" ? "active" : ""}
									onClick={() => handleTabClick("Marketlink")}
								>
									<NavLink to="/shorts/feed/marketlink">Marketlink</NavLink>
								</li>
							</ul>
						</div>
					) : (
						<div className="svsubheader_header_main_left_emptytabsection"></div>
					)}
				</div>
				{modulTitle && (
					<div className="svsubheader_header_main_right" onClick={handleModuleNavigation}>
						<p>{modulTitle === "ITProfileType" ? "Information Technology" :  modulTitle === "educationProfileType" ? "Education" : modulTitle}</p>
						{rightmobilemenu && <img src={hamburger} alt="" />}
					</div>
				)}
			</div>
		</div>
	);
};

export default SVSubHeader;
