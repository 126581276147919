import React from "react";
import location from "../../../../../assets/images/icons/location.png";
import { Navigate, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/IT/itprofileclient.png";
import editable from "../../../../../assets/images/editable.png";
import editablenoborder from "../../../../../assets/images/editablenoborder.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserWorkLocationAndSkills,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	EducationHistory,
	EducationTitle,
	Education,
	SkillsWrapper,
	SkillsTitle,
	Skills,
	ProfileHeading,
	ManageOtherProfile,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
} from "../../../styles/index.styled.js";
import { ProfilesWrapper } from "../../../../Services/styles/index.styled.js";

const EmployeePrivate = () => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const handlePublicView = () => {
		navigate("/services/employee/profile/public");
	};

	return (
		<>
			{token ? (
				<Wrapper>
					<ProfileWrapper>
						<ProfileSections>
							<SectionTitle>Regular Employee</SectionTitle>
							<Profile>
								<ProfileDetailsWrapper>
									<ProfileImageWrapper>
										<img src={ProfilePicture} alt="profile" />
										<img src={editable} className="imgedit" alt="profile" />
									</ProfileImageWrapper>
									<UserDetailsWrapper>
										<UserNameWrapper>
											<UserName>Thomas Mark</UserName>
											<img
												src={editablenoborder}
												className="imgedit"
												alt="profile"
												style={{
													display: "block",
												}}
											/>
										</UserNameWrapper>
										<UserAddress>
											<img src={location} alt="location-icon" />
											United States, India - 10:46 am local time
										</UserAddress>
										<UserWorkLocationAndSkills>
											<span>Software developer in Paychex</span>
											<span>Java, Python, Angular JS, React JS, Node JS</span>
										</UserWorkLocationAndSkills>
										{/* To be visible on mobile only */}
										<UserProfileActions
											hideOnDesktop
											style={{
												marginBlockEnd: "14px",
											}}
										>
											<Button
												buttonClick={handlePublicView}
												styles={{
													width: "fit-content",
													backgroundColor: "#FCFDFF",
													color: "#6B6B6B",
													borderColor: "#C2C2C2",
												}}
											>
												See Public View
											</Button>
										</UserProfileActions>
									</UserDetailsWrapper>
								</ProfileDetailsWrapper>
								{/* To be visible on desktop only */}
								<UserProfileActions
									hideOnMobile
									style={{
										marginBlockEnd: "14px",
									}}
								>
									<Button
										buttonClick={handlePublicView}
										styles={{
											width: "fit-content",
											backgroundColor: "#FCFDFF",
											marginLeft: "auto",
											color: "#6B6B6B",
											borderColor: "#C2C2C2",
										}}
									>
										See Public View
									</Button>
								</UserProfileActions>
							</Profile>

							<MoreDetailWrapper>
								<ProfilesAndAboutWrapper>
									<ProfilesWrapper>
										<Profiles>
											<ProfileHeading>
												Other Profiles
												<ManageOtherProfile>Manage</ManageOtherProfile>
											</ProfileHeading>
											<ProfileList>
												<ProfileName>
													<ProfileUserName>Engineering</ProfileUserName> George Kajaia123
												</ProfileName>
												<ProfileName>
													<ProfileUserName>Education</ProfileUserName> George Kajaia456
												</ProfileName>
												<ProfileName>
													<ProfileUserName>Ecommerce</ProfileUserName> George Kajaia897
												</ProfileName>
											</ProfileList>
										</Profiles>
									</ProfilesWrapper>
									<About>
										<AboutTitle>
											About <img src={editablenoborder} alt="" />
										</AboutTitle>
										<AboutDesc>
											I have 10 years of professional experience. I always work honestly and hard to please
											my clients. I always strict deadlines and do work with fast turnaround times. I am
											flexible with hourly rates.
										</AboutDesc>
										<More>more...</More>
										<EducationHistory>
											<EducationTitle>
												Education History <img src={editablenoborder} alt="" />
											</EducationTitle>
											<Education>
												<span>Degree for M.Tech</span>
												<span>Punjab Technical Institute</span>
												<span>Graducation year In 1989</span>
											</Education>
										</EducationHistory>
										<SkillsWrapper>
											<SkillsTitle>
												Skills <img src={editablenoborder} alt="" />
											</SkillsTitle>
											<Skills>Communication Skills, Management Skills</Skills>
										</SkillsWrapper>
									</About>
								</ProfilesAndAboutWrapper>
								<FeedbackWrapper>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment1}
											commentimagestyle={{
												marginLeft: "2rem",
											}}
											commenttitle="Thanks"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment}
											commenttitle="Reply"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
								</FeedbackWrapper>
							</MoreDetailWrapper>
						</ProfileSections>
					</ProfileWrapper>
				</Wrapper>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default EmployeePrivate;
