import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useLayout from "../../utils/hooks/useLayout";
import {
	MainDesc,
	MainNavBtn,
	MainSection2,
	MainSection2Info,
	MainSection2Title,
	MainSection3Left,
	MainSection3LeftImg,
	MainSection3Right,
	MainSection3Title,
	MainSectionInfo,
	MainSectionSection2Join,
	MainSectionSubWrapper3,
	MainSubWrapper,
	MainSubWrapperLeft,
	MainSubWrapperRight,
	MainSubWrapperRightImg,
	MainTitle,
	MainWrapperSection1,
	MainWrapperSection2,
	MainWrapperSection3,
	Section2Card,
	InnovativeSolutionsWrapper,
	InnovativeSolutions,
	InnovativeSolutionsInfo,
	InnovativeSolutionsTitle,
	InnovativeSolutionsDesc,
	MainWrapperSection4,
	MainSection4Title,
	MainSectionSubWrapper4,
	Section4CardWrapper,
	Sections4Card,
	Section4CardTitle,
	Section4CardDesc,
	Section4CardInfoWrapper,
	Section4CardIcon,
	MainWrapperSection5,
	MainSectionSubWrapper5,
	MainSection5Left,
	MainSection5LeftImg,
	MainSection5Right,
	MainSection5Title,
	MainSection5Desc,
} from "./index.styled";
import PreviewMonitor from "../../assets/images/landing/previewMonitor.png";
import PreviewMobile from "../../assets/images/landing/previewMobile.png";
import MobilePreviewMobile from "../../assets/images/landing/mobile/previewMobile.png";
import DigitalWorld from "../../assets/icons/landing/DigitalWorld";
import TraditionalMedia from "../../assets/icons/landing/TraditionalMedia";
import DiscoveringProducts from "../../assets/icons/landing/DiscoveringProducts";
import InnovatorsAndEntrepreneurs from "../../assets/icons/landing/InnovatorAndEntrepreneurs";
import OnlinePlatforms from "../../assets/icons/landing/OnlinePlatforms";
import OnlineAdSpending from "../../assets/icons/landing/OnlineAdSpending";
import OnlineConnections from "../../assets/icons/landing/OnlineConnections";
import OnlinePlatformsAnonymity from "../../assets/icons/landing/OnlinePlatformsAnonymoity";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DigitalEcosystem from "../../assets/icons/landing/products/DigitalEcosystem";
import Marketplace from "../../assets/icons/landing/products/Marketplace";
import SeamlessInterconnection from "../../assets/icons/landing/products/SeamlessInterconnection";
import SocialNetworking from "../../assets/icons/landing/products/SocialNetworking";
import DiverseInternetUsers from "../../assets/icons/landing/products/DiverseInternetUsers";
import MediaContent from "../../assets/icons/landing/products/MediaContent";
import EngagementFeatures from "../../assets/icons/landing/products/EngagementFeatures";
import ECommerce from "../../assets/icons/landing/products/ECommerce";
import UserExperience from "../../assets/icons/landing/products/UserExperience";
import BankingAndTransactions from "../../assets/icons/landing/products/BankingAndTransactions";
import Header from "../../components/Header";

const section2Cards = [
	{
		id: 1,
		icon: <DigitalWorld />,
		desc: "The digital world is flooded with irrelevant or negatively-toned information, manipulated by powerful entities like governments and corporations for their gain, often harming the majority's interests.",
	},
	{
		id: 2,
		icon: <TraditionalMedia />,
		desc: "Traditional media sources like TV and magazines depend heavily on news agencies, sometimes missing out on important information found on social networks, which lacks efficient dissemination.",
	},

	{
		id: 3,
		icon: <DiscoveringProducts />,
		desc: "Discovering products and services on today's websites often occurs accidentally, leading to a subpar user experience, as people find these more through social media than dedicated e-commerce sites.",
	},
	{
		id: 4,
		icon: <InnovatorsAndEntrepreneurs />,
		desc: "Innovators and entrepreneurs struggle to promote new ideas in the business world, risking obscurity without aggressive marketing and lacking a systematic approach for idea development.",
	},
	{
		id: 5,
		icon: <OnlinePlatforms />,
		desc: "Online platforms for dating people usually involve high costs with no success guarantee, offering communication without ensuring actual meetings, leading to user frustration.",
	},
	{
		id: 6,
		icon: <OnlineAdSpending />,
		desc: "Online advertising spending doesn't benefit viewers, creating a frustrating disconnect for audiences tired of constant ads.",
	},
	{
		id: 7,
		icon: <OnlineConnections />,
		desc: "Online connections and relationships often depend on superficial factors instead of considering financial means, impacting genuine engagement.",
	},
	{
		id: 8,
		icon: <OnlinePlatformsAnonymity />,
		desc: "Online platforms prioritize anonymity, but users may lack control over when and with whom their identity is revealed",
	},
];

const innovativeSolutions = [
	{
		id: 1,
		title: "User-Generated News:",
		dsec: "Users create news from their surroundings, featured on TV and in magazines.",
	},
	{
		id: 2,
		title: "Transparency & Accountability:",
		dsec: "Empower users to expose government, corporate activities, and more, ensuring the right people take notice.",
	},
	{
		id: 3,
		title: "Unified Marketplace:",
		dsec: "Combine buying and selling of services and products with social networks, all on a single platform.",
	},
	{
		id: 4,
		title: "Funding for All:",
		dsec: "Every idea finds funding, with support tailored to its unique needs and potential.",
	},
	{
		id: 5,
		title: "Efficient Dating:",
		dsec: "Our dating feature charges users only upon successful meetings.",
	},
	{
		id: 6,
		title: "Ad Revenue Sharing:",
		dsec: "Users earn a share of advertisement fees for viewing ads.",
	},
	{
		id: 7,
		title: "Modular Profiling:",
		dsec: "Separate profiles for distinct functions, ensuring tailored experiences for users.",
	},
];

const products = [
	{
		id: 1,
		icon: <DigitalEcosystem />,
		title: "Our Innovative Digital Ecosystem",
		desc: "Our platform seamlessly integrates e-commerce, social networking, journalism, government oversight, and secure transactions. It's a convergence of distinct modules, offering a tailored and personalized experience in each domain.",
	},
	{
		id: 2,
		icon: <Marketplace />,
		title: "Diverse Marketplaces for Dynamic Interactions",
		desc: "Our platform hosts a range of double-sided marketplaces, where buyers and sellers converge to forge meaningful deals. Spanning multiple sectors including Education, Agriculture, Transport, Creativity, IT, Healthcare, Hospitality, Environment, Entertainment, Professional Services, Used Products, Real Estate, Used Cars, and Investment.",
	},
	{
		id: 3,
		icon: <SeamlessInterconnection />,
		title: "Seamless Module Interconnection:",
		desc: "Our platform's distinctive feature is the interconnected module structure. Users can maintain separate usernames for different functions, enabling privacy and personalization like never before.",
	},
	{
		id: 4,
		icon: <SocialNetworking />,
		title: "Social Networking:",
		desc: "Modules for microblogging, short video sharing, dating, and social journalism to cater to different forms of digital interaction.",
	},
	{
		id: 5,
		icon: <DiverseInternetUsers />,
		title: "For Diverse Internet Users",
		desc: "Designed for social media enthusiasts, online shoppers, media consumers, TV aficionados, and journalism followers. Our platform brings multiple services under one accessible, user-friendly umbrella.",
	},
	{
		id: 6,
		icon: <MediaContent />,
		title: "Media Content:",
		desc: "TV and magazine modules offering news, entertainment, education, and lifestyle content.",
	},
	{
		id: 7,
		icon: <EngagementFeatures />,
		title: "Unique Features for Engagement:",
		desc: "Revenue sharing from ads, content creation from purchases, product-centric social media integration, independent module structure, civic engagement, and innovative dating through gamification.",
	},
	{
		id: 8,
		icon: <ECommerce />,
		title: "E-Commerce:",
		desc: "An extensive range of products, from real estate to automobiles, all within one easy-to-navigate platform.",
	},
	{
		id: 9,
		icon: <UserExperience />,
		title: "Revolutionizing User Experience:",
		desc: "Integrated services, seamless transactions, amplified voice in media, enhanced government accountability, financial rewards, and improved communication and community building.",
	},
	{
		id: 10,
		icon: <BankingAndTransactions />,
		title: "Banking and Transaction Services:",
		desc: "Secure and efficient financial tools, ensuring safe and convenient user transactions.",
	},
];

const Landing = () => {
	const navigate = useNavigate();
	const isMobile = useLayout();

	const handleSignUp = () => {
		navigate("/signup");
	};

	const tokenUrl = localStorage.getItem("token");
	useEffect(() => {
		if (tokenUrl) {
			navigate("/home");
		}
	}, [tokenUrl]);

	const location = useLocation();
	const searchParams = new URLSearchParams(location.search);
	const tokenFromUrl = searchParams.get("token");
	useEffect(() => {
		console.log("Token from URL:", tokenFromUrl);
		if (tokenFromUrl) {
			sessionStorage.setItem("token", tokenFromUrl);
			navigate("/home");
		}
	}, [tokenFromUrl]);
	return (
		<>
			<Header />
			<MainWrapperSection1>
				<MainSubWrapper>
					<MainSubWrapperLeft>
						<MainTitle>Step into the Future of Web 4.0</MainTitle>
						<MainDesc>Empower individuals worldwide to achieve their aspirations and life goals</MainDesc>
						{!isMobile && <MainNavBtn onClick={handleSignUp}>Signup Now</MainNavBtn>}
					</MainSubWrapperLeft>
					<MainSubWrapperRight>
						<MainSubWrapperRightImg src={PreviewMonitor} alt="preview-monitor" />
					</MainSubWrapperRight>
					<MainSectionInfo>
						Welcome to our MVP (Minimum Viable Product) website – please bear in mind that certain features may be
						limited or not fully functional at this stage.
					</MainSectionInfo>
				</MainSubWrapper>
			</MainWrapperSection1>
			<MainWrapperSection2>
				<MainSection2Title>The Challenges You Encounter</MainSection2Title>
				<MainSection2Info>Empowering You to Overcome Every Hurdle</MainSection2Info>
				<MainSection2>
					{section2Cards.map((card) => (
						<Section2Card key={card.id}>
							{card.icon}
							{card.desc}
						</Section2Card>
					))}
				</MainSection2>
				<MainSectionSection2Join>
					<span>Join Now for Limitless Opportunities</span>
					<MainNavBtn onClick={handleSignUp}>Signup Now</MainNavBtn>
				</MainSectionSection2Join>
			</MainWrapperSection2>
			<MainWrapperSection3>
				<MainSectionSubWrapper3>
					{isMobile && <MainSection3Title>Our Innovative Solutions</MainSection3Title>}
					<MainSection3Left>
						<MainSection3LeftImg src={isMobile ? MobilePreviewMobile : PreviewMobile} alt="preview-mobile" />
					</MainSection3Left>
					<MainSection3Right>
						{!isMobile && <MainSection3Title>Our Innovative Solutions</MainSection3Title>}
						<InnovativeSolutionsWrapper>
							{innovativeSolutions?.map((solution) => (
								<InnovativeSolutions key={solution.id}>
									<CheckCircleIcon />
									<InnovativeSolutionsInfo>
										<InnovativeSolutionsTitle>{solution.title}</InnovativeSolutionsTitle>
										<InnovativeSolutionsDesc>{solution.dsec}</InnovativeSolutionsDesc>
									</InnovativeSolutionsInfo>
								</InnovativeSolutions>
							))}
						</InnovativeSolutionsWrapper>
					</MainSection3Right>
				</MainSectionSubWrapper3>
				<MainSectionSection2Join>
					<span>Join Now for Limitless Opportunities</span>
					<MainNavBtn onClick={handleSignUp}>Signup Now</MainNavBtn>
				</MainSectionSection2Join>
			</MainWrapperSection3>
			<MainWrapperSection4>
				<MainSectionSubWrapper4>
					<MainSection4Title>Discover Our Product</MainSection4Title>
					<Section4CardWrapper>
						{products?.map((product) => (
							<Sections4Card key={product.id}>
								<Section4CardIcon>{product.icon}</Section4CardIcon>
								<Section4CardInfoWrapper>
									<Section4CardTitle>{product.title}</Section4CardTitle>
									<Section4CardDesc>{product.desc}</Section4CardDesc>
								</Section4CardInfoWrapper>
							</Sections4Card>
						))}
					</Section4CardWrapper>
				</MainSectionSubWrapper4>
			</MainWrapperSection4>
			<MainWrapperSection5>
				<MainSectionSubWrapper5>
					<MainSection5Left>
						<MainSection5LeftImg
							src="https://buzzs3-bucket.s3.eu-north-1.amazonaws.com/assets/Buzzgalactic+explainer+video.mp4"
							alt="video-preview"
							controls
							autoPlay
							muted
							loop
						/>
						{isMobile && (
							<MainSectionSection2Join
								style={{
									justifyContent: "initial",
									width: "85%",
								}}
							>
								<span>Join Now for Limitless Opportunities</span>
								<MainNavBtn onClick={handleSignUp}>Signup Now</MainNavBtn>
							</MainSectionSection2Join>
						)}
					</MainSection5Left>

					<MainSection5Right>
						<MainSection5Title>Discover the Power of Buzzgalactic</MainSection5Title>
						<MainSection5Desc>
							In this video, we'll take you on a journey through our platform's incredible features and capabilities
						</MainSection5Desc>
						{!isMobile && (
							<MainSectionSection2Join
								style={{
									justifyContent: "initial",
									width: "fit-content",
								}}
							>
								<span>Join Now for Limitless Opportunities</span>
								<MainNavBtn onClick={handleSignUp}>Signup Now</MainNavBtn>
							</MainSectionSection2Join>
						)}
					</MainSection5Right>
				</MainSectionSubWrapper5>
			</MainWrapperSection5>
		</>
	);
};
export default Landing;
