import styled from "@emotion/styled";
import Background from "../../../assets/images/singnup/background.svg";

const Wrapper = styled.div`
	max-width: 800px;
	grid-column: 2 / span 8;
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	background: #fff;
	box-shadow: 0 4px 11px 0 rgba(0, 0, 0, 0.25);
	height: max-content;

	@media screen and (max-width: 768px) {
		max-width: unset;
		width: 100%;
		margin: 12px;
		display: flex;
		flex-direction: column;
	}
`;

const LeftWrapper = styled.div`
	grid-column: span 3;
	background-image: url(${Background});
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;

	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: space-between;

	padding: 24px;

	& > img {
		width: auto;
		height: auto;
		max-width: 100%;
	}

	@media screen and (max-width: 768px) {
		padding: 16px;
		min-height: 60dvh;

		& > img {
			padding-block: 24px;
		}
	}
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 14px;

	@media screen and (max-width: 768px) {
		row-gap: 6px;
	}
`;

const InfoTitle = styled.h1`
	color: #000;
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 128%; /* 46.08px */
`;

const Desc = styled.p`
	color: rgba(0, 0, 0, 0.7);
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 130%; /* 26px */
`;

const Form = styled.form`
	grid-column: span 7;
	padding: 18px 24px 10px;

	@media screen and (max-width: 768px) {
		padding: 14px 18px 14px;
	}
`;

const FormTitle = styled.h1`
	color: #000;
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
	line-height: 157%; /* 37.68px */

	margin-block-end: 14px;
`;

const TermsAndConditions = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media screen and (max-width: 768px) {
		flex-direction: column;
		row-gap: 12px;
		margin-block-end: 18px;
	}
`;

const Divider = styled.div`
	width: -webkit-fill-available;
	position: relative;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 1;
	&::before {
		content: "";
		position: absolute;
		width: 100%;
		height: 1px;
		top: 50%;
		width: 100%;
		background-color: #dedede;
	}
`;

const DividerText = styled.span`
	color: #000;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 157%; /* 28.26px */

	background-color: #fff;

	padding-inline: 32px;

	position: relative;
	z-index: 2;
`;

const GoogleLogin = styled.div`
	width: -webkit-fill-available;
	margin-inline: 0;
	display: flex;
	justify-content: center;
`;

const Login = styled.div`
	margin-block-start: 12px;
	text-align: center;
	&,
	& > span {
		color: #696363;
		font-family: Inter;
		font-size: 12px;
		font-style: normal;
		font-weight: 500;
		line-height: 137%; /* 24.66px */
	}

	& > span {
		cursor: pointer;
		color: #61bcac;
		text-decoration-line: underline;
	}
`;

export {
	Wrapper,
	LeftWrapper,
	InfoWrapper,
	InfoTitle,
	Desc,
	Form,
	FormTitle,
	TermsAndConditions,
	Divider,
	DividerText,
	GoogleLogin,
	Login,
};
