const Google = ({ size = 18 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 17 17" fill="none">
			<g clipPath="url(#clip0_5110_175)">
				<path
					d="M5.92232 0.559161C4.22376 1.14841 2.75892 2.26682 1.74297 3.75012C0.727015 5.23341 0.213499 7.00341 0.277848 8.80013C0.342197 10.5968 0.981019 12.3256 2.10048 13.7324C3.21994 15.1392 4.76104 16.15 6.4974 16.6162C7.90511 16.9794 9.37998 16.9954 10.7952 16.6627C12.0773 16.3747 13.2626 15.7587 14.2351 14.875C15.2472 13.9272 15.9819 12.7214 16.3601 11.3874C16.771 9.93659 16.8441 8.41096 16.5739 6.92752H8.66889V10.2067H13.2469C13.1554 10.7297 12.9594 11.2288 12.6705 11.6743C12.3816 12.1197 12.0057 12.5023 11.5655 12.7992C11.0066 13.1691 10.3764 13.4179 9.71545 13.5296C9.05264 13.6529 8.37279 13.6529 7.70998 13.5296C7.03815 13.3909 6.40263 13.1136 5.84396 12.7155C4.94635 12.0801 4.27236 11.1774 3.91818 10.1363C3.55811 9.07561 3.55811 7.92576 3.91818 6.8651C4.1703 6.12164 4.58707 5.44472 5.1374 4.88486C5.76719 4.23242 6.56452 3.76605 7.44191 3.53692C8.3193 3.30779 9.24285 3.32476 10.1112 3.58596C10.7896 3.79411 11.41 4.15795 11.9228 4.64846C12.439 4.13492 12.9543 3.62005 13.4687 3.10385C13.7344 2.82627 14.0239 2.56197 14.2855 2.27775C13.5026 1.5493 12.5837 0.98244 11.5815 0.60963C9.75623 -0.0531181 7.75909 -0.0709288 5.92232 0.559161Z"
					fill="white"
				/>
				<path
					d="M5.92225 0.559196C7.75886 -0.071322 9.756 -0.0539801 11.5814 0.608337C12.5838 0.983679 13.5023 1.55327 14.2841 2.28443C14.0185 2.56865 13.7383 2.83427 13.4673 3.11052C12.952 3.62495 12.4371 4.13761 11.9227 4.64849C11.4099 4.15799 10.7896 3.79414 10.1112 3.58599C9.24306 3.32388 8.31954 3.30593 7.44191 3.53412C6.56429 3.76231 5.76647 4.22782 5.136 4.87959C4.58567 5.43944 4.16889 6.11636 3.91678 6.85982L1.16357 4.72818C2.14905 2.77393 3.85535 1.27907 5.92225 0.559196Z"
					fill="#E33629"
				/>
				<path
					d="M0.43275 6.83987C0.580623 6.10644 0.826308 5.39619 1.16322 4.72815L3.91642 6.8651C3.55635 7.92577 3.55635 9.07561 3.91642 10.1363C2.99913 10.8446 2.0814 11.5565 1.16322 12.2719C0.32006 10.5936 0.0629107 8.68132 0.43275 6.83987Z"
					fill="#F8BD00"
				/>
				<path
					d="M8.66856 6.92615H16.5736C16.8438 8.40958 16.7707 9.93522 16.3597 11.386C15.9815 12.7201 15.2469 13.9258 14.2347 14.8736C13.3462 14.1804 12.4537 13.4924 11.5652 12.7991C12.0057 12.502 12.3817 12.119 12.6706 11.673C12.9595 11.2271 13.1554 10.7274 13.2466 10.204H8.66856C8.66723 9.11224 8.66856 8.01919 8.66856 6.92615Z"
					fill="#587DBD"
				/>
				<path
					d="M1.16211 12.2719C2.08029 11.5635 2.99802 10.8516 3.91531 10.1362C4.2702 11.1777 4.94515 12.0805 5.84375 12.7154C6.40415 13.1117 7.04105 13.3867 7.71375 13.5229C8.37656 13.6462 9.05641 13.6462 9.71922 13.5229C10.3801 13.4112 11.0103 13.1624 11.5693 12.7925C12.4578 13.4858 13.3503 14.1737 14.2388 14.867C13.2665 15.7512 12.0812 16.3676 10.799 16.656C9.38375 16.9887 7.90888 16.9727 6.50117 16.6095C5.38781 16.3122 4.34785 15.7882 3.44648 15.0702C2.49253 14.3127 1.71333 13.3582 1.16211 12.2719Z"
					fill="#319F43"
				/>
			</g>
			<defs>
				<clipPath id="clip0_5110_175">
					<rect width={size} height={size} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Google;
