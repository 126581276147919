import LeftSubNav from "../../../components/LeftSubNav";
import SearchResults from "../../../components/PublicControl/Search";
import Page from "../../../layouts/Page";
import useLayout from "../../../utils/hooks/useLayout";
import { LeftNav, SearchSection } from "./index.styled";

const Search = () => {
	const isMobile = useLayout();
	return (
		<Page
			overrides={
				isMobile && {
					"@media screen and (max-width: 768px)": {
						paddingBlockEnd: "72px",
						flexDirection: "column",
						paddingInline: "12px",
					},
				}
			}
		>
			<LeftNav>
				<LeftSubNav support={false} videos={true} />
			</LeftNav>
			<SearchSection>
				<SearchResults />
			</SearchSection>
		</Page>
	);
};

export default Search;
