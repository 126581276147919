import styled from "@emotion/styled";
import { color, spacing } from "../../../../styles/variables";
import Home from "../../../../assets/icons/shorts/mobile/Home";
import IT from "../../../../assets/icons/shorts/mobile/IT";
import Messages from "../../../../assets/icons/shorts/mobile/Messages";
import Profile from "../../../../assets/icons/shorts/mobile/Profile";
import Upload from "../../../../assets/icons/shorts/mobile/Upload";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { GetShortVideoUserDetails } from "../../../../utils/apiCalls";

const mobileNavs = [
	{
		id: 1,
		label: "Home",
		link: "/shorts/feed",
	},
	{
		id: 2,
		label: "Information Tech",
		// link: -1,
	},
	{
		id: 3,
		label: "Upload",
		link: "/shorts/upload",
	},
	{
		id: 4,
		label: "Messages",
		link: "/shorts/feed",
	},
	{
		id: 5,
		label: "Profile",
		link: "/shorts/profile/private",
	},
];

const MobileNav = ({ setNavHeight, shortVideosProfile}) => {
	const navigate = useNavigate();
	const navBarRef = useRef();
	const { pathname } = useLocation();

	const [activeTab, setActiveTab] = useState(1);
	const [showNav, setShowNav] = useState(false);

	useEffect(() => {
		setNavHeight(navBarRef?.current?.clientHeight);
	}, [setNavHeight]);

	const checkPath = () => {
		mobileNavs?.some((nav) => {
			if (nav?.link === pathname) {
				setActiveTab(nav?.id);
				return true;
			}
		});

		const path = pathname?.split("/");
		path.shift();

		if (
			path?.includes("shorts") &&
			(path?.includes("feed") || (path?.includes("profile") && path?.includes("private")) || path?.includes("public"))
		) {
			setShowNav(true);
		} else setShowNav(false);
	};

	useEffect(() => {
		checkPath();
	}, [pathname]);

	useEffect(() => {
		checkPath();
	}, []);

	const getIcon = ({ id, label }) => {
		switch (label) {
			case "Information Tech":
				return <IT color={activeTab === id ? color("pWhite") : color("pGreen")} size={24} />;
			case "Upload":
				return <Upload color={activeTab === id ? color("pWhite") : color("pGreen")} size={24} />;
			case "Messages":
				return <Messages color={activeTab === id ? color("pWhite") : color("pGreen")} size={24} />;
			case "Profile":
				return <Profile color={activeTab === id ? color("pWhite") : color("pGreen")} size={24} />;
			default:
				return <Home color={activeTab === id ? color("pWhite") : color("pGreen")} size={24} />;
		}
	};

	const handleClick = ({ id, link }) => {
		if (id !== 3) {
			setActiveTab(id);
		}
		if (id === 2) {
			if (shortVideosProfile) {
				const payload = {
					shortVideoId: shortVideosProfile?.shortVideoId,
				};
				GetShortVideoUserDetails((res) => {
					if (res.data?.module === "Information Technology") {
						navigate(`it/${res.data?.accountType.toLowerCase()}/profile/private`, { state: { userData: res.data } });
					} else {
						navigate(`${res.data?.module}/${res.data?.accountType.toLowerCase()}/profile/private`, {
							state: { user: res.data },
						});
					}
				}, payload);
			}
		}
		navigate(link);
	};

	if (!showNav) return <></>;

	return (
		<Wrapper ref={navBarRef}>
			{mobileNavs?.map((tab) => (
				<NavWrapper key={tab?.id} active={activeTab === tab?.id} onClick={() => handleClick(tab)}>
					<NavIcon>{getIcon(tab)}</NavIcon>
					<NavLabel active={activeTab === tab?.id}>{tab?.label}</NavLabel>
				</NavWrapper>
			))}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;

	display: grid;
	grid-template-columns: repeat(5, 1fr);
	justify-content: space-between;
	background-color: ${color("pWhite")};
`;

const NavWrapper = styled.div`
	cursor: pointer;
	grid-column: span 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	row-gap: ${spacing("xs")};

	padding-block: ${spacing("sm")};
	padding-inline: ${spacing("sm1")};

	${(props) =>
		props.active && {
			backgroundColor: color("pGreen"),
		}}
`;

const NavIcon = styled.div`
	svg {
		height: 22px;
		aspect-ratio: 1;
	}
`;

const NavLabel = styled.span`
	color: ${color("pGreen")};
	font-family: Inter;
	font-size: 11px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	text-align: center;

	${(props) =>
		props.active && {
			color: color("pWhite"),
		}}
`;

export default MobileNav;
