import styled from "@emotion/styled";

const LeftNav = styled.div`
	grid-column: span 2;
`;

const FeedWrapper = styled.div`
	grid-column: span 10;
	display: grid;
	grid-template-columns: repeat(10, 1fr);
	row-gap: 24px;

	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
	}
`;

const Content = styled.div`
	grid-row: 1;
	grid-column: span 6;

	@media (max-width: 768px) {
		display: flex;
	}
`;

const Trending = styled.div`
	grid-row: 1;
	grid-column: 8 / span 3;

	@media (max-width: 768px) {
		grid-row: initial;
	}
`;

export { LeftNav, FeedWrapper, Content, Trending };
