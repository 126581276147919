import { ChevronLeft } from "@mui/icons-material";
import {
	Container,
	Wrapper,
	Back,
	TitleWrapper,
	ProfilesWrapper,
	Section,
	Details,
	ProfileModuleTitleWrapper,
	ModuleTitle,
	ModuleSubGroup,
	SubProfilesWrapper,
	ProfileDetails,
	ProfileRadio,
	ProfileName,
	ModuleSubGroupTitle,
} from "./index.styled";
import { color } from "../../../styles/variables";
import { AccordionSummary } from "@mui/joy";
import { ExpandMore as ExpandMoreIcon } from "@mui/icons-material";
import { useMutation, useQuery } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import { useEffect, useState } from "react";
import InformationTechnology from "../../../assets/icons/it/it";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader";
import useLayout from "../../../utils/hooks/useLayout";
import UserIcon from "../../../assets/icons/UserWhite.svg";
import { connect } from "react-redux";
import Accordion from "@mui/material/Accordion";
import { AccordionSummary as Summary } from "@mui/material";
import AccordionDetails from "@mui/material/AccordionDetails";
import MBMobileBottom from "../../../components/MB/MBMobileBottom/MBMobileBottom";
import Agriculture from "../../../assets/icons/agriculture/agriculture";

const iconMapping = {
	ITProfileType: InformationTechnology,
	Agriculture: Agriculture,
};

const moduleMapping = {
	ITProfileType: "Information Technology",
	Agriculture: "Agriculture",
};

const Profiles = ({ activeProfileId, setActiveMBProfile, setActiveMBModuleProfile }) => {
	const navigate = useNavigate();
	const isMobile = useLayout();
	const [allProfiles, setAllProfiles] = useState(null);

	const { data, refetch, isLoading } = useQuery({
		queryKey: ["MBProfiles"],
		queryFn: async () => {
			const response = await query("GET", "MB", "getAllMicroBloggingAccounts");
			return response.data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
		// enabled: false,
	});

	useEffect(() => {
		// if (!allProfiles) {
		refetch();
		// }
	}, []);

	useEffect(() => {
		if (data) {
			let allKeys = Object.keys(data);
			let formattedData = [];
			allKeys.forEach((objKey) =>
				formattedData.push({
					module: moduleMapping[objKey],
					data: data[objKey],
					icon: iconMapping[objKey],
				}),
			);
			setAllProfiles(formattedData);
		}
	}, [data]);

	const updateLatestMBAcoount = useMutation({
		mutationFn: async (id) => {
			try {
				const response = await query("PUT", "MB", "updateLastMicrobloggingAccount", id);
				return response.data;
			} catch (error) {
				console.error("Error:", error.message);
				throw error;
			} finally {
				// Code that runs regardless of success or failure
			}
		},
	});

	const handleActiveProfile = (data, id) => {
		setActiveMBModuleProfile(data);
		setActiveMBProfile(id);
		updateLatestMBAcoount.mutateAsync({ microbloggingId: id });
		navigate("/microblogging/profile/private");
	};

	return (
		<Container>
			{isMobile ? (
				<TitleWrapper>
					<Back onClick={() => navigate(-1)}>
						<ChevronLeft
							style={{
								color: "#fff",
							}}
						/>
					</Back>
					Profiles
					<span onClick={() => navigate("/microblogging/profile/private")}>
						<img src={UserIcon} alt="profiles" />
					</span>
				</TitleWrapper>
			) : (
				<Back onClick={() => navigate(-1)}>
					<ChevronLeft
						style={{
							color: color("pGreen"),
						}}
					/>
					Back
				</Back>
			)}
			<Wrapper>
				{!isMobile && <TitleWrapper>Profiles</TitleWrapper>}

				<Section defaultExpanded IconButtonProps={{ edge: "start" }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="services" id="services">
						Services
					</AccordionSummary>
					<Details>
						{allProfiles?.map((profile) => (
							<ProfilesWrapper>
								{isMobile ? (
									<Accordion defaultExpanded elevation={0}>
										<Summary
											sx={{
												minHeight: "0 !important",
												".MuiAccordionSummary-content": {
													margin: "0 !important",
													columnGap: "12px",
												},
											}}
											expandIcon={<ExpandMoreIcon />}
											aria-controls="mobile"
											id="mobile"
										>
											{profile?.icon && <profile.icon />} <ModuleTitle>{profile.module}</ModuleTitle>
										</Summary>
										<AccordionDetails>
											{profile?.data?.map((subGroup) => (
												<ModuleSubGroup>
													<ModuleSubGroupTitle>{subGroup?.accountType}</ModuleSubGroupTitle>
													<SubProfilesWrapper>
														<ProfileDetails>
															<ProfileRadio
																name="active-profile"
																onChange={() => {
																	handleActiveProfile(subGroup, subGroup?.microbloggingId);
																}}
																checked={activeProfileId === subGroup?.microbloggingId}
																type="radio"
															/>
															<ProfileName>{subGroup?.microbloggingUsername}</ProfileName>
														</ProfileDetails>
													</SubProfilesWrapper>
												</ModuleSubGroup>
											))}
										</AccordionDetails>
									</Accordion>
								) : (
									<>
										<ProfileModuleTitleWrapper>
											{profile?.icon && <profile.icon />} <ModuleTitle>{profile.module}</ModuleTitle>
										</ProfileModuleTitleWrapper>
										{profile?.data?.map((subGroup) => (
											<ModuleSubGroup>
												<ModuleSubGroupTitle>{subGroup?.accountType}</ModuleSubGroupTitle>
												<SubProfilesWrapper>
													<ProfileDetails>
														<ProfileRadio
															name="active-profile"
															onChange={() => {
																handleActiveProfile(subGroup, subGroup?.microbloggingId);
															}}
															checked={activeProfileId === subGroup?.microbloggingId}
															type="radio"
														/>
														<ProfileName>{subGroup?.microbloggingUsername}</ProfileName>
													</ProfileDetails>
												</SubProfilesWrapper>
											</ModuleSubGroup>
										))}
									</>
								)}
							</ProfilesWrapper>
						))}
						{isLoading && <Loader />}
					</Details>
				</Section>
				{/* <Section defaultExpanded IconButtonProps={{ edge: "start" }}>
					<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="services" id="services">
						Products
					</AccordionSummary>
					<ProfilesWrapper>
						<Details>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet
							blandit leo lobortis eget.
						</Details>
					</ProfilesWrapper>
				</Section> */}
			</Wrapper>
			<MBMobileBottom />
		</Container>
	);
};

const mapStateToProps = (state) => ({
	activeProfileId: state.mb.activeProfileId || undefined,
});

const mapPropsToState = (dispatch) => ({
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
	setActiveMBModuleProfile: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE_PROFILE",
			payload: data,
		}),
});

export default connect(mapStateToProps, mapPropsToState)(Profiles);
