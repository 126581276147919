import { Wrapper, FormWrapper } from "./index.styled";
import Header from "../../components/Header";
import ResetPasswordForm from "../../components/ResetPassword/Form";

const ResetPassword = () => {
	return (
		<>
			<Wrapper>
				<Header hideButton />
				<FormWrapper>
					<ResetPasswordForm />
				</FormWrapper>
			</Wrapper>
		</>
	);
};

export default ResetPassword;
