const Index = ({ size = 24, color = "#fff" }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M1.98633 20.75C1.98633 19.2913 2.56579 17.8924 3.59724 16.8609C4.62869 15.8295 6.02764 15.25 7.48633 15.25H18.4863C19.945 15.25 21.344 15.8295 22.3754 16.8609C23.4069 17.8924 23.9863 19.2913 23.9863 20.75C23.9863 21.4793 23.6966 22.1788 23.1809 22.6945C22.6651 23.2103 21.9657 23.5 21.2363 23.5H4.73633C4.00698 23.5 3.30751 23.2103 2.79178 22.6945C2.27606 22.1788 1.98633 21.4793 1.98633 20.75Z"
				stroke={color}
				strokeWidth="1.5"
				strokeLinejoin="round"
			/>
			<path
				d="M12.9863 9.75C15.2645 9.75 17.1113 7.90317 17.1113 5.625C17.1113 3.34683 15.2645 1.5 12.9863 1.5C10.7082 1.5 8.86133 3.34683 8.86133 5.625C8.86133 7.90317 10.7082 9.75 12.9863 9.75Z"
				stroke={color}
				strokeWidth="1.5"
			/>
		</svg>
	);
};

export default Index;
