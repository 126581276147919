import React, { useContext, useEffect, useRef, useState } from "react";
import "./Home.scss";
import whitemessage from "../../assets/icons/white-message.png";
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import PostComponent from "../../components/PostComponent/PostComponent";
import { GetAllPost, GetUserDetails, UserChatGpt } from "../../utils/apiCalls";
import { Navigate } from "react-router-dom";
import { UserContext } from "../../store/UserDetailsStore";
import { MenuContext } from "../../store/MenuContext";

import Page from "../../layouts/Page";
import { connect } from "react-redux";
import Contacts from "../../components/Main/Contacts";
import useLayout from "../../utils/hooks/useLayout";
import LeftNav from "../../components/Main/LeftNav";
import Feed from "../../components/Main/Feed";

const Home = ({ headerHeight }) => {
	const { menuopen } = useContext(MenuContext);
	const [isMessage, setIsMessage] = useState("");
	const [isResponse, setIsResponse] = useState("");
	const { isLoginUserDetails, updateLoginUserDetails } = useContext(UserContext);
	const [isShowLoader, setIsShowLoader] = useState(false);
	const [activePage, setActivePage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const [postList, setPostList] = useState([]);
	const [postListAll, setPostListAll] = useState([]);
	const [totalRecordsCount, setTotalRecordsCount] = useState("");
	const [totalPagesCount, setTotalPagesCount] = useState("");
	const [loading, setLoading] = useState(false);
	const bottomOfPageRef = useRef(null);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const isMobile = useLayout();

	useEffect(() => {
		const token = localStorage.getItem("token") || sessionStorage.getItem("token");
		const payload = {
			token: token,
		};
		if (token) {
			GetUserDetails((res) => {
				if (res?.status === 200) {
					updateLoginUserDetails(res?.data);
				}
			}, payload);
		}
	}, []);

	const handleChatGpt = () => {
		const payload = {
			message: isMessage,
		};
		if (isMessage.length !== 0) {
			setIsShowLoader(true);
			if (payload) {
				if (payload.message !== "") {
					UserChatGpt((res) => {
						if (res.status === 200) {
							setIsMessage("");
							const formattedMessage = res?.data?.result ? res?.data?.result?.trim()?.replace(/\n/g, "<br>") : "";
							setIsResponse(formattedMessage);
						} else {
							setIsMessage("");
						}
						setIsShowLoader(false);
					}, payload);
				}
			}
		}
	};

	useEffect(() => {
		if (postList?.length !== 0) {
			pushArrayList(postList);
		}
	}, [postList]);

	const pushArrayList = (postList) => {
		for (let i = 0; i < postList.length; i++) {
			const newGeneration = postList[i];
			const isPostId = newGeneration.postId;
			const isGenerationAlreadyPresent = postListAll.some((existingPost) => {
				return existingPost.postId === isPostId;
			});

			if (!isGenerationAlreadyPresent) {
				postListAll.push(newGeneration);
			}
		}
		setPostListAll([...postListAll]);
	};

	const getAllPostData = (pageIndex) => {
		const payload = {
			pageSize,
			pageIndex,
		};
		GetAllPost((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setPostList(data.post);
				setTotalRecordsCount(data?.totalRecords);
				setTotalPagesCount(Math.ceil(data?.totalRecords / pageSize));
			} else {
				setTotalRecordsCount(0);
				setTotalPagesCount(0);
			}
			setLoading(false);
		}, payload);
	};

	// const handleScroll = () => {
	// 	if (bottomOfPageRef.current && bottomOfPageRef.current.getBoundingClientRect().bottom <= window.innerHeight + 1) {
	// 		if (activePage < totalPagesCount && !loading) {
	// 			setLoading(true);
	// 			getAllPostData(activePage);
	// 			setActivePage((prevPage) => prevPage + 1);
	// 		}
	// 	}
	// };

	useEffect(() => {
		setActivePage(1);
	}, [pageSize]);

	useEffect(() => {
		if (activePage >= 1) {
			getAllPostData(activePage - 1);
		}
	}, [pageSize, activePage]);

	const sidebarRef = useRef(null);

	// useEffect(() => {
	// 	const sidebar = sidebarRef.current;

	// 	const onScroll = () => {
	// 		const sidebarTop = sidebar.getBoundingClientRect().top;
	// 		if (sidebarTop <= 0) {
	// 			sidebar.classList.add("sticky");
	// 		} else {
	// 			sidebar.classList.remove("sticky");
	// 		}
	// 	};

	// 	window.addEventListener("scroll", onScroll);

	// 	return () => {
	// 		window.removeEventListener("scroll", onScroll);
	// 	};
	// }, []);

	// useEffect(() => {
	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll);
	// 	};
	// }, [handleScroll]);

	return (
		<>
			{token ? (
				<Page
					ref={bottomOfPageRef}
					overrides={{
						maxHeight: `calc(100dvh - ${headerHeight}px)`,
						overflowY: "scroll",
						paddingBlock: 0,
					}}
				>
					{!isMobile && <LeftNav />}
					{/* <div className="home_center">
						<div className="home_center_chat">
							<div className="home_center_chat_container">
								<div className="home_center_chat_content">
									{isShowLoader === false ? (
										<>
											{isResponse === "" ? (
												<p>
													Hi {isLoginUserDetails?.name}, I'm your chatbot. Let's explore powerful
													features and uncover unique financial opportunities together!
												</p>
											) : (
												<p
													dangerouslySetInnerHTML={{
														__html: isResponse,
													}}
												></p>
											)}
										</>
									) : (
										<div className="img">
											<img className="loader" src={loader} alt="Loading" />
										</div>
									)}
								</div>
								<div className="home_center_chat_message">
									<div className="home_center_chat_message_input">
										<input
											placeholder="Type your message"
											value={isMessage}
											disabled={isShowLoader}
											onChange={(e) => setIsMessage(e.target.value)}
											onKeyDown={(e) => {
												if (e.key === "Enter") {
													handleChatGpt();
												}
											}}
										/>
									</div>
									<div
										className={`pencil ${isShowLoader ? "disabled" : ""}`}
										onClick={!isShowLoader ? handleChatGpt : undefined}
									>
										<img src={whitemessage} className="pencil_img" alt="icon" />
									</div>
								</div>
							</div>
						</div>
						<SearchComponent
							style={{ padding: "1.2vw", margin: "2vw 0" }}
							setPostListAll={setPostListAll}
							getAllPostData={getAllPostData}
						/>
						{postListAll?.map((data, index) => {
							return (
								<PostComponent
									key={index}
									userId={data?.userId}
									postid={data?.postId}
									image={data?.postPicture}
									heading={"Main"}
									name={data?.fullName}
									profile={data?.profilePicture}
									content={data?.content}
									likes={data?.likes}
									Comments={data?.comments}
									currentUserLikeStatus={data?.currentUserLikeStatus}
								/>
							);
						})}
					</div> */}
					<Feed />
					{!isMobile && <Contacts />}
				</Page>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapsStateToProps = (state) => {
	return {
		headerHeight: state.common?.homeHeaderHeight || 0,
	};
};

export default connect(mapsStateToProps)(Home);
