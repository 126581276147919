import { Fragment, useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";
import styled from "@emotion/styled";
import { borderRadius, color, spacing } from "../../../styles/variables";
import { GetCountryNamesList } from "../../../utils/apiCalls";

const AutoComplete = ({
	name,
	label,
	required = false,
	errorMsg = undefined,
	gap = spacing("sm"),
	placeholder = undefined,
	error = undefined,
	helperText = undefined,
	noMargin = false,
	onChange,
}) => {
	const [open, setOpen] = useState(false);
	const [options, setOptions] = useState([]);
	const [countryData, setCountryData] = useState([]);
	const loading = open && options.length === 0;

	const handleChange = (event, value) => {
		onChange(name, value?.title || "");
	};

	useEffect(() => {
		let active = true;

		if (!loading) {
			return undefined;
		}
		if (active) {
			setOptions([...topFilms]);
		}

		return () => {
			active = false;
		};
	}, [loading]);

	useEffect(() => {
		if (!open) {
			setOptions([]);
		}
	}, [open]);

	const getCountryList = () => {
		GetCountryNamesList((res) => {
			const { status, data } = res;
			if (status === 200) {
				const formattedData = data.map((countryName) => ({
					title: countryName
				}));
				setCountryData(formattedData);
			} else {
				setCountryData([]);
			}
		});
	};

	useEffect(() => {
		if (open) {
		  getCountryList(); 
		}			
	  }, [open]);

	return (
		<Wrapper gap={gap} noMargin={noMargin}>
			{label && (
				<CustomLabel>
					{label}
					{required && "*"}
				</CustomLabel>
			)}
			<CustomAutocomplete
				id="custom-autocomplete"
				open={open}
				onOpen={() => {
					setOpen(true);
				}}
				onClose={() => {
					setOpen(false);
				}}
				required={true}
				name="country"
				isOptionEqualToValue={(option, value) => option.title === value.title}
				onChange={handleChange}
				getOptionLabel={(option) => option.title}
				options={countryData}
				loading={loading}
				renderInput={(params) => (
					<TextField
						{...params}
						error={error}
						helperText={helperText}
						InputProps={{
							...params.InputProps,
							endAdornment: (
								<Fragment>
									{loading ? <CircularProgress color="inherit" size={20} /> : null}
									{params.InputProps.endAdornment}
								</Fragment>
							),
						}}
					/>
				)}
			/>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	margin-block-end: ${spacing("md")};
	display: flex;
	flex-direction: column;
	row-gap: ${(props) => props.gap};
	@media screen and (max-width: 768px) {
		margin-block-end: ${spacing("md")};
	}
	${(props) =>
		props.noMargin && {
			margin: 0 + "!important",
		}}
`;

const CustomLabel = styled.span`
	display: inline-block;

	color: ${color("pLabel")};
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const CustomAutocomplete = styled(Autocomplete)`
	.MuiAutocomplete-inputRoot {
		padding-block: ${spacing("sm")};
		border-radius: ${borderRadius(8)};
		border-color: ${color("borderDimGrayLight")};
	}
	.MuiOutlinedInput-input {
		&.MuiAutocomplete-input {
			padding: 0;
			&.MuiAutocomplete-inputFocused {
				border-color: ${color("borderDimGrayLight")};
			}
		}
	}
	.MuiOutlinedInput-root.Mui-focused {
		.MuiOutlinedInput-notchedOutline {
			border-color: ${color("borderDimGrayLight")};
		}
	}
	.MuiFormHelperText-root {
		margin-inline: 0;
	}
`;

// Top films as rated by IMDb users. http://www.imdb.com/chart/top
const topFilms = [
	{ title: "The Shawshank Redemption", year: 1994 },
	{ title: "The Godfather", year: 1972 },
	{ title: "The Godfather: Part II", year: 1974 },
	{ title: "The Dark Knight", year: 2008 },
	{ title: "12 Angry Men", year: 1957 },
	{ title: "Schindler's List", year: 1993 },
	{ title: "Pulp Fiction", year: 1994 },
	{
		title: "The Lord of the Rings: The Return of the King",
		year: 2003,
	},
	{ title: "The Good, the Bad and the Ugly", year: 1966 },
	{ title: "Fight Club", year: 1999 },
	{
		title: "The Lord of the Rings: The Fellowship of the Ring",
		year: 2001,
	},
	{
		title: "Star Wars: Episode V - The Empire Strikes Back",
		year: 1980,
	},
	{ title: "Forrest Gump", year: 1994 },
	{ title: "Inception", year: 2010 },
	{
		title: "The Lord of the Rings: The Two Towers",
		year: 2002,
	},
	{ title: "One Flew Over the Cuckoo's Nest", year: 1975 },
	{ title: "Goodfellas", year: 1990 },
	{ title: "The Matrix", year: 1999 },
	{ title: "Seven Samurai", year: 1954 },
	{
		title: "Star Wars: Episode IV - A New Hope",
		year: 1977,
	},
	{ title: "City of God", year: 2002 },
	{ title: "Se7en", year: 1995 },
	{ title: "The Silence of the Lambs", year: 1991 },
	{ title: "It's a Wonderful Life", year: 1946 },
	{ title: "Life Is Beautiful", year: 1997 },
	{ title: "The Usual Suspects", year: 1995 },
	{ title: "Léon: The Professional", year: 1994 },
	{ title: "Spirited Away", year: 2001 },
	{ title: "Saving Private Ryan", year: 1998 },
	{ title: "Once Upon a Time in the West", year: 1968 },
	{ title: "American History X", year: 1998 },
	{ title: "Interstellar", year: 2014 },
];

export default AutoComplete;
