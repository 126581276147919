import styled from "@emotion/styled";

const UserName = styled.h3`
	color: #696a68;

	/* font-weight-bold-lg */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

export { UserName };
