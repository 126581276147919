import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { GetAllShortVideoAccounts, UpdateShortVideoAccount } from "../../../utils/apiCalls";
import Loader from "../../../components/loader/index";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Collapse } from "@mui/material";
import {
	Wrapper,
	NavWrapper,
	BreadCrumb,
	BreadCrumbArrow,
	ProfileWrapper,
	Profile,
	ServiceAccordian,
	ServiceWrap,
	DownArrow,
	ServiceLink,
	ProfileSection,
	ServiceContainer,
	HeadingWrapper,
	HeadIcon,
	HeadText,
	CollapsibleIcons,
	OneWrapper,
	SectionWrapper,
	ContentWrapper,
	LeftWrapper,
	RightWrapper,
	Name,
	MobileHead,
	HeaderWrap,
	BackIcon,
	TextWrap,
	TextWrapper,
} from "./index.styles";
import backarrow from "../../../assets/icons/ArrowBack.png";
import downarrow from "../../../assets/images/icons/arrow.png";
import educationIcon from "../../../assets/images/icons/education.png";
import technologyIcon from "../../../assets/images/icons/technology.png";
import agricultureIcon from "../../../assets/images/icons/Agriculture.png";
import backarrowicon from "../../../assets/icons/back_arrow.png";

const Profiles = () => {
	const navigate = useNavigate();
	const [isLoader, setIsLoader] = useState(false);
	const [shortVideosData, setShortVideosData] = useState([]);
	const [selectedProfileId, setSelectedProfileId] = useState();
	const [updateStatus, setUpdateStatus] = useState("");
	const [expandedSectionIndex, setExpandedSectionIndex] = useState("");

	const toggleCategory = (index) => {
		setExpandedSectionIndex((currentExpandedIndex) => (currentExpandedIndex === index ? null : index));
	};
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	const isScreen = windowWidth <= 768;

	const handleBack = () => {
		navigate("/shorts/feed");
	};

	const getShortVideoAccounts = () => {
		setIsLoader(true);
		GetAllShortVideoAccounts((res) => {
			const { status, data } = res;
			if (status === 200) {
				setIsLoader(false);
				const newData = Object.keys(data).map((key) => ({
					section: key,
					data: data[key],
				}));
				setShortVideosData(newData);
			} else {
				setIsLoader(false);
				setShortVideosData([]);
			}
		});
	};

	useEffect(() => {
		getShortVideoAccounts();
	}, []);

	const handleUpdateAccounts = (shortVideoId) => {
		if (shortVideoId) {
			const payload = { shortVideoId };
			UpdateShortVideoAccount((res) => {
				const { status } = res;
				if (status === 200) {
					navigate("/shorts/profile/private");
				} else {
					setUpdateStatus("error");
				}
			}, payload);
		}
	};

	if (isLoader) {
		return <Loader />;
	}

	const handleBackClick = () => {
		navigate("/shorts/profile/private");
	};

	return (
		<>
			<Wrapper>
				<NavWrapper onClick={handleBack}>
					<BreadCrumbArrow src={backarrow} alt="" />
					<BreadCrumb> Back </BreadCrumb>
				</NavWrapper>
				<ProfileWrapper>
					<Profile>Profiles</Profile>
					<MobileHead>
						<HeaderWrap>
							<BackIcon src={backarrowicon} alt=""onClick={handleBackClick} />
							<TextWrap>Profile</TextWrap>
						</HeaderWrap>
					</MobileHead>
					<ServiceAccordian>
						<ServiceWrap>
							<DownArrow src={downarrow} alt="" />
							<ServiceLink>Services</ServiceLink>
						</ServiceWrap>
						<ProfileSection>
							{shortVideosData
								.filter((section) => section.data.length > 0)
								.map((section, sectionIndex) => {
									const isExpanded = expandedSectionIndex === sectionIndex;
									return (
										<ServiceContainer key={sectionIndex}>
											<HeadingWrapper>
												<HeadText>
													<HeadIcon
														src={
															section.section === "Education"
																? educationIcon
																: section.section === "ITProfileType"
																	? technologyIcon
																	: section.section === "Agriculture"
																		? agricultureIcon
																		: ""
														}
														alt={section.shortVideoAccountType}
													/>
													{section.section === "ITProfileType"
														? "Information Technology"
														: section?.section}{" "}
												</HeadText>
												{section?.data?.length === 0 ? (
													""
												) : (
													<CollapsibleIcons>
														{expandedSectionIndex !== sectionIndex ? (
															<AddIcon onClick={() => toggleCategory(sectionIndex)} />
														) : (
															<RemoveIcon onClick={() => toggleCategory(sectionIndex)} />
														)}
													</CollapsibleIcons>
												)}
											</HeadingWrapper>
											{isScreen ? (
												<Collapse in={expandedSectionIndex === sectionIndex} style={{width:"100%"}}>
													<OneWrapper>
														{section?.data?.length > 0 ? (
															section?.data?.map((shortVideo, index) => (
																<SectionWrapper key={index}>
																	<TextWrapper>{shortVideo?.accountType}</TextWrapper>
																	<ContentWrapper
																		onClick={() =>
																			handleUpdateAccounts(shortVideo?.shortVideoId)
																		}
																	>
																		<LeftWrapper>
																			<input
																				type="radio"
																				name="userOption"
																				value={shortVideo?.title}
																				checked={
																					shortVideo?.shortVideoId === selectedProfileId
																				}
																				onChange={() =>
																					setSelectedProfileId(shortVideo?.shortVideoId)
																				}
																			/>
																		</LeftWrapper>
																		<RightWrapper>
																			<Name
																				onClick={() => {
																					setSelectedProfileId(
																						shortVideo?.shortVideoId,
																					);
																				}}
																			>
																				{shortVideo?.fullName}&nbsp;&nbsp;&nbsp;
																				{`(${shortVideo?.shortVideoUsername})`}
																			</Name>
																		</RightWrapper>
																	</ContentWrapper>
																</SectionWrapper>
															))
														) : (
															<p>No Data available</p>
														)}
													</OneWrapper>
												</Collapse>
											) : (
												<OneWrapper>
													{section?.data?.length > 0 ? (
														section?.data?.map((shortVideo, index) => (
															<SectionWrapper key={index}>
																<TextWrapper>{shortVideo?.accountType}</TextWrapper>
																<ContentWrapper
																	onClick={() => handleUpdateAccounts(shortVideo?.shortVideoId)}
																>
																	<LeftWrapper>
																		<input
																			type="radio"
																			name="userOption"
																			value={shortVideo?.title}
																			checked={
																				shortVideo?.shortVideoId === selectedProfileId
																			}
																			onChange={() =>
																				setSelectedProfileId(shortVideo?.shortVideoId)
																			}
																		/>
																	</LeftWrapper>
																	<RightWrapper>
																		<Name
																			onClick={() => {
																				setSelectedProfileId(shortVideo?.shortVideoId);
																			}}
																		>
																			{shortVideo?.fullName}&nbsp;&nbsp;
																			{`(${shortVideo?.shortVideoUsername})`}
																		</Name>
																	</RightWrapper>
																</ContentWrapper>
															</SectionWrapper>
														))
													) : (
														<p>No Data available</p>
													)}
												</OneWrapper>
											)}
										</ServiceContainer>
									);
								})}
						</ProfileSection>
					</ServiceAccordian>
				</ProfileWrapper>
			</Wrapper>
		</>
	);
};

export default Profiles;
