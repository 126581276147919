const Index = ({ color = "#9E9E9E", size = 24 }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M11.3193 16V7.85L8.71934 10.45L7.31934 9L12.3193 4L17.3193 9L15.9193 10.45L13.3193 7.85V16H11.3193ZM4.31934 20V15H6.31934V18H18.3193V15H20.3193V20H4.31934Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
