import { Title, Info } from "./index.styled";
import Input from "../../../components/Inputs/Input";
import { Fragment } from "react";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { Card, Box } from "@mui/material";

const searchData = [
	{
		name: "Riverdale Bridge Construction",
		location: "Over the Grand River, connecting the",
		content:
			"The Department of Transportation and Infrastructure is considering the construction of a new bridge, designated..",
	},
	{
		name: "Riverdale Bridge Construction",
		location: "Over the Grand River, connecting the",
		content:
			"The Department of Transportation and Infrastructure is considering the construction of a new bridge, designated..",
	},
	{
		name: "Riverdale Bridge Construction",
		location: "Over the Grand River, connecting the",
		content:
			"The Department of Transportation and Infrastructure is considering the construction of a new bridge, designated..",
	},
];

const Search = () => {
	return (
		<Box display={"flex"} flexDirection={"column"} rowGap={"24px"}>
			<Card
				elevation={7}
				sx={{
					borderRadius: "8px",
				}}
			>
				<Input
					name={"search"}
					placeholder={"Search"}
					styles={{
						margin: 0,
						"@media screen and (max-width: 768px)": {
							width: "auto",
						},
					}}
					overrides={{
						"& .MuiOutlinedInput-notchedOutline": {
							border: "none",
						},
					}}
					inputProps={{
						endAdornment: (
							<InputAdornment position="end">
								<SearchIcon sx={{ width: "24px" }} />
							</InputAdornment>
						),
					}}
				/>
			</Card>
			{searchData.map((result, index) => (
				<Card
					elevation={7}
					sx={{
						borderRadius: "8px",
						p: "12px",
					}}
				>
					<Box key={index} display={"flex"} flexDirection={"column"} rowGap={"12px"}>
						<Box display={"flex"} flexDirection={"column"} rowGap={"8px"}>
							<Title>Project Name:</Title>
							<Info>{result?.name}</Info>
						</Box>
						<Box display={"flex"} flexDirection={"column"} rowGap={"8px"}>
							<Title>Location:</Title>
							<Info>{result?.location}</Info>
						</Box>
						<Box display={"flex"} flexDirection={"column"} rowGap={"8px"}>
							<Title>Overview:</Title>
							<Info>{result?.content}</Info>
						</Box>
					</Box>
				</Card>
			))}
		</Box>
	);
};

export default Search;
