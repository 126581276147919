import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import HomePage from "../pages/Investment/Home";
import ClientSignup from "../pages/Investment/Client/Signup";
import CompanySignup from "../pages/Investment/Company/Signup";
import IndividualSignup from "../pages/Investment/Individual/Signup";
import IndividualProfilePrivate from "../pages/Investment/Individual/Profile/Private";
import IndividualProfilePublic from "../pages/Investment/Individual/Profile/Public";
import EmployeeSignup from "../pages/Investment/Employee/Signup";
import SearchResult from "../pages/Investment/SearchResult";
import CompanyProfilePrivate from "../pages/Investment/Company/Profile/Private";
import CompanyProfilePublic from "../pages/Investment/Company/Profile/Public";
import ClientProfilePrivate from "../pages/Investment/Client/Profile/Private";
import ClientProfilePublic from "../pages/Investment/Client/Profile/Public";
import EmployeeProfilePrivate from "../pages/Investment/Employee/Profile/Private";
import EmployeeProfilePublic from "../pages/Investment/Employee/Profile/Public";
import Heading from "../components/Heading/Heading";
import egicon4 from "../assets/icons/investment/investment";

import SubHeader from "../components/Investment/Header";
import SignupMain from "../pages/Investment/SingupMain";
import Microblogging from "../components/Investment/Microblogging";

const ITRoutes = () => {
	const location = useLocation();
	const { pathname } = location;
	const [isInvestment, setIsInvestment] = useState(false);
	const [isUser, setIsUser] = useState(false);
	const [activeProfile, setActiveProfile] = useState("Thomas Mark");

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path?.includes("investment") && !path.includes("microblogging") && !path.includes("signup") && path?.length > 1) {
			setIsInvestment(true);
		} else setIsInvestment(false);

		if (path.includes("private") || path.includes("public")) setIsUser(true);
		else setIsUser(false);

		if (path.includes("company")) setActiveProfile("Paychex");
		else setActiveProfile("Thomas Mark");
	}, [pathname]);
	return (
		<>
			{isInvestment && (
				<>
					<Heading icon={egicon4} title={"Investment"} user={isUser ? activeProfile : "Guest"} />
					<SubHeader />
				</>
			)}
			<Routes>
				<Route path="investment">
					<Route index element={<SignupMain />} />
					<Route path="home" element={<HomePage />} />
					<Route path="microblogging" element={<Microblogging />} />
					<Route path="client">
						<Route path="signup" element={<ClientSignup />} />
						<Route path="profile">
							<Route path="public" element={<ClientProfilePublic />} />
							<Route path="private" element={<ClientProfilePrivate />} />
						</Route>
					</Route>
					<Route path="company">
						<Route path="signup" element={<CompanySignup />} />
						<Route path="profile">
							<Route path="private" element={<CompanyProfilePrivate />} />
							<Route path="public" element={<CompanyProfilePublic />} />
						</Route>
					</Route>
					<Route path="individual">
						<Route path="signup" element={<IndividualSignup />} />
						<Route path="profile">
							<Route path="private" element={<IndividualProfilePrivate />} />
							<Route path="public" element={<IndividualProfilePublic />} />
						</Route>
					</Route>
					<Route path="employee">
						<Route path="signup" element={<EmployeeSignup />} />
						<Route path="profile">
							<Route path="private" element={<EmployeeProfilePrivate />} />
							<Route path="public" element={<EmployeeProfilePublic />} />
						</Route>
					</Route>
					<Route path="search" element={<SearchResult />} />
				</Route>
			</Routes>
		</>
	);
};

export default ITRoutes;
