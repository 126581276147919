import { useEffect, useState } from "react";
import Input from "../../Inputs/Input";
import PrimaryBtn from "../../Buttons/Primary";
import Checkbox from "@mui/material/Checkbox";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import SecondaryBtn from "../../Buttons/Secondary";
import Google from "../../../assets/icons/Google";
import { UserGoogleLogin } from "../../../utils/apiCalls";
import { useNavigate } from "react-router-dom";
import ForgotPasswordModal from "../../ForgotPassword/index";
import { useFormik } from "formik";
import * as yup from "yup";
import {
	Wrapper,
	Title,
	FormWrapper,
	FormTitle,
	Divider,
	DividerText,
	RememberMeWrapper,
	RememberMe,
	ForgotPassword,
	GoogleLogin,
	Signup,
} from "./index.styled";

const validationSchema = yup.object({
	username: yup.string("Email").email("Invalid email").required("Email is required"),
	password: yup
		.string("Enter your password")
		.min(4, "Password should be of minimum 4 characters length")
		.required("Password is required."),
});

const LoginForm = ({ onSubmit, disabled, loginError }) => {
	const [showPassword, setShowPassword] = useState(false);
	const [showForgotPassword, setShowForgotPassword] = useState(false);
	const formik = useFormik({
		initialValues: {
			username: "",
			password: "",
			rememberMe: false,
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onSubmit(values);
		},
	});

	const navigate = useNavigate();

	const handleShowPassword = () => {
		setShowPassword((prev) => !prev);
	};

	const handleForgotPassword = () => {
		setShowForgotPassword((prev) => !prev);
	};

	const handleGoogle = () => {
		UserGoogleLogin((res) => {});
		const googleAuthUrl =
			"https://accounts.google.com/o/oauth2/v2/auth/oauthchooseaccount?response_type=code&redirect_uri=https%3A%2F%2Fapi.buzzgalactic.com%2Fapi%2Fv1%2Flogin%2Fgoogle%2Fredirect&scope=email%20profile&client_id=363715705998-g1j4omj303i94ao4j5751e0nkvgn0atv.apps.googleusercontent.com&service=lso&o2v=2&theme=glif&flowName=GeneralOAuthFlow";
		window.open(googleAuthUrl, "_self");
	};

	const handleSignup = () => {
		navigate("/signup");
	};
	const showError = (formik.touched.password && Boolean(formik.errors.password)) || loginError;
	const errorText = loginError || (formik.touched.password && formik.errors.password);

	useEffect(() => {
		console.log(formik);
	}, [formik]);

	return (
		<>
			<ForgotPasswordModal onClose={handleForgotPassword} open={showForgotPassword} />
			<Wrapper onChange={formik.handleChange} onSubmit={formik.handleSubmit} onBlur={formik.handleBlur}>
				<Title>Hello! Welcome back</Title>
				<FormWrapper>
					<FormTitle>Login with email</FormTitle>
					<Input
						name={"username"}
						placeholder={"Email"}
						required
						error={formik.touched.username && Boolean(formik.errors.username)}
						helperText={formik.touched.username && formik.errors.username}
					/>
					<Input
						name="password"
						placeholder="Password"
						type={showPassword ? "text" : "password"}
						error={showError}
						helperText={errorText}
						required
						inputProps={{
							endAdornment: (
								<InputAdornment position="end">
									<IconButton aria-label="toggle password visibility" onClick={handleShowPassword}>
										{showPassword ? <Visibility /> : <VisibilityOff />}
									</IconButton>
								</InputAdornment>
							),
						}}
					/>
					<RememberMeWrapper>
						<RememberMe
							control={
								<Checkbox
									name="rememberMe"
									checked={formik.values.rememberMe}
									onChange={formik.handleChange}
									color="primary"
								/>
							}
							label={"Remember me"}
						/>
						<ForgotPassword onClick={handleForgotPassword}>Forgot Password?</ForgotPassword>
					</RememberMeWrapper>
					<PrimaryBtn
						label={"LOGIN"}
						overrides={{
							marginBlockEnd: "18px",
							paddingBlock: "8px",
							fontSize: "14px",
						}}
						loading={disabled}
					/>
				</FormWrapper>
				<Divider>
					<DividerText>Login with</DividerText>
				</Divider>
				<GoogleLogin>
					<SecondaryBtn
						label={
							<>
								<Google />
								Google
							</>
						}
						onClick={handleGoogle}
						overrides={{
							width: "100%",
							border: "1px solid #000",
							backgroundColor: "#fff",
							textTransform: "none",
							color: "#000",
							fontFamily: "Inter",
							fontSize: "14px",
							fontStyle: "normal",
							fontWeight: 500,
							lineHeight: "137%" /* 24.66px */,
							marginBlock: "18px",
							paddingBlock: "10px",
							"&:hover": {
								backgroundColor: "#fff",
							},

							"& > svg": {
								marginInlineEnd: "12px",
							},

							"@media screen and (max-width: 768px)": {
								marginBlock: "14px 20px",
							},
						}}
					/>
				</GoogleLogin>
				<Divider></Divider>
				<Signup>
					New User? <span onClick={handleSignup}>Click here</span>
				</Signup>
			</Wrapper>
		</>
	);
};

export default LoginForm;
