import React, { useContext, useEffect, useRef, useState } from "react";
import "./PrivateProfile.scss";
import banner from "../../assets/images/profile/banner.png";
import profile from "../../assets/icons/user-profile.png";
import square_banner from "../../assets/images/user-profile-backsquare.png";
import filter from "../../assets/images/profile/filter.png";
import follow1 from "../../assets/images/profile/follow/1.png";
import follow2 from "../../assets/images/profile/follow/2.png";
import follow3 from "../../assets/images/profile/follow/3.png";
import follow4 from "../../assets/images/profile/follow/4.png";
import photo1 from "../../assets/images/profile/photos/1.png";
import photo2 from "../../assets/images/profile/photos/2.png";
import photo3 from "../../assets/images/profile/photos/3.png";
import photo4 from "../../assets/images/profile/photos/4.png";
import photo5 from "../../assets/images/profile/photos/5.png";
import photo6 from "../../assets/images/profile/photos/6.png";
import photo7 from "../../assets/images/profile/photos/7.png";
import photo8 from "../../assets/images/profile/photos/8.png";
import photo9 from "../../assets/images/profile/photos/9.png";
import photo10 from "../../assets/images/profile/photos/10.png";
import photo11 from "../../assets/images/profile/photos/11.png";
import photo12 from "../../assets/images/profile/photos/12.png";
import crossicon from "../../assets/icons/cross-icon.png";
import PostComponent from "../../components/PostComponent/PostComponent";
import edit from "../../assets/images/edit.png";
import SearchComponent from "../../components/SearchComponent/SearchComponent";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import ReviewComponent from "../../components/ReviewComponent/ReviewComponent";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { UserContext } from "../../store/UserDetailsStore";
import SidenavMobileComponent from "../../components/SidenavMobileComponent";
import { GetAllFriendList, GetAllUserPost, GetAllUserReview } from "../../utils/apiCalls";
import axios from "axios";
import upload from "../../assets/images/upload.png";

import Avatar from "react-avatar-edit";
import ImageCropDialog from "../../components/ImageEditor/ImageCropDialog";

const PrivateProfile = ({ setMenuopen, menuopen }) => {
	const navigate = useNavigate();
	const [isPopupVisible, setPopupVisibility] = useState(false);
	const { isLoginUserDetails, getLoginUserDetails } = useContext(UserContext);
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [profilePicture, setProfilePicture] = useState(null);
	const [profileDataURL, setProfileDataURL] = useState(null);
	const [coverPicture, setCoverPicture] = useState(null);
	const [coverDataURL, setCoverDataURL] = useState(null);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [userList, setUserList] = useState([]);
	const [activePage, setActivePage] = useState(1);
	const [pageSize, setPageSize] = useState(5);
	const [postList, setPostList] = useState([]);
	const [reviewList, setReviewList] = useState([]);
	const [postListAll, setPostListAll] = useState([]);
	const [totalRecordsCount, setTotalRecordsCount] = useState("");
	const [totalPagesCount, setTotalPagesCount] = useState("");
	const [loading, setLoading] = useState(false);
	const bottomOfPageRef = useRef(null);
	const [alertMessage, setAlertMessage] = useState("");
	const [alertReviewMessage, setAlertReviewMessage] = useState("");
	const [preview, setPreview] = useState(null);
	const [showAvatar, setShowAvatar] = useState(false);
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);
	const [cropDialogVisible, setCropDialogVisible] = useState(false);
	const [imageToCrop, setImageToCrop] = useState(null);
	const [showCoverPhotoSelector, setShowCoverPhotoSelector] = useState(false);

	const openFileInput = () => {
		fileInputRef1.current.click();
		setShowCoverPhotoSelector(!showCoverPhotoSelector);
	};
	const openCoverPhotoSelector = () => {
		setShowCoverPhotoSelector(!showCoverPhotoSelector);
		console.log(showCoverPhotoSelector);
	};

	const handleCoverPhotoSelection = (e) => {
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImageToCrop(reader.result);
				setCropDialogVisible(true);
				setShowCoverPhotoSelector(false);
			};
			reader.readAsDataURL(file);
		}
	};

	console.log(reviewList, "wdwddw");

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};

		window.addEventListener("resize", handleResize);

		return () => window.removeEventListener("resize", handleResize);
	}, []);

	useEffect(() => {
		if (isPopupVisible === false) {
			setShowAvatar(false);
		}
	}, [isPopupVisible]);

	const handleSave = () => {
		setProfileDataURL(preview);
		setShowAvatar(!showAvatar);
		console.log(preview, profilePicture);
	};

	const handleCrop = (preview) => {
		setPreview(preview);
	};

	const toggleCropper = () => {
		setShowAvatar(!showAvatar);
	};

	const togglePopup = () => {
		setFirstName(isLoginUserDetails?.firstName);
		setLastName(isLoginUserDetails?.lastName);
		setProfilePicture(isLoginUserDetails?.profilePictureUrl);
		setCoverPicture(isLoginUserDetails?.coverUrl);
		setProfileDataURL(isLoginUserDetails?.profilePictureUrl);
		setCoverDataURL(isLoginUserDetails?.coverUrl);
		setPopupVisibility(!isPopupVisible);
		document.body.style.overflow = isPopupVisible ? "auto" : "scroll";
	};
	useEffect(() => {
		setFirstName(isLoginUserDetails?.firstName);
		setLastName(isLoginUserDetails?.lastName);
		setProfilePicture(isLoginUserDetails?.profilePictureUrl);
		setCoverPicture(isLoginUserDetails?.coverUrl);
	}, [isLoginUserDetails]);

	const handleFirstNameChange = (e) => {
		setFirstName(e);
	};
	const handleLastNameChange = (e) => {
		setLastName(e);
	};

	const handleCancel = () => {
		setFirstName(isLoginUserDetails?.firstName);
		setLastName(isLoginUserDetails?.lastName);
		setProfilePicture(isLoginUserDetails?.profilePictureUrl);
		setCoverPicture(isLoginUserDetails?.coverUrl);
		setProfileDataURL(isLoginUserDetails?.profilePictureUrl);
		setCoverDataURL(isLoginUserDetails?.coverUrl);
		setPopupVisibility(false);
	};

	const fileInputRef = useRef(null);
	const fileInputRef1 = useRef(null);

	const handleFileInputChange = (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			setProfilePicture(selectedFile);

			const reader = new FileReader();
			reader.onloadend = () => {
				setProfileDataURL(reader.result);
			};
			reader.readAsDataURL(selectedFile);
		}
	};

	const handleFileInputChange1 = (e) => {
		const selectedFile = e.target.files[0];
		if (selectedFile) {
			const reader = new FileReader();
			reader.onloadend = () => {
				setImageToCrop(reader.result);
				setCropDialogVisible(true);
			};
			reader.readAsDataURL(selectedFile);
		}
	};

	const handleCroppedImage = async (croppedImageUrl) => {
		try {
			const response = await fetch(croppedImageUrl);
			const blob = await response.blob();

			// Create a new File object for the cover photo
			const file = new File([blob], "cover-photo.jpg", {
				type: "image/jpeg",
			});

			// Update your state to hold the cropped image as File
			setCoverPicture(file);
			setCoverDataURL(croppedImageUrl); // Save the cropped image URL for preview
			setCropDialogVisible(false); // Close crop dialog
		} catch (error) {
			console.error("Error converting cropped image URL to File:", error);
		}
	};

	const handleEditProfile = async () => {
		if (token) {
			try {
				let profileBlob;
				if (preview) {
					const fetchResponse = await fetch(preview);
					profileBlob = await fetchResponse.blob();
				}

				const formData = new FormData();
				formData.append("firstName", firstName);
				formData.append("email", isLoginUserDetails?.email);
				formData.append("lastName", lastName);
				if (profileBlob) {
					formData.append("profile_picture", profileBlob, "profile_picture.png");
				}
				if (coverPicture instanceof File) {
					formData.append("cover_photo", coverPicture, coverPicture.name);
				}

				const queryParams = [
					`firstName=${encodeURIComponent(firstName)}`,
					`lastName=${encodeURIComponent(lastName)}`,
					`email=${encodeURIComponent(isLoginUserDetails?.email)}`,
				].join("&");
				const queryString = queryParams ? `?${queryParams}` : "";

				const response = await axios.put(
					`https://api.buzzgalactic.com/api/v1/profile/editProfile${queryString}`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
							authorization: "Bearer " + [token],
						},
					},
				);

				if (response.data.status === 200) {
					getLoginUserDetails();
					setPopupVisibility(false);
					setPreview(null);
				} else {
					console.error("Error updating profile:", response.data.message);
				}
			} catch (error) {
				console.error("Error updating profile:", error);
			}
		}
	};

	const getAllUserData = () => {
		const payload = {
			searchId: "",
		};
		GetAllFriendList((res) => {
			const { status, messsage, data } = res;
			if (status === 200) {
				setUserList(data);
			} else {
				setUserList([]);
			}
		}, payload);
	};

	useEffect(() => {
		getAllUserData();
	}, []);

	useEffect(() => {
		if (postList?.length !== 0) {
			pushArrayList(postList);
		}
	}, [postList]);

	const pushArrayList = (postList) => {
		for (let i = 0; i < postList.length; i++) {
			const newGeneration = postList[i];
			const isPostId = newGeneration.postId;
			const isGenerationAlreadyPresent = postListAll.some((existingPost) => {
				return existingPost.postId === isPostId;
			});

			if (!isGenerationAlreadyPresent) {
				postListAll.push(newGeneration);
			}
		}
		setPostListAll([...postListAll]);
	};

	const getAllPostData = (pageIndex) => {
		const payload = {
			userId: encodeURIComponent(""),
			pageSize,
			pageIndex,
		};
		GetAllUserPost((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setPostList(data.post);
				setTotalRecordsCount(data?.totalRecords);
				setTotalPagesCount(Math.ceil(data?.totalRecords / pageSize));
			} else if (status === 404) {
				setAlertMessage("No posts available.");
			} else {
				setTotalRecordsCount(0);
				setTotalPagesCount(0);
			}
			setLoading(false);
		}, payload);
	};

	const getAllReviewPost = (pageIndex) => {
		const payload = {
			userId: encodeURIComponent(""),
			pageSize,
			pageIndex,
		};
		GetAllUserReview((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setReviewList(data.reviews);
				setTotalRecordsCount(data?.totalRecords);
				setTotalPagesCount(Math.ceil(data?.totalRecords / pageSize));
			} else if (status === 404) {
				setAlertReviewMessage("No reviews available.");
			} else {
				setTotalRecordsCount(0);
				setTotalPagesCount(0);
			}
			setLoading(false);
		}, payload);
	};

	// const handleScroll = () => {
	// 	if (bottomOfPageRef.current && bottomOfPageRef.current.getBoundingClientRect().bottom <= window.innerHeight + 1) {
	// 		if (activePage < totalPagesCount && !loading) {
	// 			setLoading(true);
	// 			getAllPostData(activePage);
	// 			setActivePage((prevPage) => prevPage + 1);
	// 		}
	// 	}
	// };

	useEffect(() => {
		setActivePage(1);
	}, [pageSize]);

	useEffect(() => {
		if (activePage >= 1) {
			getAllPostData(activePage - 1);
			getAllReviewPost(activePage - 1);
		}
	}, [pageSize, activePage]);

	// useEffect(() => {
	// 	window.addEventListener("scroll", handleScroll);
	// 	return () => {
	// 		window.removeEventListener("scroll", handleScroll);
	// 	};
	// }, [handleScroll]);

	const handleFriendProfile = (id) => {
		sessionStorage?.setItem("searchId", id);
		navigate("/publicprofile");
	};

	return (
		<>
			{token ? (
				<div className="privateprofile" ref={bottomOfPageRef}>
					{menuopen ? (
						<div className="open-class">
							<SidenavMobileComponent />
						</div>
					) : (
						<div className="privateprofile_container">
							<div className="privateprofile_banner">
								{isLoginUserDetails?.coverUrl ? (
									<img src={isLoginUserDetails?.coverUrl} alt="banner" />
								) : (
									<img src={banner} alt="banner" />
								)}
							</div>
							<div className="privateprofile_user">
								<div className="privateprofile_user_details">
									<div className="privateprofile_user_details_image">
										{isLoginUserDetails?.profilePictureUrl === null ||
										isLoginUserDetails?.profilePictureUrl === undefined ? (
											<img src={profile} alt="profile" />
										) : (
											<img src={isLoginUserDetails?.profilePictureUrl} alt="profile" />
										)}
									</div>
									<div className="privateprofile_user_details_content">
										<div className="privateprofile_user_details_content_name">
											{isLoginUserDetails?.name === null || isLoginUserDetails?.name === undefined ? (
												<h3>Anonymous User</h3>
											) : (
												<h3>{isLoginUserDetails?.name}</h3>
											)}
										</div>
										<div className="privateprofile_user_details_content_follow">
											<span>928 followers</span>
											<p>*</p>
											<span>3k following</span>
										</div>
										<div className="privateprofile_user_details_content_images">
											<img src={follow1} alt="follow" />
											<img src={follow2} alt="follow" />
											<img src={follow3} alt="follow" />
											<img src={follow4} alt="follow" />
										</div>
									</div>
								</div>

								<div className="privateprofile_user_data">
									<div className="privateprofile_user_data_sections">
										<div className="privateprofile_user_data_sections_left">
											<div className="privateprofile_user_data_sections_left_container">
												<div className="privateprofile_user_data_sections_left_intro">
													<div className="privateprofile_user_data_sections_left_intro_heading">
														<h4>Intro</h4>
													</div>
													<div
														onClick={togglePopup}
														className="privateprofile_user_data_sections_left_intro_button"
													>
														<button onClick={togglePopup}>Edit Profile</button>
													</div>
													<div className="privateprofile_user_data_sections_left_intro_content">
														<p>
															<span>Profile</span> Digital Creator
														</p>
														<p>Project Manager at lorem ipsum</p>
														<p>Project Manager at lorem ipsum</p>
													</div>
												</div>
												<div className="privateprofile_user_data_sections_left_links">
													<div className="privateprofile_user_data_sections_left_links_heading">
														<h4>Profile links</h4>
														<span>Manage links</span>
													</div>
													<div className="privateprofile_user_data_sections_left_links_content">
														<p>
															<span>Engineering</span> George Kajaia123
														</p>
														<p>
															<span>Profile</span> George Kajaia456
														</p>
														<p>
															<span>Profile</span> George Kajaia897
														</p>
													</div>
												</div>
												<div className="privateprofile_user_data_sections_left_photos">
													<div className="privateprofile_user_data_sections_left_photos_heading">
														<h4>Photos</h4>
														<span>Manage Photos</span>
													</div>
													<div className="privateprofile_user_data_sections_left_photos_container">
														<img src={photo1} alt="photo1" />
														<img src={photo2} alt="photo2" />
														<img src={photo3} alt="photo3" />
														<img src={photo4} alt="photo4" />
														<img src={photo5} alt="photo5" />
														<img src={photo6} alt="photo6" />
														<img src={photo7} alt="photo7" />
														<img src={photo8} alt="photo8" />
														<img src={photo9} alt="photo9" />
														<img src={photo10} alt="photo10" />
														<img src={photo11} alt="photo11" />
														<img src={photo12} alt="photo12" />
													</div>
												</div>
												<div className="privateprofile_user_data_sections_left_friend">
													<div className="privateprofile_user_data_sections_left_friend_heading">
														<h4>Friends</h4>
														<span>
															<NavLink to="/privatefriendrequest">Manage Friends</NavLink>
														</span>
													</div>
													<div className="privateprofile_user_data_sections_left_friend_container">
														{userList.slice(0, 12)?.map((data, ind) => {
															return (
																<img
																	src={data?.profilePicture || square_banner}
																	alt="friend1"
																	key={ind}
																	onClick={() => {
																		handleFriendProfile(data?.userId);
																	}}
																/>
															);
														})}
													</div>
												</div>
											</div>
										</div>
										<div className="privateprofile_user_data_sections_right">
											<SearchComponent padding="3.7vw 3vw" margin="0 0 2vw 0" />

											<div className="privateprofile_user_data_sections_right_tabsection">
												<Tabs>
													<TabList>
														<div className="tablist-custom ">
															<div className="tabnames">
																<Tab>Posts</Tab>
																<Tab>Reviews</Tab>
															</div>
															<div className="tabimage">
																<Tab>
																	<img src={filter} alt="filter" />
																</Tab>
															</div>
														</div>
													</TabList>
													<TabPanel>
														<div className="privateprofile_user_data_sections_right_container">
															{postListAll && postListAll.length > 0 ? (
																postListAll.map((data, index) => (
																	<div key={index}>
																		<PostComponent
																			userId={data?.userId}
																			postid={data?.postId}
																			image={data?.postPicture}
																			heading={"Main"}
																			name={data?.fullName}
																			profile={data?.profilePicture}
																			content={data?.content}
																			likes={data?.likes}
																			Comments={data?.comments}
																			currentUserLikeStatus={data?.currentUserLikeStatus}
																		/>
																	</div>
																))
															) : (
																<p className="ptag">{alertMessage}</p>
															)}
														</div>
													</TabPanel>
													<TabPanel>
														{reviewList && reviewList.length > 0 ? (
															reviewList.map((data, index) => (
																<div key={index}>
																	<ReviewComponent
																		isCurrentUser={data?.isCurrentUser}
																		profile={data?.ReviewerProfilePicture}
																		heading={"Main"}
																		name={data?.ReviewerFullName}
																		commentimage={data?.UserProfilePicture}
																		commenttitle={data?.Reply}
																		review={data?.Review}
																		stars={data?.stars}
																		ReviewerId={data?.ReviewId}
																	/>
																</div>
															))
														) : (
															<p className="ptag">{alertReviewMessage}</p>
														)}
													</TabPanel>
												</Tabs>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					)}

					{isPopupVisible && (
						<div className="privatepopup" onClick={togglePopup}>
							<div className="privatepopup_container" onClick={(e) => e.stopPropagation()}>
								<div className="privatepopup_head">
									<p>Edit Profile</p>
									<img src={crossicon} className="close-button" onClick={togglePopup} alt="Close Popup" />
								</div>

								<div className="privatepopup_heading">
									<div className="Edit">
										<p>Profile Picture</p>
										<div onClick={toggleCropper}>
											<img src={edit} alt="" style={{ cursor: "pointer" }} />
										</div>

										<input
											type="file"
											id="file"
											ref={fileInputRef}
											style={{ display: "none" }}
											onChange={handleFileInputChange}
										/>
									</div>
								</div>
								<div className="privatepopup_picture">
									<img src={profileDataURL || isLoginUserDetails?.profilePictureUrl || profile} alt="" />
								</div>

								{showAvatar && (
									<div className="privatepopup_cropper-modal" onClick={toggleCropper}>
										<div
											className="privatepopup_cropper-modal_container"
											onClick={(e) => e.stopPropagation()}
										>
											<h3>Choose Profile Picture</h3>
											<div className="privatepopup_cropper-modal_titleborder"></div>
											<div className="privatepopup_cropper-modal_avatarandbuttonsection">
												<div className="privatepopup_cropper-modal_avatar">
													<Avatar
														className="avatar-container"
														label={
															<div className="avatar-container_section">
																<img src={upload} alt="" />
																<h3>Upload Photo</h3>
															</div>
														}
														width={windowWidth < 760 ? "75vw" : "40vw"}
														height={300}
														border={0}
														borderRadius={0}
														onCrop={handleCrop}
														src={null}
														labelStyle={{
															height: "100%",
															width: "100%",
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															color: "black",
															cursor: "pointer",
														}}
														style={{
															display: "flex",
															justifyContent: "center",
															alignItems: "center",
															border: "2px dashed #ffffff",
														}}
													/>

													{/* <div className="privatepopup_cropper-modal_separator" />
                        {preview && (
                          <div className="privatepopup_cropper-modal_preview">
                            <img src={preview} alt="Cropped" />
                          </div>
                        )} */}
												</div>
												<div className="privatepopup_cropper-modal_buttons">
													<button className="cancel" onClick={toggleCropper}>
														Cancel
													</button>
													<button className="save" onClick={handleSave}>
														Upload
													</button>
												</div>
											</div>
										</div>
									</div>
								)}

								<div className="privatepopup_heading">
									<div className="Edit">
										<p>Cover Photo</p>
										<div
											onClick={() => {
												fileInputRef1.current?.click();
											}}
											// onClick={openCoverPhotoSelector}
										>
											<img src={edit} alt="" style={{ cursor: "pointer" }} />
										</div>
										<input
											type="file"
											id="file"
											ref={fileInputRef1}
											style={{ display: "none" }}
											onChange={handleFileInputChange1}
										/>
									</div>
								</div>

								{/* {showCoverPhotoSelector && (
									<div className="file-selector-modal">
										<div className="file-selector-modal_container" >
											// <h3>Select Cover Photo</h3>
											// <input type="file" onChange={handleCoverPhotoSelection} />
                      <h3>Choose Profile Picture</h3>
											<div className="privatepopup_cropper-modal_titleborder"></div>
											
                    	<div className="avatar-container_section" onClick={openFileInput}>
												<img src={upload} alt="" />
												<h3>Upload Photo</h3>
											</div>
											<button onClick={() => setShowCoverPhotoSelector(false)}>Cancel</button>
										</div>
									</div>
								)} */}

								<div className="privatepopup_cover">
									<img src={coverDataURL || isLoginUserDetails?.coverUrl || banner} alt="" />
								</div>

								<div className="privatepopup_names">
									<div className="name">
										<h4>First Name</h4>
										<input
											placeholder="Enter your First Name"
											name="firstName"
											value={firstName}
											onChange={(e) => handleFirstNameChange(e.target.value)}
										/>
									</div>
								</div>

								<div className="privatepopup_names">
									<div className="name">
										<h4>Last Name</h4>
										<input
											placeholder="Enter your Last Name"
											value={lastName}
											onChange={(e) => handleLastNameChange(e.target.value)}
										/>
									</div>
								</div>

								<div className="privatepopup_names">
									<div className="name">
										<h4>Email</h4>
										<p>{isLoginUserDetails?.email}</p>
									</div>
								</div>

								<div className="privatepopup_but">
									<div className="section">
										<button className="cancel" onClick={handleCancel}>
											Cancel
										</button>
										<button onClick={handleEditProfile}>Save</button>
									</div>
								</div>
							</div>
						</div>
					)}

					{cropDialogVisible && (
						<div className="cropper-modal">
							<div className="cropper-modal_container">
								<ImageCropDialog
									id="coverImage" // Use file name as a temporary ID
									imageUrl={imageToCrop}
									// Provide initial values for crop, zoom, and aspect
									cropInit={{ x: 0, y: 0 }}
									zoomInit={1}
									aspectInit={{ value: 16 / 9, text: "16/9" }}
									onCancel={() => setCropDialogVisible(false)}
									setCroppedImageFor={(id, crop, zoom, aspect, croppedImageUrl) =>
										handleCroppedImage(croppedImageUrl)
									}
									resetImage={() => setImageToCrop(null)}
								/>
							</div>
						</div>
					)}
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default PrivateProfile;
