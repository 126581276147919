const Index = ({ size = 18 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} viewBox="0 0 21 19" fill="none">
			<path
				d="M1.85406 0.0501032C1.20192 0.193658 0.582584 0.661234 0.279069 1.24365C-0.0162421 1.80967 0.000164062 1.39541 0.000164062 7.63796C0.000164062 13.0725 0.00426561 13.2612 0.0780934 13.376C0.205241 13.5688 0.3611 13.6467 0.623599 13.6467H0.853285L2.14117 12.7321C2.84664 12.2317 3.67925 11.637 3.98687 11.4155C4.89331 10.7715 4.29038 10.8413 9.14661 10.8167L13.3097 10.7962L13.5845 10.6895C14.3433 10.3942 14.9052 9.75436 15.098 8.97097C15.1431 8.7864 15.1554 7.95788 15.1554 4.60282C15.1554 0.534086 15.1554 0.456157 15.0733 0.320806C15.0282 0.242876 14.938 0.144439 14.8765 0.0952206L14.7616 0.00908756L8.43704 0.00088501C3.19527 -0.00321674 2.06734 0.00498676 1.85406 0.0501032ZM11.8126 3.479C12.2105 3.70048 12.2228 4.18856 11.8372 4.47567C11.7265 4.5618 11.6978 4.5618 7.97767 4.5618C4.25757 4.5618 4.22886 4.5618 4.11812 4.47567C3.92534 4.33212 3.84331 4.18856 3.84331 3.99579C3.84331 3.77431 3.94585 3.58974 4.13862 3.479C4.28628 3.39697 4.31089 3.39286 7.97357 3.39286C11.6362 3.39286 11.6568 3.39286 11.8126 3.479ZM11.8126 6.35008C12.2105 6.57156 12.2228 7.05965 11.8372 7.34675C11.7265 7.43289 11.6978 7.43289 7.97767 7.43289C4.25757 7.43289 4.22886 7.43289 4.11812 7.34675C3.92534 7.2032 3.84331 7.05965 3.84331 6.86687C3.84331 6.64539 3.94585 6.46082 4.13862 6.35008C4.28628 6.26805 4.31089 6.26395 7.97357 6.26395C11.6362 6.26395 11.6568 6.26395 11.8126 6.35008Z"
				fill="white"
			/>
			<path
				d="M16.353 6.82182C16.3366 8.79876 16.3325 8.92591 16.2463 9.25814C15.9223 10.5091 15.0323 11.4525 13.7813 11.8708L13.4327 11.9857L9.62235 11.998L5.81201 12.0103L5.83662 12.7978C5.86123 13.6837 5.90635 13.9093 6.14014 14.3564C6.41904 14.8814 6.94404 15.3038 7.58798 15.5171C7.79716 15.5868 8.09247 15.595 11.9561 15.6155C15.9264 15.636 16.1069 15.6401 16.2792 15.714C16.3776 15.7591 17.2758 16.3743 18.2684 17.0839C19.2651 17.7935 20.1182 18.3882 20.1715 18.4087C20.4258 18.5071 20.7457 18.3964 20.9098 18.1585L21 18.0231V12.4081C21 6.20659 21.0123 6.53471 20.7416 6.04253C20.4873 5.58725 20.1674 5.27143 19.7286 5.03765C19.2815 4.79976 19.1338 4.77925 17.6901 4.75874L16.3735 4.73823L16.353 6.82182Z"
				fill="white"
			/>
		</svg>
	);
};

export default Index;
