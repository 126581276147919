import styled from "@emotion/styled";

const Wrapper = styled.div`
	margin-block: 2vw 0;
	display: flex;
	flex-direction: column;
`;
const NavWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	margin: 1vw 0 1vw 5vw;
	width: 4vw;
	cursor: pointer;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;
const BreadCrumb = styled.nav`
	color: #256e61;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const BreadCrumbArrow = styled.img`
	width: 8px;
`;

const ProfileWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
const Profile = styled.div`
	display: flex;
	justify-content: center;
	background-color: #ffffff;
	width: 48%;
	border-radius: 8px 8px 0px 0px;
	background-color: #61bcac;
	padding: 0.8vw 0;
	font-size: 1.2vw;
	color: #fff;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const ServiceAccordian = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	flex-direction: column;
	width: 48%;
	padding: 1vw 0;
	box-shadow: 0 0 8px #b9babd;

	@media screen and (max-width: 768px) {
		width: 95%;
		border-radius: 5px;
	}
`;

const ProfileSection = styled.div`
	display: flex;
	justify-content: space-between;
	flex-wrap: wrap;
	width: 90%;

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		flex-wrap: wrap;
		width: 80%;
		border-radius: 5px;
	}
`;

const ServiceContainer = styled.div`
	width: 38%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: center;

	@media screen and (max-width: 768px) {
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: flex-start;
	}
`;

const MainWrap = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	padding-top: 14px;

	@media screen and (max-width: 768px) {
		width: 90%;
	}
`;

const MainHead = styled.div`
	width: 75%;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 16px 0;

	@media screen and (max-width: 768px) {
		width: 68%;
	}
`;

const UserLink = styled.div`
	font-size: 14px;
	padding-left: 5px;
	cursor:pointer;
`;

const ServiceWrap = styled.div`
	display: flex;
	align-items: center;
	width: 100%;

	@media screen and (max-width: 768px) {
		width: 90%;
	}
`;
const DownArrow = styled.img`
	width: 10px;
	height: 8px;
	padding-left: 1vw;
`;

const ServiceLink = styled.nav`
	color: #30302f;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding-left: 1vw;

	@media screen and (max-width: 768px) {
		font-size: 16px;
	}
`;

const HeadingWrapper = styled.div`
	display: flex;
	width: 100%;
	align-items: center;
	padding: 1vw 0;

	@media screen and (max-width: 768px) {
		display: flex;
		justify-content: space-between;
		padding: 10px 0;
	}
	background-color: ${(props) => (props.isExpanded ? "#E8F8F5" : "transparent")};
	border-radius: 5px;
`;
const HeadText = styled.h3`
	display: flex;
	align-items: center;
	color: #30302f;
	font-size: 16px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding-left: 1vw;
`;

const HeadIcon = styled.img`
	width: 1.2vw;
	padding: 0 5px 0 0;

	@media screen and (max-width: 768px) {
		width: 18px;
	}
`;

const CollapsibleIcons = styled.div`
	display: none;

	@media screen and (max-width: 768px) {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 50%;
		background: #61bcac;
		color: white;
	}
`;

const OneWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	// align-items: center;
	// padding: 0 0 0 34px;

	@media screen and (max-width: 768px) {
		padding: 0 0 0 18px;
	}
`;

const SectionWrapper = styled.div``;

const TextWrapper = styled.h6`
	color: #61bcac;
	font-family: Inter;
	font-size: 1vw;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	@media screen and (max-width: 768px) {
		font-size: 13px;
	}
`;

const ContentWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 0.8vw 0;
	cursor: pointer;

	@media screen and (max-width: 768px) {
		display: flex;
		justify-content: flex-start;
		align-items: center;
		padding: 10px 0;
		width: 100%;
	}
`;

const LeftWrapper = styled.div`
	display: flex;
`;

const RightWrapper = styled.div`
	// min-width: max-content;
	margin-left: 0.5vw;
`;

const InputRadio = styled.input`
	margin: 0;
`;

const Name = styled.p`
	color: #000;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;



export {
	Wrapper,
	NavWrapper,
	BreadCrumb,
	BreadCrumbArrow,
	ProfileWrapper,
	Profile,
	ServiceAccordian,
	MainWrap,
	DownArrow,
	ServiceLink,
	MainHead,
	ServiceWrap,
	ServiceContainer,
	ProfileSection,
	HeadingWrapper,
	HeadIcon,
	HeadText,
	CollapsibleIcons,
	OneWrapper,
	SectionWrapper,
	TextWrapper,
	ContentWrapper,
	LeftWrapper,
	InputRadio,
	RightWrapper,
	Name,
	UserLink,
};
