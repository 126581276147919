import { Fragment, useEffect, useState } from "react";
import {
	btnStyleOverrides,
	BtnWrapper,
	Checkboxes,
	CheckboxesWrapper,
	CheckboxGroupTitle,
	CheckboxWrapper,
	Form,
	FormRowWrapper,
	FormTitle,
	FormWrapper,
	SkillTitle,
	SkillWrapper,
	SubInfoTitle,
	SubSkillsWrapper,
	SubSkillTitle,
	Wrapper,
} from "../../index.styled";
import { Checkbox, FormControlLabel } from "@mui/material";
import PrimaryBtn from "../../../../../Buttons/Primary";
import SecondaryBtn from "../../../../../Buttons/Secondary";

const allSkills = [
	{
		id: 1,
		label: "Programming ( eg. Java, PHP, C++)",
		name: "programming",
		subLabels: [
			{
				id: 1,
				skills: [
					{
						id: 1,
						label: "High-Level Languages:",
						skills: [
							{
								id: 1,
								label: "Java",
								name: "isHLLJava",
							},
							{
								id: 2,
								label: "C#",
								name: "isHLLCSharp",
							},
							{
								id: 3,
								label: "Python",
								name: "isHLLPython",
							},
							{
								id: 4,
								label: "Javascript",
								name: "isHLLJavascript",
							},
							{
								id: 5,
								label: "Ruby",
								name: "isHLLRuby",
							},
							{
								id: 6,
								label: "PHP",
								name: "isHLLPHP",
							},
						],
					},
					{
						id: 2,
						label: "Low-Level Languages:",
						skills: [
							{
								id: 1,
								label: "C",
								name: "isLLLC",
							},
							{
								id: 2,
								label: "C++",
								name: "isLLLCPlusPlus",
							},
						],
					},
					{
						id: 3,
						label: "Functional Languages:",
						skills: [
							{
								id: 1,
								label: "Scala",
								name: "isFLScala",
							},
							{
								id: 2,
								label: "Huskell",
								name: "isFLHuskell",
							},
							{
								id: 3,
								label: "Erlang",
								name: "isFLErlang",
							},
						],
					},
					{
						id: 4,
						label: "Scripting Languages:",
						skills: [
							{
								id: 1,
								label: "Python",
								name: "isScrLPython",
							},
							{
								id: 2,
								label: "Perl",
								name: "isScrLPerl",
							},
							{
								id: 3,
								label: "Bash",
								name: "isScrLBash",
							},
						],
					},
					{
						id: 5,
						label: "Web Development Languages:",
						skills: [
							{
								id: 1,
								label: "HTML/CSS",
								name: "isWebDevLHTMLCSS",
							},
							{
								id: 2,
								label: "JavaScript",
								name: "isWebDevLJavaScript",
							},
							{
								id: 3,
								label: "TypeScript",
								name: "isWebDevLTypeScript",
							},
						],
					},
				],
			},
			{
				id: 2,
				skills: [
					{
						id: 1,
						label: "Front-End Frameworks:",
						skills: [
							{
								id: 1,
								label: "React",
								name: "isFEFReact",
							},
							{
								id: 2,
								label: "Angular",
								name: "isFEFAngular",
							},
							{
								id: 3,
								label: "Vue.js",
								name: "isFEFVue",
							},
						],
					},
					{
						id: 2,
						label: "Back-End Technologies:",
						skills: [
							{
								id: 1,
								label: "Node.js",
								name: "isBEFNode",
							},
							{
								id: 2,
								label: "Django",
								name: "isBEFDjango",
							},
							{
								id: 3,
								label: "Flask",
								name: "isBEFFlask",
							},
							{
								id: 4,
								label: "Ruby on Rails",
								name: "isBEFRuby",
							},
							{
								id: 5,
								label: "ASP.NET",
								name: "isBEFASP",
							},
						],
					},
					{
						id: 3,
						label: "Testing Frameworks:",
						skills: [
							{
								id: 1,
								label: "Jest",
								name: "isTestFJest",
							},
							{
								id: 2,
								label: "Mocha",
								name: "isTestFMocha",
							},
							{
								id: 3,
								label: "Jasmine",
								name: "isTestFJasmine",
							},
						],
					},
					{
						id: 4,
						label: "Machine Learning Libraries:",
						skills: [
							{
								id: 1,
								label: "TensorFlow",
								name: "isMachLearLibTensorFlow",
							},
							{
								id: 2,
								label: "PyTorch scikit-learn)",
								name: "isMachLearLibPyTorch",
							},
						],
					},
					{
						id: 5,
						label: "Version Control tools:",
						skills: [
							{
								id: 1,
								label: "Git",
								name: "isVersionControlGit",
							},
							{
								id: 2,
								label: "SVN",
								name: "isVersionControlSVN",
							},
						],
					},
					{
						id: 6,
						label: "Code Review and Quality Assurane Practices",
						name: "isCodeReview",
					},
					{
						id: 7,
						label: "Application Development",
						name: "isApplicationDev",
					},
				],
			},
			{
				id: 3,
				skills: [
					{
						id: 1,
						label: "Desktop Application Development",
						name: "isDesktopApp",
					},
					{
						id: 2,
						label: "Mobile Application Development:",
						skills: [
							{
								id: 1,
								label: "iOS (Swift)",
								name: "isMobileAppIOS",
							},
							{
								id: 2,
								label: "Android (Java/Kotlin)",
								name: "isMobileAppAndroid",
							},
						],
					},
					{
						id: 3,
						label: "Architecture",
						name: "isMobileAppArchitecture",
					},
					{
						id: 4,
						label: "Database Integration",
						name: "isMobileAppDatabaseIntegration",
					},
					{
						id: 5,
						label: "Working with SQL and NoSQL databases: ",
						skills: [
							{
								id: 1,
								label: "MySQL",
								name: "isWorkingDBMySQL",
							},
							{
								id: 2,
								label: "PostgreSQL",
								name: "isWorkingDBPostgreSQL",
							},
							{
								id: 3,
								label: "MongoDB",
								name: "isWorkingDBMongoDB",
							},
							{
								id: 4,
								label: "Firebase",
								name: "isWorkingDBFirebase",
							},
						],
					},
				],
			},
		],
	},
	{
		id: 2,
		label: "Network Administrator",
		name: "networkAdministration",
	},
	{
		id: 3,
		label: "System Administration",
		name: "systemAdministration",
	},
	{
		id: 4,
		label: "Database Management (e.g., SQL, Oracle)",
		name: "databaseManagement",
		subLabels: [
			{
				id: 1,
				skills: [
					{
						id: 1,
						label: "Database Systems",
						name: "isDatabaseSystem",
					},
					{
						id: 2,
						label: "Proficiency in Database Management Systems (DBMS):",
						name: "isProficientinDBMS",
						skills: [],
					},
					{
						id: 3,
						label: "Relation Databases:",
						skills: [
							{
								id: 1,
								label: "SQL Server",
								name: "isRDBMSSqlServer",
							},
							{
								id: 2,
								label: "Oracle",
								name: "isRDBMSOracle",
							},
							{
								id: 3,
								label: "MySQL",
								name: "isRDBMSMySQL",
							},
							{
								id: 4,
								label: "PostgreSQL",
								name: "isRDBMSPostgreSQL",
							},
						],
					},
					{
						id: 3,
						label: "NonSQL Databases:",
						skills: [
							{
								id: 1,
								label: "MongoDB",
								name: "isNoSQLMongoDB",
							},
							{
								id: 2,
								label: "Cassandra",
								name: "isNoSQLCassandra",
							},
							{
								id: 3,
								label: "Redis, Neo4j",
								name: "isNoSQLRedis",
							},
						],
					},
				],
			},
		],
	},
	{
		id: 5,
		label: "Cybersecurity and Information Security",
		name: "cybersecurityAndInfoSecurity",
	},
	{
		id: 6,
		label: "Cloud Computing (e.g., AWS, Azure)",
		name: "cloudComputing",
	},
	{
		id: 7,
		label: "Software Development",
		name: "softwareDevelopment",
	},
	{
		id: 8,
		label: "Web Development (Frontend, Backend)",
		name: "webDevelopment",
	},
	{
		id: 9,
		label: "IT Support and Helpdesk Services",
		name: "itSupportAndHelpdeskServices",
	},
	{
		id: 10,
		label: "User Interface/User Experience (UI/UX) Design",
		name: "userInterface",
	},
];

const FreelancerSkills = ({ setSkills, handleBack, handleSubmit }) => {
	const [activeSkills, setActiveSkills] = useState({});
	const handleCheckbox = (event, name) => {
		setActiveSkills({
			...activeSkills,
			[name]: event.target.checked,
		});
	};

	const handleGroupCheckbox = (event, name) => {
		const selected = { ...activeSkills };
		let currentGroup = allSkills?.filter((skill) => skill?.name === name)[0];
		if (event?.target?.checked === false) {
			selected[name] = false;
			currentGroup?.subLabels?.forEach((subLabel) => {
				subLabel?.skills?.forEach((skill) => {
					skill?.skills
						? skill?.skills?.forEach((subSkill) => {
								selected[subSkill?.name] = false;
							})
						: (selected[skill?.name] = false);
				});
			});
		} else selected[name] = true;
		setActiveSkills(selected);
	};

	useEffect(() => {
		const defaultActiveSkills = {};
		allSkills.forEach((skills) => {
			defaultActiveSkills[skills?.name] = false;
			skills?.subLabels?.forEach((subLabel) =>
				subLabel?.skills?.forEach((skill) => {
					skill?.skills?.forEach((subSkill) => {
						defaultActiveSkills[subSkill?.name] = false;
					});
				}),
			);
		});
		setActiveSkills(defaultActiveSkills);
	}, []);

	useEffect(() => {
		setSkills && setSkills(activeSkills);
	}, [activeSkills]);

	const onSubmit = (event) => {
		event.preventDefault();
		handleSubmit();
	};

	return (
		<Wrapper>
			<FormWrapper onSubmit={onSubmit}>
				<FormTitle>Provide Your Freelancer Information</FormTitle>
				<Form>
					<SubInfoTitle>Skills & Expertise</SubInfoTitle>
					{allSkills?.map((allSkill) => (
						<FormRowWrapper key={allSkill?.id} childCount={1}>
							<CheckboxWrapper>
								<CheckboxGroupTitle>
									<FormControlLabel
										control={
											<Checkbox
												name={allSkill?.name}
												onChange={(event) => handleGroupCheckbox(event, allSkill?.name)}
											/>
										}
										label={<CheckboxGroupTitle>{allSkill?.label}</CheckboxGroupTitle>}
									/>
								</CheckboxGroupTitle>
								<CheckboxesWrapper>
									{allSkill?.subLabels?.map((subLabel) => (
										<Fragment key={subLabel?.id}>
											<SkillWrapper>
												{subLabel?.skills?.map((subSkill) => (
													<SubSkillsWrapper key={subSkill?.id}>
														{!subSkill?.skills ? (
															<FormControlLabel
																control={
																	<Checkbox
																		name={subSkill?.name}
																		checked={activeSkills[subSkill?.name] === true}
																		disabled={!activeSkills[allSkill?.name]}
																		onChange={(event) =>
																			handleCheckbox(event, subSkill?.name)
																		}
																	/>
																}
																label={<SubSkillTitle>{subSkill?.label}</SubSkillTitle>}
															/>
														) : (
															<SubSkillTitle>{subSkill?.label}</SubSkillTitle>
														)}
														<Checkboxes>
															{subSkill?.skills?.map((skill) => (
																<FormControlLabel
																	key={skill?.id}
																	control={
																		<Checkbox
																			name={skill?.name}
																			checked={activeSkills[skill?.name] === true || false}
																			disabled={!activeSkills[allSkill?.name]}
																			onChange={(event) =>
																				handleCheckbox(event, skill?.name)
																			}
																		/>
																	}
																	label={<SkillTitle>{skill?.label}</SkillTitle>}
																/>
															))}
														</Checkboxes>
													</SubSkillsWrapper>
												))}
											</SkillWrapper>
										</Fragment>
									))}
								</CheckboxesWrapper>
							</CheckboxWrapper>
						</FormRowWrapper>
					))}
				</Form>
				<BtnWrapper>
					<SecondaryBtn label={"Back"} onClick={handleBack} overrides={btnStyleOverrides} />
					<PrimaryBtn label={"Next"} overrides={btnStyleOverrides} />
				</BtnWrapper>
			</FormWrapper>
		</Wrapper>
	);
};

export default FreelancerSkills;
