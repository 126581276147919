import styled from "@emotion/styled";
import { Fragment, useEffect, useState } from "react";
import { borderRadius, color, spacing } from "../../../../styles/variables";
import ProfilePicture from "../../../../assets/images/MB/dp.png";
import ListView from "../../../../assets/icons/shorts/mobile/ListView";
import Favorites from "@mui/icons-material/FavoriteBorderRounded";
import Profileicon from "../../../../assets/icons/user_grey.png";
import {
	ResultWrapper,
	Result,
	ProfileWrapper,
	UserImage,
	UserDetailsWrapper,
	UserDetails,
	UserName,
	StatsWrapper,
	Stat,
	ViewIcons,
	EditIcon,
	Btnwrapper,
} from "../../../../pages/Shorts/index.styled";
import Preview1 from "../../../../assets/images/shorts/Preview1.png";
import Preview2 from "../../../../assets/images/shorts/Preview2.png";
import PrimaryBtn from "../../../../components/Buttons/Primary";
import Edit from "../../../../assets/icons/Edit";
import { useLocation, useNavigate } from "react-router-dom";
import { GetPublicShortVideoProfile, GetShortVideoUserDetails, GetShortVideosPrivateProfile } from "../../../../utils/apiCalls";
import Loader from "../../../../components/loader/index";

const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	width: max-content;
	margin: 0 auto;
	margin-block-start: ${spacing("md2")};
`;

const Publicprofile = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const userData = location?.state?.user;
	const [shortVideoPublicData, setShortVideoPublicData] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const [shortVideoUser, setShortVideoUser] = useState([]);

	const getPublicITShortVideoProfile = (id) => {
		setIsLoader(true);
		if (id) {
			const payload = {
				itProfileId: id,
			};
			GetPublicShortVideoProfile((res) => {
				const { message, data, status } = res;
				if (status === 200) {
					setIsLoader(false);
					setShortVideoPublicData(data);
				} else {
					setIsLoader(false);
					setShortVideoPublicData([]);
				}
			}, payload);
		}
	};

	const getPublicShortVideoProfile = (id) => {
		setIsLoader(true);
		if (id) {
			const payload = {
				shortVideoId: id,
			};
			GetShortVideosPrivateProfile((res) => {
				const { message, data, status } = res;
				if (status === 200) {
					setIsLoader(false);
					setShortVideoPublicData(data);
				} else {
					setIsLoader(false);
					setShortVideoPublicData([]);
				}
			}, payload);
		}
	};

	useEffect(() => {
		if (userData) {
			if (userData?.itProfileId) {
				getPublicITShortVideoProfile(userData?.itProfileId);
			} else if (userData?.shortVideoId) {
				getPublicShortVideoProfile(userData?.shortVideoId);
			}
		}
	}, [userData]);

	const getSvUserDetail = (id) => {
		if (id) {
			const payload = {
				shortVideoId: id,
			};
			GetShortVideoUserDetails((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setShortVideoUser(data);
					if (data?.module === "Information Technology") {
						const accountTyp = data?.accountType?.toLowerCase();
						navigate(`/it/${accountTyp}/profile/public`, { state: { user: data } });
					}
				} else {
					setShortVideoUser([]);
				}
			}, payload);
		}
	};

	const handlePublicprofile = () => {
		getSvUserDetail(shortVideoPublicData?.shortVideosId || userData?.shortVideoId);
	};

	const handleprofileIcon = () => {
		navigate("/shorts/profiles");
	};
	
	if (isLoader) {
		return <Loader />;
	}
	return (
		<Fragment>
			<ProfileWrapper>
				<ImageWrapper>
					<UserImage src={shortVideoPublicData?.profilePicture || ProfilePicture} alt="profile" />
					<EditIcon>
						<Edit />
					</EditIcon>
				</ImageWrapper>
				<UserDetailsWrapper>
					<UserDetails>
						<UserName>
							<img src={Profileicon} alt="icon" onClick={handleprofileIcon} />
							{shortVideoPublicData?.fullName || "Anonymous User"}
						</UserName>
						<StatsWrapper>
							<Stat>
								448
								<span>Following</span>
							</Stat>
							<Stat>
								744
								<span>Followers</span>
							</Stat>
						</StatsWrapper>
					</UserDetails>
					<Btnwrapper>
					<PrimaryBtn
						label={"Follow"}
						overrides={{
							textTransform: "none",
							borderRadius: borderRadius(60),
							paddingInline: spacing("md1"),
							paddingBlock: spacing("xs"),
						}}
					/>
						<PrimaryBtn
						label={"Information Tech"} onClick={handlePublicprofile}
						overrides={{
							textTransform: "none",
							borderRadius: borderRadius(60),
							paddingInline: spacing("md1"),
							paddingBlock: spacing("xs"),
						}}
					/>
					</Btnwrapper>
				</UserDetailsWrapper>
			</ProfileWrapper>
			<ViewIcons>
				<ListView />
				<Favorites />
			</ViewIcons>
			<ResultWrapper
				style={{
					marginInline: 0,
					paddingBlockEnd: 0,
				}}
			>
				<Result src={Preview1} alt="preview" />
				<Result src={Preview2} alt="preview" />
				<Result src={Preview2} alt="preview" />
				<Result src={Preview1} alt="preview" />
				<Result src={Preview1} alt="preview" />
				<Result src={Preview2} alt="preview" />
				<Result src={Preview2} alt="preview" />
				<Result src={Preview1} alt="preview" />
				<Result src={Preview1} alt="preview" />
				<Result src={Preview2} alt="preview" />
			</ResultWrapper>
		</Fragment>
	);
};

export default Publicprofile;
