import React, { useState } from "react";
import "./PublicFriendrequest.scss";
import banner from "../../assets/images/profile/banner.png";
import tick from "../../assets/icons/charm_tick.svg";
import friends from "../../assets/icons/account.png";
import profile from "../../assets/icons/user-profile.png";
import follow1 from "../../assets/images/profile/follow/1.png";
import follow2 from "../../assets/images/profile/follow/2.png";
import follow3 from "../../assets/images/profile/follow/3.png";
import follow4 from "../../assets/images/profile/follow/4.png";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import HomeHeader from "../../components/HomeHeader/HomeHeader";
import addfriend from "../../assets/images/icons/add-friend.png";
import message from "../../assets/images/icons/message.png";
import funds from "../../assets/images/icons/funds.png";
import { Navigate, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { AddFriendRequest, GetAllFriendList, GetAllFriendListSentStatus, GetUserDetails } from "../../utils/apiCalls";

const PublicFriendrequest = () => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const searchId = sessionStorage.getItem("searchId");
	const [profileData, setProfileData] = useState([]);
	const [userList, setUserList] = useState([]);
	const [statusData, setStatusData] = useState([]);

	useEffect(() => {
		const payload = {
			searchId: searchId,
		};
		if (searchId) {
			GetUserDetails((res) => {
				const { message, status, data } = res;
				if (status === 200) {
					setProfileData(data);
				} else {
					setProfileData([]);
				}
			}, payload);
		}
	}, []);

	const getAllUserData = () => {
		const payload = {
			searchId: searchId,
		};
		if (payload) {
			GetAllFriendList((res) => {
				const { message, data, status } = res;
				if (status === 200) {
					setUserList(data);
				} else {
					setUserList([]);
				}
			}, payload);
		}
	};

	const handleAddFriend = () => {
		if (searchId) {
			const payload = {
				recipientId: searchId,
			};
			if (payload) {
				AddFriendRequest((res) => {
					const { message, status, data } = res;
					if (status === 200) {
						handleSentStatus();
					}
				}, payload);
			}
		}
	};

	const handleSentStatus = () => {
		const payload = {
			searchId: searchId,
		};
		if (searchId) {
			GetAllFriendListSentStatus((res) => {
				const { message, status, data } = res;
				if (status === 200) {
					setStatusData(data);
				} else {
					setStatusData(data);
				}
			}, payload);
		}
	};

	useEffect(() => {
		handleAddFriend();
		getAllUserData();
	}, []);

	const handleFriendProfile = (id) => {
		sessionStorage?.setItem("searchId", id);
		navigate("/publicprofile");
	};

	return (
		<>
			{token ? (
				<div className="publicfriend">
					<HomeHeader />
					<div className="publicfriend_container">
						<div className="publicfriend_banner">
							{profileData?.coverUrl ? (
								<img src={profileData?.coverUrl} alt="banner" />
							) : (
								<img src={banner} alt="banner" />
							)}
						</div>
						<div className="publicfriend_user">
							<div className="publicfriend_user_details">
								<div className="publicfriend_user_details_left">
									<div className="publicfriend_user_details_image">
										{profileData?.profilePictureUrl ? (
											<img src={profileData?.profilePictureUrl} alt="profile" />
										) : (
											<img src={profile} alt="profile" />
										)}
									</div>
									<div className="publicfriend_user_details_content">
										<div className="publicfriend_user_details_content_name">
											{profileData?.name ? <h3>{profileData?.name}</h3> : <h3>Anonymous User</h3>}
										</div>
										<div className="publicfriend_user_details_content_follow">
											<span>928 followers</span>
											<p>*</p>
											<span>3k following</span>
										</div>
										<div className="publicfriend_user_details_content_images">
											<img src={follow1} alt="follow" />
											<img src={follow2} alt="follow" />
											<img src={follow3} alt="follow" />
											<img src={follow4} alt="follow" />
										</div>
									</div>
								</div>
								<div className="publicfriend_user_details_right">
									{statusData?.friendRequestStatus === "canceled" ? (
										<div
											className="publicfriend_user_details_right_buttonsec"
											style={{ display: "flex", cursor: "pointer" }}
											onClick={handleAddFriend}
										>
											<img src={addfriend} alt="" />
											<p>Add Friend</p>
										</div>
									) : statusData?.friendRequestStatus === "pending" ? (
										<div
											className="publicfriend_user_details_right_buttonsec"
											style={{ display: "flex", cursor: "pointer" }}
											onClick={handleAddFriend}
										>
											<img src={tick} alt="" />
											<p>Request Sent</p>
										</div>
									) : statusData?.friendRequestStatus === "accepted" ? (
										<div
											className="publicfriend_user_details_right_buttonsec"
											style={{ display: "flex", cursor: "pointer" }}
											onClick={handleAddFriend}
										>
											<img src={friends} alt="" />
											<p>Friends</p>
										</div>
									) : (
										<></>
									)}
									<div className="publicfriend_user_details_right_buttonsec" style={{ display: "flex" }}>
										<img src={message} alt="" />
										<p>Message</p>
									</div>
									<div className="publicfriend_user_details_right_buttonsec" style={{ display: "flex" }}>
										<img src={funds} alt="" />
										<p>Send Funds</p>
									</div>
								</div>
							</div>
							<div className="publicfriend_user_data">
								<div className="publicfriend_user_data_sections">
									<div className="publicfriend_user_data_sections_right">
										<div className="publicfriend_user_data_sections_right_tabsection">
											<Tabs>
												<TabList>
													<div className="tablist-custom ">
														<div className="tabnames">
															<Tab>Friends</Tab>
														</div>
													</div>
												</TabList>
												<TabPanel>
													<div className="publicfriend_user_data_sections_right_tabsection_tabpanel1_friendscards">
														{/* {[0, 1, 2, 3, 4, 6].map((index) => (
                              <div
                                key={index}
                                className="publicfriend_user_data_sections_right_tabsection_tabpanel1_friendscard"
                              >
                                <div className="friendscard-image">
                                  <img src={natasha} alt="" />
                                  <p>Natasha</p>
                                </div>
                                <div
                                  onMouseEnter={() => handleMouseEnter(index)}
                                  onMouseLeave={() => handleMouseLeave(index)}
                                  className="friendscard-content"
                                >
                                  <Button style={{ background: "white" }}>
                                    <img src={point} alt="" />
                                  </Button>
                                </div>
                                {rectangles[index] && (
                                  <div className="rectangle">
                                    <img src={Unfriend} alt="" />
                                    <p>Unfriend</p>
                                  </div>
                                )}
                              </div>
                            ))} */}
														{/* </div> */}

														{userList?.map((data, index) => {
															return (
																<div
																	className="publicfriend_user_data_sections_right_tabsection_tabpanel1_friendscard"
																	key={index}
																	onClick={() => {
																		handleFriendProfile(data?.userId);
																	}}
																>
																	<div className="friendscard-image">
																		{data?.profilePicture ? (
																			<img src={data?.profilePicture} alt="" />
																		) : (
																			<img src={profile} alt="" />
																		)}
																		{data?.fullName ? (
																			<p>{data?.fullName}</p>
																		) : (
																			<p>Anonymous User</p>
																		)}
																	</div>
																</div>
															);
														})}
													</div>
												</TabPanel>
											</Tabs>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default PublicFriendrequest;
