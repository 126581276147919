import React from "react";

const Videos = ({ color = "#D03131", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 29 29" fill="none">
			<path
				d="M18.628 11.933L13.1905 8.30805C13.054 8.21697 12.8953 8.16466 12.7314 8.15672C12.5674 8.14878 12.4044 8.1855 12.2598 8.26295C12.1151 8.34041 11.9942 8.4557 11.9099 8.59652C11.8256 8.73733 11.7811 8.89839 11.7812 9.0625V16.3125C11.7811 16.4766 11.8256 16.6377 11.9099 16.7785C11.9942 16.9193 12.1151 17.0346 12.2598 17.112C12.4044 17.1895 12.5674 17.2262 12.7314 17.2183C12.8953 17.2103 13.054 17.158 13.1905 17.067L18.628 13.442C18.7523 13.3592 18.8542 13.2471 18.9247 13.1154C18.9952 12.9838 19.0321 12.8368 19.0321 12.6875C19.0321 12.5382 18.9952 12.3912 18.9247 12.2596C18.8542 12.1279 18.7523 12.0158 18.628 11.933ZM13.5938 14.6189V10.7617L16.4915 12.6875L13.5938 14.6189ZM24.4688 4.53125H4.53125C4.05055 4.53125 3.58953 4.72221 3.24962 5.06212C2.90971 5.40203 2.71875 5.86305 2.71875 6.34375V19.0312C2.71875 19.512 2.90971 19.973 3.24962 20.3129C3.58953 20.6528 4.05055 20.8438 4.53125 20.8438H24.4688C24.9495 20.8438 25.4105 20.6528 25.7504 20.3129C26.0903 19.973 26.2812 19.512 26.2812 19.0312V6.34375C26.2812 5.86305 26.0903 5.40203 25.7504 5.06212C25.4105 4.72221 24.9495 4.53125 24.4688 4.53125ZM24.4688 19.0312H4.53125V6.34375H24.4688V19.0312ZM26.2812 23.5625C26.2812 23.8029 26.1858 24.0334 26.0158 24.2033C25.8459 24.3733 25.6154 24.4688 25.375 24.4688H3.625C3.38465 24.4688 3.15414 24.3733 2.98418 24.2033C2.81423 24.0334 2.71875 23.8029 2.71875 23.5625C2.71875 23.3221 2.81423 23.0916 2.98418 22.9217C3.15414 22.7517 3.38465 22.6562 3.625 22.6562H25.375C25.6154 22.6562 25.8459 22.7517 26.0158 22.9217C26.1858 23.0916 26.2812 23.3221 26.2812 23.5625Z"
				fill={color}
			/>
		</svg>
	);
};

export default Videos;
