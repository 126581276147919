import styled from "@emotion/styled";
import { borderRadius } from "../../../../styles/variables";

const Breadcrumbs = styled.span`
	color: #61bcac;

	/* font-weight-medium-md */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	text-transform: capitalize;

	@media screen and (max-width: 768px) {
		font-size: 12px;
	}
`;

const ModuleUserAvatar = styled.img`
	width: 42px;
	height: 42px;
	border-radius: 50%;
`;

const ModuleUserName = styled.span`
	text-transform: capitalize;
	color: #797979;

	/* font-weight-medium-md */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const ModuleTitle = styled.span`
	text-transform: capitalize;
	color: #61bcac;

	/* body-text-xsm */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const PostAvatar = styled.img`
	width: 56px;
	height: 56px;
	border-radius: 50%;
`;

const PostAuthor = styled.span`
	text-transform: capitalize;
	color: #797979;

	/* font-weight-bold-xl */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const PostModule = styled.span`
	text-transform: capitalize;
	color: #61bcac;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const PostDescription = styled.span`
	color: #797979;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const PostedAt = styled.span`
	color: #848484;
	font-family: Inter;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const ThumbnailWrapper = styled.div`
	border-radius: ${borderRadius(8)};
	width: 100%;
	overflow: hidden;
`;

const Thumbnail = styled.img`
	width: 100%;
`;

export {
	Breadcrumbs,
	ModuleUserAvatar,
	ModuleUserName,
	ModuleTitle,
	PostAvatar,
	PostAuthor,
	PostedAt,
	PostModule,
	PostDescription,
	Thumbnail,
	ThumbnailWrapper,
};
