import React, { useState, useEffect } from "react";
import Home from "../../../assets/images/MB/home.png";
import Explore from "../../../assets/images/MB/explore.png";
import Notifications from "../../../assets/images/MB/notifications.png";
import Messages from "../../../assets/images/MB/messages.png";
import { NavLink, Navigate } from "react-router-dom";
import mbpic1 from "../../../assets/icons/public/blog1.png";

import MBPost from "../../../components/MB/Post";
import Search from "../../../assets/images/MB/search.png";
import {
	EduWrapperLeft,
	EduWrapperCenter,
	EduWrapperRight,
	Wrapper,
	MobileBottom,
	MobileBottomIcons,
} from "../styles/index.styled";
import MBRight from "../../../components/RightSubNav";
import LeftSubNav from "../../../components/LeftSubNav";
import "react-tabs/style/react-tabs.css";
import MBLeftOther from "../../../components/MB/MBLeftOther/MBLeftOther";
import MBPostStatus from "../../../components/MB/PostStatus";
import MBMobileBottom from "../../../components/MB/MBMobileBottom/MBMobileBottom";
import Page from "../../../layouts/Page";

const MBMonthly = () => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 760);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			{token ? (
				<>
					<Page>
						<EduWrapperLeft>
							<LeftSubNav />
							<MBLeftOther visible={!isMobile} />
						</EduWrapperLeft>
						<EduWrapperCenter>
							<MBLeftOther visible={isMobile} />
							<MBPostStatus />
							<MBPost
								heading={"Michelle"}
								content="Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting gas the Jews"
								image={mbpic1}
							/>
							<MBPost
								heading={"Michelle"}
								content="Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting gas the Jews.Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hama"
							/>
						</EduWrapperCenter>
						<EduWrapperRight>
							<MBRight />
						</EduWrapperRight>
					</Page>
					<MBMobileBottom />
				</>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default MBMonthly;
