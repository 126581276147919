import { Box } from "@mui/material";
import MagazineCover from "../../../../assets/images/MagazineCover.png";
import { Author, Details, Headline, ImageWrapper, InfoSection, ModuleName, Title, Wrapper } from "./index.styled";
import useLayout from "../../../../utils/hooks/useLayout";

const topics = [
	{
		module: "Information Technology",
		title: "I spent the morning with the Apple Vision Pro",
		descriptions:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
		author: "John Doe",
		thumbnail: MagazineCover,
	},
	{
		module: "Education",
		title: "The future of AI in the workplace",
		descriptions:
			"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco",
		author: "John Doe",
		thumbnail: MagazineCover,
	},
];

const LatestTopics = () => {
	const isMobile = useLayout();
	return (
		<Box display={"flex"} gridRow={2} gridColumn={"span 10"} flexDirection={"column"} rowGap={isMobile ? "24px" : "58px"}>
			<Title>The Latest</Title>
			{topics?.map((topic, index) => (
				<Wrapper>
					<InfoSection>
						<Box display={"flex"} flexDirection={"column"} rowGap={"12px"}>
							<ModuleName>{topic?.module}</ModuleName>
							<Headline>{topic?.title}</Headline>
							<Author>{topic?.author}</Author>
						</Box>
					</InfoSection>
					<Details>{topic?.descriptions}</Details>
					<ImageWrapper>
						<img src={topic?.thumbnail} alt="Magazine Cover" />
					</ImageWrapper>
				</Wrapper>
			))}
		</Box>
	);
};

export default LatestTopics;
