import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";
import { TextField } from "@mui/material";
import { borderRadius } from "../../../styles/variables";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { spacing } from "../../../styles/variables";

const Wrapper = styled.div`
	background: #e8f8f5;
	padding-block: 20px;
	display: flex;
	justify-content: center;

	@media screen and (max-width: 768px) {
		background-color: #fff;
	}
`;

const Container = styled.div`
	width: -webkit-fill-available;
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	margin-inline: 40px;
	grid-column-gap: ${spacing("lg")};

	@media screen and (max-width: 768px) {
		display: flex;
		justify-content: space-between;
		margin-inline: 8px;
		grid-column-gap: ${spacing("xsl")};
	}
	@media screen and (min-width: 1440px) {
		max-width: 1440px;
	}
`;

const LeftSection = styled.div`
	grid-column: span 3;

	display: flex;

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: fit-content;
	}
`;

const LogoContainer = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	& > img {
		width: 132px;
	}
	${(props) =>
		!props.noBorder &&
		`
			border-right: 1px solid #cbc3c3;
			margin-right: 22px;

			& > img {
				padding-right: 12px;
			}

			@media screen and (max-width: 768px) {
				margin-right: 6px;
				& > img {
					padding-right: 4px;
					margin-right: 0;
				}
			}
	`};

	@media screen and (max-width: 768px) {
		& > img {
			width: 132px;
		}
	}

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		& > img {
			width: 90px;
			margin-right: 0;
		}
	}
`;

const ModuleName = styled(NavLink)`
	display: flex;
	align-items: center;
	column-gap: 12px;
	text-transform: capitalize;
	color: #256e61;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;

	& > img {
		width: 18px;
		aspect-ratio: 1;
	}

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		& > img {
			width: 18px;
			aspect-ratio: 1;
		}
		
	}
	@media screen and (max-width: 768px) {
		column-gap: 6px;
	}
`;

const MiddleSection = styled.div`
	grid-column: span 3;

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const Search = styled(TextField)`
	background: #fff;
	border-radius: ${borderRadius(8)};

	.MuiInput-input {
		padding-inline-start: 8px;

		&::placeholder {
			color: #989898;
			font-family: Inter;
			font-size: 16px;
			font-style: normal;
			font-weight: 500;
			line-height: normal;
			opacity: 1;
		}
	}
`;

const RightSection = styled.div`
	grid-column: span 9;
	display: grid;

	grid-template-columns: repeat(9, 1fr);

	@media screen and (max-width: 768px) {
		display: flex;
		justify-content: space-between;
	}

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		display: flex;
		justify-content: space-between;
		padding-left: 16px;
	}
`;

const NavContainer = styled.div`
	grid-column: span 7;
	display: flex;
	align-items: center;
	// justify-content: space-between;
	width: 100%;
	column-gap: 36px;

	@media screen and (max-width: 768px) {
		column-gap: 6px;
	}

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		display: flex;
		justify-content: space-between;
		width: 100%;
	}
`;

const NavItem = styled(NavLink)`
	cursor: pointer;
	display: flex;
	align-items: center;
	column-gap: 12px;

	border-radius: 6px;
	padding: 4px 10px;

	${(props) =>
		props.active && {
			background: "red",
			backgroundColor: "#5e6b86",
			color: "white",
		}}

	& > img {
		width: 14px;
	}
	& > p {
		font-size: 14px;
		margin: 0;
	}

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		column-gap: 8px;

		& > img {
			width: 12px;
		}
		& > p {
			font-size: 12px;
			margin: 0;
		}
	}

	@media screen and (max-width: 768px) {
		// margin: 0 8px 0 0;
		padding: 4px 4px;
	}
`;

const Activewrap = styled.div`
	background-color: #5e6b86;
	display: flex;
	column-gap: 12px;
	color: white;

	& > p {
		font-size: 14px;
		margin: 0;
	}

	@media screen and (min-width: 769px) and (max-width: 1024px) {
		column-gap: 8px;

		& > p {
			font-size: 12px;
			margin: 0;
		}
	}
`;
const MoreContainer = styled.div`
	margin-inline-start: auto;
	grid-column: span 2;
	display: flex;
	align-items: center;

	& > span {
		cursor: pointer;
		display: inline-flex;
		align-items: center;
		column-gap: 12px;
	}
`;

const LightTooltip = styled(({ className, ...props }) => <Tooltip {...props} classes={{ popper: className }} />)(({ theme }) => ({
	[`& .${tooltipClasses.tooltip}`]: {
		paddingInline: "16px",
		cursor: "pointer",
		display: "flex",
		alignItems: "center",
		columnGap: "8px",
		color: "#256F61",
		fontFamily: "Inter",
		fontSize: "14px",
		fontStyle: "normal",
		fontWeight: 400,
		lineHeight: "normal",
		backgroundColor: "#FFF",
		boxShadow: "0px 4px 13px rgba(0, 0, 0, 0.25)",
	},
	[`& .${tooltipClasses.arrow}`]: {
		color: "#FFF",
		cursor: "pointer",
	},
}));

const TooltipContent = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;
	padding-inline: 16px;
	padding-block: 6px;
	column-gap: 12px;
	align-items: center;

	color: #256f61;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	background-color: #fff;
	box-shadow: 0px 4px 13px rgba(0, 0, 0, 0.25);
`;

const NotificationsWrapper = styled.div`
	min-width: 300px;
	padding: 16px 12px;
`;

const NotificationsTitle = styled.h3`
	color: #000;
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const NotificationsSubWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 12px;
`;

const Notification = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 8px;
`;

const NotificationInfo = styled.div`
	display: flex;
	column-gap: 12px;
	align-items: center;
`;

const NotificationDesc = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 2px;
`;

const NotificationImg = styled.img`
	width: 42px;
	aspect-ratio: 1;

	border-radius: 50%;
`;

const NotificationModule = styled.span`
	color: #256f61;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const NotificationTitle = styled.span`
	color: #8b8b8b;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const NotificationTime = styled.span`
	color: #575757;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

export {
	Wrapper,
	Container,
	LeftSection,
	LogoContainer,
	ModuleName,
	MiddleSection,
	Search,
	RightSection,
	NavContainer,
	NavItem,
	MoreContainer,
	LightTooltip,
	TooltipContent,
	NotificationsWrapper,
	NotificationsTitle,
	NotificationsSubWrapper,
	Notification,
	NotificationInfo,
	NotificationTitle,
	NotificationModule,
	NotificationImg,
	NotificationTime,
	NotificationDesc,
	Activewrap,
};
