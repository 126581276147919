import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import EastIcon from "@mui/icons-material/East";
import Slick from "../../../components/Slick";
import AddIcon from "@mui/icons-material/AddOutlined";
import {
	Button,
	CardWrapper,
	IconWrapper,
	OriginCountry,
	PostJob,
	Rate,
	Role,
	ScreenTitle,
	ScreenTitleWrapper,
	SectionTitle,
	SectionWrapper,
	Stack,
	StackWrapper,
	SubWrapper,
	TechStacks,
	UserDetails,
	UserDetailsWrapper,
	UserImage,
	UserName,
	Wrapper,
} from "../index.styled";
import { spacing } from "../../../styles/variables";
import useLayout from "../../../utils/hooks/useLayout";
import { query } from "../../../utils/queryController";
import { useQuery } from "@tanstack/react-query";
import Loader from "../../../components/loader/index.js";

import {
	GetTopClientITProfile,
	GetTopCompanyITProfile,
	GetTopEmployeeITProfile,
	GetTopFreelancerITProfile,
} from "../../../utils/apiCalls";

const dummySkills = [
	{
		stack: ["laravel Framework"],
	},
	{
		stack: ["Codegnitor"],
	},
	{
		stack: ["javascript"],
	},
	{
		stack: ["javascript"],
	},
];

const members = [
	{
		id: 1,
		role: "Full Stack Developer",
	},
	{
		id: 2,
		role: "Website Developer",
	},
	{
		id: 3,
		role: "Python Developer",
	},
	{
		id: 4,
		role: "Java Developer",
	},
	{
		id: 5,
		role: "Full Stack Developer",
	},
];

const HomePage = ({ userData, setSignUpPopupToggle, signUpPopup }) => {
	const isMobile = useLayout();
	const [isLoader, setIsLoader] = useState(false);
	const [freeLancerData, setFreeLancerData] = useState([]);
	const [employeeData, setEmployeeData] = useState([]);
	const [companyData, setCompanyData] = useState([]);
	const [clientData, setClientData] = useState([]);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const togglePopup = (talent) => {
		if (!userData?.itProfileType) {
			setSignUpPopupToggle(!signUpPopup);
		} else if (talent?.itProfileType) {
			const profile = talent?.itProfileType?.toLowerCase();
			navigate(`/it/${profile}/profile/public`, { state: { user: talent } });
		}
	};

	const { data, isLoading } = useQuery({
		queryKey: ["ITProfile"],
		queryFn: async () => {
			const response = await query("GET", "IT", "getITProfile");
			return response.data;
		},
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	const getTopFreelancerITProfileData = (pageIndex) => {
		setIsLoader(true);
		const payload = {
			pageSize: 4,
			pageIndex: 0,
		};
		GetTopFreelancerITProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setFreeLancerData(data?.result);
				setIsLoader(false);
			}
		}, payload);
	};

	useEffect(() => {
		getTopFreelancerITProfileData();
	}, []);

	const getEmployeeProfileData = (pageIndex) => {
		setIsLoader(true);
		const payload = {
			pageSize: 4,
			pageIndex: 0,
		};
		GetTopEmployeeITProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setEmployeeData(data?.result);
				setIsLoader(false);
			}
		}, payload);
	};

	useEffect(() => {
		getEmployeeProfileData();
	}, []);

	const getCompanyProfileData = () => {
		setIsLoader(true);
		const payload = {
			pageSize: 4,
			pageIndex: 0,
		};
		GetTopCompanyITProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setCompanyData(data?.result);
				setIsLoader(false);
			}
		}, payload);
	};

	useEffect(() => {
		getCompanyProfileData();
	}, []);

	const getClientProfileData = () => {
		setIsLoader(true);
		const payload = {
			pageSize: 4,
			pageIndex: 0,
		};
		GetTopClientITProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setClientData(data?.result);
				setIsLoader(false);
			}
		}, payload);
	};

	useEffect(() => {
		getClientProfileData();
	}, []);

	if (isLoading) return <Loader />;

	return (
		<>
			{token ? (
				isLoader ? (
					<Loader />
				) : (
					<Wrapper>
						<ScreenTitleWrapper>
							<ScreenTitle>
								<span className="screen-title">Discover top rated talent</span>
								<span className="mobile-title">Browse Talent</span>
								<EastIcon />
							</ScreenTitle>
							<PostJob onClick={togglePopup}>
								Post a Job <AddIcon />
							</PostJob>
						</ScreenTitleWrapper>
						<SubWrapper>
							<SectionWrapper>
								<SectionTitle>{freeLancerData[0]?.itProfileType}</SectionTitle>
								{freeLancerData[0]?.itProfileType && (
									<Slick colSpace={isMobile ? spacing("sm") : spacing("md3")}>
										{freeLancerData?.map((talent, index) => (
											<CardWrapper key={talent.id}>
												<UserDetailsWrapper>
													<UserImage src={talent?.profilePicture} alt="profile" />
													<UserDetails>
														<UserName>{talent?.fullName}</UserName>
														<OriginCountry>{talent?.location}</OriginCountry>
													</UserDetails>
													<IconWrapper>
														<FavoriteBorderIcon />
													</IconWrapper>
												</UserDetailsWrapper>
												<StackWrapper>
													<Role>{members[index % members.length].role}</Role>
													<Rate>
														<MonetizationOnOutlinedIcon /> $8/hr
													</Rate>
													<TechStacks>
														{dummySkills?.map((stack) => (
															<Stack>{stack?.stack}</Stack>
														))}
													</TechStacks>
												</StackWrapper>
												<Button onClick={() => togglePopup(talent)}>View Profile</Button>
											</CardWrapper>
										))}
									</Slick>
								)}
							</SectionWrapper>
							<SectionWrapper>
								<SectionTitle>{employeeData[0]?.itProfileType}</SectionTitle>
								{employeeData[0]?.itProfileType && (
									<Slick colSpace={isMobile ? spacing("sm") : spacing("md3")}>
										{employeeData?.map((talent, index) => (
											<CardWrapper key={index}>
												<UserDetailsWrapper>
													<UserImage src={talent?.profilePicture} alt="profile" />
													<UserDetails>
														<UserName>{talent?.fullName}</UserName>
														<OriginCountry>{talent?.location}</OriginCountry>
													</UserDetails>
													<IconWrapper>
														<FavoriteBorderIcon />
													</IconWrapper>
												</UserDetailsWrapper>
												<StackWrapper>
													<Role>{members[index % members.length].role}</Role>
													<TechStacks>
														{dummySkills?.map((stack) => (
															<Stack>{stack?.stack}</Stack>
														))}
													</TechStacks>
												</StackWrapper>
												<Button onClick={() => togglePopup(talent)}>View Profile</Button>
											</CardWrapper>
										))}
									</Slick>
								)}
							</SectionWrapper>
							<SectionWrapper>
								<SectionTitle>{companyData[0]?.itProfileType}</SectionTitle>
								{companyData[0]?.itProfileType && (
									<Slick colSpace={isMobile ? spacing("sm") : spacing("md3")}>
										{companyData?.map((talent, index) => (
											<CardWrapper key={index}>
												<UserDetailsWrapper>
													<UserImage src={talent?.profilePicture} alt="profile" />
													<UserDetails>
														<UserName>{talent?.companyName}</UserName>
														<OriginCountry>{talent?.location}</OriginCountry>
													</UserDetails>
													<IconWrapper>
														<FavoriteBorderIcon />
													</IconWrapper>
												</UserDetailsWrapper>
												<StackWrapper>
													<Role>{members[index % members.length].role}</Role>
													<TechStacks>
														{dummySkills?.map((stack) => (
															<Stack>{stack?.stack}</Stack>
														))}
													</TechStacks>
												</StackWrapper>
												<Button onClick={() => togglePopup(talent)}>View Profile</Button>
											</CardWrapper>
										))}
									</Slick>
								)}
							</SectionWrapper>
							<SectionWrapper>
								<SectionTitle>{clientData[0]?.itProfileType}</SectionTitle>
								{clientData[0]?.itProfileType && (
									<Slick colSpace={isMobile ? spacing("sm") : spacing("md3")}>
										{clientData?.map((talent) => (
											<CardWrapper>
												<UserDetailsWrapper>
													<UserImage src={talent?.profilePicture} alt="profile" />
													<UserDetails>
														<UserName>{talent?.fullName}</UserName>
														<OriginCountry>{talent?.location}</OriginCountry>
													</UserDetails>
													<IconWrapper>
														<FavoriteBorderIcon />
													</IconWrapper>
												</UserDetailsWrapper>
												<StackWrapper>
													<Role>{talent?.skill}</Role>
													{talent?.ratePerHour && (
														<Rate>
															<MonetizationOnOutlinedIcon /> ${talent?.ratePerHour}/hr
														</Rate>
													)}
													{talent?.skill && (
														<TechStacks>
															{talent?.skill?.map((stack) => (
																<Stack>{stack}</Stack>
															))}
														</TechStacks>
													)}
												</StackWrapper>
												<Button onClick={() => togglePopup(talent)}>View Profile</Button>
											</CardWrapper>
										))}
									</Slick>
								)}
							</SectionWrapper>
						</SubWrapper>
					</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state.it?.profile?.data,
		signUpPopup: state.it?.popup,
	};
};

const mapPropsToState = (dispatch) => ({
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_IT",
			payload: data,
		}),
});

export default connect(mapStateToProps, mapPropsToState)(HomePage);
