import { useRef } from "react";
import styled from "@emotion/styled";
import Slider from "react-slick";
import { color, spacing } from "../../styles/variables";
import ArrowLeft from "../../assets/icons/ArrowLeft";
import ArrowRight from "../../assets/icons/ArrowRight";

const Wrapper = styled.div`
	/* margin-block: ${spacing("lg")}; */

	min-height: 100px;
	/* margin-inline: calc(${(props) => props?.gap} * -1); */

	.slick-slider {
		margin-inline: calc(${(props) => props?.gap} * -1);
		position: relative;

		.slick-track {
			display: flex !important;
			padding-block: ${spacing("md")};
		}

		.slick-slide {
			height: inherit;
			display: flex;

			& > div {
				display: flex;
				width: 100%;
			}
		}

		& > svg {
			position: absolute;
		}
		.slick-arrow {
			cursor: pointer;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			z-index: 9;
			background: ${color("pWhite")};
			padding: ${spacing("sm")};
			box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.25);
			border-radius: 50%;

			&.slick-disabled {
				display: none;
			}
			&.slick-prev {
				left: calc((${(props) => props?.gap}) * 0.2);
			}
			&.slick-next {
				right: calc((${(props) => props?.gap}) * 0.2);
			}
		}
		.slick-list .slick-track {
			display: flex;
			column-gap: ${(props) => props.gap};
		}
	}
`;

const Slick = ({ colSpace = spacing("md"), children, customSetting = {} }) => {
	const sliderRef = useRef();
	const settings = {
		infinite: false,
		slidesToShow: 4,
		slidesToScroll: 1,
		adaptiveHeight: false,
		arrows: true,
		ceterPadding: "48px",
		prevArrow: <ArrowLeft onClick={sliderRef?.current?.slickPrev} size={60} className={"slick-arrow"} />,
		nextArrow: <ArrowRight onClick={sliderRef?.current?.slickNext} size={60} className={"slick-arrow"} />,
		responsive: [
			{
				breakpoint: 767,
				settings: {
					slidesToShow: 1,
					slidesToScroll: 1,
					dots: true,
					arrows: false,
				},
			},
		],
		...customSetting,
	};
	return children ? (
		<Wrapper gap={colSpace}>
			<Slider {...settings}>{children}</Slider>
		</Wrapper>
	) : (
		<></>
	);
};

export default Slick;
