import LeftSubNav from "../../../components/LeftSubNav";
import Page from "../../../layouts/Page";
import { LeftNav, NotificationSection } from "./index.styled";
import NotificationsInfo from "../../../components/PublicControl/Notifications";
import useLayout from "../../../utils/hooks/useLayout";

const Notifications = () => {
	const isMobile = useLayout();
	return (
		<Page
			overrides={
				isMobile && {
					"@media screen and (max-width: 768px)": {
						paddingBlockEnd: "54px",
					},
				}
			}
		>
			<LeftNav>
				<LeftSubNav videos={true} support={false} />
			</LeftNav>
			<NotificationSection>
				<NotificationsInfo />
			</NotificationSection>
		</Page>
	);
};

export default Notifications;
