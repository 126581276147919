import React, { useState } from "react";
import "./Banking.scss";
import Heading from "../../components/Heading/Heading";
import banking from "../../assets/icons/banking.png";
import balance from "../../assets/icons/balance.png";
import account from "../../assets/icons/account.png";
import activetranscation from "../../assets/icons/activetranscation.png";
import transaction from "../../assets/icons/transcation.png";
import earning from "../../assets/icons/earning (2).png";
import activeearning from "../../assets/icons/activeearning.png";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Navigate } from "react-router-dom";

function createData(Date, Username, From, To, Amount, Balane, Details) {
	return { Date, Username, From, To, Amount, Balane, Details };
}

const rows = [
	createData("11/10/2023", "Petersmith", "", "Werner", "$456", "$56,784", "..."),
	createData("12/10/2023", "Michelle", "Thomas", "", "$410", "$56,484", "..."),
	createData("10/10/2023", "Elle", "", "Aram", "$566", "$55,664", "..."),
];

const Banking = () => {
	const [value, setValue] = useState("one");
	const [activeTab, setActiveTab] = useState("one");
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const handleChange = (event, newValue) => {
		setValue(newValue);
		setActiveTab(newValue);
	};
	return (
		<>
			{token ? (
				<div className="banking">
					<Heading icon={banking} title={"Banking"} showSearchInput={true} />
					<div className="banking_container">
						<div className="banking_container_leftside">
							<div className="banking_container_leftside_top">
								<div className="banking_container_leftside_top_header">
									<img src={balance} alt="logo" />
									<p>Balance Summary</p>
								</div>
								<div className="banking_container_leftside_top_content">
									<p>$56,789</p>
									<div className="date">
										<p>Available balance</p>
										<p>as of 11/10/2023</p>
									</div>
								</div>
							</div>
							<div className="banking_container_leftside_bottom">
								<div className="banking_container_leftside_bottom_header">
									<img src={account} alt="logo" />
									<p>Account Usernames</p>
								</div>
								<div className="banking_container_leftside_bottom_content">
									<div className="banking_container_leftside_bottom_content_data">
										<p>Petersmith</p>
									</div>
									<div className="banking_container_leftside_bottom_content_data">
										<p>Michelle</p>
									</div>
									<div className="banking_container_leftside_bottom_content_data">
										<p>Elle Thomas</p>
									</div>
									<div className="banking_container_leftside_bottom_content_data">
										<p>Aram ronald</p>
									</div>
								</div>
							</div>
						</div>
						<div className="banking_container_rightside">
							<TabContext value={value}>
								<Box sx={{ width: "100%", background: "#256F6b!important" }}>
									<Tabs
										value={value}
										onChange={handleChange}
										textColor="secondary"
										indicatorColor="secondary"
										aria-label="secondary tabs example"
									>
										<Tab
											value="one"
											icon={
												activeTab === "one" ? (
													<img src={activetranscation} alt="Transaction Icon (Active)" />
												) : (
													<img src={transaction} alt="Transaction Icon (Normal)" />
												)
											}
											label="Transaction"
										/>
										<Tab
											value="two"
											icon={
												activeTab === "two" ? (
													<img src={activeearning} alt="Earning Icon (Active)" />
												) : (
													<img src={earning} alt="Earning Icon (Normal)" />
												)
											}
											label="Earning"
										/>
									</Tabs>
									<TabPanel value="one">
										<TableContainer>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell align="left">Date</TableCell>
														<TableCell align="left">Username</TableCell>
														<TableCell align="left">From</TableCell>
														<TableCell align="left">To</TableCell>
														<TableCell align="left">Amount</TableCell>
														<TableCell align="left">Balance</TableCell>
														<TableCell align="left">Details</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{rows.map((row) => (
														<TableRow key={row.name} align="left">
															<TableCell scope="row">{row.Date}</TableCell>
															<TableCell align="left">{row.Username}</TableCell>
															<TableCell align="left">{row.From}</TableCell>
															<TableCell align="left">{row.To}</TableCell>
															<TableCell align="left">{row.Amount}</TableCell>
															<TableCell align="left">{row.Balane}</TableCell>
															<TableCell align="left">{row.Details}</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</TabPanel>
									<TabPanel value="two">
										<TableContainer>
											<Table aria-label="simple table">
												<TableHead>
													<TableRow>
														<TableCell align="left">Date</TableCell>
														<TableCell align="left">Username</TableCell>
														<TableCell align="left">From</TableCell>
														<TableCell align="left">To</TableCell>
														<TableCell align="left">Amount</TableCell>
														<TableCell align="left">Balance</TableCell>
														<TableCell align="left">Details</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{rows.map((row) => (
														<TableRow key={row.name} align="left">
															<TableCell scope="row">{row.Date}</TableCell>
															<TableCell align="left">{row.Username}</TableCell>
															<TableCell align="left">{row.From}</TableCell>
															<TableCell align="left">{row.To}</TableCell>
															<TableCell align="left">{row.Amount}</TableCell>
															<TableCell align="left">{row.Balane}</TableCell>
															<TableCell align="left">{row.Details}</TableCell>
														</TableRow>
													))}
												</TableBody>
											</Table>
										</TableContainer>
									</TabPanel>
								</Box>
							</TabContext>
						</div>
					</div>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default Banking;
