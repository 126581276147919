const Index = ({ color = "white", size = 48 }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_8166_506)">
				<path
					d="M12.5 1.70005L12.955 1.16805C12.8282 1.0596 12.6668 1 12.5 1C12.3332 1 12.1718 1.0596 12.045 1.16805L12.5 1.70005ZM2.7 10.1L2.245 9.56804L2 9.77804V10.1H2.7ZM9.7 21.3V22C9.88565 22 10.0637 21.9263 10.195 21.795C10.3263 21.6637 10.4 21.4857 10.4 21.3H9.7ZM15.3 21.3H14.6C14.6 21.4857 14.6737 21.6637 14.805 21.795C14.9363 21.9263 15.1143 22 15.3 22V21.3ZM22.3 10.1H23V9.77804L22.755 9.56804L22.3 10.1ZM4.1 22H9.7V20.6H4.1V22ZM22.755 9.56804L12.955 1.16805L12.045 2.23205L21.845 10.632L22.755 9.56804ZM12.045 1.16805L2.245 9.56804L3.155 10.632L12.955 2.23205L12.045 1.16805ZM10.4 21.3V17.1H9V21.3H10.4ZM14.6 17.1V21.3H16V17.1H14.6ZM15.3 22H20.9V20.6H15.3V22ZM23 19.9V10.1H21.6V19.9H23ZM2 10.1V19.9H3.4V10.1H2ZM12.5 15C13.057 15 13.5911 15.2213 13.9849 15.6151C14.3788 16.0089 14.6 16.5431 14.6 17.1H16C16 16.1718 15.6313 15.2815 14.9749 14.6252C14.3185 13.9688 13.4283 13.6 12.5 13.6V15ZM12.5 13.6C11.5717 13.6 10.6815 13.9688 10.0251 14.6252C9.36875 15.2815 9 16.1718 9 17.1H10.4C10.4 16.5431 10.6212 16.0089 11.0151 15.6151C11.4089 15.2213 11.943 15 12.5 15V13.6ZM20.9 22C21.457 22 21.9911 21.7788 22.3849 21.385C22.7788 20.9911 23 20.457 23 19.9H21.6C21.6 20.0857 21.5263 20.2637 21.395 20.395C21.2637 20.5263 21.0857 20.6 20.9 20.6V22ZM4.1 20.6C3.91435 20.6 3.7363 20.5263 3.60503 20.395C3.47375 20.2637 3.4 20.0857 3.4 19.9H2C2 20.457 2.22125 20.9911 2.61508 21.385C3.0089 21.7788 3.54305 22 4.1 22V20.6Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_8166_506">
					<rect width="21" height="21" fill="white" transform="translate(2 1)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Index;
