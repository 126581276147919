import styled from "@emotion/styled";
import { borderRadius, color, spacing } from "../../../../styles/variables";

const Wrapper = styled.div`
	grid-column: 3 / span 8;
	width: 100%;
	display: flex;
	justify-content: center;
	margin-block: ${spacing("xl1")};

	@media screen and (max-width: 768px) {
		margin-block: 0 24px;
	}
`;

const FormWrapper = styled.form`
	width: 100%;
	grid-column: 3 / span 8;
	display: inline-block;
	max-width: 740px;
	margin-block: 40px;
	margin-inline: auto;

	border-radius: ${borderRadius(6)};

	@media screen and (max-width: 768px) {
		margin-block: ${spacing("xs")};
		margin-inline: ${spacing("sm")};
		width: 100%;

		${(props) =>
			props.initialForm && {
				backgroundColor: color("pWhite"),
				gridColumn: "2/12",
				width: "-webkit-fill-available",
				marginBlock: spacing("xl"),
				paddingInline: 0,
				marginInline: 0,
				paddingBlock: 0,
			}}
	}

	${(props) =>
		props.initialForm && {
			backgroundColor: color("pWhite"),
			gridColumn: "span 12",
			width: "-webkit-fill-available",
			marginBlock: spacing("xl2"),
		}}
`;

const FormTitle = styled.div`
	background-color: ${color("pGreen")};
	padding: 14px 20px;
	border-radius: 6px 6px 0 0;

	&,
	& > strong {
		color: ${color("pWhite")};
	}

	& > strong {
		font-weight: 600;
	}

	@media screen and (max-width: 768px) {
		padding: ${spacing("md1")};
		margin-block-end: ${spacing("md")};
		border-radius: ${borderRadius(6)};
		text-align: center;
		font-family: Poppins;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		& > strong {
			display: none;
		}
	}
`;

const Form = styled.div`
	padding: ${spacing("md3")};
	margin-block-end: ${spacing("md")};

	border: 1px solid ${color("borderDimGray")};
	border-block-start-width: 0;
	border-bottom-left-radius: ${borderRadius(8)};
	border-bottom-right-radius: ${borderRadius(8)};
	@media screen and (max-width: 768px) {
		padding: ${spacing("md")};
		border-block-start-width: 1px;
		border-radius: ${borderRadius(6)};
	}
`;

const BtnWrapper = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-block-start: 24px;

	button {
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
	}
	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
		row-gap: ${spacing("sm2")};

		button {
			width: 100%;
		}
	}
`;

const FormContainer = styled.div`
	width: 100%;
`;

const FormTitleInfo = styled.h3`
	color: ${color("pBlack")};
	font-family: Inter;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	margin-block-end: ${spacing("md2")};

	@media screen and (max-width: 768px) {
		margin-block-end: ${spacing("md")};
		font-size: 18px;
	}
`;

const FormRowWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(${(props) => props.childCount || 2}, 1fr);
	grid-column-gap: ${(props) => (props.colGap ? props.colGap : spacing("md"))};
	&:last-of-type > div:only-child {
		margin-block-end: 0;
	}
	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(${(props) => props.childCount || 1}, 1fr);
	}
`;

const btnStyleOverrides = {
	textTransform: "none",
	paddingInline: spacing("xxl"),
	paddingBlock: spacing("sm"),
};

const CheckboxWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	align-items: flex-start;

	.MuiFormControlLabel-label {
		color: ${color("pBlack")};
		font-family: Inter;
		font-size: 16px !important;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
		text-align: left !important;
	}
`;

export {
	Wrapper,
	FormWrapper,
	FormTitle,
	Form,
	BtnWrapper,
	FormContainer,
	FormTitleInfo,
	FormRowWrapper,
	btnStyleOverrides,
	CheckboxWrapper,
};
