const initialUserState = {
	// initial state
};

const commonReducer = (state = initialUserState, action) => {
	// reducer logic based on different action types
	switch (action.type) {
		case "SET_HOME_HEADER_HEIGHT":
			state = {
				...state,
				homeHeaderHeight: action?.payload || null,
			};
			break;
	}
	return state;
};

export default commonReducer;
