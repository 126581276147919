import React, { createContext, useState, useEffect } from "react";
import { GetUserDetails } from "../utils/apiCalls";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
	const [isLoginUserDetails, setIsLoginUserDetails] = useState("");
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");

	useEffect(() => {
		getLoginUserDetails();
	}, []);

	const getLoginUserDetails = () => {
		const payload = {
			token: token,
		};
		if (token) {
			GetUserDetails((res) => {
				if (res?.status === 200) {
					setIsLoginUserDetails(res?.data);
				}
			}, payload);
		}
	};

	const updateLoginUserDetails = (newData) => {
		setIsLoginUserDetails((prevData) => ({ ...prevData, ...newData }));
	};

	const clearLoginUserDetails = () => {
		setIsLoginUserDetails(null);
	};

	const userContextStore = {
		isLoginUserDetails,
		updateLoginUserDetails,
		clearLoginUserDetails,
		getLoginUserDetails,
	};

	return <UserContext.Provider value={userContextStore}>{children}</UserContext.Provider>;
};
