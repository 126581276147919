import React, { useEffect, useState } from "react";
import locationMark from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/user-profile-backsquare.png";
import editable from "../../../../../assets/images/editable.png";
import editablenoborder from "../../../../../assets/images/editablenoborder.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserWorkLocationAndSkills,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	EducationHistory,
	EducationTitle,
	Education,
	SkillsWrapper,
	SkillsTitle,
	Skills,
	ProfileHeading,
	ManageOtherProfile,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
} from "../../../styles/index.styled.js";
import { ProfilesWrapper } from "../../../../Education/styles/index.styled.js";
import { GetEducationRegularEmployeeProfile } from "../../../../../utils/apiCalls.js";
import Loader from "../../../../../components/loader/index.js";

const EmployeePrivate = () => {
	const [educationEmployeeData, setEducationEmployeeData] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const location = useLocation();
	const userData = location.state?.userData;
	const navigate = useNavigate();
	const handlePublicView = () => {
		navigate("/education/employee/profile/public");
	};
	const getEducationEmployeeProfile = () => {
		if (userData) {
			setIsLoader(true);
			const payload = {
				educationTypeId: userData?.educationTypeId || userData?.accountTypeId,
				educationId: userData?.educationId || userData?.moduleId,
			};
			GetEducationRegularEmployeeProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setIsLoader(false);
					setEducationEmployeeData(data);
				} else {
					setIsLoader(false);
					setEducationEmployeeData();
				}
			}, payload);
		}
	};

	useEffect(() => {
		getEducationEmployeeProfile();
	}, []);
	return (
		<>
			{token ? (
				isLoader ?(
					<Loader/>
				):(
				<Wrapper>
					<ProfileWrapper>
						<ProfileSections>
							<SectionTitle>Regular Employee</SectionTitle>
							<Profile>
								<ProfileDetailsWrapper>
									<ProfileImageWrapper>
									<img src={educationEmployeeData?.profilePicture || ProfilePicture} alt="profile" />
										<img src={editable} className="imgedit" alt="profile" />
									</ProfileImageWrapper>
									<UserDetailsWrapper>
										<UserNameWrapper>
											<UserName>{educationEmployeeData?.fullName || "Anonymous User"}</UserName>
											<img
												src={editablenoborder}
												className="imgedit"
												alt="profile"
												style={{
													display: "block",
												}}
											/>
										</UserNameWrapper>
										<UserAddress>
											<img src={locationMark} alt="location-icon" />
											{educationEmployeeData?.location || "No Location Available"}
										</UserAddress>
										<UserWorkLocationAndSkills>
											<span>{educationEmployeeData?.jobTitle || "No Data Available"}</span>
											<span>{educationEmployeeData?.companyName || "No Data Available"}</span>
											<span>{educationEmployeeData?.industrySector || "No Data Available"}</span>
										</UserWorkLocationAndSkills>
										{/* To be visible on mobile only */}
										<UserProfileActions
											hideOnDesktop
											style={{
												marginBlockEnd: "14px",
											}}
										>
											<Button
												buttonClick={handlePublicView}
												styles={{
													width: "fit-content",
													backgroundColor: "#FCFDFF",
													color: "#6B6B6B",
													borderColor: "#C2C2C2",
												}}
											>
												See Public View
											</Button>
										</UserProfileActions>
									</UserDetailsWrapper>
								</ProfileDetailsWrapper>
								{/* To be visible on desktop only */}
								<UserProfileActions
									hideOnMobile
									style={{
										marginBlockEnd: "14px",
									}}
								>
									<Button
										buttonClick={handlePublicView}
										styles={{
											width: "fit-content",
											backgroundColor: "#FCFDFF",
											marginLeft: "auto",
											color: "#6B6B6B",
											borderColor: "#C2C2C2",
										}}
									>
										See Public View
									</Button>
								</UserProfileActions>
							</Profile>

							<MoreDetailWrapper>
								<ProfilesAndAboutWrapper>
									<ProfilesWrapper>
										<Profiles>
											<ProfileHeading>
												Other Profiles
												<ManageOtherProfile>Manage</ManageOtherProfile>
											</ProfileHeading>
											<ProfileList>
											{educationEmployeeData?.userName ? (
													<ProfileName>
														<ProfileUserName>Username</ProfileUserName>
														{educationEmployeeData?.userName}
													</ProfileName>
												) : (
													"No Data available"
												)}
												{educationEmployeeData?.shortVideoUsername && (
													<ProfileName>
														<ProfileUserName>shortVideoUsername</ProfileUserName>
														{educationEmployeeData?.shortVideoUsername}
													</ProfileName>
												)}
											</ProfileList>
										</Profiles>
									</ProfilesWrapper>
									<About>
										<AboutTitle>
											About <img src={editablenoborder} alt="" />
										</AboutTitle>
										<AboutDesc>
											{educationEmployeeData?.about}
										</AboutDesc>
										<More>more...</More>
										<EducationHistory>
											<EducationTitle>
												Education History <img src={editablenoborder} alt="" />
											</EducationTitle>
											<Education>
												<span>{educationEmployeeData?.educationDegree}</span>
												<span>{educationEmployeeData?.educationInstitution}</span>
												<span>{educationEmployeeData?.gradYear}</span>
											</Education>
										</EducationHistory>
										<SkillsWrapper>
											<SkillsTitle>
												Skills <img src={editablenoborder} alt="" />
											</SkillsTitle>
											<Skills>{educationEmployeeData?.skill}</Skills>
										</SkillsWrapper>
									</About>
								</ProfilesAndAboutWrapper>
								<FeedbackWrapper>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment1}
											commentimagestyle={{
												marginLeft: "2rem",
											}}
											commenttitle="Thanks"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment}
											commenttitle="Reply"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
								</FeedbackWrapper>
							</MoreDetailWrapper>
						</ProfileSections>
					</ProfileWrapper>
				</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default EmployeePrivate;
