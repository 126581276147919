import styled from "@emotion/styled";

const GPTResponse = styled.p`
	color: #797979;

	/* body-text-sm */
	&,
	& * {
		font-family: Inter;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}

	height: 80px;
	overflow-y: auto;

	& span {
		color: #2d2d2d;
		font-weight: 500;
	}
`;

export { GPTResponse };
