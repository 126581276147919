import React, { useState, useEffect } from "react";
import LeftSubNav from "../../../components/LeftSubNav";
import RightSubNav from "../../../components/RightSubNav";
import FeedList from "../../../components/Shorts/Feed";
import { MobileWrapper, Wrapper, LeftSection, CenterContent, RightSection } from "../index.styled";
import Profile1 from "../../../assets/images/Educationprofile2.png";
import Profile2 from "../../../assets/images/Educationprofile.png";
import Preview1 from "../../../assets/images/shorts/Preview1.png";
import Preview2 from "../../../assets/images/shorts/Preview2.png";
import useLayout from "../../../utils/hooks/useLayout";
import Smiley from "../../../assets/icons/shorts/Smile.svg";
import Chat from "../../../assets/icons/shorts/Chat.svg";
import Loader from "../../../components/loader/index.js";
const feedData = [
	{
		id: 1,
		name: "George Kajaia",
		profile: Profile1,
		preview: Preview1,
		analytics: [
			{
				name: "reaction",
				stat: 2456,
				icon: Smiley,
			},
			{
				name: "comments",
				stat: 456,
				icon: Chat,
			},
		],
	},
	{
		id: 2,
		name: "Michelle",
		profile: Profile2,
		preview: Preview2,
		analytics: [
			{
				name: "reaction",
				stat: 12326,
				icon: Smiley,
			},
			{
				name: "comments",
				stat: 8745,
				icon: Chat,
			},
		],
	},
];

const AllFeed = () => {
	const isMobile = useLayout();
	const [isLoading, setIsLoading] = useState(true);

	useEffect(() => {
	  const fetchData = async () => {
		await new Promise((resolve) => setTimeout(resolve, 2000));
		setIsLoading(false);
	  };

	  fetchData();
	}, []);

	return isLoading ? (
		<Loader />
	) : isMobile ? (
		<MobileWrapper>
			<FeedList feed={feedData} isMobile={isMobile} />
		</MobileWrapper>
	) : (
		<Wrapper>
			<LeftSection>
				<LeftSubNav />
			</LeftSection>
			<CenterContent>
				<FeedList feed={feedData} />
			</CenterContent>
			<RightSection>
				<RightSubNav />
			</RightSection>
		</Wrapper>
	);
};

export default AllFeed;
