import { Box } from "@mui/material";
import Page from "../../../layouts/Page";

import ProfileInfo from "../../../components/Dating/Profile";

const Profile = () => {
	return (
		<Page
			overrides={{
				"@media (max-width: 768px)": {
					paddingBottom: 0,
				},
			}}
		>
			<Box gridColumn={"3 / span 8"}>
				<ProfileInfo />
			</Box>
		</Page>
	);
};

export default Profile;
