import { useState } from "react";
import styled from "@emotion/styled";
import { FormTitle, FormWrapper, BtnWrapper, btnStyleOverrides } from "../index.styled";
import { borderRadius, color, spacing } from "../../../../../styles/variables";
import PrimaryBtn from "../../../../Buttons/Primary";
import SecondaryBtn from "../../../../Buttons/Secondary";
import { useNavigate } from "react-router-dom";
import Client from "../../../../../assets/icons/education/Client";
import Freelancer from "../../../../../assets/icons/education/Freelancer";
import Employee from "../../../../../assets/icons/education/Employee";
import Company from "../../../../../assets/icons/education/Company";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
`;

const CardWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-column-gap: ${spacing("md3")};
	margin-block: ${spacing("xxl")};

	& > label {
		display: flex;
		height: 100%;

		color: ${color("pBlack")};
		font-family: Poppins;
		font-size: 19px;
		font-style: normal;
		font-weight: 400;
		line-height: 30px; /* 157.895% */
	}

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		grid-row-gap: ${spacing("xl2")};
	}
`;

const Card = styled.div`
	cursor: pointer;
	grid-column: span 1;
	border: 1px solid #ccc;
	position: relative;
	border-radius: 6px;
	border: 1px solid #aba6a6;
	background: ${color("pWhite")};
	padding: ${spacing("md3")} ${spacing("lg")};

	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: ${spacing("md1")};
`;

const RadioButton = styled.input`
	margin: 0;
	position: absolute;
	top: 20px;
	right: 20px;
	transform: translateY(50%);
`;

const cards = [
	{
		id: 1,
		icon: Client,
		desc: "I'm a client in the creative sector, seeking professional expertise in art and design.",
		link: "/creativity/client/signup",
	},
	{
		id: 2,
		icon: Freelancer,
		desc: "I'm a freelancer specializing in the creative arts,",
		link: "/creativity/freelancer/signup",
	},
	{
		id: 3,
		icon: Employee,
		desc: "I'm a regular employee in the creative sector, keen to connect with peers in art and design.",
		link: "/creativity/employee/signup",
	},
	{
		id: 4,
		icon: Company,
		desc: "We are a company immersed in the creative industry, offering a spectrum of art and design services.",
		link: "/creativity/company/signup",
	},
];

const CardComponent = ({ selectedCard, setSelectedCard }) => {
	const handleRadioChange = (value) => {
		setSelectedCard(value);
	};

	return (
		<CardWrapper>
			{cards?.map((card) => (
				<label key={card?.id}>
					<Card>
						<RadioButton
							type="radio"
							name="card"
							value={card?.id}
							checked={selectedCard?.id === card?.id}
							onChange={() => handleRadioChange(card)}
						/>
						{card?.icon && <card.icon />}
						{card?.desc}
					</Card>
				</label>
			))}
		</CardWrapper>
	);
};

const InitialSignupForm = () => {
	const [selectedCard, setSelectedCard] = useState(null);
	const navigate = useNavigate();
	const handleSubmit = (event) => {
		event.preventDefault();
		navigate(selectedCard?.link);
	};
	return (
		<Wrapper>
			<FormWrapper initialForm onSubmit={handleSubmit}>
				<FormTitle
					style={{
						borderRadius: borderRadius(6),
					}}
				>
					<strong>Creativity: </strong>Join as a Client , freelancer , Regular Employee or a company
				</FormTitle>
				<CardComponent selectedCard={selectedCard} setSelectedCard={setSelectedCard} />
				<BtnWrapper>
					<SecondaryBtn label={"Back"} onClick={() => navigate(-1)} overrides={btnStyleOverrides} />
					<PrimaryBtn label={"Continue"} overrides={btnStyleOverrides} />
				</BtnWrapper>
			</FormWrapper>
		</Wrapper>
	);
};

export default InitialSignupForm;
