import styled from "@emotion/styled";
import { Fragment, useEffect, useState } from "react";
import { borderRadius, color, spacing } from "../../../../styles/variables";
import ProfilePicture from "../../../../assets/images/MB/dp.png";
import ListView from "../../../../assets/icons/shorts/mobile/ListView";
import Favorites from "@mui/icons-material/FavoriteBorderRounded";
import Profileicon from "../../../../assets/icons/user_grey.png";
import {
	ResultWrapper,
	Result,
	ProfileWrapper,
	UserImage,
	UserDetailsWrapper,
	UserDetails,
	UserName,
	StatsWrapper,
	Stat,
	ViewIcons,
	EditIcon,
} from "../../../../pages/Shorts/index.styled";
import Preview1 from "../../../../assets/images/shorts/Preview1.png";
import Preview2 from "../../../../assets/images/shorts/Preview2.png";
import PrimaryBtn from "../../../../components/Buttons/Primary";
import Edit from "../../../../assets/icons/Edit";
import { useNavigate } from "react-router-dom";
import { GetShortVideosPrivateProfile } from "../../../../utils/apiCalls";
import Loader from "../../../../components/loader/index";

const ImageWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	position: relative;
	width: max-content;
	margin: 0 auto;
	margin-block-start: ${spacing("md2")};
`;

const Profile = () => {
	const navigate = useNavigate();
	const data = localStorage?.getItem("shortVideoData");
	const parsedData = JSON.parse(data);
	const [profileData, setProfileData] = useState([]);
	const [isLoader, setIsLoader] = useState(false);

	const getPrivateProfile = () => {
		setIsLoader(true);
		if (parsedData) {
			const payload = {
				shortVideoId: parsedData?.shortVideoId,
			};
			GetShortVideosPrivateProfile((res) => {
				const { status, data, message } = res;
				if (status === 200) {
					setIsLoader(false);
					setProfileData(data);
				} else {
					setIsLoader(false);
					setProfileData(data);
				}
			}, payload);
		}
	};

	useEffect(() => {
		getPrivateProfile();
	}, [data]);

	const handleprofile = ()=>{
		navigate("/shorts/profiles")
	};

	if (isLoader) {
		return <Loader />;
	}
	
	return (
		<Fragment>
			<ProfileWrapper>
				<ImageWrapper>
					<UserImage src={profileData?.profilePicture || ProfilePicture} alt="profile" />
					<EditIcon>
						<Edit />
					</EditIcon>
				</ImageWrapper>
				<UserDetailsWrapper>
					<UserDetails>
						<UserName><img src={Profileicon} alt="icon" onClick={handleprofile}/>
						{profileData?.shortVideoUsername || "Anonymous User"}
						</UserName>
						<StatsWrapper>
							<Stat>
								448
								<span>Following</span>
							</Stat>
							<Stat>
								744
								<span>Followers</span>
							</Stat>
						</StatsWrapper>
					</UserDetails>
					<PrimaryBtn
						label={"Edit Profile"}
						overrides={{
							textTransform: "none",
							borderRadius: borderRadius(60),
							paddingInline: spacing("xl"),
							paddingBlock: spacing("xs"),
						}}
					/>
				</UserDetailsWrapper>
			</ProfileWrapper>
			<ViewIcons>
				<ListView />
				<Favorites />
			</ViewIcons>
			<ResultWrapper
				style={{
					marginInline: 0,
					paddingBlockEnd: 0,
				}}
			>
				<Result src={Preview1} alt="preview" />
				<Result src={Preview2} alt="preview" />
				<Result src={Preview2} alt="preview" />
				<Result src={Preview1} alt="preview" />
				<Result src={Preview1} alt="preview" />
				<Result src={Preview2} alt="preview" />
				<Result src={Preview2} alt="preview" />
				<Result src={Preview1} alt="preview" />
				<Result src={Preview1} alt="preview" />
				<Result src={Preview2} alt="preview" />
			</ResultWrapper>
		</Fragment>
	);
};

export default Profile;
