import styled from "@emotion/styled";

const ContactName = styled.h5`
	cursor: pointer;
	color: #797979;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Profile = styled.img`
	cursor: pointer;
	border-radius: 50%;
	height: 56px;
	width: 56px;
`;

const Events = styled.div`
	color: #797979;

	/* body-text-lg */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	padding-bottom: 10px;
	border-bottom: 1px solid #d9d9d9;
	margin-top: 12px;
`;

export { ContactName, Profile, Events };
