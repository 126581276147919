import styled from "@emotion/styled";

const Title = styled.h2`
	color: #676464;

	/* font-weight-bold-xxxl */
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const Author = styled.span`
	color: #676464;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Thumbnail = styled.img`
	max-width: 100%;

	@media (max-width: 768px) {
		margin-top: 12px;
	}
`;

export { Title, Author, Thumbnail };
