const Index = ({ color = "#61BCAC", size = 52 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 52 52" fill="none">
			<path
				d="M2 44C2 40.8174 3.26428 37.7652 5.51472 35.5147C7.76515 33.2643 10.8174 32 14 32H38C41.1826 32 44.2348 33.2643 46.4853 35.5147C48.7357 37.7652 50 40.8174 50 44C50 45.5913 49.3679 47.1174 48.2426 48.2426C47.1174 49.3679 45.5913 50 44 50H8C6.4087 50 4.88258 49.3679 3.75736 48.2426C2.63214 47.1174 2 45.5913 2 44Z"
				stroke={color}
				strokeWidth="3"
				strokeLinejoin="round"
			/>
			<path
				d="M26 20C30.9706 20 35 15.9706 35 11C35 6.02944 30.9706 2 26 2C21.0294 2 17 6.02944 17 11C17 15.9706 21.0294 20 26 20Z"
				stroke={color}
				strokeWidth="3"
			/>
		</svg>
	);
};

export default Index;
