import { useState } from "react";
import styled from "@emotion/styled";
import Modal from "../Modal";
import Input from "../Inputs/Input";
import PrimaryBtn from "../Buttons/Primary";
import { UserForgotPassword } from "../../utils/apiCalls";

const ForgotPassword = ({ open = false, onClose }) => {
	const [formValues, setFormValues] = useState({});
	const [emailError, setEmailError] = useState('');
	const [sentPasswordReset, setSentPasswordReset] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleChange = (event) => {
		let { name, value } = event.target;
		setFormValues((prev) => ({ ...prev, [name]: value }));
		if (name === 'email') setEmailError('');
	};

	const validateEmail = (email) => {
		const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		return regex.test(email);
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		if (!validateEmail(formValues.email)) {
			setEmailError('Please enter a valid email address.');
			return;
		}
		if (sentPasswordReset) {
			onClose();
			setSentPasswordReset(false);
			return;
		}
		setIsLoading(true);
		const payload = {
			email: formValues.email,
		};
		UserForgotPassword((res) => {
			const { status, message } = res;
			if (status === 200) {
				setSentPasswordReset(true);
				setIsLoading(false);
			} else {
				setSentPasswordReset(true);
				setIsLoading(false);
				console.log(message);
			}
		}, payload);
	};

	return sentPasswordReset ? (
		<Modal open={open} onClose={onClose} noIcon={true} fullWidth={true}>
			<Wrapper
				onSubmit={handleSubmit}
				style={{
					marginBlock: "36px",
					marginInline: "18px",
				}}
			>
				<InfoDesc>Reset password link was sent to your email address</InfoDesc>
				<PrimaryBtn
					loading={isLoading}
					label={"Ok"}
					overrides={{
						fontSize: "16px",
						borderRadius: "40px",
						paddingBlock: "10px",
						width: "fit-content",
						minWidth: "25%",
						marginInline: "auto",
						'@media (max-width: 768px)': {
							minWidth: "100%", 
						  },
					}}
				/>
			</Wrapper>
		</Modal>
	) : (
		<Modal open={open} onClose={onClose} title={"Forgot Password"}>
			<Wrapper onSubmit={handleSubmit} onChange={handleChange}>
				<InfoDesc>Enter the email address you used to sign up to get your password reset link.</InfoDesc>
				<Input name={"email"} placeholder={"Enter your email address"} type="email" required />
				{emailError && <Error>{emailError}</Error>}
				<PrimaryBtn
					loading={isLoading}
					label={"Request Reset Link"}
					overrides={{
						fontSize: "16px",
						borderRadius: "40px",
						paddingBlock: "8px",
					}}
				/>
				<Login>
					Back to <span onClick={onClose}>login</span>
				</Login>
			</Wrapper>
		</Modal>
	);
};
const Error = styled.div`
	color: #D8000C;
	font-family: Poppins;
	font-size: 14px;
	text-align: center;
`;
const Wrapper = styled.form`
	display: flex;
	flex-direction: column;

	& > div {
		margin-block-end: 12px;
	}
`;

const InfoDesc = styled.div`
	color: #000;
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 128%; /* 20.48px */

	text-align: "center";

	margin-block-end: 12px;
`;

const Login = styled.div`
	&,
	& > span {
		color: #000;
		font-family: Poppins;
		font-size: 14px;
		font-style: normal;
		font-weight: 400;
		line-height: 157%; /* 28.26px */
		margin-inline: auto;
		margin-block: 18px;
	}
	& > span {
		cursor: pointer;
		color: #61bcac;
		text-decoration-line: underline;
	}
`;
 
export default ForgotPassword;
