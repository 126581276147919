import styled from "@emotion/styled";
import { borderRadius, color, spacing } from "../../../styles/variables";

const Wrapper = styled.div`
	max-width: inherit;

	grid-column: span 12;

	display: grid;
	grid-template-columns: repeat(12, 1fr);
	grid-row-gap: 16px;

	@media screen and (max-width: 768px) {
		grid-row-gap: 32px;
	}
`;

const SubWrapper = styled.div`
	grid-column: span 12;
`;

const SectionWrapper = styled.div`
	&:not(:last-child) {
		margin-block-end: ${spacing("lg")};
	}
`;
const SectionTitle = styled.h3`
	color: ${color("pGreen")};

	/* heading-text-lg */
	font-family: Poppins;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	margin-block-end: ${spacing("sm")};

	@media screen and (max-width: 768px) {
		margin-block-end: ${spacing("xs")};
	}
`;

const CardWrapper = styled.div`
	width: 100%;
	border-radius: ${borderRadius(6)};
	background: ${color("pWhite")};
	box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.25);

	margin-block: ${spacing("sm2")};
	padding-inline: ${spacing("md")};
	padding-block: ${spacing("md")};

	display: flex !important;
	flex-direction: column;
	row-gap: ${spacing("xl1")};
`;

const Info = styled.span`
	color: #000;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Button = styled.button`
	cursor: pointer;
	width: 100%;
	border-radius: ${borderRadius(8)};
	border: 1px solid ${color("pBlack")};
	padding-block: ${spacing("sm")};
	text-align: center;
	background-color: #fff;

	color: ${color("pBlack")};

	/* button text black */
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	transition: 300ms all;

	margin-block-start: auto;

	&:hover {
		color: ${color("pWhite")};
		background-color: ${color("pGreen")};
	}
`;

export { Wrapper, SubWrapper, SectionWrapper, SectionTitle, CardWrapper, Info, Button };
