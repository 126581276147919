const initialState = {
	// initial user state
	mb: { data: null, isLoading: false, error: null },
};

const mbReducer = (state = initialState, action) => {
	// reducer logic based on different action types
	switch (action.type) {
		case "SET_ACTIVE_MB_MODULE":
			state = {
				...state,
				active: action.payload,
			};
			break;
		case "SET_ACTIVE_MB_MODULE_PROFILE":
			state = {
				...state,
				activeProfile: action.payload,
			};
			break;
		case "MB_SET_ACTIVE_PROFILE":
			state = {
				...state,
				activeProfileId: action.payload || undefined,
			};
			break;
	}
	return state;
};

export default mbReducer;
