const Index = ({ color = "white", size = 103, fillOpacity = 0.3 }) => {
	return (
		<svg width={size} viewBox="0 0 173 232" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M166.628 104.533C174.586 110.107 174.586 121.893 166.628 127.467L22.0315 228.741C12.7529 235.24 -1.13913e-05 228.602 -1.08961e-05 217.274L-2.04246e-06 14.726C-1.5473e-06 3.39802 12.7529 -3.2397 22.0315 3.25892L166.628 104.533Z"
				fill={color}
				fillOpacity={fillOpacity}
			/>
		</svg>
	);
};

export default Index;
