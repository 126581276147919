import styled from "@emotion/styled";

const Nav = styled.div`
	text-transform: capitalize;
	cursor: pointer;

	color: #767575;

	/* lineheight */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 36px;

	${(props) =>
		props.active &&
		`
		color: #61BCAC;
	`}
`;

export { Nav };
