import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import NotificationBell from "../../assets/icons/NotificationBell";
import { spacing } from "../../styles/variables";
import "./index.scss";
import { io } from "socket.io-client";
import { UserContext } from "../../store/UserDetailsStore";
import { GetUserNotifications } from "../../utils/apiCalls";
import defaultProfile from "../../assets/icons/user-profile.png";
import { Badge } from "@mui/material";
import { CircularProgress } from "@mui/material";
import { color } from "../../styles/variables";

const ITEM_HEIGHT = 48;

const ChatEventEnum = Object.freeze({
	CONNECTED_EVENT: "connected",
	DISCONNECT_EVENT: "disconnect",
	JOIN_CHAT_EVENT: "joinChat",
	LEAVE_CHAT_EVENT: "leaveChat",
	UPDATE_GROUP_NAME_EVENT: "updateGroupName",
	MESSAGE_RECEIVED_EVENT: "messageReceived",
	NEW_CHAT_EVENT: "newChat",
	SOCKET_ERROR_EVENT: "socketError",
	STOP_TYPING_EVENT: "stopTyping",
	TYPING_EVENT: "typing",
	ADD_NOTIFICATION_EVENT: "addNotification",
	REMOVE_NOTIFICATION_EVENT: "removeNotification",
});

const Notifications = ({ notified, hasNew, isActive }) => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [notification, setNotification] = useState([]);
	const [socket, setSocket] = useState(null);
	const { isLoginUserDetails } = useContext(UserContext);
	const [isLoader, setIsLoader] = useState(false);
	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setNotification(notified);
	}, [notified]);

	useEffect(() => {
		const newSocket = io("https://api-messaging.buzzgalactic.com", {
			withCredentials: true,
			extraHeaders: {
				Authorization: `Bearer ${token}`,
			},
		});

		newSocket.on("connect", () => {
			console.log("Connected to socket server");
		});

		newSocket.on(ChatEventEnum.MESSAGE_RECEIVED_EVENT, (message) => {});

		newSocket.on(ChatEventEnum.ADD_NOTIFICATION_EVENT, (notification) => {
			console.log(notification, "notification");
			setNotification((prevNotifications) => [...prevNotifications, notification]);
			getAllNotificationData();
		});

		newSocket.on(ChatEventEnum.REMOVE_NOTIFICATION_EVENT, (notificationToRemove) => {
			setNotification((prevNotifications) =>
				prevNotifications.filter((n) => n.notificationId !== notificationToRemove.notificationId),
			);
		});

		setSocket(newSocket);

		return () => {
			newSocket.off(ChatEventEnum.ADD_NOTIFICATION_EVENT);
			newSocket.off(ChatEventEnum.REMOVE_NOTIFICATION_EVENT);
			newSocket.disconnect();
		};
	}, []);

	useEffect(() => {
		if (socket && isLoginUserDetails?.userId) {
			socket.emit(ChatEventEnum.JOIN_CHAT_EVENT, isLoginUserDetails?.userId);
		}
	}, [socket, isLoginUserDetails]);

	const getAllNotificationData = () => {
		setIsLoader(true);
		GetUserNotifications((res) => {
			const { status, data, message } = res;
			if (status === 200) {
				setNotification(res.data.notifications);
				setIsLoader(false);
			} else {
				setNotification([]);
				setIsLoader(false);
			}
		});
	};

	const handleNotificationClick = (notification) => {
		if (!notification.isFriend) {
			navigate("/privatefriendrequest");
		} else if (notification.isReview) {
			navigate("/privateprofile");
		}
	};

	return (
		<div>
			<IconButton
				aria-label="more"
				id="long-button"
				aria-controls={open ? "long-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup="true"
				onClick={handleClick}
				sx={{
					padding: 0,
				}}
			>
				{hasNew && <span className="red-dot"></span>}
				<Badge color={"error"} variant={"dot"} invisible={!hasNew}>
					{/* {isActive ? <NotificationBellActive /> :  */}
					<NotificationBell />
					{/* } */}
				</Badge>
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					"aria-labelledby": "long-button",
					style: {
						padding: 0,
					},
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
				sx={{
					".MuiPaper-root": {
						minHeight: ITEM_HEIGHT * 2,
						maxHeight: ITEM_HEIGHT * 8,
						width: "100dvw",
						maxWidth: "100dvw",
						left: "0 !important",
						marginBlockStart: spacing("md"),
						position: "relative",
					},
				}}
			>
				<div className="notifications_dropdown">
					<div className="notifications_dropdown_container">
						<h5>Notifications</h5>
						<div>
							{notification.length === 0 ? (
								<div className="notifications_dropdown_loader">
									{isLoader ? (
										<CircularProgress
											sx={{
												color: color("pGreen"),
												marginInline: "auto",
											}}
											size={25}
										/>
									) : (
										<p>Notifications are not available</p>
									)}
								</div>
							) : (
								notification?.map((notification, index) => (
									<div
										className="notifications_dropdown_section"
										key={index}
										onClick={() => handleNotificationClick(notification)}
									>
										<h6>{"Main"}</h6>
										<div className="notifications_dropdown_section_content">
											<div className="notifications_dropdown_section_content_left">
												{notification?.senderDetails?.profilePicture === null ? (
													<img src={defaultProfile} alt="icon" />
												) : (
													<img src={notification?.senderDetails?.profilePicture} alt="icon" />
												)}
											</div>
											<div className="notifications_dropdown_section_content_right">
												<p className="">{notification?.content}</p>
												<p className="date">{notification?.createdAt?.split("T")[0]}</p>
											</div>
										</div>
									</div>
								))
							)}
						</div>
					</div>
				</div>
			</Menu>
		</div>
	);
};

export default Notifications;
