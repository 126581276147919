import Header from "../../components/Header";
import styled from "@emotion/styled";
import Background from "../../assets/images/login/background.svg";
import MobileBackground from "../../assets/images/verify/mobile/background.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { UserVerifyEmail } from "../../utils/apiCalls";

const VerifyEmail = () => {
	const navigate = useNavigate();
	const searchParams = new URLSearchParams(window.location.search);
	const tokenFromUrl = searchParams.get("token");

	const handleNavigate = () => {
		navigate("/login");
	};

	useEffect(() => {
		if (tokenFromUrl) {
			const payload = {
				token: tokenFromUrl,
			};
			UserVerifyEmail((res) => {
				const { status } = res;
				if (status === 200) {
				}
			}, payload);
		}
	}, [tokenFromUrl]);

	return (
		<Wrapper>
			<Header hideButton />
			<SubWrapper>
				<Title>Email verified!</Title>
				<Description>
					Your email is verified <br />
					Please <span onClick={handleNavigate}>login</span> to continue
				</Description>
			</SubWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100dvh;

	background-image: url(${Background});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (max-width: 768px) {
		background-image: url(${MobileBackground});
	}
`;

const SubWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	/* height: 100%; */
	background-color: red;
	width: 40dvw;
	margin: auto;

	background: #fff;
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);

	padding: 20px;

	@media screen and (max-width: 768px) {
		width: initial;
		margin-inline: 24px;
	}
`;

const Title = styled.h1`
	color: #000;
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 400;
	line-height: 128%; /* 46.08px */

	padding-block-end: 12px;
	text-align: center;
	width: 100%;

	border-bottom: 1px solid #dedede;

	@media screen and (max-width: 768px) {
		font-size: 28px;
	}
`;

const Description = styled.p`
	width: 80%;
	margin-block-start: 16px;

	&,
	& > span {
		color: #000;
		text-align: center;
		font-family: Poppins;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: 148%; /* 23.68px */
	}

	& > span {
		color: #256f61;
		cursor: pointer;
		text-decoration: underline;
	}
`;

export default VerifyEmail;
