const InnovatorsAndEntrepreneurs = () => (
	<svg xmlns="http://www.w3.org/2000/svg" width="92" height="92" viewBox="0 0 92 92" fill="none">
		<circle cx="46" cy="46" r="45" stroke="#9E6892" strokeWidth="2" />
		<path
			d="M57.6727 27.0001C57.6727 23.5891 56.2137 20.3291 53.6707 18.0531C51.1297 15.7801 47.7117 14.6971 44.2957 15.0771C39.0307 15.6651 34.6257 19.8791 33.8207 25.0981C33.3017 28.4601 34.1587 31.7651 36.2357 34.4031C38.4837 37.2591 39.6727 40.4901 39.6727 43.7461V44.0001C39.6727 44.5521 40.1207 45.0001 40.6727 45.0001H50.6727C51.2247 45.0001 51.6727 44.5521 51.6727 44.0001V43.7431C51.6727 40.3311 52.7887 37.1421 54.8157 34.7631C56.6577 32.5991 57.6727 29.8421 57.6727 27.0001ZM49.6887 43.0001H41.6547C41.4877 39.5481 40.1657 36.1631 37.8057 33.1651C36.0767 30.9681 35.3627 28.2111 35.7957 25.4021C36.4657 21.0611 40.1327 17.5541 44.5157 17.0641C47.4117 16.7431 50.1867 17.6211 52.3357 19.5431C54.4567 21.4401 55.6727 24.1581 55.6727 27.0001C55.6727 29.3681 54.8277 31.6641 53.2937 33.4651C51.1077 36.0311 49.8417 39.3931 49.6887 43.0001Z"
			fill="#9E6892"
		/>
		<path d="M49.6729 46H41.6729V48H49.6729V46Z" fill="#9E6892" />
		<path d="M46.6729 10H44.6729V13H46.6729V10Z" fill="#9E6892" />
		<path d="M62.6729 26H59.6729V28H62.6729V26Z" fill="#9E6892" />
		<path d="M31.6729 26H28.6729V28H31.6729V26Z" fill="#9E6892" />
		<path d="M56.2785 36.1925L54.8643 37.6067L56.9856 39.728L58.3998 38.3138L56.2785 36.1925Z" fill="#9E6892" />
		<path d="M34.3595 14.2721L32.9453 15.6863L35.0666 17.8076L36.4808 16.3934L34.3595 14.2721Z" fill="#9E6892" />
		<path d="M56.9865 14.2726L54.8652 16.3939L56.2794 17.8081L58.4007 15.6868L56.9865 14.2726Z" fill="#9E6892" />
		<path d="M35.0656 36.1921L32.9443 38.3134L34.3585 39.7276L36.4798 37.6063L35.0656 36.1921Z" fill="#9E6892" />
		<path
			d="M45.0729 24H46.2729C47.0318 24 47.6729 24.458 47.6729 25H49.6729C49.6729 23.467 48.3569 22.213 46.6729 22.036V20H44.6729V22.036C42.9889 22.213 41.6729 23.467 41.6729 25V26C41.6729 27.654 43.1979 29 45.0729 29H46.2729C47.0318 29 47.6729 29.458 47.6729 30V31C47.6729 31.542 47.0318 32 46.2729 32H45.0729C44.3139 32 43.6729 31.542 43.6729 31H41.6729C41.6729 32.533 42.9889 33.787 44.6729 33.964V36H46.6729V33.964C48.3569 33.787 49.6729 32.533 49.6729 31V30C49.6729 28.346 48.1479 27 46.2729 27H45.0729C44.3139 27 43.6729 26.542 43.6729 26V25C43.6729 24.458 44.3139 24 45.0729 24Z"
			fill="#9E6892"
		/>
		<path
			d="M50.1309 57.242C50.6649 56.201 50.8179 54.997 50.5269 53.787C50.0909 51.975 48.6398 50.54 46.8288 50.13C45.3068 49.784 43.7489 50.136 42.5539 51.091C41.3589 52.047 40.6729 53.471 40.6729 55C40.6729 55.787 40.8679 56.551 41.2209 57.24C39.1749 57.867 37.6729 59.751 37.6729 62V70H39.6729V62C39.6729 60.396 40.9379 59.083 42.5219 59.004L44.3499 59.613L42.7439 63.628C42.6489 63.867 42.6489 64.132 42.7439 64.371L44.7439 69.371C44.8969 69.751 45.2639 70 45.6729 70C46.0819 70 46.4489 69.751 46.6019 69.372L48.6019 64.372C48.6969 64.133 48.6969 63.868 48.6019 63.629L46.9959 59.614L48.8239 59.005C50.4079 59.083 51.6729 60.396 51.6729 62V70H53.6729V62C53.6729 59.754 52.1739 57.871 50.1309 57.242ZM43.8019 52.655C44.5309 52.072 45.4469 51.868 46.3869 52.081C47.4449 52.321 48.3259 53.194 48.5819 54.255C48.8539 55.386 48.4919 56.511 47.6119 57.265L47.6349 57.291L45.6729 57.946L43.7099 57.292L43.7329 57.266C43.0589 56.688 42.6729 55.862 42.6729 55C42.6729 54.083 43.0849 53.228 43.8019 52.655ZM45.6729 66.308L44.7499 64L45.6729 61.692L46.5959 64L45.6729 66.308Z"
			fill="#9E6892"
		/>
		<path
			d="M18.346 67.7401L28.768 58.2651L34.119 61.8321L35.228 60.1681L29.228 56.1681C28.846 55.9131 28.341 55.9511 28 56.2601L17 66.2601L18.346 67.7401Z"
			fill="#9E6892"
		/>
		<path
			d="M74.6733 43H69.6733V45H72.2593L63.6733 53.586L60.3803 50.293C60.1813 50.094 59.9083 49.994 59.6283 50.001C59.3473 50.014 59.0853 50.144 58.9053 50.36L53.9053 56.36L55.4413 57.64L59.7403 52.481L62.9663 55.707C63.3573 56.098 63.9893 56.098 64.3803 55.707L73.6733 46.414V49H75.6733V44C75.6733 43.448 75.2253 43 74.6733 43Z"
			fill="#9E6892"
		/>
		<path d="M72.6729 55H70.6729V70H72.6729V55Z" fill="#9E6892" />
		<path d="M66.6729 58H64.6729V70H66.6729V58Z" fill="#9E6892" />
		<path d="M60.6729 56H58.6729V66H60.6729V56Z" fill="#9E6892" />
		<path d="M32.6729 63H30.6729V70H32.6729V63Z" fill="#9E6892" />
		<path d="M26.6729 64H24.6729V70H26.6729V64Z" fill="#9E6892" />
		<path d="M60.6729 68H58.6729V70H60.6729V68Z" fill="#9E6892" />
		<path d="M72.6729 51H70.6729V53H72.6729V51Z" fill="#9E6892" />
	</svg>
);

export default InnovatorsAndEntrepreneurs;
