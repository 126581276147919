import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { color, spacing } from "../../styles/variables";
const LeftList = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("md4")};

	position: sticky;
	top: 28px;

	button {
		width: max-content;
		padding-inline: 36px;
	}

	@media screen and (max-width: 768px) {
		display: none;
	}
`;

const CustomNavLinkLeft = styled(NavLink)`
	display: flex;
	align-items: center;
	column-gap: ${spacing("md")};
	img {
		width: 16px;
		aspect-ratio: 1;
	}
	span {
		color: #797979;
		font-family: Inter;
		font-size: 16px;
		font-style: normal;
		font-weight: 400;
		line-height: normal;
	}
	${(props) =>
		props.active === "true" &&
		`span {
		color: ${color("pGreen")};
		font-weight: 700;
	}`};
`;

const SpanHead = styled.span`
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
	color: #797979;

	@media screen and (max-width: 768px) {
		/* color: #696a68; */
	}
`;

export { LeftList, SpanHead, CustomNavLinkLeft };
