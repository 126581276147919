import { Fragment } from "react";
import InputField from "../../../../Inputs/Input";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";
import Textarea from "../../../../Inputs/Textarea";
import {
	FormContainer,
	FormTitleInfo,
	FormRowWrapper,
	BtnWrapper,
	btnStyleOverrides,
	FormWrapper,
	FormTitle,
	Form,
	Wrapper,
} from "../index.styled";
import { useFormik } from "formik";
import PrimaryBtn from "../../../../Buttons/Primary";
import SecondaryBtn from "../../../../Buttons/Secondary";
import * as Yup from "yup";

const validationSchema = Yup.object({
	firstName: Yup.string().required("First Name is required"),
	lastName: Yup.string().required("Last Name is required"),
	location: Yup.string().required("Location is required"),
});

const FreelancerSignUp = ({ setProfilePicture, handleSubmit, handleBack }) => {
	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			location: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			handleSubmit(values);
		},
	});
	return (
		<Wrapper>
			<FormWrapper onSubmit={formik.handleSubmit} onBlur={formik.handleBlur} onChange={formik.handleChange}>
				<FormTitle>Provide Your Freelancer Information</FormTitle>
				<Form>
					<FormTitleInfo>Personal Information</FormTitleInfo>
					<FormRowWrapper>
						<InputField
							name={"firstName"}
							label={"First Name"}
							required
							error={formik.touched.firstName && Boolean(formik.errors.firstName)}
							helperText={formik.touched.firstName && formik.errors.firstName}
						/>
						<InputField
							name={"lastName"}
							label={"Last Name"}
							required
							error={formik.touched.lastName && Boolean(formik.errors.lastName)}
							helperText={formik.touched.lastName && formik.errors.lastName}
						/>
					</FormRowWrapper>
					<FormRowWrapper>
						<InputField
							name={"location"}
							label={"Location"}
							required
							error={formik.touched.location && Boolean(formik.errors.location)}
							helperText={formik.touched.location && formik.errors.location}
						/>
						<InputFieldWithUpload onChange={setProfilePicture} name={"file_upload"} label={"Profile Picture"} />
					</FormRowWrapper>
					<FormRowWrapper childCount={1}>
						<Textarea
							name={"summary"}
							label={"About"}
							rows={4}
							mobileRows={12}
							placeholder={"A detailed professional overview highlighting skills and experience"}
						/>
					</FormRowWrapper>
				</Form>
				<BtnWrapper>
					<SecondaryBtn label={"Back"} onClick={handleBack} overrides={btnStyleOverrides} />
					<PrimaryBtn label={"Next"} overrides={btnStyleOverrides} />
				</BtnWrapper>
			</FormWrapper>
		</Wrapper>
	);
};

export default FreelancerSignUp;
