import styled from "@emotion/styled";
import { borderRadius, color, spacing } from "../../styles/variables";

const Wrapper = styled.div`
	margin-inline: ${spacing("xxxl1")};
	margin-block: ${spacing("xxl1")};

	@media screen and (max-width: 768px) {
		margin-inline: ${spacing("md")};
		margin-block: ${spacing("xl")};
	}
`;

const ScreenTitleWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-block-end: ${spacing("lg")};

	@media screen and (max-width: 768px) {
		position: relative;
		margin-block-end: ${spacing("xl2")};
	}
`;

const ScreenTitle = styled.h2`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 38px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	& > span {
		display: inline-flex;
		color: ${color("pGreen")};
		font-family: Poppins;
		font-size: 38px;
		font-style: normal;
		font-weight: 500;
		line-height: normal;

		& > svg {
			width: 48px;
			height: auto;
		}
	}

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		font-size: 29px;
		& > span {
			font-size: 29px;
			& > svg {
				width: 24px;
			}
		}
	}
`;

const PostJob = styled.button`
	cursor: pointer;
	border-radius: ${borderRadius(50)};
	background: ${color("freshGreen")};
	border: 2px solid ${color("freshGreen")};

	color: ${color("pWhite")};
	font-family: Inter;
	font-size: 28px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	padding: ${spacing("sm2")} ${spacing("xl")};

	&:hover {
		background-color: ${color("pWhite")};
		color: ${color("freshGreen")};
	}

	@media screen and (max-width: 768px) {
		font-size: 19px;
		position: absolute;
		bottom: 0;
		right: 0;
	}
`;

const SectionWrapper = styled.div`
	&:not(:last-child) {
		margin-block-end: ${spacing("lg")};
	}
`;
const SectionTitle = styled.h3`
	color: ${color("pGreen")};
	font-family: Poppins;
	font-size: 33px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	margin-block-end: ${spacing("sm")};

	@media screen and (max-width: 768px) {
		margin-block-end: ${spacing("xs")};
	}
`;

const CardWrapper = styled.div`
	width: 100%;
	border-radius: ${borderRadius(6)};
	background: ${color("pWhite")};
	box-shadow: 0px 4px 11px 0px rgba(0, 0, 0, 0.25);

	margin-block: ${spacing("sm2")};
	padding-inline: ${spacing("xl")};
	padding-block: ${spacing("xxl1")} ${spacing("xl")};

	display: flex !important;
	flex-direction: column;
	row-gap: ${spacing("xl1")};

	@media screen and (max-width: 768px) {
		padding-inline: ${spacing("lg")};
	}
`;

const UserDetailsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: ${spacing("lg1")};

	@media screen and (max-width: 768px) {
		column-gap: ${spacing("sm1")};
	}
`;

const UserImage = styled.img`
	border-radius: 50%;
	height: 61px;
	aspect-ratio: 1;
`;

const UserDetails = styled.div`
	display: flex;
	flex-direction: column;
`;

const UserName = styled.h4`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 19px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const OriginCountry = styled.span`
	color: ${color("dimGray")};
	font-family: Poppins;
	font-size: 12px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const IconWrapper = styled.div`
	margin-inline-start: auto;
	cursor: pointer;

	& > svg {
		width: 32px;
		height: 32px;
	}
`;

const StackWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("xl")};
`;

const Role = styled.h3`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const Rate = styled.span`
	display: inline-flex;
	align-items: center;
	column-gap: ${spacing("sm")};

	color: ${color("dimGray")};
	font-family: Poppins;
	font-size: 22px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	& > svg {
		height: 34px;
		width: auto;
	}
`;

const TechStacks = styled.div`
	display: flex;
	column-gap: ${spacing("sm1")};
	row-gap: ${spacing("sm1")};
	flex-wrap: wrap;
`;

const Stack = styled.div`
	border-radius: ${borderRadius(6)};
	background: ${color("dimGrey")};
	padding: ${spacing("sm2")} ${spacing("md1")};

	color: ${color("dimGray")};
	font-family: Poppins;
	font-size: 10px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: 768px) {
		padding: ${spacing("sm1")};
	}
`;

const Button = styled.button`
	cursor: pointer;
	width: 100%;
	border-radius: ${borderRadius(40)};
	border: 2px solid ${color("pGreen")};
	padding-block: ${spacing("sm")};
	text-align: center;

	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	transition: 300ms all;

	margin-block-start: auto;

	&:hover {
		color: ${color("pWhite")};
		background-color: ${color("pGreen")};
	}
`;

export {
	Wrapper,
	ScreenTitleWrapper,
	ScreenTitle,
	PostJob,
	SectionWrapper,
	SectionTitle,
	CardWrapper,
	UserDetailsWrapper,
	UserImage,
	UserDetails,
	UserName,
	OriginCountry,
	IconWrapper,
	StackWrapper,
	Role,
	Rate,
	TechStacks,
	Stack,
	Button,
};
