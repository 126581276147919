import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { LeftList, CustomNavLinkLeft, SpanHead } from "./index.styled";
import PrimaryButton from "../Buttons/Primary";
import Home from "../../assets/images/MB/home.png";
import Explore from "../../assets/images/MB/explore.png";
import Notifications from "../../assets/images/MB/notifications.png";
import Messages from "../../assets/images/MB/messages.png";
import Support from "../../assets/images/MB/support.png";
import Profile from "../../assets/images/MB/profile.png";
import listsimg from "../../assets/images/MB/lists.png";
import { connect } from "react-redux";
import Videos from "../../assets/icons/tv/videos";

const getLink = (path) => {
	let link = undefined;
	if (path.includes("microblogging")) {
		link = "/microblogging/feed";
	} else if (path.includes("shorts")) {
		link = "/shorts/feed";
	} else if (path.includes("tv")) {
		link = "/tv/home";
	} else if (path.includes("journalist")) {
		link = "/journalist/home";
	} else if (path.includes("pcontrol")) {
		link = "/pcontrol/home";
	}

	return link || `/${path[1]}`;
};

const LeftSubNav = ({
	lists,
	resetActiveProfile,
	post = true,
	support = true,
	explore = true,
	messages = true,
	videos = false,
	notifications = true,
}) => {
	const { pathname } = useLocation();
	const path = pathname?.split("/");
	const [activeRoute, setActiveRoute] = useState(null);

	const checkPath = () => {
		const path = pathname?.split("/");
		path.shift();

		if (path?.includes("shorts")) {
			setActiveRoute("shorts");
		} else if (path?.includes("microblogging")) {
			setActiveRoute("microblogging");
		} else if (path?.includes("tv")) {
			setActiveRoute("tv");
		} else if (path?.includes("journalist")) {
			setActiveRoute("journalist");
		} else if (path?.includes("pcontrol")) {
			setActiveRoute("pcontrol");
		}
	};

	useEffect(() => {
		checkPath();
	}, []);
	useEffect(() => {
		checkPath();
	}, [pathname]);

	const CustomNavigationLink = ({ to, children, exact, active }) => (
		<CustomNavLinkLeft to={to} exact={exact} active={String(active)}>
			{children}
		</CustomNavLinkLeft>
	);

	const getProfilePath = () => {
		if (activeRoute === "tv" || activeRoute == "journalist" || activeRoute == "pcontrol") {
			return `/${activeRoute}/profile`;
		}
		return `/${activeRoute}/profile/private`;
	};

	return (
		<LeftList>
			<CustomNavigationLink
				to={getLink(path)}
				active={
					((path?.includes("feed") || path?.includes("home")) && true) ||
					(path?.includes(activeRoute) && path?.length === 2 && true)
				}
			>
				<img src={Home} alt="icon" />
				<SpanHead>Home</SpanHead>
			</CustomNavigationLink>
			{explore && (
				<CustomNavigationLink
					to={`/${activeRoute}/search`}
					active={path?.includes("search") || (path?.includes("explore") && true)}
				>
					<img src={Explore} alt="icon" />
					<SpanHead>Explore</SpanHead>
				</CustomNavigationLink>
			)}
			{notifications && (
				<CustomNavigationLink to={`/${activeRoute}/notifications`} active={path?.includes("notifications") && true}>
					<img src={Notifications} alt="icon" />
					<SpanHead>Notifications</SpanHead>
				</CustomNavigationLink>
			)}
			{messages && (
				<CustomNavigationLink to="/messaging">
					<img src={Messages} alt="icon" />
					<SpanHead>Messages</SpanHead>
				</CustomNavigationLink>
			)}
			{lists && (
				<li>
					<CustomNavigationLink to="/">
						<img src={listsimg} alt="icon" />
						<SpanHead>Lists</SpanHead>
					</CustomNavigationLink>
				</li>
			)}
			<CustomNavigationLink
				to={getProfilePath()}
				onClick={() => {
					resetActiveProfile();
				}}
				active={(path?.includes("private") || path?.includes("profile")) && true}
			>
				<img src={Profile} alt="icon" />
				<SpanHead>Profile</SpanHead>
			</CustomNavigationLink>
			{support && (
				<CustomNavigationLink to="/">
					<img src={Support} alt="icon" />
					<SpanHead>Support & Security</SpanHead>
				</CustomNavigationLink>
			)}
			{videos && (
				<CustomNavigationLink to="#">
					<Videos size={16} />
					<SpanHead>Your Videos</SpanHead>
				</CustomNavigationLink>
			)}
			{post && <PrimaryButton label={"Post"} />}
		</LeftList>
	);
};

const mapPropsToState = (dispatch) => {
	return {
		resetActiveProfile: () =>
			dispatch({
				type: "MB_SET_ACTIVE_PROFILE",
			}),
	};
};

export default connect(undefined, mapPropsToState)(LeftSubNav);
