const Index = ({ color = "#61BCAC", size = 52 }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 52 51" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M16.4 47H35.6C42.3872 47 45.7832 47 47.8904 44.6358C50 42.2662 50 38.4614 50 30.8437V28.1509C50 20.5359 50 16.7284 47.8904 14.3615C46.0472 12.2935 43.22 12.0323 38 12M14 12C8.78 12.0323 5.9528 12.2935 4.1096 14.3615C2 16.7284 2 20.5359 2 28.1509V30.8437C2 38.4614 2 42.2689 4.1096 44.6358C4.8296 45.4436 5.6984 45.9741 6.8 46.3241"
				stroke={color}
				strokeWidth="2.5"
				strokeLinecap="round"
			/>
			<path
				d="M25.1705 38.9119V19.2244H28.5114V38.9119H25.1705ZM16.9972 30.7386V27.3977H36.6847V30.7386H16.9972Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
