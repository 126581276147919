import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";

import HomePage from "../pages/Services/Home";
import ClientSignup from "../pages/Services/Client/Signup";
import CompanySignup from "../pages/Services/Company/Signup";
import FreelancerSignup from "../pages/Services/Freelancer/Signup";
import FreelancerProfilePrivate from "../pages/Services/Freelancer/Profile/Private";
import FreelancerProfilePublic from "../pages/Services/Freelancer/Profile/Public";
import EmployeeSignup from "../pages/Services/Employee/Signup";
import SearchResult from "../pages/Services/SearchResult";
import CompanyProfilePrivate from "../pages/Services/Company/Profile/Private";
import CompanyProfilePublic from "../pages/Services/Company/Profile/Public";
import ClientProfilePrivate from "../pages/Services/Client/Profile/Private";
import ClientProfilePublic from "../pages/Services/Client/Profile/Public";
import EmployeeProfilePrivate from "../pages/Services/Employee/Profile/Private";
import EmployeeProfilePublic from "../pages/Services/Employee/Profile/Public";
import Heading from "../components/Heading/Heading";
import Icon from "../assets/icons/services/services";

import SubHeader from "../components/Services/Header";
import SignupMain from "../pages/Services/SingupMain";
import Microblogging from "../components/Services/Microblogging";

const TransportationRoutes = () => {
	const location = useLocation();
	const { pathname } = location;
	const [isServices, setIsServices] = useState(false);
	const [isUser, setIsUser] = useState(false);
	const [activeProfile, setActiveProfile] = useState("Thomas Mark");

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path?.includes("services") && !path.includes("microblogging") && !path.includes("signup") && path?.length > 1) {
			setIsServices(true);
		} else setIsServices(false);

		if (path.includes("private") || path.includes("public")) setIsUser(true);
		else setIsUser(false);

		if (path.includes("company")) setActiveProfile("Paychex");
		else setActiveProfile("Thomas Mark");
	}, [pathname]);
	return (
		<>
			{isServices && (
				<>
					<Heading icon={Icon} title={"Professional Services"} user={isUser ? activeProfile : "Guest"} />
					<SubHeader />
				</>
			)}
			<Routes>
				<Route path="services">
					<Route index element={<SignupMain />} />
					<Route path="home" element={<HomePage />} />
					<Route path="microblogging" element={<Microblogging />} />
					<Route path="client">
						<Route path="signup" element={<ClientSignup />} />
						<Route path="profile">
							<Route path="public" element={<ClientProfilePublic />} />
							<Route path="private" element={<ClientProfilePrivate />} />
						</Route>
					</Route>
					<Route path="company">
						<Route path="signup" element={<CompanySignup />} />
						<Route path="profile">
							<Route path="private" element={<CompanyProfilePrivate />} />
							<Route path="public" element={<CompanyProfilePublic />} />
						</Route>
					</Route>
					<Route path="freelancer">
						<Route path="signup" element={<FreelancerSignup />} />
						<Route path="profile">
							<Route path="private" element={<FreelancerProfilePrivate />} />
							<Route path="public" element={<FreelancerProfilePublic />} />
						</Route>
					</Route>
					<Route path="employee">
						<Route path="signup" element={<EmployeeSignup />} />
						<Route path="profile">
							<Route path="private" element={<EmployeeProfilePrivate />} />
							<Route path="public" element={<EmployeeProfilePublic />} />
						</Route>
					</Route>
					<Route path="search" element={<SearchResult />} />
				</Route>
			</Routes>
		</>
	);
};

export default TransportationRoutes;
