import styled from "@emotion/styled";

const Title = styled.div`
	color: #61bcac;

	/* font-weight-bold-xxl */
	font-family: Inter;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;

	margin-bottom: 8px;
`;

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);

	column-gap: 24px;

	@media (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);

		border-bottom: 1px solid #e0e0e0;
		padding-bottom: 24px;

		&:last-child {
			border-bottom: none;
			padding-bottom: 0;
		}
	}
`;

const InfoSection = styled.div`
	grid-column: span 4;

	@media (max-width: 768px) {
		grid-row: 2;

		margin-top: 16px;
	}
`;

const Details = styled.div`
	grid-column: span 4;

	color: #676464;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	@media (max-width: 768px) {
		grid-row: 3;

		margin-top: 12px;
	}
`;

const ImageWrapper = styled.div`
	grid-column: span 4;
	@media (max-width: 768px) {
		grid-row: 1;
	}
	& img {
		max-width: 100%;
	}
`;

const ModuleName = styled.div`
	color: #61bcac;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Headline = styled.div`
	color: #606060;

	/* font-weight-bold-xxl */
	font-family: Inter;
	font-size: 22px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const Author = styled.div`
	color: #676464;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export { Title, Wrapper, InfoSection, Details, ImageWrapper, ModuleName, Headline, Author };
