import React, { useContext, useEffect, useState } from "react";
import backarrow from "../../assets/icons/ArrowBack.png";
import downarrow from "../../assets/images/icons/arrow.png";
import technologyIcon from "../../assets/images/icons/technology.png";
import educationIcon from "../../assets/images/icons/education.png";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Collapse } from "@mui/material";
import {
	Wrapper,
	NavWrapper,
	BreadCrumb,
	BreadCrumbArrow,
	ProfileWrapper,
	Profile,
	ServiceAccordian,
	MainWrap,
	DownArrow,
	ServiceLink,
	MainHead,
	ServiceWrap,
	ServiceContainer,
	ProfileSection,
	HeadingWrapper,
	HeadIcon,
	HeadText,
	CollapsibleIcons,
	OneWrapper,
	SectionWrapper,
	TextWrapper,
	ContentWrapper,
	LeftWrapper,
	InputRadio,
	RightWrapper,
	Name,
	UserLink,
} from "./index.styles";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/loader/index";
import { GetAllSubModuleProfile } from "../../utils/apiCalls";
import { UserContext } from "../../store/UserDetailsStore";

const Profiles = () => {
	const navigate = useNavigate();
	const { isLoginUserDetails } = useContext(UserContext);
	const [isLoader, setIsLoader] = useState(false);
	const [modulesData, setModulesData] = useState([]);
	const [selectedProfileId, setSelectedProfileId] = useState();
	const [expandedSectionIndex, setExpandedSectionIndex] = useState("");

	const toggleCategory = (index) => {
		setExpandedSectionIndex((currentExpandedIndex) => (currentExpandedIndex === index ? null : index));
	};
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	useEffect(() => {
		const handleResize = () => {
			setWindowWidth(window.innerWidth);
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, []);
	const isScreen = windowWidth <= 768;

	const getSubModuleProfiles = () => {
		setIsLoader(true);
		GetAllSubModuleProfile((res) => {
			const { status, data } = res;
			if (status === 200) {
				setIsLoader(false);
				const newData = Object.keys(data).map((key) => ({
					section: key,
					data: data[key],
				}));
				setModulesData(newData);
			} else {
				setIsLoader(false);
				setModulesData([]);
			}
		});
	};

	useEffect(() => {
		getSubModuleProfiles();
	}, []);

	const handleNavigateProfile = (data) => {
		if (data) {
			const profile = data?.moduleName?.toLowerCase();
			const accountType = data.accountType.toLowerCase();
			if (profile && accountType) {
				navigate(`/${profile}/${accountType}/profile/private`, { state: { user: data } });
			}
		}
	};

	const handleNavigateMain = () => {
		navigate("/privateprofile");
	};

	const handlebackHome = () => {
		navigate("/home");
	};

	if (isLoader) {
		return <Loader />;
	}
	return (
		<>
			<Wrapper>
				<NavWrapper onClick={handlebackHome}>
					<BreadCrumbArrow src={backarrow} alt="" />
					<BreadCrumb> Back </BreadCrumb>
				</NavWrapper>
				<ProfileWrapper>
					<Profile> All Profiles</Profile>
					<ServiceAccordian>
						<MainWrap>
							<DownArrow src={downarrow} alt="" />
							<ServiceLink>Main</ServiceLink>
						</MainWrap>
						<MainHead
							onClick={() => {
								handleNavigateMain();
							}}
						>
							<input type="radio" name="userOption" value="main" />
							<UserLink>{isLoginUserDetails?.name}</UserLink>
						</MainHead>
						<ServiceWrap>
							<DownArrow src={downarrow} alt="" />
							<ServiceLink>Services</ServiceLink>
						</ServiceWrap>
						<ProfileSection>
							{modulesData
								.filter((section) => section.data.length > 0)
								.map((section, sectionIndex) => {
									const isExpanded = expandedSectionIndex === sectionIndex;
									return (
										<ServiceContainer key={sectionIndex}>
											<HeadingWrapper isExpanded={isExpanded}>
												<HeadText>
													<HeadIcon
														src={
															section.data.length === 0
																? ""
																: section.section === "itProfileAccounts"
																	? technologyIcon
																	: educationIcon
														}
														alt=""
													/>
													{section.data.length === 0
														? ""
														: section.section === "itProfileAccounts"
															? "Information Technology"
															: section?.section === "educationAccounts"
																? "Education"
																: section?.section}
												</HeadText>

												{section?.data?.length === 0 ? (
													""
												) : (
													<CollapsibleIcons>
														{expandedSectionIndex !== sectionIndex ? (
															<AddIcon onClick={() => toggleCategory(sectionIndex)} />
														) : (
															<RemoveIcon onClick={() => toggleCategory(sectionIndex)} />
														)}
													</CollapsibleIcons>
												)}
											</HeadingWrapper>
											{isScreen ? (
												<Collapse in={expandedSectionIndex === sectionIndex}>
													<OneWrapper>
														{section?.data?.length > 0
															? section?.data?.map((module, index) => (
																	<SectionWrapper key={index}>
																		<TextWrapper>{module?.accountType}</TextWrapper>
																		<ContentWrapper
																			onClick={() => {
																				handleNavigateProfile(module);
																			}}
																		>
																			<LeftWrapper>
																				<input
																					type="radio"
																					name="userOption"
																					value={module.title}
																					checked={module === selectedProfileId}
																					onChange={() => setSelectedProfileId(module)}
																				/>
																			</LeftWrapper>
																			<RightWrapper>
																				<Name>{module?.fullName}</Name>
																			</RightWrapper>
																		</ContentWrapper>
																	</SectionWrapper>
																))
															: ""}
													</OneWrapper>
												</Collapse>
											) : (
												<OneWrapper>
													{section?.data?.length > 0
														? section?.data?.map((module, index) => (
																<SectionWrapper key={index}>
																	<TextWrapper>{module?.accountType}</TextWrapper>
																	<ContentWrapper
																		onClick={() => {
																			handleNavigateProfile(module);
																		}}
																	>
																		<LeftWrapper>
																			<input
																				type="radio"
																				name="userOption"
																				value={module.title}
																				checked={module === selectedProfileId}
																				onChange={() => setSelectedProfileId(module)}
																			/>
																		</LeftWrapper>
																		<RightWrapper>
																			<Name>{module?.fullName}</Name>
																		</RightWrapper>
																	</ContentWrapper>
																</SectionWrapper>
															))
														: ""}
												</OneWrapper>
											)}
										</ServiceContainer>
									);
								})}
						</ProfileSection>
					</ServiceAccordian>
				</ProfileWrapper>
			</Wrapper>
		</>
	);
};

export default Profiles;
