import styled from "@emotion/styled";

const ModuleName = styled.h3`
	color: #61bcac;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const Title = styled.h4`
	max-width: 70%;
	color: #676464;

	/* Body-text-md */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	@media (max-width: 768px) {
		color: #000;
		max-width: 100%;
	}
`;

const Author = styled.span`
	color: #696a68;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;
export { ModuleName, Title, Author };
