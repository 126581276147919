import styled from "@emotion/styled";
import { useRef, useState } from "react";
import { borderRadius, color, spacing, typography } from "../../../styles/variables";
import PropTypes from "prop-types";
import PrimaryBtn from "../../Buttons/Primary";
import { FormHelperText } from "@mui/material";

const InputFieldWithUpload = ({
	name,
	label,
	required = false,
	gap = spacing("sm"),
	onChange = undefined,
	error = undefined,
	helperText = undefined,
}) => {
	const [fileName, setFileName] = useState("");

	const handleChange = (event) => {
		const file = event.target.files[0];
		if (file) {
			setFileName(file?.name);
			typeof onChange === "function" && onChange(file);
		}
	};

	const inputRef = useRef(null);

	const handleFieldFocus = (event) => {
		event.stopPropagation();
		inputRef && inputRef?.current?.click();
	};

	return (
		<Wrapper gap={gap}>
			{label && (
				<CustomLabel>
					{label}
					{required && "*"}
				</CustomLabel>
			)}
			<input ref={inputRef} onChange={handleChange} type="file" name={name} style={{ display: "none" }} />
			<label>
				<BtnWrapper error={error}>
					{fileName && <FileName>{fileName}</FileName>}
					<PrimaryBtn
						label={"Upload"}
						type="button"
						onClick={handleFieldFocus}
						overrides={{
							position: "absolute",
							top: 0,
							bottom: 0,
							right: 0,
							borderRadius: `0 ${borderRadius(6)} ${borderRadius(6)} 0`,
							textTransform: "none",
							paddingInline: spacing("lg1"),
						}}
					/>
				</BtnWrapper>
				<FormHelperText error={error}>{helperText}</FormHelperText>
			</label>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	width: 100%;
	margin-block-end: ${spacing("md")};

	display: flex;
	flex-direction: column;
	row-gap: ${(props) => props.gap};

	@media screen and (max-width: 768px) {
		margin-block-end: ${spacing("lg")};
	}
`;

export const CustomLabel = styled.span`
	display: inline-block;

	color: ${color("pLabel")};
	${typography("body", "md", false)}
`;

const BtnWrapper = styled.div`
	min-height: 38px;
	height: 38px;
	cursor: pointer;
	position: relative;
	border: 1px solid ${color("borderDimGray")};
	border-radius: ${borderRadius(8)};

	display: flex;
	align-items: center;

	&:hover {
		border-color: ${color("pBlack")};
	}

	& button:hover {
		border-color: transparent;
	}
	${(props) =>
		props?.error && {
			borderColor: color("error"),
		}}
`;

const FileName = styled.span`
	max-width: 70%;
	padding-inline-start: ${spacing("sm2")};
	text-overflow: ellipsis;
	color: #000;
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

InputFieldWithUpload.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	placeholder: PropTypes.string,
	required: PropTypes.bool,
	errorMsg: PropTypes.string,
	gap: PropTypes.string,
};

export default InputFieldWithUpload;
