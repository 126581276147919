import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web
import userReducer from "./UserReducer";
import itReducer from "./ITReducer";
import mbReducer from "./MBReducer";
import moduleReducer from "./ModuleReducer";
import EducationReducer from "./EducationReducer";
import AgricultureReducer from "./AgricultureReducer";
import TVReducer from "./TVReducer";
import CommonReducer from "./CommonReducer";

const rootReducer = combineReducers({
	user: userReducer,
	it: itReducer,
	mb: mbReducer,
	module: moduleReducer,
	education: EducationReducer,
	ag: AgricultureReducer,
	tv: TVReducer,
	common: CommonReducer,
});

const persistConfig = {
	key: "root",
	storage,
	// No whitelist or blacklist means all reducers will be persisted by default
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
});

export const persistor = persistStore(store);
