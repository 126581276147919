import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { connect } from "react-redux";

import Heading from "../components/Heading/Heading";
import Icon from "../assets/icons/socialJournalist/socialJornalist";
import Home from "../pages/SocialJournalist/Home";
import Search from "../pages/SocialJournalist/Search";
import Notifications from "../pages/SocialJournalist/Notifications/index";
import Profile from "../pages/SocialJournalist/Profile";
import useLayout from "../utils/hooks/useLayout";
import BottomNav from "../components/SocialJournalist/BottomNav";

const SocialJournalistRoutes = ({ userData, setActiveModule, setSignUpPopupToggle, signUpPopup }) => {
	const location = useLocation();
	const { pathname } = location;
	const [profileData, setProfileData] = useState({});
	const isMobile = useLayout();
	const [isSocialJournalist, setIsSocialJournalist] = useState(false);

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path?.includes("journalist") && !path.includes("microblogging") && !path.includes("signup") && path?.length > 1) {
			setActiveModule("sj");
			setIsSocialJournalist(true);
		} else setIsSocialJournalist(false);
	}, [pathname]);

	useEffect(() => {
		if (!userData?.isLoading) setProfileData(userData?.data || null);
	}, [userData]);

	return (
		<>
			{isSocialJournalist && (
				<>
					<Heading
						icon={Icon}
						title={"Social Journalist"}
						moduleHomeRedirect={"/journalist/home"}
						user={profileData?.fullName || "George Kajaia"}
					/>
					{isMobile && <BottomNav />}
				</>
			)}
			<Routes>
				<Route path="journalist">
					{/* <Route index element={<SignupMain />} /> */}
					<Route path="home" element={<Home />} />
					<Route path="search" element={<Search />} />
					<Route path="profile">
						<Route index element={<Profile />} />
					</Route>
					<Route path="notifications" element={<Notifications />} />
				</Route>
			</Routes>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state.sj?.profile,
		signUpPopup: state.sj?.signUpPopup,
	};
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MODULE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_SJ",
			payload: data,
		}),
});

export default connect(mapStateToProps, mapPropsToState)(SocialJournalistRoutes);
