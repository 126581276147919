import React from "react";
import { createRoot } from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./app/App";
import { UserProvider } from "./app/store/UserDetailsStore";
import { persistor, store } from "./app/store/redux/Reducers";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

const root = createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		<UserProvider>
			<Provider store={store}>
				<PersistGate persistor={persistor}>
					<App />
				</PersistGate>
			</Provider>
		</UserProvider>
	</React.StrictMode>,
);

reportWebVitals();
