import { Box } from "@mui/material";
import { Author, Title, Thumbnail } from "./index.styled";
import MagazineCover from "../../../../assets/images/MagazineCover.png";
import useLayout from "../../../../utils/hooks/useLayout";

const FeedContent = () => {
	const isMobile = useLayout();
	return (
		<Box display={"flex"} flexDirection={"column"} rowGap={isMobile ? "12px" : "24px"}>
			<Title>Sundar Pichai warns Google staff more layoffs are coming</Title>
			<Author>Ivan Mehta</Author>
			<Thumbnail src={MagazineCover} />
		</Box>
	);
};

export default FeedContent;
