import LeftSubNav from "../../../../components/LeftSubNav";
import { borderRadius, color, spacing } from "../../../../styles/variables";
import {
	Wrapper,
	LeftSection,
	RightSection,
	ResultWrapper,
	Result,
	ProfileWrapper,
	UserImage,
	UserDetailsWrapper,
	UserDetails,
	UserName,
	StatsWrapper,
	Stat,
	OtherProfilesWrapper,
	ProfilesHeader,
	Profiles,
	Profile,
	ManageLinks,
	Butt,
} from "../../index.styled";
import Preview1 from "../../../../assets/images/shorts/Preview1.png";
import Preview2 from "../../../../assets/images/shorts/Preview2.png";
import ProfilePicture from "../../../../assets/images/user-profile-backsquare.png";
import PrimaryBtn from "../../../../components/Buttons/Primary";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
	GetEducationPublicShortVideoProfile,
	GetPublicShortVideoProfile,
	GetShortVideoUserDetails,
	GetShortVideosPrivateProfile,
} from "../../../../utils/apiCalls";
import Loader from "../../../../components/loader/index";

const PrivateProfile = () => {
	const location = useLocation();
	const userData = location?.state?.user;
	const [shortVideoPublicData, setShortVideoPublicData] = useState([]);
	const [shortVideoUser, setShortVideoUser] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const navigate = useNavigate();

	const getPublicITShortVideoProfile = (id) => {
		setIsLoader(true);
		if (id) {
			const payload = {
				itProfileId: id,
			};
			GetPublicShortVideoProfile((res) => {
				const { message, data, status } = res;
				if (status === 200) {
					setIsLoader(false);
					setShortVideoPublicData(data);
				} else {
					setIsLoader(false);
					setShortVideoPublicData([]);
				}
			}, payload);
		}
	};

	const getPublicEducationShortVideoProfile = (id) => {
		setIsLoader(true);
		if (id) {
			const payload = {
				educationId: id,
			};
			GetEducationPublicShortVideoProfile((res) => {
				const { message, data, status } = res;
				if (status === 200) {
					setIsLoader(false);
					setShortVideoPublicData(data);
				} else {
					setIsLoader(false);
					setShortVideoPublicData([]);
				}
			}, payload);
		}
	};

	const getPublicShortVideoProfile = (id) => {
		setIsLoader(true);
		if (id) {
			const payload = {
				shortVideoId: id,
			};
			GetShortVideosPrivateProfile((res) => {
				const { message, data, status } = res;
				if (status === 200) {
					setIsLoader(false);
					setShortVideoPublicData(data);
				} else {
					setIsLoader(false);
					setShortVideoPublicData([]);
				}
			}, payload);
		}
	};

	useEffect(() => {
		if (userData) {
			if (userData?.itProfileId) {
				getPublicITShortVideoProfile(userData?.itProfileId);
			} else if (userData?.educationId) {
				getPublicEducationShortVideoProfile(userData?.educationId);
			} else if (userData?.shortVideoId) {
				getPublicShortVideoProfile(userData?.shortVideoId);
			}
		}
	}, [userData]);

	const getSvUserDetail = (id) => {
		if (id) {
			const payload = {
				shortVideoId: id,
			};
			GetShortVideoUserDetails((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setShortVideoUser(data);
					if (data?.module === "Information Technology") {
						const accountTyp = data?.accountType?.toLowerCase();
						navigate(`/it/${accountTyp}/profile/public`, { state: { user: data } });
					} else if (data?.module === "Education") {
						const accountTyp = data?.accountType?.toLowerCase();
						navigate(`/education/${accountTyp}/profile/public`, { state: { user: data } });
					}
				} else {
					setShortVideoUser([]);
				}
			}, payload);
		}
	};

	const handleProfile = () => {
		getSvUserDetail(shortVideoPublicData?.shortVideosId || userData?.shortVideoId);
	};

	if (isLoader) {
		return <Loader />;
	}

	return (
		<Wrapper>
			<LeftSection>
				<LeftSubNav />
				<OtherProfilesWrapper>
					<ProfilesHeader>Other Profiles</ProfilesHeader>
					<Butt onClick={handleProfile}>{shortVideoPublicData?.shortVidModule}</Butt>
					<Profiles>
						<Profile>
							<span>Engineering</span> George Kajaia
						</Profile>
						<Profile>
							<span>Engineering</span> George Kajaia
						</Profile>
						<Profile>
							<span>Engineering</span> George Kajaia
						</Profile>
					</Profiles>
					<ManageLinks>Manage Links</ManageLinks>
				</OtherProfilesWrapper>
			</LeftSection>
			<RightSection
				style={{
					gridColumn: "span 9/13",
				}}
			>
				<ProfileWrapper>
					<UserImage src={shortVideoPublicData?.profilePicture || ProfilePicture} alt="profile" />
					<UserDetailsWrapper>
						<UserDetails>
							<UserName>{shortVideoPublicData?.fullName || "Anonymous User"}</UserName>
							<StatsWrapper>
								<Stat>448 Following</Stat>
								<Stat>744 Followes</Stat>
							</StatsWrapper>
						</UserDetails>
						<PrimaryBtn
							label={"Follow"}
							overrides={{
								textTransform: "none",
								borderRadius: borderRadius(60),
								paddingInline: spacing("xl"),
								paddingBlock: spacing("xs"),
							}}
						/>
					</UserDetailsWrapper>
				</ProfileWrapper>
				<ResultWrapper>
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
				</ResultWrapper>
			</RightSection>
		</Wrapper>
	);
};

export default PrivateProfile;
