import { Fragment, useState } from "react";
import { useNavigate } from "react-router-dom";
import ClientSignUpForm from "../../../../components/IT/Forms/Signup/Client";
import Heading from "../../../../components/Heading/Heading";
import icon from "../../../../assets/icons/engineering.png";
import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	justify-content: center;
	margin-inline: 40px;

	@media screen and (max-width: 768px) {
		display: flex;
		margin-inline: 0;
	}

	@media screen and (min-width: 1440px) {
		max-width: 1440px;
		margin-inline: auto;
	}
`;

const ClientSignUp = () => {
	const [profilePicture, setProfilePicture] = useState(null);
	const navigate = useNavigate();
	const handleSubmit = (values) => {
		navigate("/it/microblogging", {
			state: {
				allData: { ...values },
				picture: profilePicture,
				url: "createClientAcc",
				redirect: "/it/client/profile/private",
			},
		});
	};

	return (
		<Fragment>
			<Heading icon={icon} title={"Information Technology"} showSearchInput={false} moduleHomeRedirect={"/it/home"} />
			<Wrapper>
				<ClientSignUpForm setProfilePicture={setProfilePicture} onSubmit={handleSubmit} />
			</Wrapper>
		</Fragment>
	);
};

export default ClientSignUp;
