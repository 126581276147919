import LeftSubNav from "../../../components/LeftSubNav";
import {
	Wrapper,
	LeftSection,
	CenterContent,
	ProfileWrapper,
	UserImage,
	UserDetailsWrapper,
	UserDetails,
	UserName,
	StatsWrapper,
	Stat,
} from "../index.styled";
import ProfilePicture from "../../../assets/images/MB/dp.png";
import {
	InfoWrapper,
	NotificationImage,
	NotificationWrapper,
	NotificationsList,
	NotificationUser,
	NotificationDesc,
} from "./index.styled";

const Notifications = () => {
	return (
		<Wrapper>
			<LeftSection>
				<LeftSubNav />
			</LeftSection>
			<CenterContent>
				<ProfileWrapper>
					<UserImage src={ProfilePicture} alt="profile" />
					<UserDetailsWrapper>
						<UserDetails>
							<UserName>George Kajaia</UserName>
							<StatsWrapper>
								<Stat>448 Following</Stat>
								<Stat>744 Followes</Stat>
							</StatsWrapper>
						</UserDetails>
					</UserDetailsWrapper>
				</ProfileWrapper>
				<NotificationsList>
					<NotificationWrapper>
						<NotificationImage src={ProfilePicture} alt="profile" />
						<InfoWrapper>
							<NotificationUser>Michelle</NotificationUser>
							<NotificationDesc>
								Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced
								with great excitement. Come play with us on December.
							</NotificationDesc>
						</InfoWrapper>
					</NotificationWrapper>
					<NotificationWrapper>
						<NotificationImage src={ProfilePicture} alt="profile" />
						<InfoWrapper>
							<NotificationUser>Michelle</NotificationUser>
							<NotificationDesc>
								Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced
								with great excitement. Come play with us on December.
							</NotificationDesc>
						</InfoWrapper>
					</NotificationWrapper>
					<NotificationWrapper>
						<NotificationImage src={ProfilePicture} alt="profile" />
						<InfoWrapper>
							<NotificationUser>Michelle</NotificationUser>
							<NotificationDesc>
								Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced
								with great excitement. Come play with us on December.
							</NotificationDesc>
						</InfoWrapper>
					</NotificationWrapper>
					<NotificationWrapper>
						<NotificationImage src={ProfilePicture} alt="profile" />
						<InfoWrapper>
							<NotificationUser>Michelle</NotificationUser>
							<NotificationDesc>
								Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced
								with great excitement. Come play with us on December.
							</NotificationDesc>
						</InfoWrapper>
					</NotificationWrapper>
				</NotificationsList>
			</CenterContent>
		</Wrapper>
	);
};

export default Notifications;
