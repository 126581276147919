import { NavLink, useNavigate } from "react-router-dom";
import { InputAdornment, MenuItem } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { connect } from "react-redux";

import egicon1 from "../../../assets/images/icons/egicon1.svg";
import egicon2 from "../../../assets/images/icons/egicon2.svg";

import useLayout from "../../../utils/hooks/useLayout";
import { color, spacing } from "../../../styles/variables";
import SidenavMobileComponent from "../../../components/SidenavMobileComponent";
import {
	Wrapper,
	NavigationLink,
	LeftNav,
	Searchbar,
	SearchField,
	Dropdown,
	RightNav,
	QuickNaviation,
	MobileWrapper,
	MobileTopNavs,
	MobileLeftNav,
	MobileRightNav,
	MobileQuickNav,
} from "./index.styled";

const Header = ({ setActiveMBModule }) => {
	const isMobile = useLayout();
	const navigate = useNavigate();

	const handleMicroblogging = () => {
		setActiveMBModule("healthcare");
		navigate("/microblogging");
	};

	const handleShortVideos = () => {
		navigate("/shorts/feed");
	};

	const handleSearch = () => {
		navigate("/healthcare/search");
	};
	return (
		<Wrapper isMobile={isMobile}>
			{isMobile ? (
				<MobileWrapper>
					<MobileTopNavs>
						<MobileLeftNav>
							<SidenavMobileComponent color={"#fff"} module="healthcare" />
							<span onClick={handleSearch}>
								<SearchIcon sx={{ color: color("pWhite"), width: 23 }} />
							</span>
						</MobileLeftNav>
						<MobileRightNav>
							<NavLink>
								<NotificationsIcon sx={{ color: color("pWhite"), width: 30 }} />
							</NavLink>
							<NavLink>
								<FavoriteBorderIcon sx={{ color: color("pWhite"), width: 32 }} />
							</NavLink>
						</MobileRightNav>
					</MobileTopNavs>
					<MobileQuickNav>
						<QuickNaviation onClick={handleMicroblogging}>
							<img src={egicon1} alt="" />
							Micro Blogging
						</QuickNaviation>
						<QuickNaviation onClick={handleShortVideos}>
							<img src={egicon2} alt="" />
							Short Videos
						</QuickNaviation>
					</MobileQuickNav>
				</MobileWrapper>
			) : (
				<>
					<LeftNav>
						<NavigationLink>
							Jobs
							<ExpandMoreIcon />
						</NavigationLink>
						<NavigationLink>
							Talent
							<ExpandMoreIcon />
						</NavigationLink>
						<NavigationLink>
							Reports
							<ExpandMoreIcon />
						</NavigationLink>
						<NavigationLink>Messages</NavigationLink>
					</LeftNav>
					<Searchbar>
						<SearchField
							onClick={handleSearch}
							startAdornment={
								<InputAdornment
									position="start"
									sx={{
										marginInlineEnd: spacing("xs"),
									}}
								>
									<SearchIcon color={color("dimGray")} />
								</InputAdornment>
							}
							endAdornment={
								<InputAdornment position="end">
									<Dropdown IconComponent={ExpandMoreIcon} variant="standard" defaultValue={"talent"}>
										<MenuItem value="talent">Talent</MenuItem>
										<MenuItem value="tech">Tech</MenuItem>
									</Dropdown>
								</InputAdornment>
							}
							placeholder="Search"
						/>
					</Searchbar>
					<RightNav>
						<QuickNaviation onClick={handleMicroblogging}>
							<img src={egicon1} alt="" />
							Micro Blogging
						</QuickNaviation>
						<QuickNaviation onClick={handleShortVideos}>
							<img src={egicon2} alt="" />
							Short Videos
						</QuickNaviation>
					</RightNav>
				</>
			)}
		</Wrapper>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveMBModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: data,
		}),
});

export default connect(undefined, mapPropsToState)(Header);
