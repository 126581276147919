import React from "react";

const Investment = ({ color = "#F800FD", size = 23 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 23 23" fill="none">
			<path
				d="M8.64318 0.219389C7.88849 0.331694 7.40783 0.85728 7.309 1.67935C7.20568 2.54634 7.43927 2.94166 7.98732 2.82037C8.29728 2.75298 8.4006 2.54185 8.4006 1.98482C8.4006 1.80513 8.42306 1.60748 8.45451 1.54458C8.56681 1.29302 8.52189 1.29751 11.4957 1.29751C13.5262 1.29751 14.2674 1.31099 14.3527 1.35142C14.5324 1.43228 14.5998 1.60748 14.6043 2.00279C14.6043 2.41607 14.6672 2.6272 14.8289 2.744C14.9727 2.85181 15.3231 2.8608 15.4758 2.76197C15.669 2.63619 15.7004 2.52388 15.6959 1.97134C15.6914 1.53111 15.6779 1.41431 15.5791 1.17623C15.3994 0.731498 15.1703 0.502397 14.7211 0.313725L14.4426 0.196928L11.6574 0.187944C10.1256 0.187944 8.76896 0.196928 8.64318 0.219389Z"
				fill={color}
			/>
			<path
				d="M1.10059 3.92104C0.67832 4.05132 0.381836 4.29839 0.175195 4.67573L0.0224609 4.95874V7.11499V9.27124L0.125781 9.4689C0.291992 9.77886 0.601953 10.0798 0.889453 10.2056C1.05117 10.2775 2.62344 10.6503 5.25586 11.2343L9.36621 12.1507L9.38867 13.0896C9.41113 14.1183 9.4291 14.1857 9.68066 14.289C9.87383 14.3744 13.1262 14.3744 13.3193 14.289C13.5709 14.1857 13.5889 14.1183 13.6113 13.0896L13.6338 12.1507L17.7441 11.2343C20.3766 10.6503 21.9488 10.2775 22.1105 10.2056C22.398 10.0798 22.708 9.77886 22.8742 9.4689L22.9775 9.27124V7.11499V4.95874L22.8248 4.67573C22.6541 4.36128 22.4115 4.13667 22.0746 3.98393L21.8545 3.88061L11.5674 3.87163C3.38711 3.86714 1.24434 3.87612 1.10059 3.92104ZM21.6254 4.99917C21.9309 5.12495 21.9219 5.07104 21.9219 7.11948V8.97026L21.8096 9.10503C21.7422 9.18589 21.6254 9.25776 21.5086 9.28472C21.2705 9.34761 13.6742 11.0457 13.6428 11.0457C13.6293 11.0457 13.6113 10.9019 13.6023 10.7267C13.5889 10.4347 13.5754 10.3943 13.4406 10.2775L13.2969 10.1472H11.5H9.70312L9.55937 10.2775C9.42461 10.3943 9.41113 10.4347 9.39766 10.7267C9.38867 10.9019 9.3707 11.0457 9.35723 11.0457C9.32578 11.0457 1.72949 9.34761 1.49141 9.28472C1.37461 9.25776 1.25781 9.18589 1.19043 9.10503L1.07812 8.97026V7.13296C1.07812 5.098 1.07363 5.12944 1.37012 4.99917C1.57676 4.90933 21.4053 4.90933 21.6254 4.99917ZM12.5332 12.2361V13.2468H11.5H10.4668V12.2361V11.2253H11.5H12.5332V12.2361Z"
				fill={color}
			/>
			<path
				d="M0.732461 11.2702C0.638125 11.3286 0.575234 11.4095 0.543789 11.5263C0.476406 11.7554 0.476406 18.4847 0.539297 18.8216C0.66957 19.4954 1.15023 19.9581 1.84652 20.0749C2.24633 20.1378 20.7541 20.1378 21.1539 20.0749C21.8502 19.9581 22.3309 19.4954 22.4612 18.8216C22.5241 18.4847 22.5241 11.7554 22.4567 11.5263C22.3983 11.3106 22.2006 11.1804 21.9311 11.1804C21.7649 11.1804 21.7065 11.2073 21.5807 11.3331L21.428 11.4858V14.9583C21.428 16.9259 21.41 18.5116 21.383 18.6149C21.3606 18.7228 21.2932 18.8351 21.2168 18.8935L21.0911 18.9968H11.5002H1.90941L1.78363 18.8935C1.70727 18.8351 1.63988 18.7228 1.61742 18.6149C1.59047 18.5116 1.5725 16.9259 1.5725 14.9583V11.4858L1.41977 11.3331C1.29398 11.2073 1.23559 11.1804 1.07387 11.1804C0.95707 11.1804 0.817812 11.2163 0.732461 11.2702Z"
				fill={color}
			/>
		</svg>
	);
};

export default Investment;
