import React, { useEffect, useState } from "react";
import "./index.scss";
import ProfilePicture from "../../../../../assets/images/user-profile-backsquare.png";
import locationMark from "../../../../../assets/images/icons/location.png";
import job from "../../../../../assets/images/icons/job.png";
import rated from "../../../../../assets/images/icons/rated.png";
import time from "../../../../../assets/images/icons/time.png";
import hours from "../../../../../assets/images/icons/hours.png";
import language from "../../../../../assets/images/icons/language.png";
import Button from "../../../../../components/button/index.js";
import star from "../../../../../assets/images/icons/star.png";
import share from "../../../../../assets/images/icons/share.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import editable from "../../../../../assets/images/editable.png";
import editablenoborder from "../../../../../assets/images/editablenoborder.png";
import Tab from "../../../../../components/Tab";
import { TabPanel } from "@mui/lab";
import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	ProfilesWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	TabPanelWrapper,
	ManageOtherProfile,
	ProfileModule,
	Butt,
} from "../../../styles/index.styled.js";
import { GetITFreelancerProfile } from "../../../../../utils/apiCalls.js";
import Loader from "../../../../../components/loader/index.js";
import { connect } from "react-redux";

const tabsConfig = [
	{ id: 1, label: "Completed jobs (102)" },
	{ id: 2, label: "In Progress (20)" },
];

const ITFreelancerPrivate = ({ setActiveModule, setActiveMBProfile }) => {
	const [freeLancerProfileData, setFreeLancerProfileData] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const location = useLocation();
	const userData = location.state?.userData;
	const navigate = useNavigate();
	const handlePublicView = () => {
		navigate("/it/freelancer/profile/public");
	};

	const getITFreeLancerProfile = () => {
		setIsLoader(true);
		if (userData) {
			const payload = {
				itProfileTypeId: userData?.itProfileTypeId || userData?.accountTypeId,
				itProfileId: userData?.itProfileId || userData?.moduleId,
			};
			GetITFreelancerProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setIsLoader(false);
					setFreeLancerProfileData(data);
				} else {
					setIsLoader(false);
					setFreeLancerProfileData();
				}
			}, payload);
		}
	};

	useEffect(() => {
		getITFreeLancerProfile();
	}, []);

	const [activeTab, setActiveTab] = useState(tabsConfig[0]?.id);

	const handleShortvideos = (freeLancerProfileData) => {
		navigate("/shorts/profile/private", { state: { user: freeLancerProfileData } });
	};

	const handleMBRedirection = (data) => {
		setActiveModule("it");
		setActiveMBProfile(data?.itProfileId);
		navigate("/microblogging/profile/private");
	};
	return (
		<>
			{token ? (
				isLoader ? (
					<Loader />
				) : (
					<Wrapper>
						<ProfileWrapper>
							<ProfileSections>
								<SectionTitle>Freelancer</SectionTitle>
								<Profile>
									<ProfileDetailsWrapper>
										<ProfileImageWrapper>
											<img src={freeLancerProfileData?.profilePicture || ProfilePicture} alt="profile" />
											<img src={editable} className="imgedit" alt="profile" />
										</ProfileImageWrapper>
										<UserDetailsWrapper>
											<UserNameWrapper>
												<UserName>{freeLancerProfileData?.fullName || "Anonymous User"}</UserName>
												<img
													src={editablenoborder}
													className="imgedit"
													alt="profile"
													style={{
														display: "block",
													}}
												/>
											</UserNameWrapper>
											<UserAddress>
												<img src={locationMark} alt="location-icon" />
												{freeLancerProfileData?.location || "No Location Available"} <br />
											</UserAddress>
											<div>
												<div className="itfreelancerprivate_profile_left_user_job">
													<div className="itfreelancerprivate_profile_left_user_job_left">
														<img src={job} alt="job-icon" />
														<p>100% Job Success</p>
													</div>
													<div className="itfreelancerprivate_profile_left_user_job_right">
														<img src={rated} alt="rated-icon" />
														<p>Top rated</p>
													</div>
												</div>
											</div>
											<UserProfileActions
												hideOnDesktop
												style={{
													marginBlockEnd: "14px",
												}}
											>
												<Button
													buttonClick={handlePublicView}
													styles={{
														width: "fit-content",
														backgroundColor: "#FCFDFF",
														color: "#6B6B6B",
														borderColor: "#C2C2C2",
													}}
												>
													See Public View
												</Button>
											</UserProfileActions>
										</UserDetailsWrapper>
									</ProfileDetailsWrapper>
									{/* To be visible on desktop only */}
									<UserProfileActions
										hideOnMobile
										style={{
											marginBlockEnd: "14px",
										}}
									>
										<Button
											buttonClick={handlePublicView}
											styles={{
												width: "fit-content",
												backgroundColor: "#FCFDFF",
												marginLeft: "auto",
												color: "#6B6B6B",
												borderColor: "#C2C2C2",
											}}
										>
											See Public View
										</Button>
									</UserProfileActions>
								</Profile>

								<MoreDetailWrapper>
									<ProfilesAndAboutWrapper>
										<ProfilesWrapper>
											<div className="itfreelancerprivate_details_left_container" style={{ width: "100%" }}>
												<div className="itfreelancerprivate_details_left_earnings">
													<div className="itfreelancerprivate_details_left_earnings_sec">
														<h5>$80K</h5>
														<p>Total Earnings</p>
													</div>
													<div className="itfreelancerprivate_details_left_earnings_sec">
														<h5>150</h5>
														<p>Total Jobs</p>
													</div>
													<div className="itfreelancerprivate_details_left_earnings_sec">
														<h5>8966</h5>
														<p>Total hours</p>
													</div>
												</div>
												<div className="itfreelancerprivate_details_left_hours">
													<div className="itfreelancerprivate_details_left_hours_heading">
														<div className="itfreelancerprivate_details_left_hours_heading_left">
															<h5>Hours per week</h5>
															<img src={hours} alt="hours-icon" />
														</div>

														<img src={editablenoborder} className="imgedit" alt="profile" />
													</div>
													<div className="itfreelancerprivate_details_left_hours_content">
														<h6>More than 30 hrs/week</h6>
														<h6>No contract-to-hire preference set</h6>
													</div>
												</div>
												<div className="itfreelancerprivate_details_left_language">
													<div className="itfreelancerprivate_details_left_language_heading">
														<div className="itfreelancerprivate_details_left_language_heading_left">
															<h5>Languages</h5>
															<img src={language} alt="hours-icon" />
														</div>
														<img src={editablenoborder} className="imgedit" alt="profile" />
													</div>
													<div className="itfreelancerprivate_details_left_hours_content">
														<h6>English: Native or Bilingual</h6>
													</div>
												</div>
											</div>
											<Profiles>
												<ProfileHeading>
													Other Profiles
													<ManageOtherProfile>Manage</ManageOtherProfile>
												</ProfileHeading>
												<ProfileModule>
													<Butt onClick={() => handleMBRedirection(freeLancerProfileData)}>
														Microblogging
													</Butt>
													<Butt onClick={() => handleShortvideos(freeLancerProfileData)}>
														ShortVideos
													</Butt>
												</ProfileModule>
												<ProfileList>
													{freeLancerProfileData?.userName ? (
														<ProfileName>
															<ProfileUserName>userName</ProfileUserName>
															{freeLancerProfileData?.userName}
														</ProfileName>
													) : (
														"No Data Available"
													)}

													{freeLancerProfileData?.shortVideoUsername && (
														<ProfileName>
															<ProfileUserName>shortVideoUsername</ProfileUserName>{" "}
															{freeLancerProfileData?.shortVideoUsername}
														</ProfileName>
													)}
												</ProfileList>
											</Profiles>
										</ProfilesWrapper>
										<About>
											<AboutTitle>
												<div className="itfreelancerprivate_details_right_heading" style={{ padding: 0 }}>
													<div className="itfreelancerprivate_details_right_heading_sec1">
														<div className="itfreelancerprivate_details_right_heading_left">
															<h3>
																{freeLancerProfileData?.skillList.length > 0
																	? freeLancerProfileData?.skillList[0]
																	: "No Skills Available"}
																&nbsp;{freeLancerProfileData?.skillList.length > 0 ? "," : ""}
																{freeLancerProfileData?.programmingSkillsList.length > 0
																	? freeLancerProfileData?.programmingSkillsList[0]
																	: ""}
																&nbsp;
																{freeLancerProfileData?.programmingSkillsList.length > 0
																	? ","
																	: ""}
																{freeLancerProfileData?.databaseSkillsList.length > 0
																	? freeLancerProfileData?.databaseSkillsList[0]
																	: ""}
																&nbsp;
															</h3>
														</div>
														<div className="itfreelancerprivate_details_right_heading_right">
															<div className="itfreelancerprivate_details_right_heading_right_sec1">
																<h3>$9/hr</h3>
																<img src={time} alt="time-icon" />
															</div>
														</div>
													</div>
													<img src={editablenoborder} className="imgedit" alt="profile" />
												</div>
											</AboutTitle>
											<AboutDesc
												noClamp
												style={{
													fontFamily: "Poppins",
													fontSize: "18px",
													fontStyle: "normal",
													fontWeight: 300,
													lineHeight: "normal",
												}}
											>
												{freeLancerProfileData?.summary || "No Summary"} <br />
												<br /> I am an expert in:
												<br />
												{freeLancerProfileData?.skillList.length > 0
													? freeLancerProfileData?.skillList.join(", ") + "."
													: "No Data Available"}
												<br />{" "}
												{freeLancerProfileData?.databaseSkillsList.length > 0
													? freeLancerProfileData?.databaseSkillsList.join(", ") + "."
													: ""}
												<br />
												{freeLancerProfileData?.programmingSkillsList.length > 0
													? freeLancerProfileData?.programmingSkillsList.join(", ") + "."
													: ""}
											</AboutDesc>
											<More>more...</More>

											<Tab onChange={setActiveTab} defaultActive={activeTab} tabs={tabsConfig}>
												<TabPanelWrapper>
													<TabPanel value={1}>
														{" "}
														<div className="itfreelancerprivate_details_right_history_data_sec">
															<div className="itfreelancerprivate_details_right_history_data_sec_heading">
																<h6>Wordpress Development</h6>
															</div>
															<div className="itfreelancerprivate_details_right_history_data_sec_content">
																<p>
																	He was quick, efficient & had a great eye for detail. She went
																	over & beyond. Highly recommend him."
																</p>
															</div>
															<div className="itfreelancerprivate_details_right_history_data_sec_details">
																<div className="itfreelancerprivate_details_right_history_data_sec_details_stars">
																	<img src={star} alt="star-icon" />
																	<img src={star} alt="star-icon" />
																	<img src={star} alt="star-icon" />
																	<img src={star} alt="star-icon" />
																	<img src={star} alt="star-icon" />
																</div>
																<p>Oct 18, 2018 - May 14, 2022</p>
																<img src={share} alt="share-icon" />
															</div>
														</div>
														<div className="itfreelancerprivate_details_right_history_data_sec">
															<div className="itfreelancerprivate_details_right_history_data_sec_heading">
																<h6>Software Engineering</h6>
															</div>
															<div className="itfreelancerprivate_details_right_history_data_sec_content">
																<p>
																	Excellent skills and good communication skills. Was always
																	able to give us an update on task and looking forward to
																	working with her for our project.
																</p>
															</div>
															<div className="itfreelancerprivate_details_right_history_data_sec_details">
																<div className="itfreelancerprivate_details_right_history_data_sec_details_stars">
																	<img src={star} alt="star-icon" />
																	<img src={star} alt="star-icon" />
																	<img src={star} alt="star-icon" />
																	<img src={star} alt="star-icon" />
																	<img src={star} alt="star-icon" />
																</div>
																<p>Oct 18, 2018 - May 14, 2022</p>
																<img src={share} alt="share-icon" />
															</div>
														</div>
													</TabPanel>
													<TabPanel value={2}>In Progress</TabPanel>
												</TabPanelWrapper>
											</Tab>
										</About>
									</ProfilesAndAboutWrapper>
								</MoreDetailWrapper>
							</ProfileSections>
						</ProfileWrapper>
					</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(ITFreelancerPrivate);
