import React from "react";
import { LeftBottom, LeftBottomContent, LeftBottomHead, LeftBottomSpan } from "../../../pages/MB/styles/index.styled";
import PrimaryBtn from "../../Buttons/Primary";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";

const moduleMapping = {
	it: "Information Technology",
	sv: "Short Videos",
	ag: "Agriculture",
	healthcare: "Healthcare",
	hospitality: "Hospitality",
};

const MBLeftOther = ({ activeProfile, visible, activeModule }) => {
	const navigate = useNavigate();
	const handleModuleNavigation = () => {
		navigate(-1, {
			state: {
				user: activeProfile,
			},
		});
	};
	return (
		<>
			{visible && (
				<LeftBottom>
					<div
						style={{
							display: "inline-flex",
							justifyContent: "center",
							width: "100%",
						}}
					>
						<PrimaryBtn
							onClick={handleModuleNavigation}
							label={moduleMapping[activeModule]}
							overrides={{
								width: "100%",
								padding: "4px 10px",
								fontSize: "12px",
								backgroundColor: "#256F61",
							}}
						/>
					</div>

					<LeftBottomHead>
						<h3>Other Profiles</h3>
					</LeftBottomHead>
					<LeftBottomContent>
						<p>
							Engineering <span>George Kajaia123</span>
						</p>
						<p>
							Education <span>George Kajaia456</span>
						</p>
						<p>
							Ecommerce <span>George Kajaia897</span>
						</p>
					</LeftBottomContent>
					<LeftBottomSpan>
						<span>Manage Links</span>
					</LeftBottomSpan>
				</LeftBottom>
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		activeModule: state?.mb?.active,
		activeProfile: state?.mb?.activeProfile,
	};
};

export default connect(mapStateToProps)(MBLeftOther);
