const SignUpForm = ({ onSubmit }) => {
	// const [email, setEmail] = useState("");
	// const [password, setPassword] = useState("");
	// const [confirmPassword, setConfirmPassword] = useState("");

	// const handleSubmit = (e) => {
	// 	e.preventDefault();
	// 	onSubmit({ email, password, confirmPassword });
	// };

	return (
		// <form onSubmit={handleSubmit}>
		// 	<input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" />
		// 	<input type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Password" />
		// 	<input
		// 		type="password"
		// 		value={confirmPassword}
		// 		onChange={(e) => setConfirmPassword(e.target.value)}
		// 		placeholder="Confirm Password"
		// 	/>
		// 	<button type="submit">Sign Up</button>
		// </form>
		<>Signup form</>
	);
};

export default SignUpForm;
