import React from "react";

const Magazine = ({ color = "#982323", size = 18 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 14 18" fill="none">
			<path
				d="M3.31982 8.76915H7.11982V6.33325H3.31982V8.76915ZM3.82649 6.82043H6.61316V8.28197H3.82649V6.82043Z"
				fill={color}
			/>
			<path
				d="M11.9333 9.74365H5.09326V12.6667H11.9333V9.74365ZM11.4266 12.1795H5.59993V10.2308H11.4266V12.1795Z"
				fill={color}
			/>
			<path d="M7.11982 14.616H3.31982V15.1031H7.11982V14.616Z" fill={color} />
			<path d="M7.11982 13.6414H3.31982V14.1285H7.11982V13.6414Z" fill={color} />
			<path d="M10.92 14.6152H8.1333V15.1024H10.92V14.6152Z" fill={color} />
			<path d="M11.9333 13.6414H8.1333V14.1285H11.9333V13.6414Z" fill={color} />
			<path d="M10.16 6.33325H8.1333V6.82043H10.16V6.33325Z" fill={color} />
			<path d="M11.9328 7.30762H9.65283V7.7948H11.9328V7.30762Z" fill={color} />
			<path d="M9.14663 7.30762H8.1333V7.7948H9.14663V7.30762Z" fill={color} />
			<path d="M11.9333 8.28198H8.1333V8.76916H11.9333V8.28198Z" fill={color} />
			<path d="M11.9337 6.33325H10.667V6.82043H11.9337V6.33325Z" fill={color} />
			<path d="M9.90649 2.43579H3.31982V2.92297H9.90649V2.43579Z" fill={color} />
			<path d="M8.13316 3.4104H3.31982V3.89758H8.13316V3.4104Z" fill={color} />
			<path
				d="M1.29312 16.0769C1.57255 16.0769 1.79979 16.2954 1.79979 16.5641H2.05312H2.30645H13.4531V0.974359H2.81312V1.46154H12.9465V16.0769H2.30645V0.974359C2.30645 0.437 1.85197 0 1.29312 0C0.734265 0 0.279785 0.437 0.279785 0.974359V16.5641C0.279785 17.1015 0.734265 17.5385 1.29312 17.5385H12.4398V17.0513H1.29312C1.01369 17.0513 0.786452 16.8328 0.786452 16.5641C0.786452 16.2954 1.01369 16.0769 1.29312 16.0769ZM0.786452 0.974359C0.786452 0.705679 1.01369 0.487179 1.29312 0.487179C1.57255 0.487179 1.79979 0.705679 1.79979 0.974359V15.7252C1.65007 15.6414 1.47856 15.5897 1.29312 15.5897C1.10768 15.5897 0.936172 15.6414 0.786452 15.7252V0.974359Z"
				fill={color}
			/>
		</svg>
	);
};

export default Magazine;
