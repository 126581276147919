import { useState, Fragment } from "react";
import { FormContainer, FormRowWrapper, CheckboxWrapper, CheckboxGroupTitle, Checkboxes } from "../../index.styled";
import { FormControlLabel, Checkbox } from "@mui/material";

const allSkills = [
	{
		id: 1,
		name: "Accommodation Providers",
		skills: [
			{
				id: 1,
				name: "Apartments",
			},
			{
				id: 2,
				name: "Houses",
			},
			{
				id: 3,
				name: "Unique stays for guests",
			},
		],
	},
	{
		id: 2,
		name: "Cultural and Educational Instructors",
		skills: [
			{
				id: 1,
				name: "Local Arts",
			},
			{
				id: 2,
				name: "Local Crafts",
			},
			{
				id: 3,
				name: "Local Language Teaching",
			},
			{
				id: 4,
				name: "Local Cultural Workshops",
			},
		],
	},
	{
		id: 3,
		name: "Tour and Experience Guides",
		skills: [
			{
				id: 1,
				name: "Local tours",
			},
			{
				id: 2,
				name: "Cultural Experiences",
			},
			{
				id: 3,
				name: "Adventure Activities",
			},
			{
				id: 4,
				name: "Specialized Educational Tours",
			},
			{
				id: 5,
				name: "Event and Entertainment Organizers",
			},
		],
	},
	{
		id: 4,
		name: "Hospitality Consultants",
		skills: [
			{
				id: 1,
				name: "Experts in Hospitality Management",
			},
			{
				id: 2,
				name: "Customer Service Training",
			},
			{
				id: 3,
				name: "Business Development for Smaller Hospitality Businesses",
			},
		],
	},
	{
		id: 5,
		name: "Wellness and Recreation Experts",
		skills: [
			{
				id: 1,
				name: "Yoga",
			},
			{
				id: 2,
				name: "Personal Fitness",
			},
			{
				id: 3,
				name: "Spa and Massage Therapies",
			},
			{
				id: 4,
				name: "Meditation Sessions",
			},
		],
	},
];

const renderSkills = (activeSkills, setActiveSkills) => {
	const rows = [];
	const handleCheckbox = (event, id) => {
		if (event.target.checked) {
			setActiveSkills([...activeSkills, id]);
		} else {
			setActiveSkills(activeSkills.filter((skill) => skill !== id));
		}
	};

	for (let i = 0; i < allSkills.length; i += 2) {
		rows.push(
			<FormRowWrapper key={allSkills[i]?.id}>
				<CheckboxWrapper>
					<CheckboxGroupTitle>{allSkills[i]?.name}</CheckboxGroupTitle>
					<Checkboxes>
						{allSkills[i]?.skills?.map((skill) => (
							<FormControlLabel
								control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
								label={skill?.name}
							/>
						))}
					</Checkboxes>
				</CheckboxWrapper>
				{i + 1 < allSkills.length && (
					<CheckboxWrapper>
						<CheckboxGroupTitle>{allSkills[i + 1]?.name}</CheckboxGroupTitle>
						<Checkboxes>
							{allSkills[i + 1]?.skills?.map((skill) => (
								<FormControlLabel
									control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
									label={skill?.name}
								/>
							))}
						</Checkboxes>
					</CheckboxWrapper>
				)}
			</FormRowWrapper>,
		);
	}

	return rows;
};

const FreelancerSkills = () => {
	const [activeSkills, setActiveSkills] = useState([]);
	return (
		<Fragment>
			<FormContainer>{renderSkills(activeSkills, setActiveSkills)}</FormContainer>
		</Fragment>
	);
};

export default FreelancerSkills;
