import React from "react";

const Healthcare = ({ color = "#C42121", size = 20 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 23 20" fill="none">
			<path
				d="M4.17683 9.94745C3.8293 9.13466 3.625 8.33696 3.625 7.58385C3.625 2.50125 9.28621 -0.981364 12.8991 2.24944C16.9958 -0.920053 22 2.50125 22 7.58385C22 11.1212 17.6011 15.6409 14.9257 18.0625"
				stroke={color}
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M14.9336 18.0625C14.2021 18.7012 13.3295 19.1658 13.3295 19.1658C13.3295 19.1658 8.48262 16.9956 5.74609 13.4688"
				stroke={color}
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M1 11.6403H8.87771L11.1471 6.25L14.176 12.8125L15.3294 7.68589L20.6875 7.69603"
				stroke={color}
				strokeMiterlimit="10"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Healthcare;
