import InputField from "../../../../Inputs/Input";
import Textarea from "../../../../Inputs/Textarea";
import { FormTitleInfo, FormRowWrapper, FormWrapper, FormTitle, Form, BtnWrapper, btnStyleOverrides } from "../index.styled";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";
import * as Yup from "yup";
import Autocomplete from "../../../../Inputs/Autocomplete";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import SecondaryBtn from "../../../../Buttons/Secondary";
import PrimaryBtn from "../../../../Buttons/Primary";

const validationSchema = Yup.object({
	firstName: Yup.string().required("First Name is required"),
	lastName: Yup.string().required("Last Name is required"),
	location: Yup.string().required("Location is required"),
	profilePicture: Yup.string().required("Profile Picture is required"),
});

const ClientSignUpForm = ({ setProfilePicture, onSubmit }) => {
	const navigate = useNavigate();
	const handleBack = () => {
		navigate(-1);
	};

	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			location: "",
			profilePicture: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onSubmit(values);
		},
	});

	return (
		<FormWrapper onSubmit={formik.handleSubmit} onBlur={formik.handleBlur} onChange={formik.handleChange}>
			<FormTitle>Provide Your Client Information</FormTitle>
			<Form>
				<FormTitleInfo>Personal Information</FormTitleInfo>
				<FormRowWrapper>
					<InputField
						name={"firstName"}
						label={"First Name"}
						required
						error={formik.touched.firstName && Boolean(formik.errors.firstName)}
						helperText={formik.touched.firstName && formik.errors.firstName}
					/>
					<InputField
						name={"lastName"}
						label={"Last Name"}
						required
						error={formik.touched.lastName && Boolean(formik.errors.lastName)}
						helperText={formik.touched.lastName && formik.errors.lastName}
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<Autocomplete
						name={"location"}
						label={"Location"}
						required
						error={formik.touched.location && Boolean(formik.errors.location)}
						helperText={formik.touched.location && formik.errors.location}
						onChange={formik.setFieldValue}
					/>
					<InputFieldWithUpload
						name={ "profilePicture" }
						onChange={setProfilePicture}
						label={"Profile Picture"}
						required
						error={ formik.touched.profilePicture && Boolean(formik.errors.profilePicture) }
						helperText={ formik.touched.profilePicture && formik.errors.profilePicture }
					/>
				</FormRowWrapper>
				<FormRowWrapper childCount={1}>
					<Textarea
						name={"about"}
						label={"About"}
						rows={4}
						mobileRows={10}
						placeholder={"A detailed professional overview highlighting skills and experience"}
					/>
				</FormRowWrapper>
			</Form>
			<BtnWrapper>
				<SecondaryBtn label={"Back"} onClick={handleBack} overrides={btnStyleOverrides} />
				<PrimaryBtn label={"Next"} overrides={btnStyleOverrides} />
			</BtnWrapper>
		</FormWrapper>
	);
};

export default ClientSignUpForm;
