const Index = ({ color = "#929292", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
			<path
				d="M6.12402 4C5.4875 4 4.87705 4.25754 4.42697 4.71596C3.97688 5.17438 3.72402 5.79614 3.72402 6.44444V13.7778H0.124023L4.92402 18.6667L9.72402 13.7778H6.12402V6.44444H14.524L16.924 4H6.12402ZM18.124 8.88889H14.524L19.324 4L24.124 8.88889H20.524V16.2222C20.524 16.8705 20.2712 17.4923 19.8211 17.9507C19.371 18.4091 18.7605 18.6667 18.124 18.6667H7.32402L9.72402 16.2222H18.124V8.88889Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
