import { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import { Collapse } from "@mui/material";
import {
	ActiveModule,
	Category,
	CategoryIconWrapper,
	CollapsibleIcons,
	LabelWrapper,
	LogoWrapper,
	SubCategory,
	SubListWrapper,
	Wrapper,
} from "./index.styled";
import Buzzgalactic from "../../assets/icons/buzzgalactic/logo.svg";
import Home from "../../assets/icons/Home";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Education from "../../assets/icons/education/education";
import Agriculture from "../../assets/icons/agriculture/agriculture";
import Creativity from "../../assets/icons/creativity/creativity";
import HealthCare from "../../assets/icons/healthcare/healthcare";
import Hospitality from "../../assets/icons/hospitality/hospitality";
import Environment from "../../assets/icons/environment/environment";
import Entertainment from "../../assets/icons/entertainment/entertainment";
import Services from "../../assets/icons/services/services";
import Investment from "../../assets/icons/investment/investment";
import Transport from "../../assets/icons/transportation/transportation";
import IT from "../../assets/icons/it/it";
import Bazaar from "../../assets/icons/bazaar/bazaar";
import UsedProducts from "../../assets/icons/products/products";
import RealEstate from "../../assets/icons/realEstate/realEstate";
import UsedCars from "../../assets/icons/usedCars/usedCars";
import ShortVideos from "../../assets/icons/shorts/shortVideos";
import SocialJournalist from "../../assets/icons/socialJournalist/socialJornalist";
import PublicControl from "../../assets/icons/publicControl/publicControl";
import MicroBlogging from "../../assets/icons/microBlogging/microBlogging";
import Dating from "../../assets/icons/dating/dating";
import Magazine from "../../assets/icons/magazine/magazine";
import TV from "../../assets/icons/tv/tv";
import { useNavigate } from "react-router-dom";

const menuList = [
	{
		label: "Services",
		subItems: [
			{
				label: "Education",
				link: "/education/home",
				icon: <Education />,
			},
			{
				label: "Agriculture",
				link: "/agriculture/home",
				icon: <Agriculture />,
			},
			{
				label: "Transport",
				link: "/transportation",
				icon: <Transport />,
			},
			{
				label: "Creativity",
				link: "/creativity",
				icon: <Creativity />,
			},
			{
				label: "Information Tech",
				link: "/it/home",
				icon: <IT />,
			},
			{
				label: "Healthcare",
				link: "/healthcare/home",
				icon: <HealthCare />,
			},
			{
				label: "Hospitality",
				link: "/hospitality/home",
				icon: <Hospitality />,
			},
			{
				label: "Environment",
				link: "/environment",
				icon: <Environment />,
			},
			{
				label: "Entertainment",
				link: "/entertainment",
				icon: <Entertainment />,
			},
			{
				label: "Professional Services",
				link: "/services",
				icon: <Services />,
			},
		],
	},
	{
		label: "Products",
		subItems: [
			{
				label: "Multi-Source Bazaar",
				link: "/home",
				icon: <Bazaar />,
			},
			{
				label: "Used Products",
				link: "/home",
				icon: <UsedProducts />,
			},
			{
				label: "Real Estate",
				link: "/home",
				icon: <RealEstate />,
			},
			{
				label: "Used Cards",
				link: "/home",
				icon: <UsedCars />,
			},
		],
	},
	{
		label: "Finance",
		subItems: [
			{
				label: "Investment",
				link: "/investment",
				icon: <Investment />,
			},
		],
	},
	{
		label: "Social Interactions",
		subItems: [
			{
				label: "Short Videos",
				link: "/shorts/feed",
				icon: <ShortVideos />,
			},
			{
				label: "Social Journalist",
				link: "/journalist/home",
				icon: <SocialJournalist />,
			},
			{
				label: "Public Control",
				link: "/pcontrol/home",
				icon: <PublicControl />,
			},
			{
				label: "Microblogging",
				link: "/microblogging",
				icon: <MicroBlogging />,
			},
			{
				label: "Dating",
				link: "/dating/home",
				icon: <Dating />,
			},
		],
	},
	{
		label: "Media & Entertainment",
		subItems: [
			{
				label: "Magazine",
				link: "/mg/home",
				icon: <Magazine />,
			},
			{
				label: "TV",
				link: "/tv/home",
				icon: <TV />,
			},
		],
	},
];

const moduleIconMappings = {
	main: <Home />,
	it: <IT />,
	mb: <MicroBlogging />,
	shorts: <ShortVideos />,
	ag: <Agriculture />,
	tv: <TV />,
	sj: <SocialJournalist />,
	mg: <Magazine />,
	dating: <Dating />,
	healthcare: <HealthCare />,
	hospitality: <Hospitality />,
	pcontrol: <PublicControl />,
};

const moduleNameMappings = {
	main: "Main",
	it: "Information Tech",
	mb: "Microblogging",
	shorts: "Short Videos",
	ag: "Agriculture",
	tv: "TV",
	sj: "Social Journalist",
	mg: "Magazine",
	dating: "Dating",
	healthcare: "Healthcare",
	hospitality: "Hospitality",
	pcontrol: "Public Control",
};

const Menu = ({ module = "main", color = undefined, anchor = "right" }) => {
	const [isDrawerOpen, setIsDrawerOpen] = useState(false);

	const toggleDrawer = () => {
		setIsDrawerOpen((isOpen) => !isOpen);
	};

	const MenuItems = ({ toggleDrawer }) => (
		<Box sx={{ width: "-webkit-fill-available", px: 1 }} role="presentation">
			<List
				sx={{
					py: 0,
				}}
			>
				<ListItem
					sx={{
						".MuiListItemIcon-root": {
							minWidth: "auto",
							cursor: "pointer",
						},
						justifyContent: "space-between",
						paddingInline: "0",
						marginInline: "8px",
						width: "calc(100% - 16px)",
					}}
				>
					<LogoWrapper>
						<img src={Buzzgalactic} alt="logo" />
						<ActiveModule>
							{moduleIconMappings[module]}
							{moduleNameMappings[module]}
						</ActiveModule>
					</LogoWrapper>
					<ListItemIcon onClick={toggleDrawer}>
						<CloseIcon />
					</ListItemIcon>
				</ListItem>
			</List>
			<List>
				{menuList.map(({ label, subItems }, index) => (
					<Categories key={index} label={label} subCategories={subItems} toggleDrawer={toggleDrawer} />
				))}
			</List>
		</Box>
	);

	const Categories = ({ label, subCategories, toggleDrawer }) => {
		const [isExpanded, setIsExpanded] = useState(true);
		const navigate = useNavigate();

		const toggleCategory = () => {
			setIsExpanded((isExpanded) => !isExpanded);
		};

		const handleNav = (link) => {
			toggleDrawer();
			navigate(link);
		};

		return (
			<Wrapper>
				<LabelWrapper>
					<Category>{label}</Category>
					<CollapsibleIcons>
						{!isExpanded ? <AddIcon onClick={toggleCategory} /> : <RemoveIcon onClick={toggleCategory} />}
					</CollapsibleIcons>
				</LabelWrapper>
				<Collapse in={isExpanded}>
					<SubListWrapper>
						{subCategories.map(({ label, icon, link }, index) => (
							<SubCategory onClick={() => handleNav(link)}>
								<CategoryIconWrapper>{icon}</CategoryIconWrapper>
								{label}
							</SubCategory>
						))}
					</SubListWrapper>
				</Collapse>
			</Wrapper>
		);
	};

	return (
		<Fragment>
			<span
				onClick={toggleDrawer}
				style={{
					cursor: "pointer",
				}}
			>
				<MenuIcon
					sx={{
						color: color || "#7B8180",
					}}
				/>
			</span>
			<Drawer
				anchor={anchor}
				sx={{
					".MuiPaper-root": {
						width: "100dvw",
					},
				}}
				open={isDrawerOpen}
				onClose={toggleDrawer}
			>
				<MenuItems toggleDrawer={toggleDrawer} />
			</Drawer>
		</Fragment>
	);
};

export default Menu;
