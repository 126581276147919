import { Route, Routes, useLocation } from "react-router-dom";
import Heading from "../components/Heading/Heading";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import TV from "../assets/icons/tv/tv";
import Home from "../pages/TV/Home";
import Profile from "../pages/TV/Profile";
import Notifications from "../pages/TV/Notifications";
import Search from "../pages/TV/Search";
import BottomNav from "../components/TV/BottomNav";
import useLayout from "../utils/hooks/useLayout";

const TVRoutes = ({ userData, setActiveModule, setSignUpPopupToggle, signUpPopup }) => {
	const location = useLocation();
	const { pathname } = location;
	const [isTv, setIsTv] = useState(false);
	const isMobile = useLayout();

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path?.includes("tv") && !path.includes("microblogging") && !path.includes("signup") && path?.length > 1) {
			setActiveModule("tv");
			setIsTv(true);
		} else setIsTv(false);
	}, [pathname]);

	return (
		<>
			{isTv && !userData?.isLoading && (
				<>
					<Heading
						icon={TV}
						title={"TV"}
						moduleHomeRedirect={"/tv/home"}
						user={userData?.firstName || "George Kajaia"}
					/>
					{isMobile && <BottomNav />}
				</>
			)}
			<Routes>
				<Route path="/tv">
					{/* <Route index element={<Signup />} /> */}
					<Route path="home" element={<Home />} />
					<Route path="profile" element={<Profile />} />
					<Route path="notifications" element={<Notifications />} />
					<Route path="search" element={<Search />} />
				</Route>
			</Routes>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state.tv?.profile,
		signUpPopup: state.tv?.signUpPopup,
	};
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MODULE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_TV",
			payload: data,
		}),
});
export default connect(mapStateToProps, mapPropsToState)(TVRoutes);
