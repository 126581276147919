import React from "react";
import location from "../../../../../assets/images/icons/location.png";
import { Navigate, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/IT/itprofileclient.png";
import editable from "../../../../../assets/images/editable.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";
import message from "../../../../../assets/icons/mi_message.png";
import outline from "../../../../../assets/icons/material-symbols_rate-review-outline.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfilesWrapper,
} from "../../../styles/index.styled.js";

const ClientPublic = () => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const handlePublicProfileNav = (path) => {
		return;
		navigate(`/${path}`);
	};

	return (
		<>
			{token ? (
				<Wrapper>
					<ProfileWrapper>
						<ProfileSections>
							<SectionTitle>Client</SectionTitle>
							<Profile>
								<ProfileDetailsWrapper>
									<ProfileImageWrapper>
										<img src={ProfilePicture} alt="profile" />
										<img src={editable} className="imgedit" alt="profile" />
									</ProfileImageWrapper>
									<UserDetailsWrapper>
										<UserNameWrapper>
											<UserName>Thomas Mark</UserName>
										</UserNameWrapper>
										<UserAddress>
											<img src={location} alt="location-icon" />
											United States, India - 10:46 am local time
										</UserAddress>
									</UserDetailsWrapper>
								</ProfileDetailsWrapper>
								<UserProfileActions>
									<Button buttonClick={() => handlePublicProfileNav("message")}>
										<img src={message} alt="message" />
										Message
									</Button>
									<Button buttonClick={() => handlePublicProfileNav("review")}>
										<img src={outline} alt="review" />
										Review
									</Button>
								</UserProfileActions>
							</Profile>

							<MoreDetailWrapper>
								<ProfilesAndAboutWrapper>
									<ProfilesWrapper>
										<Profiles fillAvailable>
											<ProfileHeading>Other Profiles</ProfileHeading>
											<ProfileList>
												<ProfileName>
													<ProfileUserName>Engineering</ProfileUserName> George Kajaia123
												</ProfileName>
												<ProfileName>
													<ProfileUserName>Education</ProfileUserName> George Kajaia456
												</ProfileName>
												<ProfileName>
													<ProfileUserName>Ecommerce</ProfileUserName> George Kajaia897
												</ProfileName>
											</ProfileList>
										</Profiles>
									</ProfilesWrapper>
									<About>
										<AboutTitle>About</AboutTitle>
										<AboutDesc>
											Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
											exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
											dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
											ExceLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
											exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
											dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
											ExceLorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
											incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
											exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
											dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
											Exce
										</AboutDesc>
										<More>more...</More>
									</About>
								</ProfilesAndAboutWrapper>
								<FeedbackWrapper>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment1}
											commentimagestyle={{
												marginLeft: "2rem",
											}}
											commenttitle="Thanks"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment}
											commenttitle="Reply"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
								</FeedbackWrapper>
							</MoreDetailWrapper>
						</ProfileSections>
					</ProfileWrapper>
				</Wrapper>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default ClientPublic;
