import React, { useContext, useState } from "react";
import "./SearchComponent.scss";
import profile from "../../assets/icons/user-profile.png";
import crossicon from "../../assets/icons/cross-icon.png";
import image from "../../assets/images/imageupload.png";
import { NavLink } from "react-router-dom";
import { UserContext } from "../../store/UserDetailsStore";
import axios from "axios";
import ImageCropDialog from "../../components/ImageEditor/ImageCropDialog";

const SearchComponent = ({ isPopupVisible, setPopupVisibility, setPostListAll, getAllPostData }) => {
	const [] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [selectedFileURL, setSelectedFileURL] = useState(null);
	const [postContent, setPostContent] = useState({
		post: "",
	});
	const { isLoginUserDetails } = useContext(UserContext);
	const [cropDialogVisible, setCropDialogVisible] = useState(false);
	const [imageToCrop, setImageToCrop] = useState(null);

	const openFileDialog = () => {
		document.getElementById("fileInput").click();
	};

	const handleFileSelect = (event) => {
		const file = event.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				setImageToCrop(e.target.result); // Set image to crop
				setCropDialogVisible(true); // Open crop dialog
			};
			reader.readAsDataURL(file);
		}
	};

	const handleCroppedImage = async (croppedImageUrl) => {
		try {
			// Convert the cropped image URL to a Blob
			const response = await fetch(croppedImageUrl);
			const blob = await response.blob();

			// Create a new File object
			const file = new File([blob], "cropped-image.jpg", {
				type: "image/jpeg",
			});

			// Update your state to hold the cropped image as File
			setSelectedFile(file);
			setSelectedFileURL(croppedImageUrl); // Save the cropped image URL for preview

			// If you want to close the crop dialog
			setCropDialogVisible(false);
		} catch (error) {
			console.error("Error converting cropped image URL to File:", error);
		}
	};

	const handlePost = async () => {
		const token = localStorage.getItem("token") || sessionStorage.getItem("token");
		if (token) {
			// setPostListAll([]);
			try {
				const formData = new FormData();
				formData.append("post", postContent?.post);
				formData.append("file_upload", selectedFile);
				const queryParams = [`content=${encodeURIComponent(postContent?.post)}`];
				const queryString = queryParams ? `?${queryParams}` : "";
				const response = await axios.post(
					`https://api.buzzgalactic.com/api/v1/posts/createPost${queryString}`,
					formData,
					{
						headers: {
							"Content-Type": "multipart/form-data",
							authorization: "Bearer " + [token],
						},
					},
				);
				const { status, message, data } = response.data;
				if (status === 200) {
					handleClear();
					setPopupVisibility(false);
					// getAllPostData();
				}
			} catch (error) {
				console.error(error);
			}
		}
	};

	const togglePopup = () => {
		handleClear();
		setPopupVisibility(!isPopupVisible);
		document.body.style.overflow = isPopupVisible ? "auto" : "scroll";
	};

	const handlePostChange = (e) => {
		const { name, value } = e.target;
		setPostContent({
			...postContent,
			[name]: value,
		});
		const textarea = e.target;
		textarea.style.height = "auto";
		textarea.style.height = `${textarea.scrollHeight}px`;
	};

	const handleClear = () => {
		setSelectedFile(null);
		setSelectedFileURL(null);
		setPostContent({
			post: "",
		});
	};

	return (
		<>
			{isPopupVisible && (
				<div className="searchpopup" onClick={togglePopup}>
					<div className="searchpopup_containers" onClick={(e) => e.stopPropagation()}>
						<div className="searchpopup_mainhead">
							<p>Create Post</p>
							<img src={crossicon} className="close-button" onClick={togglePopup} alt="Close Popup" />
						</div>
						<div className="searchpopup_george">
							<div className="Edit">
								{isLoginUserDetails?.profilePictureUrl ? (
									<img src={isLoginUserDetails?.profilePictureUrl} alt="profile" />
								) : (
									<img src={profile} alt="profile" />
								)}

								{isLoginUserDetails?.name ? <p>{isLoginUserDetails?.name}</p> : <p>Anonymous User</p>}
							</div>
							<textarea
								name="post"
								rows={1}
								value={postContent.post}
								onChange={(e) => {
									handlePostChange(e);
								}}
								className="hemant"
								placeholder={`What's on your mind ${isLoginUserDetails?.name} ?`}
							/>
						</div>
						<div className="searchpopup_postbox">
							<input type="text" className="add" placeholder="Add to your post" disabled />
							<div className="searchpopup_postbox_picture" onClick={openFileDialog}>
								<img src={image} alt="" />
								<input type="file" id="fileInput" style={{ display: "none" }} onChange={handleFileSelect} />
							</div>
						</div>
						<div className="selected-file">{selectedFileURL && <img src={selectedFileURL} alt="Cropped" />}</div>
						<div className="searchpopup_postbutton">
							<button onClick={handlePost}>Post</button>
						</div>
					</div>
				</div>
			)}

			{cropDialogVisible && (
				<div className="cropper-modal">
					<div className="cropper-modal_container">
						<ImageCropDialog
							id={selectedFile?.name} // Use file name as a temporary ID
							imageUrl={imageToCrop}
							// Provide initial values for crop, zoom, and aspect
							cropInit={{ x: 0, y: 0 }}
							zoomInit={1}
							aspectInit={4 / 3}
							onCancel={() => setCropDialogVisible(false)}
							setCroppedImageFor={(id, crop, zoom, aspect, croppedImageUrl) => handleCroppedImage(croppedImageUrl)}
							resetImage={() => setImageToCrop(null)}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default SearchComponent;
