const initialUserState = {
	agriculture: { data: null, isLoading: false, error: null },
	signUpPopup: false,
	profiles: [],
};

const agricultureReducer = (state = initialUserState, action) => {
	switch (action.type) {
		case "UPDATE_AG_USER_DATA":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					data: action?.payload,
				},
			};
			break;
		case "UPDATE_AG_ALL_PROFILES":
			state = {
				...state,
				profiles: action?.payload,
			};
			break;
		case "SET_SIGNUP_TOGGLE_AG":
			state = {
				...state,
				signUpPopup: action?.payload || false,
			};
			break;
		default:
			break;
	}
	return state;
};

export default agricultureReducer;
