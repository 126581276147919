const Index = ({ size = 18 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 17 17" fill="none">
			<path
				d="M13.0306 3.26935C11.7863 1.31077 9.57334 0 7.04682 0C3.1611 0 0 3.09085 0 6.89022C0 8.3722 0.485559 9.81047 1.37347 10.981L0.0527782 13.9122C-0.0416016 14.1216 -0.00745103 14.3651 0.140949 14.5424C0.260165 14.6844 0.437748 14.7646 0.620919 14.7646C0.665626 14.7646 0.710332 14.7597 0.755038 14.7506L4.34582 13.9729C5.96393 15.5648 8.30604 16.314 10.6159 15.9017L15.6242 16.986C15.6689 16.9958 15.7142 17 15.7583 17C15.9421 17 16.1191 16.9205 16.2383 16.7778C16.3867 16.6005 16.4202 16.3577 16.3265 16.1476L15.0058 13.2164C15.8943 12.0459 16.3792 10.6076 16.3792 9.12564C16.3792 6.74147 15.0964 4.51577 13.03 3.26874L13.0306 3.26935ZM5.61684 12.4533L1.68393 13.3051L2.66126 11.137C2.75626 10.9258 2.72087 10.6805 2.56999 10.5032C1.71436 9.50084 1.24308 8.21739 1.24308 6.89022C1.24184 3.76051 3.84598 1.21424 7.04619 1.21424C10.2464 1.21424 12.8512 3.76051 12.8512 6.89022C12.8512 10.0199 10.247 12.5656 7.04619 12.5656C6.6575 12.5656 6.27563 12.5279 5.87949 12.4508C5.79566 12.4344 5.70066 12.4357 5.61684 12.4533ZM13.7198 13.3731L14.6965 15.5411L10.7636 14.6893C10.6792 14.6711 10.5836 14.6699 10.4997 14.6869C8.84313 15.0111 7.18714 14.617 5.89315 13.6827C6.27998 13.7446 6.6606 13.7798 7.04682 13.7798C10.9325 13.7798 14.0936 10.689 14.0936 6.89022C14.0936 6.49802 14.052 6.11614 13.9875 5.74154C14.7195 6.70687 15.1386 7.89075 15.1386 9.12564C15.1386 10.4534 14.6674 11.7363 13.8117 12.7386C13.6602 12.9159 13.6248 13.1612 13.7205 13.3725L13.7198 13.3731Z"
				fill="white"
			/>
			<path
				d="M3.96516 7.48597C4.30808 7.48597 4.58608 7.21415 4.58608 6.87885C4.58608 6.54355 4.30808 6.27173 3.96516 6.27173C3.62223 6.27173 3.34424 6.54355 3.34424 6.87885C3.34424 7.21415 3.62223 7.48597 3.96516 7.48597Z"
				fill="white"
			/>
			<path
				d="M7.00813 7.48597C7.35105 7.48597 7.62905 7.21415 7.62905 6.87885C7.62905 6.54355 7.35105 6.27173 7.00813 6.27173C6.6652 6.27173 6.38721 6.54355 6.38721 6.87885C6.38721 7.21415 6.6652 7.48597 7.00813 7.48597Z"
				fill="white"
			/>
			<path
				d="M10.0506 7.48597C10.3935 7.48597 10.6715 7.21415 10.6715 6.87885C10.6715 6.54355 10.3935 6.27173 10.0506 6.27173C9.70768 6.27173 9.42969 6.54355 9.42969 6.87885C9.42969 7.21415 9.70768 7.48597 10.0506 7.48597Z"
				fill="white"
			/>
		</svg>
	);
};

export default Index;
