const Index = ({ size = 20 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 24 24" fill="none">
			<path
				d="M9.29976 22L8.89976 18.8C8.68309 18.7167 8.47876 18.6167 8.28676 18.5C8.09476 18.3833 7.90742 18.2583 7.72476 18.125L4.74976 19.375L1.99976 14.625L4.57476 12.675C4.55809 12.5583 4.54976 12.4457 4.54976 12.337V11.663C4.54976 11.5543 4.55809 11.4417 4.57476 11.325L1.99976 9.375L4.74976 4.625L7.72476 5.875C7.90809 5.74167 8.09976 5.61667 8.29976 5.5C8.49976 5.38333 8.69976 5.28333 8.89976 5.2L9.29976 2H14.7998L15.1998 5.2C15.4164 5.28333 15.6208 5.38333 15.8128 5.5C16.0048 5.61667 16.1921 5.74167 16.3748 5.875L19.3498 4.625L22.0998 9.375L19.5248 11.325C19.5414 11.4417 19.5498 11.5543 19.5498 11.663V12.337C19.5498 12.4457 19.5331 12.5583 19.4998 12.675L22.0748 14.625L19.3248 19.375L16.3748 18.125C16.1914 18.2583 15.9998 18.3833 15.7998 18.5C15.5998 18.6167 15.3998 18.7167 15.1998 18.8L14.7998 22H9.29976ZM11.0498 20H13.0248L13.3748 17.35C13.8914 17.2167 14.3708 17.0207 14.8128 16.762C15.2548 16.5033 15.6588 16.191 16.0248 15.825L18.4998 16.85L19.4748 15.15L17.3248 13.525C17.4081 13.2917 17.4664 13.046 17.4998 12.788C17.5331 12.53 17.5498 12.2673 17.5498 12C17.5498 11.7333 17.5331 11.471 17.4998 11.213C17.4664 10.955 17.4081 10.709 17.3248 10.475L19.4748 8.85L18.4998 7.15L16.0248 8.2C15.6581 7.81667 15.2541 7.496 14.8128 7.238C14.3714 6.98 13.8921 6.784 13.3748 6.65L13.0498 4H11.0748L10.7248 6.65C10.2081 6.78333 9.72909 6.97933 9.28776 7.238C8.84642 7.49667 8.44209 7.809 8.07476 8.175L5.59976 7.15L4.62476 8.85L6.77476 10.45C6.69142 10.7 6.63309 10.95 6.59976 11.2C6.56642 11.45 6.54976 11.7167 6.54976 12C6.54976 12.2667 6.56642 12.525 6.59976 12.775C6.63309 13.025 6.69142 13.275 6.77476 13.525L4.62476 15.15L5.59976 16.85L8.07476 15.8C8.44142 16.1833 8.84576 16.5043 9.28776 16.763C9.72976 17.0217 10.2088 17.2173 10.7248 17.35L11.0498 20Z"
				fill="#5E6B86"
			/>
			<circle cx="12.5" cy="12.5" r="2.5" fill="#D9D9D9" />
		</svg>
	);
};

export default Index;
