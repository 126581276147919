import Header from "../../components/Header";
import styled from "@emotion/styled";
import Background from "../../assets/images/login/background.svg";
import MobileBackground from "../../assets/images/verify/mobile/background.svg";

const VerifyEmail = () => {
	return (
		<Wrapper>
			<Header hideButton />
			<FormContainer>
				<SubWrapper>
					<Title>Verify your Email</Title>
					<Description>
						We have sent an Email to your registered email id, Please check your Email and confirm your account
					</Description>
				</SubWrapper>
			</FormContainer>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	height: 100dvh;

	background-image: url(${Background});
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;

	@media screen and (max-width: 768px) {
		background-image: url(${MobileBackground});
	}
`;

const FormContainer = styled.div`
	display: grid;
	grid-template-columns: repeat(12, 1fr);
	align-items: center;
	height: -webkit-fill-available;
`;

const SubWrapper = styled.div`
	grid-column: 4 / span 6;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width: 100%;
	/* height: 100%; */
	background-color: red;
	width: 40dvw;
	margin: auto;

	background: #fff;
	box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.25);
	border-radius: 7px;

	padding: 20px;

	@media screen and (max-width: 768px) {
		width: auto;
		padding: 10px;
  	}
`;

const Title = styled.h1`
	color: #000;
	font-family: Poppins;
	font-size: 24px;
	font-style: normal;
	font-weight: 400;
	line-height: 128%; /* 46.08px */

	padding-block-end: 12px;
	text-align: center;
	width: 100%;

	border-bottom: 1px solid #dedede;

	@media screen and (max-width: 768px) {
		font-size: 24px;
	}
`;

const Description = styled.p`
	width: 80%;
	margin-block-start: 16px;

	color: #000;
	text-align: center;
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 148%; /* 23.68px */
`;

export default VerifyEmail;
