import styled from "@emotion/styled";
import Backgorund1 from "../../assets/images/landing/background1.svg";
import MobileBackgorund1 from "../../assets/images/landing/mobile/background1.svg";
import Backgorund3 from "../../assets/images/landing/background3.svg";
import MobileBackgorund3 from "../../assets/images/landing/mobile/background3.svg";
import Backgorund4 from "../../assets/images/landing/background4.svg";
import MobileBackgorund4 from "../../assets/images/landing/mobile/background4.svg";
import { borderRadius, color } from "../../styles/variables";

const MainWrapperSection1 = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100dvh;
	background-image: url(${Backgorund1});
	background-repeat: no-repeat;
	background-position: top;

	@media screen and (max-width: 768px) {
		background-image: url(${MobileBackgorund1});
		background-size: contain;
		min-height: initial;
	}
`;

const MainSubWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	gap: 72px;
	max-width: 75dvw;

	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		max-width: initial;
		margin-inline: 24px;
		row-gap: 0px;
	}
`;

const MainSubWrapperLeft = styled.div`
	grid-column: span 1;
	display: flex;
	flex-direction: column;
	padding-block: 36px;

	@media screen and (max-width: 768px) {
		padding-block: 30px;
	}
`;

const MainTitle = styled.h1`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 30px;
	font-style: normal;
	font-weight: 600;
	line-height: 117.3%; /* 64.515px */

	margin-block-end: 24px;

	@media screen and (max-width: 768px) {
		margin-block-end: 10px;
	}
`;

const MainDesc = styled.p`
	color: #232323;
	font-family: Lato;
	font-size: 20px;
	font-style: normal;
	font-weight: 300;
	line-height: 134.3%; /* 26.86px */

	margin-block-end: 56px;

	@media screen and (max-width: 768px) {
		margin-block-end: 0;
	}
`;

const MainNavBtn = styled.button`
	width: fit-content;
	background: #256f61;
	border-radius: 5px;
	border: 1px solid transparent;
	padding: 10px 36px;
	color: ${color("pWhite")};
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;

	&:hover {
		color: #256f61;
		background-color: ${color("pWhite")};
		border-color: #256f61;
	}
`;

const MainSubWrapperRight = styled.div`
	grid-column: span 1;
	display: flex;
	flex-direction: column;
`;

const MainSubWrapperRightImg = styled.img`
	width: 85%;
	margin-inline: auto;

	@media screen and (max-width: 768px) {
		width: 100%;
	}
`;

const MainSectionInfo = styled.div`
	grid-column: span 2;
	width: 85%;
	margin: 0 auto;
	border-radius: 6px;
	background: #2d7d6f;
	padding: 10px 26px;
	color: ${color("pWhite")};
	text-align: center;
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 144%; /* 28.8px */

	@media screen and (max-width: 768px) {
		width: initial;
		margin-block-start: 36px;
	}
`;

const MainWrapperSection2 = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100dvh;

	@media screen and (max-width: 768px) {
		min-height: initial;
		margin-inline: 24px;
		margin-block-start: 48px;
	}
`;

const MainSection2Title = styled.h2`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: 151.5%; /* 54.54px */

	@media screen and (max-width: 768px) {
		margin-inline: 24px;
		text-align: center;
	}
`;

const MainSection2Info = styled.p`
	color: #827979;
	font-family: Poppins;
	font-size: 20px;
	font-style: normal;
	font-weight: 400;
	line-height: 151.5%; /* 30.3px */

	@media screen and (max-width: 768px) {
		margin-inline: 36px;
		text-align: center;
	}
`;

const MainSection2 = styled.div`
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	column-gap: 64px;
	row-gap: 32px;
	max-width: 75dvw;
	padding-block: 36px;

	@media screen and (max-width: 768px) {
		max-width: initial;
		grid-template-columns: repeat(1, 1fr);
		margin-inline: 48px;
		row-gap: 46px;
	}
`;

const Section2Card = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 10px;

	color: #232323;
	text-align: center;
	font-family: Proxima Nova;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 146.5%; /* 23.44px */

	@media screen and (max-width: 768px) {
		row-gap: 16px;
	}
`;

const MainSectionSection2Join = styled.div`
	display: flex;
	border-radius: 5px;
	border: 1px solid #a08c8c;
	position: relative;
	display: flex;
	justify-content: space-between;
	align-items: center;
	column-gap: 24px;
	padding-inline-start: 10px;
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	& > button {
		position: relative;
		height: 105%;
		right: -1px;
		top: 0;
		bottom: -1px;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column;
		padding: 0;

		border: none;
		width: 85%;

		row-gap: 4px;

		& > span {
			display: inline-block;
			width: -webkit-fill-available;
			border: 1px solid #a08c8c;
			padding: 10px 16px;
			text-align: center;
			border-radius: ${borderRadius(5)};
		}

		& > button {
			padding: 8px 16px;
			width: 100%;
		}
	}
`;

const MainWrapperSection3 = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100dvh;
	background-image: url(${Backgorund3});
	background-repeat: no-repeat;
	background-position: top;
	background-size: 65%;
	background-position-x: 0;

	@media screen and (max-width: 768px) {
		min-height: initial;
		background-size: contain;
		background-image: url(${MobileBackgorund3});
		background-position-y: 20vh;
		padding-bottom:50px;
	}
`;	

const MainSectionSubWrapper3 = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 24px;
	max-width: 75dvw;
	padding-block: 36px;

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		max-width: initial;
	}
`;

const MainSection3Left = styled.div`
	grid-column: span 1;
	display: flex;
	flex-direction: column;
`;

const MainSection3LeftImg = styled.img`
	width: 100%;
`;

const MainSection3Right = styled.div`
	grid-column: span 1;
	display: flex;
	flex-direction: column;
	row-gap: 38px;
`;

const MainSection3Title = styled.h2`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%; /* 39.6px */

	@media screen and (max-width: 768px) {
		margin-inline: 0;
		text-align: center;
	}
`;

const InnovativeSolutionsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 20px;

	@media screen and (max-width: 768px) {
		margin-inline: 36px;
	}
`;

const InnovativeSolutions = styled.div`
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	column-gap: 36px;
	& > svg {
		fill: #256f61;
	}
`;

const InnovativeSolutionsInfo = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 8px;
`;

const InnovativeSolutionsTitle = styled.h3`
	color: #6f6d6d;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: 110%; /* 19.8px */
`;

const InnovativeSolutionsDesc = styled.p`
	color: #000;
	font-family: Proxima Nova;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 124%; /* 19.84px */
`;

const MainWrapperSection4 = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	min-height: 100dvh;
	background-image: url(${Backgorund4});
	background-repeat: no-repeat;
	background-position: top;

	box-shadow: 0px 34px 44px 0px rgba(0, 0, 0, 0.07);

	@media screen and (max-width: 768px) {
		background-image: url(${MobileBackgorund4});
		background-size: contain;
	}
`;

const MainSection4Title = styled.h2`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: 151.5%; /* 54.54px */

	@media screen and (max-width: 768px) {
		margin-inline: 24px;
		text-align: center;
	}
`;

const MainSectionSubWrapper4 = styled.div`
	max-width: 75dvw;
	padding-block: 36px;

	display: flex;
	flex-direction: column;
	align-items: center;
	row-gap: 74px;
`;

const Section4CardWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	column-gap: 24px;
	row-gap: 16px;

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		row-gap: 32px;
	}
`;

const Sections4Card = styled.div`
	grid-column: span 1;
	display: flex;
	flex-direction: row;
	align-items: flex-start;
	row-gap: 10px;
	column-gap: 34px;

	@media screen and (max-width: 768px) {
		flex-direction: column;
		align-items: center;

		row-gap: 24px;
		margin-inline: 24px;
		text-align: center;
	}
`;

const Section4CardIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
`;

const Section4CardTitle = styled.h3`
	color: #6f6d6d;
	font-family: Poppins;
	font-size: 19px;
	font-style: normal;
	font-weight: 500;
	line-height: 126%; /* 23.94px */

	@media screen and (max-width: 768px) {
		width: -webkit-fill-available;
	}
`;

const Section4CardDesc = styled.h3`
	color: #000;
	font-family: Proxima Nova;
	font-size: 16px;
	font-style: normal;
	font-weight: 300;
	line-height: 124%; /* 19.84px */

	@media screen and (max-width: 768px) {
		width: -webkit-fill-available;
	}
`;

const Section4CardInfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	row-gap: 10px;

	@media screen and (max-width: 768px) {
		row-gap: 16px;
	}
`;

const MainWrapperSection5 = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const MainSectionSubWrapper5 = styled.div`
	max-width: 75dvw;
	padding-block: 48px;

	display: grid;
	grid-template-columns: repeat(5, 1fr);
	align-items: center;

	@media screen and (max-width: 768px) {
		grid-template-columns: repeat(1, 1fr);
		max-width: initial;
		margin-inline: 24px;
		row-gap: 24px;
	}
`;

const MainSection5Left = styled.div`
	grid-column: span 2;
	display: flex;
	flex-direction: column;

	@media screen and (max-width: 768px) {
		grid-row: 2;

		row-gap: 36px;
		align-items: center;
	}
`;

const MainSection5LeftImg = styled.video`
	width: 100%;
`;

const MainSection5Right = styled.div`
	grid-column: span 3;
	display: flex;
	flex-direction: column;
	row-gap: 36px;
	justify-content: center;
	margin-inline-start: 48px;

	@media screen and (max-width: 768px) {
		grid-row: 1;
		margin-inline: 24px;
	}
`;

const MainSection5Title = styled.h2`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 36px;
	font-style: normal;
	font-weight: 500;
	line-height: 45px; /* 125% */

	@media screen and (max-width: 768px) {
		text-align: center;
	}
`;

const MainSection5Desc = styled.h2`
	color: ${color("pBlack")};
	font-family: Poppins;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: 24px; /* 150% */

	@media screen and (max-width: 768px) {
		text-align: center;
	}
`;

export {
	MainWrapperSection1,
	MainSubWrapper,
	MainSubWrapperLeft,
	MainTitle,
	MainDesc,
	MainNavBtn,
	MainSubWrapperRight,
	MainSubWrapperRightImg,
	MainSectionInfo,
	MainWrapperSection2,
	MainSection2Title,
	MainSection2Info,
	MainSectionSection2Join,
	MainSection2,
	Section2Card,
	MainWrapperSection3,
	MainSectionSubWrapper3,
	MainSection3Left,
	MainSection3LeftImg,
	MainSection3Right,
	MainSection3Title,
	InnovativeSolutionsWrapper,
	InnovativeSolutions,
	InnovativeSolutionsInfo,
	InnovativeSolutionsTitle,
	InnovativeSolutionsDesc,
	MainWrapperSection4,
	MainSectionSubWrapper4,
	MainSection4Title,
	Section4CardWrapper,
	Sections4Card,
	Section4CardTitle,
	Section4CardDesc,
	Section4CardInfoWrapper,
	Section4CardIcon,
	MainWrapperSection5,
	MainSectionSubWrapper5,
	MainSection5Left,
	MainSection5LeftImg,
	MainSection5Right,
	MainSection5Title,
	MainSection5Desc,
};
