import { Box, Card, Input } from "@mui/material";
import { borderRadius } from "../../../styles/variables";
import { UserContext } from "../../../store/UserDetailsStore";
import { useContext, useState } from "react";
import profile from "../../../assets/icons/user-profile.png";
import styled from "@emotion/styled";
import SearchComponent from "../../SearchComponent/SearchComponent";

const PostDialog = () => {
	const { isLoginUserDetails } = useContext(UserContext);
	const handlePostModal = () => {
		setPopupVisibility((props) => !props);
	};
	const [isPopupVisible, setPopupVisibility] = useState(false);
	return (
		<Box>
			<Card
				elevation={6}
				sx={{ p: "12px", borderRadius: borderRadius(8), display: "flex", flexDirection: "column", rowGap: "16px" }}
			>
				<Box display={"flex"} alignItems={"center"} columnGap={"12px"}>
					<UserImg src={isLoginUserDetails?.profilePictureUrl || profile} alt="user-profile-picture" />
					<Input
						fullWidth
						placeholder={`What's on your mind ${isLoginUserDetails?.name}`}
						onClick={handlePostModal}
						value={""}
					/>
				</Box>
			</Card>
			<SearchComponent isPopupVisible={isPopupVisible} setPopupVisibility={handlePostModal} />
		</Box>
	);
};

const UserImg = styled.img`
	border-radius: 50%;
	width: 56px;
	height: 56px;
`;

export default PostDialog;
