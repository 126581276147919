import { useState, Fragment } from "react";
import { FormContainer, FormRowWrapper, CheckboxWrapper, CheckboxGroupTitle, Checkboxes } from "../../index.styled";
import { FormControlLabel, Checkbox } from "@mui/material";

const allSkills = [
	{
		id: 1,
		name: "Models",
		skills: [
			{
				id: 1,
				name: "Fashion",
			},
			{
				id: 2,
				name: "Runaway",
			},
			{
				id: 3,
				name: "Commercial",
			},
			{
				id: 4,
				name: "Fitness",
			},
			{
				id: 5,
				name: "Glamour",
			},
			{
				id: 6,
				name: "Parts Modeling",
			},
		],
	},
	{
		id: 2,
		name: "Film Makers and Videographers",
		skills: [
			{
				id: 1,
				name: "Directors",
			},
			{
				id: 2,
				name: "Cinematographers",
			},
			{
				id: 3,
				name: "Editors",
			},
		],
	},
	{
		id: 3,
		name: "Photographers",
		skills: [
			{
				id: 1,
				name: "Fashion",
			},
			{
				id: 2,
				name: "Portrait",
			},
			{
				id: 3,
				name: "Commercial",
			},
			{
				id: 4,
				name: "Wedding",
			},
			{
				id: 5,
				name: "Artistic",
			},
		],
	},
	{
		id: 4,
		name: "Body Painters and Special Effects Artists",
		skills: [
			{
				id: 1,
				name: "Body painting",
			},
			{
				id: 2,
				name: "Special effects makeup",
			},
		],
	},
	{
		id: 5,
		name: "Makeup Artists and Stylists",
		skills: [
			{
				id: 1,
				name: "Makeup",
			},
			{
				id: 2,
				name: "Hair styling",
			},
			{
				id: 3,
				name: "Wardrobe",
			},
		],
	},
	{
		id: 6,
		name: "Artists and Illustrators",
		skills: [
			{
				id: 1,
				name: "Drawing",
			},
			{
				id: 2,
				name: "Painting",
			},
		],
	},
	{
		id: 7,
		name: "Fashion",
		skills: [
			{
				id: 1,
				name: "Fashion Designers",
			},
		],
	},
];

const renderSkills = (activeSkills, setActiveSkills) => {
	const rows = [];
	const handleCheckbox = (event, id) => {
		if (event.target.checked) {
			setActiveSkills([...activeSkills, id]);
		} else {
			setActiveSkills(activeSkills.filter((skill) => skill !== id));
		}
	};

	for (let i = 0; i < allSkills.length; i += 2) {
		rows.push(
			<FormRowWrapper key={allSkills[i]?.id}>
				<CheckboxWrapper>
					<CheckboxGroupTitle>{allSkills[i]?.name}</CheckboxGroupTitle>
					<Checkboxes>
						{allSkills[i]?.skills?.map((skill) => (
							<FormControlLabel
								control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
								label={skill?.name}
							/>
						))}
					</Checkboxes>
				</CheckboxWrapper>
				{i + 1 < allSkills.length && (
					<CheckboxWrapper>
						<CheckboxGroupTitle>{allSkills[i + 1]?.name}</CheckboxGroupTitle>
						<Checkboxes>
							{allSkills[i + 1]?.skills?.map((skill) => (
								<FormControlLabel
									control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
									label={skill?.name}
								/>
							))}
						</Checkboxes>
					</CheckboxWrapper>
				)}
			</FormRowWrapper>,
		);
	}

	return rows;
};

const FreelancerSkills = () => {
	const [activeSkills, setActiveSkills] = useState([]);
	return (
		<Fragment>
			<FormContainer>{renderSkills(activeSkills, setActiveSkills)}</FormContainer>
		</Fragment>
	);
};

export default FreelancerSkills;
