import styled from "@emotion/styled";
import { color } from "../../../styles/variables";

const Name = styled.h3`
	color: #000;

	/* font-weight-bold-xl */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const SubTitle = styled.h4`
	color: #676464;

	/* font-weight-medium-xl */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	margin-bottom: 22px;
`;

const Info = styled.span`
	color: #676464;

	& span {
		color: #000;
	}

	/* Body-text-md */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const IconWrapper = styled.div`
	background-color: ${color("pGreen")};
	width: max-content;

	display: flex;
	align-items: center;
	justify-content: center;

	border-radius: 50%;
	padding: 10px;
	cursor: pointer;

	@media screen and (max-width: 768px) {
		position: absolute;
		right: 12px;
	}
`;

export { Name, Info, IconWrapper, SubTitle };
