import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Heading from "../components/Heading/Heading";
import Home from "../pages/Dating/Home";
import Signup from "../pages/Dating/Signup";
import SubHeader from "../components/Dating/Header";
import Dating from "../assets/icons/dating/dating";
import Profile from "../pages/Dating/Profile";

const DatingRoutes = ({ userData, setActiveModule, setSignUpPopupToggle, signUpPopup }) => {
	const location = useLocation();
	const { pathname } = location;
	const [isDating, setIsDating] = useState(false);

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path?.includes("dating") && !path.includes("microblogging") && !path.includes("signup") && path?.length > 1) {
			setActiveModule("dating");
			setIsDating(true);
		} else setIsDating(false);
	}, [pathname]);

	return (
		<>
			{isDating && !userData?.isLoading && (
				<>
					<Heading
						icon={Dating}
						title={"Dating"}
						moduleHomeRedirect={"/dating/home"}
						user={userData?.data?.fullName || "George Kajaia"}
					/>
					<SubHeader />
				</>
			)}
			<Routes>
				<Route path="dating">
					<Route index element={<Signup />} />
					<Route path="home" element={<Home />} />
					<Route path="profile" element={<Profile />} />
				</Route>
			</Routes>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state?.dating?.profile,
		signUpPopup: state.dating?.signUpPopup,
	};
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MODULE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_DATING",
			payload: data,
		}),
});
export default connect(mapStateToProps, mapPropsToState)(DatingRoutes);
