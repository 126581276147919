const Index = ({ color = "#fff", size = 24 }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g clip-path="url(#clip0_8166_522)">
				<path
					d="M11.4 1H13.6V6.5H11.4V1ZM11.4 17.5H13.6V23H11.4V17.5ZM23.5 10.9V13.1H18V10.9H23.5ZM7 10.9V13.1H1.5V10.9H7ZM19.5004 3.4442L21.0558 4.9996L17.1662 8.8892L15.6108 7.3338L19.5004 3.4442ZM7.8338 15.1108L9.3892 16.6662L5.4996 20.5558L3.9442 19.0004L7.8338 15.1108ZM21.0558 19.0004L19.5004 20.5558L15.6108 16.6662L17.1662 15.1108L21.0558 19.0004ZM9.3892 7.3338L7.8338 8.8892L3.9442 4.9996L5.4996 3.4442L9.3892 7.3338Z"
					fill={color}
				/>
			</g>
			<defs>
				<clipPath id="clip0_8166_522">
					<rect width="22" height="22" fill="white" transform="translate(1.5 1)" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Index;
