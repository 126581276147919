import React, { useState, useEffect } from "react";
import locationMark from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/user-profile-backsquare.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";
import message from "../../../../../assets/icons/mi_message.png";
import outline from "../../../../../assets/icons/material-symbols_rate-review-outline.png";
import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";
import { GetITRegularEmployeeProfile } from "../../../../../utils/apiCalls.js";
import Loader from "../../../../../components/loader/index.js";
import { connect } from "react-redux";
import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserWorkLocationAndSkills,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	EducationHistory,
	EducationTitle,
	Education,
	SkillsWrapper,
	SkillsTitle,
	Skills,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfileModule,
	Butt,
} from "../../../styles/index.styled.js";

const EmployeePublic = ({ setActiveModule, setActiveMBProfile }) => {
	const [regularEmployeeProfileData, SetRegularEmployeeProfileData] = useState();
	const [isLoader, setIsLoader] = useState(false);
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const location = useLocation();
	const userData = location.state?.user;
	const navigate = useNavigate();
	const handlePublicProfileNav = (path) => {
		return;
		navigate(`/${path}`);
	};

	const getITRegularEmployeeProfile = () => {
		if (userData) {
			setIsLoader(true);
			const payload = {
				itProfileTypeId: userData?.employeeId || userData?.accountTypeId,
				itProfileId: userData?.itProfileId || userData?.moduleId,
			};
			GetITRegularEmployeeProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setIsLoader(false);
					SetRegularEmployeeProfileData(data);
				} else {
					setIsLoader(false);
					SetRegularEmployeeProfileData();
				}
			}, payload);
		}
	};

	useEffect(() => {
		getITRegularEmployeeProfile();
	}, []);

	const handleMBRedirection = (data) => {
		setActiveModule("it");
		setActiveMBProfile(data?.itProfileId);
		navigate("/microblogging/profile/public");
	};

	const handleShortvideos = (regularEmployeeProfileData) => {
		navigate("/shorts/profile/public", { state: { user: regularEmployeeProfileData } });
	};
	return (
		<>
			{token ? (
				isLoader ? (
					<Loader />
				) : (
					<Wrapper>
						<ProfileWrapper>
							<ProfileSections>
								<SectionTitle>Regular Employee</SectionTitle>
								<Profile>
									<ProfileDetailsWrapper>
										<ProfileImageWrapper>
											<img
												src={regularEmployeeProfileData?.profilePicture || ProfilePicture}
												alt="profile"
											/>
										</ProfileImageWrapper>
										<UserDetailsWrapper>
											<UserNameWrapper>
												<UserName>{regularEmployeeProfileData?.fullName || "Anonymous User"}</UserName>
											</UserNameWrapper>
											<UserAddress>
												<img src={locationMark} alt="location-icon" />
												{regularEmployeeProfileData?.location || "No Location Available"} <br />
												{regularEmployeeProfileData?.jobTitle || "No Data Available"}&nbsp;in&nbsp;
												{regularEmployeeProfileData?.companyName}
												<br />
												{regularEmployeeProfileData?.industrySector}
											</UserAddress>
										</UserDetailsWrapper>
									</ProfileDetailsWrapper>
									<UserProfileActions>
										<Button buttonClick={() => handlePublicProfileNav("message")}>
											<img src={message} alt="message" />
											Message
										</Button>
										<Button buttonClick={() => handlePublicProfileNav("review")}>
											<img src={outline} alt="review" />
											Review
										</Button>
									</UserProfileActions>
								</Profile>
								<MoreDetailWrapper>
									<ProfilesAndAboutWrapper>
										<Profiles>
											<ProfileHeading>Other Profiles</ProfileHeading>
											<ProfileModule>
												<Butt onClick={() => handleMBRedirection(regularEmployeeProfileData)}>
													Microblogging
												</Butt>
												<Butt onClick={() => handleShortvideos(regularEmployeeProfileData)}>
													ShortVideos
												</Butt>
											</ProfileModule>
											<ProfileList>
												{regularEmployeeProfileData?.userName ? (
													<ProfileName>
														<ProfileUserName>Username</ProfileUserName>
														{regularEmployeeProfileData?.userName}
													</ProfileName>
												) : (
													"No Data available"
												)}
												{regularEmployeeProfileData?.shortVideoUsername && (
													<ProfileName>
														<ProfileUserName>shortVideoUsername</ProfileUserName>
														{regularEmployeeProfileData?.shortVideoUsername}
													</ProfileName>
												)}
											</ProfileList>
										</Profiles>
										<About>
											<AboutTitle>About</AboutTitle>
											<AboutDesc>{regularEmployeeProfileData?.about || "No Data available"}</AboutDesc>
											<More>more...</More>
											<EducationHistory>
												<EducationTitle>Education History</EducationTitle>
												<Education>
													<span>{regularEmployeeProfileData?.educationDegree}</span>
													<span>{regularEmployeeProfileData?.educationInstitution}</span>
													<span>{regularEmployeeProfileData?.gradYear}</span>
												</Education>
											</EducationHistory>
											<SkillsWrapper>
												<SkillsTitle>Skills</SkillsTitle>
												<Skills>{regularEmployeeProfileData?.skill || "No Data available"}</Skills>
											</SkillsWrapper>
										</About>
									</ProfilesAndAboutWrapper>
									<FeedbackWrapper>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment1}
												commentimagestyle={{
													marginLeft: "2rem",
												}}
												commenttitle="Thanks"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
										<Feedback>
											<ReviewComponent
												heading={"Education"}
												name={"George"}
												commentimage={comment}
												commenttitle="Reply"
												reviewcomponentstyle={reviewComponentStyles}
											/>
										</Feedback>
									</FeedbackWrapper>
								</MoreDetailWrapper>
							</ProfileSections>
						</ProfileWrapper>
					</Wrapper>
				)
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(EmployeePublic);
