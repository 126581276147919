import React, { useContext, useEffect, useRef, useState } from "react";
import "./PostComponent.scss";
import smile from "../../assets/images/profile/smile.png";
import smileb from "../../assets/images/profile/smileb.png";
import postArrow from "../../assets/images/post-arrow.png";
import defaultprofile from "../../assets/icons/user-profile.png";
import close from "../../assets/images/rightclose.png";
import comment from "../../assets/images/profile/comment.png";
import share from "../../assets/images/profile/share.png";
import { GetAllComments, LikePost, PostComment } from "../../utils/apiCalls";
import { UserContext } from "../../store/UserDetailsStore";
import { useNavigate } from "react-router-dom";

const PostComponent = ({ image, heading, name, profile, content, Comments, likes, postid, currentUserLikeStatus, userId }) => {
	const [commentText, setCommentText] = useState({
		text: "",
	});
	const navigate = useNavigate();
	const [likedPost, setLikedPost] = useState(likes);
	const [activeLikes, setActiveLikes] = useState(currentUserLikeStatus);
	const inputRef = useRef();
	const [commentCount, setCommentCount] = useState(Comments);
	const [comments, setComments] = useState([]);
	const { isLoginUserDetails } = useContext(UserContext);
	const [isViewMore, setIsViewMore] = useState(true);

	const handleClear = () => {
		setCommentText({
			text: "",
		});
	};

	const handleLikes = (id) => {
		const payload = {
			postId: id,
		};
		LikePost((res) => {
			const { message, data, status } = res;
			if (status === 200) {
				setActiveLikes(!activeLikes);
				setLikedPost(data);
			}
		}, payload);
	};

  const handlePostChange = (e) => {
    const { name, value } = e.target;
    setCommentText({
      ...commentText,
      [name]: value,
    });
    const textarea = e.target;
    textarea.style.height = "auto";
    textarea.style.height = `${textarea.scrollHeight - 5}px`;
  };

	const handleComment = () => {
		inputRef.current.focus();
	};

	const handlePostComment = (id) => {
		if (id) {
			const payload = {
				postId: id,
				text: commentText?.text,
			};
			PostComment((res) => {
				const { status, message, data } = res;
				if (status === 201) {
					handleClear();
					inputRef.current.focus();
					setCommentCount(data);
					getAllComments(id);
				}
			}, payload);
		}
	};

	const getAllComments = (postid) => {
		if (postid) {
			const payload = {
				postId: postid,
			};
			GetAllComments((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setComments(data);
				}
			}, payload);
		}
	};

	useEffect(() => {
		getAllComments(postid);
	}, [postid]);

	const handleViewMore = () => {
		setIsViewMore(!isViewMore);
	};

	const handleFriendProfile = (id) => {
		sessionStorage?.setItem("searchId", id);
		navigate("/publicprofile");
	};

  return (
    <div className="post">
      <div className="post_container">
        <div className="post_mobile">
          <div className="post_heading">
            <div className="post_heading_left">
              <img
                src={profile || defaultprofile}
                alt="profile"
                onClick={() => {
                  handleFriendProfile(userId);
                }}
              />
              <div className="post_heading_left_user">
                <div className="post_heading_left_user_name">
                  <h6>{name}</h6>
                </div>
                <div className="post_heading_left_user_date">
                  <p>{heading}</p>
                  <span>9 oct 2023</span>
                </div>
              </div>
            </div>
            <div className="post_heading_right">
              <img src={close} alt="close-icon" />
            </div>
          </div>
          <div className="post_content">
            <p>{content}</p>
          </div>
          <div className="post_comments">
            <div className="post_comments_image">
              {image ? <img src={image} alt="post" /> : <></>}
            </div>
          </div>
        </div>
        <div className="post_comments_current">
          <div className="post_comments_current_count">
            <img src={smileb} alt="smile" />
            <p>{likedPost}</p>
          </div>
          <p>{commentCount} Comments</p>
        </div>
        <div className="post_bottom">
          <div
            className="post_bottom_like"
            onClick={() => {
              handleLikes(postid);
            }}
          >
            {activeLikes ? (
              <img src={smileb} alt="smile" />
            ) : (
              <img src={smile} alt="smile" />
            )}
            <p className={activeLikes ? "activelike" : "inactivelike"}>Like</p>
          </div>
          <div className="post_bottom_comment" onClick={handleComment}>
            <img src={comment} alt="comment" />
            <p>Comment</p>
          </div>
          <div className="post_bottom_share">
            <img src={share} alt="share" />
            <p>Share</p>
          </div>
        </div>
        <div className="post_comment">
          {commentCount > 1 ? (
            <span className="view" onClick={handleViewMore}>
              {isViewMore ? " view more comments" : " view less comments"}
            </span>
          ) : (
            <></>
          )}
          {isViewMore ? (
            <div>
              {comments.slice(-1)?.map((data, ind) => {
                return (
                  <div className="post_comment_data" key={ind}>
                    <div className="post_comment_data_left">
                      <img
                        src={data?.profilePicture || defaultprofile}
                        alt="profile"
                      />
                    </div>
                    <div className="post_comment_data_right">
                      <div className="post_comment_data_right_name">
                        <p>{data?.fullName}</p>
                      </div>
                      <div className="post_comment_data_right_comment">
                        <p>{data?.Comment}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className="allComments">
              {comments?.map((data, ind) => {
                return (
                  <div className="post_comment_data" key={ind}>
                    <div className="post_comment_data_left">
                      <img
                        src={data?.profilePicture || defaultprofile}
                        alt="profile"
                      />
                    </div>
                    <div className="post_comment_data_right">
                      <div className="post_comment_data_right_name">
                        <p>{data?.fullName}</p>
                      </div>
                      <div className="post_comment_data_right_comment">
                        <span>{data?.Comment}</span>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}

          <div className="post_comment_section">
            <div className="post_comment_section_left">
              <img
                src={isLoginUserDetails?.profilePictureUrl || defaultprofile}
                alt="profile"
              />
            </div>
            <div className="post_comment_section_right">
              <div className="post_comment_section_right_content">
                <textarea
                  name="text"
                  ref={inputRef}
                  rows={1}
                  value={commentText.text}
                  onChange={(e) => {
                    handlePostChange(e);
                  }}
                  className="hemant"
                  placeholder="Write a comment"
                  style={{ overflow: "hidden", resize: "none", }}
                />
              </div>
              <div className="post_comment_section_right_likes">
                <img src={smile} alt="smile" />
                <img
                  src={postArrow}
                  alt="post"
                  onClick={() => {
                    handlePostComment(postid);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

};

export default PostComponent;
