import styled from "@emotion/styled";
import { borderRadius, color, spacing, toRem } from "../../../styles/variables";

const MobileWrapper = styled.div`
	height: 100%;
	position: relative;
`;

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	column-gap: ${spacing("md2")};
`;

const UserAvatarWrapper = styled.div`
	position: relative;
`;

const UserAvatar = styled.img`
	border-radius: 50%;
	height: 56px;
	aspect-ratio: 1;

	@media screen and (max-width: 768px) {
		height: 82px;
	}
`;

const SubWrapper = styled.div`
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-column-gap: ${spacing("lg")};
	grid-row-gap: ${spacing("xl1")};
`;

const InfoWrapper = styled.div`
	grid-column: span 6;
	display: grid;
	grid-template-columns: repeat(6, 1fr);
	grid-column-gap: ${spacing("lg")};
	justify-content: space-between;
	align-items: flex-start;
	min-height: 56px;

	& > button {
		grid-column: span 2 / 7;
		width: fit-content;
		margin-inline-start: auto;
		padding-inline: ${spacing("md1")};
		padding-block: ${spacing("xs")};
		margin-bottom: auto;
		font-family: Inter;
		font-size: ${toRem(20)};
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		text-transform: none;
		border-radius: ${borderRadius(30)};
	}
`;

const UserName = styled.span`
	grid-column: span 2 / 3;
	color: ${color("grey")};
	font-family: Inter;
	font-size: ${toRem(20)};
	font-style: normal;
	font-weight: 600;
	line-height: normal;
`;

const RewardsWrapper = styled.div`
	grid-column: span 2 / 5;
	margin-top: auto;
	margin-left: auto;
`;

const RewardsButton = styled.button`
	cursor: pointer;
	border-radius: ${borderRadius(40)};
	border: 1px solid ${color("pinkFaded")};
	color: ${color("grayish")};
	padding-inline: ${spacing("md3")};
	padding-block: ${spacing("sm")};
	font-family: Inter;
	font-size: ${toRem(15)};
	font-style: normal;
	font-weight: 500;
	line-height: normal;

	@media screen and (max-width: 768px) {
		color: ${color("grayishFaded")};
		background: rgba(0, 0, 0, 0.34);
		border: none;

		position: absolute;
		top: 15%;
		right: 2%;
	}
`;

const FeedImageWrapper = styled.div`
	grid-row: 2;
	grid-column: span 4;
`;

const FeedImage = styled.img`
	width: 100%;
	border-radius: ${borderRadius(8)};
`;

const AnalyticsWrapper = styled.div`
	grid-row: 2;
	grid-column: span 2;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: ${spacing("md3")};
	row-gap: ${spacing("md3")};
`;

const Analytic = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	row-gap: ${spacing("sm")};
`;

const AnalyticIcon = styled.span`
	cursor: pointer;
	border-radius: 50%;
	padding: ${spacing("md1")};
	background-color: ${color("sWhite")};

	display: flex;
	align-items: center;
	justify-content: center;

	img {
		height: 36px;
	}
`;

const AnalyticInfo = styled.span`
	color: ${color("pBlack")};
	font-family: Inter;
	font-size: ${toRem(16)};
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const UserActionSection = styled.div`
	position: absolute;
	right: 0;
	bottom: 0;
	height: max-content;

	display: flex;
	flex-direction: column;
	row-gap: ${spacing("xl")};
	margin-block-end: ${spacing("xl")};
	margin-inline-end: ${spacing("sm")};
`;

const PlusIconWrapper = styled.div`
	background-color: ${color("pGreen")};
	border-radius: 50%;
	padding: ${spacing("sm")};

	position: absolute;
	bottom: -${spacing("md")};
	display: flex;
	left: 50%;
	transform: translateX(-50%);

	color: ${color("pWhite")};
`;

const LikeWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("sm1")};
	align-items: center;

	color: ${color("pWhite")};
	font-family: Inter;
	font-size: ${toRem(20)};
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const CommentsWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("sm1")};
	align-items: center;

	color: ${color("pWhite")};
	font-family: Inter;
	font-size: ${toRem(20)};
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const PlayerWrapper = styled.div`
	height: 100%;
`;
const Preview = styled.div`
	height: 100%;
`;
const PreviewImage = styled.img`
	height: 100%;
	width: 100%;
	object-fit: cover;
`;

const PlayButtonWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-35%, -50%);
`;

export {
	MobileWrapper,
	Wrapper,
	UserAvatarWrapper,
	UserAvatar,
	SubWrapper,
	InfoWrapper,
	UserName,
	RewardsWrapper,
	RewardsButton,
	FeedImageWrapper,
	FeedImage,
	AnalyticsWrapper,
	Analytic,
	AnalyticIcon,
	AnalyticInfo,
	UserActionSection,
	PlusIconWrapper,
	LikeWrapper,
	CommentsWrapper,
	PlayerWrapper,
	Preview,
	PreviewImage,
	PlayButtonWrapper,
};
