import React from "react";
import "./EngineeringSignUp.scss";
import Heading from "../../components/Heading/Heading";
import icon from "../../assets/icons/engineering.png";
import client from "../../assets/icons/client (1).png";
import freelencer from "../../assets/icons/client (2).png";
import user from "../../assets/icons/client (3).png";
import { RadioButtonCheckedRounded } from "@mui/icons-material";
import { FormControlLabel, Radio } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

const EngineeringSignUp = () => {
	const navigate = useNavigate();
	const handleNav = () => {
		navigate("/engineering-signup2");
	};
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");

	return (
		<>
			{token ? (
				<div className="engineeringsignup">
					<Heading icon={icon} title={"Engineering"} showSearchInput={false} />
					<div className="engineeringsignup_container">
						<div className="engineeringsignup_container_content">
							<div className="engineeringsignup_container_content_top">
								<button>
									<span>Join as a client , </span>
									<span>freelancer or guest</span>
								</button>
							</div>
							<div className="engineeringsignup_container_content_middle">
								<div className="engineeringsignup_container_content_middle_client">
									<div className="engineeringsignup_container_content_middle_client_header">
										<img src={client} alt="" />
										<Radio />
									</div>
									<div className="engineeringsignup_container_content_middle_client_details">
										<p>I'm a client, hiring</p>
										<p>for a project</p>
									</div>
								</div>
								<div className="engineeringsignup_container_content_middle_client">
									<div className="engineeringsignup_container_content_middle_client_header">
										<img src={freelencer} alt="" />
										<Radio />
									</div>
									<div className="engineeringsignup_container_content_middle_client_details">
										<p>I'm a client, hiring</p>
										<p>for a project</p>
									</div>
								</div>
								<div className="engineeringsignup_container_content_middle_client">
									<div className="engineeringsignup_container_content_middle_client_header">
										<img src={user} alt="" />
										<Radio />
									</div>
									<div className="engineeringsignup_container_content_middle_client_details">
										<p>I'm a client, hiring</p>
										<p>for a project</p>
									</div>
								</div>
							</div>
							<div className="engineeringsignup_container_content_bottom">
								<button onClick={handleNav}>
									<span>Create Profile</span>
								</button>
							</div>
						</div>
					</div>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default EngineeringSignUp;
