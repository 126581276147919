import { InfoWrapper, Name, ProfileImage, SearchContent, SearchWrapper, Wrapper } from "./index.styled";
import ProfilePicture from "../../../assets/images/Educationprofile.png";
import PrimaryBtn from "../../Buttons/Primary";
import Input from "../../../components/Inputs/Input";
import { Fragment } from "react";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const searchData = [
	{
		name: "Michelle",
		content:
			"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
	},
	{
		name: "Peter Smith",
		content: "Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus Fattu",
	},
	{
		name: "David Beckham",
		content:
			"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
	},
	{
		name: "Michelle",
		content: "Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus Fattu",
	},
	{
		name: "Michelle",
		content:
			"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
	},
	{
		name: "Peter Smith",
		industry: "Engineering",
		content: "Friend - Software developer at XYZ limited Guru Nanak Dev University Regional Campus Fattu",
	},
];

const Search = () => {
	return (
		<Fragment>
			<Input
				name={"search"}
				placeholder={"Search"}
				styles={{
					marginBlockEnd: "16px",
					"@media screen and (max-width: 768px)": {
						marginInline: "12px",
						width: "auto",
					},
				}}
				inputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<SearchIcon sx={{ width: "24px" }} />
						</InputAdornment>
					),
				}}
			/>
			<Wrapper>
				{searchData.map((result, index) => (
					<SearchWrapper key={index}>
						<ProfileImage src={ProfilePicture} alt="profile" />
						<InfoWrapper>
							<Name>{result.name}</Name>
							<SearchContent>{result.content}</SearchContent>
						</InfoWrapper>
						<PrimaryBtn
							label={"Follow"}
							overrides={{
								marginBlock: "auto",
								paddingBlock: "4px",
								minWidth: "80px",
								"@media screen and (max-width: 768px)": {
									marginBlock: "initial",
								},
							}}
						/>
					</SearchWrapper>
				))}
			</Wrapper>
		</Fragment>
	);
};

export default Search;
