import React, { useContext } from "react";
import "./SidenavComponent.scss";
import arrow from "../../assets/images/icons/arrow.png";
import profile from "../../assets/icons/user-profile.png";
import education from "../../assets/images/icons/education.png";
import agriculture from "../../assets/images/icons/Agriculture.png";
import transport from "../../assets/images/icons/transport.png";
import art from "../../assets/images/icons/artD.png";
import technology from "../../assets/images/icons/technology.png";
import business from "../../assets/images/icons/business.png";
import magazine from "../../assets/images/icons/magazine.png";
import health from "../../assets/images/icons/health.png";
import hospitality from "../../assets/images/icons/hospitality.png";
import professional from "../../assets/images/icons/professional.png";
import entertainment from "../../assets/images/icons/entertainment.png";
import real from "../../assets/images/icons/real-estate.png";
import healthcare from "../../assets/images/icons/healthcare.png";
import short from "../../assets/images/icons/short-videos.png";
import dating1 from "../../assets/icons/Dating.png";
import publicC from "../../assets/images/icons/public.png";
import micro from "../../assets/images/icons/micro-blog.png";
import dating from "../../assets/images/icons/dating.png";
import bazar from "../../assets/images/icons/bazaar.png";
import tv from "../../assets/images/icons/tv.png";
import general from "../../assets/images/icons/general.png";
import cars from "../../assets/images/icons/cars.png";
import { NavLink, useNavigate } from "react-router-dom";
import { UserContext } from "../../store/UserDetailsStore";
import { connect } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../utils/queryController";

const SidenavComponent = ({ setActiveMBModule, setActiveMBModuleProfile, setActiveMBProfile }) => {
	const navigate = useNavigate();
	let activeStyle = {
		color: "black",
		fontWeight: 600,
	};
	const { isLoginUserDetails } = useContext(UserContext);

	const { refetch } = useQuery({
		queryKey: ["LastMBProfile"],
		queryFn: async () => {
			const response = await query("GET", "MB", "getLatestMicrobloggingAccount");
			return response.data;
		},
		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
		enabled: false,
	});
	const handleMicroBlogging = () => {
		setActiveMBModule(null);
		refetch().then((res) => {
			const { data } = res;
			setActiveMBModuleProfile(data);
			setActiveMBProfile(data?.microbloggingId);
			navigate("/microblogging");
		});
	};

	return (
		<>
			<div className="sidenav">
				<div className="sidenav_container">
					<div className="sidenav_header">
						<NavLink to="/privateprofile">
							<img src={isLoginUserDetails?.profilePictureUrl || profile} alt="profile" />
						</NavLink>
						<h5>{isLoginUserDetails?.name || "Anonymous User"}</h5>
					</div>
					<div className="sidenav_menu">
						<div className="sidenav_menu_heading">
							<img src={arrow} alt="icon" />
							<h5>Services</h5>
						</div>
						<div className="sidenav_menu_links">
							<div className="sidenav_menu_link">
								<NavLink to="/education/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={education} alt="icon" />
									<h4>Education</h4>
								</NavLink>
							</div>
							<div className="sidenav_menu_link">
								<NavLink to="/agriculture/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={agriculture} alt="icon" />
									<h4>Agriculture</h4>
								</NavLink>
							</div>
							<div className="sidenav_menu_link">
								<NavLink to="/transporation" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={transport} alt="icon" />
									<h4>Transport</h4>
								</NavLink>
							</div>
							<div className="sidenav_menu_link">
								<NavLink to="#" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={art} alt="icon" />
									<h4>Creativity</h4>
								</NavLink>
							</div>
							<div className="sidenav_menu_link">
								<NavLink to="/it/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={technology} alt="icon" />
									<h4>Information Tech</h4>
								</NavLink>
							</div>
							<div className="sidenav_menu_link">
								<NavLink to="/healthcare/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={healthcare} alt="icon" />
									<h4>Healthcare</h4>
								</NavLink>
							</div>
							<div className="sidenav_menu_link">
								<NavLink to="/hospitality/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={hospitality} alt="icon" />
									<h4>Hospitality</h4>
								</NavLink>
							</div>
							<div className="sidenav_menu_link">
								<NavLink to="#" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={health} alt="icon" />
									<h4>Environment</h4>
								</NavLink>
							</div>
							<div className="sidenav_menu_link">
								<NavLink to="#" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={entertainment} alt="icon" />
									<h4>Entertainment</h4>
								</NavLink>
							</div>
							<div className="sidenav_menu_link">
								<NavLink to="#" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
									<img src={professional} alt="icon" />
									<h4>Professional Services</h4>
								</NavLink>
							</div>
						</div>

						<div className="sidenav_menu_dropdown">
							<div className="sidenav_menu_dropdown_heading">
								<img src={arrow} alt="icon" />
								<h5>Products</h5>
							</div>
							<div className="sidenav_menu_dropdown_links">
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="#" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={bazar} alt="icon" />
										<h4>Multi-Source Bazaar</h4>
									</NavLink>
								</div>
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="#" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={general} alt="icon" />
										<h4>Used Products</h4>
									</NavLink>
								</div>
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="#" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={real} alt="icon" />
										<h4>Real Estate</h4>
									</NavLink>
								</div>
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="#" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={cars} alt="icon" />
										<h4>Used Cars</h4>
									</NavLink>
								</div>
							</div>
						</div>

						<div className="sidenav_menu_dropdown">
							<div className="sidenav_menu_dropdown_heading">
								<img src={arrow} alt="icon" />
								<h5>Finance</h5>
							</div>
							<div className="sidenav_menu_dropdown_links">
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="#" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={business} alt="icon" />
										<h4>Investment</h4>
									</NavLink>
								</div>
							</div>
						</div>

						<div className="sidenav_menu_dropdown">
							<div className="sidenav_menu_dropdown_heading">
								<img src={arrow} alt="icon" />
								<h5>Social Interactions</h5>
							</div>
							<div className="sidenav_menu_dropdown_links">
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="/shorts/feed" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={short} alt="icon" />
										<h4>Short videos</h4>
									</NavLink>
								</div>
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="/journalist/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={dating1} alt="icon" />
										<h4>Social Journalist</h4>
									</NavLink>
								</div>
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="/pcontrol/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={publicC} alt="icon" />
										<h4>Public Control</h4>
									</NavLink>
								</div>
								<div className="sidenav_menu_dropdown_link">
									<NavLink
										// to="/microblogging"
										onClick={handleMicroBlogging}
										style={({ isActive }) => (isActive ? activeStyle : undefined)}
									>
										<img src={micro} alt="icon" />
										<h4>Micro Blogging</h4>
									</NavLink>
								</div>
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="/dating/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={dating} alt="icon" />
										<h4>Dating</h4>
									</NavLink>
								</div>
							</div>
						</div>

						<div className="sidenav_menu_dropdown">
							<div className="sidenav_menu_dropdown_heading">
								<img src={arrow} alt="icon" />
								<h5>Media & Entertainment</h5>
							</div>
							<div className="sidenav_menu_dropdown_links">
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="/mg/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={magazine} alt="icon" />
										<h4>Magazine</h4>
									</NavLink>
								</div>
								<div className="sidenav_menu_dropdown_link">
									<NavLink to="/tv/home" style={({ isActive }) => (isActive ? activeStyle : undefined)}>
										<img src={tv} alt="icon" />
										<h4>Tv</h4>
									</NavLink>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
const mapPropsToState = (dispatch) => ({
	setActiveMBModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: data,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
	setActiveMBModuleProfile: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE_PROFILE",
			payload: data,
		}),
});

export default connect(undefined, mapPropsToState)(SidenavComponent);
