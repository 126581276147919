import React from "react";
import Smile from "../../../assets/images/MB/smile.png";
import Pic from "../../../assets/images/Educationprofile.png";
import Gallery from "../../../assets/images/MB/gallery.png";
import styled from "@emotion/styled";
import { borderRadius, color, spacing } from "../../../styles/variables";
import Input from "../../Inputs/Input";
import PrimaryBtn from "../../Buttons/Primary";

const PostStatus = () => {
	return (
		<Wrapper>
			<Image src={Pic} about="Picture" />
			<InputWrapper>
				<Input placeholder={"What is happening?"} overrides={inputStyles} />
				<FileUploadWrapper>
					<img src={Smile} alt="" />
					<img src={Gallery} alt="" />
				</FileUploadWrapper>
			</InputWrapper>
			<PrimaryBtn label={"Post"} overrides={btnStyles} />
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	column-gap: ${spacing("lg1")};
	border: 1px solid ${color("borderGray")};
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
	border-radius: ${borderRadius(6)};
	padding-block: ${spacing("md4")} ${spacing("md1")};
	padding-inline: ${spacing("md1")};
	@media screen and (max-width: 768px) {
		width: -webkit-fill-available;
	}
`;

const Image = styled.img`
	height: 56px;
	aspect-ratio: 1;
`;

const InputWrapper = styled.div`
	width: 60%;
	display: flex;
	flex-direction: column;

	& > div {
		margin: 0 !important;
	}
`;

const inputStyles = {
	".MuiInputBase-root": {
		".MuiOutlinedInput-notchedOutline": {
			borderColor: "transparent !important",
		},
	},
	".MuiInputBase-input": {
		paddingBlockStart: "0 !important",
		paddingInline: "0",
		"&::placeholder": {
			color: color("pGreen"),
			opacity: 1,
		},
	},
};

const FileUploadWrapper = styled.div`
	display: flex;
	align-items: center;
	column-gap: ${spacing("sm2")};

	img {
		width: 19px;
		aspect-ratio: 1;
		cursor: pointer;
	}
`;

const btnStyles = {
	textTransform: "none",
	height: "max-content",
	marginLeft: "auto",
	marginTop: "auto",

	/* button text white */
	fontFamily: "Poppins",
	fontSize: "16px",
	fontStyle: "normal",
	fontWeight: 400,
	lineHeight: "normal",
};

export default PostStatus;
