import React from "react";
import { useNavigate } from "react-router-dom";
import SignUp from "../../../../components/Hospitality/Forms/Signup";
import CompanySignUpForm from "../../../../components/Hospitality/Forms/Signup/Company";

const CompanySignUp = () => {
	const navigate = useNavigate();
	const handleSubmit = (values) => {
		navigate("/hospitality/microblogging");
	};
	return (
		<SignUp userType={"Company"} onSubmit={handleSubmit}>
			<CompanySignUpForm />
		</SignUp>
	);
};

export default CompanySignUp;
