import { useEffect, useState } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import Magazine from "../assets/icons/magazine/magazine";
import Heading from "../components/Heading/Heading";
import Home from "../pages/Magazine/Home";
import Signup from "../pages/Magazine/Signup";
import useLayout from "../utils/hooks/useLayout";
import SubHeader from "../components/Magazine/Header";

const MagazineRoutes = ({ userData, setActiveModule, setSignUpPopupToggle, signUpPopup }) => {
	const location = useLocation();
	const { pathname } = location;
	const [isMagazine, setIsMagazine] = useState(false);
	const isMobile = useLayout();

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();
		if (path?.includes("mg") && !path.includes("microblogging") && !path.includes("signup") && path?.length > 1) {
			setActiveModule("mg");
			setIsMagazine(true);
		} else setIsMagazine(false);
	}, [pathname]);

	return (
		<>
			{isMagazine && !userData?.isLoading && (
				<>
					<Heading
						icon={Magazine}
						title={"Magazine"}
						moduleHomeRedirect={"/mg/home"}
						user={userData?.data?.fullName || "George Kajaia"}
					/>
					{isMobile && <SubHeader />}
				</>
			)}
			<Routes>
				<Route path="mg">
					<Route index element={<Signup />} />
					<Route path="home" element={<Home />} />
				</Route>
			</Routes>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		userData: state?.mg?.profile,
		signUpPopup: state.mg?.signUpPopup,
	};
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MODULE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_MG",
			payload: data,
		}),
});
export default connect(mapStateToProps, mapPropsToState)(MagazineRoutes);
