import React, { useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import mbpic1 from "../../../../assets/icons/public/blog1.png";

import Post from "../../../../components/MB/Post";
import { EduWrapperLeft, EduWrapperCenter, EduWrapperRight } from "../../styles/index.styled";
import MBLeft from "../../../../components/LeftSubNav";
import MBProfileDetails from "../../../../components/MB/MBProfileDetails";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MBLeftOther from "../../../../components/MB/MBLeftOther/MBLeftOther";
import MBRightTrend from "../../../../components/MB/MBRightTrend/MBRightTrend";
import { spacing } from "../../../../styles/variables";
import { connect } from "react-redux";
import MBMobileBottom from "../../../../components/MB/MBMobileBottom/MBMobileBottom";
import Page from "../../../../layouts/Page";

const MBPrivateProfile = ({ user, activeModule, activeProfileId, setActiveMBProfile, setActiveMBModuleProfile }) => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [isMobile, setIsMobile] = useState(window.innerWidth < 760);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 760);
		};

		window.addEventListener("resize", handleResize);

		return () => {
			window.removeEventListener("resize", handleResize);
		};
	}, []);

	return (
		<>
			{token ? (
				<>
					<Page>
						<EduWrapperLeft>
							<MBLeft />
							<MBLeftOther visible={!isMobile} />
						</EduWrapperLeft>
						<EduWrapperCenter>
							<MBProfileDetails
								userName={user?.fullName || user?.userName || user?.microbloggingUsername}
								profilePicture={user?.profilePicture}
								button={"Edit Profile"}
							/>
							<div className="tabsection">
								<Tabs>
									<TabList
										style={{
											marginBlockEnd: spacing("lg1"),
										}}
									>
										<Tab>Posts</Tab>
									</TabList>

									<TabPanel
										style={{
											display: "flex",
											flexDirection: "column",
											rowGap: spacing("lg1"),
										}}
									>
										<MBLeftOther visible={isMobile} />
										<Post
											heading={"Michelle"}
											name="Education"
											content="Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting gas the Jews"
											image={mbpic1}
										/>
										<Post
											heading={"Michelle"}
											name="Education"
											content="Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hamas supporters were chanting gas the Jews.Australian police is investigating after videos emerged of yesterday's gathering in front of the Sydney Opera House where crowds of Hama"
										/>
									</TabPanel>
								</Tabs>
							</div>
						</EduWrapperCenter>
						<EduWrapperRight>
							<MBRightTrend />
						</EduWrapperRight>
					</Page>
					<MBMobileBottom />
				</>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		user: state.mb?.activeProfile,
		activeProfileId: state.mb?.activeProfileId,
		activeModule: state.mb?.activeModule,
	};
};

const mapPropsToState = (dispatch) => {
	return {
		setActiveMBProfile: (id) =>
			dispatch({
				type: "MB_SET_ACTIVE_PROFILE",
				payload: id,
			}),
		setActiveMBModuleProfile: (data) =>
			dispatch({
				type: "SET_ACTIVE_MB_MODULE_PROFILE",
				payload: data,
			}),
	};
};

export default connect(mapStateToProps, mapPropsToState)(MBPrivateProfile);
