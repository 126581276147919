const initialUserState = {
	tv: { data: null, isLoading: false, error: null },
	signUpPopup: false,
	profiles: [],
};

const tvReducer = (state = initialUserState, action) => {
	switch (action.type) {
		case "UPDATE_TV_USER_DATA":
			state = {
				...state,
				profile: {
					...state.profile,
					isLoading: false,
					data: action?.payload,
				},
			};
			break;
		case "UPDATE_TV_ALL_PROFILES":
			state = {
				...state,
				profiles: action?.payload,
			};
			break;
		case "SET_SIGNUP_TOGGLE_TV":
			state = {
				...state,
				signUpPopup: action?.payload || false,
			};
			break;
		default:
			break;
	}
	return state;
};

export default tvReducer;
