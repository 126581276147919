import { useState } from "react";
import {
	FormRowWrapper,
	CheckboxWrapper,
	FormWrapper,
	Wrapper,
	BtnWrapper,
	FormTitle,
	Form,
	btnStyleOverrides,
} from "../../index.styled";
import { FormControlLabel, Checkbox } from "@mui/material";
import SecondaryBtn from "../../../../../Buttons/Secondary";
import PrimaryBtn from "../../../../../Buttons/Primary";
const skills = [
	{
		id: 1,
		label: "Crop Science Specialists",
		name: "iscropScienceSpecialists",
	},
	{
		id: 2,
		label: "Horticulture Consultants",
		name: "ishorticultureConsultants",
	},
	{
		id: 3,
		label: "Agronomy Experts",
		name: "isagronomyExperts",
	},
	{
		id: 4,
		label: "Agribusiness Consultants",
		name: "isagribusinessConsultants",
	},
	{
		id: 5,
		label: "Agriculture Technology Advisors",
		name: "isagriculturalTechnologyAdvisors",
	},
	{
		id: 6,
		label: "Livestock Management Specialists",
		name: "isagribusinessConsultants",
	},
	{
		id: 7,
		label: "Organic Farming Experts",
		name: "isorganicFarmingExperts",
	},
	{
		id: 8,
		label: "Aquaculture and Fishery Consultants",
		name: "isaquacultureAndFisheryConsultants",
	},
	{
		id: 9,
		label: "Agroforestry and Land Management Experts ",
		name: "isagroforestryAndLandManagementExperts",
	},
	{
		id: 10,
		label: "Food Safety and Quality Analysts",
		name: "isfoodSafetyAndQualityAnalysts",
	},
	{
		id: 11,
		label: "Agricultural Policy Advisors",
		name: "isagriculturalPolicyAdvisors",
	},
	{
		id: 12,
		label: "Climate and Environmental Specialists",
		name: "isclimateAndEnvironmentalSpecialists",
	},
	{
		id: 13,
		label: "Agricultural Extension Services",
		name: "isagriculturalExtensionServices",
	},
	{
		id: 14,
		label: "Veterinary Services",
		name: "isveterinaryServices",
	},
	{
		id: 15,
		label: "Irrigation and Water Management Experts",
		name: "isirrigationAndWaterManagementExperts",
	},
	{
		id: 16,
		label: "Agricultural Research and Development",
		name: "isagriculturalResearchAndDevelopment",
	},
];

const renderSkills = (setActiveSkills) => {
	const rows = [];
	const allSkills = {};
	skills.forEach((skill) => {
		allSkills[skill.name] = false;
	});
	const handleCheckbox = (event, name) => {
		setActiveSkills((prev) => {
			return {
				...allSkills,
				...prev,
				[name]: event.target.checked ? true : false,
			};
		});
	};

	for (let i = 0; i < skills.length; i += 2) {
		rows.push(
			<FormRowWrapper key={skills[i]?.id}>
				<CheckboxWrapper>
					<FormControlLabel
						control={<Checkbox name={skills[i]?.name} onChange={(event) => handleCheckbox(event, skills[i]?.name)} />}
						label={skills[i]?.label}
					/>
				</CheckboxWrapper>
				{i + 1 < skills.length && (
					<CheckboxWrapper>
						<FormControlLabel
							control={
								<Checkbox defaultValue={"off"} onChange={(event) => handleCheckbox(event, skills[i + 1]?.name)} />
							}
							label={skills[i + 1]?.label}
						/>
					</CheckboxWrapper>
				)}
			</FormRowWrapper>,
		);
	}

	return rows;
};

const FreelancerSkills = ({ handleBack, handleSubmit }) => {
	const [activeSkills, setActiveSkills] = useState({});
	const onSubmit = (event) => {
		event.preventDefault();
		handleSubmit(activeSkills);
	};
	return (
		<Wrapper>
			<FormWrapper onSubmit={onSubmit}>
				<FormTitle>Provide Your Freelancer Information</FormTitle>
				<Form>{renderSkills(setActiveSkills)}</Form>
				<BtnWrapper>
					<SecondaryBtn label={"Back"} onClick={handleBack} overrides={btnStyleOverrides} />
					<PrimaryBtn label={"Next"} overrides={btnStyleOverrides} />
				</BtnWrapper>
			</FormWrapper>
		</Wrapper>
	);
};

export default FreelancerSkills;
