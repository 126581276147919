const Index = ({ color = "white", size = 40 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 47 40" fill="none">
			<path
				d="M47 12.9197C47 27.5065 25.2226 39.3134 24.2952 39.8011C24.0508 39.9316 23.7776 40 23.5 40C23.2224 40 22.9492 39.9316 22.7048 39.8011C21.7774 39.3134 0 27.5065 0 12.9197C0.00388744 9.49438 1.37572 6.21044 3.81452 3.78836C6.25333 1.36628 9.55994 0.00386078 13.0089 0C17.3417 0 21.1353 1.85044 23.5 4.97826C25.8647 1.85044 29.6583 0 33.9911 0C37.4401 0.00386078 40.7467 1.36628 43.1855 3.78836C45.6243 6.21044 46.9961 9.49438 47 12.9197Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
