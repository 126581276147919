import React, { useEffect, useState } from "react";
import "../ProfileComponent/ProfileComponent.scss";
import { Popover, Typography } from "@mui/material";
import Loader from "../../components/loader/index";

const EduProfileComponent = ({
	id,
	open,
	anchorEl,
	handleClose,
	handleNavigateProfile,
	toggleAddAccount,
	usersData,
	handleUpdateProfile,
	selectedProfileId,
}) => {
	const [isLoader, setIsLoader] = useState(false);	

	useEffect(() => {
		const handleResize = () => {
			if (window.innerWidth < 768) {
				handleClose();
			}
		};
		window.addEventListener("resize", handleResize);
		handleResize();
		return () => window.removeEventListener("resize", handleResize);
	}, [handleClose]);
	
	return (
		<Popover
			id={id}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "left",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "left",
			}}
			sx={{
				"& .MuiPopover-paper": {
					borderRadius: "8px",
					width: "12vw",
					transform: "translateY(18px) !important",
				},
			}}
		>
			<Typography sx={{ p: 2 }}>
				{isLoader ? (
					<Loader />
				) : (
					<div className="profiledropdown">
						{usersData?.map((user, index) => (
							<div className="flex_dropdown_section" key={index}>
								<h6>{user.educationType  }</h6>
								<div className="flex_dropdown_section_content">
									<div
										className="flex_dropdown_section_content_left"
										onClick={() => {
											handleNavigateProfile(user);
											handleUpdateProfile(user?.educationId);
											
										}}
									>
										<input
											type="radio"
											name="userOption"
											value={user?.educationId}
											checked={user?.educationId === selectedProfileId}
										/>
										<p>{user.fullName}</p>
									</div>
								</div>
							</div>
						))}

						{usersData?.length === 0 ? (
							""
						) : (
							<button className="add-account-btn" onClick={toggleAddAccount}>
								Add Account
							</button>
						)}
					</div>
				)}
			</Typography>
		</Popover>
	);
};
export default EduProfileComponent