import React, { useEffect, useState } from "react";
import "./index.scss";
import defaultProfile from "../../../assets/images/user-profile-backsquare.png";
import heartplus from "../../../assets/images/heart-plus.png";
import { GetITSearchProfile } from "../../../utils/apiCalls";
import loader from "../../../assets/icons/loading.png";
import { useNavigate } from "react-router-dom";
import Loader from "../../../components/loader";

const ITSearchResult = () => {
	const navigate = useNavigate();
	const [searchProfileaData, setSearchProfileaData] = useState([]);
	const [searchText, setSearchText] = useState("");
	const [isLoader, setIsLoader] = useState(false);

	const getITSearch = () => {
		setIsLoader(true);
		const payload = {
			searchName: searchText,
		};
		GetITSearchProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setSearchProfileaData(data);
				setIsLoader(false);
			}
		}, payload);
	};

	useEffect(() => {
		if (searchText == "") {
			getITSearch();
		} else {
			getITSearch();
		}
	}, [searchText]);
	
	const handlePublicprofile = (data) => {
		if (data?.itProfileType) {
			const profileType = data.itProfileType.toLowerCase().trim();
			navigate(`/it/${profileType}/profile/public`, { state: { user: data } });
		} else {
			alert("No data available.");
		}
	};
	
	if (isLoader) {
		return <Loader />;
	}

	return (
		<div className="sr">
			<div className="sr_main">
				<input
					name="searchText"
					placeholder="Search"
					value={searchText}
					onChange={(e) => {
						setSearchText(e.target.value);
					}}
				/>
			</div>
			<div className="sr_pop">
				{searchProfileaData?.companies?.length === 0 &&
				searchProfileaData?.clients?.length === 0 &&
				searchProfileaData?.freelancer?.length === 0 &&
				searchProfileaData?.employees?.length === 0 ? (
					<div>
						<div className="sr_pop_loader">
							{isLoader ? <img className="loader" src={loader} alt="Loading" /> : <p>No Data Available</p>}
						</div>
					</div>
				) : (
					<>
						{searchProfileaData?.clients?.length === 0 ? (
							""
						) : (
							<div className="sr_pop_container">
								<div className="sr_pop_section">
									<div className="sr_pop_section_heading">
										<h3>Clients</h3>
									</div>
									{searchProfileaData?.clients &&
										searchProfileaData?.clients.map((data, ind) => (
											<div className="sr_pop_section_cards" key={ind}>
												<div className="sr_pop_section_cards_content">
													<div
														className="sr_pop_section_cards_content_center"
														onClick={() => handlePublicprofile(data)}
													>
														<div className="sr_pop_section_cards_content_center_image">
															<img src={data?.profilePicture || defaultProfile} alt="" />
														</div>
														<div className="sr_pop_section_cards_content_center_content">
															<h2>{data?.firstName}</h2>
															<h5>
																Friend - Software developer at XYZ limited Guru Nanak Dev
																University Regional Campus Fattu Dhinga - Sultanpur Lodhi
															</h5>
															<p>13 Mutual Friends</p>
														</div>
													</div>
													<div className="sr_pop_section_cards_content_right">
														<img src={heartplus} alt="heartplus" />
													</div>
												</div>
											</div>
										))}
								</div>
							</div>
						)}

						{searchProfileaData?.freelancer?.length === 0 ? (
							""
						) : (
							<div className="sr_pop_container">
								<div className="sr_pop_section">
									<div className="sr_pop_section_heading">
										<h3>Freelancer</h3>
									</div>
									<div className="sr_pop_section_cards">
										{searchProfileaData?.freelancer &&
											searchProfileaData?.freelancer.map((data, ind) => (
												<div className="sr_pop_section_cards_content">
													<div
														className="sr_pop_section_cards_content_center"
														onClick={() => handlePublicprofile(data)}
													>
														<div className="sr_pop_section_cards_content_center_image">
															<img src={data?.profilePicture} alt="" />
														</div>
														<div className="sr_pop_section_cards_content_center_content">
															<h2>{data?.fullName}</h2>
															<h5>
																Friend - Software developer at XYZ limited Guru Nanak Dev
																University Regional Campus Fattu Dhinga - Sultanpur Lodhi
															</h5>
														</div>
													</div>
													<div className="sr_pop_section_cards_content_right">
														<img src={heartplus} alt="heartplus" />
													</div>
												</div>
											))}
									</div>
								</div>
							</div>
						)}

						{searchProfileaData?.companies?.length === 0 ? (
							""
						) : (
							<div className="sr_pop_container">
								<div className="sr_pop_section">
									<div className="sr_pop_section_heading">
										<h3>Company</h3>
									</div>
									<div className="sr_pop_section_cards">
										{searchProfileaData?.companies &&
											searchProfileaData?.companies.map((data, ind) => (
												<div className="sr_pop_section_cards_content" key={ind}>
													<div className="sr_pop_section_cards_content_center" onClick={() => handlePublicprofile(data)}>
														<div className="sr_pop_section_cards_content_center_image">
															<img src={data?.profilePicture} alt="" />
														</div>
														<div className="sr_pop_section_cards_content_center_content">
															<h2>{data?.companyName}</h2>
															<h5>
																Friend - Software developer at XYZ limited Guru Nanak Dev
																University Regional Campus Fattu Dhinga - Sultanpur Lodhi
															</h5>
														</div>
													</div>
													<div className="sr_pop_section_cards_content_right">
														<img src={heartplus} alt="heartplus" />
													</div>
												</div>
											))}
									</div>
								</div>
							</div>
						)}
						{searchProfileaData?.employees?.length === 0 ? (
							""
						) : (
							<div className="sr_pop_container">
								<div className="sr_pop_section">
									<div className="sr_pop_section_heading">
										<h3>Regular Employees</h3>
									</div>
									<div className="sr_pop_section_cards">
										{searchProfileaData?.employees &&
											searchProfileaData?.employees.map((data, ind) => (
												<div className="sr_pop_section_cards_content">
													<div className="sr_pop_section_cards_content_center" onClick={() => handlePublicprofile(data)}>
														<div className="sr_pop_section_cards_content_center_image">
															<img src={data?.profilePicture} alt="" />
														</div>
														<div className="sr_pop_section_cards_content_center_content">
															<h2>{data?.fullName}</h2>
															<h5>
																Friend - Software developer at XYZ limited Guru Nanak Dev
																University Regional Campus Fattu Dhinga - Sultanpur Lodhi
															</h5>
														</div>
													</div>
													<div className="sr_pop_section_cards_content_right">
														<img src={heartplus} alt="heartplus" />
													</div>
												</div>
											))}
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</div>
		</div>
	);
};	

export default ITSearchResult;
