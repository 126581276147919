import { Box } from "@mui/material";
import { Info, MainTitle, SubTitle } from "./index.styled";

const topics = [
	{
		category: "Politics - Trending",
		title: "Big Breaking",
		info: "24.5K",
	},
	{
		category: "Trending",
		title: "Harvard",
		info: "208K",
	},
];

const Trending = () => {
	return (
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				rowGap: "28px",
				p: 2,
				backgroundColor: "#F8F7F7",
			}}
		>
			<MainTitle>What's Happening</MainTitle>
			{topics.map((topic) => (
				<Box
					key={topic.category}
					sx={{
						display: "flex",
						flexDirection: "column",
						rowGap: "8px",
						"&:last-child": {
							marginBottom: "72px",
						},
					}}
				>
					<Info>{topic.category}</Info>
					<SubTitle>{topic.title}</SubTitle>
					<Info>{topic.info}</Info>
				</Box>
			))}
		</Box>
	);
};

export default Trending;
