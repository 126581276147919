const Index = ({ color = "white", size = 46 }) => {
	return (
		<svg width={size} height={size} viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
			<rect x="12.4299" y="16.5386" width="21.7276" height="14.6791" fill="black" />
			<path
				d="M23 0C35.7029 0 46 10.2958 46 22.9972C46 35.6985 35.7029 45.9944 23 45.9944C19.2791 45.9993 15.6131 45.0979 12.3188 43.3681L2.45182 45.9369C2.12213 46.0228 1.77572 46.021 1.44693 45.9317C1.11814 45.8425 0.818376 45.6689 0.577355 45.4281C0.336335 45.1874 0.162422 44.8878 0.0728541 44.5591C-0.016714 44.2305 -0.0188279 43.8841 0.0667218 43.5544L2.63352 33.6909C0.898861 30.3936 -0.0051052 26.7229 2.16868e-05 22.9972C2.16868e-05 10.2958 10.2971 0 23 0ZM25.8796 25.2969H15.525L15.2904 25.313C14.8775 25.3698 14.4992 25.5742 14.2253 25.8883C13.9514 26.2024 13.8005 26.605 13.8005 27.0217C13.8005 27.4384 13.9514 27.841 14.2253 28.1551C14.4992 28.4693 14.8775 28.6736 15.2904 28.7304L15.525 28.7465H25.8796L26.1119 28.7304C26.5248 28.6736 26.9031 28.4693 27.177 28.1551C27.4509 27.841 27.6018 27.4384 27.6018 27.0217C27.6018 26.605 27.4509 26.2024 27.177 25.8883C26.9031 25.5742 26.5248 25.3698 26.1119 25.313L25.8796 25.2969ZM30.475 17.2479H15.525L15.2904 17.264C14.8775 17.3208 14.4992 17.5251 14.2253 17.8392C13.9514 18.1533 13.8005 18.556 13.8005 18.9727C13.8005 19.3894 13.9514 19.792 14.2253 20.1061C14.4992 20.4202 14.8775 20.6246 15.2904 20.6814L15.525 20.6975H30.475L30.7096 20.6814C31.1225 20.6246 31.5008 20.4202 31.7747 20.1061C32.0486 19.792 32.1995 19.3894 32.1995 18.9727C32.1995 18.556 32.0486 18.1533 31.7747 17.8392C31.5008 17.5251 31.1225 17.3208 30.7096 17.264L30.475 17.2479Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
