import { Box } from "@mui/material";
import { useState } from "react";
import { Nav } from "./index.styled";

const navs = [
	{
		id: 1,
		label: "home",
	},
	{
		id: 2,
		label: "for you",
	},
	{
		id: 3,
		label: "following",
	},
	{
		id: 4,
		label: "usa",
	},
	{
		id: 5,
		label: "world",
	},
	{
		id: 6,
		label: "local",
	},
	{
		id: 7,
		label: "business",
	},
	{
		id: 8,
		label: "technology",
	},
	{
		id: 9,
		label: "entertainment",
	},
	{
		id: 10,
		label: "sports",
	},
	{
		id: 11,
		label: "science",
	},
	{
		id: 12,
		label: "health",
	},
];

const FeedNav = ({}) => {
	const [active, setActive] = useState(1);
	return (
		<Box display={"flex"} flexDirection={"column"} rowGap={"4px"}>
			{navs.map((nav) => (
				<Nav key={nav?.id} active={nav?.id === active}>
					{nav.label}
				</Nav>
			))}
		</Box>
	);
};

export default FeedNav;
