import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Landing from "../pages/Landing";
import Login from "../pages/Login";
import SignUp from "../pages/SignUp";
import HomeHeader from "../components/Main/Header";
import { connect } from "react-redux";
import { MenuProvider } from "../../app/store/MenuContext";

import SubRoutes from "./subRoutes";
import ITRoutes from "./it";
import MicroBloggingRoutes from "./microblogging";
import ShortsRoutes from "./shorts";
import EducationRoutes from "./education";
import AgricultureRoutes from "./agriculture";
import TransportationRoutes from "./transportation";
import CreativityRoutes from "./creativity";
import InvestmentRoutes from "./investment";
import HealthcareRoutes from "./healthcare";
import HospitalityRoutes from "./hospitality";
import EnvironmentRoutes from "./environment";
import EntertainmentRoutes from "./entertainment";
import ServicesRoutes from "./services";
import TVRoutes from "./tv";
import MagazineRoutes from "./magazine";
import SocialJournalistRoutes from "./socialJournalist";
import DatingRoutes from "./dating";
import PublicControlRoutes from "./publicControl";

const HomeHeaderWithCondition = ({ setActiveModule }) => {
	const { pathname } = useLocation();
	const [menuopen, setMenuopen] = useState(false);
	useEffect(() => {
		const path = pathname.split("/");
		path.shift();

		if (path[0] === "services") {
			setActiveModule("Professional Services");
		} else {
			path.shift();
			setActiveModule(path.shift());
		}
	}, [pathname]);

	const showHomeHeader =
		pathname === "/home" ||
		pathname === "/privateprofile" ||
		pathname === "/publicprofile" ||
		pathname === "/search" ||
		pathname === "/chatbot" ||
		pathname === "/profiles";

	return showHomeHeader && <HomeHeader setMenuopen={setMenuopen} menuopen={menuopen} />;
};

const Routing = ({ setActiveModule }) => {
	return (
		<BrowserRouter>
			<MenuProvider>
				<HomeHeaderWithCondition setActiveModule={setActiveModule} />
				<Routes>
					<Route index element={<Landing />} />
					<Route path="/login" element={<Login />} />
					<Route path="/signup" element={<SignUp />} />
				</Routes>
				<SubRoutes />
				<ITRoutes />
				<EducationRoutes />
				<AgricultureRoutes />
				<TransportationRoutes />
				<CreativityRoutes />
				<InvestmentRoutes />
				<HealthcareRoutes />
				<HospitalityRoutes />
				<EnvironmentRoutes />
				<EntertainmentRoutes />
				<ServicesRoutes />
				<ShortsRoutes />
				<MicroBloggingRoutes />
				<TVRoutes />
				<MagazineRoutes />
				<SocialJournalistRoutes />
				<DatingRoutes />
				<PublicControlRoutes />
			</MenuProvider>
		</BrowserRouter>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (value) =>
		dispatch({
			type: "SET_ACTIVE_MODULE",
			payload: value,
		}),
});
export default connect(undefined, mapPropsToState)(Routing);
