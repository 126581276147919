import { Box, Input, Tab } from "@mui/material";
import { UserName } from "./index.styled";
import Smile from "@mui/icons-material/SentimentSatisfiedAlt";
import ImageSelector from "@mui/icons-material/ImageOutlined";
import PrimaryBtn from "../../Buttons/Primary/index";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { useState } from "react";
import Offers from "./Offers/index";
import Post from "./Post/index";

const Feed = () => {
	const [value, setValue] = useState(1);
	const handleChange = (event, newValue) => {
		setValue(newValue);
	};
	return (
		<Box display={"flex"} flexDirection={"column"} rowGap={"26px"}>
			<Box
				display={"flex"}
				flexDirection={"column"}
				rowGap={"10px"}
				p={"10px"}
				borderRadius={"8px"}
				sx={{
					backgroundColor: "#fff",
					boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.25)",
				}}
			>
				<UserName>Id 70584356</UserName>
				<Input
					sx={{
						"& input::placeholder": {
							color: "#61BCAC",
						},
					}}
					placeholder={"What's happening?"}
				/>
				<Box
					display={"flex"}
					columnGap={"8px"}
					sx={{
						"& > *:hover": {
							cursor: "pointer",
						},
						position: "relative",
					}}
				>
					<Smile
						sx={{
							color: "#61BCAC",
						}}
					/>
					<ImageSelector
						sx={{
							color: "#61BCAC",
						}}
					/>
					<PrimaryBtn
						label={"Post"}
						overrides={{
							position: "absolute",
							right: "0",
							bottom: "0",
						}}
					/>
				</Box>
			</Box>
			<TabContext value={value}>
				<Box
					borderRadius={"8px"}
					p={"10px"}
					sx={{
						backgroundColor: "#fff",
						boxShadow: "0px 4px 24px 0px rgba(0, 0, 0, 0.25)",
					}}
				>
					<TabList
						aria-label="as"
						onChange={handleChange}
						sx={{
							minHeight: "auto",
							"& .MuiTabs-indicator": {
								backgroundColor: "#61BCAC",
								height: "4px",
							},
							"& button": {
								textTransform: "none",
								padding: "10px",
								minHeight: "auto",
								color: "#000",
								/* body-text-lg */
								fontFamily: "Inter",
								fontSize: "18px",
								fontStyle: "normal",
								fontWeight: 400,
								lineHeight: "normal",
								"&.Mui-selected": {
									color: "#61BCAC",
									fontWeight: 500,
									backgroundColor: "transparent",
								},
							},
						}}
					>
						<Tab label={"Offers"} value={1} />
						<Tab label={"Posts"} value={2} />
					</TabList>
				</Box>
				{value === 1 && (
					<TabPanel
						value={1}
						sx={{
							display: "flex",
							flexDirection: "column",
							rowGap: "24px",
						}}
					>
						<Offers />
						<Post image={false} actions />
					</TabPanel>
				)}
				{value === 2 && (
					<TabPanel
						value={2}
						sx={{
							display: "flex",
							flexDirection: "column",
							rowGap: "24px",
						}}
					>
						<Post image={false} />
						<Post />
					</TabPanel>
				)}
			</TabContext>
		</Box>
	);
};

export default Feed;
