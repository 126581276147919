import { useLocation, useNavigate } from "react-router-dom";
import Heading from "../../Heading/Heading";
import icon from "../../../assets/icons/engineering.png";
import {
	BackBtnStyles,
	BloggingInfo,
	BloggingProfileBtnWrapper,
	BloggingTitle,
	BloggingWrapper,
	CharacterCount,
	DetailsWrapper,
	NextBtnStyles,
	SubDesc,
	SubHeaders,
	TextArea,
	TextAreaWrapper,
	Username,
	Wrapper,
} from "./index.styled";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import { FormHelperText } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import PrimaryBtn from "../../Buttons/Primary";
import SecondaryBtn from "../../Buttons/Secondary";
import Modal from "../../Modal";

const validationSchema = Yup.object({
	MicrobloggingBio: Yup.string().required("Bio is required"),
	ShortVideosUsername: Yup.string().required("Username is required"),
});

const MicroBlogging = () => {
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { state } = useLocation();
	const bioLength = 160;
	const [bioCharLength, setBioCharLenght] = useState(0);
	const formikRef = useRef();

	const [showModal, setShowModal] = useState(false);
	const [errorMessage, setErrorMessage] = useState("");

	const mutation = useMutation({
		mutationFn: async (data) => {
			try {
				const response = await query("POST", "IT", state?.url, data);
				if (response?.status === 400) {
					setErrorMessage(response?.message);
					setShowModal(true);
				}

				const result = await response.data;
				if (result.status === 200) {
					queryClient.invalidateQueries("ITProfile");
					navigate("/it/home");
					window.location.reload();
				}
				return result;
			} catch (error) {
				console.error("Error creating user:", error.message);
				throw error;
			} finally {
				// Code that runs regardless of success or failure
			}
		},
	});

	useEffect(() => {
		console.log("Modal state:", showModal, "Error Message:", errorMessage);
	}, [showModal, errorMessage]);
	// Function to close the modal
	const handleCloseModal = () => {
		setShowModal(false);
	};

	const formik = useFormik({
		initialValues: {
			MicrobloggingBio: "",
			ShortVideosUsername: "",
		},
		validationSchema: validationSchema,
		onSubmit: async (values) => {
			const { allData, picture } = state;
			const formData = new FormData(formikRef.current);
			Object.entries(allData).forEach(([key, value]) => {
				if (key === "file_upload") {
					const blob = new Blob([picture], { type: picture.type });
					formData.append(key, blob);
				} else formData.append(key, value);
			});
			try {
				const result = await mutation.mutateAsync(formData);
				console.log(result, "res");
				navigate("/it/home");
			} catch (err) {
				console.error(err);
			}
		},
	});

	const handleOk = () => {
		navigate("/it");
	};

	return (
		<>
			<Heading icon={icon} title={"Information Technology"} showSearchInput={false} />
			<Wrapper>
				<BloggingWrapper
					ref={formikRef}
					onSubmit={formik.handleSubmit}
					onChange={formik.handleChange}
					onBlur={formik.handleBlur}
				>
					<BloggingTitle>Provide Your Microblogging Information</BloggingTitle>
					<DetailsWrapper>
						<BloggingInfo>
							We gather additional info for microblogging and short video modules to enhance your experience: deep
							industry conversations on microblogging, and engaging visual content in short videos for broader
							appeal and impact.
						</BloggingInfo>
						<SubHeaders>Personal Information for Microblogging</SubHeaders>
						<SubDesc>Bio*</SubDesc>
						<TextAreaWrapper>
							<TextArea
								name="MicrobloggingBio"
								maxLength={bioLength}
								placeholder={"A detailed professional overview highlighting skills and experience"}
								onInput={(event) => setBioCharLenght(event.target.value.length)}
								rows={4}
							/>
							<CharacterCount>{bioLength - bioCharLength} characters</CharacterCount>
							{formik.touched.MicrobloggingBio && (
								<FormHelperText
									error={formik.touched.MicrobloggingBio && Boolean(formik.errors.MicrobloggingBio)}
								>
									{formik.errors.MicrobloggingBio}
								</FormHelperText>
							)}
						</TextAreaWrapper>
						<SubHeaders>Personal Information for Short Videos</SubHeaders>
						<SubDesc>Username*</SubDesc>
						<Username name="ShortVideosUsername" />
						{formik.touched.ShortVideosUsername && (
							<FormHelperText
								error={formik.touched.ShortVideosUsername && Boolean(formik.errors.ShortVideosUsername)}
							>
								{formik.errors.ShortVideosUsername}
							</FormHelperText>
						)}
					</DetailsWrapper>
					<BloggingProfileBtnWrapper>
						<SecondaryBtn label={"Back"} overrides={BackBtnStyles} onClick={() => navigate(-1)} />
						<PrimaryBtn type={"submit"} label={"Create Profile"} overrides={NextBtnStyles} />
					</BloggingProfileBtnWrapper>
				</BloggingWrapper>
			</Wrapper>

			<Modal open={showModal} onClose={handleCloseModal} noIcon>
				<p style={{ fontSize: "1.5vw", padding: "1vw" }}>{errorMessage}.</p>
				<button
					style={{ color: "white", background: "#61BCAC", border: "none", padding: "0.5vw", borderRadius: "1vw" }}
					onClick={handleOk}
				>
					OK
				</button>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		activeModule: state.module.active,
	};
};

export default connect(mapStateToProps)(MicroBlogging);
