const Index = ({ color = "#61BCAC", size }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 52 52" fill="none">
			<path
				d="M15.3333 16.1176H36.6667M15.3333 27.4118H31.3333M42 2C44.1217 2 46.1566 2.89243 47.6569 4.48098C49.1571 6.06952 50 8.22405 50 10.4706V33.0588C50 35.3054 49.1571 37.4599 47.6569 39.0484C46.1566 40.637 44.1217 41.5294 42 41.5294H28.6667L15.3333 50V41.5294H10C7.87827 41.5294 5.84344 40.637 4.34315 39.0484C2.84286 37.4599 2 35.3054 2 33.0588V10.4706C2 8.22405 2.84286 6.06952 4.34315 4.48098C5.84344 2.89243 7.87827 2 10 2H42Z"
				stroke={color}
				strokeWidth="3"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	);
};

export default Index;
