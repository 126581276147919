import { useState, Fragment } from "react";
import { FormContainer, FormRowWrapper, CheckboxWrapper, CheckboxGroupTitle, Checkboxes } from "../../index.styled";
import { FormControlLabel, Checkbox } from "@mui/material";

const allSkills = [
	{
		id: 1,
		name: "Accounting and Finance Professionals",
		skills: [
			{
				id: 1,
				name: "Public Accountants (CPAs)",
			},
			{
				id: 2,
				name: "Auditors",
			},
			{
				id: 3,
				name: "Tax Consultants",
			},
		],
	},
	{
		id: 2,
		name: "Legal Services",
		skills: [
			{
				id: 1,
				name: "Lawyers",
			},
			{
				id: 2,
				name: "Paralegals",
			},
			{
				id: 3,
				name: "Legal Advisors",
			},
			{
				id: 4,
				name: "Notaries",
			},
			{
				id: 5,
				name: "Management Consulting",
			},
		],
	},
	{
		id: 3,
		name: "Architecture and Engineering Services",
		skills: [
			{
				id: 1,
				name: "Architects",
			},
			{
				id: 2,
				name: "Civil Engineers",
			},
			{
				id: 3,
				name: "Mechanical Engineers",
			},
			{
				id: 4,
				name: "Electrical Engineers",
			},
		],
	},
	{
		id: 4,
		name: "Marketing and Public Relations Services",
		skills: [
			{
				id: 1,
				name: "Marketing Consultants",
			},
			{
				id: 2,
				name: "Public Relations Specialists",
			},
			{
				id: 3,
				name: "Brand Strategists",
			},
		],
	},
	{
		id: 5,
		name: "Human Resources Consultants",
		skills: [
			{
				id: 1,
				name: "HR advisors",
			},
			{
				id: 2,
				name: "Recruitment Consultants",
			},
			{
				id: 3,
				name: "Organizational Development Experts",
			},
		],
	},
];

const renderSkills = (activeSkills, setActiveSkills) => {
	const rows = [];
	const handleCheckbox = (event, id) => {
		if (event.target.checked) {
			setActiveSkills([...activeSkills, id]);
		} else {
			setActiveSkills(activeSkills.filter((skill) => skill !== id));
		}
	};

	for (let i = 0; i < allSkills.length; i += 2) {
		rows.push(
			<FormRowWrapper key={allSkills[i]?.id}>
				<CheckboxWrapper>
					<CheckboxGroupTitle>{allSkills[i]?.name}</CheckboxGroupTitle>
					<Checkboxes>
						{allSkills[i]?.skills?.map((skill) => (
							<FormControlLabel
								control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
								label={skill?.name}
							/>
						))}
					</Checkboxes>
				</CheckboxWrapper>
				{i + 1 < allSkills.length && (
					<CheckboxWrapper>
						<CheckboxGroupTitle>{allSkills[i + 1]?.name}</CheckboxGroupTitle>
						<Checkboxes>
							{allSkills[i + 1]?.skills?.map((skill) => (
								<FormControlLabel
									control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
									label={skill?.name}
								/>
							))}
						</Checkboxes>
					</CheckboxWrapper>
				)}
			</FormRowWrapper>,
		);
	}

	return rows;
};

const FreelancerSkills = () => {
	const [activeSkills, setActiveSkills] = useState([]);
	return (
		<Fragment>
			<FormContainer>{renderSkills(activeSkills, setActiveSkills)}</FormContainer>
		</Fragment>
	);
};

export default FreelancerSkills;
