const Index = ({ size = 18, color = "#C5D0DE" }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 13 17" fill="none">
			<path
				d="M0 13.3966H13V11.1638L11.5556 8.93106V5.20978C11.5556 4.52562 11.4248 3.84817 11.1707 3.21609C10.9167 2.58401 10.5443 2.00968 10.0748 1.52591C9.60536 1.04214 9.04805 0.658387 8.43468 0.396571C7.82131 0.134755 7.16391 0 6.5 0C5.83609 0 5.17869 0.134755 4.56532 0.396571C3.95195 0.658387 3.39463 1.04214 2.92518 1.52591C2.45573 2.00968 2.08334 2.58401 1.82928 3.21609C1.57521 3.84817 1.44444 4.52562 1.44444 5.20978V8.93106L0 11.1638V13.3966Z"
				fill={color}
			/>
			<path
				d="M4.98973 15.9842C4.55321 15.6492 4.23317 15.1781 4.07715 14.6408H8.92326C8.76723 15.1781 8.44719 15.6492 8.01067 15.9842C7.57415 16.3191 7.04444 16.5 6.5002 16.5C5.95597 16.5 5.42626 16.3191 4.98973 15.9842Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
