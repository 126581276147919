import LeftSubNav from "../../../components/LeftSubNav";
import "./index.scss";
import { InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { color, spacing } from "../../../styles/variables";
import defaultProfile from "../../../assets/images/user-profile-backsquare.png";
import {
	Wrapper,
	LeftSection,
	RightSection,
	Searchbar,
	SearchField,
	ResultWrapper,
	Result,
	MobileWrapper,
	SearchWrapper,
	TabPanelWrapper,
} from "../index.styled";
import Preview1 from "../../../assets/images/shorts/Preview1.png";
import Preview2 from "../../../assets/images/shorts/Preview2.png";
import useLayout from "../../../utils/hooks/useLayout";
import { useNavigate } from "react-router-dom";
import Tab from "../../../components/Tab";
import { TabPanel } from "@mui/lab";
import { useEffect, useState } from "react";
import { GetAllShortVideoAccounts, SearchSVAccounts } from "../../../utils/apiCalls";
import Loader from "../../../components/loader/index";

const tabsConfig = [
	{ id: 1, label: "Users" },
	{ id: 2, label: "Videos" },
];

const Search = () => {
	const isMobile = useLayout();
	const navigate = useNavigate();
	const [activeTab, setActiveTab] = useState(tabsConfig[0]?.id);
	const [shortVideosData, setShortVideosData] = useState([]);
	const [isLoader, setIsLoader] = useState(false);
	const [searchName, setSearchName] = useState("");

	const handleShorts = (data) => {
		if (data) {
			navigate(`/shorts/profile/public`, { state: { user: data } });
		}
	};

	useEffect(() => {
		const delay = 1000;
		let timeoutId;

		const debounceSearch = () => {
			clearTimeout(timeoutId);

			timeoutId = setTimeout(() => {
				getSearchSVAccounts();
			}, delay);
		};

		debounceSearch();

		return () => {
			clearTimeout(timeoutId);
		};
	}, [searchName]);

	const getSearchSVAccounts = () => {
		setIsLoader(true);
		const payload = {
			searchName: searchName,
			pageSize: 0,
			pageIndex: 5,
		};
		SearchSVAccounts((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setShortVideosData(data?.result);
				setIsLoader(false);
			} else {
				setShortVideosData([]);
				setIsLoader(false);
			}
		}, payload);
	};

	useEffect(() => {
		getSearchSVAccounts();
	}, []);

	return isMobile ? (
		<MobileWrapper>
			<SearchWrapper>
				<Searchbar>
					<SearchField
						startAdornment={
							<InputAdornment
								position="start"
								sx={{
									marginInlineEnd: spacing("xs"),
								}}
							>
								<SearchIcon color={color("dimGray")} />
							</InputAdornment>
						}
						placeholder="Search"
						onChange={(e) => setSearchName(e.target.value)}
					/>
				</Searchbar>
			</SearchWrapper>

			<div className="shortsSearchTab">
				{isLoader ? (
					<Loader />
				) : (
					<Tab onChange={setActiveTab} defaultActive={activeTab} tabs={tabsConfig}>
						<TabPanelWrapper>
							<TabPanel value={1}>
								<div className="user_container">
									<div className="user_section">
										{shortVideosData &&
											shortVideosData?.map((data, index) => (
												<div className="user_section_cards" key={index}>
													<div
														className="user_section_cards_content"
														onClick={() => handleShorts(data)}
													>
														<div className="user_section_cards_content_center">
															<div className="user_section_cards_content_center_image">
																<img src={data?.profilePicture || defaultProfile} alt="" />
															</div>
															<div className="user_section_cards_content_center_content">
																<h2>{data.fullName}</h2>
																<h5>
																	Friend - Software developer at XYZ limited Guru Nanak Dev
																	University Regional Campus Fattu Dhinga - Sultanpur Lodhi
																</h5>
																<p>13 Mutual Friends</p>
															</div>
															<div className="user_section_cards_content_center_follow">
																<button>Follow</button>
															</div>
														</div>
													</div>
												</div>
											))}
									</div>
								</div>
							</TabPanel>
							<TabPanel value={2}>
								<ResultWrapper>
									<Result src={Preview1} alt="preview" />
									<Result src={Preview2} alt="preview" />
								</ResultWrapper>
							</TabPanel>
						</TabPanelWrapper>
					</Tab>
				)}
			</div>
		</MobileWrapper>
	) : (
		<Wrapper>
			<LeftSection>
				<LeftSubNav />
			</LeftSection>
			<RightSection
				style={{
					gridColumn: "span 9/13",
				}}
			>
				<Searchbar>
					<SearchField
						startAdornment={
							<InputAdornment
								position="start"
								sx={{
									marginInlineEnd: spacing("xs"),
								}}
							>
								<SearchIcon color={color("dimGray")} />
							</InputAdornment>
						}
						placeholder="Search"
						onChange={(e) => setSearchName(e.target.value)}
					/>
				</Searchbar>
				<div className="shortsSearchTab" style={{ marginBottom: "3vw" }}>
					{isLoader ? (
						<Loader />
					) : (
						<Tab onChange={setActiveTab} defaultActive={activeTab} tabs={tabsConfig}>
							<TabPanelWrapper>
								<TabPanel value={1}>
									<div className="user_container">
										<div className="user_section">
											{shortVideosData &&
												shortVideosData?.map((data, index) => (
													<div className="user_section_cards" key={index}>
														<div
															className="user_section_cards_content"
															onClick={() => handleShorts(data)}
														>
															<div className="user_section_cards_content_center">
																<div className="user_section_cards_content_center_image">
																	<img src={data?.profilePicture || defaultProfile} alt="" />
																</div>
																<div className="user_section_cards_content_center_content">
																	<h2>{data.fullName}</h2>
																	<h5>
																		Friend - Software developer at XYZ limited Guru Nanak Dev
																		University Regional Campus Fattu Dhinga - Sultanpur Lodhi
																	</h5>
																	<p>13 Mutual Friends</p>
																</div>
																<div className="user_section_cards_content_center_follow">
																	<button>Follow</button>
																</div>
															</div>
														</div>
													</div>
												))}
										</div>
									</div>
								</TabPanel>
								<TabPanel value={2}>
									<ResultWrapper>
										<Result src={Preview1} alt="preview" />
										<Result src={Preview2} alt="preview" />
										<Result src={Preview1} alt="preview" />
										<Result src={Preview2} alt="preview" />
										<Result src={Preview1} alt="preview" />
										<Result src={Preview2} alt="preview" />
										<Result src={Preview1} alt="preview" />
										<Result src={Preview2} alt="preview" />
										<Result src={Preview1} alt="preview" />
										<Result src={Preview2} alt="preview" />
									</ResultWrapper>
								</TabPanel>
							</TabPanelWrapper>
						</Tab>
					)}
				</div>
			</RightSection>
		</Wrapper>
	);
};

export default Search;
