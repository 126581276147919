import styled from "@emotion/styled";

const LeftNav = styled.div`
	grid-column: span 3;
`;

const NotificationSection = styled.div`
	grid-column: span 6;
`;

export { LeftNav, NotificationSection };
