import { InfoWrapper, Name, NotificationContent, NotificationWrapper, ProfileImage, Wrapper } from "./index.styled";
import ProfilePicture from "../../../assets/images/Educationprofile.png";

const notificationsData = [
	{
		name: "Michelle",
		content:
			"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
	},
	{
		name: "Peter Smith",
		content:
			"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
	},
	{
		name: "David Beckham",
		content:
			"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
	},
	{
		name: "Michelle",
		content:
			"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
	},
	{
		name: "Michelle",
		content:
			"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
	},
	{
		name: "Peter Smith",
		industry: "Engineering",
		content:
			"Wonderful News for Junior Golf Fans! The Hyundai India Junior Golf Tournament is being announced with great excitement. Come play with us on December.",
	},
];

const Notifications = () => {
	return (
		<Wrapper>
			{notificationsData.map((notification, index) => (
				<NotificationWrapper key={index}>
					<ProfileImage src={ProfilePicture} alt="profile" />
					<InfoWrapper>
						<Name>{notification.name}</Name>
						<NotificationContent>{notification.content}</NotificationContent>
					</InfoWrapper>
				</NotificationWrapper>
			))}
		</Wrapper>
	);
};

export default Notifications;
