import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { InputAdornment, Divider, MenuItem, Popover } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MenuIcon from "@mui/icons-material/Menu";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import egicon1 from "../../../assets/images/icons/egicon1.svg";
import egicon2 from "../../../assets/images/icons/egicon2.svg";
import useLayout from "../../../utils/hooks/useLayout";
import { color, spacing } from "../../../styles/variables";
import Chat from "../../../assets/icons/chat/Chat";
import UserWhite from "../../../assets/icons/UserWhite.svg";
import { connect } from "react-redux";
import {
	Wrapper,
	NavigationLink,
	LeftNav,
	Searchbar,
	SearchField,
	Dropdown,
	RightNav,
	QuickNaviation,
	MobileWrapper,
	MobileTopNavs,
	MobileLeftNav,
	MobileRightNav,
	MobileQuickNav,
} from "./index.styled";
import { GetAllEducationProfile, GetEducationMicroBloggingProfile, GetEducationShortVideosProfile, UpdateEducationProfile } from "../../../utils/apiCalls";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";
import styled from "@emotion/styled";
import React, { useState, useEffect, Fragment } from "react";
import User from "../../../assets/icons/User.svg";
import Loader from "../../loader";
import AddIcon from "@mui/icons-material/AddOutlined";
import SidenavMobileComponent from "../../SidenavMobileComponent";

const Header = ({setActiveMBModule, signUpPopup, setSignUpPopupToggle, userData, user,setActiveMBModuleProfile }) => {
	const isMobile = useLayout();
	const [usersData, setUsersData] = useState([]);
	const navigate = useNavigate();
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [isLoader, setIsLoader] = useState(false);
	const [isMessage, setIsMessage] = useState("");
	const [updateStatus, setUpdateStatus] = useState("");
	const [updateStatusId, setUpdateStatusId] = useState("");
	const [activeUser, setActiveUser] = useState(null);
	const [selectedProfileId, setSelectedProfileId] = useState();
	const displayName = activeUser || user;
	const location = useLocation();
	const ModuleName = location?.pathname.split("/")[1];

	const handleProfileClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleProfileClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	const id = open ? "simple-popover" : undefined;

	const handleSearch = () => {
		navigate("/education/search");
	};
	const handleActiveModule = (name) => {
		setActiveMBModule(name);
		if (userData?.data?.educationId) {
			if (name === "shorts") {
				handleShortVideo();
			} else {
				handleMicroBlogging();
			}
		} else {
			setSignUpPopupToggle(!signUpPopup);
		}
	};
	const getEducationProfilesDetails = () => {
		setIsLoader(true);
		GetAllEducationProfile((res) => {
			const { status, message, data } = res;
			if (status === 200) {
				setUsersData(res.data);
				setIsLoader(false);
			} else {
				setUsersData([]);
				setIsLoader(false);
				setIsMessage(message);
			}
		});
	};
	useEffect(() => {
		getEducationProfilesDetails();
	}, []);

	const { data, refetch } = useQuery({
		queryKey: ["EducationProfile"],
		queryFn: async () => {
			const response = await query("GET", "EDUCATION", "getUserLastEducationProfile");
			setActiveUser(response?.data?.fullName);
			return response.data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	useEffect(() => {
		const filteredData = usersData.filter((user) => user.educationId === data?.educationId);
		setSelectedProfileId(filteredData[0]?.educationId);
	}, [usersData, data]);

	useEffect(() => {
		if (updateStatus === updateStatusId) {
			refetch();
		}
	}, [updateStatus, refetch]);

	const handleUpdateEducationProfile = (updateId) => {
		setActiveUser("");
		if (updateId) {
			setUpdateStatusId(updateId);
			const payload = {
				EducationId: updateId,
			};
			UpdateEducationProfile((res) => {
				setSelectedProfileId(updateId);
				const { status, message, data } = res;
				if (status === 200) {
					localStorage.setItem("educationId", updateId);
					setUpdateStatus(updateId);
				} else {
					setUpdateStatus("error");
				}
			}, payload);
		}
	};
	const toggleAddAccount = () => {
		navigate("/education");
	};
	const handleNavigateProfile = (user) => {
		if (user) {
			const profile = user.educationType.toLowerCase();
			if (ModuleName && profile) {
				navigate(`/${ModuleName}/${profile}/profile/private`, { state: { userData: user } });
				handleProfileClose();
			}
			
		}
	};
	const handleShortVideo = () => {
		if (data) {
			const payload = {
				educationId: data?.educationId,
			};
			GetEducationShortVideosProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					navigate("/shorts/profile/private");
				}
			}, payload);
		}
	};
	const handleMicroBlogging = () => {
		if (data) {
			const payload = {
				educationId: data?.educationId,
			};
			GetEducationMicroBloggingProfile((res) => {
				const { status, message, data } = res;
				if (status === 200) {
					setActiveMBModuleProfile(data);
					navigate("/microblogging/profile/private", {
						state: {
							module: "education",
							profile: data,
						},
					});
				}
			}, payload);
		}
	};

	return (
		<Wrapper isMobile={isMobile}>
			{isMobile ? (
				<MobileWrapper>
					<MobileTopNavs>
						<SearchIcon sx={{ color: color("pWhite"), width: 23 }} onClick={handleSearch} />
						<NotificationsIcon sx={{ color: color("pWhite"), width: 30 }} />
						<span onClick={() => navigate("/messaging")}>
							<Chat />
						</span>
						<span onClick={handleProfileClick}>
							<img src={UserWhite} alt="user-white" />
						</span>
						<Popover
							id={id}
							open={open}
							anchorEl={anchorEl}
							onClose={handleProfileClose}
							placement="bottom-end"
							sx={{
								right: 0,
								width: "50%",
								top: "42px",
								".MuiPaper-root": {
									borderRadius: 0,
									right: "-100%",
									left: "initial !important",
									width: "100%",
									padding: "12px",
								},
							}}
						>
							{displayName !== "Guest" && (
								<>
									{isLoader ? (
										<Loader />
									) : usersData?.length === 0 ? (
										<p>{isMessage}</p>
									) : (
										usersData?.map((user, index) => (
											<Fragment key={index}>
												<ProfileName>
													<img src={User} alt={"user-icon"} /> {user?.educationType}
												</ProfileName>
												<InputWrapper>
													<div onClick={() => handleUpdateEducationProfile(user?.educationId)}>
														<input
															type="radio"
															name="userOption"
															value={user?.educationId}
															checked={user?.educationId === selectedProfileId}
															onChange={() => setSelectedProfileId(user?.educationId)}
														/>
													</div>
													<div
														onClick={() => {
															handleNavigateProfile(user);
															handleUpdateEducationProfile(user?.educationId);
															setSelectedProfileId(user?.educationId);
														}}
													>
														<p>{user.fullName}</p>
													</div>
												</InputWrapper>
											</Fragment>
										))
									)}
									{usersData?.length > 0 && (
										<ButtonWrapper>
											<AddAccount onClick={toggleAddAccount}>
												Add Account <AddIcon />
											</AddAccount>
										</ButtonWrapper>
									)}
								</>
							)}
						</Popover>
						<SidenavMobileComponent module={"education"} color={"#fff"} />
					</MobileTopNavs>
					{/* <MobileQuickNav>
						<QuickNaviation>
							<img src={egicon1} alt="" />
							Micro Blogging
						</QuickNaviation>
						<QuickNaviation>
							<img src={egicon2} alt="" />
							Short Videos
						</QuickNaviation>
					</MobileQuickNav> */}
				</MobileWrapper>
			) : (
				<>
					<LeftNav>
						<NavigationLink>
							Jobs
							<ExpandMoreIcon />
						</NavigationLink>
						<NavigationLink>
							Talent
							<ExpandMoreIcon />
						</NavigationLink>
						<NavigationLink>
							Reports
							<ExpandMoreIcon />
						</NavigationLink>
						<NavigationLink>Messages</NavigationLink>
					</LeftNav>
					<Searchbar onClick={handleSearch}>
						<SearchField
							onClick={handleSearch}
							startAdornment={
								<InputAdornment
									position="start"
									sx={{
										marginInlineEnd: spacing("xs"),
									}}
								>
									<SearchIcon color={color("dimGray")} />
								</InputAdornment>
							}
							// endAdornment={
							// 	<InputAdornment position="end">
							// 		<Dropdown IconComponent={ExpandMoreIcon} variant="standard" defaultValue={"talent"}>
							// 			<MenuItem value="talent">Talent</MenuItem>
							// 			<MenuItem value="tech">Tech</MenuItem>
							// 		</Dropdown>
							// 	</InputAdornment>
							// }
							placeholder="Search"
						/>
					</Searchbar>
					<RightNav>
						<QuickNaviation onClick={() => handleActiveModule("education")}>
							<img src={egicon1} alt="" />
							Micro Blogging
						</QuickNaviation>
						<QuickNaviation onClick={() => handleActiveModule("shorts")}>
							<img src={egicon2} alt="" />
							Short Videos
						</QuickNaviation>
					</RightNav>
				</>
			)}
		</Wrapper>
	);
};

const ProfileName = styled.h5`
	color: #000;
	margin-block: 8px;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;

	display: inline-flex;
	align-items: center;
	column-gap: 12px;

	& > img {
		height: 16px;
		aspect-ratio: 1;
	}
`;
const InputWrapper = styled.div`
	display: flex;
	column-gap: 12px;
	margin: 8px 16px;

	& > div {
		display: inline-flex;
		align-items: center;
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;

	margin-block-start: 16px;
`;

const AddAccount = styled.button`
	width: 100%;
	text-align: center;
	border-radius: 8px;
	background: #3abf81;
	border: 1px solid transparent;
	display: inline-flex;
	justify-content: center;
	padding: 7px 10px;
	align-items: center;
	gap: 2px;
	color: #fff;
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;
const mapStateToProps = (state) => {
	return {
		userData: state.education?.profile,
		signUpPopup: state.education?.signUpPopup,
	};
};
const mapPropsToState = (dispatch) => ({
	setActiveMBModule: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: data,
		}),
	setActiveMBModuleProfile: (data) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE_PROFILE",
			payload: data,
		}),
	setSignUpPopupToggle: (data) =>
		dispatch({
			type: "SET_SIGNUP_TOGGLE_EDUCATION",
			payload: data,
		}),
});
export default connect(mapStateToProps, mapPropsToState)(Header);
