import { fetchCall, fetchLoginCall } from "../utils/ajax";
import { API_CONSTANTS, API_METHODS } from "../utils/api-constant";

export const getRefreshToken = (callback, payload) => {
	const url = `${API_CONSTANTS.GET_REFRESH_TOKEN}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const UserLogin = (callback, payload) => {
	const url = `${API_CONSTANTS.LOGIN}`;
	return fetchCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const UserToken = (callback, payload) => {
	const url = `${API_CONSTANTS.TOKEN}`;
	return fetchCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
		payload,
	);
};

export const UserSignUp = (callback, payload) => {
	const url = `${API_CONSTANTS.SIGNUP}`;
	return fetchCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const UserVerifyEmail = (callback, payload) => {
	const url = `${API_CONSTANTS.VERIFYUSER}`;
	return fetchCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const UserForgotPassword = (callback, payload) => {
	const url = `${API_CONSTANTS.FORGOTPASSWORD}`;
	return fetchCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const UserResetPassword = (callback, payload) => {
	const url = `${API_CONSTANTS.RESETPASSWORD}`;
	return fetchCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const UserGoogleLogin = (callback, payload) => {
	const url = `${API_CONSTANTS.GOOGLELOGIN}`;
	return fetchCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
		payload,
	);
};

export const UserFacebookLogin = (callback, payload) => {
	const url = `${API_CONSTANTS.FACEBOOKLOGIN}`;
	return fetchCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
		payload,
	);
};

export const UserChatGpt = (callback, payload) => {
	const url = `${API_CONSTANTS.CHAT_GPT_RESPONSE}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const GetUserDetails = (callback, payload) => {
	let queryString = "";

	if (payload.token) {
		queryString = `?token=${payload.token}`;
	} else if (payload.searchId) {
		queryString = `?userId=${payload.searchId}`;
	}
	const url = `${API_CONSTANTS.GET_USER_PROFILE}${queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const UpdateUserProfile = (callback, payload) => {
	const firstNameParam = payload?.firstName ? `firstName=${encodeURIComponent(payload.firstName)}` : "";
	const lastNameParam = payload?.lastName ? `lastName=${encodeURIComponent(payload.lastName)}` : "";
	const emailParam = payload?.email ? `email=${encodeURIComponent(payload.email)}` : "";

	const queryParams = [firstNameParam, lastNameParam, emailParam].filter(Boolean).join("&");
	const queryString = queryParams ? `?${queryParams}` : "";

	const url = `${API_CONSTANTS.EDIT_USER_PROFILE}${queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const GetAllUserList = (callback, payload) => {
	const queryString = `?searchName=${payload?.searchName}&pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.GETALLUSERLIST + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const AddFriendRequest = (callback, payload) => {
	const url = `${API_CONSTANTS.SENDFRIENDREQUEST}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const GetAllFriendListSent = (callback, payload) => {
	const url = `${API_CONSTANTS.GETFRIENDREQUEST}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const CancelFriendSentRequest = (callback, payload) => {
	const url = `${API_CONSTANTS.CANCELFRIENDREQUEST}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const GetAllFriendListSentStatus = (callback, payload) => {
	const queryString = `?recipientId=${payload.searchId}`;
	const url = `${API_CONSTANTS.GETFRIENDREQUESTSTATUS}${queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetAllRequestFriendsList = (callback, payload) => {
	const url = `${API_CONSTANTS.GETREQUESTFRIENDLIST}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
		payload,
	);
};

export const GetAllFriendList = (callback, payload) => {
	const queryString = `?userId=${payload?.searchId}`;
	const url = `${API_CONSTANTS.GETALLFRIENDLIST}${queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const ConfirmRequestFriend = (callback, payload) => {
	const url = `${API_CONSTANTS.CONFIRMFRIENDREQUEST}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const CancelReceivedFriendRequest = (callback, payload) => {
	const url = `${API_CONSTANTS.CANCELRECEIVEDFRIENDREQUEST}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const RemoveFriend = (callback, payload) => {
	const url = `${API_CONSTANTS.REMOVEFRIEND}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const GetAllPost = (callback, payload) => {
	const queryString = `?pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.GETALLPOST + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetAllUserPost = (callback, payload) => {
	const queryString = `?userId=${payload?.userId}&pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.GETALLUSERPOST + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const LikePost = (callback, payload) => {
	const url = `${API_CONSTANTS.LIKESPOST}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const GetPostLikes = (callback, payload) => {
	const queryString = `?postId=${payload?.postId}`;
	const url = `${API_CONSTANTS.GETPOSTLIKES + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const PostComment = (callback, payload) => {
	const url = `${API_CONSTANTS.POSTCOMMENT}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const GetAllComments = (callback, payload) => {
	const queryString = `?postId=${payload.postId}`;
	const url = `${API_CONSTANTS.GETALLCOMMENTS + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetAllUserReview = (callback, payload) => {
	const queryString = `?userId=${payload?.userId}&pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.GETALLREVIEW + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const ReviewGPT = (callback, payload) => {
	const url = `${API_CONSTANTS.REVIEWGPT}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const ReviewUser = (callback, payload) => {
	const url = `${API_CONSTANTS.REVIEWUSER}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const ReplyGPT = (callback, payload) => {
	const url = `${API_CONSTANTS.REPLYGPT}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const ReplyUser = (callback, payload) => {
	const url = `${API_CONSTANTS.REPLYUSER}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const GetAllChats = (callback, payload) => {
	const url = `${API_CONSTANTS.GETALLCHATS}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetAllOneToOneChat = (callback, payload) => {
	const queryString = `?chatId=${payload?.chatId}&pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.GETONETONECHATS + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const SendMessage = (callback, payload) => {
	const url = `${API_CONSTANTS.SENDMESSAGE}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const CreateOrJoinChat = (callback, payload) => {
	const url = `${API_CONSTANTS.CREATEORJOINCHAT}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.POST,
		payload,
	);
};

export const GetUserNotifications = (callback, payload) => {
	const url = `${API_CONSTANTS.GETUSERNOTIFICATIONS}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetAllITProfile = (callback, payload) => {
	const url = `${API_CONSTANTS.ITPROFILEDROPDOWN}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const UpdateITProfile = (callback, payload) => {
	const url = `${API_CONSTANTS.ITPROFILEDUPDATE}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const GetITClientProfile = (callback, payload) => {
	const queryString = `?clientId=${payload?.itProfileTypeId}&itProfileId=${payload?.itProfileId}`;
	const url = `${API_CONSTANTS.ITCLIENTPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetITFreelancerProfile = (callback, payload) => {
	const queryString = `?freelancerId=${payload?.itProfileTypeId}&itProfileId=${payload?.itProfileId}`;
	const url = `${API_CONSTANTS.ITFREELANCERPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetITCompanyProfile = (callback, payload) => {
	const queryString = `?companyId=${payload?.itProfileTypeId}&itProfileId=${payload?.itProfileId}`;
	const url = `${API_CONSTANTS.ITCOMPANYPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetITRegularEmployeeProfile = (callback, payload) => {
	const queryString = `?employeeId=${payload?.itProfileTypeId}&itProfileId=${payload?.itProfileId}`;
	const url = `${API_CONSTANTS.ITREGULAREMPLOYEEPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetITSearchProfile = (callback, payload) => {
	const queryString = `?searchName=${payload?.searchName}`;
	const url = `${API_CONSTANTS.ITSEARCHPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetTopFreelancerITProfile = (callback, payload) => {
	const queryString = `?pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.FREELANCERPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetTopEmployeeITProfile = (callback, payload) => {
	const queryString = `?pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.EMPLOYEEPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetTopCompanyITProfile = (callback, payload) => {
	const queryString = `?pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.COMPANYPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetTopClientITProfile = (callback, payload) => {
	const queryString = `?pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.CLIENTPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetShortVideosProfile = (callback, payload) => {
	const url = `${API_CONSTANTS.SHORTVIDEOPROFILE}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetITShortVideosProfile = (callback, payload) => {
	const queryString = `?itProfileId=${payload?.itProfileId}`;
	const url = `${API_CONSTANTS.ITSHORTVIDEOPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetITMicroBloggingProfile = (callback, payload) => {
	const queryString = `?itProfileId=${payload?.itProfileId}`;
	const url = `${API_CONSTANTS.ITMICROBLOGGINGPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetShortVideosPrivateProfile = (callback, payload) => {
	const queryString = `?shortVideoId=${payload?.shortVideoId}`;
	const url = `${API_CONSTANTS.SHORTVIDEOPRIVATEPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetAllShortVideoAccounts = (callback) => {
	const url = `${API_CONSTANTS.SHORTVIDEOALLACCOUNTS}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const UpdateShortVideoAccount = (callback, payload) => {
	const url = `${API_CONSTANTS.SHORTVIDEOACCOUNTUPDATE}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const GetAllSubModuleProfile = (callback, payload) => {
	const url = `${API_CONSTANTS.SUBMODULEALLPROFILES}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetCountryNamesList = (callback) => {
	const url = `${API_CONSTANTS.COUNTRYLIST}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetPublicShortVideoProfile = (callback, payload) => {
	const queryString = `?itProfileId=${payload?.itProfileId}`;
	const url = `${API_CONSTANTS.SHORTVIDEOPUBLICPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const SearchSVAccounts = (callback, payload) => {
	const queryString = `?searchName=${payload?.searchName}&pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.SEARCHSHORTVIDEOACCOUNTS + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetShortVideoUserDetails = (callback, payload) => {
	const queryString = `?shortVideoId=${payload?.shortVideoId}`;
	const url = `${API_CONSTANTS.SHORTVIDEOUSERS + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetITShortVideoPrivateAccount = (callback, payload) => {
	const queryString = `?itProfileId=${payload?.itProfileId}`;
	const url = `${API_CONSTANTS.ITSHORTVIDEOACCOUNT + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

/*Education API*/
export const GetAllEducationProfile = (callback, payload) => {
	const url = `${API_CONSTANTS.EDUCATIONPROFILEDROPDOWN}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const UpdateEducationProfile = (callback, payload) => {
	const url = `${API_CONSTANTS.EDUCATIONPROFILEUPDATE}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

export const GetEducationSearchProfile = (callback, payload) => {
	const queryString = `?searchName=${payload?.searchName}`;
	const url = `${API_CONSTANTS.EDUCATIONSEARCHPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetEducationClientProfile = (callback, payload) => {
	const queryString = `?clientId=${payload?.educationTypeId}&educationId=${payload?.educationId}`;
	const url = `${API_CONSTANTS.EDUCATIONCLIENTPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetEducationFreelancerProfile = (callback, payload) => {
	const queryString = `?freelancerId=${payload?.educationTypeId}&educationId=${payload?.educationId}`;
	const url = `${API_CONSTANTS.EDUCATIONFREELANCERPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetEducationCompanyProfile = (callback, payload) => {
	const queryString = `?companyId=${payload?.educationTypeId}&educationId=${payload?.educationId}`;
	const url = `${API_CONSTANTS.EDUCATIONCOMPANYPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetEducationRegularEmployeeProfile = (callback, payload) => {
	const queryString = `?employeeId=${payload?.educationTypeId}&educationId=${payload?.educationId}`;
	const url = `${API_CONSTANTS.EDUCATIONEMPLOYEEPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetTopClientEducationProfile = (callback, payload) => {
	const queryString = `?pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.CLIENTEDUCATIONPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetTopCompanyEducationProfile = (callback, payload) => {
	const queryString = `?pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.COMPANYEDUCATIONPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetTopEmployeeEducationProfile = (callback, payload) => {
	const queryString = `?pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.EMPLOYEEEDUCATIONPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetTopFreelancerEducationProfile = (callback, payload) => {
	const queryString = `?pageIndex=${payload?.pageIndex}&pageSize=${payload?.pageSize}`;
	const url = `${API_CONSTANTS.FREELANCEREDUCATIONPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetEducationShortVideosProfile = (callback, payload) => {
	const queryString = `?educationId=${payload?.educationId}`;
	const url = `${API_CONSTANTS.EDUCATIONSHORTVIDEOPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};
export const GetEducationMicroBloggingProfile = (callback, payload) => {
	const queryString = `?educationId=${payload?.educationId}`;
	const url = `${API_CONSTANTS.EDUCATIONMICROBLOGGINGPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetEducationShortVideoPrivateAccount = (callback, payload) => {
	const queryString = `?educationId=${payload?.educationId}`;
	const url = `${API_CONSTANTS.EDUCATIONSHORTVIDEOACCOUNT + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const GetEducationPublicShortVideoProfile = (callback, payload) => {
	const queryString = `?educationId=${payload?.educationId}`;
	const url = `${API_CONSTANTS.EDUCATIONSHORTVIDEOPUBLICPROFILE + queryString}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

/* Agriculture APIs */
export const getAllAgricultureProfiles = (callback, payload) => {
	const url = `${API_CONSTANTS.AGRICULTUREPROFILEDROPDOWN}`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.GET,
	);
};

export const updateAgricultureProfile = (callback, payload) => {
	const url = `${API_CONSTANTS.AGRICULTURE_PROFILE_URL}updateLastAgricultureProfile`;
	return fetchLoginCall(
		(response) => {
			callback(response);
		},
		url,
		API_METHODS.PUT,
		payload,
	);
};

