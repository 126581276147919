const Index = ({ size = 18 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 18 18" fill="none">
			<g clipPath="url(#clip0_3071_1731)">
				<path
					d="M8.55024 0.63626C8.28657 0.681963 7.85063 0.829619 7.6643 0.938603C7.57641 0.987823 7.41118 1.10735 7.29516 1.19876C7.17914 1.29017 5.52329 2.92845 3.61782 4.84095C-0.291557 8.76087 -0.0208537 8.44798 0.00727129 8.98235C0.0248494 9.30579 0.0986775 9.47454 0.306099 9.67493C0.50649 9.86829 0.742037 9.9597 1.03735 9.9597C1.50493 9.9597 1.26235 10.1777 5.16821 6.27532C8.33579 3.11126 8.71547 2.74212 8.85258 2.70696C9.1936 2.61907 8.94399 2.39407 12.8323 6.27532C14.7764 8.21595 16.4147 9.8261 16.4709 9.85423C16.5307 9.88235 16.6678 9.92454 16.7803 9.94563C17.1389 10.0124 17.4799 9.90345 17.7225 9.64329C17.9194 9.43938 17.9756 9.29876 17.9932 8.97884C18.0213 8.47259 18.0389 8.50071 16.3971 6.8554L14.9768 5.42806V3.7722C14.9768 2.1972 14.9733 2.10228 14.9065 1.92298C14.8221 1.70149 14.6252 1.49056 14.3897 1.36751C14.1541 1.24798 13.7323 1.24798 13.4967 1.36399C13.3034 1.45892 13.0995 1.65579 12.9975 1.84563C12.9272 1.9722 12.9202 2.04954 12.9096 2.66829L12.8956 3.34681L11.8022 2.26048C10.7334 1.20228 10.4873 0.991338 10.1393 0.840166C9.67875 0.643291 8.99321 0.555401 8.55024 0.63626Z"
					fill="#5E6B86"
				/>
				<path
					d="M8.77187 4.67227C8.7332 4.68985 7.30586 6.10313 5.60078 7.81172L2.5 10.916L2.47891 11.1094C2.46484 11.2148 2.46133 12.3223 2.46836 13.5703C2.48242 15.6375 2.48945 15.8555 2.5457 16.0313C2.7707 16.7168 3.29805 17.209 3.9625 17.3496C4.14883 17.3918 4.53906 17.4023 5.77656 17.4023H7.34805V15.0996V12.7969H9.00039H10.6527V15.0996V17.4023H12.2277C13.4617 17.4023 13.852 17.3918 14.0383 17.3496C14.7027 17.209 15.2301 16.7168 15.4551 16.0313C15.5113 15.8555 15.5184 15.6375 15.5324 13.5703C15.5395 12.3223 15.5359 11.2148 15.5219 11.1094L15.5008 10.916L12.3754 7.79063L9.25 4.66172L9.04609 4.65117C8.93359 4.64766 8.81055 4.65469 8.77187 4.67227Z"
					fill="#C5D0DE"
				/>
			</g>
			<defs>
				<clipPath id="clip0_3071_1731">
					<rect width={size} height={size} fill="white" />
				</clipPath>
			</defs>
		</svg>
	);
};

export default Index;
