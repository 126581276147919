import { useState, Fragment } from "react";
import { FormContainer, FormRowWrapper, CheckboxWrapper, CheckboxGroupTitle, Checkboxes } from "../../index.styled";
import { FormControlLabel, Checkbox } from "@mui/material";

const allSkills = [
	{
		id: 1,
		name: "Doctors and Physicians",
		skills: [
			{
				id: 1,
				name: "General Medicine",
			},
			{
				id: 2,
				name: "Pediatrics",
			},
			{
				id: 3,
				name: "Cardiology",
			},
			{
				id: 4,
				name: "Dermaology",
			},
			{
				id: 5,
				name: "Psychiatry",
			},
		],
	},
	{
		id: 2,
		name: "Technicians and Technologists",
		skills: [
			{
				id: 1,
				name: "Radiology technicians",
			},
			{
				id: 2,
				name: "Laboratory technicians",
			},
			{
				id: 3,
				name: "Surgical technicians",
			},
			{
				id: 4,
				name: "Pharmacy Technicians",
			},
		],
	},
	{
		id: 3,
		name: "Nurses and Nurse Practitioners",
		skills: [
			{
				id: 1,
				name: "Critical Care",
			},
			{
				id: 2,
				name: "Pediatric",
			},
			{
				id: 3,
				name: "Geriatric",
			},
			{
				id: 4,
				name: "Surgical",
			},
			{
				id: 5,
				name: "Psychiatric",
			},
			{
				id: 6,
				name: "Community Health Nursing",
			},
		],
	},
	{
		id: 4,
		name: "Dietitians and Nutritionists",
		skills: [
			{
				id: 1,
				name: "Clinical nutrition",
			},
			{
				id: 2,
				name: "Sports nutrition",
			},
			{
				id: 3,
				name: "Pediatric nutrition",
			},
			{
				id: 4,
				name: "Dietetic counseling",
			},
		],
	},
	{
		id: 5,
		name: "Therapists and Counselors",
		skills: [
			{
				id: 1,
				name: "Psychotherapy",
			},
			{
				id: 2,
				name: "Physical Therapy",
			},
			{
				id: 3,
				name: "Speech therapy",
			},
			{
				id: 4,
				name: "Addiction Counseling",
			},
		],
	},
	{
		id: 6,
		name: "Optometrists and Ophthalmologists",
		skills: [
			{
				id: 1,
				name: "Optometrists",
			},
			{
				id: 2,
				name: "Ophthalmologists",
			},
			{
				id: 3,
				name: "Opticians",
			},
		],
	},
	{
		id: 7,
		name: "Dentists and Dental Specialists",
		skills: [
			{
				id: 1,
				name: "Including general dentistry",
			},
			{
				id: 2,
				name: "Orthodontics",
			},
			{
				id: 3,
				name: "Periodontics",
			},
			{
				id: 4,
				name: "Endodontics",
			},
			{
				id: 5,
				name: "Oral Surgery",
			},
		],
	},
	{
		id: 8,
		name: "Alternative Medicine Practitioners",
		skills: [
			{
				id: 1,
				name: "Acupuncturists",
			},
			{
				id: 2,
				name: "Chiropractors",
			},
			{
				id: 3,
				name: "Naturopaths",
			},
			{
				id: 4,
				name: "Ayurvedic Practitioner",
			},
		],
	},
	{
		id: 9,
		name: "Pharmacists",
		skills: [
			{
				id: 1,
				name: "Retail",
			},
			{
				id: 2,
				name: "Clinical",
			},
			{
				id: 3,
				name: "Naturopaths",
			},
			{
				id: 4,
				name: "Compounding pharmacy",
			},
		],
	},
	{
		id: 10,
		name: "Administrative and Support Staff",
		skills: [
			{
				id: 1,
				name: "Medical administrators",
			},
			{
				id: 2,
				name: "Healthcare managers",
			},
			{
				id: 3,
				name: "Medical Secretaries",
			},
			{
				id: 4,
				name: "Healthcare IT professionals",
			},
		],
	},
];

const renderSkills = (activeSkills, setActiveSkills) => {
	const rows = [];
	const handleCheckbox = (event, id) => {
		if (event.target.checked) {
			setActiveSkills([...activeSkills, id]);
		} else {
			setActiveSkills(activeSkills.filter((skill) => skill !== id));
		}
	};

	for (let i = 0; i < allSkills.length; i += 2) {
		rows.push(
			<FormRowWrapper key={allSkills[i]?.id}>
				<CheckboxWrapper>
					<CheckboxGroupTitle>{allSkills[i]?.name}</CheckboxGroupTitle>
					<Checkboxes>
						{allSkills[i]?.skills?.map((skill) => (
							<FormControlLabel
								control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
								label={skill?.name}
							/>
						))}
					</Checkboxes>
				</CheckboxWrapper>
				{i + 1 < allSkills.length && (
					<CheckboxWrapper>
						<CheckboxGroupTitle>{allSkills[i + 1]?.name}</CheckboxGroupTitle>
						<Checkboxes>
							{allSkills[i + 1]?.skills?.map((skill) => (
								<FormControlLabel
									control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
									label={skill?.name}
								/>
							))}
						</Checkboxes>
					</CheckboxWrapper>
				)}
			</FormRowWrapper>,
		);
	}

	return rows;
};

const FreelancerSkills = () => {
	const [activeSkills, setActiveSkills] = useState([]);
	return (
		<Fragment>
			<FormContainer>{renderSkills(activeSkills, setActiveSkills)}</FormContainer>
		</Fragment>
	);
};

export default FreelancerSkills;
