import styled from "@emotion/styled";

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 30px;

	@media screen and (max-width: 768px) {
		margin-inline: 12px;
	}
`;

const DetailsWrapper = styled.div`
	display: flex;
	column-gap: 12px;
	align-items: center;
`;

const Image = styled.img`
	width: 126px;
	height: 126px;
	border-radius: 50%;
`;

const Details = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: 6px;
`;

const Name = styled.h3`
	color: #000;

	/* font-weight-bold-xl */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

const UserName = styled.h4`
	color: #797979;

	/* body-text-sm */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const VideosSection = styled.div`
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	row-gap: 24px;

	margin-block-end: 24px;
`;

const SectionTitle = styled.h3`
	grid-column: span 7;
	color: #000;

	/* font-weight-bold-lg */
	font-family: Inter;
	font-size: 16px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	padding-bottom: 4px;

	border-bottom: 1px solid #d9d9d9;
`;

const Videos = styled.div`
	grid-column: span 6;
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	column-gap: 20px;
	row-gap: 28px;

	@media screen and (max-width: 768px) {
		grid-column: span 7;
		display: flex;
		flex-direction: column;
	}
`;

const VideoCard = styled.div`
	/* grid-column: span 1; */
	display: flex;
	flex-direction: column;
	row-gap: 8px;
	@media screen and (max-width: 768px) {
		flex-direction: row;
		column-gap: 16px;
	}
`;

const VideoThumbail = styled.img`
	width: 100%;
	min-height: 120px;
	border-radius: 12px;
	@media screen and (max-width: 768px) {
		max-width: 50%;
	}
`;

const VideoInfoWrapper = styled.div`
	@media screen and (max-width: 768px) {
		display: flex;
		flex-direction: column;
		row-gap: 12px;
	}
`;

const Title = styled.h3`
	color: #676464;

	/* font-weight-medium-md */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const Channel = styled.h4`
	display: inline-flex;
	align-items: center;
	column-gap: 8px;
	color: #797979;

	/* font-weight-medium-md */
	font-family: Inter;
	font-size: 14px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const Info = styled.h4`
	color: #000;

	/* body-text-xsm */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

export {
	Wrapper,
	DetailsWrapper,
	Image,
	Details,
	Name,
	UserName,
	VideosSection,
	SectionTitle,
	Videos,
	VideoCard,
	VideoThumbail,
	VideoInfoWrapper,
	Title,
	Channel,
	Info,
};
