import { Dialog, Paper } from "@mui/material";
import { DialogTitle, DialogContent } from "@mui/joy";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { color } from "../../styles/variables";
import useLayout from "../../utils/hooks/useLayout";

const Modal = ({ open, title, onClose, children, fullScreen = false, fullWidth = true, noIcon = false }) => {
	const isMobile = useLayout();
	return title ? (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{ "& .MuiDialog-paper": { margin: 1 } }}
			fullWidth={!fullWidth}
			fullScreen={fullScreen}
		>
			<DialogTitle
				sx={{
					m: 0,
					mx: 2,
					py: 2,
					justifyContent: "space-between",
					alignItems: "center",
					borderBottom: "1px solid #D9D9D9",
					color: "#000",
					fontFamily: "Poppins",
					fontSize: "24px",
					fontStyle: "normal",
					fontWeight: 400,
					lineHeight: "128%" /* 35.84px */,
				}}
				id="customized-dialog-title"
			>
				{title && title}
				{!noIcon && (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							color: "#928D8D",
							marginLeft: "auto",
							p: 1,
							backgroundColor: "#D9D9D9",
							"&:hover": {
								color: color("pGreen"),
							},
						}}
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<DialogContent
				dividers
				sx={{
					p: 2,
				}}
			>
				{children}
			</DialogContent>
		</Dialog>
	) : (
		<Dialog
			open={open}
			onClose={onClose}
			sx={{
				"& .MuiDialog-paper": {
					margin: 1,
					backgroundColor: "transparent",
					boxShadow: "none",
					height: "auto",
				},
				backgroundColor: "rgba(255,255,255,0.7)",
			}}
			fullWidth
			fullScreen={isMobile}
		>
			<DialogTitle
				sx={{ m: 0, p: 2, px: 0, justifyContent: "space-between", alignItems: "center" }}
				id="customized-dialog-title"
			>
				{!noIcon && (
					<IconButton
						aria-label="close"
						onClick={onClose}
						sx={{
							color: "#928D8D",
							marginLeft: "auto",
							p: 1,
							backgroundColor: "#D9D9D9",
							"&:hover": {
								color: color("pGreen"),
							},
						}}
					>
						<CloseIcon />
					</IconButton>
				)}
			</DialogTitle>
			<Paper
				elevation={6}
				sx={{
					p: 2,
				}}
			>
				<DialogContent
					dividers
					sx={{
						backgroundColor: "#fff",
						borderRadius: "4px",
					}}
				>
					{children}
				</DialogContent>
			</Paper>
		</Dialog>
	);
};

export default Modal;
