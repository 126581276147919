import styled from "@emotion/styled";
import { color, spacing } from "../../../styles/variables";

const Wrapper = styled.div`
	background-color: ${color("pGreen")};

	display: grid;
	grid-column-gap: ${spacing("xxxl")};
	grid-template-columns: repeat(1, 1fr);
	width: 100%;

	${(props) =>
		props.isMobile &&
		`
			
			grid-template-columns: repeat(1, 1fr);
			padding-inline: ${spacing("md")}
  		`};

	@media screen and (min-width: 1440px) {
		& > div {
			max-width: 1440px;
		}
	}
`;

const SubWrapper = styled.div`
	margin-inline: ${spacing("xxxl1")};
	padding-block: ${spacing("md")};
	width: -webkit-fill-available;

	display: grid;
	grid-column-gap: ${spacing("xxxl")};
	grid-template-columns: repeat(8, 1fr);

	${(props) =>
		props.isMobile &&
		`
			grid-template-columns: repeat(1, 1fr);
			padding-inline: ${spacing("md")}
  		`};

	@media screen and (min-width: 1440px) {
		margin-inline: auto;
	}
	@media screen and (max-width: 768px) {
		margin-inline: 0;
	}
`;

const MobileWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("lg")};
`;

const MobileTopNavs = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;

	& > span {
		display: inline-flex;
		column-gap: 16px;
		align-items: center;
	}
`;

export { Wrapper, SubWrapper, MobileWrapper, MobileTopNavs };
