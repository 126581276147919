import { useState, Fragment } from "react";
import { FormContainer, FormRowWrapper, CheckboxWrapper, CheckboxGroupTitle, Checkboxes } from "../../index.styled";
import { FormControlLabel, Checkbox } from "@mui/material";

const allSkills = [
	{
		id: 1,
		name: "Personal Vahicle Services",
		skills: [
			{
				id: 1,
				name: "Sedan Services",
			},
			{
				id: 2,
				name: "SUV Services",
			},
			{
				id: 3,
				name: "Luxury Car Services",
			},
		],
	},
	{
		id: 2,
		name: "Non-traditional Vehicle Services",
		skills: [
			{
				id: 1,
				name: "Motorcycle or Scooter Services",
			},
			{
				id: 2,
				name: "Bicycle Services",
			},
		],
	},
	{
		id: 3,
		name: "Commercial Vehicle Services",
		skills: [
			{
				id: 1,
				name: "Trucking Services",
			},
			{
				id: 2,
				name: "Van Services",
			},
		],
	},
	{
		id: 4,
		name: "Water-based Transportation",
		skills: [
			{
				id: 1,
				name: "Boat Services",
			},
			{
				id: 2,
				name: "Yacth Services",
			},
			{
				id: 3,
				name: "Ferry Services",
			},
		],
	},
	{
		id: 5,
		name: "Specialized Transportation Services",
		skills: [
			{
				id: 1,
				name: "Limousine Services",
			},
			{
				id: 2,
				name: "Handicap Accessible Vehicle Services",
			},
			{
				id: 3,
				name: "Pet-friendly Transportation.",
			},
		],
	},
	{
		id: 6,
		name: "Air Transportation",
		skills: [
			{
				id: 1,
				name: "Helicopter Services",
			},
			{
				id: 2,
				name: "Private Airplane Services",
			},
		],
	},
	{
		id: 7,
		name: "Public Transport Style Services",
		skills: [
			{
				id: 1,
				name: "Shuttle Services",
			},
			{
				id: 2,
				name: "Mini-bus Services",
			},
		],
	},
	{
		id: 8,
		name: "Special Services",
		skills: [
			{
				id: 1,
				name: "Tourist Guide cum Driver",
			},
			{
				id: 2,
				name: "Emergency Vehicle Services",
			},
		],
	},
];

const renderSkills = (activeSkills, setActiveSkills) => {
	const rows = [];
	const handleCheckbox = (event, id) => {
		if (event.target.checked) {
			setActiveSkills([...activeSkills, id]);
		} else {
			setActiveSkills(activeSkills.filter((skill) => skill !== id));
		}
	};

	for (let i = 0; i < allSkills.length; i += 2) {
		rows.push(
			<FormRowWrapper key={allSkills[i]?.id}>
				<CheckboxWrapper>
					<CheckboxGroupTitle>{allSkills[i]?.name}</CheckboxGroupTitle>
					<Checkboxes>
						{allSkills[i]?.skills?.map((skill) => (
							<FormControlLabel
								control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
								label={skill?.name}
							/>
						))}
					</Checkboxes>
				</CheckboxWrapper>
				{i + 1 < allSkills.length && (
					<CheckboxWrapper>
						<CheckboxGroupTitle>{allSkills[i + 1]?.name}</CheckboxGroupTitle>
						<Checkboxes>
							{allSkills[i + 1]?.skills?.map((skill) => (
								<FormControlLabel
									control={<Checkbox onChange={(event) => handleCheckbox(event, skill?.id)} />}
									label={skill?.name}
								/>
							))}
						</Checkboxes>
					</CheckboxWrapper>
				)}
			</FormRowWrapper>,
		);
	}

	return rows;
};

const FreelancerSkills = () => {
	const [activeSkills, setActiveSkills] = useState([]);
	return (
		<Fragment>
			<FormContainer>{renderSkills(activeSkills, setActiveSkills)}</FormContainer>
		</Fragment>
	);
};

export default FreelancerSkills;
