import { Fragment } from "react";
import InputField from "../../../../Inputs/Input";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";
import Textarea from "../../../../Inputs/Textarea";
import { FormContainer, FormTitleInfo, FormRowWrapper } from "../index.styled";
import Autocomplete from "../../../../Inputs/Autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
	firstName: Yup.string().required("First Name is required"),
	lastName: Yup.string().required("Last Name is required"),
	location: Yup.string().required("Location is required"),
	file_upload: Yup.string().required("Profile Picture is required"),
});

const CompanySignUpForm = ({ setProfilePicture, onSubmit }) => {
	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
			location: "",
			file_upload: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onSubmit(values);
		},
	});

	const handleChange = (e, value) => {
		localStorage.setItem("locationED", value);
	};
	return (
		<Fragment>
			<FormContainer>
				<FormTitleInfo>Company Basic Information</FormTitleInfo>
				<FormRowWrapper>
					<InputField name={"companyName"} label={"Company Name"} required />
					<InputField
						name={"typeOfBusiness"}
						label={"Type of Business"}
						placeholder={"LLC, Corporation, Partnership"}
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"industrySector"} label={"Industry Sector"} />
					<InputField name={"companySize"} label={"Company Size"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<Autocomplete
						name={"location"}
						label={"Location"}
						required
						error={formik.touched.location && Boolean(formik.errors.location)}
						helperText={formik.touched.location && formik.errors.location}
						onChange={(e, value) => handleChange(e, value)}
						getOptionLabel={(option) => option.label}
						onBlur={formik.handleBlur}
					/>
					<InputFieldWithUpload
						name={"file_upload"}
						onChange={setProfilePicture}
						label={"Profile Picture"}
						required
						error={formik.touched.file_upload && Boolean(formik.errors.file_upload)}
						helperText={formik.touched.file_upload && formik.errors.file_upload}
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"websiteURL"} label={"Website URL"} />
				</FormRowWrapper>
				<FormRowWrapper childCount={1}>
					<Textarea name={"about"} label={"About"} rows={4} mobileRows={8} />
				</FormRowWrapper>
			</FormContainer>
		</Fragment>
	);
};

export default CompanySignUpForm;
