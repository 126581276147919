import { Fragment } from "react";
import InputField from "../../../../Inputs/Input";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";
import Textarea from "../../../../Inputs/Textarea";
import { FormContainer, FormTitleInfo, FormRowWrapper } from "../index.styled";

const FreelancerSignUp = () => {
	return (
		<Fragment>
			<FormContainer>
				<FormTitleInfo>Personal Information</FormTitleInfo>
				<FormRowWrapper>
					<InputField name={"firstName"} label={"First Name"} required />
					<InputField name={"lastName"} label={"Last Name"} required />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"location"} label={"Location"} required />
					<InputFieldWithUpload name={"profilePicture"} label={"Profile Picture"} />
				</FormRowWrapper>
				<FormRowWrapper childCount={1}>
					<Textarea
						name={"about"}
						label={"About"}
						rows={4}
						mobileRows={12}
						placeholder={"A detailed professional overview highlighting skills and experience"}
					/>
				</FormRowWrapper>
			</FormContainer>
		</Fragment>
	);
};

export default FreelancerSignUp;
