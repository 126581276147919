import { Fragment } from "react";
import InputField, { CustomLabel } from "../../../../Inputs/Input";
import Textarea from "../../../../Inputs/Textarea";
import { FormContainer, FormTitleInfo, FormRowWrapper } from "../index.styled";
import { spacing } from "../../../../../styles/variables";
import InputFieldWithUpload from "../../../../Inputs/Input/InputWithUpload";
import Autocomplete from "../../../../Inputs/Autocomplete";
import { useFormik } from "formik";
import * as Yup from "yup";

const validationSchema = Yup.object({
	firstName: Yup.string().required("First Name is required"),
	lastName: Yup.string().required("Last Name is required"),
});
const EmployeeSignUpForm = ({ setProfilePicture, onSubmit }) => {
	const formik = useFormik({
		initialValues: {
			firstName: "",
			lastName: "",
		},
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onSubmit(values);
		},
	});

	const handleChange = (e, value) => {
		localStorage.setItem("locationED", value);
	};

	return (
		<Fragment>
			<FormContainer>
				<FormTitleInfo>Personal Information</FormTitleInfo>
				<FormRowWrapper>
					<InputField
						name={"firstName"}
						label={"First Name"}
						required
						error={formik.touched.firstName && Boolean(formik.errors.firstName)}
						helperText={formik.touched.firstName && formik.errors.firstName}
					/>
					<InputField
						name={"lastName"}
						label={"Last Name"}
						required
						error={formik.touched.lastName && Boolean(formik.errors.lastName)}
						helperText={formik.touched.lastName && formik.errors.lastName}
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<Autocomplete name={"location"} label={"Location"} required onChange={(e, value) => handleChange(e, value)} />
					<InputFieldWithUpload
						name={"file_upload"}
						onChange={setProfilePicture}
						label={"Profile Picture"}
						required
						error={formik.touched.file_upload && Boolean(formik.errors.file_upload)}
						helperText={formik.touched.file_upload && formik.errors.file_upload}
					/>
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"jobTitle"} label={"Current Job Title"} placeholder={"eg. Web Designer, Developer"} />
					<InputField name={"companyName"} label={"Company Name (If currently employed)"} />
				</FormRowWrapper>
				<FormRowWrapper>
					<InputField name={"industrySector"} label={"Industry/Sector"} />
					<InputField name={"skill"} label={"Skills & Expertise"} />
				</FormRowWrapper>
				<FormRowWrapper
					childCount={1}
					style={{
						gridRowGap: spacing("md2"),
					}}
				>
					<CustomLabel>Education History</CustomLabel>
					<FormRowWrapper childCount={3} colGap={spacing("md1")}>
						<InputField name={"educationDegree"} placeholder={"Degree"} required />
						<InputField name={"educationInstitution"} placeholder={"Institution"} required />
						<InputField name={"gradYear"} placeholder={"Graduation Year"} required />
					</FormRowWrapper>
				</FormRowWrapper>
				<FormRowWrapper childCount={1}>
					<Textarea name={"about"} label={"About"} rows={3} mobileRows={6} />
				</FormRowWrapper>
			</FormContainer>
		</Fragment>
	);
};

export default EmployeeSignUpForm;
