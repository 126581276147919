import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import egicon4 from "../../../assets/images/icons/education.png";
import InitialSignupForm from "../../../components/Education/Forms/Signup/InitialSignupForm";
import Heading from "../../../components/Heading/Heading";
import Loader from "../../../components/loader";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../utils/queryController";

const SignupMain = ({ handleClick, id }) => {
	const location = useLocation();
	const { pathname } = location;
	const [isUser, setIsUser] = useState(false);
	const [activeProfile, setActiveProfile] = useState("Thomas Mark");

	const { data, isLoading } = useQuery({
		queryKey: ["EducationProfile"],
		queryFn: async () => {
			const response = await query("GET", "EDUCATION", "getUserLastEducationProfile");
			setActiveProfile(response?.data?.fullName);
			return response.data;
		},

		refetchOnWindowFocus: false,
		refetchIntervalInBackground: false,
	});

	useEffect(() => {
		const path = pathname.split("/");
		path.shift();

		if (path.includes("private") || path.includes("public")) setIsUser(true);
		else setIsUser(false);

		if (path.includes("company")) setActiveProfile("Paychex");
		else setActiveProfile("Thomas Mark");
	}, [pathname]);
	return (
		<>
			<Heading
				icon={egicon4}
				title={"Education"}
				user={isUser ? activeProfile : "Guest"}
				moduleHomeRedirect={"/education/home"}
				handleClick={handleClick}
				id={id}
			/>
			
			{isLoading ? <Loader /> : <InitialSignupForm />}
		</>
	);
};

export default SignupMain;
