import React from "react";
import { EduWrapperCenter, EduWrapperLeft, EduWrapperRight, Wrapper } from "../styles/index.styled";
import LeftSubNav from "../../../components/LeftSubNav";
import MBSearch from "../../../components/MB/MBSearch/MBSearch";
import MBMobileBottom from "../../../components/MB/MBMobileBottom/MBMobileBottom";
import Page from "../../../layouts/Page";

const MBExplore = () => {
	return (
		<>
			<Page>
				<EduWrapperLeft>
					<LeftSubNav />
				</EduWrapperLeft>
				<EduWrapperCenter>
					<MBSearch />
				</EduWrapperCenter>
				<EduWrapperRight />
			</Page>
			<MBMobileBottom />
		</>
	);
};

export default MBExplore;
