import React from "react";
import location from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/IT/itprofile1.png";
import editablenoborder from "../../../../../assets/images/editablenoborder.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent";
import Button from "../../../../../components/button/index.js";
import message from "../../../../../assets/icons/mi_message.png";
import outline from "../../../../../assets/icons/material-symbols_rate-review-outline.png";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfilesWrapper,
	Butt,
	ProfileModule,
} from "../../../styles/index.styled.js";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../../../utils/queryController.js";
import { connect } from "react-redux";
import Loader from "../../../../../components/loader/index.js";

const ProfilePrivate = ({ setActiveMBProfile, setActiveModule }) => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const { state } = useLocation();
	const userData = state?.user;
	const handlePublicProfileNav = (path) => {
		return;
		navigate(`/${path}`);
	};

	const { data, isLoading } = useQuery({
		queryKey: "AgricultureCompanyProfile",
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getAgricultureCompanyProfile?companyId=${userData?.companyId}&agricultureId=${userData?.agricultureId}`,
			);
			const { data } = response;
			return data;
		},
	});

	const { isLoading: isShortVideoAccountLoading, refetch: fetchShortVideos } = useQuery({
		queryKey: "AgriculturePrivateShortVideosProfile",
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getPrivateAgricultureShortVideoProfile?agricultureId=${data?.agricultureId}`,
			);
			const { data: profileData, status } = response;
			if (status === 200) {
				navigate("/shorts/profile/public", {
					state: {
						user: profileData,
					},
				});
			}
			return data;
		},
		enabled: false,
	});

	const handleMBRedirection = () => {
		setActiveModule("ag");
		setActiveMBProfile(data?.agricultureId);
		navigate("/microblogging/profile/public");
	};

	if (isLoading || isShortVideoAccountLoading) {
		return <Loader />;
	}

	return (
		<>
			{token ? (
				<Wrapper>
					<ProfileWrapper>
						<ProfileSections>
							<SectionTitle>Company</SectionTitle>
							<Profile>
								<ProfileDetailsWrapper>
									<ProfileImageWrapper>
										<img src={data?.companyLogo || ProfilePicture} alt="profile" />
									</ProfileImageWrapper>
									<UserDetailsWrapper>
										<UserNameWrapper>
											<UserName>{data?.companyName || "Anonymous user"}</UserName>
										</UserNameWrapper>
										<UserAddress>
											{/* <img src={location} alt="location-icon" />
											United States, India - 10:46 am local time <br />
											Human Resources Services Payroll Software Rochester, NY
											<br />
											10,001+ employees
											<br />
											www.paychex.com */}
											<img src={location} alt="location-icon" />
											{data?.location || "No Location available"}
										</UserAddress>
									</UserDetailsWrapper>
								</ProfileDetailsWrapper>
								<UserProfileActions>
									<Button buttonClick={() => handlePublicProfileNav("message")}>
										<img src={message} alt="message" />
										Message
									</Button>
									<Button buttonClick={() => handlePublicProfileNav("review")}>
										<img src={outline} alt="review" />
										Review
									</Button>
								</UserProfileActions>
							</Profile>

							<MoreDetailWrapper>
								<ProfilesAndAboutWrapper>
									<ProfilesWrapper>
										<Profiles fillAvailable>
											<ProfileHeading>Other Profiles</ProfileHeading>
											<ProfileModule>
												<Butt onClick={handleMBRedirection}>Microblogging</Butt>
												<Butt onClick={fetchShortVideos}>ShortVideos</Butt>
											</ProfileModule>
											<ProfileList>
												{data?.userName && (
													<ProfileName>
														<ProfileUserName>userName </ProfileUserName>
														{data?.userName}
													</ProfileName>
												)}
												{data?.shortVideoUsername && (
													<ProfileName>
														<ProfileUserName>shortVideoUsername </ProfileUserName>
														{data?.shortVideoUsername}
													</ProfileName>
												)}
											</ProfileList>
										</Profiles>
									</ProfilesWrapper>
									<About card>
										<AboutTitle>
											About <img src={editablenoborder} alt="" />
										</AboutTitle>
										<AboutDesc noClamp>{data?.about || "No Data available"}</AboutDesc>
									</About>
								</ProfilesAndAboutWrapper>
								<FeedbackWrapper>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment1}
											commentimagestyle={{
												marginLeft: "2rem",
											}}
											commenttitle="Thanks"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment}
											commenttitle="Reply"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
								</FeedbackWrapper>
							</MoreDetailWrapper>
						</ProfileSections>
					</ProfileWrapper>
				</Wrapper>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(ProfilePrivate);
