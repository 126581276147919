import React from "react";
import styled from "@emotion/styled";
import PrimaryBtn from "../../Buttons/Primary";
import Modal from "../../Modal";
import { useLocation, useNavigate } from "react-router-dom";

const RegistrationModal = ({ open, handleClose }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const ModuleName = location?.pathname.split("/")[1];
	const handleSignup = () => {
		if (ModuleName) {
			handleClose();
			navigate(`/${ModuleName}`);
		}
	};
	
	return (
		<Modal open={open} onClose={handleClose} noIcon>
			<Wrapper>
				<Info>Please register to access all features and upgrade from a guest to a full member. It’s FREE!!</Info>
				<ButtonWrapper>
					<PrimaryBtn
						label={"Cancel"}
						overrides={{
							fontSize: "18px",
						}}
						onClick={handleClose}
					/>
					<PrimaryBtn
						label={"Continue"}
						overrides={{
							fontSize: "18px",
						}}
						onClick={handleSignup}
					/>
				</ButtonWrapper>
			</Wrapper>
		</Modal>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 18px;
`;

const Info = styled.p`
	color: #000;
	text-align: center;
	font-family: Poppins;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 129.5%; /* 40.145px */
`;

const ButtonWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: center;
	column-gap: 20px;
	width: 100%;

	margin-block-start: 18px;

	& > button {
		min-width: 35%;
	}

	@media screen and (max-width: 768px) {
		flex-direction: column-reverse;
		row-gap: 20px;
	}
`;

export default RegistrationModal;
