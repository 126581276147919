import React from "react";
import "./EngineeringSignUp2.scss";
import Heading from "../../components/Heading/Heading";
import icon from "../../assets/icons/engineering.png";
import { Radio, TextField } from "@mui/material";
import { Navigate, useNavigate } from "react-router-dom";

const Engineeringsignup2 = () => {
	const navigate = useNavigate();
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const [selectedValue, setSelectedValue] = React.useState("a");
	const handleChange = (event) => {
		setSelectedValue(event.target.value);
	};
	const controlProps = (item) => ({
		checked: selectedValue === item,
		onChange: handleChange,
		value: item,
		name: "size-radio-button-demo",
		inputProps: { "aria-label": item },
	});

	const handleNav = () => {
		navigate("/engineering-home");
	};

	return (
		<>
			{token ? (
				<div className="engineeringsignup2">
					<Heading icon={icon} title={"Engineering"} showSearchInput={false} />
					<div className="engineeringsignup2_container">
						<div className="engineeringsignup2_container_content">
							<div className="engineeringsignup2_container_content_top">
								<p>Select Your Engineering Discipline</p>
							</div>
							<div className="engineeringsignup2_container_content_bottom">
								<div className="engineeringsignup2_container_content_bottom_top">
									<p>Eng-FR/</p>
									<TextField placeholder="Pick a username" />
								</div>
								<div className="engineeringsignup2_container_content_bottom_mec">
									<Radio
										{...controlProps("c")}
										sx={{
											"& .MuiSvgIcon-root": {
												fontSize: 20,
											},
										}}
									/>
									<p>Mechnical Engineering</p>
								</div>
								<div className="engineeringsignup2_container_content_bottom_mec_data">
									<div className="engineeringsignup2_container_content_bottom_mec_data_types">
										<Radio
											{...controlProps("c")}
											sx={{
												"& .MuiSvgIcon-root": {
													fontSize: 18,
												},
											}}
										/>
										<p>Thermal engineering</p>
									</div>
									<div className="engineeringsignup2_container_content_bottom_mec_data_types">
										<Radio
											{...controlProps("c")}
											sx={{
												"& .MuiSvgIcon-root": {
													fontSize: 18,
												},
											}}
										/>
										<p>Automotive engineering</p>
									</div>
									<div className="engineeringsignup2_container_content_bottom_mec_data_types">
										<Radio
											{...controlProps("c")}
											sx={{
												"& .MuiSvgIcon-root": {
													fontSize: 18,
												},
											}}
										/>
										<p>Aerospace engineering</p>
									</div>
									<div className="engineeringsignup2_container_content_bottom_mec_data_types">
										<Radio
											{...controlProps("c")}
											sx={{
												"& .MuiSvgIcon-root": {
													fontSize: 18,
												},
											}}
										/>
										<p>Biomechanics</p>
									</div>
								</div>
								<div className="engineeringsignup2_container_content_bottom_mec">
									<Radio
										{...controlProps("c")}
										sx={{
											"& .MuiSvgIcon-root": {
												fontSize: 20,
											},
										}}
									/>
									<p>Civil Engineering</p>
								</div>
								<div className="engineeringsignup2_container_content_bottom_mec">
									<Radio
										{...controlProps("c")}
										sx={{
											"& .MuiSvgIcon-root": {
												fontSize: 20,
											},
										}}
									/>
									<p>Electrical Engineering</p>
								</div>
								<div className="engineeringsignup2_container_content_bottom_mec">
									<Radio
										{...controlProps("c")}
										sx={{
											"& .MuiSvgIcon-root": {
												fontSize: 20,
											},
										}}
									/>
									<p>Software Engineering</p>
								</div>
								<div className="engineeringsignup2_container_content_bottom_mec">
									<Radio
										{...controlProps("c")}
										sx={{
											"& .MuiSvgIcon-root": {
												fontSize: 20,
											},
										}}
									/>
									<p>Chemical Engineering</p>
								</div>
								<div className="engineeringsignup2_container_content_bottom_mec">
									<Radio
										{...controlProps("c")}
										sx={{
											"& .MuiSvgIcon-root": {
												fontSize: 20,
											},
										}}
									/>
									<p>Boimedical Engineering</p>
								</div>
							</div>
						</div>
					</div>
					<div className="engineeringsignup2_button">
						<div className="engineeringsignup2_button_content">
							<button onClick={handleNav}>Submit</button>
						</div>
					</div>
				</div>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

export default Engineeringsignup2;
