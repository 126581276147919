import SearchIcon from "@mui/icons-material/Search";
import useLayout from "../../../utils/hooks/useLayout";
import { color } from "../../../styles/variables";
import { MobileTopNavs, MobileWrapper, SubWrapper, Title, Wrapper } from "./index.styled";

import SidenavMobileComponent from "../../SidenavMobileComponent";
import { Box } from "@mui/material";

import Home from "../../../assets/icons/shorts/mobile/Home";
import Messages from "../../../assets/icons/shorts/mobile/Messages";
import Like from "../../../assets/icons/Like";
import Matches from "../../../assets/icons/Matches";
import Spinlove from "../../../assets/icons/Spinlove";
import User from "../../../assets/icons/User";
import SettingsSwitch from "../../../assets/icons/SettingsSwitch";
import { useNavigate } from "react-router-dom";

const Header = () => {
	const isMobile = useLayout();
	const navigate = useNavigate();

	const handleSearch = () => {
		// navigate("/mg/search");
	};

	const handleProfileNav = () => {
		navigate("/dating/profile");
	};
	const handleHomeNav = () => {
		navigate("/dating/home");
	};

	return (
		<Wrapper>
			<SubWrapper isMobile={isMobile}>
				{(isMobile && (
					<MobileWrapper>
						<MobileTopNavs>
							<SearchIcon sx={{ color: color("pWhite"), width: 22 }} onClick={handleSearch} />
							<span>
								<SettingsSwitch size={18} />
							</span>
							<span onClick={handleProfileNav}>
								<User size={18} color="white" />
							</span>
							<SidenavMobileComponent module={"dating"} color={"#fff"} />
						</MobileTopNavs>
					</MobileWrapper>
				)) || (
					<Box
						gridColumn={"span 8"}
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						columnGap={"72px"}
						sx={{
							"& > *": {
								cursor: "pointer",
							},
						}}
					>
						<Box
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							rowGap={"8px"}
							onClick={handleHomeNav}
						>
							<Home size={24} color="white" />
							<Title>Discover</Title>
						</Box>
						<Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={"8px"}>
							<Like size={24} color="white" />
							<Title>Likes</Title>
						</Box>
						<Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={"8px"}>
							<Matches size={24} color="white" />
							<Title>Matches</Title>
						</Box>
						<Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={"8px"}>
							<Spinlove size={24} color="white" />
							<Title>Spinlove</Title>
						</Box>
						<Box display={"flex"} flexDirection={"column"} alignItems={"center"} rowGap={"8px"}>
							<Messages size={24} color="white" />
							<Title>Messaging</Title>
						</Box>
						<Box
							display={"flex"}
							flexDirection={"column"}
							alignItems={"center"}
							rowGap={"8px"}
							onClick={handleProfileNav}
						>
							<User size={24} color="white" />
							<Title>Profile</Title>
						</Box>
					</Box>
				)}
			</SubWrapper>
		</Wrapper>
	);
};

export default Header;
