import React, { useState } from "react";
import "./index.scss";

import { TabPanel, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import MBFollowing from "./Following";
import NFEducation from "./Feed/Education";
import MBRewardFeed from "./Feed/Reward";
import MBMonthly from "./Monthly";
import MBMarketLink from "./MarketLink";

const MBHome = () => {
	return (
		<Tabs>
			<TabPanel>
				<NFEducation />
			</TabPanel>
			<TabPanel>
				<MBFollowing />
			</TabPanel>
			<TabPanel>
				<MBRewardFeed />
			</TabPanel>
			<TabPanel>
				<MBMonthly />
			</TabPanel>
			<TabPanel>
				<MBMarketLink />
			</TabPanel>
		</Tabs>
	);
};

export default MBHome;
