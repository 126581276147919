import styled from "@emotion/styled";

const MainTitle = styled.h2`
	color: #000;

	/* font-weight-medium-xxxl */
	font-family: Inter;
	font-size: 24px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
`;

const Info = styled.div`
	color: #8d8d8d;

	/* body-text-xsm */
	font-family: Inter;
	font-size: 12px;
	font-style: normal;
	font-weight: 400;
	line-height: normal;
`;

const SubTitle = styled.h3`
	color: #242323;

	/* font-weight-bold-xl */
	font-family: Inter;
	font-size: 18px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
`;

export { MainTitle, Info, SubTitle };
