const Index = ({ color = "#fff", size = 24 }) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" width={size} height={size} viewBox="0 0 25 24" fill="none">
			<path
				d="M17.4107 2C15.3826 2 13.6069 2.87896 12.5 4.36467C11.3931 2.87896 9.61741 2 7.58929 2C5.97487 2.00183 4.42709 2.64898 3.28552 3.79947C2.14395 4.94996 1.50182 6.50983 1.5 8.13686C1.5 15.0656 11.6937 20.6739 12.1278 20.9055C12.2422 20.9675 12.3701 21 12.5 21C12.6299 21 12.7578 20.9675 12.8722 20.9055C13.3063 20.6739 23.5 15.0656 23.5 8.13686C23.4982 6.50983 22.856 4.94996 21.7145 3.79947C20.5729 2.64898 19.0251 2.00183 17.4107 2ZM12.5 19.302C10.7066 18.2488 3.07143 13.4512 3.07143 8.13686C3.07299 6.92977 3.54948 5.77257 4.3964 4.91903C5.24333 4.06549 6.39155 3.58528 7.58929 3.58371C9.49955 3.58371 11.1034 4.60916 11.7732 6.25621C11.8324 6.40145 11.9331 6.52567 12.0625 6.61309C12.1919 6.70051 12.3442 6.74719 12.5 6.74719C12.6558 6.74719 12.8081 6.70051 12.9375 6.61309C13.0669 6.52567 13.1676 6.40145 13.2268 6.25621C13.8966 4.60619 15.5004 3.58371 17.4107 3.58371C18.6084 3.58528 19.7567 4.06549 20.6036 4.91903C21.4505 5.77257 21.927 6.92977 21.9286 8.13686C21.9286 13.4433 14.2914 18.2478 12.5 19.302Z"
				fill={color}
			/>
		</svg>
	);
};

export default Index;
