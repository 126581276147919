import LeftSubNav from "../../../../components/LeftSubNav";
import { borderRadius, color, spacing } from "../../../../styles/variables";
import {
	Wrapper,
	LeftSection,
	RightSection,
	ResultWrapper,
	Result,
	ProfileWrapper,
	UserImage,
	UserDetailsWrapper,
	UserDetails,
	UserName,
	StatsWrapper,
	Stat,
	OtherProfilesWrapper,
	ProfilesHeader,
	Profiles,
	Profile,
	ManageLinks,
} from "../../index.styled";
import Preview1 from "../../../../assets/images/shorts/Preview1.png";
import Preview2 from "../../../../assets/images/shorts/Preview2.png";
import ProfilePicture from "../../../../assets/images/user-profile-backsquare.png";
import PrimaryBtn from "../../../../components/Buttons/Primary";
import { useEffect, useState } from "react";
import { GetShortVideosPrivateProfile } from "../../../../utils/apiCalls";
import Loader from "../../../../components/loader/index";

const PrivateProfile = () => {
	const data = localStorage?.getItem("shortVideoData");
	const parsedData = JSON.parse(data);
	const [profileData, setProfileData] = useState([]);
	const [isLoader, setIsLoader] = useState(false);

	const getPrivateProfile = () => {
		setIsLoader(true);
		if (parsedData) {
			const payload = {
				shortVideoId: parsedData?.shortVideoId,
			};
			GetShortVideosPrivateProfile((res) => {
				const { status, data, message } = res;
				if (status === 200) {
					setIsLoader(false);
					setProfileData(data);
				} else {
					setIsLoader(false);
					setProfileData(data);
				}
			}, payload);
		}
	};

	useEffect(() => {
		getPrivateProfile();
	}, [data]);

	if (isLoader) {
		return <Loader />;
	}
	
	return (
		<Wrapper>
			<LeftSection>
				<LeftSubNav />
				<OtherProfilesWrapper>
					<ProfilesHeader>Other Profiles</ProfilesHeader>
					<Profiles>
						<Profile>
							<span>Engineering</span> George Kajaia
						</Profile>
						<Profile>
							<span>Engineering</span> George Kajaia
						</Profile>
						<Profile>
							<span>Engineering</span> George Kajaia
						</Profile>
					</Profiles>
					<ManageLinks>Manage Links</ManageLinks>
				</OtherProfilesWrapper>
			</LeftSection>
			<RightSection
				style={{
					gridColumn: "span 9/13",
				}}
			>
				<ProfileWrapper>
					<UserImage src={profileData?.profilePicture || ProfilePicture} alt="profile" />
					<UserDetailsWrapper>
						<UserDetails>
							<UserName>{profileData?.shortVideoUsername || "Anonymous User"}</UserName>
							<StatsWrapper>
								<Stat>448 Following</Stat>
								<Stat>744 Followers</Stat>
							</StatsWrapper>
						</UserDetails>
						<PrimaryBtn
							label={"Edit Profile"}
							overrides={{
								textTransform: "none",
								borderRadius: borderRadius(60),
								paddingInline: spacing("xl"),
								paddingBlock: spacing("xs"),
							}}
						/>
					</UserDetailsWrapper>
				</ProfileWrapper>
				<ResultWrapper>
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
					<Result src={Preview1} alt="preview" />
					<Result src={Preview2} alt="preview" />
				</ResultWrapper>
			</RightSection>
		</Wrapper>
	);
};

export default PrivateProfile;
