import styled from "@emotion/styled";
import { color, spacing } from "../../../styles/variables";

const NotificationsList = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("lg")};
	padding: ${spacing("xl1")} ${spacing("lg1")};

	background: ${color("pWhite")};
	box-shadow: 0px 4px 13px 0px rgba(0, 0, 0, 0.25);
`;

const NotificationWrapper = styled.div`
	display: flex;
	column-gap: ${spacing("md2")};
`;

const NotificationImage = styled.img`
	border-radius: 50%;
	height: 68px;
	aspect-ratio: 1;
`;

const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	row-gap: ${spacing("md")};
`;

const NotificationUser = styled.h5`
	color: ${color("lightGreyFaded")};
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 600;
	line-height: 133.125%; /* 19.969px */
`;

const NotificationDesc = styled.p`
	color: ${color("strokedGrayLight")};
	font-family: Inter;
	font-size: 15px;
	font-style: normal;
	font-weight: 400;
	line-height: 133.125%; /* 19.969px */
`;

export { NotificationsList, NotificationWrapper, NotificationImage, InfoWrapper, NotificationUser, NotificationDesc };
