import { Fragment } from "react";
import Heading from "../../../components/Heading/Heading";
import Page from "../../../layouts/Page";
import Magazine from "../../../assets/icons/magazine/magazine";
import SignUpForm from "../../../components/Magazine/Forms/Signup";

const Signup = () => {
	return (
		<Fragment>
			<Heading icon={Magazine} title={"Magazine"} moduleHomeRedirect={"/mg/home"} />
			<Page>
				<SignUpForm />
			</Page>
		</Fragment>
	);
};

export default Signup;
