import React from "react";
import "./HeaderPrivacy.scss"
import Buzzgalactic from "../../assets/icons/buzzgalactic/logo.svg";
import styled from "@emotion/styled";
import { spacing } from "../../styles/variables";

const HeaderPrivacy = ({ icon, title }) => {
	return (
		<Wrapper>
			<SubWrapper>
				<div className="headingPrivacy">
					<div className="headingPrivacy_container">
						<div className="headingPrivacy_sections">
							<div className="headingPrivacy_left">
								<LogoContainer>
									<img
										src={Buzzgalactic}
										alt="logo"
										style={{
											width: "132px",
										}}
									/>
								</LogoContainer>
								{icon && title && (
									<div className="headingPrivacy_left_content">
										{<img src={icon} alt="logo" className="image" />}
										<h3
											style={{
												textTransform: "capitalize",
											}}
										>
											{title}
										</h3>
									</div>
								)}
							</div>
						</div>
					</div>
				</div>
			</SubWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	background-color: #f5f5f5;

	display: grid;
	grid-column-gap: ${spacing("xxxl")};
	grid-template-columns: repeat(1, 1fr);

	@media screen and (min-width: 1440px) {
		& > div {
			max-width: 1440px;
		}
	}
`;
const SubWrapper = styled.div`
	margin-inline: ${spacing("xxxl1")};
	width: -webkit-fill-available;

	@media screen and (min-width: 1440px) {
		margin-inline: auto;
	}

	@media screen and (max-width: 768px) {
		margin-inline: 0;
	}
`;

const LogoContainer = styled.div`
	cursor: pointer;
	margin-block: 12px;
	border-right: 1px solid #cbc3c3;

	& > img {
		width: 132px;
		padding-right: 12px;
	}

	@media screen and (max-width: 768px) {
		border: none;
	}
`;

export default HeaderPrivacy;
