import React from "react";
import location from "../../../../../assets/images/icons/location.png";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import ProfilePicture from "../../../../../assets/images/IT/itprofileclient.png";
import comment from "../../../../../assets/images/profile/comment.png";
import comment1 from "../../../../../assets/images/profile/comment1.png";
import message from "../../../../../assets/icons/mi_message.png";
import outline from "../../../../../assets/icons/material-symbols_rate-review-outline.png";

import ReviewComponent from "../../../../../components/ReviewComponent/ReviewComponent.jsx";
import Button from "../../../../../components/button/index.js";

import {
	ProfileSections,
	ProfileWrapper,
	SectionTitle,
	Wrapper,
	Profile,
	ProfileDetailsWrapper,
	ProfileImageWrapper,
	UserDetailsWrapper,
	UserName,
	UserNameWrapper,
	UserAddress,
	UserProfileActions,
	MoreDetailWrapper,
	ProfilesAndAboutWrapper,
	Profiles,
	About,
	AboutTitle,
	AboutDesc,
	More,
	ProfileHeading,
	ProfileName,
	ProfileUserName,
	ProfileList,
	FeedbackWrapper,
	Feedback,
	reviewComponentStyles,
	ProfilesWrapper,
	Butt,
	ProfileModule,
} from "../../../styles/index.styled.js";
import { useQuery } from "@tanstack/react-query";
import { query } from "../../../../../utils/queryController.js";
import { connect } from "react-redux";
import { CircularProgress } from "@mui/material";
import { color } from "../../../../../styles/variables.js";

const ClientPublic = ({ setActiveMBProfile, setActiveModule }) => {
	const token = localStorage.getItem("token") || sessionStorage.getItem("token");
	const navigate = useNavigate();
	const { state } = useLocation();
	const userData = state?.user;
	const handlePublicProfileNav = (path) => {
		return;
		navigate(`/${path}`);
	};

	const { data, isLoading } = useQuery({
		queryKey: "AgricultureClientProfile",
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getAgricultureClientProfile?clientId=${userData?.clientId}&agricultureId=${userData?.agricultureId}`,
			);
			const { data } = response;
			return data;
		},
	});

	const { isLoading: isShortVideoAccountLoading, refetch: fetchShortVideos } = useQuery({
		queryKey: "AgriculturePrivateShortVideosProfile",
		queryFn: async () => {
			const response = await query(
				"GET",
				"AG_PROFILE",
				`getPrivateAgricultureShortVideoProfile?agricultureId=${data?.agricultureId}`,
			);
			const { data: profileData, status } = response;
			console.log(data, "shortVideoData");
			if (status === 200) {
				navigate("/shorts/profile/public", {
					state: {
						user: profileData,
					},
				});
			}
			return data;
		},
		enabled: false,
	});

	const handleMBRedirection = () => {
		setActiveModule("ag");
		setActiveMBProfile(data?.agricultureId);
		navigate("/microblogging/profile/public");
	};

	if (isLoading || isShortVideoAccountLoading) {
		return (
			<div
				style={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					minHeight: "80dvh",
				}}
			>
				<CircularProgress
					sx={{
						color: color("pGreen"),
					}}
				/>
			</div>
		);
	}

	return (
		<>
			{token ? (
				<Wrapper>
					<ProfileWrapper>
						<ProfileSections>
							<SectionTitle>Client</SectionTitle>
							<Profile>
								<ProfileDetailsWrapper>
									<ProfileImageWrapper>
										<img src={data?.profilePicture || ProfilePicture} alt="profile" />
									</ProfileImageWrapper>
									<UserDetailsWrapper>
										<UserNameWrapper>
											<UserName>{data?.fullName || "Anonymous user"}</UserName>
										</UserNameWrapper>
										<UserAddress>
											<img src={location} alt="location-icon" />
											{data?.location || "No Location available"}
										</UserAddress>
									</UserDetailsWrapper>
								</ProfileDetailsWrapper>
								<UserProfileActions>
									<Button buttonClick={() => handlePublicProfileNav("message")}>
										<img src={message} alt="message" />
										Message
									</Button>
									<Button buttonClick={() => handlePublicProfileNav("review")}>
										<img src={outline} alt="review" />
										Review
									</Button>
								</UserProfileActions>
							</Profile>

							<MoreDetailWrapper>
								<ProfilesAndAboutWrapper>
									<ProfilesWrapper>
										<Profiles fillAvailable>
											<ProfileHeading>Other Profiles</ProfileHeading>
											<ProfileModule>
												<Butt onClick={handleMBRedirection}>Microblogging</Butt>
												<Butt onClick={fetchShortVideos}>ShortVideos</Butt>
											</ProfileModule>
											<ProfileList>
												{data?.userName && (
													<ProfileName>
														<ProfileUserName>userName </ProfileUserName>
														{data?.userName}
													</ProfileName>
												)}
												{data?.shortVideoUsername && (
													<ProfileName>
														<ProfileUserName>shortVideoUsername </ProfileUserName>
														{data?.shortVideoUsername}
													</ProfileName>
												)}
											</ProfileList>
										</Profiles>
									</ProfilesWrapper>
									<About>
										<AboutTitle>About</AboutTitle>
										<AboutDesc>{data?.about || "No Data available"}</AboutDesc>
										<More>more...</More>
									</About>
								</ProfilesAndAboutWrapper>
								<FeedbackWrapper>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment1}
											commentimagestyle={{
												marginLeft: "2rem",
											}}
											commenttitle="Thanks"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
									<Feedback>
										<ReviewComponent
											heading={"Education"}
											name={"George"}
											commentimage={comment}
											commenttitle="Reply"
											reviewcomponentstyle={reviewComponentStyles}
										/>
									</Feedback>
								</FeedbackWrapper>
							</MoreDetailWrapper>
						</ProfileSections>
					</ProfileWrapper>
				</Wrapper>
			) : (
				<Navigate to={"/login"} />
			)}
		</>
	);
};

const mapPropsToState = (dispatch) => ({
	setActiveModule: (module) =>
		dispatch({
			type: "SET_ACTIVE_MB_MODULE",
			payload: module,
		}),
	setActiveMBProfile: (id) =>
		dispatch({
			type: "MB_SET_ACTIVE_PROFILE",
			payload: id,
		}),
});

export default connect(undefined, mapPropsToState)(ClientPublic);
